import React, { useState } from 'react';
import { exists } from 'lib/types';
import ToastActions from 'redux/toast';
import { userHasPermission } from 'utils/permissions';
import { Permissions } from 'lib/permissions/roles';
import { PlusSmallIcon } from '@heroicons/react/24/outline';
import { ColumnButton } from 'lib/components/ColumnButton';
import FreeformCModal from 'components/modals/FreeFormCModal';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { selectActiveOrganization, selectUser } from 'redux/auth';
import LoadingState from 'components/LoadingState';
import CreateInvitesFormContainer from '../invites/CreateInvitesFormContainer';
import OrgSettingsMemberTable from './OrgSettingsMemberTable';
import SettingsHeader from './SettingsHeader';

export default function OrganizationMemberSettings() {
  const activeOrganization = useAppSelector(selectActiveOrganization);
  const user = useAppSelector(selectUser);
  const dispatch = useAppDispatch();
  const [showInviteForm, setShowInviteForm] = useState(false);
  const [inviteModalHeaderText, setInviteModalHeaderText] = useState(
    'Invite members to your organization'
  );
  const [refresh, setRefresh] = useState(false);

  if (!exists(activeOrganization) || !exists(user)) {
    return <LoadingState />;
  }

  return (
    <div className="bg-white border box-border rounded-lg">
      <SettingsHeader
        header="Organization"
        description="These users belong to your organization."
      >
        {userHasPermission(user, Permissions.ORGANIZATION_MODIFY_MEMBERS) && (
          <ColumnButton
            id="invite"
            tertiary
            size="lg"
            startIcon={<PlusSmallIcon className="h-6 w-6" />}
            buttonText={'Invite Additional'}
            onClick={() => {
              setShowInviteForm(true);
            }}
            type="button"
          />
        )}
      </SettingsHeader>
      <OrgSettingsMemberTable
        setToastMessage={({ message, invitedSuccessfully }) =>
          invitedSuccessfully
            ? dispatch(
                ToastActions.toastSuccess({
                  headerText: 'Success',
                  bodyText: message
                })
              )
            : dispatch(
                ToastActions.toastError({
                  headerText: 'Error',
                  bodyText: message
                })
              )
        }
        activeOrganization={activeOrganization}
        setRefresh={setRefresh}
        refresh={refresh}
        user={user}
      />
      {showInviteForm && (
        <FreeformCModal
          header={inviteModalHeaderText}
          setOpen={() => setShowInviteForm(false)}
        >
          <CreateInvitesFormContainer
            organization={activeOrganization}
            onSetShowInviteForm={setShowInviteForm}
            onUpdateInviteModalHeaderText={setInviteModalHeaderText}
            onSetToastMessage={newMessage =>
              dispatch(
                ToastActions.toastSuccess({
                  headerText: 'Success',
                  bodyText: newMessage
                })
              )
            }
          />
        </FreeformCModal>
      )}
    </div>
  );
}
