import { Ad } from 'lib/types/ad';
import { Question } from 'lib/types/madlib';
import { Obituary, ObituaryFilingTypeNames } from 'lib/types/obituary';
import moment from 'moment';

/**
 * Automatically prefill madlib data based on order information
 * This prevents users from having to submit all of the required fields twice
 */
export const getInitialMadlibDataForDeathNotice = (
  adData: Partial<Obituary>,
  questions: Question[]
) => {
  const questionTemplateData: Record<string, any> = {};
  for (const question of questions) {
    if (question.varName.includes('ad__deceasedBirthDate')) {
      questionTemplateData[question.varName] = moment(
        adData.deceasedBirthDate
      ).format('MM/DD/YYYY');
    } else if (question.varName.includes('ad__deceasedDeathDate')) {
      questionTemplateData[question.varName] = moment(
        adData.deceasedDeathDate
      ).format('MM/DD/YYYY');
    } else if (question.varName.includes('ad__deceasedName')) {
      questionTemplateData[question.varName] = adData.deceasedName;
    } else if (question.varName.includes('includeDateOfBirth')) {
      questionTemplateData[question.varName] = true;
    }
  }
  const templateData = {};
  return {
    templateData,
    questionTemplateData
  };
};

export const getInitialMadlibData = (
  adData: Partial<Ad>,
  questions: Question[]
) => {
  if (adData.filingTypeName === ObituaryFilingTypeNames.DeathNotice) {
    return getInitialMadlibDataForDeathNotice(adData, questions);
  }
  return null;
};
