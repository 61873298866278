import { datadogLogs } from '@datadog/browser-logs';
import { JSONObject, LogFn, logInternal } from 'lib/types/logger';

type LogUser = {
  id: string;
  email?: string;
  occupation?: string;
  organizationType?: string;
};

interface Logger {
  setUser: (user?: LogUser) => void;
  getSessionId: () => string | undefined;
  info: LogFn;
  warn: LogFn;
  error: LogFn;
}

const ConsoleLogger: Logger = {
  setUser: () => {},
  getSessionId: () => undefined,
  info: console.log,
  warn: console.warn,
  error: console.error
};

const DataDogLogger: Logger = {
  setUser: user => {
    if (user) {
      datadogLogs.setUser(user);
    } else {
      datadogLogs.clearUser();
    }
  },
  getSessionId: () => {
    return datadogLogs.getInternalContext()?.session_id;
  },
  info: (message: string, data?: any, ...args: any[]) => {
    console.log(message, data, ...args);
    datadogLogs.logger.info(message, data);
  },
  warn: (message: string, data?: any, ...args: any[]) => {
    console.warn(message, data, ...args);
    datadogLogs.logger.warn(message, data);
  },
  error: (message: string, data?: any, ...args: any[]) => {
    console.error(message, data, ...args);
    datadogLogs.logger.error(message, data);
  }
};

let _LOGGER: Logger = ConsoleLogger;

export const initializeLogger = (options: {
  env: string;
  useDataDog: boolean;
}) => {
  const { env, useDataDog } = options;

  if (useDataDog) {
    datadogLogs.init({
      env: env.toLowerCase(),
      service: 'enotice-app',
      clientToken: 'puba1ba9c9a26fd6e8f86db79da4949cfe0',
      sessionSampleRate: 100,
      forwardErrorsToLogs: false,
      forwardConsoleLogs: [],
      silentMultipleInit: true
    });

    _LOGGER = DataDogLogger;
  }
};

export const getLogger = () => {
  return _LOGGER;
};

/**
 * Log a message at INFO level with both structured and unstructured data.
 */
export const logInfo = (message: string, data?: JSONObject, ...args: any[]) => {
  logInternal(_LOGGER.info, message, data, ...args);
};

/**
 * Log a message at WARN level with both structured and unstructured data.
 */
export const logWarn = (message: string, data?: JSONObject, ...args: any[]) => {
  logInternal(_LOGGER.warn, message, data, ...args);
};

/**
 * Log a message at ERROR level with both structured and unstructured data.
 */
export const logError = (
  message: string,
  data?: JSONObject,
  ...args: any[]
) => {
  logInternal(_LOGGER.error, message, data, ...args);
};
