import { GridInput } from 'lib/components/Card/Grid';
import LabeledSwitch from 'lib/components/LabeledSwitch';
import { EOrganization, ESnapshot } from 'lib/types';
import { CustomerObjDataFields } from '../CreateOrEditModalDetails';

type CustomerAffidavitSettingsValue = Pick<
  CustomerObjDataFields,
  'enableAffidavitsBeforePayment' | 'allowAffidavitEmail'
>;
type CustomerAffidavitSettingsProps = {
  activeOrganization: ESnapshot<EOrganization> | null;
  onChange: (value: CustomerAffidavitSettingsValue) => void;
  value: CustomerAffidavitSettingsValue;
};

export function CustomerAffidavitSettings({
  activeOrganization,
  onChange,
  value
}: CustomerAffidavitSettingsProps) {
  return (
    <>
      <GridInput fullWidth>
        <LabeledSwitch
          label="Allow affidavits before payment?"
          description="Customers will be allowed to download affidavits before paying
              invoices."
          value={!!value.enableAffidavitsBeforePayment}
          onChange={enableAffidavitsBeforePayment => {
            onChange({
              ...value,
              enableAffidavitsBeforePayment
            });
          }}
        />
      </GridInput>
      {activeOrganization?.data()?.hideDigitalAffidavits && (
        <GridInput fullWidth>
          <LabeledSwitch
            label="Allow emailed affidavits?"
            description="Toggle this on if you want to allow this customer to request
                  affidavits by email."
            value={!!value.allowAffidavitEmail}
            onChange={allowAffidavitEmail => {
              onChange({
                ...value,
                allowAffidavitEmail
              });
            }}
          />
        </GridInput>
      )}
    </>
  );
}
