import { RouteObject } from 'react-router-dom';
import SignInLayout from 'layouts/signInLayout';
import Login from 'routes/login';
import Register from 'routes/register/user';
import { ResetPassword } from 'routes/resetPassword';
import ForgotPassword from 'routes/forgotPassword';
import Invitation from 'routes/invites/invitation';
import { INVITE_ROUTE, MAIN_ROUTES } from './routes';
import { RequireAnon } from './redirect/RequireAnon';

export const authRoutes: RouteObject = {
  element: <SignInLayout />,
  children: [
    { path: INVITE_ROUTE, element: <Invitation /> },
    {
      element: <RequireAnon />,
      children: [
        { path: MAIN_ROUTES.FORGOT_PASSWORD, element: <ForgotPassword /> },
        { path: MAIN_ROUTES.LOGIN, element: <Login /> },
        { path: MAIN_ROUTES.REGISTER, element: <Register /> },
        { path: MAIN_ROUTES.RESET_PASSWORD, element: <ResetPassword /> }
      ]
    }
  ]
};
