import React, { useState } from 'react';

import { ESnapshotExists, ETemplate } from 'lib/types';
import { ColumnButton } from 'lib/components/ColumnButton';
import TabGroup, { TabOption } from 'lib/components/Tabs';
import TemplateDesignTab from './TemplateDesignTab';
import TemplateSettingsTab from './TemplateSettingsTab';
import TemplatePreviewTab from './TemplatePreviewTab';

const PREVIEW_TAB: TabOption = {
  label: 'Preview',
  enabled: true,
  id: 'preview-tab'
};
const DESIGN_TAB: TabOption = {
  label: 'Design',
  enabled: true,
  id: 'design-tab'
};
const SETTINGS_TAB: TabOption = {
  label: 'Settings',
  enabled: true,
  id: 'settings-tab'
};

const TEMPLATE_DRAWER_TABS = [PREVIEW_TAB, DESIGN_TAB, SETTINGS_TAB];

type TemplateDrawerBodyProps = {
  template: ESnapshotExists<ETemplate> | null;
  onEdit: () => void;
};
export default function TemplateDrawerBody({
  template,
  onEdit
}: TemplateDrawerBodyProps) {
  const [activeTab, setActiveTab] = useState(TEMPLATE_DRAWER_TABS[0]);

  if (!template) {
    return null;
  }

  return (
    <div className="relative flex flex-col h-full">
      <TabGroup
        onClickTab={setActiveTab}
        activeTab={activeTab}
        tabs={TEMPLATE_DRAWER_TABS}
        id="template-drawer-tabs"
      />

      <div className="overflow-auto px-8 pt-4 pb-8 flex-grow">
        {activeTab.label === PREVIEW_TAB.label && (
          <TemplatePreviewTab template={template} />
        )}

        {activeTab.label === DESIGN_TAB.label && (
          <TemplateDesignTab template={template} />
        )}

        {activeTab.label === SETTINGS_TAB.label && (
          <TemplateSettingsTab template={template} />
        )}
      </div>

      <div className="my-10" />

      <div className="sticky bottom-0 flex w-full py-4 px-8 border-t bg-white">
        <div className="flex-1" />
        <ColumnButton
          id="edit-template"
          size="lg"
          buttonText="Edit"
          onClick={onEdit}
          type="button"
        />
      </div>
    </div>
  );
}
