import { useState } from 'react';
import { useAppDispatch } from 'redux/hooks';
import ToastActions from 'redux/toast';
import { Modal } from 'lib/components/Modal';
import { MediaModel } from 'lib/model/objects/mediaModel';

type MediaEditorModalProps = {
  media: MediaModel;
  onClose: () => void;
  onEdit: () => void;
};

export default function MediaEditorModal({
  media,
  onClose,
  onEdit
}: MediaEditorModalProps) {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);

  return (
    <Modal
      id="media-editor"
      onClose={onClose}
      title="Edit Media"
      loading={loading}
      primaryAction={{
        buttonText: 'Close',
        type: 'button',
        onClick: onClose
      }}
      secondaryActions={[
        {
          buttonText: 'Archive',
          type: 'button',
          destructive: true,
          secondary: true,
          onClick: async () => {
            setLoading(true);
            const [error] = await media.archive();

            if (error) {
              dispatch(
                ToastActions.toastError({
                  headerText: 'Error archiving media',
                  bodyText: 'Failed to archive media. Please try again.'
                })
              );
            }

            setLoading(false);
            onEdit();
            onClose();
          }
        }
      ]}
    >
      <img src={media.modelData.cdnUrl} alt="Selected Media" />
    </Modal>
  );
}
