import { exists } from 'lib/types';
import { ExternalUploadFormats } from 'lib/types/externalUploads';
import SettingsHeader, {
  SettingsHeaderProps
} from 'routes/settings/SettingsHeader';
import { useActiveOrganizationListener } from 'hooks/useActiveOrganizationListener';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { useBooleanFlag } from 'utils/flags';
import { ExternalUploadForm } from './ExternalUploadForm';
import ColumnDisplaySiteForm from './ColumnDisplaySite';

type ExternalUploadProps = {
  uploadFormat: ExternalUploadFormats;
};

export function ExternalUpload({ uploadFormat }: ExternalUploadProps) {
  const activeOrganization = useActiveOrganizationListener();

  const showColumnDisplaySiteForm = useBooleanFlag(
    LaunchDarklyFlags.ENABLE_COLUMN_PUBLIC_NOTICE_DISPLAY_SITE_CONTROL
  );

  if (!exists(activeOrganization)) {
    return null;
  }

  const { externalUploadSettings } = activeOrganization.data();

  const displaySiteText: SettingsHeaderProps = showColumnDisplaySiteForm
    ? {
        header: 'Public Notice Display Sites',
        description:
          "Control how notices appear on your Column display site and your press association's statewide site."
      }
    : {
        header: 'Upload to Your Statewide Site',
        description:
          'Upload invoiced notices on publication to your statewide site.'
      };

  return (
    <>
      <SettingsHeader
        header={displaySiteText.header}
        description={displaySiteText.description}
      />
      {showColumnDisplaySiteForm && (
        <ColumnDisplaySiteForm activeOrganization={activeOrganization} />
      )}

      <ExternalUploadForm
        uploadFormat={uploadFormat}
        uploadSettings={externalUploadSettings}
      />
    </>
  );
}
