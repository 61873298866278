import { selectActiveOrganization, selectUser } from 'redux/auth';
import { logAndCaptureException } from 'utils';
import { ColumnService } from 'lib/services/directory';
import ToastActions from 'redux/toast';
import { getModelFromSnapshot } from 'lib/model';
import { PackageModel } from 'lib/model/objects/packageModel';
import { safeUpdateModel } from 'lib/model/safeHandlers';
import { AppAsyncThunk } from 'redux/types';
import { ResponseOrError, wrapError, wrapSuccess } from 'lib/types/responses';
import { getFirebaseContext } from 'utils/firebase';
import { PackageService } from 'lib/services/packageService';
import { ESnapshotExists, Package } from 'lib/types';

const packageService = new PackageService(getFirebaseContext());

export type PackageFormData = Pick<
  Package,
  | 'title'
  | 'description'
  | 'filingTypeLabel'
  | 'product'
  | 'publisherSettings'
  | 'visibility'
  | 'publisherOrganizations'
>;

export function createPackage(
  formData: PackageFormData
): AppAsyncThunk<ResponseOrError<null>> {
  return async (dispatch, getState) => {
    const activeOrganization = selectActiveOrganization(getState());
    const user = selectUser(getState());
    if (!activeOrganization || !user) {
      dispatch(
        ToastActions.toastError({
          headerText: 'Error updating package',
          bodyText: 'You must be logged in to update a package.'
        })
      );
      return wrapError(new Error('User not logged in'));
    }

    const [error] = await packageService.create({
      ...formData,
      authorizedOrganization: activeOrganization.ref,
      createdBy: user.ref
    });

    if (error) {
      logAndCaptureException(
        ColumnService.SETTINGS_MANAGEMENT,
        error,
        'Error creating package',
        {
          userId: user.id,
          organizationId: activeOrganization.id
        }
      );
      dispatch(
        ToastActions.toastError({
          headerText: 'Error creating package',
          bodyText:
            'An error occurred while creating the package. Please try again.'
        })
      );
      return wrapError(error);
    }

    dispatch(
      ToastActions.toastSuccess({
        headerText: 'Package created',
        bodyText: 'The package has been successfully created.'
      })
    );
    return wrapSuccess(null);
  };
}

export function updatePackage(
  formData: PackageFormData,
  currentPackage: ESnapshotExists<Package>
): AppAsyncThunk<ResponseOrError<null>> {
  return async (dispatch, getState) => {
    const activeOrganization = selectActiveOrganization(getState());
    const user = selectUser(getState());
    if (!activeOrganization || !user) {
      dispatch(
        ToastActions.toastError({
          headerText: 'Error updating package',
          bodyText: 'You must be logged in to update a package.'
        })
      );
      return wrapError(new Error('User not logged in'));
    }

    const packageModel = getModelFromSnapshot(
      PackageModel,
      getFirebaseContext(),
      currentPackage
    );

    const [error] = await safeUpdateModel(packageModel, formData);
    if (error) {
      logAndCaptureException(
        ColumnService.SETTINGS_MANAGEMENT,
        error,
        'Error updating package',
        {
          userId: user.id,
          organizationId: activeOrganization.id,
          packageId: currentPackage.id
        }
      );
      dispatch(
        ToastActions.toastError({
          headerText: 'Error updating package',
          bodyText:
            'An error occurred while updating the package. Please try again.'
        })
      );
      return wrapError(error);
    }

    dispatch(
      ToastActions.toastSuccess({
        headerText: 'Package updated',
        bodyText: 'The package has been successfully updated.'
      })
    );
    return wrapSuccess(null);
  };
}
