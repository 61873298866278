import React, { useRef, useState } from 'react';
import { PinturaEditor } from '@pqina/react-pintura';
import { getEditorDefaults } from '@pqina/pintura';
import '@pqina/pintura/pintura.css';
import { OrderImage } from 'lib/orders/images';
import { Layout } from 'lib/types/layout';
import { Modal } from 'lib/components/Modal';
import { uploadFilesToStorage } from 'lib/frontend/hooks/useFirebaseStorageUpload';
import Firebase from 'EnoticeFirebase';
import { ColumnService } from 'lib/services/directory';
import { getErrorReporter } from 'lib/utils/errors';
import { cdnIfy, unCdnify } from 'lib/helpers';
import api from 'api';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import classNames from 'classnames';
import { LoadingSpinner } from 'lib/components/LoadingSpinner';
import { getBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { LayoutModel } from 'lib/model/objects/layoutModel';

/**
 * The width of the image to use for the editor, always use 800
 * We set a fixed width as otherwise small images are hard to see within the editor
 */
const FIXED_WIDTH_IMAGE_SIZE = 450;

/**
 * Enables selection between the original image and the upscaled image
 */
function UpscaleOrNotTabSelector({
  value: useUpscale,
  onChange,
  loading
}: {
  value: boolean;
  onChange: (value: boolean) => void;
  loading: boolean;
}) {
  return (
    <div className="ml-28 sticky flex">
      <div className="mx-auto flex bg-column-gray-50 p-1 rounded-md mt-5">
        <div
          className={classNames(
            'cursor-pointer px-12 py-2 rounded-md text-xs font-semibold text-column-gray-400',
            {
              'bg-white text-column-gray-500': !useUpscale
            }
          )}
          role="button"
          onClick={() => onChange(false)}
          tabIndex={0}
        >
          Default
        </div>
        <div
          className={classNames(
            'cursor-pointer px-12 py-2 rounded-md text-xs font-semibold text-column-gray-400',
            {
              'bg-white text-column-gray-500': useUpscale,
              'opacity-50 pointer-events-none cursor-not-allowed flex items-center justify-center gap-2':
                loading
            }
          )}
          role="button"
          onClick={() => onChange(true)}
          tabIndex={0}
        >
          {loading && <LoadingSpinner size="sm" />}
          Upscaled
        </div>
      </div>
    </div>
  );
}

type EditorUtil = 'resize' | 'crop' | 'filter' | 'finetune' | 'frame';
const ENABLED_EDITOR_UTILS: EditorUtil[] = [
  'crop',
  'resize'
  // TODO: Re-enable these options later
  // 'filter',
  // 'finetune',
  // 'frame'
];

const CUSTOM_EDITOR_TAB_TITLES = {
  resize: 'Upscale your image to make it sharper and look better in print',
  crop: 'Drag the corners of the black grid to change how your image is cropped',
  filter:
    'Use filters to make your picture darker, lighter, grayscaled and more',
  finetune:
    'Change the brightness, contrast, and saturation of the image to make it stand out',
  frame: 'Add a frame to the image to make it look more professional'
};

type ImageEditorModalProps = {
  onUpdateOrderImage: (orderImage: OrderImage) => void;
  orderImage: OrderImage;
  onClose: () => void;
  layout: Layout;
  disabled?: boolean;
  uploadFolder: string;
};

export default function AdvancedImageEditorModal({
  onUpdateOrderImage,
  orderImage,
  onClose,
  layout,
  disabled,
  uploadFolder
}: ImageEditorModalProps) {
  const layoutModel = new LayoutModel(layout);
  const upscaledFileNamePrefix = 'upscaled';
  const enableUpscaling = !orderImage.imageUrl.includes(upscaledFileNamePrefix);

  const editorRef = useRef<PinturaEditor>(null);
  const [uploading, setUploading] = useState(false);
  const [useUpscale, setUseUpscale] = useState(false);
  const [selectedTab, setSelectedTab] = useState<EditorUtil>('crop');

  const { value: upscaleUrl, error: upscaleError } = useAsyncEffect({
    fetchData: async () => {
      const result = await api.safePost('image/upscale', {
        storagePath: unCdnify(orderImage.imageUrl)
      });
      if (result.response) {
        const { url } = result.response;
        // Resize the image to 50% of its original size so it matches the size of the original image
        const resizedUrl = cdnIfy(unCdnify(url), {
          imgixTransformations: { w: `${FIXED_WIDTH_IMAGE_SIZE}` },
          useImgix: true
        });
        return resizedUrl;
      }
      if (result.error) {
        if (selectedTab === 'resize') {
          setSelectedTab('crop');
        }
        throw result.error;
      }
    },
    dependencies: [orderImage.imageUrl]
  });

  const fixedWidthOrderURL = cdnIfy(unCdnify(orderImage.imageUrl), {
    imgixTransformations: { w: `${FIXED_WIDTH_IMAGE_SIZE}` },
    useImgix: true
  });

  /**
   * Save the edits to the image, upoload to storage and override the order image
   */
  const handleSaveEdits = async () => {
    const currentFile = editorRef.current?.editor.imageFile;
    if (!currentFile) {
      return;
    }
    const processedImage = await editorRef.current?.editor.processImage();
    const useColumnCDN = getBooleanFlag(LaunchDarklyFlags.ENABLE_COLUMN_CDN);
    if (!processedImage) return;
    setUploading(true);
    const newFileName = `${
      enableUpscaling ? upscaledFileNamePrefix : 'edited'
    }-${new Date().getTime()}-${currentFile.name}`;

    const newFile = new File([processedImage.dest as File], newFileName, {
      type: currentFile.type
    });
    const { successfulFilesAndUploads } = await uploadFilesToStorage(
      Firebase.storage(),
      [newFile],
      uploadFolder
    );
    if (successfulFilesAndUploads.length > 0) {
      const [successfulFileAndUpload] = successfulFilesAndUploads;
      onUpdateOrderImage({
        ...orderImage,
        sourcePath: successfulFileAndUpload.uploadRef.fullPath,
        imageUrl: cdnIfy(successfulFileAndUpload.uploadRef.fullPath, {
          cloudinaryTransformations: 'q_auto:best',
          useColumnCDN
        })
      });
    } else {
      getErrorReporter().logAndCaptureError(
        ColumnService.FILE_STORAGE,
        new Error('Failed to upload file'),
        'Failed to upload file'
      );
    }
    setUploading(false);
    onClose();
  };

  return (
    <Modal
      title={'Edit Image'}
      id="edit-image-modal"
      onClose={onClose}
      primaryAction={{
        buttonText: 'Save Edits',
        onClick: handleSaveEdits,
        type: 'button'
      }}
      size="3xl"
      loading={uploading}
    >
      <div className="flex flex-col gap-4">
        <div className="text-column-gray-400 text-center font-semibold">
          {CUSTOM_EDITOR_TAB_TITLES[selectedTab]}
        </div>
        <div style={{ height: '40vh' }}>
          <PinturaEditor
            {...getEditorDefaults()}
            src={fixedWidthOrderURL}
            utils={ENABLED_EDITOR_UTILS}
            // We don't want to render the footer for the resize tab because it's a custom component
            resizeWillRenderFooter={() => []}
            util={selectedTab}
            // Rename the resize tab to "upscale"
            willRenderUtilTabs={tabs =>
              tabs
                .map(tab => ({
                  ...tab,
                  label: tab.id === 'resize' ? 'Upscale' : tab.label,
                  selected: selectedTab === tab.id
                }))
                // Only show the resize tab if there's no error and we have not upscaled the image yet
                .filter(
                  tab =>
                    (!upscaleError && enableUpscaling) || tab.id !== 'resize'
                )
            }
            onSelectutil={newTab => setSelectedTab(newTab as EditorUtil)}
            imageCropAspectRatio={layoutModel.imageAspectRatioDecimal}
            ref={editorRef}
            enableButtonExport={false}
            disabled={disabled}
          />
        </div>
        {selectedTab === 'resize' && (
          <UpscaleOrNotTabSelector
            value={useUpscale}
            onChange={async newUseUpscale => {
              const editor = editorRef.current?.editor;
              if (!editor) return;
              if (newUseUpscale) {
                if (!upscaleUrl) return;
                await editor.updateImage(upscaleUrl);
                setUseUpscale(true);
              } else {
                await editor.updateImage(fixedWidthOrderURL);
                setUseUpscale(false);
              }
            }}
            loading={!upscaleUrl}
          />
        )}
      </div>
    </Modal>
  );
}
