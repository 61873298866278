export type FileUploadQuestionInputValue = {
  sanitizedFileName: string;
  storagePath: string;
  linkToUploadedFile: string;
};

export type TemplateData = Record<
  string,
  string | number | boolean | FileUploadQuestionInputValue | null
>;

export type MadlibFieldState = {
  varName: string;
  placeholder: string;
  hint: string;
  value: string;
  isValid: boolean;
};

export type MadlibState = {
  fields: MadlibFieldState[];
  templateData: TemplateData;
  renderedHtml: string;
};

export type MadlibMetadata = {
  noticePrice?: number | null;
  noticeName?: string | null;
};

export type MadlibDataType = Pick<MadlibState, 'templateData'> & {
  /**
   * Publishers may choose to edit an advertiser's Madlib notice using the normal
   * MCE editor. When this happens we set editorOverride to 'true', convert the
   * notice to HTML, and remove all of the structured data.
   */
  editorOverride?: boolean;
  questionTemplateData: TemplateData;
  metadata?: MadlibMetadata;
};

export type BaseQuestion = {
  varName: string;
  prompt: string;
  displayColumns?: number;
};

export type YesNoQuestion = BaseQuestion & {
  type: 'yesno';
  default?: boolean;
};

export type NumberQuestion = BaseQuestion & {
  type: 'number';
  min: number;
  max: number;
  default?: number;
};

export type MultipleChoiceQuestion = BaseQuestion & {
  type: 'multiplechoice';
  choices: {
    value: string;

    // If there is no label, We default to the value
    label?: string;
  }[];
};

export type FileUploadQuestion = BaseQuestion & {
  type: 'file';
  extensions?: string;
};

export type TextInputQuestion = BaseQuestion & {
  type: 'text';
  optional?: boolean;
  placeholder?: string;
  pattern?: string;
};

export type Question =
  | YesNoQuestion
  | NumberQuestion
  | MultipleChoiceQuestion
  | FileUploadQuestion
  | TextInputQuestion;

export type QuestionInputEvent = {
  varName: string;
  value: string | boolean | number | FileUploadQuestionInputValue | null;
};

export type MadlibQuestionConfig = {
  showAllQuestions: boolean;
};
export const DEFAULT_MADLIB_QUESTION_CONFIG: MadlibQuestionConfig = {
  showAllQuestions: false
};
