import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { safeGetOrThrow } from 'lib/safeWrappers';
import { getFirebaseContext } from 'utils/firebase';
import { ESnapshotExists, ENotice, EInvoice } from 'lib/types';
import { SearchableExpressEmailConversationRecord } from 'lib/types/searchable';

export const usePlacementRequestData = (
  conversationRequest: SearchableExpressEmailConversationRecord
) => {
  const {
    value: placementData,
    isLoading,
    isError
  } = useAsyncEffect({
    fetchData: async () => {
      let notice: ESnapshotExists<ENotice> | null = null;
      if (conversationRequest.notice_id) {
        const [noticeError, noticeSnapshot] = await safeGetOrThrow(
          getFirebaseContext()
            .userNoticesRef()
            .doc(conversationRequest.notice_id)
        );
        if (noticeError) {
          return null;
        }
        notice = noticeSnapshot;
      }

      let invoice: ESnapshotExists<EInvoice> | null = null;
      if (notice?.data()?.invoice) {
        const invoiceResponse = await safeGetOrThrow(notice.data()?.invoice);
        if (invoiceResponse.response) {
          invoice = invoiceResponse.response;
        }
      }

      return { notice, invoice };
    },
    dependencies: [conversationRequest.id, conversationRequest.notice_id]
  });

  return { placementData, isLoading, isError };
};
