import { Form } from 'lib/components/Form';
import { replaceUndefinedWithDelete } from 'lib/helpers';
import { EOrganization, ESnapshotExists } from 'lib/types';
import React from 'react';
import { getFirebaseContext } from 'utils/firebase';
import { SwitchControlledCard } from 'lib/components/Card/SwitchControlledCard';
import {
  CardGridLayout,
  GridInput,
  useEditableCard
} from 'lib/components/Card/Grid';
import LabeledSwitch from 'lib/components/LabeledSwitch';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { useBooleanFlag } from 'utils/flags';

const getBulkInvoicingValues = (
  activeOrganization: ESnapshotExists<EOrganization>,
  parentOrganization: ESnapshotExists<EOrganization> | undefined | null
) => {
  let bulkPaymentEnabled_v2;
  let bulkInvoiceByPubDate;

  const childBulkPaymentEnabled =
    activeOrganization.data().bulkPaymentEnabled_v2;
  const parentBulkPaymentEnabled =
    parentOrganization?.data().bulkPaymentEnabled_v2;
  if (typeof childBulkPaymentEnabled === 'boolean') {
    bulkPaymentEnabled_v2 = childBulkPaymentEnabled;
  } else {
    bulkPaymentEnabled_v2 = !!parentBulkPaymentEnabled;
  }

  const childBulkInvoicingByPubDate =
    activeOrganization.data().bulkInvoiceByPubDate;
  const parentBulkInvoicingByPubDate =
    parentOrganization?.data().bulkInvoiceByPubDate;
  if (typeof childBulkInvoicingByPubDate === 'boolean') {
    bulkInvoiceByPubDate = childBulkInvoicingByPubDate;
  } else {
    bulkInvoiceByPubDate = !!parentBulkInvoicingByPubDate;
  }

  return { bulkPaymentEnabled_v2, bulkInvoiceByPubDate };
};

const getGeneralBillingSettingsFromOrg = (
  organization: ESnapshotExists<EOrganization>,
  bulkPaymentEnabled_v2: boolean | undefined,
  bulkInvoiceByPubDate: boolean | undefined
): {
  requireUpfrontPayment: boolean;
  sendInvoiceReminders: boolean;
  autoInvoice: boolean;
  bulkPaymentEnabled_v2?: boolean;
  bulkInvoiceByPubDate?: boolean;
  autoConfirmOnAutoInvoice: boolean;
} => {
  const {
    requireUpfrontPayment,
    sendInvoiceReminders,
    autoInvoice,
    autoConfirmOnAutoInvoice
  } = organization.data();

  return {
    requireUpfrontPayment: !!requireUpfrontPayment,
    sendInvoiceReminders: !!sendInvoiceReminders,
    autoInvoice: !!autoInvoice,
    bulkPaymentEnabled_v2: !!bulkPaymentEnabled_v2,
    bulkInvoiceByPubDate: !!bulkInvoiceByPubDate,
    autoConfirmOnAutoInvoice: !!autoConfirmOnAutoInvoice
  };
};

type GeneralBillingSettingsProps = {
  activeOrganization: ESnapshotExists<EOrganization>;
  parentOrganization: ESnapshotExists<EOrganization> | undefined | null;
};

export function GeneralBillingSettings(props: GeneralBillingSettingsProps) {
  const { activeOrganization, parentOrganization } = props;
  const { bulkPaymentEnabled_v2, bulkInvoiceByPubDate } =
    getBulkInvoicingValues(activeOrganization, parentOrganization);

  const enableAutoConfirmOnAutoInvoice = useBooleanFlag(
    LaunchDarklyFlags.ENABLE_AUTO_CONFIRM_ON_AUTO_INVOICE
  );

  const currentSettings = getGeneralBillingSettingsFromOrg(
    activeOrganization,
    bulkPaymentEnabled_v2,
    bulkInvoiceByPubDate
  );

  const { onChange, onSubmitWrapper, disabled, formSettings, editable } =
    useEditableCard(currentSettings);

  // Shouldn't be possible, but needed for type narrowing.
  if (!formSettings) {
    return null;
  }

  return (
    <Form
      id="publisher-settings-billings-general"
      onSubmit={() =>
        onSubmitWrapper(async () => {
          // We do not want to update the active organization bulk invoicing enablement if it matches what's inherited from the parent
          let { bulkPaymentEnabled_v2, bulkInvoiceByPubDate } = formSettings;
          const parentBulkPaymentEnabled =
            parentOrganization?.data()?.bulkPaymentEnabled_v2;
          const childBulkPaymentEnabled =
            activeOrganization.data().bulkPaymentEnabled_v2;

          // When bulk invoicing enablement is unset on the child, it's inherited from the parent
          if (childBulkPaymentEnabled === undefined) {
            if (
              !formSettings.bulkPaymentEnabled_v2 &&
              parentBulkPaymentEnabled === false
            ) {
              bulkPaymentEnabled_v2 = undefined;
            }

            if (
              parentBulkPaymentEnabled === true &&
              formSettings.bulkPaymentEnabled_v2
            ) {
              bulkPaymentEnabled_v2 = undefined;
            }
          }

          // We do not want to update the active organization bulk invoicing inclusion criteria if it matches what's inherited from the parent
          const parentBulkInvoicingByPubDate =
            parentOrganization?.data()?.bulkInvoiceByPubDate;
          const childBulkInvoicingByPubDate =
            activeOrganization.data().bulkInvoiceByPubDate;

          // When bulk invoicing inclusion criteria is unset on the child, it's inherited from the parent
          if (childBulkInvoicingByPubDate === undefined) {
            if (
              !formSettings.bulkInvoiceByPubDate &&
              parentBulkInvoicingByPubDate === false
            ) {
              bulkInvoiceByPubDate = undefined;
            }

            if (
              parentBulkInvoicingByPubDate === true &&
              formSettings.bulkInvoiceByPubDate
            ) {
              bulkInvoiceByPubDate = undefined;
            }
          }

          // Save the new settings on the active organization
          await activeOrganization.ref.update(
            replaceUndefinedWithDelete(getFirebaseContext(), {
              ...formSettings,
              bulkPaymentEnabled_v2,
              bulkInvoiceByPubDate
            })
          );
          return { success: true };
        })
      }
      aria-label="General Settings"
    >
      <CardGridLayout
        header={{
          title: 'General Settings',
          description: 'Configure default billing settings.'
        }}
        editable={editable}
      >
        <GridInput fullWidth>
          <LabeledSwitch
            label="Require upfront payment?"
            description="If enabled, invoices will require upfront payment by default."
            value={formSettings.requireUpfrontPayment}
            onChange={requireUpfrontPayment =>
              onChange({ requireUpfrontPayment })
            }
            disabled={disabled}
          />
        </GridInput>
        <GridInput fullWidth>
          <LabeledSwitch
            label="Automatically generate invoices?"
            description="If enabled, invoices will be created automatically upon notice submission for all notices in this publication."
            value={formSettings.autoInvoice}
            onChange={autoInvoice => onChange({ autoInvoice })}
            disabled={disabled}
          />
        </GridInput>
        {enableAutoConfirmOnAutoInvoice && formSettings.autoInvoice && (
          <GridInput fullWidth>
            <LabeledSwitch
              label="Automatically confirm notices?"
              description="If enabled, notices will be auto-confirmed once invoices are successfully created."
              value={formSettings.autoConfirmOnAutoInvoice}
              onChange={autoConfirmOnAutoInvoice =>
                onChange({ autoConfirmOnAutoInvoice })
              }
              disabled={disabled}
            />
          </GridInput>
        )}
        <GridInput fullWidth>
          <LabeledSwitch
            label="Send automatic invoice reminders?"
            description="If enabled, customers with unpaid invoices will receive email notifications every 7 days after their invoice due date."
            value={formSettings.sendInvoiceReminders}
            onChange={sendInvoiceReminders =>
              onChange({ sendInvoiceReminders })
            }
            disabled={disabled}
          />
        </GridInput>
        <SwitchControlledCard
          labelProps={{
            disabled: disabled || formSettings.bulkPaymentEnabled_v2 === false,
            label: 'Allow bulk invoicing?',
            description: 'Allow customers you enable to receive bulk invoices.',
            value: formSettings.bulkPaymentEnabled_v2 ?? false,
            onChange: bulkPaymentEnabled_v2 =>
              onChange({ bulkPaymentEnabled_v2 })
          }}
          header=""
        >
          <GridInput fullWidth>
            <LabeledSwitch
              label="Enable bulk invoicing by last publication date?"
              description="Notices with last publication dates in the last month will be included in the next bulk invoice."
              value={formSettings.bulkInvoiceByPubDate ?? false}
              onChange={bulkInvoiceByPubDate =>
                onChange({ bulkInvoiceByPubDate })
              }
              disabled={disabled}
            />
          </GridInput>
        </SwitchControlledCard>
      </CardGridLayout>
    </Form>
  );
}
