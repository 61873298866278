import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { Popover } from 'lib/components/Popover';
import { dbToUICurrencyString } from 'lib/pricing/ui';
import { ConsolidatedOrderPricing } from 'lib/types/order';
import { LoadingSpinner } from 'lib/components/LoadingSpinner';
import classNames from 'classnames';
import { PricePreviewNewspaperOrders } from './PricePreviewNewspaperOrders';

type PricePreviewProps = {
  consolidatedOrderPricing: ConsolidatedOrderPricing | undefined;
  loading: boolean;
  priceIsStale: boolean;
};

export function PricePreview({
  consolidatedOrderPricing,
  loading,
  priceIsStale
}: PricePreviewProps) {
  const shouldShowDropdownMenu =
    consolidatedOrderPricing !== undefined && !priceIsStale;
  const displayedPrice = dbToUICurrencyString(
    consolidatedOrderPricing?.totalInCents ?? 0
  );

  return (
    <div className="flex items-center justify-between gap-2">
      {loading && <LoadingSpinner />}
      {!loading && (
        <>
          <div
            className={classNames({ 'text-column-yellow-700': priceIsStale })}
          >
            ${displayedPrice}
          </div>
          {shouldShowDropdownMenu && (
            <Popover
              id="price-preview-popover"
              activator={
                <div className="p-2">
                  <ChevronDownIcon className="h-5 w-5 cursor-pointer" />
                </div>
              }
              alignment="right"
            >
              <div
                className="md:w-80 overflow-scroll"
                style={{ maxHeight: '75vh' }}
              >
                <div className="p-6 font-semibold bg-column-primary-25 flex flex-col gap-2">
                  <div className="text-semibold">Order Summary</div>
                  <div className="text-sm text-column-gray-400">
                    Total price $
                    {dbToUICurrencyString(
                      consolidatedOrderPricing.totalInCents
                    )}
                  </div>
                </div>
                <PricePreviewNewspaperOrders
                  newspaperOrderPublishingDataGroup={
                    consolidatedOrderPricing.newspaperOrderPublishingDataGroup
                  }
                />
              </div>
            </Popover>
          )}
        </>
      )}
    </div>
  );
}
