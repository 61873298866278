import { Ad, isObituary } from 'lib/types/ad';
import { EditorEvent, Editor as MceEditor } from 'tinymce';

const PREFILL_TEXT_ID = 'prefill-text';

/**
 * We want to remove the prefill content when the user presses certain keys.
 * This function returns true if the keyboard event should trigger prefill removal.
 */
export const checkEventTriggersPrefillRemoval = (
  event: EditorEvent<KeyboardEvent>
) => {
  const editorEventsTriggeringPrefillRemoval = [
    'Backspace',
    'Enter',
    'Delete',
    'ArrowUp',
    'ArrowDown',
    'ArrowLeft',
    'ArrowRight'
  ];
  return editorEventsTriggeringPrefillRemoval.includes(event.key);
};

/**
 * Removes the Copilot content from the editor, leaving just what a user has typed or
 * accepted via autocomplete.
 */
export const stripCopilotContent = (content: string) => {
  const dom = new DOMParser().parseFromString(content, 'text/html');
  const prefillNode = dom.getElementById(PREFILL_TEXT_ID);
  if (prefillNode) {
    prefillNode.remove();
  }
  return dom.body.innerHTML;
};

/**
 * Creates the prefill content that is displayed in the editor when Copilot is enabled.
 */
export const createPrefillContent = (prefillText: string) => {
  const prefillContent = `<span id="${PREFILL_TEXT_ID}" style="color: gray; user-select: none; cursor: default; contenteditable: false;">${prefillText}</span>`;
  return prefillContent;
};

/**
 * Right now don't suggest prefill content for obituaries until the user has written more than 10 characters.
 * For all other ad types, suggest prefill content immediately.
 */
export const shouldSuggestPrefillContent = (
  adData: Partial<Ad>,
  debouncedUserContent: string
) => {
  const obitMinTextLength = 10;
  if (isObituary(adData)) {
    return debouncedUserContent.length > obitMinTextLength;
  }
  const nonObitMaxTextLength = 20;
  return debouncedUserContent.length <= nonObitMaxTextLength;
};

export const removePrefillElementsFromEditor = (editor: MceEditor) => {
  while (editor?.dom.get(PREFILL_TEXT_ID)) {
    editor?.dom.get(PREFILL_TEXT_ID)?.remove();
  }
};

/**
 * Checks if the editor contains the prefill container.
 */
export const checkEditorContainsPrefillContainer = (editor: MceEditor) => {
  return editor?.dom.get(PREFILL_TEXT_ID);
};

/**
 * Adds the prefill content to the editor, initially empty.
 */
export const addPrefillContentToEditor = (editor: MceEditor) => {
  editor.insertContent(createPrefillContent(''));
  const focusOffset = editor?.selection.getSel()?.focusOffset;
  const currentSelectedNode = editor?.selection.getNode();
  editor.selection.setCursorLocation(
    currentSelectedNode,
    focusOffset ? focusOffset - 1 : 0
  );
};

/**
 * Sets the value of the prefill content in the editor.
 */
export const setPrefillValue = (editor: MceEditor, value: string) => {
  const prefillElement = editor?.dom.get(PREFILL_TEXT_ID);
  if (prefillElement) {
    prefillElement.textContent = value ? `${value} (hit tab to accept)` : '';
  } else {
    editor.insertContent(createPrefillContent(value));
  }
};

/**
 * Accepts the completion and inserts it into the editor.
 */
export const acceptCompletion = (editor: MceEditor, prefillText: string) => {
  editor.insertContent(prefillText);
  removePrefillElementsFromEditor(editor);
};
