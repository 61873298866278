import { SnapshotModel } from '..';
import { Collections } from '../../constants';
import { Media } from '../../types';
import { safeUpdateModel } from '../safeHandlers';

export class MediaModel extends SnapshotModel<Media, typeof Collections.media> {
  get type() {
    return Collections.media;
  }

  async archive() {
    return await safeUpdateModel(this, { isArchived: true });
  }
}
