import React from 'react';
import { ESnapshotExists } from 'lib/types';
import {
  Header,
  PublisherPaginationSettings
} from 'lib/types/publisherPaginationSettings';
import { Form } from 'lib/components/Form';
import {
  CardGridLayout,
  GridInput,
  useEditableCard
} from 'lib/components/Card/Grid';
import { EditableCardArray } from 'lib/components/Card/EditableCardArray';
import { TextField } from 'lib/components/TextField';
import { guidGenerator } from 'utils';

const headerNameToId = (name: string) =>
  name.toLowerCase().replace(/\s+/g, '-');

function HeaderCard({
  value,
  onChange
}: {
  value: Header;
  onChange: (header: Header) => void;
}) {
  return (
    <>
      <GridInput>
        <TextField
          labelText="Name"
          value={value.name}
          onChange={e => onChange({ ...value, name: e })}
          required
          id="header-name"
        />
      </GridInput>
      <GridInput>
        <TextField
          labelText="ID"
          disabled
          value={`${value.id}--${headerNameToId(value.name)}`}
          required
          id="header-id"
        />
      </GridInput>
      <GridInput fullWidth>
        <TextField
          labelText="URL"
          value={value.url}
          onChange={newUrl => onChange({ ...value, url: newUrl })}
          type="url"
          id="header-url"
          required
        />
      </GridInput>
      <GridInput>
        <TextField
          labelText="Width (pixels)"
          value={value.dimensions?.width?.toString()}
          onChange={e =>
            onChange({
              ...value,
              dimensions: {
                ...value.dimensions,
                width: parseInt(e, 10)
              }
            })
          }
          type="number"
          required
          min={1}
          id="header-width"
        />
      </GridInput>
      <GridInput>
        <TextField
          labelText="Height (pixels)"
          value={value.dimensions?.height?.toString()}
          onChange={e =>
            onChange({
              ...value,
              dimensions: {
                ...value.dimensions,
                height: parseInt(e, 10)
              }
            })
          }
          type="number"
          required
          min={1}
          id="header-height"
        />
      </GridInput>
    </>
  );
}

export default function HeaderSettings({
  publisherPaginationSettings
}: {
  publisherPaginationSettings: ESnapshotExists<PublisherPaginationSettings>;
}) {
  const { formSettings, onChange, onSubmitWrapper, editable, disabled } =
    useEditableCard(publisherPaginationSettings.data());

  const saveHeaderSettings = async () => {
    const relevantSettings = {
      headers: formSettings.headers
    };
    console.log(
      'relevantSettings',
      relevantSettings,
      publisherPaginationSettings.id
    );
    try {
      await publisherPaginationSettings.ref.update(relevantSettings);
      return { success: true };
    } catch (e) {
      return { success: false };
    }
  };

  return (
    <Form
      onSubmit={() => onSubmitWrapper(saveHeaderSettings)}
      id="pagination-settings-form"
    >
      <CardGridLayout
        header={{
          title: 'Headers',
          description: 'Configure headers that can be used in notice blocks.'
        }}
        editable={editable}
      >
        <GridInput fullWidth>
          <EditableCardArray
            disabled={disabled}
            columns={1}
            baseHeader="Headers"
            onChange={newHeaders =>
              onChange({ ...formSettings, headers: newHeaders })
            }
            archivable={{
              buttonText: 'Remove'
            }}
            createable={{
              buttonText: 'Add header',
              initialValue: {
                id: guidGenerator().slice(0, 8),
                url: '',
                name: '',
                dimensions: { width: 0, height: 0 }
              }
            }}
            Component={HeaderCard}
            values={formSettings.headers || []}
          />
        </GridInput>
      </CardGridLayout>
    </Form>
  );
}
