// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useState, useEffect } from 'react';
import { ENotice, EOrganization, ESnapshotExists, EUser } from 'lib/types';
import { getFirebaseContext } from 'utils/firebase';
import {
  MANUAL_BUILD_AD_REQUEST,
  MANUAL_CANCEL_BUILD_AD_REQUEST,
  ManualBuildAdRequestEvent,
  ManualCancelBuildAdRequestEvent
} from 'lib/types/events';
import { useFirestoreQueryListener } from 'lib/frontend/hooks/useFirestoreQueryListener';
import { isNoticeAfterPublicationDeadline } from 'lib/utils/deadlines';
import * as affinityXLibHelpers from 'lib/integrations/affinityx/helpers';
import { usePublisherModularSizes } from 'routes/placeScroll/hooks/usePublisherModularSizes';
import { CopyText } from 'lib/components/CopyText';
import { logAndCaptureException } from 'utils';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { ColumnService } from 'lib/services/directory';
import { safeStringify } from 'lib/utils/stringify';
import AffinityXSyncButton from './AffinityXSyncButton';
import AffinityXSyncInputs from './AffinityXSyncInputs';
import {
  AffinityXSyncStatus,
  getAXOrderStatus,
  getMaxAdHeightForTemplate
} from './helpers';
import AffinityXSyncStatusPill from './AffinityXSyncStatusPill';

type AffinityXSyncPanelProps = {
  noticeSnap: ESnapshotExists<ENotice>;
  newspaperSnap: ESnapshotExists<EOrganization>;
  requestingUser: ESnapshotExists<EUser>;
};

function AffinityXSyncPanel({
  noticeSnap,
  newspaperSnap,
  requestingUser
}: AffinityXSyncPanelProps) {
  const [numColumns, setNumColumns] = useState<number | null>(null);
  const [pageCount, setPageCount] = useState<number | null>(null);
  const [approxHeight, setApproxHeight] = useState<number | null>(null);
  const [heightIsValid, setHeightIsValid] = useState(true);
  const [maxHeight, setMaxHeight] = useState<number | null>(null);
  const [noticeIsAfterDeadline, setNoticeIsAfterDeadline] = useState(true);

  const affinityBuildEventsQueryRef =
    affinityXLibHelpers.getLatestAffinityXEventsQuery<ManualBuildAdRequestEvent>(
      getFirebaseContext(),
      { notice: noticeSnap.ref, type: MANUAL_BUILD_AD_REQUEST }
    );
  const affinityBuildEventsQuerySnap =
    useFirestoreQueryListener<ManualBuildAdRequestEvent>(
      affinityBuildEventsQueryRef,
      [noticeSnap.id]
    );
  // The query to get the trigger events calls a descending sort on `createdAt`
  const mostRecentTriggerEvent = affinityBuildEventsQuerySnap?.docs[0];

  const affinityCancelEventsQueryRef =
    affinityXLibHelpers.getLatestAffinityXEventsQuery<ManualCancelBuildAdRequestEvent>(
      getFirebaseContext(),
      { notice: noticeSnap.ref, type: MANUAL_CANCEL_BUILD_AD_REQUEST }
    );
  const affinityCancelEventsQuerySnap =
    useFirestoreQueryListener<ManualCancelBuildAdRequestEvent>(
      affinityCancelEventsQueryRef,
      [noticeSnap.id]
    );

  useEffect(() => {
    const determineWhetherAfterDeadline = async () => {
      const afterDeadline = await isNoticeAfterPublicationDeadline(noticeSnap);
      setNoticeIsAfterDeadline(afterDeadline);
    };

    void determineWhetherAfterDeadline();
  }, [noticeSnap.id]);

  const { isLoading: orderLoading, value: affinityData } = useAsyncEffect({
    fetchData: async () => {
      const affinityXOrderNumber =
        mostRecentTriggerEvent?.data().data.orderNumber;
      if (!affinityXOrderNumber) {
        return {
          orderNumber: null,
          syncStatus: AffinityXSyncStatus.READY_TO_SYNC
        };
      }
      if (affinityCancelEventsQuerySnap?.docs.length) {
        const cancelEvents = affinityCancelEventsQuerySnap.docs;
        const mostRecentTriggerWasCancelled = !!cancelEvents.find(
          cancelEvent =>
            cancelEvent.data().data.initialOrderRequest.id ===
            mostRecentTriggerEvent.id
        );
        if (mostRecentTriggerWasCancelled) {
          return {
            orderNumber: affinityXOrderNumber,
            syncStatus: AffinityXSyncStatus.SYNC_CANCELLED_EDIT_REQUIRED
          };
        }
      }
      const [statusError, status] = await getAXOrderStatus(
        affinityXOrderNumber
      );

      if (statusError) {
        logAndCaptureException(
          ColumnService.AFFINITY_X,
          statusError,
          'Error refreshing AffinityX sync status',
          {
            noticeId: noticeSnap.id
          }
        );
        return {
          orderNumber: affinityXOrderNumber,
          syncStatus: null
        };
      }
      return {
        orderNumber: affinityXOrderNumber,
        syncStatus: status
      };
    },
    dependencies: [
      noticeSnap.id,
      mostRecentTriggerEvent?.id,
      safeStringify(affinityCancelEventsQuerySnap?.docs)
    ]
  });

  const { modularSizes } = usePublisherModularSizes(newspaperSnap.ref ?? null);
  useEffect(() => {
    const setInitialInputValues = async () => {
      const maxHeightForTemplate = await getMaxAdHeightForTemplate(
        noticeSnap.data().adTemplate
      );
      setMaxHeight(maxHeightForTemplate);

      if (!mostRecentTriggerEvent) {
        setApproxHeight(maxHeightForTemplate);

        // Use modular size data to populate the columns and height inputs only when no previous sync event exists
        // This leaves the possibility for a rep to override the modular size data if needed
        // The most recent trigger event will be used to populate the columns and height inputs if it exists (see below)
        if (modularSizes.length) {
          const { height: displayParamsHeight, columns: displayParamsColumns } =
            noticeSnap.data().displayParams || {};
          setApproxHeight(
            Number(displayParamsHeight?.toFixed(1)) || maxHeightForTemplate
          );
          setNumColumns(displayParamsColumns || 1);
        }

        return;
      }

      const {
        numberOfColumns: numColumnsFromTriggerEvt,
        approxHeightInInches: approxHeightFromTriggerEvt
      } = mostRecentTriggerEvent.data().data;

      // Allow the change coming from the sync input component otherwise use data from the most recent trigger event
      setNumColumns(numColumns || numColumnsFromTriggerEvt);
      setApproxHeight(approxHeightFromTriggerEvt || maxHeightForTemplate);
    };

    void setInitialInputValues();
  }, [
    mostRecentTriggerEvent?.id,
    noticeSnap.data().adTemplate.id,
    noticeSnap.data().displayParams?.height,
    noticeSnap.data().displayParams?.columns
  ]);

  return orderLoading ? (
    <div className="mt-8 content-center">
      <p className="text-xl font-bold text-column-gray-700">
        Loading AX order details...
      </p>
    </div>
  ) : (
    <div className="mt-8">
      <div className="flex flex-row justify-between">
        <p className="text-xl font-bold text-column-gray-700">
          Sync to AffinityX
        </p>
        <AffinityXSyncStatusPill syncStatus={affinityData!.syncStatus} />
      </div>
      <div
        id="affinity-x-sync-panel"
        className="bg-primary-25 rounded-md border border-column-gray-150 p-4 my-4"
      >
        {affinityData?.orderNumber && (
          <section className="bg-primary-25 mb-3">
            <div id="ax-order-number" className="text-column-gray-400">
              <CopyText copyText={affinityData.orderNumber || ''}>
                AffinityX Order Number:&nbsp;
                <span
                  className={`${
                    affinityData!.syncStatus ===
                    AffinityXSyncStatus.SYNC_CANCELLED_EDIT_REQUIRED
                      ? 'line-through'
                      : ''
                  }`}
                >
                  {affinityData.orderNumber}
                </span>
              </CopyText>
            </div>
          </section>
        )}
        <AffinityXSyncInputs
          numColumns={numColumns}
          setNumColumns={setNumColumns}
          pageCount={pageCount}
          setPageCount={setPageCount}
          approxHeight={approxHeight}
          maxHeight={maxHeight}
          setApproxHeight={setApproxHeight}
          setHeightIsValid={setHeightIsValid}
          newspaperSnap={newspaperSnap}
          disableInputs={
            affinityData!.syncStatus === AffinityXSyncStatus.SYNC_SUCCESSFUL
          }
        />

        <AffinityXSyncButton
          heightIsValid={heightIsValid}
          noticeSnap={noticeSnap}
          mostRecentTriggerEvent={mostRecentTriggerEvent}
          publicationSnap={newspaperSnap}
          orderNumber={affinityData!.orderNumber}
          syncStatus={affinityData!.syncStatus}
          noticeIsAfterDeadline={noticeIsAfterDeadline}
          reqData={{
            numberOfColumns: numColumns || 0,
            requestingUserId: requestingUser.id,
            approxHeightInInches: approxHeight || 0,
            pageCount: pageCount || 1
          }}
        />
      </div>
    </div>
  );
}

export default AffinityXSyncPanel;
