import {
  BanknotesIcon,
  CalendarIcon,
  ClipboardIcon,
  CurrencyDollarIcon,
  DocumentPlusIcon,
  DocumentTextIcon,
  FolderArrowDownIcon,
  HomeIcon,
  IdentificationIcon,
  MegaphoneIcon,
  NewspaperIcon,
  ReceiptRefundIcon,
  SwatchIcon,
  UserGroupIcon,
  UsersIcon
} from '@heroicons/react/24/outline';
import { RouterTabOption } from 'lib/components/RouterTabs';
import { OrganizationType, State } from 'lib/enums';
import { getModelFromSnapshot } from 'lib/model';
import { UserModel } from 'lib/model/objects/userModel';
import { Permissions } from 'lib/permissions/roles';
import { exists } from 'lib/types';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import {
  selectActiveOrganization,
  selectHasClassifiedsActive,
  selectHasObituariesActive,
  selectUser
} from 'redux/auth';
import { useAppSelector } from 'redux/hooks';
import { ORGANIZATION_SETTINGS_ROUTES } from 'router/routes';
import { getFirebaseContext } from 'utils/firebase';
import { getBooleanFlag } from 'utils/flags';
import { userHasPermission } from 'utils/permissions';

export const useValidOrganizationSettingsTabs = (): RouterTabOption[] => {
  const userSnapshot = useAppSelector(selectUser);
  const activeOrganization = useAppSelector(selectActiveOrganization);
  const hasObituariesActive = useAppSelector(selectHasObituariesActive);
  const hasClassifiedsActive = useAppSelector(selectHasClassifiedsActive);

  if (!exists(userSnapshot) || !exists(activeOrganization)) return [];

  const user = getModelFromSnapshot(
    UserModel,
    getFirebaseContext(),
    userSnapshot
  );

  const universalTabs = [
    {
      path: ORGANIZATION_SETTINGS_ROUTES.GENERAL,
      enabled: userHasPermission(user, Permissions.SETTINGS_ORGANIZATION_INFO),
      label: 'General Info',
      Icon: HomeIcon
    },
    {
      path: ORGANIZATION_SETTINGS_ROUTES.MEMBERS,
      enabled: true,
      label: 'Members',
      Icon: UsersIcon
    }
  ];

  const isHoldingCompany =
    activeOrganization.data().organizationType ===
    OrganizationType.holding_company.value;

  if (isHoldingCompany) {
    return universalTabs;
  }

  if (user.isPublisher) {
    const hasOrderProductPermissions =
      userHasPermission(user, Permissions.SETTINGS_DEADLINES) ||
      userHasPermission(user, Permissions.SETTINGS_RATES) ||
      userHasPermission(user, Permissions.SETTINGS_ORDER_TYPES);

    const publisherTabs: RouterTabOption[] = [
      ...universalTabs,
      {
        path: ORGANIZATION_SETTINGS_ROUTES.CUSTOMERS,
        enabled: userHasPermission(user, Permissions.SETTINGS_CUSTOMERS),
        label: 'Customers',
        Icon: UserGroupIcon
      },
      {
        path: ORGANIZATION_SETTINGS_ROUTES.DEADLINES,
        enabled: userHasPermission(user, Permissions.SETTINGS_DEADLINES),
        label: 'Deadlines',
        Icon: CalendarIcon
      },
      {
        path: ORGANIZATION_SETTINGS_ROUTES.DESIGN_AND_LAYOUT,
        enabled: userHasPermission(
          user,
          Permissions.SETTINGS_DESIGN_AND_LAYOUT
        ),
        label: 'Design and Layout',
        Icon: DocumentPlusIcon
      },
      {
        path: ORGANIZATION_SETTINGS_ROUTES.NOTICE_TYPES,
        enabled:
          getBooleanFlag(LaunchDarklyFlags.ENABLE_NOTICE_TYPE_SETTINGS) &&
          userHasPermission(user, Permissions.SETTINGS_NOTICE_TYPES),
        label: 'Notice Types',
        Icon: DocumentTextIcon
      },
      {
        path: ORGANIZATION_SETTINGS_ROUTES.RATES,
        enabled: userHasPermission(user, Permissions.SETTINGS_RATES),
        label: 'Rates',
        Icon: ReceiptRefundIcon
      },
      {
        path: ORGANIZATION_SETTINGS_ROUTES.BILLING,
        enabled: userHasPermission(user, Permissions.SETTINGS_BILLING),
        label: 'Billing',
        Icon: BanknotesIcon
      },
      {
        path: ORGANIZATION_SETTINGS_ROUTES.BRANDING,
        enabled: userHasPermission(user, Permissions.SETTINGS_CUSTOM_BRANDING),
        label: 'Branding',
        Icon: SwatchIcon
      },
      {
        path: ORGANIZATION_SETTINGS_ROUTES.AFFIDAVITS,
        enabled: userHasPermission(user, Permissions.SETTINGS_AFFIDAVITS),
        label: 'Affidavits',
        Icon: ClipboardIcon
      },
      {
        path: ORGANIZATION_SETTINGS_ROUTES.PAYOUTS,
        enabled: userHasPermission(user, Permissions.SETTINGS_PAYMENT),
        label: 'Payouts',
        Icon: CurrencyDollarIcon
      },
      {
        path: ORGANIZATION_SETTINGS_ROUTES.FTP,
        enabled: !!(
          State.by_value(activeOrganization.data().state)?.showUploads ||
          activeOrganization.data().ftp
        ),
        label: 'FTP',
        Icon: FolderArrowDownIcon
      }
    ];

    if (hasObituariesActive) {
      publisherTabs.push({
        path: ORGANIZATION_SETTINGS_ROUTES.OBITUARIES,
        enabled: hasOrderProductPermissions,
        label: 'Obituaries',
        Icon: IdentificationIcon
      });
    }

    if (hasClassifiedsActive) {
      publisherTabs.push({
        path: ORGANIZATION_SETTINGS_ROUTES.CLASSIFIEDS,
        enabled: hasOrderProductPermissions,
        label: 'Classifieds',
        Icon: MegaphoneIcon
      });
    }

    return publisherTabs;
  }

  const advertiserOrganizationTabs: RouterTabOption[] = [
    ...universalTabs,
    {
      path: ORGANIZATION_SETTINGS_ROUTES.BANK_ACCOUNTS,
      enabled: userHasPermission(user, Permissions.BANK_ACCOUNTS_WRITE),
      label: 'Bank Accounts',
      Icon: CurrencyDollarIcon
    }
  ];

  const isFuneralHome =
    activeOrganization.data().organizationType ===
    OrganizationType.funeral_home.value;

  if (isFuneralHome) {
    advertiserOrganizationTabs.push({
      path: ORGANIZATION_SETTINGS_ROUTES.BRANDING,
      enabled: true,
      label: 'Branding',
      Icon: SwatchIcon
    });
  } else {
    advertiserOrganizationTabs.push({
      path: ORGANIZATION_SETTINGS_ROUTES.PUBLISHER_SETTINGS,
      enabled: true,
      label: 'Publisher Settings',
      Icon: NewspaperIcon
    });
  }

  return advertiserOrganizationTabs;
};
