import { wrapSuccess, wrapError, ResponseOrError } from '../../types/responses';
import { NOTICE_ELEMENT_PREFIX } from '../../pagination/constants';
import { asyncMap } from '../../helpers';
import { UserNoticeModel } from '../../model/objects/userNoticeModel';
import { getModelFromSnapshot } from '../../model';
import { IndesignServerClient } from '../../indesign/request';
import {
  EFirebaseContext,
  ENotice,
  ESnapshotExists,
  ETemplate
} from '../../types';
import { AutoLayoutRequestElement } from '../../types/api';
import { DisplayParams } from '../../types/notice';

const MAX_VARIANT_COLUMNS = 6;
const MIN_OPTIONAL_VARIANT_HEIGHT = 100;

export const variantIsValidForAutoPagination = (
  template: ESnapshotExists<ETemplate>,
  variant: DisplayParams,
  { isDefaultVariant }: { isDefaultVariant: boolean }
) => {
  if (variant.columns > MAX_VARIANT_COLUMNS) {
    return false;
  }

  const variantHeightInPixels =
    (variant.height ?? 0) * (template.data().styles?.pointsPerInch ?? 0);

  const templateHeightInPixels = template.data().styles?.pageHeight ?? 0;

  if (variantHeightInPixels > templateHeightInPixels) {
    return false;
  }

  if (
    !isDefaultVariant &&
    variantHeightInPixels < MIN_OPTIONAL_VARIANT_HEIGHT
  ) {
    return false;
  }

  return true;
};

export const getVariantsForNotices = async (
  firebaseContext: EFirebaseContext,
  notices: ESnapshotExists<ENotice>[],
  autoSolverTemplate: ESnapshotExists<ETemplate>,
  indesignServerClient: IndesignServerClient,
  domParser: typeof DOMParser
): Promise<ResponseOrError<AutoLayoutRequestElement[]>> => {
  const [variantsError, noticeBlocks] = await asyncMap(
    notices,
    async notice => {
      const noticeModel = getModelFromSnapshot(
        UserNoticeModel,
        firebaseContext,
        notice
      );
      const [variantsError, variants] =
        await noticeModel.getDisplayParamVariants(
          indesignServerClient,
          domParser
        );
      if (variantsError) {
        return wrapError(variantsError);
      }

      const validVariants = variants.filter(variant =>
        variantIsValidForAutoPagination(autoSolverTemplate, variant, {
          isDefaultVariant: variant.columns === notice.data().columns
        })
      );
      if (validVariants.length === 0) {
        return wrapError(
          new Error(`No valid variants found for notice ${notice.id}`)
        );
      }

      const noticeBlock = {
        id: `${NOTICE_ELEMENT_PREFIX}-${notice.id}`,
        variants: validVariants.map(variant => ({
          width: variant.columns,
          height: Math.round(
            (variant.height ?? 0) *
              (autoSolverTemplate.data().styles?.pointsPerInch ?? 0)
          )
        })),
        order_type: 'notice'
      };
      return wrapSuccess(noticeBlock);
    }
  );
  if (variantsError) {
    return wrapError(variantsError);
  }

  return wrapSuccess(noticeBlocks);
};
