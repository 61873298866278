import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { Alert } from 'lib/components/Alert';
import { GridInput } from 'lib/components/Card/Grid';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { supportsDisplay } from 'lib/notice/rates';
import { EOrganization, ERate, ESnapshot, ESnapshotExists } from 'lib/types';
import { CustomerObjDataFields } from '../CreateOrEditModalDetails';

type CustomerRateSettingsValue = Pick<
  CustomerObjDataFields,
  'linerRateSnap' | 'displayRateSnap'
>;
type CustomerRateSettingsProps = {
  activeOrganization: ESnapshot<EOrganization> | null;
  onChange: (value: CustomerRateSettingsValue) => void;
  value: CustomerRateSettingsValue;
  rates?: ESnapshotExists<ERate>[];
};

export function CustomerRateSettings({
  activeOrganization,
  onChange,
  value,
  rates = []
}: CustomerRateSettingsProps) {
  const linerRateIsArchived = !!value.linerRateSnap?.data()?.archived;
  const displayRateIsArchived = !!value.displayRateSnap?.data()?.archived;

  const linerRateOptions = rates
    ?.filter(rate => {
      if (value.linerRateSnap?.id === rate.id) return true;
      return !rate.data().archived;
    })
    .map(rate => ({
      value: rate.id,
      label: rate.data()?.description
    }));

  const displayRateOptions = rates
    ?.filter(rate => {
      if (value.displayRateSnap?.id === rate.id) return true;
      const rateSupportsDisplay = supportsDisplay(rate.data());
      const isOrganizationDefaultDisplayRate =
        rate.id === activeOrganization?.data()?.defaultDisplayRate?.id;
      return (
        isOrganizationDefaultDisplayRate ||
        (rateSupportsDisplay && !rate.data().archived)
      );
    })
    .map(rate => ({
      value: rate.id,
      label: rate.data()?.description
    }));

  return (
    <>
      <GridInput fullWidth>
        <Alert
          id="customization-warning"
          status="warning"
          icon={<ExclamationCircleIcon className="w-5 h-5" />}
          description="Customer liner and display rates are set to your organization's default settings. You should only make changes if you want a different rate for this customer."
        />
      </GridInput>
      <GridInput>
        <ColumnSelect
          labelText="Liner rate"
          id="liner_rate"
          value={value.linerRateSnap?.id}
          options={linerRateOptions}
          onChange={newValue => {
            const selectedRate = rates?.find(r => r.id === newValue);
            if (selectedRate) {
              onChange({
                ...value,
                linerRateSnap: selectedRate
              });
            }
          }}
          errorText={
            linerRateIsArchived
              ? 'The selected rate is archived, please select another rate'
              : undefined
          }
        />
      </GridInput>
      <GridInput>
        <ColumnSelect
          labelText="Display rate"
          id="display_rate"
          value={value.displayRateSnap?.id}
          options={displayRateOptions}
          onChange={newValue => {
            const selectedRate = rates?.find(r => r.id === newValue);
            if (selectedRate) {
              onChange({
                ...value,
                displayRateSnap: selectedRate
              });
            }
          }}
          errorText={
            displayRateIsArchived
              ? 'The selected rate is archived, please select another rate'
              : undefined
          }
        />
      </GridInput>
    </>
  );
}
