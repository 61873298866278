import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import LoadingState from 'components/LoadingState';
import { Alert } from 'lib/components/Alert';
import { useFirestoreDocumentListener } from 'lib/frontend/hooks/useFirestoreDocumentListener';
import { useFirestoreQueryListener } from 'lib/frontend/hooks/useFirestoreQueryListener';
import { getModelFromSnapshot } from 'lib/model';
import { OrderModel } from 'lib/model/objects/orderModel';
import { ColumnService } from 'lib/services/directory';
import { NewspaperOrderModel } from 'lib/model/objects/newspaperOrderModel';
import { exists } from 'lib/types';
import { getFirebaseContext } from 'utils/firebase';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import AuthActions, {
  selectAuthLoading,
  selectOrderIdClaims
} from 'redux/auth';
import { apiPost } from 'api/typed';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import useSafeAsyncEffect from 'lib/frontend/hooks/useSafeAsyncEffect';
import { wrapSuccess } from 'lib/types/responses';
import { logAndCaptureCriticalError } from 'utils';
import { useAppParams } from 'lib/frontend/utils/router';
import { OBITUARY_ROUTES } from 'router/routes';
import AdDetailContents from './AdDetailContents';

export default function AdDetailWrapper() {
  const context = getFirebaseContext();
  const dispatch = useAppDispatch();
  const [claim] = useAppSelector(selectOrderIdClaims);
  const queryParams = new URLSearchParams(location.search);
  const accessCode = queryParams.get('accessCode') ?? undefined;
  const authEmail = queryParams.get('authEmail') ?? undefined;

  const { id: orderId } = useAppParams(OBITUARY_ROUTES.DETAIL);

  const { isLoading: authenticatingIndividual, isError: authError } =
    useAsyncEffect({
      fetchData: async () => {
        if (!accessCode || !authEmail) {
          return;
        }
        const accessResult = await apiPost('orders/grant-access', {
          accessCode,
          authEmail,
          forFuneralHomeVerification: false
        });
        if (!accessResult.success) {
          throw Error(accessResult.error);
        }
        const { token } = accessResult;
        dispatch(AuthActions.loginToken(token));
      },
      dependencies: [accessCode, authEmail],
      errorConfig: {
        message: 'Failed to authenticate individual on order details page',
        service: ColumnService.ORDER_MANAGEMENT,
        tags: {
          accessCode: accessCode ?? 'null',
          authEmail: authEmail ?? 'null',
          orderId: orderId ?? 'null'
        }
      }
    });

  const isAuthLoading =
    useAppSelector(selectAuthLoading) ||
    authenticatingIndividual ||
    (!claim && accessCode);

  const orderSnap = useFirestoreDocumentListener(
    orderId && !isAuthLoading ? context.ordersRef().doc(orderId) : undefined
  );

  const orderModel = orderSnap
    ? getModelFromSnapshot(OrderModel, context, orderSnap)
    : null;

  const { value: orderDetail, isError: orderDetailError } = useSafeAsyncEffect({
    fetchData: async () => {
      if (!orderModel) {
        return wrapSuccess(null);
      }
      return await orderModel.getOrderDetail();
    },
    errorConfig: {
      service: ColumnService.ORDER_MANAGEMENT,
      message: 'Error fetching order detail',
      tags: { orderId: orderId ?? 'null' }
    },
    dependencies: [orderModel?.modelData.activeVersion]
  });

  const queryResult = orderModel?.getNewspaperOrdersQuery();

  const newspaperOrders = useFirestoreQueryListener(
    orderModel && !isAuthLoading && queryResult?.response
      ? queryResult.response
      : undefined,
    [isAuthLoading, orderModel?.modelData.activeVersion]
  );

  const newspaperOrderModels = newspaperOrders?.docs.map(doc =>
    getModelFromSnapshot(NewspaperOrderModel, context, doc)
  );

  if (orderDetailError) {
    logAndCaptureCriticalError(
      ColumnService.ORDER_MANAGEMENT,
      new Error('Error fetching order detail'),
      'Error fetching order detail on ad detail page',
      {
        orderId: orderId ?? 'null',
        orderDetail: orderDetail?.id ?? 'null'
      }
    );
    return (
      <Alert
        id="order-detail-alert"
        title="Error fetching order detail"
        status="error"
        icon={<ExclamationCircleIcon className="h-5" />}
      />
    );
  }

  if (authError) {
    return (
      <Alert
        id="order-detail-alert"
        title="Failed to authenticate user to order. Please refresh the page. If the problem persists, please contact support."
        status="error"
        icon={<ExclamationCircleIcon className="h-5" />}
      />
    );
  }
  if (orderSnap === null || isAuthLoading || !orderDetail) {
    return (
      <LoadingState
        context={{
          service: ColumnService.ORDER_MANAGEMENT,
          location: `Ad Detail`,
          tags: {
            orderId: orderModel?.id ?? 'null',
            isAuthLoading: isAuthLoading ? 'true' : 'false',
            adDetail: 'true'
          }
        }}
      />
    );
  }

  if (!exists(orderModel)) {
    return (
      <Alert
        id="order-detail-alert"
        title="Order not found"
        icon={<ExclamationCircleIcon className="h-5" />}
      />
    );
  }

  return (
    <AdDetailContents
      order={orderModel}
      orderDetail={orderDetail}
      anonymousUserAuthInfo={
        authEmail && accessCode ? { authEmail, accessCode } : undefined
      }
      newspaperOrders={newspaperOrderModels ?? []}
    />
  );
}
