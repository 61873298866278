import LoadingState from 'components/LoadingState';
import { ColumnService } from 'lib/services/directory';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { selectAuthLoading, selectUserAuth } from 'redux/auth';
import { useAppSelector } from 'redux/hooks';
import { logAndCaptureException } from 'utils';
import { useRedirectToLanding, useRedirectToLogin } from './redirect/hooks';

export function UnknownRoute() {
  const { pathname } = useLocation();
  const redirectToLanding = useRedirectToLanding();
  const redirectToLogin = useRedirectToLogin();
  const auth = useAppSelector(selectUserAuth);
  const authLoading = useAppSelector(selectAuthLoading);

  useEffect(() => {
    // Capture visits to unknown routes
    logAndCaptureException(
      ColumnService.FRONTEND_ROUTING,
      new Error('Page not found'),
      'Unknown page requested',
      { pathname }
    );
  }, [pathname]);

  useEffect(() => {
    if (authLoading) {
      return;
    }

    // Once auth is loaded, redirect to the landing page or login page
    if (auth) {
      redirectToLanding();
    } else {
      redirectToLogin();
    }
  }, [auth, authLoading]);

  return (
    <LoadingState
      context={{ location: pathname, service: ColumnService.FRONTEND_ROUTING }}
    />
  );
}
