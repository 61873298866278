export default function RegisterIndividualHeader() {
  return (
    <div className="my-6">
      <h1 className="text-center text-column-gray-900 font-semibold leading-8 text-2xl">
        Tell us a bit about you
      </h1>
      <p className="text-center p-3 text-column-gray-400 font-medium leading-6">
        Just one more step to create your account.
      </p>
    </div>
  );
}
