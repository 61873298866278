import React from 'react';
import { ClassifiedLocation, JobsClassified } from 'lib/types/classified';
import { GridInput } from 'lib/components/Card/Grid';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { TextField } from 'lib/components/TextField';
import { State } from 'lib/enums';
import { jobIndustries } from 'lib/orders/classifieds';

type JobsExtraFieldsProps<T extends JobsClassified> = {
  adData: Partial<T>;
  onChangeAd: (ad: Partial<T>) => void;
};

function JobsExtraFields<T extends JobsClassified>({
  onChangeAd,
  adData
}: JobsExtraFieldsProps<T>) {
  const onChangeLocationField = <
    LocationField extends keyof ClassifiedLocation
  >(
    locationField: LocationField,
    locationValue: ClassifiedLocation[LocationField]
  ) => {
    onChangeAd({
      ...adData,
      location: {
        ...adData.location,
        [locationField]: locationValue
      } as ClassifiedLocation
    });
  };
  return (
    <div className="flex flex-col gap-3">
      <div>
        <h3 className="text-column-gray-500 font-semibold text-sm">
          Additional Information
        </h3>
        <div className="text-column-gray-400 text-sm">
          Tell us more about the role
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-3 w-full">
        <GridInput>
          <TextField
            id={`extra-info-employer-name-input`}
            value={adData.employerName || ''}
            placeholder="Company LLC"
            labelText="Employer name"
            type="text"
            size="small"
            onChange={employerName => onChangeAd({ ...adData, employerName })}
            required
            validationMessages={{
              valueMissing: 'A employer name is required'
            }}
          />
        </GridInput>
        <GridInput>
          <TextField
            id={`extra-info-job-title-input`}
            value={adData.title || ''}
            placeholder="Sales Representative"
            labelText="Job title"
            type="text"
            size="small"
            onChange={title => onChangeAd({ ...adData, title })}
            required
            validationMessages={{
              valueMissing: 'A job title is required'
            }}
          />
        </GridInput>
        <GridInput>
          <TextField
            id={`extra-info-city-input`}
            value={adData.location?.city || ''}
            placeholder="City"
            labelText="City"
            type="text"
            size="small"
            onChange={newValue => onChangeLocationField('city', newValue)}
            required
            validationMessages={{
              valueMissing: 'City is required'
            }}
          />
        </GridInput>
        <GridInput>
          <ColumnSelect
            id={`extra-info-state-input`}
            value={adData.location?.state?.toString() || ''}
            placeholder="State"
            labelText="State"
            allowUndefined
            size="small"
            options={State.items().map(s => ({
              label: s.label,
              value: s.value.toString()
            }))}
            onChange={newState =>
              onChangeLocationField('state', parseInt(newState, 10))
            }
            required
            validationMessages={{
              valueMissing: 'State is required'
            }}
          />
        </GridInput>
        <GridInput>
          <TextField
            id={`extra-info-zip-input`}
            value={adData.location?.zipCode || ''}
            placeholder="Zip code"
            labelText="Zip code"
            type="postal-code"
            size="small"
            onChange={newValue => onChangeLocationField('zipCode', newValue)}
            required
          />
        </GridInput>
        <GridInput>
          <ColumnSelect
            id="select-category-type"
            value={adData.category}
            labelText="Category"
            size="small"
            onChange={category => onChangeAd({ ...adData, category })}
            options={jobIndustries}
            placeholder="Mechanic, Sales, Design, etc."
            required
          />
        </GridInput>
        <GridInput>
          <TextField
            id={`extra-info-link-input`}
            value={adData.linkToApplicationSite || ''}
            placeholder="https://www.example.com"
            labelText="Link to application page"
            type="text"
            size="small"
            onChange={linkToApplicationSite =>
              onChangeAd({ ...adData, linkToApplicationSite })
            }
          />
        </GridInput>
      </div>
    </div>
  );
}

export default JobsExtraFields;
