import React, { useState } from 'react';
import TabGroup, { TabOption } from 'lib/components/Tabs';
import classNames from 'classnames';
import LinkTo from 'components/LinkTo';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import { InvoiceStatus } from 'lib/enums';
import { isColumnUser } from 'lib/helpers';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { selectUser } from 'redux/auth';
import { SearchableExpressEmailConversationRecord } from 'lib/types/searchable';
import { ColumnButton } from 'lib/components/ColumnButton';
import ToastActions from 'redux/toast';
import api from 'api';
import { safeGetOrThrow } from 'lib/safeWrappers';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { usePlacementRequestData } from './usePlacementRequestData';

function DetailsRow({
  label,
  value,
  isLastRow
}: {
  label: React.ReactNode;
  value: React.ReactNode;
  isLastRow: boolean;
}) {
  return (
    <div
      className={classNames(`flex w-full px-4 py-3.5 justify-between`, {
        'border-column-gray-50 border-b': !isLastRow
      })}
    >
      <div className="text-column-gray-400">{label}</div>
      <div className="text-column-gray-500" id={`${label}`}>
        {value ?? '--'}
      </div>
    </div>
  );
}

function DetailsTab({
  conversationRequest
}: {
  conversationRequest: SearchableExpressEmailConversationRecord;
}) {
  const { placementData } = usePlacementRequestData(conversationRequest);
  const [refreshingData, setRefreshingData] = useState(false);

  const { value: publisher } = useAsyncEffect({
    fetchData: async () => {
      const notice = placementData?.notice;
      if (!notice) return null;
      const [publisherError, publisherSnapshot] = await safeGetOrThrow(
        notice.data().newspaper
      );
      if (publisherError) return null;
      return publisherSnapshot;
    },
    dependencies: [placementData?.notice?.id]
  });

  const notice = placementData?.notice;
  const invoice = placementData?.invoice;
  const affidavitUrl = notice?.data()?.affidavitURL;
  const noticeUrl = notice?.data()?.jpgURL;
  const user = useAppSelector(selectUser);
  const dispatch = useAppDispatch();

  const refreshConversationData = async () => {
    setRefreshingData(true);
    try {
      const { error } = await api.safePost('conversation-requests/refresh', {
        conversationId: conversationRequest.id
      });
      if (error) {
        dispatch(
          ToastActions.toastError({
            headerText: `Failed to refresh conversation data`,
            bodyText: `${error}`
          })
        );
      } else {
        dispatch(
          ToastActions.toastSuccess({
            headerText: `Conversation data refreshed`,
            bodyText: `Refresh the page to see the updated data`
          })
        );
      }
    } catch (err) {
      dispatch(
        ToastActions.toastError({
          headerText: `Unexpected error refreshing conversation data`,
          bodyText: `Please try again later. Reach out to support if the issue persists.`
        })
      );
    } finally {
      setRefreshingData(false);
    }
  };

  return (
    <div>
      {noticeUrl && (
        <div className="w-full bg-column-gray-50 p-6">
          <img src={noticeUrl} className="w-full h-80 object-contain" />
        </div>
      )}
      {publisher && (
        <DetailsRow
          label="Publisher"
          value={publisher.data()?.name}
          isLastRow={false}
        />
      )}
      <DetailsRow
        label="Custom ID"
        value={conversationRequest.custom_id}
        isLastRow={false}
      />
      {conversationRequest.external_id && (
        <DetailsRow
          label="External ID"
          value={conversationRequest.external_id}
          isLastRow={false}
        />
      )}
      {placementData?.notice && (
        <>
          <DetailsRow
            label="Notice ID"
            value={
              <LinkTo
                href={`/notice/${placementData.notice.id}`}
                className="text-column-primary-500 underline"
                target="_blank"
              >
                <div className="flex items-center gap-2">
                  <span className="text-column-primary-500">{notice?.id}</span>
                  <ArrowTopRightOnSquareIcon className="w-4 h-4" />
                </div>
              </LinkTo>
            }
            isLastRow={false}
          />
          <DetailsRow
            label="Publication Dates"
            value={notice
              ?.data()
              ?.publicationDates?.map(timestamp =>
                moment(timestamp.toDate()).format('MM/DD/YYYY')
              )
              .join(', ')}
            isLastRow={false}
          />
          <DetailsRow
            label="Affidavit"
            value={
              affidavitUrl ? (
                <div className="flex items-center gap-2 text-column-primary-500 underline">
                  <a
                    href={affidavitUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View Affidavit
                  </a>
                  <ArrowTopRightOnSquareIcon className="w-4 h-4" />
                </div>
              ) : (
                'None'
              )
            }
            isLastRow
          />
          <DetailsRow
            label="Invoice Status"
            value={
              invoice?.data()?.status
                ? InvoiceStatus.by_value(invoice?.data()?.status)?.label
                : 'Not invoiced yet'
            }
            isLastRow
          />
        </>
      )}
      {user && isColumnUser(user) && (
        <>
          <DetailsRow
            label="(Column Only) Conversation ID"
            value={
              <div className="flex items-center gap-2 text-column-primary-500 underline">
                <a
                  href={`https://app.frontapp.com/open/${conversationRequest.conversation_id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {conversationRequest.conversation_id}
                </a>
                <ArrowTopRightOnSquareIcon className="w-4 h-4" />
              </div>
            }
            isLastRow
          />
          <div className="p-4">
            <ColumnButton
              buttonText="(Column Only) Refresh conversation data"
              onClick={refreshConversationData}
              id="refresh-conversation-data"
              type="button"
              fullWidth
              loading={refreshingData}
            />
          </div>
        </>
      )}
    </div>
  );
}
export default function ConversationRequestDrawer({
  conversationRequest
}: {
  conversationRequest: SearchableExpressEmailConversationRecord;
}) {
  const tabs = [
    {
      label: 'Details',
      enabled: true,
      id: 'details'
    }
  ];
  const [activeTab, setActiveTab] = useState<TabOption>(tabs[0]);

  return (
    <div>
      <TabGroup
        tabs={tabs}
        activeTab={activeTab}
        onClickTab={tab => setActiveTab(tab)}
        id="conversation-request-drawer-tabs"
      />
      {activeTab.id === 'details' && (
        <DetailsTab conversationRequest={conversationRequest} />
      )}
    </div>
  );
}
