import moment from 'moment';
import classNames from 'classnames';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { CalendarIcon } from '@heroicons/react/24/outline';
import { TextField } from 'lib/components/TextField';

interface ColumnDatePickerProps {
  id?: string;
  className?: string;
  format: string;
  momentFormat: string;
  placeholderText: string;
  errorText?: string;
  value: Date | string | undefined;
  onChange: (date: Date | undefined) => void;

  shouldDisableDate?: (date: MaterialUiPickersDate) => boolean;
  fullWidth?: boolean;
  disabled?: boolean;
}

export default function ColumnDatePicker({
  id,
  className,
  format,
  momentFormat,
  placeholderText,
  value,
  onChange,
  shouldDisableDate,
  disabled,
  errorText,
  fullWidth
}: ColumnDatePickerProps) {
  const dateValue = typeof value === 'string' ? moment(value).toDate() : value;

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DatePicker
        label=""
        value={dateValue}
        format={format}
        className={'p-2'}
        disabled={disabled}
        TextFieldComponent={props => (
          <div
            className={classNames(
              {
                'w-full': fullWidth
              },
              className ?? ''
            )}
            onClick={e => props.onClick!(e)}
          >
            <TextField
              id={id || ''}
              labelText={undefined}
              disabled={disabled}
              placeholder={placeholderText}
              value={value ? moment(value).format(momentFormat) : ''}
              errorText={errorText}
              suffix={
                <CalendarIcon
                  className="w-5 h-5 text-column-gray-400"
                  strokeWidth="2"
                  stroke="currentColor"
                />
              }
            />
          </div>
        )}
        InputProps={{
          disableUnderline: true,
          className: ''
        }}
        autoOk
        onChange={newDate => {
          if (newDate) {
            onChange(new Date(newDate.toISOString()));
          } else {
            onChange(undefined);
          }
        }}
        shouldDisableDate={date => {
          if (shouldDisableDate) {
            return shouldDisableDate(date);
          }

          return false;
        }}
      />
    </MuiPickersUtilsProvider>
  );
}
