import hash from 'object-hash';
import { wrapSuccess, wrapError, ResponseOrError } from '../../types/responses';
import { NOTICE_ELEMENT_PREFIX } from '../../pagination/constants';
import { safeGetOrThrow } from '../../safeWrappers';
import { BaseService } from '../baseService';
import { PublisherPaginationSettings } from '../../types/publisherPaginationSettings';
import {
  EFirebaseContext,
  ENotice,
  EOrganization,
  ERequestTypes,
  ESnapshotExists,
  ETemplateStyles
} from '../../types';
import { PublicationIssueModel } from '../../model/objects/publicationIssueModel';
import {
  AutoPaginateOrder,
  AutoPaginationResponseBlock,
  OptimizerHyperparams,
  OptimizerResponse
} from '../../types/api';
import { asyncMap, cdnIfy, guidGenerator } from '../../helpers';
import {
  IndesignServerClient,
  requestRawDocument
} from '../../indesign/request';
import { getVariantsForNotices } from './variants';
import { Spacer } from '../../enums';
import {
  templateStylesToPixelSizes,
  buildColumnLayoutElements,
  ExcalidrawElement
} from './excalidrawUtils';
import {
  LEE_DEFAULT_PAGINATION_LAYOUTS,
  LEE_DEFAULT_PAGINATION_FILLERS,
  LEE_DEFAULT_PAGINATION_HEADERS
} from './defaultValues';
import { getLayoutsForAutoPaginationRequest } from './layouts';

type PaginationElement = {
  x: number;
  y: number;
  width: number;
  height: number;
  url: string;
  id: string;
};

type SavedStateElement = {
  blockNumber: number;
  elements: ExcalidrawElement[];
  binaryFiles: Record<
    string,
    {
      mimeType: string;
      id: string;
      dataURL: string;
    }
  >;
};

const DPI_SCALE_FACTOR = 4;

export const DEFAULT_OPTIMIZER_HYPERPARAMS: OptimizerHyperparams = {
  enable_split_points: false,
  enable_page_splitting: false,
  min_element_margin: 4,
  max_time_seconds: 2000,
  max_whitespace: 350
};

export class PublisherPaginationSettingsService extends BaseService<PublisherPaginationSettings> {
  constructor(ctx: EFirebaseContext) {
    super(ctx, ctx.publisherPaginationSettingssRef());
  }

  async getOrCreateDefault(publisher: ESnapshotExists<EOrganization>) {
    const existing = await this.ctx
      .publisherPaginationSettingssRef()
      .where('owner', '==', publisher.ref)
      .get();
    if (!existing.empty) return wrapSuccess(existing.docs[0]);

    const newPublisherPaginationSettings: PublisherPaginationSettings = {
      allowedPublishers: [],
      owner: publisher.ref,
      adTemplate: publisher.data().adTemplate!,
      enableAdSplitting: false,
      flowText: false,
      headers: LEE_DEFAULT_PAGINATION_HEADERS,
      allowedLayouts: LEE_DEFAULT_PAGINATION_LAYOUTS,
      fillers: LEE_DEFAULT_PAGINATION_FILLERS
    };
    try {
      const newPublisherPaginationSettingsRef = await this.ctx
        .publisherPaginationSettingssRef()
        .add(newPublisherPaginationSettings);
      return safeGetOrThrow(newPublisherPaginationSettingsRef);
    } catch (error) {
      return wrapError(error as Error);
    }
  }

  async getAutoPaginationRequestForNotices(
    publisherPaginationSettings: ESnapshotExists<PublisherPaginationSettings>,
    notices: ESnapshotExists<ENotice>[],
    hyperparams: OptimizerHyperparams,
    indesignServerClient: IndesignServerClient,
    domParser: typeof DOMParser
  ): Promise<
    ResponseOrError<
      Omit<ERequestTypes['pagination/auto-paginate'], 'hyperparams'>
    >
  > {
    const [templateError, autoSolverTemplate] = await safeGetOrThrow(
      publisherPaginationSettings.data().adTemplate
    );
    const styles = autoSolverTemplate?.data().styles;
    if (templateError || !styles) {
      return wrapError(templateError || new Error('Template not found'));
    }

    const [variantsError, noticeBlocks] = await getVariantsForNotices(
      this.ctx,
      notices,
      autoSolverTemplate,
      indesignServerClient,
      domParser
    );
    if (variantsError) {
      return wrapError(variantsError);
    }

    const [layoutsError, layouts] = await getLayoutsForAutoPaginationRequest(
      publisherPaginationSettings,
      hyperparams,
      autoSolverTemplate,
      noticeBlocks
    );
    if (layoutsError) {
      return wrapError(layoutsError);
    }

    return wrapSuccess({
      layouts,
      orders: noticeBlocks as AutoPaginateOrder[]
    });
  }

  async getAutoPaginationRequestForPublicationIssue(
    publicationIssue: PublicationIssueModel,
    hyperparams: OptimizerHyperparams,
    indesignServerClient: IndesignServerClient,
    domParser: typeof DOMParser
  ) {
    const [publisherError, publisher] = await safeGetOrThrow(
      publicationIssue.modelData.publisher
    );
    if (publisherError || !publisher)
      return wrapError(publisherError || new Error('Publisher not found'));

    const [settingsError, settings] = await this.getOrCreateDefault(publisher);
    if (settingsError) return wrapError(settingsError);
    const [noticesError, notices] =
      await publicationIssue.slowGetNoticesForPublicationIssue();
    if (noticesError) return wrapError(noticesError);
    return await this.getAutoPaginationRequestForNotices(
      settings,
      notices,
      hyperparams,
      indesignServerClient,
      domParser
    );
  }

  async runAutoLayout(
    publicationIssue: PublicationIssueModel,
    hyperparams: OptimizerHyperparams,
    optimizer: (
      request: ERequestTypes['pagination/auto-paginate']
    ) => Promise<ResponseOrError<OptimizerResponse>>,
    indesignServerClient: IndesignServerClient,
    domParser: typeof DOMParser
  ) {
    const [configError, autoGenerateRequest] =
      await this.getAutoPaginationRequestForPublicationIssue(
        publicationIssue,
        hyperparams,
        indesignServerClient,
        domParser
      );
    if (configError) {
      return wrapError(configError);
    }
    const { response: blocks, error: paginationError } = await optimizer({
      ...autoGenerateRequest,
      hyperparams
    });
    if (paginationError) return wrapError(paginationError);
    return wrapSuccess(blocks);
  }

  async generateBlockUrl(
    publicationIssue: PublicationIssueModel,
    block: AutoPaginationResponseBlock,
    layoutEngine: (
      request: ERequestTypes['pagination/download-block']
    ) => Promise<ResponseOrError<string>>
  ) {
    const [publisherError, publisher] = await safeGetOrThrow(
      publicationIssue.modelData.publisher
    );
    if (publisherError || !publisher)
      return wrapError(publisherError || new Error('Publisher not found'));
    const [templateError, templateSnapshot] = await safeGetOrThrow(
      publisher.data().adTemplate
    );
    if (templateError) {
      return wrapError(templateError);
    }

    const templateStyles = templateSnapshot.data().styles;
    if (!templateStyles) {
      return wrapError(
        new Error('Failed to load style properties from publisher template')
      );
    }
    const { gutterWidth, colWidth } =
      templateStylesToPixelSizes(templateStyles);

    const downloadableElements = block.elements.map(elt => {
      const width = elt.width * colWidth + (elt.width - 1) * gutterWidth;
      const x = elt.x * colWidth + elt.x * gutterWidth;
      return {
        id: elt.id,
        columnWidth: elt.width,
        height: elt.height / DPI_SCALE_FACTOR,
        width: width / DPI_SCALE_FACTOR,
        x: x / DPI_SCALE_FACTOR,
        y: elt.y / DPI_SCALE_FACTOR
      };
    });

    return await layoutEngine({
      fileName: `${publicationIssue.id}-${guidGenerator().slice(0, 5)}jpg`,
      newspaperId: publisher.id,
      elements: downloadableElements
    });
  }

  getPublicationIssueStoragePath(publicationIssue: PublicationIssueModel) {
    return `documentcloud/publicationIssues/${publicationIssue.id}.json`;
  }

  async getOrCreateSavedStateForPublicationIs(
    publicationIssue: PublicationIssueModel,
    storageClient: {
      readState: (
        storagePath: string
      ) => Promise<ResponseOrError<SavedStateElement[]>>;
    }
  ) {
    const [existingStateError, existingState] = await storageClient.readState(
      this.getPublicationIssueStoragePath(publicationIssue)
    );
    if (existingStateError) {
      return wrapError(existingStateError);
    }
    if (existingState?.length) {
      return wrapSuccess(existingState);
    }
    return wrapSuccess([]);
  }

  async savePaginationBlockForPublicationIssue(
    publicationIssue: PublicationIssueModel,
    savedState: SavedStateElement[],
    storageClient: {
      writeState: (
        storagePath: string,
        state: SavedStateElement[]
      ) => Promise<ResponseOrError<void>>;
    }
  ) {
    const storagePath = this.getPublicationIssueStoragePath(publicationIssue);
    return storageClient.writeState(storagePath, savedState);
  }

  async getUrlFromElement(
    { id, width }: { id: string; width: number },
    indesignServerClient: IndesignServerClient,
    domParser: typeof DOMParser,
    fillers: { id: string; pdfUrl: string }[]
  ) {
    for (const filler of fillers) {
      if (id.includes(filler.id)) {
        return wrapSuccess(filler.pdfUrl);
      }
    }
    if (id.includes(Spacer.notice.skinny.prefix)) {
      return wrapSuccess(Spacer.notice.skinny.url);
    }
    if (id.includes(Spacer.notice.thick.prefix)) {
      return wrapSuccess(Spacer.notice.thick.url);
    }
    if (id.includes(Spacer.notice.super_skinny.prefix)) {
      return wrapSuccess(Spacer.notice.super_skinny.url);
    }
    if (id.includes(NOTICE_ELEMENT_PREFIX)) {
      const noticeId = id.split(NOTICE_ELEMENT_PREFIX)[1].split('-').pop();
      if (!noticeId) {
        return wrapError(new Error('No notice id found'));
      }
      const [noticeError, notice] = await safeGetOrThrow(
        this.ctx.userNoticesRef().doc(noticeId)
      );
      if (noticeError || !notice) {
        return wrapError(noticeError);
      }
      const splitFactor = width / notice.data().columns;
      if (splitFactor === 1) {
        return wrapSuccess(
          cdnIfy(notice.data().jpgStoragePath ?? '', {
            useColumnCDN: true,
            cloudinaryTransformations: 'w_200'
          })
        );
      }
      const encodedDocResponse = await requestRawDocument(
        this.ctx,
        indesignServerClient,
        notice,
        domParser,
        ['jpg'],
        {
          columns: notice.data().columns * splitFactor,
          textColumnCount: splitFactor,
          quality: 'low'
        }
      );
      const base64 = Buffer.from(encodedDocResponse.data).toString('base64');

      return wrapSuccess(`data:image/jpeg;base64,${base64}`);
    }

    throw new Error(`Unknown element id: ${id}`);
  }

  maybeAddElementToExcalidrawBlock(
    block: SavedStateElement,
    paginationElement: PaginationElement
  ) {
    const fileId = hash(paginationElement.url);

    const newElement = {
      id: paginationElement.id,
      type: 'image',
      x: paginationElement.x,
      y: paginationElement.y,
      width: paginationElement.width,
      height: paginationElement.height,
      fileId,
      scale: [1, 1],
      angle: 0,
      strokeColor: '#1e1e1e',
      backgroundColor: 'transparent',
      fillStyle: 'hachure',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      groupIds: [],
      frameId: null,
      roundness: null,
      isDeleted: false,
      boundElements: null,
      updated: Date.now(),
      link: null,
      locked: false
    };

    const existingIndex = block.elements.findIndex(
      element => element.id === paginationElement.id
    );
    if (existingIndex !== -1) {
      // eslint-disable-next-line no-param-reassign
      block.elements[existingIndex] = newElement;
    } else {
      block.elements.push(newElement);
    }

    const newBinaryFiles = block.binaryFiles;
    newBinaryFiles[fileId] = {
      mimeType: 'image/jpeg',
      id: fileId,
      dataURL: paginationElement.url
    };
    // eslint-disable-next-line no-param-reassign
    block.binaryFiles = newBinaryFiles;
  }

  async addAutoLayoutBlocksToSavedState(
    savedState: SavedStateElement[],
    autoLayoutBlocks: PaginationElement[][],
    styles: ETemplateStyles
  ) {
    for (const [idx] of autoLayoutBlocks.entries()) {
      let relevantSavedStateBlock = savedState.find(
        block => block.blockNumber === idx + 1
      );
      if (!relevantSavedStateBlock) {
        relevantSavedStateBlock = {
          blockNumber: idx + 1,
          elements: buildColumnLayoutElements(styles),
          binaryFiles: {}
        };
        savedState.push(relevantSavedStateBlock);
      }
      for (const block of autoLayoutBlocks[idx]) {
        this.maybeAddElementToExcalidrawBlock(relevantSavedStateBlock, block);
      }
    }
    return wrapSuccess(savedState);
  }

  async cacheAutoLayoutResults(
    publicationIssue: PublicationIssueModel,
    blocks: OptimizerResponse,
    storageClient: {
      readState: (
        storagePath: string
      ) => Promise<ResponseOrError<SavedStateElement[]>>;
      writeState: (
        storagePath: string,
        state: SavedStateElement[]
      ) => Promise<ResponseOrError<void>>;
    },
    indesignServerClient: IndesignServerClient,
    domParser: typeof DOMParser,
    fillers: { id: string; pdfUrl: string }[]
  ) {
    const [savedStateError, savedState] =
      await this.getOrCreateSavedStateForPublicationIs(
        publicationIssue,
        storageClient
      );
    if (savedStateError) {
      return wrapError(savedStateError);
    }

    const [publisherError, publisher] = await safeGetOrThrow(
      publicationIssue.modelData.publisher
    );
    if (publisherError || !publisher)
      return wrapError(publisherError || new Error('Publisher not found'));

    const [settingsError, settings] = await this.getOrCreateDefault(publisher);
    if (settingsError) {
      return wrapError(settingsError);
    }

    const [adTemplateError, adTemplate] = await safeGetOrThrow(
      settings.data().adTemplate
    );
    if (adTemplateError) {
      return wrapError(adTemplateError);
    }
    const { styles } = adTemplate.data();
    if (!styles) {
      return wrapError(new Error('No styles found'));
    }

    const { colWidth, gutterWidth } = templateStylesToPixelSizes(styles);

    const [urlError, blockElementsWithUrls] = await asyncMap(
      blocks,
      async block => {
        const [urlExtractionError, elementsWithUrls] = await asyncMap(
          block.elements,
          async element => {
            const [urlError, url] = await this.getUrlFromElement(
              element,
              indesignServerClient,
              domParser,
              fillers
            );
            if (urlError) {
              return wrapError(urlError);
            }

            const x = element.x * (colWidth + gutterWidth);
            const width =
              element.width * colWidth + (element.width - 1) * gutterWidth;

            return wrapSuccess({
              ...element,
              x,
              width,
              url
            });
          }
        );
        if (urlExtractionError) {
          return wrapError(urlExtractionError);
        }
        return wrapSuccess(elementsWithUrls);
      }
    );

    if (urlError) {
      return wrapError(urlError);
    }

    const [addError, newSavedState] =
      await this.addAutoLayoutBlocksToSavedState(
        savedState,
        blockElementsWithUrls,
        styles
      );
    if (addError) {
      return wrapError(addError);
    }

    const [saveError] = await this.savePaginationBlockForPublicationIssue(
      publicationIssue,
      newSavedState,
      storageClient
    );
    if (saveError) {
      return wrapError(saveError);
    }

    return wrapSuccess(newSavedState);
  }

  async getAdditionalURLs(publisher: ESnapshotExists<EOrganization>) {
    const [settingsError, settings] = await this.getOrCreateDefault(publisher);
    if (settingsError) {
      return wrapError(settingsError);
    }
    const additionalUrls = [
      ...settings.data().fillers,
      ...settings.data().headers.map(h => ({
        id: h.id,
        pdfUrl: h.url
      }))
    ];
    return wrapSuccess(additionalUrls);
  }
}
