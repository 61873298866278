import api from 'api';
import { InviteStatus, OccupationType } from 'lib/enums';
import {
  EUser,
  ESnapshotExists,
  EInvite,
  EFirebaseContext,
  EResponseTypes
} from 'lib/types';
import {
  getOpenInvitesAssociatedWithEmail,
  awaitAllPromises
} from 'lib/helpers';

/**
 * Determine if an organization with the given name and type already exists.
 */
export const organizationAlreadyExists = async (
  organizationType: number,
  name: string
): Promise<boolean> => {
  const { exists }: EResponseTypes['organizations/:organizationType/exists'] =
    await api.get(`organizations/${organizationType}/exists`, { name });

  return exists;
};

export const isAdvertiserOrganization = (occupation: number) =>
  occupation === OccupationType.lawyer.value ||
  occupation === OccupationType.other_organization.value ||
  occupation === OccupationType.government_official.value;

/**
 * Update status of anonymous users pending invites to accepted and update the user to non-anonymous
 */
export const updatePendingInviteStatusForAnonUsers = async (
  ctx: EFirebaseContext,
  userSnap: ESnapshotExists<EUser>
) => {
  const pendingInvites: ESnapshotExists<EInvite>[] =
    await getOpenInvitesAssociatedWithEmail(ctx, userSnap.data().email);
  if (pendingInvites.length) {
    await awaitAllPromises(
      pendingInvites.map(async pendingInvite => {
        if (!pendingInvite.data().organizationId && userSnap.data().anonymous) {
          await pendingInvite.ref.update({
            status: InviteStatus.accepted.value
          });
        }
      })
    );
  }

  if (userSnap.data().anonymous) {
    await userSnap.ref.update({
      anonymous: false
    });
  }
};
