import { Product } from 'lib/enums';
import Header from 'components/Header';
import { selectOrderIdClaims } from 'redux/auth';
import { useAppSelector } from 'redux/hooks';
import { OBITUARY_ROUTES } from 'router/routes';
import { useAppParams } from 'lib/frontend/utils/router';
import OrderEditFlow from './OrderEditFlow';
import { AdFormStatusProvider } from './contexts/AdFormStatusProvider';

export default function EditOrderContainer() {
  const { orderId } = useAppParams(OBITUARY_ROUTES.EDIT);

  const orderIdClaims = useAppSelector(selectOrderIdClaims);
  const [claim] = orderIdClaims;

  const segments = location.pathname.split('/');
  const product =
    segments[1] === 'obituaries' ? Product.Obituary : Product.Classified;

  return (
    <AdFormStatusProvider>
      <Header productLine={Product.Obituary} />
      <OrderEditFlow claim={claim} orderId={orderId} product={product} />
    </AdFormStatusProvider>
  );
}
