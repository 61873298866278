import React, { useState } from 'react';
import { CurrencyDollarIcon } from '@heroicons/react/24/outline';

import { TextField, TextFieldProps } from '../TextField';

import { centsToExtendedCurrency, currencyToCents } from '../../utils/rates';

type CurrencyTextFieldProps = {
  initialValue: number | undefined;
  onChange: (newValue: number) => void;
} & Pick<
  TextFieldProps,
  | 'id'
  | 'placeholder'
  | 'disabled'
  | 'labelText'
  | 'step'
  | 'required'
  | 'max'
  | 'errorText'
  | 'size'
  | 'validationMessages'
>;

/**
 * TextField component specifically for use with money. This component expects an initialValue
 * of cents and will convert it to a currency string for display. It will also convert the value
 * back to cents when the user changes the value and pass it back through the onChange function.
 *
 * TODO: Improve clarity of props in cents (e.g. initialValue, onChange) vs. dollars (e.g. max)
 * or make it uniform across the component.
 */
export default function CurrencyTextField({
  initialValue,
  onChange,
  id,
  placeholder,
  disabled,
  labelText,
  step = 0.0001,
  required,
  errorText,
  max,
  size,
  validationMessages
}: CurrencyTextFieldProps) {
  const [value, setValue] = useState(
    initialValue ? centsToExtendedCurrency(initialValue) : undefined
  );

  return (
    <TextField
      id={id}
      labelText={labelText}
      placeholder={placeholder ?? '0.00'}
      disabled={disabled}
      type="number"
      value={value}
      onChange={newValue => {
        setValue(newValue);
        onChange(currencyToCents(newValue));
      }}
      prefix={<CurrencyDollarIcon className="w-5 h-5 text-column-gray-400" />}
      min={0}
      step={step}
      required={required ?? false}
      errorText={errorText}
      max={max}
      size={size}
      validationMessages={validationMessages}
    />
  );
}
