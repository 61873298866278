import { InformationCircleIcon } from '@heroicons/react/24/outline';

import {
  AdditionalFee,
  isFlatAdditionalFee,
  isPercentAdditionalFee
} from 'lib/types/rates';
import { Alert } from 'lib/components/Alert';
import { ColumnSelect, ColumnSelectOption } from 'lib/components/ColumnSelect';
import { TextField } from 'lib/components/TextField';

import { GridInput } from 'lib/components/Card/Grid';
import { EditableCardProps } from 'lib/components/Card/EditableCardWrapper';
import CurrencyTextField from 'lib/components/CurrencyTextField';
import { Product } from 'lib/enums';
import { useLocation } from 'react-router-dom';
import { ORGANIZATION_SETTINGS_ROUTES } from 'router/routes';
import {
  ChargeMethod,
  getAdditionalFeeChargeMethod,
  getAdditionalFeeChargeMethodSelectOption,
  isValidChargeMethod
} from '../../../ratesUtils';

const getProductFromPathname = (pathname: string) => {
  if (pathname === ORGANIZATION_SETTINGS_ROUTES.CLASSIFIEDS) {
    return Product.Classified;
  }

  if (pathname === ORGANIZATION_SETTINGS_ROUTES.OBITUARIES) {
    return Product.Obituary;
  }

  return Product.Notice;
};

const getFlatAdditionalFeeChargeOptions = (
  product: Product | null
): ColumnSelectOption<ChargeMethod>[] => {
  const options = [
    getAdditionalFeeChargeMethodSelectOption('once'),
    getAdditionalFeeChargeMethodSelectOption('perRun')
  ];

  if (product === Product.Notice) {
    options.push(
      getAdditionalFeeChargeMethodSelectOption('perMailedAffidavit')
    );
  }

  return options;
};

export default function AdditionalFeeElement({
  onChange,
  value,
  index
}: EditableCardProps<AdditionalFee>) {
  const { pathname } = useLocation();
  const product = getProductFromPathname(pathname);
  const additionalFee = value;

  const isOffsetFee =
    isFlatAdditionalFee(additionalFee) && additionalFee.isOffsetFee;

  return (
    <>
      {isOffsetFee && (
        <GridInput fullWidth>
          <Alert
            id="offset-fee-warning"
            title={`This rate uses a "flat fee with open rate structure". This fee is used to charge a starting price for this rate; you can change the description here and amount below under Advanced Settings.`}
            icon={<InformationCircleIcon className="w-5 h-5" />}
            status="warning"
          />
        </GridInput>
      )}

      <GridInput fullWidth={isOffsetFee}>
        <TextField
          id={`additional-fee-description-${index}`}
          labelText="Fee description (this will appear on invoices)"
          type="text"
          onChange={description => onChange({ ...additionalFee, description })}
          value={additionalFee.description}
        />
      </GridInput>
      {/**
       * Currently, we are disallowing percent additional fees on rates,
       * though this option is currently supported on the back end due to
       * changes made to cross-paper fees. When we decide how we want percent
       * fees on rates and on papers to interact, this feature can be reintroduced
       * simply by uncommenting the block below.
       */}
      {/* <GridInput>
        <ColumnSelect
          id={`additional-fee-type-${index}`}
          labelText="How should this fee be calculated?"
          options={[
            getAdditionalFeeTypeSelectionOption('flat'),
            getAdditionalFeeTypeSelectionOption('percent')
          ]}
          value={additionalFee.type}
          onChange={newValue => {
            const newFeeType = newValue as AdditionalFee['type'];
            updateObject(
              index,
              getResetAdditionalFeeData(additionalFee, newFeeType)
            );
          }}
        />
      </GridInput> */}
      {isFlatAdditionalFee(additionalFee) && !isOffsetFee && (
        <>
          <GridInput>
            <ColumnSelect
              id={`additional-fee-charge-method-${index}`}
              labelText="How do you charge this fee?"
              onChange={value => {
                if (!isValidChargeMethod(value)) {
                  return;
                }

                if (value === 'once') {
                  onChange({
                    ...additionalFee,
                    perRun: false,
                    perAffidavitFee: false
                  });
                } else if (value === 'perRun') {
                  onChange({
                    ...additionalFee,
                    perRun: true,
                    perAffidavitFee: false
                  });
                } else if (value === 'perMailedAffidavit') {
                  onChange({
                    ...additionalFee,
                    perRun: false,
                    perAffidavitFee: true
                  });
                }
              }}
              value={getAdditionalFeeChargeMethod(additionalFee)}
              options={getFlatAdditionalFeeChargeOptions(product)}
            />
          </GridInput>
          <GridInput>
            <CurrencyTextField
              id={`additional-fee-amount-${index}`}
              labelText="Fee amount"
              initialValue={additionalFee.amount}
              onChange={amount =>
                onChange({
                  ...additionalFee,
                  amount
                })
              }
            />
          </GridInput>
        </>
      )}
      {isPercentAdditionalFee(additionalFee) && (
        <GridInput>
          <TextField
            id={`additional-fee-percentage-${index}`}
            labelText="Set fee percentage"
            type="number"
            step="0.5"
            value={`${additionalFee.feePercentage}`}
            suffix="%"
            onChange={feePercentage =>
              onChange({
                ...additionalFee,
                feePercentage: parseFloat(feePercentage)
              })
            }
          />
        </GridInput>
      )}
    </>
  );
}
