import {
  ECollectionRef,
  EFirebaseContext,
  ETransaction,
  ERef,
  EQuery
} from '../types';
import {
  ResponseOrColumnError,
  wrapError,
  wrapSuccess
} from '../types/responses';
import { TimestampedModel } from '../model/types';
import { getErrorReporter } from '../utils/errors';
import { ColumnService } from './directory';
import {
  InternalServerError,
  wrapErrorAsColumnError
} from '../errors/ColumnErrors';
import { safeAsync } from '../safeWrappers';
import {
  runInFirestoreTransaction,
  runQueryWithOptionalTransaction
} from '../utils/firebase';

/**
 * Not sure how this will evolve but I don't want to have all implementations sending undefined
 * and I don't want to make transaction an optional prop so here we are.  Please adapt as needed.
 */
type BaseServiceOptions<T> = {
  transaction?: ETransaction;
  /**
   * Used to filter the results of a query
   * e.g. filter out deleted records so we don't error due to duplicates or return them
   */
  filter?: (item: T) => boolean;
};

/**
 * Because we are supporting transactions in BaseService, we are not able to return
 * models in these functions as the transaction must be committed before a model can
 * be returned.
 */
export abstract class BaseService<T extends TimestampedModel> {
  constructor(
    protected ctx: EFirebaseContext,
    private collection: ECollectionRef<T>
  ) {}

  async create(
    data: T,
    options: BaseServiceOptions<T> = {}
  ): Promise<ResponseOrColumnError<ERef<T>>> {
    const { transaction } = options;
    const createdAt = this.ctx.fieldValue().serverTimestamp();

    const objectWithTimestamp = {
      ...data,
      createdAt,
      modifiedAt: null // Initially set to null, can be updated later
    };

    const collectionRef = this.collection;
    const docRef = collectionRef.doc();

    const [setError] = await safeAsync(async () =>
      transaction
        ? transaction.set(docRef, objectWithTimestamp)
        : await docRef.set(objectWithTimestamp)
    )();
    if (setError) {
      getErrorReporter().logAndCaptureError(
        ColumnService.DATABASE,
        setError,
        'Error setting document in collection'
      );
      return wrapErrorAsColumnError(setError, InternalServerError);
    }
    return wrapSuccess(docRef);
  }

  /**
   * Get a record by a field that is unique in the collection
   */
  async getUniqueRecordByField(
    field: Extract<keyof T, string>,
    value: T[Extract<keyof T, string>],
    options: BaseServiceOptions<T> = {}
  ): Promise<ResponseOrColumnError<ERef<T> | null>> {
    const { transaction, filter } = options;
    const collectionRef = this.collection;
    const query: EQuery<T> = collectionRef.where(field, '==', value);

    const [getError, docResults] = await runQueryWithOptionalTransaction(
      query,
      transaction
    );

    if (getError) {
      getErrorReporter().logAndCaptureError(
        ColumnService.DATABASE,
        getError,
        'Error getting document by field'
      );
      return wrapErrorAsColumnError(getError, InternalServerError);
    }

    if (docResults.empty) {
      getErrorReporter().logInfo(
        `No document found with field ${field} = ${value} in the database.`
      );
      return wrapSuccess(null);
    }

    const filteredDocs = filter
      ? docResults.docs.filter(doc => filter(doc.data()))
      : docResults.docs;

    if (filteredDocs.length === 0) {
      getErrorReporter().logInfo(
        `No documents with field ${field} = ${value} passed the filter criteria.`
      );
      return wrapSuccess(null);
    }

    if (filteredDocs.length > 1) {
      const err = new Error(
        `Expected 1 document with field ${field} = ${value}, found ${
          filteredDocs.length
        } ${filter ? 'after applying filter' : ''}.`
      );
      getErrorReporter().logAndCaptureError(
        ColumnService.DATABASE,
        err,
        'Error: Multiple documents found when one was expected.'
      );
      return wrapErrorAsColumnError(err, InternalServerError);
    }

    return wrapSuccess(filteredDocs[0].ref);
  }

  private async getOrCreateUniqueWithTransaction(
    field: Extract<keyof T, string>,
    value: T[Extract<keyof T, string>],
    data: T,
    transaction: ETransaction,
    filter?: (item: T) => boolean
  ): Promise<ResponseOrColumnError<{ record: ERef<T>; isNew: boolean }>> {
    const [getError, getRecord] = await this.getUniqueRecordByField(
      field,
      value,
      { transaction, filter }
    );
    if (getError) {
      return wrapError(getError);
    }

    if (getRecord) {
      return wrapSuccess({ record: getRecord, isNew: false });
    }

    const [createError, createRecord] = await this.create(data, {
      transaction
    });
    if (createError) {
      return wrapError(createError);
    }

    return wrapSuccess({ record: createRecord, isNew: true });
  }

  /**
   * Gets or creates a record with a unique field
   * Adds an isNew flag to the response to indicate if the record was created
   * Leverages a firestore transaction to ensure the check & the creation are atomic
   */
  async getOrCreateRecordByUniqueField(
    field: Extract<keyof T, string>,
    value: T[Extract<keyof T, string>],
    data: T,
    options: BaseServiceOptions<T> = {}
  ): Promise<ResponseOrColumnError<{ record: ERef<T>; isNew: boolean }>> {
    const { transaction, filter } = options;
    const [transactionError, result] = await runInFirestoreTransaction(
      this.ctx,
      transaction,
      async transaction =>
        this.getOrCreateUniqueWithTransaction(
          field,
          value,
          data,
          transaction,
          filter
        )
    );

    if (transactionError) {
      getErrorReporter().logAndCaptureError(
        ColumnService.DATABASE,
        transactionError,
        'Error getting or creating document by field'
      );
      return wrapErrorAsColumnError(transactionError);
    }

    return wrapSuccess(result);
  }
}
