import React from 'react';

import LoadingState from 'components/LoadingState';
import { exists } from 'lib/types';
import { selectActiveOrganization } from 'redux/auth';
import { useAppSelector } from 'redux/hooks';
import AffidavitTable from './AffidavitTable';

export default function AffidavitSettingsWrapper() {
  const activeOrganization = useAppSelector(selectActiveOrganization);

  if (!exists(activeOrganization)) {
    return <LoadingState />;
  }

  return <AffidavitTable activeOrganization={activeOrganization} />;
}
