import React from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { exists } from 'lib/types';
import AuthActions, {
  selectActiveOrganization,
  selectAvailableOrganizations,
  selectIsPublisher,
  selectUser
} from 'redux/auth';
import { OccupationType } from 'lib/enums';
import {
  processActiveOrganizationChange,
  reloadAppState
} from 'redux/auth/authActions';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { MAIN_ROUTES } from 'router/routes';
import OrganizationSelect from './OrganizationSelect';

export function ActiveOrganizationSelect() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const isPublisher = useAppSelector(selectIsPublisher);

  const user = useAppSelector(selectUser);
  const displayCreateJoinOrganizationTab =
    user?.data().occupation !== OccupationType.publishing.value;

  const availableOrganizations =
    useAppSelector(selectAvailableOrganizations) ?? [];
  const activeOrganization = useAppSelector(selectActiveOrganization);
  const value = exists(activeOrganization) ? activeOrganization : undefined;

  const popoverHeader = isPublisher
    ? 'Switch Publications'
    : 'Switch Organizations';

  /**
   * Enables all organization notices to show
   */
  const onAllOrgsClicked = async () => {
    dispatch(AuthActions.showAllOrgsNotices(true));
    dispatch(reloadAppState(navigate, searchParams));
  };

  const onCreateOrgClicked = () => {
    navigate(MAIN_ROUTES.ADD_ORGANIZATION);
  };

  return (
    <OrganizationSelect
      popoverHeader={popoverHeader}
      options={availableOrganizations}
      value={value}
      onChange={newOrg => {
        dispatch(
          processActiveOrganizationChange(newOrg, navigate, searchParams)
        );
      }}
      showAllOrgsOption
      onAllOrgsClicked={onAllOrgsClicked}
      showCreateOrgOption={displayCreateJoinOrganizationTab}
      onCreateOrgClicked={onCreateOrgClicked}
    />
  );
}
