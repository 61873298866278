import { useParams } from 'react-router-dom';

/**
 * Given a route string, this type will extract any dynamic parameters, allowing
 * us to add type safety to helper functions that work with dynamic routes.
 *
 * For example, given the route `/notices/:noticeId`, this type will extract
 * `{ noticeId: string }`.
 */
type ExtractParams<Path> = Path extends `${infer Segment}/${infer Rest}`
  ? ExtractParam<Segment, ExtractParams<Rest>>
  : ExtractParam<Path, Record<string, any>>;
type ExtractParam<Path, OtherParams> = Path extends `:${infer Param}?`
  ? Record<Param, string | undefined> & OtherParams
  : Path extends `:${infer Param}`
  ? Record<Param, string> & OtherParams
  : OtherParams;

/**
 * Helper function to generate a route, replacing dynamic parameters with
 * values from the provided `params` object (e.g., turn `/notices/:noticeId`
 * into `/notices/123`). Uses inferred types from the desired route to add
 * type safety.
 */
export const getDynamicRoute = <
  R extends string,
  P extends Record<string, any> = ExtractParams<R>
>(
  route: R,
  params: P,
  searchParams?: Record<string, string>
): string => {
  const parts = route.split('/');

  const baseUrl = parts
    .map(part => {
      if (!part.startsWith(':')) {
        return part;
      }

      let allowUndefined = false;
      let paramName = part.slice(1);

      if (paramName.endsWith('?')) {
        allowUndefined = true;
        paramName = paramName.slice(0, -1);
      }

      const value = params[paramName];

      if (value === undefined && !allowUndefined) {
        throw new Error(`Missing parameter ${paramName} for route ${route}`);
      }

      return value ?? '';
    })
    .join('/');

  if (!searchParams) {
    return baseUrl;
  }

  return `${baseUrl}?${new URLSearchParams(searchParams).toString()}`;
};

/**
 * Hook to extract dynamic parameters from the current route (e.g. grab
 * `noticeId` from `/notices/:noticeId`). Uses inferred types from the
 * expected route to add type safety.
 */
export const useAppParams = <
  R extends string,
  P extends Record<string, any> = ExtractParams<R>
>(
  route: R
): P => {
  const params = useParams();
  const parts = route.split('/');

  return parts.reduce<P>((acc, part) => {
    if (!part.startsWith(':')) {
      return acc;
    }

    let allowUndefined = false;
    let paramName = part.slice(1);

    if (paramName.endsWith('?')) {
      allowUndefined = true;
      paramName = paramName.slice(0, -1);
    }

    const value = params[paramName];

    if (value === undefined && !allowUndefined) {
      throw new Error(`Missing parameter ${paramName} for route ${route}`);
    }

    return { ...acc, [paramName]: value };
  }, {} as P);
};
