import { State } from 'lib/enums';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { TextField } from 'lib/components/TextField';
import { RegisterOrganizationAddress } from './createOrganization';

type RegisterOrganizationAddressFormProps = {
  address: RegisterOrganizationAddress;
  handleInputChanged: (input: RegisterOrganizationAddress) => void;
};

/**
 * Common form elements between all organization registration flows.
 */
export default function RegisterOrganizationAddressForm({
  address,
  handleInputChanged
}: RegisterOrganizationAddressFormProps) {
  return (
    <div className="space-y-4">
      <TextField
        id="name"
        required
        value={address.name}
        onChange={name =>
          handleInputChanged({
            ...address,
            name
          })
        }
        placeholder="Name of Organization"
        labelText="Name of organization"
      />
      <TextField
        id="phone"
        required
        type="tel"
        value={address.phone}
        placeholder="Phone"
        onChange={phone => handleInputChanged({ ...address, phone })}
        labelText="Phone number"
      />
      <TextField
        id="email"
        required
        type={'email'}
        value={address.email}
        onChange={email => handleInputChanged({ ...address, email })}
        placeholder="Email"
        labelText="Organization contact email"
      />
      <TextField
        id="address-line-1"
        required
        value={address.addressLine1}
        onChange={addressLine1 =>
          handleInputChanged({ ...address, addressLine1 })
        }
        placeholder="Address line 1"
        labelText="Address line 1"
      />
      <TextField
        id="address-line-2"
        value={address.addressLine2 || ''}
        onChange={addressLine2 =>
          handleInputChanged({ ...address, addressLine2 })
        }
        placeholder="Address line 2"
        labelText="Address line 2"
      />
      <TextField
        id="city"
        required
        value={address.city}
        onChange={city => handleInputChanged({ ...address, city })}
        placeholder="City"
        labelText="City"
      />
      <ColumnSelect
        options={State.items().map(item => ({
          value: item.value.toString(),
          label: item.label
        }))}
        value={address.state?.toString()}
        allowUndefined
        onChange={state =>
          handleInputChanged({ ...address, state: Number(state) })
        }
        id="state"
        labelText="State"
        required
      />
      <TextField
        id="zipCode"
        required
        type="postal-code"
        value={address.zipCode}
        placeholder="Zip Code"
        onChange={zipCode => handleInputChanged({ ...address, zipCode })}
        labelText="Zip Code"
      />
    </div>
  );
}
