import { createBrowserRouter } from 'react-router-dom';
import { appRoutes } from './appRoutes';
import { authRoutes } from './authRoutes';
import { onboardingRoutes } from './onboardingRoutes';
import { RouteLogger } from './RouteLogger';
import { UnknownRoute } from './UnknownRoute';
import { standaloneRoutes } from './standaloneRoutes';

export const router = createBrowserRouter([
  {
    element: <RouteLogger />,
    children: [appRoutes, authRoutes, onboardingRoutes, standaloneRoutes],
    errorElement: <UnknownRoute />
  }
]);
