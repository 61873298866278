import { DeadlineSettings } from 'lib/types/deadlines';
import { EOrganization, ESnapshotExists } from 'lib/types';
import { Product } from 'lib/enums';
import { PublishingSettingModel } from 'lib/model/objects/publishingSettingModel';
import React from 'react';
import { ScheduleChanges } from './ScheduleChanges';

type ProductScheduleChangesProps = {
  activeOrganization: ESnapshotExists<EOrganization>;
  productLine: Product;
  publishingSetting: PublishingSettingModel;
};

function ProductScheduleChanges({
  activeOrganization,
  productLine,
  publishingSetting
}: ProductScheduleChangesProps) {
  const [deadlineOverrides, setDeadlineOverrides] = React.useState(
    publishingSetting.modelData.deadlineOverrides
  );

  const handleDeadlineOverrideChange = async (
    newOverrides: Record<string, DeadlineSettings>
  ) => {
    await publishingSetting.ref.update({
      deadlineOverrides: newOverrides
    });
    setDeadlineOverrides(newOverrides);
  };

  return (
    <ScheduleChanges
      productLine={productLine}
      activeOrganization={activeOrganization}
      onChange={handleDeadlineOverrideChange}
      deadlineOverrides={deadlineOverrides}
      deadlines={publishingSetting.modelData.deadlines}
    />
  );
}

export default ProductScheduleChanges;
