import { ESnapshotExists, EUser } from 'lib/types';
import { getModelFromSnapshot } from 'lib/model';
import { UserModel } from 'lib/model/objects/userModel';
import { getFirebaseContext } from 'utils/firebase';
import { OccupationType } from 'lib/enums';
import { useLocation } from 'react-router-dom';
import { MAIN_ROUTES } from 'router/routes';

export const useDetermineShowPaymentsTab = (
  user: ESnapshotExists<EUser> | null
) => {
  const { pathname } = useLocation();
  const userModel = user
    ? getModelFromSnapshot(UserModel, getFirebaseContext(), user)
    : null;

  const isOnInvoicesRoute = pathname.startsWith(MAIN_ROUTES.PAYMENTS);

  return (
    isOnInvoicesRoute ||
    !userModel?.isOccupationType(OccupationType.funeral_director)
  );
};
