import {
  FilingType,
  isNoticeFilingType,
  isOrderFilingType
} from 'lib/types/filingType';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { AdRate, EOrganization, ESnapshotExists } from 'lib/types';
import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';
import LabeledSwitch from 'lib/components/LabeledSwitch';
import { Product } from 'lib/enums';
import { PRODUCT_TO_NAME } from 'lib/enums/Product';
import { Alert } from 'lib/components/Alert';
import { useState } from 'react';
import { ClassifiedFilingTypeNames } from 'lib/types/classified';
import { isOrderRate } from 'lib/types/rates';
import { logAndCaptureException } from 'utils';
import { ColumnService } from 'lib/services/directory';
import { InheritableSettingSelectInput } from '../../InheritableSettingSelectInput';

const DEFAULT_RATE_DESCRIPTION =
  'Default - use organization default rate or customer specific rates';

/**
 * Converts a set of rates into a set of options for a select input
 */
const ratesToRateSelectOptions = (
  rates: ESnapshotExists<AdRate>[],
  includeDefaultOption: boolean
) => {
  const rateOptions = includeDefaultOption
    ? [
        {
          label: DEFAULT_RATE_DESCRIPTION,
          value: 'default'
        }
      ]
    : [];
  rates.forEach(rate => {
    const label = rate.data().archived
      ? `${rate.data().description} (archived)`
      : rate.data().description;
    rateOptions.push({
      value: rate.id,
      label
    });
  });
  return rateOptions;
};

type BillingSettingsCardProps<T extends FilingType> = {
  onUpdateFilingType: (newValue: T) => void;
  activeOrganization: ESnapshotExists<EOrganization>;
  updatedFilingType: T;
  rates: ESnapshotExists<AdRate>[];
  productLine: Product;
};

export default function BillingSettingsCard<T extends FilingType>({
  onUpdateFilingType,
  activeOrganization,
  updatedFilingType,
  productLine,
  rates
}: BillingSettingsCardProps<T>) {
  const [rateError, setRateError] = useState<string>('');

  const getValidationErrorForRateAssignment = (
    filingType: FilingType,
    rate: ESnapshotExists<AdRate>
  ): string | null => {
    const rateData = rate.data();
    if (!isOrderRate(rateData)) {
      return null;
    }
    if (
      rateData.jobcaseFee?.enabled &&
      filingType.label !== ClassifiedFilingTypeNames.Jobs
    ) {
      return `Rates with Jobcase fees can only be assigned to '${ClassifiedFilingTypeNames.Jobs}' filing types.`;
    }
    return '';
  };

  const handleRateChange = (newValue: string) => {
    setRateError('');
    const newFilingType = { ...updatedFilingType };
    if (newValue === 'default') {
      delete newFilingType.rate;
      onUpdateFilingType(newFilingType);
      return;
    }
    const rate = rates.find(r => r.id === newValue);
    if (!rate) {
      logAndCaptureException(
        ColumnService.SETTINGS_MANAGEMENT,
        new Error('Rate not found!'),
        'Failed to update filing type rate',
        {
          organizationId: activeOrganization.id
        }
      );
      setRateError('Rate not found!  Please refresh the page and try again.');
      return;
    }
    const userError = getValidationErrorForRateAssignment(
      updatedFilingType,
      rate
    );
    if (userError) {
      setRateError(`Rate is not valid for this filing type: ${userError}`);
      return;
    }
    newFilingType.rate = rate.ref;
    onUpdateFilingType(newFilingType);
  };

  return (
    <CardGridLayout
      header={{
        title: 'Billing Settings',
        description: `Decide how to charge customers for this ${PRODUCT_TO_NAME[
          productLine
        ].singular.toLowerCase()} type.`
      }}
    >
      <GridInput fullWidth>
        {rateError && (
          <Alert id="rate-error" status="error" title={rateError} />
        )}
        <ColumnSelect
          id="filing-type-rate-select"
          labelText="Default rate"
          options={ratesToRateSelectOptions(
            rates,
            isNoticeFilingType(updatedFilingType)
          )}
          placeholder={DEFAULT_RATE_DESCRIPTION}
          value={updatedFilingType.rate?.id}
          onChange={newValue => handleRateChange(newValue)}
          allowUndefined
          required={productLine !== Product.Notice}
        />
      </GridInput>
      {isOrderFilingType(updatedFilingType) && (
        <GridInput fullWidth>
          <ColumnSelect
            id={'default-rate-for-display-layouts'}
            labelText={'Default rate for display layouts'}
            options={ratesToRateSelectOptions(rates, true)}
            placeholder={DEFAULT_RATE_DESCRIPTION}
            value={updatedFilingType.rateConfiguration?.defaultDisplayRate?.id}
            onChange={newValue => {
              const newFilingType = { ...updatedFilingType };
              newFilingType.rateConfiguration = {
                ...newFilingType.rateConfiguration,
                defaultDisplayRate:
                  newValue !== 'default'
                    ? rates.find(r => r.id === newValue)?.ref
                    : null
              };
              onUpdateFilingType(newFilingType);
            }}
            allowUndefined
          />
        </GridInput>
      )}
      {isNoticeFilingType(updatedFilingType) && (
        <>
          <GridInput fullWidth>
            <LabeledSwitch
              label="Require upfront payment?"
              description="If enabled, notices of this type will require upfront payment unless turned off for a customer or a specific notice."
              value={updatedFilingType.upFrontPayment || false}
              onChange={newValue =>
                onUpdateFilingType({
                  ...updatedFilingType,
                  upFrontPayment: newValue
                })
              }
            />
          </GridInput>
          <GridInput fullWidth>
            <LabeledSwitch
              label="Automatically generate invoices?"
              description="If enabled, invoices will be created automatically upon notice submission."
              value={updatedFilingType.autoInvoice || false}
              onChange={newValue =>
                onUpdateFilingType({
                  ...updatedFilingType,
                  autoInvoice: newValue
                })
              }
            />
          </GridInput>
          {activeOrganization.data().allowInvoiceOutsideColumn && (
            <GridInput fullWidth>
              <InheritableSettingSelectInput
                id="invoice-outside-column"
                labelText="Enable invoicing outside Column?"
                optionText={{
                  yesLabel: `Yes — publisher will bill and collect payment outside of Column`,
                  noLabel: `No — create invoices and collect payment inside Column`,
                  defaultLabel:
                    'Default — use default newspaper or customer settings'
                }}
                value={updatedFilingType.invoiceOutsideColumn}
                onChange={newValue => {
                  const newFilingType = { ...updatedFilingType };
                  if (newValue === undefined) {
                    delete newFilingType.invoiceOutsideColumn;
                  } else {
                    newFilingType.invoiceOutsideColumn = newValue;
                  }
                  onUpdateFilingType(newFilingType);
                }}
              />
            </GridInput>
          )}
        </>
      )}
    </CardGridLayout>
  );
}
