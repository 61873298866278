import classNames from 'classnames';
import { MediaModel } from 'lib/model/objects/mediaModel';
import { LayoutModel } from 'lib/model/objects/layoutModel';
import { OrderImage } from 'lib/orders/images';
import { Layout } from 'lib/types/layout';
import ImageEditorOrUploader from './ImageEditorOrUploader';

type NewOrderImageEditorProps = {
  position: 'top' | 'bottom';
  orderImages: (OrderImage | null)[];
  mediaList: MediaModel[] | null;
  insertImageAtIndex: (index: number, newOrderImage: OrderImage) => void;
  removeImageAtIndex: (index: number) => void;
  layout: Layout;
  disableEditing?: boolean;
};
export function NewOrderImageEditor({
  position,
  orderImages,
  mediaList,
  insertImageAtIndex,
  removeImageAtIndex,
  layout,
  disableEditing
}: NewOrderImageEditorProps) {
  const localOrderImages = orderImages;

  const layoutModel = new LayoutModel(layout);
  const { imageConfig } = layoutModel;
  const activeImages =
    imageConfig?.map(image => {
      if (image.position === position) {
        return image;
      }

      return null;
    }) || [];

  if (activeImages.filter(Boolean).length === 0) {
    return null;
  }

  return (
    <div
      className={classNames({
        'flex gap-4': layout.photoAlignment === 'center'
      })}
    >
      {activeImages.map((image, index) => {
        if (!image) {
          return null;
        }

        const currentImage = localOrderImages[index];

        return (
          <div
            key={index}
            style={{
              width: layout.photoWidth === 'none' ? '100%' : layout.photoWidth,
              aspectRatio: currentImage ? layout.aspectRatio : ''
            }}
            className={classNames({
              'inline-block relative z-10 bg-white float-right border-dashed border-b border-l p-2':
                layout.photoAlignment !== 'center'
            })}
          >
            <ImageEditorOrUploader
              orderImage={currentImage}
              mediaList={mediaList}
              layout={layout}
              onDeleteOrderImage={() => removeImageAtIndex(index)}
              onUpdateOrderImage={newOrderImage =>
                insertImageAtIndex(index, newOrderImage)
              }
              disabled={disableEditing}
            />
          </div>
        );
      })}
    </div>
  );
}
