import React, { useEffect, useState } from 'react';
import { Typography, Box } from '@material-ui/core';
import InputMask from 'react-input-mask';
import api from 'api';
import * as EmailValidator from 'email-validator';
import { logAndCaptureException, logAndCaptureMessage } from 'utils';
import { ERequestTypes, EResponseTypes } from 'lib/types';
import { ElavonTokenizeParams, ElavonTokenizeResponse } from 'lib/types/elavon';
import { InvoicePricingData, PayInvoiceData } from 'lib/types/invoices';
import { InvoiceTransactionType } from 'lib/types/invoiceTransaction';
import { ColumnService } from 'lib/services/directory';
import * as validators from '../../../../../register/organization/validators';
import PayInvoiceButton from '../buttons/PayInvoiceButton';
import { elavonCardTokenizerInjector } from './helpers/ElavonCardTokenizerInjector';
import { getErrorFromElavonTokenizerResponse } from './helpers/GetErrorFromElavonTokenizerResponse';
import { DEFAULT_PAYMENT_USER_ERROR } from './helpers/PaywayCardAuthorization';

type CheckoutFormProps = {
  payInvoiceData: PayInvoiceData;
  invoicePricingData: InvoicePricingData;
  handleSuccessfulPayment: () => void;
};

export function ElavonCardCheckoutForm({
  payInvoiceData,
  invoicePricingData,
  handleSuccessfulPayment
}: CheckoutFormProps) {
  // Payment-processing related state
  const [tokenizerLoaded, setTokenizerLoaded] = useState(false);
  const [loadingTokenizer, setLoadingTokenizer] = useState(false);
  const [requestingSessionToken, setRequestingSessionToken] = useState(false);
  const [processingPayment, setProcessingPayment] = useState(false);
  const [triedToPay, setTriedToPay] = useState(false);
  const loading =
    requestingSessionToken || processingPayment || !tokenizerLoaded;
  const [sessionToken, setSessionToken] = useState('');
  const [idempotencyKey, setIdempotencyKey] = useState('');
  const [paymentDetailsToken, setPaymentDetailsToken] = useState('');
  const [userErr, setUserErr] = useState('');

  // Payment details
  const [creditCardNumber, setCreditCardNumber] = useState('');
  const [expirationDate, setExpirationDate] = useState('');
  const [cvc, setCvc] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  // Billing Address
  const [zip, setZip] = useState('');

  // Customer details
  const [email, setEmail] = useState('');

  const { invoice, surchargeWarning } = payInvoiceData;
  const { id: invoiceId } = invoice;
  const requestSessionToken = async () => {
    setRequestingSessionToken(true);
    try {
      const req: ERequestTypes['invoice-transactions/create-session'] = {
        invoiceId,
        sessionType: InvoiceTransactionType.Charge
      };
      const response: EResponseTypes['invoice-transactions/create-session'] =
        await api.post(`invoice-transactions/create-session`, req);
      if (!response.gatewaySessionResponse) {
        throw Error('No gatewaySessionResponse returned from create-session');
      }
      setSessionToken(response.gatewaySessionResponse.sessionToken);
      setIdempotencyKey(response.idempotencyKey);
      console.log('Loaded Elavon payment session');
    } catch (err) {
      logAndCaptureException(
        ColumnService.PAYMENTS,
        err,
        'Unable to get payment session token for Elavon',
        { invoiceId }
      );
      setUserErr(
        'Unable to start payment session. Try refreshing this page or contact help@column.us for support.'
      );
    }
    setRequestingSessionToken(false);
  };

  useEffect(() => {
    const loadElavonTokenizer = async () => {
      if (tokenizerLoaded || loadingTokenizer) return;
      setLoadingTokenizer(true);
      try {
        await elavonCardTokenizerInjector();
        setTokenizerLoaded(true);
      } catch (e) {
        logAndCaptureMessage(
          'Checkout.js did not load successfully to pay Elavon invoice!',
          {
            invoiceId
          }
        );
        setUserErr(
          'Something went wrong. Please refresh the page and try again. Contact help@column.us if this issue persists.'
        );
      }
      setLoadingTokenizer(false);
    };

    void loadElavonTokenizer();
    void requestSessionToken();
  }, []);

  useEffect(() => {
    void processElavonCharge();
  }, [paymentDetailsToken]);

  useEffect(() => {
    if (triedToPay) void validateInput();
  }, [creditCardNumber, expirationDate, cvc, firstName, lastName, email, zip]);

  const validateInput = () => {
    if (
      !creditCardNumber ||
      !expirationDate ||
      !cvc ||
      !firstName ||
      !lastName ||
      !email
    ) {
      setUserErr('Please check that all payment fields are complete.');
      return false;
    }

    if (!validators.creditCard(creditCardNumber)) {
      setUserErr('Please enter a valid credit card number.');
      return false;
    }

    if (!validators.cvc(cvc)) {
      setUserErr('Please enter a valid cvc code.');
      return false;
    }

    if (!validators.expirationDate(expirationDate)) {
      setUserErr(
        'Credit card expiration date must be valid and in the future.'
      );
      return false;
    }

    if (!zip) {
      setUserErr('Please check that all billing address fields are complete.');
      return false;
    }

    if (!EmailValidator.validate(email)) {
      setUserErr('Please check that the email entered is correct.');
      return false;
    }

    setUserErr('');
    return true;
  };

  const handleTokenizePaymentResponse = {
    onError(error: any): void {
      logAndCaptureMessage(
        `Tokenization Error response from Checkout.js: ${JSON.stringify(
          error
        )}`,
        { invoiceId }
      );
      setUserErr(error);
      setProcessingPayment(false);
      void requestSessionToken();
    },
    onDeclined(response: ElavonTokenizeResponse): void {
      const error = getErrorFromElavonTokenizerResponse(response, true);
      if (error) setUserErr(error);
      setProcessingPayment(false);
      void requestSessionToken();
    },
    onApproval(response: ElavonTokenizeResponse): void {
      const error = getErrorFromElavonTokenizerResponse(response, false);
      const { ssl_token } = response;
      let success = true;
      if (error) {
        setUserErr(error);
        success = false;
      } else if (!ssl_token) {
        setUserErr('Failed to process payment. Please try again.');
        logAndCaptureMessage('Token not found after Elavon tokenization', {
          invoiceId
        });
        success = false;
      }
      if (!success || !ssl_token) {
        setProcessingPayment(false);
        void requestSessionToken();
      } else {
        setPaymentDetailsToken(ssl_token);
      }
    }
  };

  const processElavonCharge = async () => {
    if (!paymentDetailsToken) return;
    const transactionData: ERequestTypes['invoice-transactions/create-charge'] =
      {
        // Use netTotal so we get post-appliedBalance if there's a credit on the customer
        amountInCents: invoicePricingData.netTotal,
        customerEmail: email,
        idempotencyKey,
        invoiceId,
        paymentMethodType: 'card',
        paymentMethodToken: paymentDetailsToken,
        paymentSessionToken: sessionToken
      };

    const {
      paymentGatewayResult,
      columnPaymentResult
    }: EResponseTypes['invoice-transactions/create-charge'] = await api.post(
      `invoice-transactions/create-charge`,
      transactionData
    );

    const {
      gatewayResultSuccessful: isChargeSuccessful,
      responseMessage: gatewayResponseMessage
    } = paymentGatewayResult;
    const columnResponseMessage = columnPaymentResult?.errorMessage;

    // Everything worked!
    if (isChargeSuccessful && columnPaymentResult?.columnPaymentSuccess) {
      // Just in case, get rid of the tokenized payment details so they can't be used again
      setPaymentDetailsToken('');
      handleSuccessfulPayment();
      return;
    }

    if (!isChargeSuccessful) {
      if (gatewayResponseMessage === 'DECLINED')
        setUserErr(
          'Your credit card was declined by your bank. Please try again or contact your bank if you continue to have issues.'
        );
      else
        setUserErr(
          `The transaction could not be processed: ${gatewayResponseMessage.toLocaleLowerCase()}. Contact help@column.us if you need further assistance.`
        );
    } else if (!columnPaymentResult?.columnPaymentSuccess) {
      logAndCaptureException(
        ColumnService.PAYMENTS,
        new Error(columnResponseMessage || 'Undetermined error message'),
        'Elavon invoice was likely paid in Elavon but not marked as paid in Column',
        { invoiceId }
      );
      setUserErr(
        'Something went wrong. Please contact help@column.us to make sure your payment is processed.'
      );
    }
    // Just in case, get rid of the tokenized payment details so they can't be used again
    setPaymentDetailsToken('');
    // Cleanup at end of failure state
    void requestSessionToken();
    setProcessingPayment(false);
  };

  const tokenizePaymentDetails = async () => {
    const tokenizeParams: ElavonTokenizeParams = {
      ssl_txn_auth_token: sessionToken,
      ssl_card_number: creditCardNumber,
      ssl_exp_date: expirationDate,
      ssl_get_token: 'Y',
      ssl_add_token: 'Y',
      ssl_verify: 'Y',
      ssl_first_name: firstName,
      ssl_last_name: lastName,
      ssl_avs_zip: zip,
      ssl_cvv2cvc2: cvc
    };

    // Checkout.js is loaded through an appended script so the 'as any' is unfortunately necessary
    if (!(window as any).ConvergeEmbeddedPayment) {
      logAndCaptureException(
        ColumnService.PAYMENTS,
        new Error(
          'Checkout.js did not load successfully to pay Elavon invoice!'
        ),
        'Failed to load Elavon payment page',
        { invoiceId }
      );
      setUserErr(
        'Something went wrong. Please refresh the page and try again. Contact help@column.us if this issue persists.'
      );
      setProcessingPayment(false);
      return;
    }
    try {
      await (window as any).ConvergeEmbeddedPayment.pay(
        tokenizeParams,
        handleTokenizePaymentResponse
      );
    } catch (e) {
      logAndCaptureException(
        ColumnService.PAYMENTS,
        e,
        'Failed to tokenize payment with Elavon',
        { invoiceId }
      );
      setUserErr(DEFAULT_PAYMENT_USER_ERROR);
    }
  };

  const initializeElavonPayment = () => {
    setProcessingPayment(true);
    setTriedToPay(true);
    const inputsValid = validateInput();
    if (!inputsValid) {
      setProcessingPayment(false);
      return;
    }
    setUserErr('');
    setPaymentDetailsToken('');

    void tokenizePaymentDetails();
  };

  const inputClasses =
    'p-2 block w-full sm:text-sm rounded-md focus:outline-none';

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        void initializeElavonPayment();
      }}
    >
      <div id="paymentDetails">
        <div className="font-medium text-sm text-gray-900 mb-2">
          Payment details
        </div>
        <div className="bg-white border border-gray-400 rounded-md">
          <input
            data-private
            placeholder="Card number *"
            maxLength={16}
            className={inputClasses}
            onChange={e => setCreditCardNumber(e.target.value)}
            name="cardNumber"
            value={creditCardNumber}
            autoComplete="cc-number"
          />
        </div>
        <div className="flex mt-2">
          <div
            data-private
            className="mr-1 flex-1 bg-white border border-gray-400 rounded-md"
          >
            <InputMask
              mask="99/99"
              className={inputClasses}
              value={expirationDate}
              name="expirationDate"
              onChange={e => setExpirationDate(e.target.value)}
              placeholder="Expiration date *"
              autoComplete="cc-exp"
            />
          </div>
          <div className="ml-1 flex-1 bg-white border border-gray-400 rounded-md">
            <input
              data-private
              placeholder="CVC *"
              maxLength={4}
              className={inputClasses}
              onChange={e => setCvc(e.target.value)}
              value={cvc}
              name="cvc"
              autoComplete="cc-csc"
            />
          </div>
        </div>
        <div className="flex mt-2">
          <div className="mr-1 flex-1 bg-white border border-gray-400 rounded-md">
            <input
              placeholder="First name *"
              className={inputClasses}
              onChange={e => setFirstName(e.target.value)}
              value={firstName}
              name="firstName"
              autoComplete="cc-given-name"
            />
          </div>
          <div className="ml-1 flex-1 bg-white border border-gray-400 rounded-md">
            <input
              placeholder="Last name *"
              className={inputClasses}
              onChange={e => setLastName(e.target.value)}
              value={lastName}
              name="lastName"
              autoComplete="cc-last-name"
            />
          </div>
        </div>
        <div className="flex mt-2">
          <div className="w-4/5 bg-white border border-gray-400 rounded-md">
            <input
              placeholder="Email *"
              className={inputClasses}
              onChange={e => setEmail(e.target.value)}
              value={email}
              name="email"
              autoComplete="email"
            />
          </div>
          <div className="ml-1 w-1/5 bg-white border border-gray-400 rounded-md">
            <input
              className={inputClasses}
              value={zip}
              name="zip"
              onChange={e => setZip(e.target.value)}
              placeholder="Zip *"
              autoComplete="postal-code"
              maxLength={5}
            />
          </div>
        </div>
      </div>
      {surchargeWarning && (
        <Box mt={1}>
          <Typography color="error" variant="caption">
            {surchargeWarning}
          </Typography>
        </Box>
      )}
      <PayInvoiceButton
        loading={loading}
        type={'submit'}
        disabled={!sessionToken || loading}
        id="pay-invoice-elavon"
      />
      {userErr && (
        <Box mt={1}>
          <Typography color="error" variant="caption">
            {userErr}
          </Typography>
        </Box>
      )}
    </form>
  );
}
