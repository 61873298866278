export * from './constants/visionDataOcr';

export const envs = {
  STAGING: 'STAGING',
  TEST: 'TEST',
  DEMO: 'DEMO',
  PROD: 'PROD',
  DEV: 'DEV'
};

export enum IndesignRoute {
  liner_ad_image = 'liner_ad_image',
  display_ad_image = 'display_ad_image',
  template_specs = 'template_specs',
  display_ad_marginalia = 'display_ad_marginalia',
  paginate = 'paginate',
  paginate_rtf = 'paginate_rtf'
}

export const Collections = {
  subscriptions: 'subscriptions',
  publicNotices: 'publicNotices',
  userNotices: 'usernotices',
  userDrafts: 'userdrafts',
  users: 'users',
  organizations: 'organizations',
  invoices: 'invoices',
  /** Used as a subcollection on invoices * */
  invoiceTransactions: 'invoiceTransactions',
  notifications: 'notifications',
  invites: 'invites',
  adTemplates: 'adTemplates',
  rates: 'rates',
  transfers: 'transfers',
  previewNotices: 'previewNotices',
  emailConfirmations: 'emailConfirmations',
  notes: 'notes',
  media: 'media',
  affidavitTemplates: 'affidavitTemplates',

  cards: 'cards',
  cardInvoices: 'cardInvoices',
  cardTransactions: 'cardTransactions',
  classifieds: 'classifieds',
  noticeFiles: 'noticeFiles',
  migrations: 'migrations',
  events: 'events',
  customers: 'customers',
  customerOrganizations: 'customerOrganizations',
  dwollaEvents: 'dwollaEvents',
  invoiceItems: 'invoiceItems',
  payouts: 'payouts',
  displaySites: 'displaySites',
  notarizations: 'notarizations',
  deadlines: 'deadlines',
  newspaperOrders: 'newspaperOrders',
  modularSizes: 'modularSizes',
  stripeevents: 'stripeevents',

  /** This is only used as a subcollection of usernotices */
  courthouse: 'courthouse',

  /**
   * This is only used as a subcollection of users / organizations
   * @deprecated after COREDEV-684 this collection is unused.
   */
  sources: 'sources',

  /** This is only used as a subcollection of usernotices */
  mail: 'mail',

  /** This is not a Firestore collection */
  affidavits: 'affidavits',

  /** This is only used as a subcollection of display sites */
  uploadIDs: 'uploadIDs',

  joinRequests: 'joinRequests',

  /** This is used to store metadata about files uploaded to Column through FTP */
  ftpFiles: 'ftpFiles',

  /** This is only used as a subcollection of organization */
  caches: 'caches',

  /** This is only used as a subcollection of caches */
  cacheEntries: 'cacheEntries',
  publicationIssues: 'publicationIssues',
  publicationIssueAttachments: 'publicationIssueAttachments',
  publicationIssueSections: 'publicationIssueSections',

  updateSettingRequests: 'updateSettingRequests',
  runs: 'runs',
  eeditions: 'eeditions',
  obituaries: 'obituaries',
  orders: 'orders',
  filingTypes: 'filingTypes',
  layouts: 'layouts',
  publishingSettings: 'publishingSettings',
  productPublishingSettings: 'productPublishingSettings',
  ledger: 'ledger',
  adjudicationAreas: 'adjudicationAreas',
  productSiteSettings: 'productSiteSettings',
  orderDetails: 'orderDetails',
  coupons: 'coupons',
  paperChecks: 'paperChecks',
  externalAds: 'externalAds',
  expressEmailConversations: 'expressEmailConversations',
  packages: 'packages',
  publisherPaginationSettingss: 'publisherPaginationSettingss'
  // CODEGEN: COLLECTIONS_CONST - DO NOT DELETE OR MOVE
} as const;

export type CollectionName = (typeof Collections)[keyof typeof Collections];

export const Functions = {
  createCustomer: {
    name: 'createCustomer',
    timeout: 300
  },
  createPublisherDocsOnCall: {
    name: 'createPublisherDocsOnCall',
    timeout: 540
  },
  cloudConvertOnCall: { name: 'cloudConvertOnCall', timeout: 540 },
  donate: { name: 'donate', timeout: 540 },
  createBillingSession: { name: 'createBillingSession', timeout: 540 }
};

/** Zero width space (unicode) */
export const ZWS = '\u200b';

export const EChars = {
  tab: `__enotice-tab-char`
};

let firebaseProject: string;
try {
  firebaseProject = require('../src/config.firebase').default.projectId;
} catch (err) {
  try {
    // For admin
    firebaseProject = require('../../src/config.firebase').default.projectId;
  } catch {
    firebaseProject = process.env.GCLOUD_PROJECT!;
  }
}

// Avoid any console.log() in the global scope while running jest tests because
// jest reinitializes all dependences for every test case, meaning this would
// print hundreds of times.
if (!process.env.JEST_WORKER_ID) {
  if (firebaseProject) {
    console.log('using: ', firebaseProject);
  } else {
    console.log('Cannot find local firebase project ID');
  }
}

export const CLOUDINARY_BUCKET = 'dgqq2xsfd';

export const FIREBASE_PROJECT = firebaseProject;
export const PROJECT_LOCATION = 'us-central1';

// Note: FUNCTIONS_EMULATOR is only set when running the local functions
// development environment (firebase serve or emulators:start)
export const IS_LOCAL_DEV =
  (process.env.FUNCTIONS_EMULATOR === 'true' ||
    process.env.NODE_ENV === 'development') &&
  FIREBASE_PROJECT !== 'enotice-production';

export const IS_TESTING_PROJECT =
  FIREBASE_PROJECT === 'enotice-testing' ||
  FIREBASE_PROJECT === 'enotice-testing2' ||
  FIREBASE_PROJECT === 'enotice-testing3';

export const BACKEND_URL = IS_LOCAL_DEV
  ? `http://localhost:5001/${FIREBASE_PROJECT}/us-central1/api/`
  : `https://us-central1-${FIREBASE_PROJECT}.cloudfunctions.net/api/`;

export const PLAID_PUBLIC_KEY = '541c7f3939c359167f48904d2571a0';

export const DEFAULT_ACCEPT =
  '.docx,.doc,.csv,.xls,.xlsx,.txt,.rtf,.jpg,.jpeg,.png,.pdf,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword,text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/plain,application/rtf,text/rtf,image/jpeg,image/png';

export const PRODUCTION_PROJECT = 'enotice-production';
export const STAGING_PROJECT = 'column-staging';
export const DEMO_PROJECT = 'enotice-demo-8d99a';
export const SAM_PROJECT = 'column-sam';
export const PAUL_PROJECT = 'column-paul';
export const COLUMN_ARI = 'column-ari';
export const SAULO_PROJECT = 'column-saulo';

export const isProdEnv = FIREBASE_PROJECT === PRODUCTION_PROJECT;

// We only configure the VPC connector for this function on prod, so this URL should always point there.
export const INTEGRATION_PROXY_BACKEND_URL = `https://us-central1-${PRODUCTION_PROJECT}.cloudfunctions.net/integrationProxy/`;

export const STRIPE = 'stripe';
export const PAYWAY = 'payway';
export const ELAVON = 'elavon';
export type PaymentGateway = typeof STRIPE | typeof PAYWAY | typeof ELAVON;

export const DEFAULT_TEMPLATE_ID = 'default-20220823';
// DEFAULT_TEMPLATE_ID_V2 will be feature-flagged and replaced its value with DEFAULT_TEMPLATE_ID.
export const DEFAULT_TEMPLATE_ID_V2 = 'default-20230925';

/** Stripe Account ID */
export const ENOTICE_ACCOUNT_ID_PROD = 'acct_1F4vICCGonLIgiaZ';
export const ENOTICE_ACCOUNT_ID_DEV = 'acct_1FzOn6EwSpcYm9q2';

/** Default convenience fee values */
export const ENOTICE_CONVENIENCE_FEE = 10;
export const ENOTICE_CONVENIENCE_FEE_GOVT = 0;

export const COLUMN_EXPRESS_EMAIL = 'express@column.us';

/**
 * Google Sign In client ID for admin.column.us
 * See: https://console.cloud.google.com/apis/credentials?referrer=search&project=enotice-production
 *
 * TODO: Right now this can only be prod or demo. If we expand to additional projects this will need to be updated.
 */
export const PRODUCTION_COLUMN_ADMIN_CLIENT_ID =
  '753063764402-f49s3kqhic772c9da1ktn03o884dp2l3.apps.googleusercontent.com';

export const DEMO_COLUMN_ADMIN_CLIENT_ID =
  '981763803674-gemrdp2stlo763r85cv2pljv5bl3vk4q.apps.googleusercontent.com';

export const getColumnAdminClientId = (firebaseProjectId: string) => {
  if (firebaseProjectId === PRODUCTION_PROJECT) {
    return PRODUCTION_COLUMN_ADMIN_CLIENT_ID;
  }

  return DEMO_COLUMN_ADMIN_CLIENT_ID;
};

export const DEV_INDESIGN_URL = 'https://indesign-dev.enotice.io';

/**
 * This is used in an additional fee's description to identify the fee as a Column Rep Fee
 *
 * TODO: This will eventually be replaced as we work towards a more specific fee structure
 * and better ways to identify the fee type
 */
export const COLUMN_REP_FEE = 'Column Rep Fee';

export const LogosInLiners = {
  /** Allow uploaded images of up to 2MB */
  MAX_UPLOAD_SIZE_BYTES: 2 * 1024 * 1024
};

export const COLUMN_LOGO =
  'https://firebasestorage.googleapis.com/v0/b/enotice-demo-8d99a.appspot.com/o/column-landing%2FLOGO_BLUE_1_BLUE_2.png?alt=media&token=4ef89835-3a6c-4df3-be53-570081b8e4c8';

export const INDESIGN_SERVICE_URL =
  (FIREBASE_PROJECT === 'enotice-production' && `${BACKEND_URL}indesign`) ||
  'https://us-central1-enotice-demo-8d99a.cloudfunctions.net/api/indesign';

export const INDESIGN_OUTPUT_BUCKET =
  (FIREBASE_PROJECT === 'enotice-production' &&
    'prod-indesign-service-output-bucket') ||
  'demo-indesign-service-output-bucket';

export const COLUMN_SUPPORT_EMAIL = 'help@column.us';
