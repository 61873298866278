import { ColumnService } from 'lib/services/directory';
import { logAndCaptureException } from 'utils';
import { DEFAULT_PAYMENT_USER_ERROR } from './PaywayCardAuthorization';

export const getPaywayErrorMessageFromErrorCode = (
  errorCode: string,
  message: string
) => {
  let userMessage = '';
  const callYourBankMessage =
    'The transaction was declined. Please try again. If the issue persists, please try a different form of payment or contact your card issuer.';
  switch (errorCode) {
    case '5012':
      userMessage =
        'The transaction was declined by the issuer. Please check that the card details entered above are correct. If the card details are correct, contact your card issuer.';
      break;
    case '5017':
      userMessage = callYourBankMessage;
      break;
    case '5020':
      userMessage =
        'Duplicate transaction request. The payment request has been rejected.';
      break;
    case '5029':
      userMessage = callYourBankMessage;
      break;
    case '5032':
      userMessage =
        'The card issuer has disallowed this type of transaction. Contact your card issuer if you expect this transaction type to be permitted.';
      break;
    case '5039':
      userMessage =
        'The merchant is not authorized to process this card type. Please try another method of payment.';
      break;

    // detail-specific declines
    case '5035':
      userMessage =
        'Invalid card number entered. Please check that the card details entered above are correct.';
      break;
    case '5036':
      userMessage =
        'Invalid address entered. Please check that the card details entered above are correct.';
      break;
    case '5037':
      userMessage =
        'Invalid credit card expiration date entered. Please check that the card details entered above are correct.';
      break;
    case '5038':
      userMessage =
        'Invalid CVV or card security code. Please check that the card details entered above are correct.';
      break;
    case '5040':
      userMessage =
        'Invalid city entered. Please check that the card details entered above are correct.';
      break;
    case '5044':
      userMessage =
        'Invalid state entered. Please check that the card details entered above are correct.';
      break;
    case '5045':
      userMessage =
        'Invalid zip or postal code entered. Please check that the card details entered above are correct.';
      break;
    case '5087':
      userMessage =
        'The transaction has timed out and was not completed. Please try again.';
      break;
    case '5091':
      userMessage = callYourBankMessage;
      break;
    case '1':
      userMessage = DEFAULT_PAYMENT_USER_ERROR;
      break;
    default:
      logAndCaptureException(
        ColumnService.PAYMENTS,
        new Error(
          `Unexpected Payway error code received: ${errorCode}. Message: ${message}`
        ),
        'Unexpected Payway error code',
        { errorCode, message }
      );
      userMessage = DEFAULT_PAYMENT_USER_ERROR;
      break;
  }
  return userMessage;
};
