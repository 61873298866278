import moment from 'moment-timezone';
import {
  ORDER_NEWSPAPER_ORDER_COMPLETED,
  OrderNewspaperOrderCompletedEvent,
  ORDER_NEWSPAPER_ORDER_CONFIRMED,
  OrderNewspaperOrderConfirmedEvent,
  ORDER_NEWSPAPER_ORDER_DELETED,
  OrderNewspaperOrderDeletedEvent
} from '../../types/events';
import { NotFoundError } from '../../errors/ColumnErrors';
import { SnapshotModel } from '..';
import { Collections } from '../../constants';
import {
  NewspaperOrder,
  NewspaperOrderStatus
} from '../../types/newspaperOrder';
import {
  ResponseOrColumnError,
  ResponseOrError,
  wrapError,
  wrapSuccess
} from '../../types/responses';
import { getProductDeadlineTimeForPaper } from '../../utils/deadlines';
import { OrderModel } from './orderModel';
import { safeAsync } from '../../safeWrappers';
import { ColumnService } from '../../services/directory';
import { getErrorReporter } from '../../utils/errors';
import { OrganizationModel } from './organizationModel';
import { FilingTypeModel } from './filingTypeModel';
import {
  AdRate,
  Customer,
  CustomerOrganization,
  EInvoice,
  ERef,
  ESnapshotExists,
  EUser,
  FirebaseTimestamp
} from '../../types';
import { safeGetModelFromRef } from '../getModel';
import { UserModel } from './userModel';
import { PublicationIssue } from '../../types/publicationIssue';
import { getDateForDateStringInTimezone } from '../../utils/dates';
import CustomerService from '../../services/customerService';
import { isAnonymousOrder } from '../../types/order';
import CustomerOrganizationService from '../../services/customerOrganizationService';
import { BillingTermType, Product } from '../../enums';
import { safeFirestoreTimestampOrDateToDate } from '../../helpers';
import { safeUpdateModel } from '../safeHandlers';
import { PackageModel } from './packageModel';
import { LayoutModel } from './layoutModel';

export type NewspaperOrderEditableData = {
  canEdit: boolean;
  isBeforeDeadline: boolean;
  bannerMessage: string | undefined;
  newspaperId: string;
};

export class NewspaperOrderModel extends SnapshotModel<
  NewspaperOrder,
  typeof Collections.newspaperOrders
> {
  private newspaper: OrganizationModel | null = null;

  get type() {
    return Collections.newspaperOrders;
  }

  get transferHasOccurred() {
    return !!this.modelData.transfer;
  }

  public getOrderRef() {
    return this.ref.parent.parent;
  }

  public async getOrder(): Promise<ResponseOrColumnError<OrderModel>> {
    const orderRef = this.getOrderRef();
    if (!orderRef) {
      return wrapError(new NotFoundError('Order not found'));
    }
    return safeGetModelFromRef(OrderModel, this.ctx, orderRef);
  }

  public async getNewspaper(): Promise<
    ResponseOrColumnError<OrganizationModel>
  > {
    if (this.newspaper) {
      return wrapSuccess(this.newspaper);
    }
    const { response: newspaper, error } = await safeGetModelFromRef(
      OrganizationModel,
      this.ctx,
      this.modelData.newspaper
    );
    if (error) {
      return wrapError(error);
    }
    this.newspaper = newspaper;
    return wrapSuccess(newspaper);
  }

  private getSortedPublishingDates() {
    return [...this.modelData.publishingDates].sort();
  }

  public async getDeadline(): Promise<ResponseOrError<moment.Moment>> {
    try {
      if (this.modelData.publishingDates.length === 0) {
        return wrapError(new Error('Newspaper order has no publishing dates'));
      }
      // TODO: Pass this in
      const newspaperResp = await this.getNewspaper();
      if (newspaperResp.error) {
        return wrapError(newspaperResp.error);
      }
      const newspaper = newspaperResp.response;
      const firstPublishingDateStr = this.getSortedPublishingDates()[0];
      const { response: order, error: getOrderError } = await this.getOrder();
      if (getOrderError) {
        return wrapError(getOrderError);
      }
      const { product } = order.modelData;
      const { response: deadlineResponse, error: deadlineError } =
        await getProductDeadlineTimeForPaper(
          newspaper,
          product,
          this.modelData.publishingMedium,
          firstPublishingDateStr
        );
      if (deadlineError) {
        return wrapError(deadlineError);
      }
      if (deadlineResponse === null) {
        const err = new Error('Deadline result response is null');
        getErrorReporter().logAndCaptureCriticalError(
          ColumnService.ORDER_PLACEMENT,
          err,
          'Failed to get deadline for newspaper order',
          {
            newspaperOrderId: this.id,
            orderId: order.id,
            product
          }
        );
        return wrapError(err);
      }
      return wrapSuccess(deadlineResponse.deadlineMoment);
    } catch (err) {
      return wrapError(err as Error);
    }
  }

  public async getFilingType(): Promise<
    ResponseOrColumnError<FilingTypeModel>
  > {
    return safeGetModelFromRef(
      FilingTypeModel,
      this.ctx,
      this.modelData.filingType
    );
  }

  public async getPackage(): Promise<
    ResponseOrColumnError<PackageModel | null>
  > {
    if (!this.modelData.package) {
      return wrapSuccess(null);
    }
    return safeGetModelFromRef(PackageModel, this.ctx, this.modelData.package);
  }

  public async getRate(): Promise<ResponseOrError<ESnapshotExists<AdRate>>> {
    const [packageError, packageModel] = await this.getPackage();
    if (packageError) {
      return wrapError(packageError);
    }

    if (packageModel) {
      const [publisherOrganizationError, publisherOrganization] =
        await this.getNewspaper();
      if (publisherOrganizationError) {
        return wrapError(publisherOrganizationError);
      }

      const [packageRateError, packageRate] = await packageModel.getPackageRate(
        {
          forPublisherOrganization: publisherOrganization,
          forPublishingMedium: this.modelData.publishingMedium
        }
      );

      if (packageRateError) {
        return wrapError(packageRateError);
      }

      if (packageRate) {
        return wrapSuccess(packageRate);
      }
    }

    const { response: filingType, error: filingTypeError } =
      await this.getFilingType();
    if (filingTypeError) {
      return wrapError(filingTypeError);
    }

    const layoutModel = new LayoutModel(this.modelData.layout);
    return await filingType.getRate({
      isDisplayLayout: layoutModel.isDisplayLayout
    });
  }

  /**
   * Updates the status of the newspaper order and handles initiating required side-effects
   * e.g. initiating a transfer for a completed newspaper order, creating an event for a confirmed newspaper order
   */
  public async updateStatus(
    status: NewspaperOrderStatus,
    confirmedBy?: ERef<EUser>
  ): Promise<ResponseOrError<void>> {
    const updates: Partial<NewspaperOrder> = { status };
    const { response: order, error: orderError } = await this.getOrder();
    if (orderError) {
      return wrapError(orderError);
    }
    switch (status) {
      case NewspaperOrderStatus.CONFIRMED:
        return this.handleConfirmedUpdate(order, updates, confirmedBy);
      case NewspaperOrderStatus.COMPLETE:
        return this.handleCompleteUpdate(order, updates);
      case NewspaperOrderStatus.DELETED:
        return this.handleDeletedUpdate(order, updates);
      default:
        return safeAsync(() => this.update(updates))();
    }
  }

  private async handleConfirmedUpdate(
    order: OrderModel,
    updates: Partial<NewspaperOrder>,
    confirmedBy?: ERef<EUser>
  ): Promise<ResponseOrError<void>> {
    if (!confirmedBy) {
      return wrapError(new Error('Newspaper order confirmed anonymously'));
    }
    const confirmedAt = this.ctx
      .fieldValue()
      .serverTimestamp() as FirebaseTimestamp;
    const { error: createEventError } =
      // Has side effects
      await order.createEvent<OrderNewspaperOrderConfirmedEvent>(
        ORDER_NEWSPAPER_ORDER_CONFIRMED,
        {
          newspaperOrder: this.ref,
          confirmedBy
        }
      );
    if (createEventError) {
      return wrapError(createEventError);
    }
    return await safeUpdateModel(this, {
      ...updates,
      confirmedAt
    });
  }

  /**
   * This event will have the side effect of triggering a transfer for the newspaper order
   */
  private async handleCompleteUpdate(
    order: OrderModel,
    updates: Partial<NewspaperOrder>
  ): Promise<ResponseOrError<void>> {
    const { response: canMarkAsComplete, error: canMarkAsCompleteError } =
      await this.areCompletionConditionsMet();
    if (canMarkAsCompleteError) {
      return wrapError(canMarkAsCompleteError);
    }
    /**
     * This is considered an error rather than just an early return because in
     * `markOrdersAsComplete`, we query orders from Elastic that match these criteria,
     * so if this returns false, then something may have gone wrong in that query or
     * in the sync to Elastic.
     */
    if (!canMarkAsComplete) {
      const err = new Error(
        'Newspaper order does not meet conditions to be marked as complete'
      );
      getErrorReporter().logAndCaptureError(
        ColumnService.FINANCIAL_RECONCILIATION,
        err,
        'Error marking newspaper order as complete',
        {
          newspaperOrderId: this.id
        }
      );
      return wrapError(err);
    }
    const { error: createEventError } =
      // Has side effects
      await order.createEvent<OrderNewspaperOrderCompletedEvent>(
        ORDER_NEWSPAPER_ORDER_COMPLETED,
        {
          newspaperOrder: this.ref
        }
      );
    if (createEventError) {
      return wrapError(createEventError);
    }
    return await safeUpdateModel(this, updates);
  }

  private async handleDeletedUpdate(
    order: OrderModel,
    updates: Partial<NewspaperOrder>
  ): Promise<ResponseOrError<void>> {
    const [createEventError] =
      // Has side effects
      await order.createEvent<OrderNewspaperOrderDeletedEvent>(
        ORDER_NEWSPAPER_ORDER_DELETED,
        {
          newspaperOrder: this.ref
        }
      );
    if (createEventError) {
      return wrapError(createEventError);
    }
    return await safeUpdateModel(this, updates);
  }

  public async isAtLeastNHoursBeforeDeadline(
    hoursBeforeDeadline: number
  ): Promise<ResponseOrError<boolean>> {
    const { response: deadline, error: deadlineError } =
      await this.getDeadline();
    if (deadlineError) {
      return wrapError(deadlineError);
    }

    return wrapSuccess(
      moment().add(hoursBeforeDeadline, 'hours').isBefore(deadline)
    );
  }

  private async getEditableDataForPublisher(
    user: UserModel,
    isBeforeDeadline: boolean
  ): Promise<ResponseOrError<NewspaperOrderEditableData>> {
    const newspaperId = this.modelData.newspaper.id;
    const userIsPartOfNewspaper = user.modelData.allowedOrganizations?.some(
      org => org.id === newspaperId
    );
    const newspaperOrderIsAwaitingReview =
      this.modelData.status === NewspaperOrderStatus.AWAITING_REVIEW;
    const userCanEditOwnNewspaper =
      userIsPartOfNewspaper && !this.transferHasOccurred;
    const userCanEditForAnotherNewspaper =
      isBeforeDeadline && newspaperOrderIsAwaitingReview;

    const canEdit = userCanEditOwnNewspaper || userCanEditForAnotherNewspaper;
    const bannerMessage =
      isBeforeDeadline && canEdit
        ? undefined
        : userIsPartOfNewspaper && canEdit
        ? 'This order has passed ad deadline. If you edit this order, ensure that the updated proof is sent to pagination to prevent incorrect content from publishing.'
        : userIsPartOfNewspaper && !canEdit
        ? 'A transfer has occurred for this order. No edits can be processed.'
        : !isBeforeDeadline
        ? 'The ad deadline for this order has passed. No edits or cancellations can be processed.'
        : "This publication has updated this order's confirmation status. You cannot edit this portion of the order.";
    return wrapSuccess({
      canEdit,
      bannerMessage,
      isBeforeDeadline,
      newspaperId
    });
  }

  private async getEditableDataForCustomer(
    isBeforeDeadline: boolean
  ): Promise<ResponseOrError<NewspaperOrderEditableData>> {
    const {
      response: isAtLeastOneHourBeforeDeadline,
      error: checkDeadlineError
    } = await this.isAtLeastNHoursBeforeDeadline(1);
    if (checkDeadlineError) {
      return wrapError(checkDeadlineError);
    }

    const canEdit = isAtLeastOneHourBeforeDeadline;
    const bannerMessage = canEdit
      ? 'Edits can be made to your order until one hour before the ad deadline. You can cancel your order until the ad deadline.'
      : isBeforeDeadline
      ? 'Edits cannot be made when it is less than one hour before the ad deadline. You can still cancel your order until the ad deadline.'
      : 'The ad deadline for this order has passed. No edits or cancellations can be processed.';
    return wrapSuccess({
      canEdit,
      bannerMessage,
      isBeforeDeadline,
      newspaperId: this.modelData.newspaper.id
    });
  }

  public async getEditableDataForUser(
    user: UserModel | null
  ): Promise<ResponseOrError<NewspaperOrderEditableData>> {
    const { response: isBeforeDeadline, error: isBeforeDeadlineError } =
      await this.isAtLeastNHoursBeforeDeadline(0);
    if (isBeforeDeadlineError) {
      return wrapError(isBeforeDeadlineError);
    }

    // If the newspaper order is cancelled, it can't be edited
    if (this.modelData.status === NewspaperOrderStatus.CANCELLED) {
      return wrapSuccess({
        canEdit: false,
        bannerMessage: undefined,
        isBeforeDeadline,
        newspaperId: this.modelData.newspaper.id
      });
    }

    if (user && user.isPublisher) {
      return this.getEditableDataForPublisher(user, isBeforeDeadline);
    }

    return this.getEditableDataForCustomer(isBeforeDeadline);
  }

  public async getPublicationIssues(): Promise<
    ResponseOrError<ESnapshotExists<PublicationIssue>[]>
  > {
    const { response, error } = await safeAsync<
      ESnapshotExists<PublicationIssue>[]
    >(async () => {
      const publicationIssues = await this.ctx
        .publicationIssuesRef()
        .where('publisher', '==', this.modelData.newspaper)
        .where('publicationDate', 'in', this.modelData.publishingDates)
        .get();

      return publicationIssues.docs;
    })();

    if (error) {
      return wrapError(error);
    }

    return wrapSuccess(response);
  }

  private get isConfirmed() {
    return this.modelData.status === NewspaperOrderStatus.CONFIRMED;
  }

  public get isComplete() {
    return this.modelData.status === NewspaperOrderStatus.COMPLETE;
  }

  private async hasLastPublishingDateElapsed(): Promise<
    ResponseOrError<boolean>
  > {
    const lastPublishingDateStr = this.getSortedPublishingDates().pop();
    if (!lastPublishingDateStr) {
      const err = new Error(
        'Unable to determine last publishing date for newspaper order'
      );
      getErrorReporter().logAndCaptureError(
        ColumnService.ORDER_MANAGEMENT,
        err,
        'Error determining last publishing date for newspaper order',
        {
          newspaperOrderId: this.id
        }
      );
      return wrapError(err);
    }

    const { response: newspaper, error: newspaperError } =
      await this.getNewspaper();
    if (newspaperError) {
      return wrapError(newspaperError);
    }

    const timezone = newspaper.modelData.iana_timezone;
    const lastPublishingDateMoment = moment(
      getDateForDateStringInTimezone({
        dayString: lastPublishingDateStr,
        timezone
      })
    );
    const today = moment().tz(timezone);
    return wrapSuccess(today.isSameOrAfter(lastPublishingDateMoment, 'day'));
  }

  private async areCompletionConditionsMet(): Promise<
    ResponseOrError<boolean>
  > {
    const {
      response: lastPublishingDateHasElapsed,
      error: lastPublishingDateError
    } = await this.hasLastPublishingDateElapsed();
    if (lastPublishingDateError) {
      return wrapError(lastPublishingDateError);
    }
    if (!lastPublishingDateHasElapsed) {
      getErrorReporter().logInfo(
        'Last publishing date has not elapsed for newspaper order.  Completion condition not met.',
        {
          newspaperOrderId: this.id
        }
      );
      return wrapSuccess(false);
    }
    if (!this.isConfirmed) {
      getErrorReporter().logInfo(
        'Newspaper order is not confirmed.  Completion condition not met.',
        {
          newspaperOrderId: this.id
        }
      );
      return wrapSuccess(false);
    }
    return wrapSuccess(true);
  }

  public async getCustomer(): Promise<
    ResponseOrError<ESnapshotExists<Customer> | null>
  > {
    const [orderError, order] = await this.getOrder();
    if (orderError) {
      return wrapError(orderError);
    }
    if (isAnonymousOrder(order.modelData)) {
      return wrapSuccess(null);
    }

    const { advertiser } = order.modelData;
    const { newspaper } = this.modelData;
    return await new CustomerService(this.ctx).maybeGetCustomerFromUserAndOrg(
      advertiser,
      newspaper
    );
  }

  public async getCustomerOrganization(): Promise<
    ResponseOrError<ESnapshotExists<CustomerOrganization> | null>
  > {
    const [orderError, order] = await this.getOrder();
    if (orderError) {
      return wrapError(orderError);
    }
    if (isAnonymousOrder(order.modelData)) {
      return wrapSuccess(null);
    }

    const { advertiserOrganization } = order.modelData;
    if (!advertiserOrganization) {
      return wrapSuccess(null);
    }
    const { newspaper } = this.modelData;
    return await new CustomerOrganizationService(
      this.ctx
    ).maybeGetCustomerOrgFromAdvertiserOrgAndNewspaper(
      advertiserOrganization,
      newspaper
    );
  }

  /**
   * Returns the determinative billing term that for the newspaper order. Should *never* return the default billing term.
   */
  private async getOperativeBillingTerm(): Promise<ResponseOrError<number>> {
    // 1. If the customer exists and has a non-default billing term, we should defer to that billing term.
    const [customerError, customer] = await this.getCustomer();
    if (customerError) {
      return wrapError(customerError);
    }
    if (
      customer &&
      customer.data().billingTerm &&
      customer.data().billingTerm !== BillingTermType.default.value
    ) {
      return wrapSuccess(customer.data().billingTerm);
    }

    // 2. If the customer org exists and has a non-default billing term, we should defer to that billing term.
    const [customerOrgError, customerOrg] =
      await this.getCustomerOrganization();
    if (customerOrgError) {
      return wrapError(customerOrgError);
    }
    if (
      customerOrg &&
      customerOrg.data().billingTerm &&
      customerOrg.data().billingTerm !== BillingTermType.default.value
    ) {
      return wrapSuccess(customerOrg.data().billingTerm);
    }

    const [orderError, order] = await this.getOrder();
    if (orderError) {
      return wrapError(orderError);
    }

    // 3a. For obits & classifieds, we should default to requiring upfront payment.
    /**
     * It is not currently safe to assume that the `requireUpfrontPayment` property on newspapers
     * controls for all products, as it was originally implemented for public notices, where the
     * default billing term is *not* to require upfront payment, in contrast to the default for
     * obits and classifieds. Until we have a more reliable way to determine a newspaper's product-specific
     * default, we will default to requiring upfront payment for all products except notices.
     */
    const { product } = order.modelData;
    if ([Product.Obituary, Product.Classified].includes(product)) {
      return wrapSuccess(BillingTermType.upfront_payment.value);
    }

    // 3b. For notices, we should yield to the newspaper's default for requiring upfront payment; otherwise, the default billing term should be net 30.
    const [newspaperError, newspaper] = await this.getNewspaper();
    if (newspaperError) {
      return wrapError(newspaperError);
    }
    const billingTerm = newspaper.modelData.requireUpfrontPayment
      ? BillingTermType.upfront_payment.value
      : BillingTermType.net_thirty.value;
    return wrapSuccess(billingTerm);
  }

  public async getRequiresUpfrontPayment(): Promise<ResponseOrError<boolean>> {
    const [billingTermError, billingTerm] =
      await this.getOperativeBillingTerm();
    if (billingTermError) {
      return wrapError(billingTermError);
    }
    if (!billingTerm || billingTerm === BillingTermType.default.value) {
      return wrapError(
        new Error(
          'Got unexpected default billing term when determining upfront payment requirement for newspaper order'
        )
      );
    }

    return wrapSuccess(billingTerm === BillingTermType.upfront_payment.value);
  }

  public async calculateInvoiceDueDateByBillingTerm(
    invoiceCreateTime: EInvoice['created']
  ): Promise<ResponseOrError<number>> {
    const firstPublishingDate = this.getSortedPublishingDates()[0];
    if (!firstPublishingDate) {
      return wrapError(
        new Error(
          'Cannot determine invoice due date for newspaper order with no publishing dates'
        )
      );
    }

    const [billingTermError, billingTerm] =
      await this.getOperativeBillingTerm();
    if (billingTermError) {
      return wrapError(billingTermError);
    }

    const [deadlineError, deadline] = await this.getDeadline();
    if (deadlineError) {
      return wrapError(deadlineError);
    }

    const [newspaperError, newspaper] = await this.getNewspaper();
    if (newspaperError) {
      return wrapError(newspaperError);
    }
    const { iana_timezone: timezone } = newspaper.modelData;

    const [invoiceCreateTimeDateError, invoiceCreateTimeDate] =
      safeFirestoreTimestampOrDateToDate(invoiceCreateTime);
    if (invoiceCreateTimeDateError) {
      return wrapError(invoiceCreateTimeDateError);
    }

    /**
     * This is the default due date we were using before we started using the billing term
     * to determine the due date. We use this if the order is invoiced outside Column
     * or if we for some reason can't determine the billing term (which is an error we should log),
     * but we also use it for customers with the net 30 billing term since that is currently what
     * we use for notice invoices and this helps to standardize due dates across the two schemas.
     * see https://columnpbc.atlassian.net/browse/APP-3546?focusedCommentId=82243
     */
    const defaultDueDate = moment(invoiceCreateTimeDate).add(1, 'month').unix();
    switch (billingTerm) {
      case BillingTermType.thirty_days_end_of_month.value:
        return wrapSuccess(
          moment(invoiceCreateTimeDate)
            .tz(timezone)
            .add(1, 'month')
            .endOf('month')
            .unix()
        );
      case BillingTermType.upfront_payment.value:
        return wrapSuccess(deadline.unix());
      case BillingTermType.net_thirty.value:
      case BillingTermType.outside_column.value:
        return wrapSuccess(defaultDueDate);
      case BillingTermType.default.value:
      default:
        getErrorReporter().logAndCaptureCriticalError(
          ColumnService.PAYMENTS,
          new Error('Unable to determine order invoice due date'),
          'Unable to determine order invoice due date',
          {
            newspaperOrderId: this.id,
            billingTerm: `${billingTerm}`
          }
        );
        return wrapSuccess(defaultDueDate);
    }
  }
}
