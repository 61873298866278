import { EOrganization, ESnapshotExists } from 'lib/types';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { getBooleanFlag } from 'utils/flags';

// TODO: base this on a property from the organization's plan settings not a hardcoded value
const orgHasPlacementsEnabled = (
  organization: ESnapshotExists<EOrganization>
) => {
  const LATE2LIEN_ORG = 'Rz6rZHjUA9sdD5m0LyWh';
  return organization.id === LATE2LIEN_ORG;
};

/**
 * Controls whether or not the placements tab is visible in the app header
 */
export function useShowPlacementsTab(
  activeOrganization: ESnapshotExists<EOrganization> | null
) {
  if (!activeOrganization) return false;
  const placementTabEnabled = getBooleanFlag(
    LaunchDarklyFlags.ENABLE_PLACEMENTS_TAB
  );
  if (!placementTabEnabled) return false;
  return orgHasPlacementsEnabled(activeOrganization);
}
