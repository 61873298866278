import React from 'react';
import {
  FullGenericPaginationSubmissionAudit,
  isOCRNoticeAuditResponse,
  isPaginationManifestForNotice,
  PaginationManifestData,
  ResolvedGenericPaginationBlockAudit
} from 'lib/types/pagination';
import LinkTo from 'components/LinkTo';

type PaginationReviewDisplayProps = {
  paginationReview: FullGenericPaginationSubmissionAudit;
};

function BadOrderData({ missingAd }: { missingAd: PaginationManifestData }) {
  const orderIndicator = isPaginationManifestForNotice(missingAd)
    ? `${missingAd.internalID} - ${missingAd.noticeID}`
    : `${missingAd.orderID} - ${missingAd.title}`;
  return (
    <div>
      <LinkTo
        href={missingAd.link}
        target="_blank"
        className="text-column-red-600 underline"
      >
        {orderIndicator}
      </LinkTo>
    </div>
  );
}

const getDisplayValueForResolvedAudit = (
  data: ResolvedGenericPaginationBlockAudit
) => {
  const { ocrResult } = data;
  if (isOCRNoticeAuditResponse(ocrResult)) {
    return ocrResult.order_numbers.join(', ');
  }
  return ocrResult.ads.map(ad => ad.title).join(', ');
};

export default function PaginationReviewDisplay({
  paginationReview
}: PaginationReviewDisplayProps) {
  const { combinedStyleAudit, manifestCheck, auditPassed } = paginationReview;

  return (
    <div>
      <div className="text-xl ">
        Automated Pagination Review - {auditPassed ? 'Passed ✅' : 'Failed'}
      </div>
      {!auditPassed && (
        <>
          <div>
            Ads missing in manifest:{' '}
            {manifestCheck.adsNotInManifest.length > 0 && <br />}
            <span className="text-column-red-600">
              {manifestCheck.adsNotInManifest.map((missingAd, idx) => (
                <BadOrderData key={idx} missingAd={missingAd} />
              ))}
            </span>
          </div>
          <div>
            Extra ads in manifest:{' '}
            <span className="text-column-red-600">
              {manifestCheck.extraAdsInManifest.map((missingAd, idx) => (
                <BadOrderData key={idx} missingAd={missingAd} />
              ))}
            </span>
          </div>
          <div>
            Ads for wrong publisher:{' '}
            <span className="text-column-red-600">
              {combinedStyleAudit.adsForWrongPublisher.map((missingAd, idx) => (
                <BadOrderData key={idx} missingAd={missingAd} />
              ))}
            </span>
          </div>
          <div>
            Potentially Missing ads:{' '}
            {combinedStyleAudit.missingAds.length > 0 && <br />}
            <span className="text-column-red-600">
              {combinedStyleAudit.missingAds.map((missingAd, idx) => (
                <BadOrderData key={idx} missingAd={missingAd} />
              ))}
            </span>
          </div>
          <div>
            Ads for wrong date:{' '}
            <span className="text-column-red-600">
              {combinedStyleAudit.adsForWrongDate.map((missingAd, idx) => (
                <BadOrderData key={idx} missingAd={missingAd} />
              ))}
            </span>
          </div>
          <div>
            Duplicate ads:{' '}
            <span className="text-column-red-600">
              {paginationReview.combinedStyleAudit.duplicateAds.map(
                (missingAd, idx) => (
                  <BadOrderData key={idx} missingAd={missingAd} />
                )
              )}
            </span>
          </div>
          <div>
            Unknown ads:{' '}
            <span className="text-column-red-600">
              {paginationReview.combinedStyleAudit.unknownAds.join(', ')}
            </span>
          </div>

          <div>Potentially failed blocks:</div>
          {paginationReview.auditFailures.map((failure, idx) => (
            <div key={idx}>
              <div>{failure.visionModelResult.error_description}</div>
              <img
                src={failure.blockImageUrl}
                alt={`Screenshot of potentially failed block`}
              />
              <div>
                Order numbers: {getDisplayValueForResolvedAudit(failure)}
              </div>
              <div>
                Minimal whitespace:{' '}
                {failure.visionModelResult.minimal_whitespace ? 'Yes' : 'No'}
              </div>
              <div>
                No ads have scanned documents:{' '}
                {failure.visionModelResult.no_pictures ? 'Yes' : 'No'}
              </div>
              <div>
                No duplicates:{' '}
                {failure.visionModelResult.no_duplicate_ads ? 'Yes' : 'No'}
              </div>
              <div>
                No weird characters:{' '}
                {failure.visionModelResult.no_weird_characters ? 'Yes' : 'No'}
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
}
