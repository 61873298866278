import { ReactNode } from 'react';
import { Product } from 'lib/enums';
import Header from 'components/Header';
import StackedLayout from 'layouts/StackedLayout';
import { useMatch } from 'react-router-dom';
import { MAIN_ROUTES } from 'router/routes';
import UserNotices from '../notices';
import ObitsTableContainer from './ObitsTableContainer';
import ClassifiedsTableContainer from './ClassifiedsTableContainer';

function useActiveTable(): Product {
  const isObits = useMatch(MAIN_ROUTES.OBITUARIES);
  const isClassified = useMatch(MAIN_ROUTES.CLASSIFIEDS);

  if (isObits) {
    return Product.Obituary;
  }

  if (isClassified) {
    return Product.Classified;
  }

  return Product.Notice;
}

const tabsTables: Record<string, ReactNode> = {
  [Product.Notice]: <UserNotices />, // placeholder
  [Product.Obituary]: <ObitsTableContainer />,
  [Product.Classified]: <ClassifiedsTableContainer />
};

export default function Ads() {
  const activeTable = useActiveTable();

  return (
    <StackedLayout>
      <Header productLine={activeTable} />
      {tabsTables[activeTable]}
    </StackedLayout>
  );
}
