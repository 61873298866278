import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { logInfo } from 'utils/logger';

export function RouteLogger() {
  const { pathname } = useLocation();

  useEffect(() => {
    logInfo('Page visited', { pathname });
  }, [pathname]);

  return <Outlet />;
}
