import { MediaModel } from 'lib/model/objects/mediaModel';
import ClipArt from './ClipArt';

type MediaGridProps = {
  mediaList: MediaModel[];
  onClick: (media: MediaModel) => void;
};

export default function MediaGrid({ mediaList, onClick }: MediaGridProps) {
  if (!mediaList.length) {
    return null;
  }

  return (
    <div className="grid grid-cols-2 md:grid-cols-4 gap-1 p-1 border">
      {mediaList.map(media => (
        <ClipArt
          key={media.modelData.cdnUrl}
          src={media.modelData.cdnUrl}
          onSelect={() => onClick(media)}
        />
      ))}
    </div>
  );
}
