import {
  ColorSettingVisibility,
  NewspaperOrder
} from 'lib/types/newspaperOrder';
import {
  BorderColorOutlined,
  FormatColorFillOutlined
} from '@material-ui/icons';
import EditorToolbar from '../EditorToolbar';
import ColorButton from './ColorButton';

type GlobalEditorToolbarProps = {
  settingVisibility: ColorSettingVisibility;
  colorOptions: Required<NewspaperOrder>['colorOptions'];
  onChange: (newColorOptions: Required<NewspaperOrder>['colorOptions']) => void;
};

function GlobalEditorToolbar({
  settingVisibility,
  colorOptions,
  onChange
}: GlobalEditorToolbarProps) {
  return (
    <EditorToolbar>
      <div className="flex px-1 py-px">
        {settingVisibility.showBackgroundColor && (
          <ColorButton
            description="background"
            icon={<FormatColorFillOutlined />}
            value={colorOptions.backgroundColor || 'transparent'}
            onChange={newValue => {
              onChange({ ...colorOptions, backgroundColor: newValue });
            }}
          />
        )}
        {settingVisibility.showBorderColor && (
          <ColorButton
            description="border"
            icon={<BorderColorOutlined />}
            value={colorOptions.borderColor || 'transparent'}
            onChange={newValue => {
              onChange({ ...colorOptions, borderColor: newValue });
            }}
          />
        )}
      </div>
    </EditorToolbar>
  );
}

export default GlobalEditorToolbar;
