import React, { useState, ReactNode } from 'react';
import { exists } from 'lib/types';
import { getLocationParams } from 'lib/frontend/utils/browser';
import LoadingState from 'components/LoadingState';
import { useAppSelector } from 'redux/hooks';
import {
  selectActiveOrganization,
  selectIsPublisher,
  selectUser,
  selectUserAuth
} from 'redux/auth';
import SidebarLayout from 'layouts/SidebarLayout';
import { getDynamicRoute } from 'lib/frontend/utils/router';
import { ORGANIZATION_SETTINGS_ROUTE } from 'router/routes';
import NotificationSettings from './NotificationSettings';
import SettingsForm from './SettingsForm';
import UserSettingsBankAccounts from './BankAccountSettings';
import UserSettingsDocuments from './Documents';
import UserSettingsFeatureFlags from './FeatureFlags';
import PublisherSettings from './publisherSettings';
import UserSettingsSidebar, {
  BANK_ACCOUNTS,
  BASIC_INFO,
  DOCUMENTS,
  FEATURE_FLAGS,
  NOTIFICATIONS,
  PUBLISHER_SETTINGS,
  getSidebarSettingsForUser
} from './UserSettingsSidebar';

export default function UserSettings() {
  const activeOrganization = useAppSelector(selectActiveOrganization);
  const user = useAppSelector(selectUser);
  const isPublisher = useAppSelector(selectIsPublisher);
  const userAuth = useAppSelector(selectUserAuth);

  const [view, setView] = useState(
    getLocationParams().get('tab') || BASIC_INFO
  );

  if (!user || !userAuth) return <LoadingState />;

  const {
    showPublisherSettingsTab,
    showBankAccountsTab,
    showFeatureFlagsTab,
    userInOrganization
  } = getSidebarSettingsForUser(user);

  const tabs: Record<string, ReactNode> = {
    [BASIC_INFO]: (
      <SettingsForm isPublisher={isPublisher} userAuth={userAuth} user={user} />
    ),
    [NOTIFICATIONS]: (
      <NotificationSettings
        user={user}
        activeOrganization={
          exists(activeOrganization) ? activeOrganization : null
        }
        isPublisher={isPublisher}
      />
    ),
    [DOCUMENTS]: <UserSettingsDocuments />
  };

  if (showBankAccountsTab) {
    tabs[BANK_ACCOUNTS] = <UserSettingsBankAccounts />;
  }

  if (showPublisherSettingsTab) {
    tabs[PUBLISHER_SETTINGS] = <PublisherSettings />;
  }

  if (showFeatureFlagsTab) {
    tabs[FEATURE_FLAGS] = <UserSettingsFeatureFlags />;
  }

  // If the user somehow gets a link to a tab they can't access, redirect.
  if (!Object.keys(tabs).includes(view)) {
    if (view === BANK_ACCOUNTS && userInOrganization) {
      // The user may have been linked to the wrong place for their bank accounts
      // Note: all of my attempts to use react-router for this redirect failed, but
      // this should be an uncommon operation so a full page refresh is OK.
      window.location.href = getDynamicRoute(
        ORGANIZATION_SETTINGS_ROUTE,
        {},
        { tab: BANK_ACCOUNTS }
      );
    } else {
      // Bounce back to basic info
      setView(BASIC_INFO);
    }
  }

  return (
    <SidebarLayout
      sidebar={
        <UserSettingsSidebar
          onViewChange={setView}
          activeView={view}
          user={user}
        />
      }
    >
      {tabs[view]}
    </SidebarLayout>
  );
}
