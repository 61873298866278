import { useNavigate } from 'react-router-dom';
import LoadingState from 'components/LoadingState';
import { Alert } from 'lib/components/Alert';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { getDynamicRoute, useAppParams } from 'lib/frontend/utils/router';
import { getModelFromId } from 'lib/model';
import { OrderModel } from 'lib/model/objects/orderModel';
import { safeAsync } from 'lib/safeWrappers';
import { ColumnService } from 'lib/services/directory';
import { selectUser } from 'redux/auth';
import { useAppSelector } from 'redux/hooks';
import { OBITUARY_ROUTES, PRODUCT_TO_ROUTES } from 'router/routes';
import { getFirebaseContext } from 'utils/firebase';
import { duplicateOrder } from '../place/helpers/duplicateOrder';

export default function DuplicateOrder() {
  const navigate = useNavigate();
  const { id: orderId } = useAppParams(OBITUARY_ROUTES.DUPLICATE);
  const user = useAppSelector(selectUser);

  const { isLoading, isError } = useAsyncEffect({
    fetchData: async () => {
      if (!orderId || !user?.id) {
        return;
      }

      const ctx = getFirebaseContext();

      const [orderError, orderModel] = await safeAsync(() =>
        getModelFromId(OrderModel, ctx, ctx.ordersRef(), orderId)
      )();

      if (orderError) {
        throw orderError;
      }

      const { response: newOrderRef, error: newOrderRefError } =
        await duplicateOrder(orderModel, user);

      if (newOrderRefError) {
        throw newOrderRefError;
      }

      navigate(
        getDynamicRoute(PRODUCT_TO_ROUTES[orderModel.modelData.product].PLACE, {
          id: newOrderRef.id
        })
      );
    },
    dependencies: [orderId, user?.id],
    errorConfig: {
      message: 'Failed to duplicate order in duplication route',
      service: ColumnService.ORDER_MANAGEMENT,
      tags: {
        orderId
      }
    }
  });

  if (!user || isLoading) {
    return (
      <LoadingState
        context={{
          location: 'DuplicateOrder',
          service: ColumnService.ORDER_MANAGEMENT,
          tags: { orderId }
        }}
      />
    );
  }

  if (isError) {
    return (
      <Alert
        id="error"
        status="error"
        title="There was an error duplicating the order. Please try again."
      />
    );
  }

  return null;
}
