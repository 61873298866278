import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PlacementActions from 'redux/placement';
import AuthActions from 'redux/auth';
import { EReduxState } from 'redux/types';
import PlaceNotice from './PlaceNotice';

const mapDispatchToProps = (dispatch: any) => ({
  placementActions: bindActionCreators(PlacementActions, dispatch),
  authActions: bindActionCreators(AuthActions, dispatch)
});

const mapStateToProps = (state: EReduxState) => ({
  activeOrganization: state.auth.activeOrganization,
  isPublisher: state.auth.isPublisher,
  user: state.auth.user,
  isAnonymous: state.auth.userAuth?.isAnonymous,
  placement: state.placement
});

export default connect(mapStateToProps, mapDispatchToProps)(PlaceNotice);
