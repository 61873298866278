import { useCallback } from 'react';
import { PlusCircleIcon } from '@heroicons/react/24/outline';
import { ColumnButton } from 'lib/components/ColumnButton';
import { EOrganization, ESnapshotExists } from 'lib/types';
import { getDateForDateStringInTimezone } from 'lib/utils/dates';
import moment from 'moment';
import { PublicationDateSettings } from 'lib/types/filingType';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { PublishingSettingModel } from 'lib/model/objects/publishingSettingModel';
import { useAppSelector } from 'redux/hooks';
import { selectUser } from 'redux/auth';
import { shouldDisablePublishingDate } from '../helpers/publishingDates';
import RemovableDate from './RemovableDate';
import {
  calculateDefaultPublishingDates,
  getNextPublishingDate
} from '../steps/SelectSchedules/orderPublishingDateCalculation';

const dateToFormattedString = (date: Date) => moment(date).format('YYYY-MM-DD');

type RemovableDateEntryProps = {
  id: string;
  date: string;
  selectedDates: string[];
  onChange: (date?: Date) => void;
  publishingSetting: PublishingSettingModel;
  newspaper: ESnapshotExists<EOrganization>;
  disable: {
    changeDate: boolean;
    removeDate: boolean;
  };
  showDelete: boolean;
};

function RemovableDateEntry({
  id,
  date,
  selectedDates,
  onChange,
  publishingSetting,
  newspaper,
  disable,
  showDelete
}: RemovableDateEntryProps) {
  const user = useAppSelector(selectUser);
  const shouldDisableDate = useCallback(
    (pickableDate: MaterialUiPickersDate) => {
      if (!pickableDate) {
        return false;
      }

      const stringFormattedDate = dateToFormattedString(pickableDate);

      // Don't disable the currently selected date
      if (stringFormattedDate === date) {
        return false;
      }

      return (
        shouldDisablePublishingDate({
          day: getDateForDateStringInTimezone({
            dayString: stringFormattedDate,
            timezone: newspaper.data().iana_timezone
          }),
          publishingSetting,
          newspaper,
          user
        }) || selectedDates.includes(stringFormattedDate)
      );
    },
    [date, selectedDates, publishingSetting]
  );

  const isSelectedDateValid = !shouldDisablePublishingDate({
    day: getDateForDateStringInTimezone({
      dayString: date,
      timezone: newspaper.data().iana_timezone
    }),
    publishingSetting,
    newspaper,
    user
  });

  return (
    <RemovableDate
      id={id}
      publicationDate={date}
      isValid={isSelectedDateValid}
      onRowDateChange={onChange}
      onRemoveRowClick={onChange}
      showDelete={showDelete}
      shouldDisableDate={shouldDisableDate}
      disable={disable}
    />
  );
}

type MultiDateSelectorProps = {
  selectedDates: string[];
  onSelectedDatesChange: (dates: string[]) => void;
  publishingSetting: PublishingSettingModel;
  newspaper: ESnapshotExists<EOrganization>;
  publicationDateSettings?: PublicationDateSettings;
  disabled?: boolean;
  errorText?: string;
};

function MultiDateSelector({
  selectedDates,
  onSelectedDatesChange,
  publishingSetting,
  newspaper,
  publicationDateSettings,
  disabled = false
}: MultiDateSelectorProps) {
  const hasMaxPublicationDates = Boolean(
    publicationDateSettings?.maximumPublicationDates &&
      selectedDates.length >= publicationDateSettings.maximumPublicationDates
  );

  const hasMinPublicationDates = Boolean(
    publicationDateSettings?.minimumPublicationDates &&
      selectedDates.length <= publicationDateSettings.minimumPublicationDates
  );

  const { requireConsecutivePublicationDates } = publicationDateSettings || {};

  const handleSelectedDateChange = (date: Date, index: number) => {
    if (requireConsecutivePublicationDates) {
      const newPublishingDates = calculateDefaultPublishingDates({
        newspaperSnap: newspaper,
        publishingSetting,
        publicationDateSettings,
        startingDate: date,
        targetPublicationDateCount: selectedDates.length
      });
      return onSelectedDatesChange(newPublishingDates);
    }
    const updatedDates = [...selectedDates];
    updatedDates[index] = dateToFormattedString(date);
    onSelectedDatesChange(updatedDates.sort());
  };

  const handleRemoveSelectedDate = (index: number) => {
    const updatedDates = [...selectedDates];
    updatedDates.splice(index, 1);
    onSelectedDatesChange(updatedDates);
  };

  const handleAddMoreDatesClick = () => {
    const nextPublishingDate = getNextPublishingDate(
      newspaper,
      publishingSetting,
      selectedDates.length
        ? moment(selectedDates[selectedDates.length - 1]).toDate()
        : new Date()
    );
    const updatedSelectedDates = [...selectedDates, nextPublishingDate].sort();
    onSelectedDatesChange(updatedSelectedDates);
  };

  const spanClass = 'col-span-2 lg:col-span-1';

  return (
    <>
      {selectedDates.map((date, index) => {
        const isFirstDate = index === 0;
        const isLastDate = index === selectedDates.length - 1;
        return (
          <div className={spanClass} key={index}>
            <RemovableDateEntry
              id={`date-${index}`}
              date={date}
              onChange={(newDate: Date | undefined) => {
                if (newDate instanceof Date) {
                  handleSelectedDateChange(newDate, index);
                } else {
                  handleRemoveSelectedDate(index);
                }
              }}
              selectedDates={
                requireConsecutivePublicationDates
                  ? [selectedDates[0]]
                  : selectedDates
              }
              publishingSetting={publishingSetting}
              newspaper={newspaper}
              disable={{
                changeDate:
                  disabled ||
                  Boolean(requireConsecutivePublicationDates && !isFirstDate),
                removeDate:
                  disabled ||
                  hasMinPublicationDates ||
                  Boolean(requireConsecutivePublicationDates && !isLastDate)
              }}
              showDelete={!hasMinPublicationDates && selectedDates.length > 1}
            />
          </div>
        );
      })}

      <div className={spanClass}>
        <ColumnButton
          type="button"
          onClick={handleAddMoreDatesClick}
          secondary
          fullWidth
          startIcon={<PlusCircleIcon className="w-5 h-5" />}
          buttonText="Add another publication date"
          disabled={disabled || hasMaxPublicationDates}
        />
      </div>
    </>
  );
}

export default MultiDateSelector;
