import React, { useEffect, useRef, useState } from 'react';
import LoadingState from 'components/LoadingState';
import { NoteViewToggle } from 'components/CustomerDrawer/NotesTab/NoteViewToggle';
import { ESnapshotExists, Note, NoteTopics } from 'lib/types';
import { PublicationIssueWithSection } from 'lib/types/publicationIssueSection';
import {
  ACTIVE_NOTES_VIEW,
  ARCHIVED_NOTES_VIEW,
  NOTES_VIEW
} from 'components/CustomerDrawer/NotesTab/notesHelpers';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { useAppSelector } from 'redux/hooks';
import { selectUser } from 'redux/auth';
import useGetPublicationIssueNotesRelevantToUser from 'lib/frontend/hooks/useGetPublicationIssueNotesRelevantToUser';
import { getFirebaseContext } from 'utils/firebase';
import {
  PUBLICATION_ISSUE_SECTION_STATUS_CHANGE,
  PUBLICATION_ISSUE_STATUS_CHANGE,
  PublicationIssueStatusChangeEvent
} from 'lib/types/events';
import NotesEditor from 'components/CustomerDrawer/NotesTab/NotesEditor';
import { isColumnUser, removeUndefinedFields } from 'lib/helpers';
import EmptyNotesDisplay from 'components/CustomerDrawer/NotesTab/EmptyNotesDisplay';
import UserNoteCard from 'components/CustomerDrawer/NotesTab/UserNoteCard';
import { useFirestoreSnapshot } from 'lib/frontend/hooks/useFirestoreSnapshot';
import moment from 'moment';
import { PublicationIssueStatus } from 'lib/types/publicationIssue';

const serializeStatus = (status: PublicationIssueStatus) => {
  switch (status) {
    case PublicationIssueStatus.DISABLED:
      return 'Disabled';
    case PublicationIssueStatus.PENDING:
      return 'Pending';
    case PublicationIssueStatus.READY_FOR_PAGINATION:
      return 'Ready for Pagination';
    case PublicationIssueStatus.AWAITING_APPROVAL:
      return 'Awaiting Approval';
    case PublicationIssueStatus.APPROVED:
      return 'Approved';
    case PublicationIssueStatus.PRINT_READY:
      return 'Print Ready';
    case PublicationIssueStatus.CHANGES_REQUESTED:
      return 'Changes Requested';
    case PublicationIssueStatus.ARCHIVED:
      return 'Archived';
    default: {
      const _exhaustiveCheck: never = status;
      return _exhaustiveCheck;
    }
  }
};

function PublicationIssueStatusChangeEventCard({
  event
}: {
  event: ESnapshotExists<PublicationIssueStatusChangeEvent>;
}) {
  const initiator = useFirestoreSnapshot(event.data().data.changedBy);
  return (
    <div className="bg-column-gray-100 p-4 rounded-lg text-column-gray-500">
      <div className="flex flex-col items-center">
        <div className="bg-column-gray-200 flex-1" style={{ width: 1 }} />
      </div>
      <div>
        (Column Only {event.id}) {initiator?.data().name} shifted from{' '}
        {serializeStatus(event.data().data.beforeStatus)} to{' '}
        {serializeStatus(event.data().data.afterStatus)} on{' '}
        {moment(event.data().createdAt.toDate()).format(
          'MMM Do, YYYY [at] h:mm A z'
        )}
      </div>
    </div>
  );
}

const isEvent = (
  noteOrEvent:
    | ESnapshotExists<Note>
    | ESnapshotExists<PublicationIssueStatusChangeEvent>
): noteOrEvent is ESnapshotExists<PublicationIssueStatusChangeEvent> => {
  return 'data' in noteOrEvent.data();
};

export default function PublicationIssueDrawerNotes({
  publicationIssueWithSection
}: {
  publicationIssueWithSection: PublicationIssueWithSection;
}) {
  const [currentView, setCurrentView] = useState<NOTES_VIEW>(ACTIVE_NOTES_VIEW);
  const user = useAppSelector(selectUser);

  const notes = useGetPublicationIssueNotesRelevantToUser(
    getFirebaseContext(),
    user,
    publicationIssueWithSection
  );
  const notesContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    notesContainer.current?.scrollTo(0, notesContainer.current.scrollHeight);
  }, [!!notesContainer.current?.scrollHeight, currentView, notes.length]);

  const { value: events } = useAsyncEffect({
    fetchData: async () => {
      let publicationIssueStatusChangeQuery = getFirebaseContext()
        .eventsRef<PublicationIssueStatusChangeEvent>()
        .where(
          'newspaper',
          '==',
          publicationIssueWithSection.publicationIssue.modelData.publisher
        );
      if (publicationIssueWithSection.section?.ref) {
        publicationIssueStatusChangeQuery = publicationIssueStatusChangeQuery
          .where('type', '==', PUBLICATION_ISSUE_SECTION_STATUS_CHANGE)
          .where(
            'publicationIssueSection',
            '==',
            publicationIssueWithSection.section?.ref
          );
      } else {
        publicationIssueStatusChangeQuery = publicationIssueStatusChangeQuery
          .where('type', '==', PUBLICATION_ISSUE_STATUS_CHANGE)
          .where(
            'publicationIssue',
            '==',
            publicationIssueWithSection.publicationIssue.ref
          );
      }
      const statusChangeQueryResult =
        await publicationIssueStatusChangeQuery.get();

      return statusChangeQueryResult.docs;
    },
    dependencies: [
      publicationIssueWithSection.publicationIssue.id,
      publicationIssueWithSection.section?.id
    ]
  });

  if (!notes || !events) return <LoadingState />;

  const isActiveView = currentView === ACTIVE_NOTES_VIEW;
  const sortedNotesAndEvents = [...notes, ...events].sort((a, b) => {
    if (a.data().createdAt < b.data().createdAt) return -1;
    if (a.data().createdAt > b.data().createdAt) return 1;
    return 0;
  });

  const filteredNotesAndEvents = sortedNotesAndEvents.filter(noteOrEvent => {
    if (isEvent(noteOrEvent)) {
      return currentView === ACTIVE_NOTES_VIEW;
    }
    if (currentView === ARCHIVED_NOTES_VIEW) {
      return noteOrEvent.data().status === 'archived';
    }
    return noteOrEvent.data().status === 'public';
  });

  const noteTopic: NoteTopics = removeUndefinedFields({
    publicationIssue: publicationIssueWithSection.publicationIssue.ref,
    section: publicationIssueWithSection.section?.ref,
    noteType: 'publication-issue-note'
  });

  return (
    <div className="px-6 flex-1 flex flex-col overflow-scroll">
      <div className="flex-1 overflow-scroll">
        {!filteredNotesAndEvents.length ? (
          <EmptyNotesDisplay currentView={currentView} />
        ) : (
          <div
            className="py-6 flex flex-col gap-4 h-full overflow-scroll"
            ref={notesContainer}
          >
            {filteredNotesAndEvents.map((noteOrEvent, idx) => {
              if (isEvent(noteOrEvent)) {
                if (!user || !isColumnUser(user)) {
                  return null;
                }
                return (
                  <PublicationIssueStatusChangeEventCard
                    key={noteOrEvent.id}
                    event={noteOrEvent}
                  />
                );
              }
              return (
                <UserNoteCard
                  key={noteOrEvent.id}
                  isFinalNote={idx === notes.length - 1}
                  currentView={currentView}
                  note={noteOrEvent}
                />
              );
            })}
          </div>
        )}
      </div>

      {isActiveView && <NotesEditor noteTopic={noteTopic} />}

      <NoteViewToggle onChangeView={setCurrentView} currentView={currentView} />
    </div>
  );
}
