import axios from 'axios';
import {
  PAYWAY_SUCCESS_CODE,
  PaywayAuthorizeRequest,
  PaywayGatewayTransactionResponse,
  PaywayGatewayTransactionResponseSchema
} from 'lib/types/payway';
import { logAndCaptureException } from 'utils';
import { safeValidateSchema } from 'lib/utils/joi';
import { ColumnService } from 'lib/services/directory';
import { isObject } from 'lib/model/typeCheckers';
import { getPaywayErrorMessageFromErrorCode } from './paywayCodeToMessage';

export const DEFAULT_PAYMENT_USER_ERROR =
  'The transaction could not be processed. Please try again. If issues persist, please try a different card or contact your card issuer.';

const getPaywayMessageAndCodeFromResponse = (
  data: unknown
): { paywayCode: string; paywayMessage: string } => {
  const paywayCode =
    isObject(data) && 'paywayCode' in data ? data.paywayCode : '';
  const paywayMessage =
    isObject(data) && 'paywayMessage' in data ? data.paywayMessage : '';
  if (typeof paywayCode !== 'string' || typeof paywayMessage !== 'string') {
    logAndCaptureException(
      ColumnService.PAYMENTS,
      new Error(`Unexpected Payway response: ${JSON.stringify(data)}`),
      'Unexpected Payway response'
    );
    return { paywayCode: '', paywayMessage: '' };
  }
  return { paywayCode, paywayMessage };
};

export const authorizePaywayCardAndGetToken = async (
  requestData: PaywayAuthorizeRequest,
  paymentAuthorizationUrl: string
): Promise<string> => {
  const paywayUrl = paymentAuthorizationUrl;
  let data: unknown;
  try {
    data = (await axios.post(`${paywayUrl}/Payment/CreditCard`, requestData))
      .data;
  } catch (error) {
    logAndCaptureException(
      ColumnService.PAYMENTS,
      error,
      'Failed to authorize card with Payway due to rejected POST request'
    );
    throw Error(DEFAULT_PAYMENT_USER_ERROR);
  }
  const { paywayCode, paywayMessage } =
    getPaywayMessageAndCodeFromResponse(data);
  if (paywayCode !== PAYWAY_SUCCESS_CODE) {
    const errorMessage = getPaywayErrorMessageFromErrorCode(
      paywayCode,
      paywayMessage
    );
    throw Error(errorMessage);
  }

  const [validationError, validation] =
    safeValidateSchema<PaywayGatewayTransactionResponse>(
      PaywayGatewayTransactionResponseSchema,
      data,
      'Invalid response from Payway authorization'
    );
  if (validationError) {
    logAndCaptureException(
      ColumnService.PAYMENTS,
      validationError,
      'Unexpected response from Payway authorization'
    );
    throw Error(DEFAULT_PAYMENT_USER_ERROR);
  }
  if (validation.cardAccount.paywayToken) {
    return validation.cardAccount.paywayToken.toString();
  }
  logAndCaptureException(
    ColumnService.PAYMENTS,
    new Error(
      'Payway token not received from successful authorization attempt'
    ),
    'Failed to authorize card with Payway'
  );
  throw Error(DEFAULT_PAYMENT_USER_ERROR);
};
