import { getDynamicRoute, useAppParams } from 'lib/frontend/utils/router';
import { Navigate, RouteObject } from 'react-router-dom';

function RedirectWithParams({ from, to }: { from: string; to: string }) {
  const params = useAppParams(from);
  return <Navigate to={getDynamicRoute(to, params)} replace />;
}

export const getRedirect = (from: string, to: string): RouteObject => {
  return {
    path: from,
    element: <RedirectWithParams from={from} to={to} />
  };
};
