import { centsToDollarsString } from 'lib/helpers';
import { Alert } from 'lib/components/Alert';
import LoadingState from 'components/LoadingState';
import PricingComparison from './PricingComparison';
import OrderSummaryRow from '../OrderSummaryRow';

type PricingProps = {
  showComparison: boolean;
  oldTotalInCents: number | null;
  totalInCents: number | undefined;
  invoiceLoading: boolean;
};

function Pricing({
  showComparison,
  oldTotalInCents,
  totalInCents,
  invoiceLoading
}: PricingProps) {
  if (
    totalInCents === undefined ||
    (showComparison && oldTotalInCents === null)
  ) {
    return <Alert id="pricing-alert" title="Missing total" />;
  }

  if (showComparison && oldTotalInCents !== null) {
    return (
      <PricingComparison
        oldTotalInCents={oldTotalInCents}
        totalInCents={totalInCents}
        invoiceLoading={invoiceLoading}
      />
    );
  }

  return (
    <OrderSummaryRow
      label="Total Price"
      data={
        invoiceLoading ? (
          <LoadingState
            isFullScreen={false}
            timeout={300}
            context={{
              location: '/ads/place/order-summary'
            }}
          />
        ) : (
          `$${centsToDollarsString(totalInCents)}`
        )
      }
    />
  );
}

export default Pricing;
