import React from 'react';
import { useNavigate } from 'react-router-dom';

import { getDynamicRoute, useAppParams } from 'lib/frontend/utils/router';
import { FORM_ROUTES, NOTICE_ROUTES } from 'router/routes';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { selectUserAuth } from 'redux/auth';
import ToastActions from 'redux/toast';
import Embed from './Embed';
import FIREBASE_CONFIG from '../../config.firebase';
import { appendToCurrentParams } from '../../utils/urls';
import { CONFIRM_PUBLISHER } from '../placeScroll/helpers/calculatePlacementSteps';

const NEWSPAPER_FORM_ID = 'whM47vYn';

export default function TypeformEmbed() {
  const navigate = useNavigate();
  const userAuth = useAppSelector(selectUserAuth);
  const dispatch = useAppDispatch();

  const { noticeId } = useAppParams(FORM_ROUTES.NEWSPAPER_WITH_NOTICE);

  const hiddenFields = {
    documentid: noticeId,
    userid: userAuth ? userAuth.uid : '',
    env: FIREBASE_CONFIG.projectId
  };

  const parametersConfirmed = () => {
    const base = getDynamicRoute(NOTICE_ROUTES.PLACE, { id: noticeId });
    const allParams = appendToCurrentParams('step', CONFIRM_PUBLISHER);
    navigate(`${base}?${allParams.toString()}`);
  };

  const customFormSubmitted = () => {
    parametersConfirmed();
    dispatch(
      ToastActions.toastSuccess({
        headerText: 'Feedback submitted',
        bodyText: `Thanks for the feedback! If you'd like to place in another newspaper, please continue.`
      })
    );
  };

  return (
    <Embed
      onSubmit={customFormSubmitted}
      hiddenFields={hiddenFields}
      formId={NEWSPAPER_FORM_ID}
    />
  );
}
