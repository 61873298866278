import { FirebaseTimestamp } from '.';
import { Ad } from './ad';
import { OrderImageCrop } from '../orders/images';

export type DeathVerificationBase = {
  verifiedAt?: FirebaseTimestamp;
};

export type FuneralHomeDetailsDeathVerification = DeathVerificationBase & {
  funeralHomePhone?: string;
  funeralHomeName?: string;
  funeralHomeEmail?: string;
};

export type DeathVerification = FuneralHomeDetailsDeathVerification;

export type Obituary = Ad & {
  // @deprecated use orderImages instead
  headerImage?: string | null;
  // @deprecated use orderImages instead
  headerImageCrop?: OrderImageCrop | null;
  // @deprecated use orderImages instead
  secondHeaderImage?: string | null;
  // @deprecated use orderImages instead
  secondImageCrop?: OrderImageCrop | null;

  deceasedName?: string;

  funeralHomeLogo?: string;
  /**
   * Date of birth of the deceased
   * Format: YYYY-MM-DD
   */
  deceasedBirthDate?: string;
  /**
   * Date of death of the deceased
   * Format: YYYY-MM-DD
   */
  deceasedDeathDate?: string;
  deathVerification: DeathVerification;
};

export enum ObituaryFilingTypeNames {
  Obituary = 'Obituary',
  DeathNotice = 'Death Notice',
  CelebrationOfLife = 'Celebration of Life',
  ServiceNotice = 'Service Notice'
}
