import React, { useEffect, useState } from 'react';
import { EInvoice, ESnapshotExists } from 'lib/types';

import { TextField } from 'lib/components/TextField';
import { InvoiceStatus } from 'lib/enums';
import { getInvoiceAmountsBreakdown } from 'lib/pricing';
import { isPaidDirectToPublisher, isInvoiceVoidable } from 'lib/utils/invoices';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { ELAVON, PAYWAY, PaymentGateway } from 'lib/constants';
import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';
import { getAmountOfRefundToRecoverFromPublisherInCents } from '../../../lib/payments/refunds';

type RefundInvoiceModalInnerActionBoxProps = {
  invoice: ESnapshotExists<EInvoice>;
  isPublisher: boolean;
  gateway: PaymentGateway | undefined;
  isInvoicedOutsideColumn: boolean | undefined;
  refundReason: string;
  refundAmount: number;
  header: string;
  body: string;
  totalRefundedInCentsFromPriorInvoiceTransactions: number;
  loading: boolean;
  setDisabled: (arg0: boolean) => void;
  setRefundAmount: (arg0: number) => void;
  setRefundReason: (arg0: string) => void;
  setIsFullRefund: (arg0: boolean) => void;
};

export default function RefundInvoiceModalInnerActionBox({
  invoice,
  isPublisher,
  gateway,
  isInvoicedOutsideColumn,
  refundReason,
  refundAmount,
  header,
  body,
  totalRefundedInCentsFromPriorInvoiceTransactions,
  loading,
  setDisabled,
  setRefundAmount,
  setRefundReason,
  setIsFullRefund
}: RefundInvoiceModalInnerActionBoxProps) {
  const { totalInCents, publisherAmountInCents, columnAmountInCents } =
    getInvoiceAmountsBreakdown(invoice);

  // The maximum amount a publisher can return to the advertiser
  const maxRefundAmount =
    Math.min(
      totalInCents - (invoice.data().refund_amount ?? 0),
      totalInCents - totalRefundedInCentsFromPriorInvoiceTransactions
    ) / 100;

  // The portion of the refund amount that comes from the publisher
  const [refundPublisherAmount, setRefundPublisherAmount] = useState('0.00');

  // The portion of the refund amount that comes from Column
  const [refundFeeAmount, setRefundFeeAmount] = useState('0.00');

  /**
   * Input field for the refund amount. Holds state so we don't prematurely truncate refunded amount
   * during typing e.g. 5.0 => 5, being unable to type 5.01
   */
  const [refundAmountInput, setRefundAmountInput] = useState(
    refundAmount.toString()
  );
  /* while in the CacncelNoticeModal, partial refund is disabled (in the context of cancelling a notice)
  ONLY for Elavon, here (since in APP-2220 we are allowing multiple partial refunds with or without cancellation)
  we should also disable it for Payway until we can confirm we have multiple partial refund capability for Payway */
  const partialRefundDisabled = gateway === ELAVON || gateway === PAYWAY;
  const canCancel = isPublisher || isInvoiceVoidable(invoice);

  const invoicePaidDirectToPublisher =
    !!invoice && isPaidDirectToPublisher(invoice);

  const invoicePaidByBulkInvoice = !!invoice.data().paidByBulkInvoice;

  // TODO (APP-16): currently this option is behind a LD flag; it should be made available app-wide
  const paymentInitiatedInNewRefundFlow =
    invoice?.data()?.status === InvoiceStatus.initiated.value;
  // TODO (APP-16): simply change this to check whether invoice is paid or initiated
  const isInvoiceRefundable =
    [InvoiceStatus.paid.value, InvoiceStatus.partially_refunded.value].includes(
      invoice?.data()?.status ?? 0
    ) || paymentInitiatedInNewRefundFlow;

  const isRefund =
    !isInvoicedOutsideColumn &&
    (!invoicePaidDirectToPublisher || invoicePaidByBulkInvoice) &&
    canCancel &&
    isPublisher &&
    isInvoiceRefundable;

  const refundAmountExceedsMax = refundAmount > maxRefundAmount;
  const nullRefundAmount =
    !refundAmount || refundAmount === 0 || refundAmount < 0;

  const handleTotalChange = (newRefundAmount: number) => {
    setRefundAmount(newRefundAmount);
  };

  useEffect(() => {
    if (refundAmountExceedsMax || nullRefundAmount) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }

    const refundAmountInCents = refundAmount * 100;

    const amountOfRefundToRecoverFromPublisherInCents =
      getAmountOfRefundToRecoverFromPublisherInCents(
        refundAmountInCents,
        totalInCents,
        publisherAmountInCents,
        columnAmountInCents,
        true // this modal should only appear when enablePartialRefundsV2 is true, so no need to retrieve the flag here
      );

    setRefundPublisherAmount(
      (amountOfRefundToRecoverFromPublisherInCents / 100).toFixed(2)
    );
    setRefundFeeAmount(
      (
        (refundAmountInCents - amountOfRefundToRecoverFromPublisherInCents) /
        100
      ).toFixed(2)
    );
    setIsFullRefund(refundAmount === maxRefundAmount);
  }, [refundAmount]);

  return (
    <>
      {isRefund && (
        <div>
          <CardGridLayout
            header={{
              title: header,
              description: body
            }}
          >
            <GridInput fullWidth>
              <TextField
                id="refund-subtotal-amount"
                labelText="Refund Amount *"
                type="currency"
                value={refundAmountInput}
                step={0.01}
                // Don't set max when loading to surpress validation errors on refund amount between invoice update and modal closure
                max={loading ? undefined : maxRefundAmount}
                onChange={value => {
                  setRefundAmountInput(value);
                  const parsedValue = parseFloat(value);
                  if (!isNaN(parsedValue)) {
                    handleTotalChange(parsedValue);
                  }
                }}
                disabled={partialRefundDisabled}
                validationMessages={{
                  rangeOverflow:
                    'You cannot refund the advertiser for more than the subtotal of this notice.'
                }}
              />
            </GridInput>
            <GridInput fullWidth>
              {canCancel && (
                <div className="w-full mt-3">
                  <TextField
                    id="refund-invoice-reason"
                    labelText="Reason for refund"
                    placeholder="Reason for refund"
                    value={refundReason}
                    onChange={setRefundReason}
                    required
                    validationMessages={{
                      valueMissing:
                        'Please specify a reason for refunding or voiding this invoice.'
                    }}
                  />
                </div>
              )}
            </GridInput>
            <GridInput fullWidth>
              {!refundAmountExceedsMax && !nullRefundAmount && (
                <div className="flex flex-row items-center text-sm rounded-md align-middle min-h-11 w-full space-x-2 py-1.5 pr-3 mt-3 bg-column-green-50">
                  <ExclamationCircleIcon className="text-column-green-500 h-16 w-16 pl-3" />
                  <div className="text-column-green-500">
                    The advertiser will receive a total refund of $
                    {refundAmount.toFixed(2)} ($
                    {refundPublisherAmount} from the publisher and $
                    {refundFeeAmount} from Column).
                  </div>
                </div>
              )}
            </GridInput>
          </CardGridLayout>
        </div>
      )}
    </>
  );
}
