import { EnumInputItem } from '../types/enums';
import { Product } from './Product';

type SpacerMetadata = {
  fileId: string;
  url: string;
  prefix: string;
};

export type SpacerItemType = EnumInputItem<{
  thick: SpacerMetadata;
  skinny: SpacerMetadata;
  super_skinny: SpacerMetadata;
}>;

const MADISON_DAILY_LEADER = '0auKhrFYdeLtgHu4fF5p'; // Madison Daily Leader
const ARGUS_OBSERVER_PAYETTE = '3sqIoRZHmogD54GeJZsf'; // Argus Observer Payette
const EASTERN_ARIZONA_COURIER_GRAHAM_COUNTY = '4J5LxfGOn7CotWBb1MVG'; // Eastern Arizona Courier (Graham County)
const MONTROSE_DAILY_PRESS = '4MejbEQewCZt6wao8pLN'; // Montrose Daily Press
const NEWS_MONITOR_RICHLAND_COUNTY = '8OzyiCJEncN2t85yTGVi'; // News Monitor (Richland County)
const SAHUARITA_SUN = '8dKEVo2h0R9vtPYWx6Op'; // Sahuarita Sun
const DOUGLAS_COUNTY_EMPIRE_PRESS = 'EspOupCAl1e0QtqamQDh'; // Douglas County Empire Press
const DAILY_TERRITORIAL_TUCSON = 'HxZUJvRjMSNzmSF5gVTt'; // Daily Territorial (Tucson)
const CAPITAL_JOURNAL = 'MO0xhXN5yPXgb7PSnLkw'; // Capital Journal
const ANCHORAGE_PRESS = 'O7S8QvrKb6ngcgtbUGne'; // Anchorage Press
const ARIZONA_DAILY_SUN = 'OEorQtuo0x7hs3obwbb3'; // Arizona Daily Sun
const SIDNEY_HERALD = 'OJWQoQSQO6i6IM07X605'; // Sidney Herald
const COPPER_ERA_GREENLEE_COUNTY = 'QjlDuSHoWoPrsxdhTvfN'; // Copper Era (Greenlee County)
const DELTA_COUNTY_INDEPENDENT = 'S8vopO1QpInr4cg57mcH'; // Delta County Independent
const WILLISTON_HERALD_MEDIA = 'YvHQs3V7CxwBHDsvzf72'; // Williston Herald Media
const GREEN_VALLEY_NEWS = 'ZS1cDeqgcbUz4fszNK1n'; // Green Valley News
const MAT_SU_VALLEY_FRONTIERSMAN = 'fUaFNNLn71Ceg1nCJW5O'; // Mat-Su Valley Frontiersman
const WENATCHEE_WORLD = 'jZvsCe2cWzyFuffApKSm'; // Wenatchee World
const QUINCY_VALLEY_POST_REGISTER = 'kQbEq1Y4jf0Gm1t1q4wE'; // Quincy Valley Post-Register
const FERGUS_FALLS_DAILY_JOURNAL = 'pFH8EJzxUuDDgWJ58VQO'; // Fergus Falls Daily Journal
const THE_ARGUS_OBSERVER = 'qIJwJGMHiHab64uYCC5j'; // The Argus Observer
const HERALD_REVIEW_MEDIA = 'rRhxPlZgDlhagPaFjBon'; // Herald/Review Media
const WAHPETON_DAILY_NEWS = 'uXrn16XL3nks5FDmbfJR'; // Wahpeton Daily News
const NOGALES_INTERNATIONAL = 'yXfpxPrpCEhFxDQS8Sgw'; // Nogales International
// TODO(Ari): add spacers for obits & classifieds
export const SpacerData = {
  [Product.Notice]: {
    thick: {
      fileId: '47ae261d098ae492ce62e5b4c79d80680d868f86',
      prefix: 'thick-spacer',
      url: 'https://enotice-production.imgix.net/custom-documents%2Fpermalink%2F9a86.d90a2-thick_header.jpg'
    },
    skinny: {
      prefix: 'skinny-spacer',
      fileId: 'd923ece1b3fc11f7ec95e6bcca671f2dfa4d1b29',
      url: 'https://enotice-production.imgix.net/custom-documents%2Fpermalink%2Fd600.7a390-skinny_header.jpg'
    },
    super_skinny: {
      prefix: 'three-col-spacer',
      fileId: '200f8030f3970cba0218b9a3766150abc16d63d4',
      url: 'https://enotice-production.imgix.net/custom-documents%2Fpermalink%2F1169.f2e48-3-col-spacer.jpg'
    },
    key: Product.Notice,
    label: 'Notice Spacers',
    value: 1
  }
};

export const PUBLISHER_SPECIFIC_SPACERS = {
  [MADISON_DAILY_LEADER]: {
    'six-col': {
      prefix: 'mdl-six-col-header',
      imgUrl:
        'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/documentcloud/wick_pagination/notice_pdfs/1738788468811_mdl_mll.pdf',
      columns: 6
    },
    'four-col': {
      prefix: 'mdl-four-col-header',
      imgUrl:
        'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/documentcloud/wick_pagination/notice_pdfs/1738789385885_mdl_mll.pdf',
      columns: 4
    },
    'three-col': {
      prefix: 'mdl-three-col-header',
      imgUrl:
        'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/documentcloud/wick_pagination/notice_pdfs/1738789865312_mdl.pdf',
      columns: 3
    },
    'two-col': {
      prefix: 'mdl-two-col-header',
      imgUrl:
        'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/documentcloud/wick_pagination/notice_pdfs/1738790337379_mdl.pdf',
      columns: 2
    }
  },
  [ARGUS_OBSERVER_PAYETTE]: {
    'six-col': {
      prefix: 'tao-six-col-header',
      imgUrl:
        'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/documentcloud/wick_pagination/notice_pdfs/1738788772711_tao.pdf',
      columns: 6
    },
    'four-col': {
      prefix: 'tao-four-col-header',
      imgUrl:
        'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/documentcloud/wick_pagination/notice_pdfs/1738789495816_tao.pdf',
      columns: 4
    },
    'three-col': {
      prefix: 'tao-three-col-header',
      imgUrl:
        'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/documentcloud/wick_pagination/notice_pdfs/1738789968304_tao.pdf',
      columns: 3
    },
    'two-col': {
      prefix: 'tao-two-col-header',
      imgUrl:
        'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/documentcloud/wick_pagination/notice_pdfs/1738790604737_tao.pdf',
      columns: 2
    }
  },
  [EASTERN_ARIZONA_COURIER_GRAHAM_COUNTY]: {
    'six-col': {
      prefix: 'eac-six-col-header',
      imgUrl:
        'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/documentcloud/wick_pagination/notice_pdfs/1738788235046_eac.pdf',
      columns: 6
    },
    'four-col': {
      prefix: 'eac-four-col-header',
      imgUrl:
        'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/documentcloud/wick_pagination/notice_pdfs/1738789309780_eac.pdf',
      columns: 4
    },
    'three-col': {
      prefix: 'eac-three-col-header',
      imgUrl:
        'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/documentcloud/wick_pagination/notice_pdfs/1738789784198_eac.pdf',
      columns: 3
    },
    'two-col': {
      prefix: 'eac-two-col-header',
      imgUrl:
        'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/documentcloud/wick_pagination/notice_pdfs/1738790268156_eac.pdf',
      columns: 2
    }
  },
  [MONTROSE_DAILY_PRESS]: {
    'six-col': {
      prefix: 'mdp-six-col-header',
      imgUrl:
        'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/documentcloud/wick_pagination/notice_pdfs/1738788518083_mdp.pdf',
      columns: 6
    },
    'four-col': {
      prefix: 'mdp-four-col-header',
      imgUrl:
        'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/documentcloud/wick_pagination/notice_pdfs/1738789412535_mdp.pdf',
      columns: 4
    },
    'three-col': {
      prefix: 'mdp-three-col-header',
      imgUrl:
        'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/documentcloud/wick_pagination/notice_pdfs/1738789884200_mdp.pdf',
      columns: 3
    },
    'two-col': {
      prefix: 'mdp-two-col-header',
      imgUrl:
        'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/documentcloud/wick_pagination/notice_pdfs/1738790357474_mdp.pdf',
      columns: 2
    }
  },
  [NEWS_MONITOR_RICHLAND_COUNTY]: {},
  [SAHUARITA_SUN]: {},
  [DOUGLAS_COUNTY_EMPIRE_PRESS]: {
    'six-col': {
      prefix: 'emp-six-col-header',
      imgUrl:
        'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/documentcloud/wick_pagination/notice_pdfs/1738788289689_emp.pdf',
      columns: 6
    },
    'four-col': {
      prefix: 'emp-four-col-header',
      imgUrl:
        'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/documentcloud/wick_pagination/notice_pdfs/1738789330385_emp.pdf',
      columns: 4
    },
    'three-col': {
      prefix: 'emp-three-col-header',
      imgUrl:
        'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/documentcloud/wick_pagination/notice_pdfs/1738789807129_emp.pdf',
      columns: 3
    },
    'two-col': {
      prefix: 'emp-two-col-header',
      imgUrl:
        'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/documentcloud/wick_pagination/notice_pdfs/1738790286971_emp.pdf',
      columns: 2
    }
  },
  [DAILY_TERRITORIAL_TUCSON]: {
    'six-col': {
      prefix: 'dt-six-col-header',
      imgUrl:
        'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/documentcloud/wick_pagination/notice_pdfs/1738944288574_dt__1_.pdf',
      columns: 6
    },
    'four-col': {
      prefix: 'dt-four-col-header',
      imgUrl:
        'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/documentcloud/wick_pagination/notice_pdfs/1738789269019_dt.pdf',
      columns: 4
    },
    'three-col': {
      prefix: 'dt-three-col-header',
      imgUrl:
        'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/documentcloud/wick_pagination/notice_pdfs/1738944228442_dt.pdf',
      columns: 3
    },
    'two-col': {
      prefix: 'dt-two-col-header',
      imgUrl:
        'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/documentcloud/wick_pagination/notice_pdfs/1738790247435_dt.pdf',
      columns: 2
    }
  },
  [CAPITAL_JOURNAL]: {
    'six-col': {
      prefix: 'tcj-six-col-header',
      imgUrl:
        'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/documentcloud/wick_pagination/notice_pdfs/1738788807575_tcj.pdf',
      columns: 6
    },
    'four-col': {
      prefix: 'tcj-four-col-header',
      imgUrl:
        'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/documentcloud/wick_pagination/notice_pdfs/1738789524180_tcj.pdf',
      columns: 4
    },
    'three-col': {
      prefix: 'tcj-three-col-header',
      imgUrl:
        'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/documentcloud/wick_pagination/notice_pdfs/1738789992258_tcj.pdf',
      columns: 3
    },
    'two-col': {
      prefix: 'tcj-two-col-header',
      imgUrl:
        'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/documentcloud/wick_pagination/notice_pdfs/1738790624312_tcj.pdf',
      columns: 2
    }
  },
  [ANCHORAGE_PRESS]: {},
  [ARIZONA_DAILY_SUN]: {
    'six-col': {
      prefix: 'azds-six-col-header',
      imgUrl:
        'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/documentcloud/wick_pagination/notice_pdfs/1738786560612_azds.pdf',
      columns: 6
    },
    'four-col': {
      prefix: 'azds-four-col-header',
      imgUrl:
        'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/documentcloud/wick_pagination/notice_pdfs/1738789151905_azds.pdf',
      columns: 4
    },
    'three-col': {
      prefix: 'azds-three-col-header',
      imgUrl:
        'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/documentcloud/wick_pagination/notice_pdfs/1738789722539_azds.pdf',
      columns: 3
    },
    'two-col': {
      prefix: 'azds-two-col-header',
      imgUrl:
        'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/documentcloud/wick_pagination/notice_pdfs/1738790214446_azds.pdf',
      columns: 2
    }
  },
  [SIDNEY_HERALD]: {},
  [COPPER_ERA_GREENLEE_COUNTY]: {},
  [DELTA_COUNTY_INDEPENDENT]: {
    'six-col': {
      prefix: 'dci-six-col-header',
      imgUrl:
        'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/documentcloud/wick_pagination/notice_pdfs/1738786748230_dci.pdf',
      columns: 6
    },
    'four-col': {
      prefix: 'dci-four-col-header',
      imgUrl:
        'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/documentcloud/wick_pagination/notice_pdfs/1738789238097_dci.pdf',
      columns: 4
    },
    'three-col': {
      prefix: 'dci-three-col-header',
      imgUrl:
        'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/documentcloud/wick_pagination/notice_pdfs/1738789739548_dci.pdf',
      columns: 3
    },
    'two-col': {
      prefix: 'dci-two-col-header',
      imgUrl:
        'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/documentcloud/wick_pagination/notice_pdfs/1738790231727_dci.pdf',
      columns: 2
    }
  },
  [WILLISTON_HERALD_MEDIA]: {
    'six-col': {
      prefix: 'twh-six-col-header',
      imgUrl:
        'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/documentcloud/wick_pagination/notice_pdfs/1738788902049_twh_tsh.pdf',
      columns: 6
    },
    'four-col': {
      prefix: 'twh-four-col-header',
      imgUrl:
        'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/documentcloud/wick_pagination/notice_pdfs/1738789569535_twh_tsh.pdf',
      columns: 4
    },
    'three-col': {
      prefix: 'twh-three-col-header',
      imgUrl:
        'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/documentcloud/wick_pagination/notice_pdfs/1738790032313_twh_tsh.pdf',
      columns: 3
    },
    'two-col': {
      prefix: 'twh-two-col-header',
      imgUrl:
        'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/documentcloud/wick_pagination/notice_pdfs/1738790663436_twh_tsh.pdf',
      columns: 2
    }
  },
  [GREEN_VALLEY_NEWS]: {
    'six-col': {
      prefix: 'gvn-six-col-header',
      imgUrl:
        'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/documentcloud/wick_pagination/notice_pdfs/1738788422447_gvn.pdf',
      columns: 6
    },
    'four-col': {
      prefix: 'gvn-four-col-header',
      imgUrl:
        'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/documentcloud/wick_pagination/notice_pdfs/1738789368949_gvn.pdf',
      columns: 4
    },
    'three-col': {
      prefix: 'gvn-three-col-header',
      imgUrl:
        'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/documentcloud/wick_pagination/notice_pdfs/1738789844331_gvn.pdf',
      columns: 3
    },
    'two-col': {
      prefix: 'gvn-two-col-header',
      imgUrl:
        'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/documentcloud/wick_pagination/notice_pdfs/1738790317027_gvn.pdf',
      columns: 2
    }
  },
  [MAT_SU_VALLEY_FRONTIERSMAN]: {
    'six-col': {
      prefix: 'tfm-six-col-header',
      imgUrl:
        'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/documentcloud/wick_pagination/notice_pdfs/1738788870045_tfm.pdf',
      columns: 6
    },
    'four-col': {
      prefix: 'tfm-four-col-header',
      imgUrl:
        'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/documentcloud/wick_pagination/notice_pdfs/1738789541687_tfm.pdf',
      columns: 4
    },
    'three-col': {
      prefix: 'tfm-three-col-header',
      imgUrl:
        'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/documentcloud/wick_pagination/notice_pdfs/1738790012174_tfm.pdf',
      columns: 3
    },
    'two-col': {
      prefix: 'tfm-two-col-header',
      imgUrl:
        'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/documentcloud/wick_pagination/notice_pdfs/1738790644123_tfm.pdf',
      columns: 2
    }
  },
  [WENATCHEE_WORLD]: {
    'six-col': {
      prefix: 'wwd-six-col-header',
      imgUrl:
        'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/documentcloud/wick_pagination/notice_pdfs/1738789078298_wwd.pdf',
      columns: 6
    },
    'four-col': {
      prefix: 'wwd-four-col-header',
      imgUrl:
        'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/documentcloud/wick_pagination/notice_pdfs/1738789610715_wwd.pdf',
      columns: 4
    },
    'three-col': {
      prefix: 'wwd-three-col-header',
      imgUrl:
        'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/documentcloud/wick_pagination/notice_pdfs/1738790072602_wwd.pdf',
      columns: 3
    },
    'two-col': {
      prefix: 'wwd-two-col-header',
      imgUrl:
        'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/documentcloud/wick_pagination/notice_pdfs/1738790701729_wwd.pdf',
      columns: 2
    }
  },
  [QUINCY_VALLEY_POST_REGISTER]: {},
  [FERGUS_FALLS_DAILY_JOURNAL]: {
    'six-col': {
      prefix: 'ffj-six-col-header',
      imgUrl:
        'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/documentcloud/wick_pagination/notice_pdfs/1738788386230_ffj.pdf',
      columns: 6
    },
    'four-col': {
      prefix: 'ffj-four-col-header',
      imgUrl:
        'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/documentcloud/wick_pagination/notice_pdfs/1738789350985_ffj.pdf',
      columns: 4
    },
    'three-col': {
      prefix: 'ffj-three-col-header',
      imgUrl:
        'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/documentcloud/wick_pagination/notice_pdfs/1738789823669_ffj.pdf',
      columns: 3
    },
    'two-col': {
      prefix: 'ffj-two-col-header',
      imgUrl:
        'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/documentcloud/wick_pagination/notice_pdfs/1738790300724_ffj.pdf',
      columns: 2
    }
  },
  [THE_ARGUS_OBSERVER]: {},
  [HERALD_REVIEW_MEDIA]: {
    'six-col': {
      prefix: 'svh-six-col-header',
      imgUrl:
        'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/documentcloud/wick_pagination/notice_pdfs/1738788741382_svh.pdf',
      columns: 6
    },
    'four-col': {
      prefix: 'svh-four-col-header',
      imgUrl:
        'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/documentcloud/wick_pagination/notice_pdfs/1738789468953_svh.pdf',
      columns: 4
    },
    'three-col': {
      prefix: 'svh-three-col-header',
      imgUrl:
        'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/documentcloud/wick_pagination/notice_pdfs/1738789948564_svh.pdf',
      columns: 3
    },
    'two-col': {
      prefix: 'svh-two-col-header',
      imgUrl:
        'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/documentcloud/wick_pagination/notice_pdfs/1738790585428_svh.pdf',
      columns: 2
    }
  },
  [WAHPETON_DAILY_NEWS]: {
    'six-col': {
      prefix: 'wdn-six-col-header',
      imgUrl:
        'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/documentcloud/wick_pagination/notice_pdfs/1738788999354_wdn.pdf',
      columns: 6
    },
    'four-col': {
      prefix: 'wdn-four-col-header',
      imgUrl:
        'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/documentcloud/wick_pagination/notice_pdfs/1738789591160_wdn.pdf',
      columns: 4
    },
    'three-col': {
      prefix: 'wdn-three-col-header',
      imgUrl:
        'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/documentcloud/wick_pagination/notice_pdfs/1738790051379_wdn.pdf',
      columns: 3
    },
    'two-col': {
      prefix: 'wdn-two-col-header',
      imgUrl:
        'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/documentcloud/wick_pagination/notice_pdfs/1738790685252_wdn.pdf',
      columns: 2
    }
  },
  [NOGALES_INTERNATIONAL]: {
    'six-col': {
      prefix: 'nog-six-col-header',
      imgUrl:
        'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/documentcloud/wick_pagination/notice_pdfs/1738788621244_nog.pdf',
      columns: 6
    },
    'four-col': {
      prefix: 'nog-four-col-header',
      imgUrl:
        'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/documentcloud/wick_pagination/notice_pdfs/1738789446740_nog.pdf',
      columns: 4
    },
    'three-col': {
      prefix: 'nog-three-col-header',
      imgUrl:
        'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/documentcloud/wick_pagination/notice_pdfs/1738789927426_nog.pdf',
      columns: 3
    },
    'two-col': {
      prefix: 'nog-two-col-header',
      imgUrl:
        'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/documentcloud/wick_pagination/notice_pdfs/1738790566654_nog.pdf',
      columns: 2
    }
  }
};

export type SpacerItems = typeof SpacerData;
