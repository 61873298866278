import React from 'react';

import { useAppSelector } from 'redux/hooks';
import { selectUser } from 'redux/auth';
import LoadingState from 'components/LoadingState';
import { exists } from 'lib/types';
import RegisterIndividualHeader from './RegisterIndividualHeader';
import RegisterIndividualForm from './RegisterIndividualForm';

export default function RegisterIndividual() {
  const user = useAppSelector(selectUser);
  if (!exists(user)) return <LoadingState />;
  return (
    <div className="max-w-xl mx-auto pb-8 px-4">
      <RegisterIndividualHeader />
      <RegisterIndividualForm user={user} />
    </div>
  );
}
