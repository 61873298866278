import useSafeAsyncEffect from 'lib/frontend/hooks/useSafeAsyncEffect';
import { safeAsync, safeGetOrThrow } from 'lib/safeWrappers';
import { ERef } from 'lib/types';
import { Ad } from 'lib/types/ad';
import { OrderFilingType } from 'lib/types/filingType';
import { MadlibDataType } from 'lib/types/madlib';
import { wrapError, wrapSuccess } from 'lib/types/responses';
import { columnObjectsAreEqual, safeStringify } from 'lib/utils/stringify';
import { useEffect, useState } from 'react';
import { fetchMadlibsConfig } from 'routes/madlib/helpers/config';
import { getBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { AdEditorData } from '../../steps/DraftContent/FormattedEditorWrapper';
import { getInitialMadlibData } from './prefillMadlibs';

const DEFAULT_MADLIB_DATA: MadlibDataType = {
  templateData: {},
  questionTemplateData: {}
};

type UseMadlibsProps<T extends Ad> = {
  filingType: ERef<OrderFilingType>;
  adData: Partial<T>;
  onChange: (update: AdEditorData) => void;
};

export const useMadlibs = <T extends Ad>({
  filingType,
  adData,
  onChange
}: UseMadlibsProps<T>) => {
  const [allQuestionsAnswered, setAllQuestionsAnswered] = useState(false);
  const [renderedHtml, setRenderedHtml] = useState(adData.content || '');
  const [madlibData, setMadlibData] = useState(
    adData.madlibData ?? DEFAULT_MADLIB_DATA
  );
  const [validationResults, setValidationResults] = useState<
    Record<string, boolean>
  >({});
  const [madlibIsComplete, setMadlibIsComplete] = useState(false);

  const {
    value: madlibProps,
    isError: madlibPropsError,
    isLoading: madlibPropsLoading
  } = useSafeAsyncEffect({
    fetchData: async () => {
      const { response: filingTypeSnap, error: filiingTypeError } =
        await safeGetOrThrow(filingType);
      if (filiingTypeError) {
        return wrapError(filiingTypeError);
      }

      const madlibConfigPath = filingTypeSnap.data().madlib;
      if (!madlibConfigPath) {
        return wrapSuccess(null);
      }

      const {
        response: templateAndQuestions,
        error: templateAndQuestionsError
      } = await safeAsync(() => fetchMadlibsConfig(madlibConfigPath))();
      if (templateAndQuestionsError) {
        return wrapError(templateAndQuestionsError);
      }

      const {
        template: madlibTemplate,
        questions,
        questionConfig
      } = templateAndQuestions;
      const configFileMissing =
        !!madlibConfigPath && !(madlibTemplate && questions);
      if (configFileMissing) {
        return wrapError(
          new Error(
            'Madlib template or questions missing for order filing type'
          )
        );
      }

      /**
       * Automatically prefill madlib data based on order information
       * This is critical to improving the experience for death notice submission!
       */
      const enablePrefilling = getBooleanFlag(
        LaunchDarklyFlags.ENABLE_PRE_FILLING_MADLIB_DATA_FOR_DEATH_NOTICES
      );
      const shouldPrefillData = columnObjectsAreEqual(
        madlibData,
        DEFAULT_MADLIB_DATA
      );
      if (shouldPrefillData && enablePrefilling) {
        const defaultMadlibData = getInitialMadlibData(adData, questions);
        if (defaultMadlibData) {
          setMadlibData(defaultMadlibData);
        }
      }

      return wrapSuccess({
        madlibConfigPath,
        madlibTemplate,
        questions,
        questionConfig
      });
    },
    dependencies: [filingType?.id]
  });

  /**
   * TODO(web): I think eventually we should be able to remove this useEffect, but that will
   * likely require some refactoring of the MadlibEditor, which I don't want to do while that component
   * is shared by the notice & order placemnt flows. When we migrate notices over to the orders schema, we
   * can consolidate simplify the code in this hook and that component.
   */
  useEffect(() => {
    /**
     * These checks prevent us from setting blank madlib data on non-madlib obits & classifieds unnecessarily
     * (this has no known adverse side effects, but could get confusing to someone looking at the data)
     */
    const madlibDataIsEmptyAndUnedited =
      columnObjectsAreEqual(madlibData, DEFAULT_MADLIB_DATA) &&
      !adData.madlibData;
    const contentIsEmptyAndUnedited = !renderedHtml && !adData.content;
    onChange({
      ...(contentIsEmptyAndUnedited ? {} : { content: renderedHtml }),
      ...(madlibDataIsEmptyAndUnedited ? {} : { madlibData })
    });
  }, [safeStringify(madlibData), renderedHtml]);

  useEffect(() => {
    if (
      madlibData.templateData &&
      Object.keys(madlibData.templateData).length
    ) {
      const noInvalidData = Object.entries(madlibData.templateData).every(
        ([key, input]) => !!input && validationResults[key] !== false
      );
      setMadlibIsComplete(noInvalidData && allQuestionsAnswered);
    }
  }, [safeStringify(madlibData), safeStringify(validationResults)]);

  return {
    madlibProps,
    madlibPropsError,
    madlibPropsLoading,
    allQuestionsAnswered,
    madlibData,
    madlibIsComplete,
    setAllQuestionsAnswered,
    setValidationResults,
    setRenderedHtml,
    setMadlibData
  };
};
