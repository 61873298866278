import { Autocomplete } from 'lib/components/Autocomplete';
import { Product } from 'lib/enums';
import { useState, useContext, useEffect, useMemo } from 'react';
import { PublisherLocationFilter } from 'routes/placeScroll/ConfirmPublisher/PublisherLocationFilter';
import { getFirebaseContext } from 'utils/firebase';
import { NewspaperOrder, NewspaperOrderStatus } from 'lib/types/newspaperOrder';
import { PublishingMedium } from 'lib/enums/PublishingMedium';
import { PRODUCT_TO_NAME } from 'lib/enums/Product';
import { getModelFromSnapshot } from 'lib/model';
import { getOrThrow } from 'lib/utils/refs';
import { OrganizationModel } from 'lib/model/objects/organizationModel';

import { OrderModel } from 'lib/model/objects/orderModel';
import { FilingTypeModel } from 'lib/model/objects/filingTypeModel';
import { Ad, CategoryChoiceOption } from 'lib/types/ad';
import { isDefined } from 'lib/helpers';
import { useAppSelector } from 'redux/hooks';
import { selectIsPublisher } from 'redux/auth';
import { EOrganization, ERef, ESnapshotExists, ETemplate } from 'lib/types';
import { uniqBy } from 'lodash';
import { ResponseOrError, wrapError, wrapSuccess } from 'lib/types/responses';
import { useBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { ColumnSelectOption } from 'lib/components/ColumnSelect';
import { LayoutModel } from 'lib/model/objects/layoutModel';
import LoadingState from 'components/LoadingState';
import MultiStepHeader from '../../components/MultiStepHeader';
import { NewspapersContext } from '../../contexts/NewspapersContext';
import { NewspaperOrdersFormData } from '../../PlacementFlowStepSelector';
import PublisherCard from './PublisherCard';
import PublisherMadlibQuestions, {
  getMadlibConfigForPublisherCard
} from './PublisherMadlibQuestions';
import {
  getColorSettingsVisibility,
  getShouldForceGrayscale
} from '../colorHelpers';
import { getDefaultLayout } from '../layoutHelpers';
import { getJobcaseEnablement } from '../jobcaseHelpers';
import { useAdForm } from '../../contexts/AdFormStatusProvider';

async function getNewspaperOrder(
  newspaper: OrganizationModel,
  publishingMedium: PublishingMedium,
  inputData: Partial<Ad>,
  productFilingType: FilingTypeModel,
  adTemplate: ERef<ETemplate>
): Promise<ResponseOrError<Partial<NewspaperOrder>>> {
  const supportedLayouts =
    productFilingType.getSortedSupportedLayouts(inputData);
  const defaultLayout = getDefaultLayout(supportedLayouts);
  const layoutModel = new LayoutModel(defaultLayout);

  const [rateError, rate] = await productFilingType.getRate({
    isDisplayLayout: layoutModel.isDisplayLayout
  });
  if (rateError) {
    return wrapError(rateError);
  }
  const rateData = rate.data();
  const shouldForceGrayscale = getShouldForceGrayscale(rateData);
  const colorEnablement = getColorSettingsVisibility(rateData);
  const jobcaseEnablement = getJobcaseEnablement(rateData);

  return wrapSuccess({
    newspaper: newspaper.ref,
    adTemplate: productFilingType?.modelData.template || adTemplate,
    publishingDates: [],
    status: NewspaperOrderStatus.DRAFT,
    publishingMedium,
    colorEnablement,
    jobcaseEnablement,
    colorOptions: {
      isGrayscale: shouldForceGrayscale,
      backgroundColor: 'transparent',
      borderColor: 'transparent'
    },
    // check if productFilingType exists before adding it to the order so we don't have an undefined field in firestore
    ...(productFilingType
      ? {
          filingType: productFilingType.ref,
          layout: defaultLayout
        }
      : {})
  });
}

const getTitleAndDescriptionForAddPublisherSetup = ({
  showAddPublications,
  isPublisher,
  productTypeName
}: {
  showAddPublications: boolean;
  isPublisher: boolean;
  productTypeName: string;
}) => {
  if (isPublisher) {
    if (showAddPublications) {
      return {
        title: `Where would you like to publish your ${productTypeName}?`,
        description:
          'Choose additional publications and publishing methods so this order will get more reach.'
      };
    }
    return {
      title: `How would you like to place your order?`,
      description:
        'This selection controls how your ad will be sent to pagination.'
    };
  }

  if (showAddPublications) {
    return {
      title: `Where would you like to publish your ${productTypeName}?`,
      description: "Choose the newspapers that you'd like to publish with."
    };
  }
  return {
    title: `How would you like to place your order?`,
    description:
      'This selection controls how your ad will be sent to pagination.'
  };
};

type SelectPublicationProps = {
  newspaperOrdersFormData: NewspaperOrdersFormData;
  onNewspaperOrdersFormDataChange: React.Dispatch<
    React.SetStateAction<NewspaperOrdersFormData>
  >;
  product: Product;
  inputData: Partial<Ad>;
  orderModel: OrderModel;
  onUpdateAd: (update: Partial<Ad>) => void;
  publishersLoading: boolean;
  autoSelectedPaper: ColumnSelectOption<string> | null;
  showAddPublications: boolean;
  availablePublisherOptions: ColumnSelectOption<string>[];
  stateOptions: number[];
  stateFilter: number | undefined;
  setStateFilter: React.Dispatch<React.SetStateAction<number | undefined>>;
};

function SelectPublication({
  newspaperOrdersFormData,
  onNewspaperOrdersFormDataChange,
  product,
  inputData,
  onUpdateAd,
  publishersLoading,
  autoSelectedPaper,
  showAddPublications,
  availablePublisherOptions,
  stateOptions,
  stateFilter,
  setStateFilter
}: SelectPublicationProps) {
  const productTypeName = PRODUCT_TO_NAME[product].singular.toLowerCase();
  const { clearUserError, setFormStatus } = useAdForm();
  const isPublisher = useAppSelector(selectIsPublisher);

  const completeMadlibsInPublicationSelectionStep = useBooleanFlag(
    LaunchDarklyFlags.SHIFT_ORDER_MADLIB_QUESTIONS_TO_FILING_TYPE_SELECTION
  );

  const context = getFirebaseContext();

  const organizations = context.organizationsRef();

  const {
    publishersAvailableForPlacement,
    filingTypeByPublisherAndPublishingMedium
  } = useContext(NewspapersContext);

  const [selectedPublishers, setSelectedPublishers] = useState(
    uniqBy(
      publishersAvailableForPlacement.filter(newspaper =>
        newspaperOrdersFormData.some(o => o.newspaper?.id === newspaper.id)
      ),
      'id'
    )
  );

  const [searchedNewspaperId, setSearchedNewspaperId] = useState<
    string | undefined
  >(undefined);

  const searchedNewspaperLoaded = publishersAvailableForPlacement.find(
    o => o.id === searchedNewspaperId
  );

  const loading = publishersLoading;

  function removeNewspaper(newspaperId: string) {
    onNewspaperOrdersFormDataChange(
      newspaperOrdersFormData.filter(no => no.newspaper?.id !== newspaperId)
    );
    setSelectedPublishers(existingPublishers =>
      existingPublishers.filter(p => p.id !== newspaperId)
    );
    setSearchedNewspaperId(undefined);
  }

  const addMediumToOrder = async (
    newspaper: ESnapshotExists<EOrganization>,
    medium: PublishingMedium
  ) => {
    const relevantPublishingData =
      filingTypeByPublisherAndPublishingMedium[newspaper.id]?.[
        inputData.filingTypeName as CategoryChoiceOption
      ]?.[medium];
    if (!relevantPublishingData) return;
    const relevantFilingType = relevantPublishingData.filingType;
    const { adTemplate } = relevantPublishingData;
    const newOrderResult = await getNewspaperOrder(
      getModelFromSnapshot(OrganizationModel, getFirebaseContext(), newspaper),
      medium,
      inputData,
      relevantFilingType,
      adTemplate
    );
    if (newOrderResult.error) {
      return newOrderResult;
    }
    onNewspaperOrdersFormDataChange([
      ...newspaperOrdersFormData,
      newOrderResult.response
    ]);
  };

  async function addNewspaper(newspaperId: string) {
    const publisherSnapshot = await getOrThrow(organizations.doc(newspaperId));
    setSelectedPublishers(existingPublishers => [
      ...existingPublishers,
      publisherSnapshot
    ]);
    setSearchedNewspaperId(newspaperId);
    const validMediums =
      filingTypeByPublisherAndPublishingMedium[newspaperId]?.[
        inputData.filingTypeName as CategoryChoiceOption
      ];
    const firstValidMedium = Object.keys(
      validMediums || {}
    )[0] as PublishingMedium;
    await addMediumToOrder(publisherSnapshot, firstValidMedium);
    clearUserError();
  }

  useEffect(() => {
    if (newspaperOrdersFormData.length || !autoSelectedPaper) {
      return;
    }
    // Auto selected paper is either the publisher's active organization or the paper associated with the custom subdomain
    if (autoSelectedPaper) {
      void addNewspaper(autoSelectedPaper.value);
    }
  }, [autoSelectedPaper?.value]);

  const selectedNewspaperIds = selectedPublishers.map(p => p.id);

  const { title, description } = getTitleAndDescriptionForAddPublisherSetup({
    showAddPublications,
    isPublisher,
    productTypeName
  });

  const { madlibError, madlibFilingType } = useMemo(() => {
    return getMadlibConfigForPublisherCard(
      filingTypeByPublisherAndPublishingMedium,
      publishersAvailableForPlacement,
      inputData,
      newspaperOrdersFormData
    );
  }, [newspaperOrdersFormData.map(o => o.filingType?.id).join(',')]);

  return (
    <>
      <MultiStepHeader title={title} description={description} />
      <div className="grid grid-cols-12 gap-6">
        {showAddPublications && (
          <>
            <div className="col-span-12 md:col-span-6 xl:col-span-8">
              <Autocomplete
                id="selectPublisher"
                labelText="Add publications"
                placeholder={`Choose one or more places to run your ${productTypeName}`}
                value={
                  searchedNewspaperLoaded && !loading ? searchedNewspaperId : ''
                }
                options={availablePublisherOptions}
                onChange={async newspaperId => {
                  setFormStatus('loading');
                  if (
                    newspaperOrdersFormData.find(
                      o => o.newspaper?.id === newspaperId
                    )
                  ) {
                    removeNewspaper(newspaperId);
                  } else if (newspaperId) {
                    await addNewspaper(newspaperId);
                  }
                  setFormStatus('idle');
                }}
                loading={loading}
                required={publishersAvailableForPlacement.length === 0}
                validationMessages={{
                  valueMissing: 'Please select a publisher'
                }}
                selectedOptionsValues={selectedNewspaperIds}
                showCheckBoxForSelectedItems
              />
            </div>
            <div className="col-span-12 md:col-span-6 xl:col-span-4 pt-8">
              <PublisherLocationFilter
                stateOptions={stateOptions}
                onStateChange={state => {
                  setStateFilter(state);
                }}
                activeFilters={{ stateFilter }}
              />
            </div>
          </>
        )}

        {selectedPublishers.length === 0 && (
          <div className="col-span-12 flex items-center justify-center">
            <LoadingState isFullScreen={false} />
            <input required className="hidden" />
          </div>
        )}

        <div className="col-span-12 flex flex-col gap-8">
          {selectedPublishers.map(newspaper => {
            const ordersForNewspaper = newspaperOrdersFormData.filter(
              o => o.newspaper?.id === newspaper.id
            );
            const publisherDataForFilingTypes =
              filingTypeByPublisherAndPublishingMedium[newspaper.id]?.[
                inputData.filingTypeName as CategoryChoiceOption
              ];
            const validMediums = Object.keys(
              publisherDataForFilingTypes || {}
            ) as PublishingMedium[];
            const publisherProductPublishingSettings = validMediums
              .map(
                medium =>
                  publisherDataForFilingTypes?.[medium]
                    ?.productPublishingSetting
              )
              .filter(isDefined);

            return (
              <div key={newspaper.id} className="col-span-12">
                <PublisherCard
                  inputData={inputData}
                  onRemovePublisher={() => {
                    removeNewspaper(newspaper.id);
                  }}
                  required={
                    loading ||
                    newspaper.data().name === autoSelectedPaper?.label
                  }
                  newspaper={newspaper}
                  validProductPublishingSettings={publisherProductPublishingSettings.map(
                    pps => pps.modelData
                  )}
                  publishingMediums={ordersForNewspaper
                    .map(o => o.publishingMedium)
                    .filter(isDefined)}
                  onPublishingMediumsChange={async value => {
                    setFormStatus('loading');
                    const missingMedium = !ordersForNewspaper.some(
                      o => o.publishingMedium === value
                    );
                    if (missingMedium) {
                      await addMediumToOrder(newspaper, value);
                    } else {
                      onNewspaperOrdersFormDataChange(
                        newspaperOrdersFormData.filter(
                          o =>
                            o.newspaper?.id !== newspaper.id ||
                            o.publishingMedium !== value
                        )
                      );
                    }
                    setFormStatus('idle');
                  }}
                  packages={[]}
                  onPackageChange={() => {}}
                />
              </div>
            );
          })}
        </div>
        {madlibFilingType && completeMadlibsInPublicationSelectionStep && (
          <PublisherMadlibQuestions
            madlibFilingType={madlibFilingType}
            madlibError={madlibError}
            onUpdateAd={onUpdateAd}
            inputData={inputData}
          />
        )}
      </div>
    </>
  );
}

export default SelectPublication;
