import { DeadlineSettings } from 'lib/types/deadlines';
import { useActiveOrganizationListener } from 'hooks/useActiveOrganizationListener';
import { exists } from 'lib/types';
import LoadingState from 'components/LoadingState';
import { Product } from 'lib/enums';
import { Alert } from 'lib/components/Alert';
import { ScheduleChanges } from './ScheduleChanges';

function NoticeScheduleChanges() {
  const activeOrganization = useActiveOrganizationListener();

  if (!exists(activeOrganization)) return <LoadingState />;

  const { deadlines } = activeOrganization.data();

  if (!deadlines) {
    return (
      <Alert status="info" id="no-deadlines" title="No Deadlines Configured">
        Please configure regular deadlines for this organization before
        configuring schedule overrides.
      </Alert>
    );
  }

  return (
    <ScheduleChanges
      productLine={Product.Notice}
      activeOrganization={activeOrganization}
      onChange={async (newOverrides: Record<string, DeadlineSettings>) => {
        await activeOrganization.ref.update({
          deadlineOverrides: newOverrides
        });
      }}
      deadlines={deadlines}
      deadlineOverrides={activeOrganization.data().deadlineOverrides}
    />
  );
}

export default NoticeScheduleChanges;
