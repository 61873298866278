import React from 'react';
import { ESnapshotExists } from 'lib/types';
import { SyncEvent, SyncTriggerEvent } from 'lib/types/events';
import { useFirestoreSnapshot } from 'lib/frontend/hooks/useFirestoreSnapshot';
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import { ColumnButton } from 'lib/components/ColumnButton';
import { LoadingSpinner } from 'lib/components/LoadingSpinner';
import LinkTo from 'components/LinkTo';
import moment from 'moment';
import { getDynamicRoute } from 'lib/frontend/utils/router';
import { NOTICE_ROUTES } from 'router/routes';

type SyncErrorCardProps = {
  event: ESnapshotExists<SyncEvent<SyncTriggerEvent>>;
  timezone: string;
};

/**
 * This frontend component is likely only necessary for initial proof of concepts, but still wanted
 * to follow best practices and extract this to a unique component (which also means being able to
 * use the `useFirestoreSnapshot` hook).
 */
export function SyncErrorCard({ event, timezone }: SyncErrorCardProps) {
  const notice = useFirestoreSnapshot(event.data().notice);
  const {
    createdAt,
    data: { syncMessage }
  } = event.data();

  return (
    <div className="pl-8 pr-2.5 py-5 flex border-b">
      <div>
        <div className="w-12 h-12 flex justify-center items-center bg-column-red-50 rounded-full">
          <ArrowPathIcon className="w-6 h-6 text-column-red-500" />
        </div>
      </div>
      <div className="pl-4 flex-col">
        <div key={event.id}>
          {notice?.data().referenceId ? (
            <div className="text-sm">
              <p className="text-column-gray-500 font-semibold">
                {notice?.data().referenceId}{' '}
                <span className="text-column-gray-400 font-medium">
                  failed to sync
                </span>
              </p>
            </div>
          ) : (
            <LoadingSpinner />
          )}
        </div>
        <div className="pt-2 text-sm text-column-gray-500">
          <p>{syncMessage}</p>
        </div>
        <div className="text-xs text-column-gray-400 font-medium pt-2">
          <p>
            {moment(createdAt.toDate())
              .tz(timezone)
              .format('DD MMMM, YYYY [at] hh:mm A')}{' '}
            {`${moment.tz(timezone).format('z')}`}
          </p>
        </div>
        <div className="flex pt-5 justify-end">
          <LinkTo
            href={getDynamicRoute(NOTICE_ROUTES.DETAIL, { id: notice?.id })}
          >
            <ColumnButton
              id="view-notice-button"
              buttonText={'View notice'}
              secondary
              type="button"
            />
          </LinkTo>
        </div>
      </div>
    </div>
  );
}
