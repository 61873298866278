import { Outlet, useLocation } from 'react-router-dom';
import {
  selectAuthLoading,
  selectHasClassifiedsActive,
  selectHasObituariesActive,
  selectHasPublicNoticesActive,
  selectUser,
  selectUserAuth
} from 'redux/auth';
import { useAppSelector } from 'redux/hooks';
import { isColumnUser } from 'lib/helpers';
import { useEffect } from 'react';
import LoadingState from 'components/LoadingState';
import { MAIN_ROUTES } from 'router/routes';
import {
  useRedirectToLanding,
  useRedirectToLogin,
  useMaybeRedirectToRegistration
} from './hooks';

type RequireAuthProps = {
  internalOnly?: boolean;
};

export function RequireAuth({ internalOnly }: RequireAuthProps) {
  const { pathname } = useLocation();

  const auth = useAppSelector(selectUserAuth);
  const authLoading = useAppSelector(selectAuthLoading);
  const user = useAppSelector(selectUser);
  const hasPublicNoticesActive = useAppSelector(selectHasPublicNoticesActive);
  const hasObituariesActive = useAppSelector(selectHasObituariesActive);
  const hasClassifiedsActive = useAppSelector(selectHasClassifiedsActive);

  const redirectToLogin = useRedirectToLogin();
  const redirectToLanding = useRedirectToLanding();
  const maybeRedirectToRegistration = useMaybeRedirectToRegistration();
  const maybeRedirect = async () => {
    if (authLoading) {
      return;
    }

    const shouldRedirectToLanding =
      (pathname === '/' && auth) ||
      (pathname === MAIN_ROUTES.NOTICES && !hasPublicNoticesActive) ||
      (pathname === MAIN_ROUTES.OBITUARIES && !hasObituariesActive) ||
      (pathname === MAIN_ROUTES.CLASSIFIEDS && !hasClassifiedsActive);

    if (shouldRedirectToLanding) {
      return redirectToLanding();
    }

    if (!auth) {
      return redirectToLogin();
    }

    const redirectedToRegistration = await maybeRedirectToRegistration();

    if (redirectedToRegistration) {
      return;
    }

    if (!internalOnly) {
      return;
    }

    if (!user || !isColumnUser(user)) {
      return redirectToLanding();
    }
  };

  useEffect(() => {
    void maybeRedirect();
  }, [auth, authLoading, user, pathname]);

  return authLoading ? <LoadingState /> : <Outlet />;
}
