import { ReceiptPercentIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import React from 'react';

import { CreateInvoiceRequest } from 'lib/types/requests';
import ColumnDatePicker from 'components/ColumnDatePicker';
import { TextField } from 'lib/components/TextField';
import { Label } from 'lib/components/Label';
import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';
import LabeledSwitch from 'lib/components/LabeledSwitch';
import CurrencyTextField from 'lib/components/CurrencyTextField';
import { MAX_MEMO_LENGTH } from 'lib/types/invoices';

type InvoiceDetailsCardProps = {
  updateInvoiceCreationData: (updates: Partial<CreateInvoiceRequest>) => void;
  invoiceCreationData: CreateInvoiceRequest;
};

export default function InvoiceDetailsCard({
  updateInvoiceCreationData,
  invoiceCreationData
}: InvoiceDetailsCardProps) {
  const {
    requireUpFrontPayment,
    orderNumber,
    customMemo,
    lineItems,
    dueDate,
    taxPct
  } = invoiceCreationData;

  const updateLineItemAmount = (newValue: number, index: number) => {
    updateInvoiceCreationData({
      lineItems: lineItems.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            amount: newValue
          };
        }
        return item;
      })
    });
  };

  return (
    <CardGridLayout
      header={{
        title: 'Provide Invoice Details',
        description:
          'Confirm invoice details and preview them on the right-hand side 👉'
      }}
    >
      <GridInput fullWidth>
        <TextField
          labelText="Should we give this invoice an order number?"
          placeholder="Leave empty if you don't have an order number."
          value={orderNumber}
          onChange={newValue =>
            updateInvoiceCreationData({ orderNumber: newValue })
          }
          id="order-number"
        />
      </GridInput>
      <GridInput fullWidth>
        <div className="bg-base-1 w-full border rounded-lg p-8">
          <div className="grid grid-cols-4 gap-6 uppercase font-medium text-column-gray-400 text-left pb-4">
            <div className="col-span-2">Item</div>
            <div className="col-span-1">Qty</div>
            <div className="col-span-1">Amount</div>
          </div>
          <div className="flex flex-col gap-4">
            {lineItems.map((lineItem, index) => (
              <div className="grid grid-cols-4 items-center gap-6" key={index}>
                <div className="col-span-2">
                  <TextField
                    labelText=""
                    disabled
                    value={`Publication Fee - ${moment(lineItem.date).format(
                      'MMM DD, YYYY'
                    )}`}
                    id={`line-item-description-${index}`}
                  />
                  <span></span>
                </div>
                <div className="col-span-1">
                  <TextField
                    labelText=""
                    disabled
                    value="1"
                    id={`line-item-qty-${index}`}
                  />
                </div>
                <div className="col-span-1">
                  <CurrencyTextField
                    initialValue={lineItem.amount}
                    labelText=""
                    onChange={newValue => updateLineItemAmount(newValue, index)}
                    id={`line-item-${index}`}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </GridInput>
      <GridInput>
        <TextField
          labelText="What tax rate should we apply to this invoice?"
          value={(taxPct || 0).toString()}
          onChange={newValue =>
            updateInvoiceCreationData({ taxPct: parseFloat(newValue) })
          }
          suffix={
            <ReceiptPercentIcon className="w-6 h-6 text-column-gray-400" />
          }
          step={0.01}
          max={25}
          min={0}
          id="tax-percent"
        />
      </GridInput>
      <GridInput>
        <Label id="due-date-label">
          What date should the invoice be due on?
        </Label>
        <ColumnDatePicker
          className="rounded-md border-column-gray-200 flex justify-center cursor-pointer"
          placeholderText="What should we set as the invoice due date?"
          momentFormat="MMM DD"
          shouldDisableDate={date => {
            if (!date) return true;
            if (moment(date).isBefore(moment())) return true;
            return false;
          }}
          onChange={date => {
            if (date) {
              updateInvoiceCreationData({ dueDate: date });
            }
          }}
          format="MMM dd"
          value={dueDate}
          fullWidth
        />
      </GridInput>
      <GridInput fullWidth>
        <LabeledSwitch
          value={requireUpFrontPayment}
          onChange={newValue => {
            updateInvoiceCreationData({
              requireUpFrontPayment: newValue
            });
          }}
          label="Should we require up front payment for this invoice?"
          description="If set to true, customers must pay for the notice before deadline."
        />
      </GridInput>
      <GridInput fullWidth>
        <TextField
          labelText="What should we include as a custom memo on this invoice?"
          placeholder="Leave empty if you do not want to include a custom memo."
          value={customMemo}
          maxLength={MAX_MEMO_LENGTH}
          onChange={newValue =>
            updateInvoiceCreationData({ customMemo: newValue })
          }
          id="custom-memo"
        />
      </GridInput>
    </CardGridLayout>
  );
}
