import {
  DeadlineSettings,
  deadlineOverrideToDeadlineOverrideDate,
  getDeadlineOverridePublicationDateFromKey
} from 'lib/types/deadlines';
import { PublishingSetting } from 'lib/types/publishingSetting';
import { ResponseOrError, wrapError, wrapSuccess } from 'lib/types/responses';
import {
  getDeadlineTimeForRegularDeadline,
  getRelevantDeadline
} from 'lib/utils/deadlines';
import moment from 'moment';

export type ScheduleChangesTableItem = {
  publicationDate: Date;
  deadlineDate: Date;
  publish: boolean;
  displayOffset?: number;
};
export type ScheduleChangesFormItem = ScheduleChangesTableItem & {
  isEdit: boolean;
};

export const publisherDeadlineOverridesToScheduleChangesTableData = (
  publisherOverrideSettings: Record<string, DeadlineSettings> = {},
  pubDateFilter: DateFilter = { type: 'all-future' }
): ScheduleChangesTableItem[] => {
  return Object.entries(publisherOverrideSettings)
    .map<ScheduleChangesTableItem>(([key, settings]) => {
      return {
        deadlineDate: deadlineOverrideToDeadlineOverrideDate(key, settings),
        publicationDate: getDeadlineOverridePublicationDateFromKey(key),
        publish: settings.publish,
        displayOffset: settings.displayOffset
      };
    })
    .filter(({ publicationDate }) => {
      const pubDate = moment(publicationDate);
      const today = moment();
      switch (pubDateFilter.type) {
        case 'all-future':
          return pubDate.isAfter(today, 'd');
        case 'all-past':
          return pubDate.isBefore(today, 'd');
        case 'all':
        case 'specific':
        default:
          return true;
      }
    })
    .sort((a, b) => a.publicationDate.getTime() - b.publicationDate.getTime());
};

export const scheduleChangesTableDataToPublisherDeadline = (
  {
    publicationDate,
    publish,
    deadlineDate,
    displayOffset
  }: ScheduleChangesTableItem,
  deadlineTime?: string
): DeadlineSettings => {
  return {
    dayEnum: publicationDate.getDay() + 1,
    deadline: {
      dayEnum: deadlineDate.getDay() + 1,
      time: deadlineTime ?? moment(deadlineDate).format('HH:mm')
    },
    weeks: moment(publicationDate)
      .startOf('day')
      .diff(moment(deadlineDate).startOf('day'), 'weeks'),
    publish,
    ...(displayOffset ? { displayOffset } : {})
  };
};

type DateFilter =
  | { type: 'all' }
  | { type: 'all-future' }
  | { type: 'all-past' }
  | { type: 'specific'; date: Date };
export type ScheduleChangesTableFilters = {
  publicationDate: DateFilter;
  deadlineDate: DateFilter;
  publish: boolean | null;
};

export const DEFAULT_SCHEDULE_CHANGES_FILTER: ScheduleChangesTableFilters = {
  publicationDate: { type: 'all-future' },
  deadlineDate: { type: 'all' },
  publish: null
};

export const shouldShowScheduleChangesTableItem = (
  item: ScheduleChangesTableItem,
  _search: string,
  { publicationDate, deadlineDate, publish }: ScheduleChangesTableFilters
): boolean => {
  if (typeof publish === 'boolean' && publish !== item.publish) {
    return false;
  }

  if (publicationDate.type === 'specific') {
    const itemPubDateMoment = moment(item.publicationDate);
    const filterPubDateMoment = moment(publicationDate.date);

    if (!itemPubDateMoment.isSame(filterPubDateMoment, 'date')) {
      return false;
    }
  }

  if (deadlineDate.type === 'specific') {
    const itemDeadlineMoment = moment(item.deadlineDate);
    const filterDeadlineMoment = moment(deadlineDate.date);

    if (!itemDeadlineMoment.isSame(filterDeadlineMoment, 'date')) {
      return false;
    }
  }

  return true;
};

export const getNumScheduleChangesFilters = ({
  publicationDate,
  deadlineDate,
  publish
}: ScheduleChangesTableFilters) => {
  let numFilters = 0;

  if (publicationDate.type === 'specific') {
    ++numFilters;
  }

  if (deadlineDate.type === 'specific') {
    ++numFilters;
  }

  if (typeof publish === 'boolean') {
    ++numFilters;
  }

  return numFilters;
};

export const getFilterHasChanges = (
  {
    publicationDate: prevPubDate,
    deadlineDate: prevDeadline,
    publish: prevPublish
  }: ScheduleChangesTableFilters,
  {
    publicationDate: currPubDate,
    deadlineDate: currDeadline,
    publish: currPublish
  }: ScheduleChangesTableFilters
): boolean => {
  if (prevPubDate.type !== currPubDate.type) {
    return true;
  }

  if (prevPubDate.type === 'specific' && currPubDate.type === 'specific') {
    const prevPubMoment = moment(prevPubDate.date);
    const currPubMoment = moment(currPubDate.date);

    if (!prevPubMoment.isSame(currPubMoment, 'date')) {
      return true;
    }
  }

  if (prevDeadline.type !== currDeadline.type) {
    return true;
  }

  if (prevDeadline.type === 'specific' && currDeadline.type === 'specific') {
    const prevDeadlineMoment = moment(prevDeadline.date);
    const currDeadlineMoment = moment(currDeadline.date);

    if (!prevDeadlineMoment.isSame(currDeadlineMoment, 'date')) {
      return true;
    }
  }

  if (prevPublish !== currPublish) {
    return true;
  }

  return false;
};

export const getScheduleChangeSettingsForNewPublicationDate = ({
  publicationDate,
  deadlines,
  deadlineOverrides,
  iana_timezone
}: {
  publicationDate: Date;
  deadlines: DeadlineSettings[];
  deadlineOverrides: PublishingSetting['deadlineOverrides'] | undefined;
  iana_timezone: string;
}): ResponseOrError<ScheduleChangesFormItem> => {
  const deadline = getRelevantDeadline(
    publicationDate,
    iana_timezone,
    deadlines,
    deadlineOverrides
  );

  if (!deadline) {
    return wrapError(
      new Error('Could not identify deadline for publication date')
    );
  }

  const deadlineMoment = getDeadlineTimeForRegularDeadline(
    publicationDate,
    deadline,
    iana_timezone
  );

  return wrapSuccess({
    publicationDate,
    deadlineDate: deadlineMoment.toDate(),
    publish: deadline.publish,
    isEdit: false
  });
};
