import classNames from 'classnames';
import { EnumOutputItem } from 'lib/types/enums';

type OccupationSelectProps = {
  setOccupationType: (occupationType: number) => void;
  occupationType?: number;
  occupationItemEnum: EnumOutputItem;
  img: any;
};

export default function OccupationSelectItem({
  occupationItemEnum,
  setOccupationType,
  occupationType,
  img
}: OccupationSelectProps) {
  return (
    <div
      className={classNames(
        'border text-center rounded-lg shadow-sm bg-white p-4',
        {
          'border-column-primary-500':
            occupationType === occupationItemEnum.value
        }
      )}
    >
      <input
        required
        className="appearance-none"
        id={occupationItemEnum.key}
        type="radio"
        checked={occupationType === occupationItemEnum.value}
        name="occupation"
        onChange={() => {
          setOccupationType(occupationItemEnum.value);
        }}
      />
      <label
        htmlFor={occupationItemEnum.key}
        className="h-full flex flex-col justify-between font-medium pb-8"
      >
        <img
          src={img}
          role="presentation"
          alt={occupationItemEnum.label}
          className="block mb-3"
        />
        <div>{occupationItemEnum.label}</div>
      </label>
    </div>
  );
}
