import moment from 'moment';
import { PublishingSettingModel } from 'lib/model/objects/publishingSettingModel';
import { EOrganization, ESnapshotExists } from 'lib/types';
import { getClosestFuturePublishingDay } from 'lib/utils/deadlines';
import { PublicationDateSettings } from 'lib/types/filingType';

export const getNextPublishingDate = (
  newspaper: ESnapshotExists<EOrganization>,
  publishingSetting: PublishingSettingModel,
  date: Date
) => {
  const { deadlines, deadlineOverrides } = publishingSetting.modelData;
  const { iana_timezone: newspaperTimezone } = newspaper.data();
  const nextPotentialPublishingDate = moment(date).add(1, 'days').toDate();
  const nextPublishingDate = getClosestFuturePublishingDay(
    deadlines || [],
    deadlineOverrides || {},
    newspaperTimezone,
    {},
    newspaper,
    nextPotentialPublishingDate
  );
  return moment(nextPublishingDate).format('YYYY-MM-DD');
};

export function calculateDefaultPublishingDates({
  newspaperSnap,
  publishingSetting,
  publicationDateSettings,
  startingDate,
  targetPublicationDateCount
}: {
  newspaperSnap: ESnapshotExists<EOrganization>;
  publishingSetting: PublishingSettingModel;
  publicationDateSettings?: PublicationDateSettings;
  startingDate?: Date;
  targetPublicationDateCount?: number;
}): string[] {
  const newPublishingDates: string[] = [
    startingDate
      ? moment(startingDate).format('YYYY-MM-DD')
      : getNextPublishingDate(newspaperSnap, publishingSetting, new Date())
  ];

  if (publicationDateSettings) {
    const numberOfDatesToFill =
      targetPublicationDateCount ||
      publicationDateSettings.initialPublicationDates ||
      publicationDateSettings.minimumPublicationDates ||
      1;

    for (let i = newPublishingDates.length; i < numberOfDatesToFill; i++) {
      const previousPublishingDateString =
        newPublishingDates[newPublishingDates.length - 1];

      const previousPublishingDate = moment(previousPublishingDateString)
        .tz(newspaperSnap.data().iana_timezone)
        .toDate();

      newPublishingDates.push(
        getNextPublishingDate(
          newspaperSnap,
          publishingSetting,
          previousPublishingDate
        )
      );
    }
  }

  return newPublishingDates;
}
