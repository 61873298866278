import StackedLayout from 'layouts/StackedLayout';
import { TableLayout } from 'lib/components/TableLayout';
import React, { useState } from 'react';
import { ListConversationRequestSchema } from 'lib/types/api';
import { z } from 'zod';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { safeStringify } from 'lib/utils/stringify';
import api from 'api';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import ToastActions from 'redux/toast';
import useDebounce from 'lib/frontend/hooks/useDebounce';
import Drawer from 'lib/components/Drawer';
import TabGroup, { TabOption } from 'lib/components/Tabs';
import { LoadingSpinner } from 'lib/components/LoadingSpinner';
import { SearchableExpressEmailConversationRecord } from 'lib/types/searchable';
import { fuzzyStringContains } from 'lib/utils/strings';
import { selectUser } from 'redux/auth';
import { isColumnUser } from 'lib/helpers';
import ConversationRequestDrawer from './ConversationRequestDrawer';
import ConversationRequestRow from './ConversationRequestRow';

const PAGE_SIZE = 30;

const MISSING_PLACEMENT_TAB = 'missing-placement';
const MISSING_INVOICE_TAB = 'missing-invoice';
const MISSING_UP_FRONT_PAYMENT_TAB = 'missing-up-front-payment';
const MISSING_BILLED_PAYMENT_TAB = 'missing-billed-payment';
const MISSING_AFFIDAVIT_TAB = 'missing-affidavit';
const COMPLETE_TAB = 'complete';
const RUNNING_TAB = 'running';
const CANCELLED_TAB = 'cancelled';
const ALL_TAB = 'all';
type HEADER_TAB =
  | typeof MISSING_PLACEMENT_TAB
  | typeof MISSING_INVOICE_TAB
  | typeof MISSING_UP_FRONT_PAYMENT_TAB
  | typeof MISSING_BILLED_PAYMENT_TAB
  | typeof MISSING_AFFIDAVIT_TAB
  | typeof COMPLETE_TAB
  | typeof RUNNING_TAB
  | typeof CANCELLED_TAB
  | typeof ALL_TAB;

const COLUMN_USER_CONVERSATION_REQUEST_TABS: TabOption<HEADER_TAB>[] = [
  {
    id: MISSING_PLACEMENT_TAB,
    label: 'Awaiting Placement',
    enabled: true
  },
  {
    id: MISSING_INVOICE_TAB,
    label: 'Pending Proof and Price',
    enabled: true
  },
  {
    id: MISSING_UP_FRONT_PAYMENT_TAB,
    label: 'Up Front Payment',
    enabled: true
  },
  {
    id: RUNNING_TAB,
    label: 'Running',
    enabled: true
  },
  {
    id: MISSING_AFFIDAVIT_TAB,
    label: 'Awaiting Affidavit',
    enabled: true
  },
  {
    id: MISSING_BILLED_PAYMENT_TAB,
    label: 'Billed Payment',
    enabled: true
  },
  {
    id: COMPLETE_TAB,
    label: 'Complete',
    enabled: true
  },
  {
    id: CANCELLED_TAB,
    label: 'Cancelled',
    enabled: true
  },
  {
    id: ALL_TAB,
    label: 'All',
    enabled: true
  }
];

const NON_COLUMN_CONVERSATION_REQUEST_TABS: TabOption<HEADER_TAB>[] = [
  {
    id: MISSING_PLACEMENT_TAB,
    label: 'Awaiting Placement',
    enabled: true
  },
  {
    id: RUNNING_TAB,
    label: 'Running',
    enabled: true
  },
  {
    id: MISSING_AFFIDAVIT_TAB,
    label: 'Awaiting Affidavit',
    enabled: true
  },
  {
    id: COMPLETE_TAB,
    label: 'Complete',
    enabled: true
  },
  {
    id: CANCELLED_TAB,
    label: 'Cancelled',
    enabled: true
  },
  {
    id: ALL_TAB,
    label: 'All',
    enabled: true
  }
];

const HEADER_COPY = {
  [MISSING_PLACEMENT_TAB]: {
    subtitle:
      'Placement requests that have not been confirmed with a publisher.',
    title: 'Unplaced Requests'
  },
  [MISSING_INVOICE_TAB]: {
    subtitle:
      'Placement requests that have been received by the publisher but are awaiting proof and price.',
    title: 'Pending Proof and Price'
  },
  [MISSING_UP_FRONT_PAYMENT_TAB]: {
    subtitle:
      'Placement requests that have received proof but are awaiting up front payment.',
    title: 'Requests requiring up front payment'
  },
  [MISSING_BILLED_PAYMENT_TAB]: {
    subtitle:
      'Placement requests that have completed their run and now require a billed payment.',
    title: 'Requests awaiting billed payment'
  },
  [RUNNING_TAB]: {
    subtitle:
      'Placement requests that are currently running and awaiting their final publication date.',
    title: 'Running requests'
  },
  [MISSING_AFFIDAVIT_TAB]: {
    subtitle: 'Placement requests that are missing an affidavit.',
    title: 'Requests awaiting affidavit'
  },
  [COMPLETE_TAB]: {
    subtitle:
      'Placement requests that have been confirmed with a publisher and have been placed.',
    title: 'Completed requests'
  },
  [CANCELLED_TAB]: {
    subtitle:
      'Placement requests that have been cancelled by the publisher or by you.',
    title: 'Cancelled requests'
  },
  [ALL_TAB]: {
    subtitle:
      'Track all of the placement requests that you have made to Column.',
    title: 'All placement requests'
  }
};

export default function ConversationRequests() {
  const dispatch = useAppDispatch();
  const [conversationRequestQuery] = useState<
    z.infer<typeof ListConversationRequestSchema>['query']
  >({
    page: 1,
    limit: 500,
    query: ''
  });

  const user = useAppSelector(selectUser);
  const conversationRequestTabs =
    user && isColumnUser(user)
      ? COLUMN_USER_CONVERSATION_REQUEST_TABS
      : NON_COLUMN_CONVERSATION_REQUEST_TABS;

  const [selectedConversationRequestTab, setSelectedConversationRequestTab] =
    useState(conversationRequestTabs[0]);
  const [drawerConversationRequest, setDrawerConversationRequest] =
    useState<SearchableExpressEmailConversationRecord | null>(null);

  const debouncedConversationRequestQuery = useDebounce(
    conversationRequestQuery,
    1000
  );

  const {
    value: conversationRequestsData,
    isLoading: conversationRequestsLoading
  } = useAsyncEffect({
    fetchData: async () => {
      const { error: searchError, response: searchResponse } =
        await api.safePost('conversation-requests/search', {
          query: conversationRequestQuery,
          tab: selectedConversationRequestTab.id
        });

      if (searchError) {
        dispatch(
          ToastActions.toastError({
            headerText: 'Error fetching conversation requests',
            bodyText:
              'The Column team has been notified of this issue. Please try again later.'
          })
        );
      } else if (searchResponse) {
        return searchResponse;
      }
    },
    dependencies: [
      safeStringify(debouncedConversationRequestQuery),
      selectedConversationRequestTab.id
    ]
  });

  return (
    <StackedLayout>
      <main className="bg-white sm:rounded-lg border border-column-gray-100 shadow-column-2">
        <TabGroup
          onClickTab={tab => setSelectedConversationRequestTab(tab)}
          activeTab={selectedConversationRequestTab}
          tabs={conversationRequestTabs}
          id="conversation-requests-tabs"
        />
        {conversationRequestsLoading ? (
          <div className="flex justify-center items-center h-full p-12">
            <LoadingSpinner />
          </div>
        ) : (
          <TableLayout
            clickable={{
              onClick: conversationRequest => {
                setDrawerConversationRequest(conversationRequest);
              }
            }}
            header={{
              subtitle: HEADER_COPY[selectedConversationRequestTab.id].subtitle,
              title: HEADER_COPY[selectedConversationRequestTab.id].title
            }}
            columns={[
              <th key="invoice-id" className="font-medium w-56">
                Placement
              </th>,
              <th key="notice-name" className="font-medium w-56">
                <span className="ml-12">Notice</span>
              </th>,
              <th key="notice-name" className="font-medium w-56">
                <span className="ml-12">Invoice</span>
              </th>,
              <th key="notice-name" className="font-medium w-56">
                <span className="ml-12">Affidavit</span>
              </th>
            ]}
            renderRow={conversationRequest => (
              <ConversationRequestRow
                conversationRequest={conversationRequest}
              />
            )}
            data={conversationRequestsData ?? []}
            loading={!conversationRequestsData || conversationRequestsLoading}
            id="invoices"
            filterable={{
              shouldShowTableItem: (tableItem, search) => {
                if (!search) {
                  return true;
                }
                const relevantFields = [
                  tableItem.notice_id,
                  tableItem.conversation_id,
                  tableItem.conversation_subject,
                  tableItem.custom_id,
                  tableItem.external_id
                ];
                for (const field of relevantFields) {
                  if (field && fuzzyStringContains(field, search)) {
                    return true;
                  }
                }
                return false;
              }
            }}
            pagination={{
              pageSize: PAGE_SIZE
            }}
          />
        )}
      </main>
      <Drawer
        header={
          <div className="text-lg font-medium text-column-grey-500">
            {drawerConversationRequest?.conversation_subject}
          </div>
        }
        open={!!drawerConversationRequest}
        onClose={() => setDrawerConversationRequest(null)}
      >
        {drawerConversationRequest && (
          <ConversationRequestDrawer
            conversationRequest={drawerConversationRequest}
          />
        )}
      </Drawer>
    </StackedLayout>
  );
}
