import { AdRate } from '../types';
import { isNoticeRate } from '../types/rates';

export const getConvenienceFeeSplitFromRate = (
  rate: AdRate,
  options: { enableFeeSplitsForOrders: boolean }
) => {
  return options.enableFeeSplitsForOrders || isNoticeRate(rate)
    ? rate.convenienceFeeSplit
    : undefined;
};
