import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { REGISTER_ROUTES } from 'router/routes';
import { getFirebaseContext } from 'utils/firebase';
import { OccupationType, OrganizationType } from 'lib/enums';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { ColumnButton } from 'lib/components/ColumnButton';
import { selectUser } from 'redux/auth';
import ToastActions from 'redux/toast';
import { exists } from 'lib/types';
import {
  notifyOrganizationAdminUsers,
  sendOrganizationInvites
} from 'routes/register/individual/RegisterIndividualForm';
import classNames from 'classnames';
import JoinExistingOrganization from './JoinExistingOrganization';
import AdvertiserRegistrationFooter from '../../AdvertiserRegistrationFooter';
import { SelectedOrganizationItem } from '../../types';
import JoinOrCreateNewOrganizationHeader from './JoinOrCreateOrganizationHeader';
import OrganizationRegistration from '../OrganizationRegistration';

type JoinOrCreateNewOrganizationProps = {
  occupationType: number;
  onBackClick: () => void;
};

export default function JoinOrCreateNewOrganization({
  occupationType,
  onBackClick
}: JoinOrCreateNewOrganizationProps) {
  const user = useAppSelector(selectUser);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [creatingNewOrganization, setCreatingNewOrganization] = useState(false);
  const [organizationsToJoin, setOrganizationsToJoin] = useState<
    SelectedOrganizationItem[]
  >([]);

  const onRequestToJoinOrganizations = async () => {
    if (!exists(user)) {
      return;
    }
    await sendOrganizationInvites(
      organizationsToJoin,
      user,
      getFirebaseContext()
    );
    await notifyOrganizationAdminUsers(user);
    dispatch(
      ToastActions.toastSuccess({
        headerText: 'Request sent',
        bodyText: `Requested to join ${organizationsToJoin
          .map(org => org.label)
          .join(', ')}`
      })
    );
    navigate(REGISTER_ROUTES.INDIVIDUAL);
  };

  return (
    <div className="p-4 max-w-128 mx-auto">
      <JoinOrCreateNewOrganizationHeader />
      <JoinExistingOrganization
        onStartJoiningNewOrganization={() => setCreatingNewOrganization(false)}
        onUpdateOrganizationsToJoin={setOrganizationsToJoin}
        creatingNewOrganization={creatingNewOrganization}
        organizationsToJoin={organizationsToJoin}
        occupationType={occupationType}
      />
      <p className="text-column-gray-500 font-medium text-md leading-6 my-3 text-center">
        or
      </p>
      <div
        className={classNames({
          hidden: !creatingNewOrganization
        })}
      >
        {occupationType === OccupationType.lawyer.value && (
          <OrganizationRegistration
            organizationType={OrganizationType.law_firm.value}
          />
        )}
        {occupationType === OccupationType.government_official.value && (
          <OrganizationRegistration
            organizationType={OrganizationType.government.value}
          />
        )}
        {occupationType === OccupationType.other_organization.value && (
          <OrganizationRegistration
            organizationType={OrganizationType.other_organization.value}
          />
        )}

        {occupationType === OccupationType.funeral_director.value && (
          <OrganizationRegistration
            organizationType={OrganizationType.funeral_home.value}
          />
        )}
        <div className="mb-24" />
      </div>
      {!creatingNewOrganization && (
        <>
          <div className="w-full">
            <ColumnButton
              id="create-new-org"
              buttonText="Create a new organization"
              secondary
              size="xl"
              fullWidth
              disabled={organizationsToJoin.length > 0}
              onClick={() => setCreatingNewOrganization(true)}
              type="button"
            />
          </div>
          <div className="w-full">
            <AdvertiserRegistrationFooter
              id="next-to-join"
              backButtonText="Go back"
              nextButtonText={
                organizationsToJoin.length ? 'Submit Request' : 'Continue'
              }
              onBackButtonClick={onBackClick}
              onNextButtonClick={() => onRequestToJoinOrganizations()}
              disableNextButton={!organizationsToJoin.length}
            />
          </div>
        </>
      )}
    </div>
  );
}
