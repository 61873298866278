import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import MultiDateSelector from 'routes/ads/place/components/MultiDateSelector';
import { Alert } from 'lib/components/Alert';
import { InputAccessories } from 'lib/components/InputAccessories';
import { EOrganization, ESnapshotExists } from 'lib/types';
import { PublicationDateSettings } from 'lib/types/filingType';
import { getDeadlineText } from 'routes/placeScroll/ConfirmScheduleForm';
import { PublishingSettingModel } from 'lib/model/objects/publishingSettingModel';
import { Product } from 'lib/enums';
import { PRODUCT_TO_NAME } from 'lib/enums/Product';
import { getDateForDateStringInTimezone } from 'lib/utils/dates';
import { Tooltip } from 'lib/components/Tooltip';
import { NewspaperOrderWithPublishingSettings } from './validation';

type SelectScheduleProps = {
  newspaperOrder: NewspaperOrderWithPublishingSettings['newspaperOrder'];
  newspaper: ESnapshotExists<EOrganization>;
  publishingSetting: PublishingSettingModel;
  handlePublicationDateChange: (newPublishingDates: string[]) => void;
  product: Product;
  disableEdits?: boolean;
  disableChangeNumberOfPublicationDates?: boolean;
  publicationDateSettings?: PublicationDateSettings;
};

function SelectSchedule({
  newspaperOrder,
  newspaper,
  publishingSetting,
  handlePublicationDateChange,
  product,
  disableEdits,
  disableChangeNumberOfPublicationDates,
  publicationDateSettings
}: SelectScheduleProps) {
  const { iana_timezone: newspaperTimezone } = newspaper.data();

  const { deadlines = [], deadlineOverrides = {} } =
    publishingSetting.modelData ?? {};

  const selectedDates = newspaperOrder.publishingDates;

  // This data is used as a temporary prop to leverage our deadline helper functions for notices
  const deadlineConfig = selectedDates.length
    ? getDeadlineText({
        publicationDate: getDateForDateStringInTimezone({
          dayString: selectedDates[0],
          timezone: newspaperTimezone
        }),
        deadlines,
        deadlineOverrides,
        newspaperTimezone,
        newspaper,
        isPublisher: false,
        alsoShowDeadlineWithoutDisplayOffset: false,
        placement: {}
      })
    : null;

  const productTypeName = PRODUCT_TO_NAME[product].singular.toLowerCase();

  return (
    <>
      {deadlineConfig && (
        <div className="pb-4">
          <Alert
            id="newspaper-schedule-deadline"
            title={deadlineConfig.text}
            status={deadlineConfig.status}
            icon={<ExclamationCircleIcon className="h-5 w-5" />}
          />
        </div>
      )}
      <div>
        <InputAccessories
          id="schedule-ad"
          labelText={`When should your ${productTypeName} be published?`}
        >
          <div className={'py-1'}>
            {disableChangeNumberOfPublicationDates && (
              <Tooltip helpText="For marked as paid ads publication dates cannot be edited.">
                <ExclamationCircleIcon className="h-5 w-5" />
              </Tooltip>
            )}
          </div>
          <div className={'grid grid-cols-2 gap-x-6 gap-y-3'}>
            <MultiDateSelector
              selectedDates={selectedDates}
              onSelectedDatesChange={handlePublicationDateChange}
              publicationDateSettings={publicationDateSettings}
              newspaper={newspaper}
              publishingSetting={publishingSetting}
              disabled={disableEdits || disableChangeNumberOfPublicationDates}
            />
          </div>
        </InputAccessories>
      </div>
    </>
  );
}

export default SelectSchedule;
