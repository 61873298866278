import { TextField } from 'lib/components/TextField';
import { GridInput } from 'lib/components/Card/Grid';
import { PublicationDateSettings } from 'lib/types/filingType';
import CheckboxInput from 'lib/components/Checkbox/CheckboxInput';

function validatePublicationDateSettings(settings: PublicationDateSettings) {
  const {
    initialPublicationDates,
    minimumPublicationDates,
    maximumPublicationDates
  } = settings;

  const isInitialPublicationDatesWithinLowerLimit =
    !initialPublicationDates ||
    !minimumPublicationDates ||
    initialPublicationDates >= minimumPublicationDates;
  const isInitialPublicationDatesWithinUpperLimit =
    !initialPublicationDates ||
    !maximumPublicationDates ||
    initialPublicationDates <= maximumPublicationDates;
  const isMinimumPublicationDatesWithinUpperLimit =
    !minimumPublicationDates ||
    !maximumPublicationDates ||
    minimumPublicationDates <= maximumPublicationDates;

  return {
    isInitialPublicationDatesWithinLowerLimit,
    isInitialPublicationDatesWithinUpperLimit,
    isMinimumPublicationDatesWithinUpperLimit
  };
}

type EditPublicationDateSettingsFormProps = {
  id?: string;
  publicationDateSettings: PublicationDateSettings | undefined;
  onChange: (updatedPublicationDateSettings: PublicationDateSettings) => void;
};

function EditPublicationDateSettingsForm({
  id = 'edit-publication-date-settings-form',
  publicationDateSettings = {},
  onChange
}: EditPublicationDateSettingsFormProps) {
  const {
    initialPublicationDates,
    minimumPublicationDates,
    maximumPublicationDates,
    requireConsecutivePublicationDates
  } = publicationDateSettings;

  const {
    isInitialPublicationDatesWithinLowerLimit,
    isInitialPublicationDatesWithinUpperLimit,
    isMinimumPublicationDatesWithinUpperLimit
  } = validatePublicationDateSettings(publicationDateSettings);

  return (
    <>
      <GridInput fullWidth>
        <TextField
          id={`${id}-initial-publication-dates`}
          labelText="Default number of publication dates"
          noteText="The number of publication dates that will be selected by default when creating a new order."
          type="number"
          min={1}
          placeholder={
            minimumPublicationDates ? String(minimumPublicationDates) : '1'
          }
          value={initialPublicationDates ? String(initialPublicationDates) : ''}
          onChange={value => {
            onChange({
              ...publicationDateSettings,
              initialPublicationDates: value ? Number(value) : null
            });
          }}
          errorText={(() => {
            if (!isInitialPublicationDatesWithinLowerLimit) {
              return 'Default number of publication dates cannot be less than the minimum number of publication dates.';
            }

            if (!isInitialPublicationDatesWithinUpperLimit) {
              return 'Default number of publication dates cannot be greater than the maximum number of publication dates.';
            }
          })()}
        />
      </GridInput>
      <TextField
        id={`${id}-minimum-publication-dates`}
        labelText="Minimum number of publication dates"
        type="number"
        min={1}
        placeholder="1"
        value={minimumPublicationDates ? String(minimumPublicationDates) : ''}
        onChange={value => {
          onChange({
            ...publicationDateSettings,
            minimumPublicationDates: value ? Number(value) : null
          });
        }}
        errorText={(() => {
          if (!isMinimumPublicationDatesWithinUpperLimit) {
            return 'Minimum number of publication dates cannot be greater than the maximum number of publication dates.';
          }

          if (!isInitialPublicationDatesWithinLowerLimit) {
            return 'Minimum number of publication dates cannot be greater than the default number of publication dates.';
          }
        })()}
      />
      <TextField
        id={`${id}-maximum-publication-dates`}
        labelText="Maximum number of publication dates"
        placeholder="Unlimited"
        type="number"
        min={1}
        value={maximumPublicationDates ? String(maximumPublicationDates) : ''}
        onChange={value => {
          onChange({
            ...publicationDateSettings,
            maximumPublicationDates: value ? Number(value) : null
          });
        }}
        errorText={(() => {
          if (!isMinimumPublicationDatesWithinUpperLimit) {
            return 'Maximum number of publication dates cannot be less than the minimum number of publication dates.';
          }

          if (!isInitialPublicationDatesWithinUpperLimit) {
            return 'Maximum number of publication dates cannot be less than the default number of publication dates.';
          }
        })()}
      />
      <GridInput fullWidth>
        <CheckboxInput
          id={`${id}-require-consecutive-publication-dates`}
          labelText="Require consecutive publication dates"
          checked={requireConsecutivePublicationDates || false}
          onChange={value => {
            onChange({
              ...publicationDateSettings,
              requireConsecutivePublicationDates: value
            });
          }}
        />
      </GridInput>
    </>
  );
}

export default EditPublicationDateSettingsForm;
