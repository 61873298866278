import React from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';

import { ColumnButton } from 'lib/components/ColumnButton';
import { getRedirect } from 'utils/urls';

export default function ExistingUserRegistrationHeader() {
  const navigate = useNavigate();
  return (
    <div className="flex justify-between py-4 px-6 bg-white border border-column-gray-100 sticky top-0 z-100 bg-white">
      <div className="flex items-center">
        <ColumnButton
          startIcon={<XMarkIcon className="h-6 w-6" />}
          onClick={() => navigate(getRedirect())}
          size="md"
          type="button"
        />
        <span className="pl-4 text-column-gray-800 font-semibold text-xl">
          New Organization
        </span>
      </div>
    </div>
  );
}
