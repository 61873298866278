export const AutomatedAffidavitNotaries = {
  pam_baez: {
    value: 1,
    key: 'pam_baez',
    label: 'Pam Baez',
    email: 'p.baez820@gmail.com',
    state: 'Florida',
    county: 'Orange',
    proof_id: 'us_npqmz4r',
    active: true
  },
  rachael_schultz: {
    value: 2,
    key: 'rachael_schultz',
    label: 'Rachael Schultz',
    email: 'rschultz1974@hotmail.com',
    state: 'Florida',
    county: 'Charlotte',
    proof_id: 'us_nkjy86j',
    active: false
  },
  emmie_atwood: {
    value: 3,
    key: 'emmie_atwood',
    label: 'Emmie Atwood',
    email: 'emmie@column.us',
    state: 'Texas',
    county: 'Travis',
    proof_id: 'us_d7bk57r',
    active: false
  },
  shannea: {
    value: 4,
    key: 'shannea',
    label: 'Shannea Holmes',
    email: 'shomles85@icloud.com',
    state: 'New Jersey',
    county: 'Hudson',
    proof_id: 'us_d5686g5',
    active: false
  },
  mitzi_hill: {
    value: 5,
    key: 'mitzi_hill',
    label: 'Mitzi Hill',
    email: 'mitzihill13@gmail.com',
    state: 'Texas',
    county: 'Ellis',
    proof_id: 'us_ng9wzyb',
    active: false
  },
  nicole_burkholder: {
    value: 6,
    key: 'nicole_burkholder',
    label: 'Nicole Burkholder',
    email: 'njburkholder@gmail.com',
    state: 'Pennsylvania',
    county: 'Lancaster',
    proof_id: 'us_dwgopeq',
    active: true
  },
  robert_hollie_iii: {
    value: 7,
    key: 'robert_hollie_iii',
    label: 'Robert Hollie III',
    email: 'kevin@column.us',
    state: 'Texas',
    county: 'Bexar',
    proof_id: 'us_qjbag5m',
    active: false
  },
  destiny_jackson: {
    value: 8,
    key: 'destiny_jackson',
    label: 'Destiny Jackson',
    email: 'destinykjackson@gmail.com',
    state: 'Florida',
    county: 'Duval',
    proof_id: 'us_www998z',
    active: false
  },
  lauren_predmore: {
    value: 9,
    key: 'lauren_predmore',
    label: 'Lauren Predmore',
    email: 'lauren_predmore@yahoo.com',
    state: 'New York',
    county: 'Orange',
    proof_id: 'us_nowyb9o',
    active: false
  },
  liza_ortiz: {
    value: 10,
    key: 'liza_ortiz',
    label: 'Liza Ortiz',
    email: 'lortiz425@aol.com',
    state: 'New Jersey',
    county: 'Burlington',
    proof_id: 'us_5mjb3wj',
    active: true
  },
  sandra_clay: {
    value: 11,
    key: 'sandra_clay',
    label: 'Sandra Clay',
    email: 'sandiclay2001@gmail.com',
    state: 'Florida',
    county: 'Brevard',
    proof_id: 'us_pb6yxoe',
    active: false
  },
  sheri_smith: {
    value: 12,
    key: 'sheri_smith',
    label: 'Sheri Smith',
    email: 'sheri@greatermindsinc.org',
    state: 'Florida',
    county: 'Broward',
    proof_id: 'usxx64xbk',
    active: true
  },
  jessica_gordon_thompson: {
    value: 13,
    key: 'jessica_gordon_thompson',
    label: 'Jessica Gordon Thompson',
    email: 'jgordonthompson2009@gmail.com',
    state: 'Florida',
    county: 'Orange',
    proof_id: 'usnbzm7wm',
    active: true
  },
  sharonn_thomas_pope: {
    value: 14,
    key: 'sharonn_thomas_pope',
    label: 'Sharonn Thomas-Pope',
    email: 'sharonn.thomas@comcast.net',
    state: 'New Jersey',
    county: 'Camden',
    proof_id: 'usny6rgrp',
    active: true
  }
} as const;

export type AutomatedAffidavitNotaryType = typeof AutomatedAffidavitNotaries;

export type AutomatedAffidavitNotaryName =
  AutomatedAffidavitNotaryType[keyof AutomatedAffidavitNotaryType]['label'];

export type AutomatedAffidavitNotaryState =
  AutomatedAffidavitNotaryType[keyof AutomatedAffidavitNotaryType]['state'];

export type AutomatedAffidavitNotaryCounty =
  AutomatedAffidavitNotaryType[keyof AutomatedAffidavitNotaryType]['county'];

export type AutomatedAffidavitNotaryValue =
  AutomatedAffidavitNotaryType[keyof AutomatedAffidavitNotaryType]['value'];

export type AutomatedAffidavitNotaryProofID =
  AutomatedAffidavitNotaryType[keyof AutomatedAffidavitNotaryType]['proof_id'];

export type AutomatedAffidavitNotaryKey = keyof AutomatedAffidavitNotaryType;

export type AutomatedAffidavitNotaryEnum =
  (typeof AutomatedAffidavitNotaries)[AutomatedAffidavitNotaryKey];

export const stringIsNotaryKey = (
  signerKeyString: string
): signerKeyString is AutomatedAffidavitNotaryKey => {
  return Object.keys(AutomatedAffidavitNotaries).some(
    key => signerKeyString === key
  );
};
