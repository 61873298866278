import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadingSpinner } from 'lib/components/LoadingSpinner';
import { getLocationParams } from 'lib/frontend/utils/browser';
import { useAppDispatch } from 'redux/hooks';
import AuthActions from 'redux/auth';

export default function Impersonate() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const token = getLocationParams().get('token');
  useEffect(() => {
    if (token) {
      dispatch(AuthActions.loginToken(token));
      navigate('/');
    }
  }, [token]);

  return (
    <div className="w-full h-full flex flex-col items-center justify-center gap-4">
      {token && (
        <>
          <LoadingSpinner />
          <div>Signing in...</div>
        </>
      )}
      {!token && <div>Error: Missing token</div>}
    </div>
  );
}
