import { wrapError, wrapSuccess, ResponseOrError } from '../../types/responses';
import { EOrganization, ERef } from '../../types';
import { SnapshotModel } from '..';
import { Collections } from '../../constants';
import { Package, PackagePublisherSettings } from '../../types/package';
import { safeGetOrThrow } from '../../safeWrappers';
import { PublishingMedium } from '../../enums/PublishingMedium';
import { FilingTypeVisibility } from '../../enums';
import { OrganizationModel } from './organizationModel';

export class PackageModel extends SnapshotModel<
  Package,
  typeof Collections.packages
> {
  get type() {
    return Collections.packages;
  }

  public getPackagePublisherSettingByProductPublishingSettingId(
    productPublishingSettingId: string
  ) {
    return this.modelData.publisherSettings.find(
      publisherSetting =>
        publisherSetting.productPublishingSetting.id ===
        productPublishingSettingId
    );
  }

  async getPackagePublisherSetting({
    forPublisherOrganization,
    forPublishingMedium
  }: {
    forPublisherOrganization: OrganizationModel;
    forPublishingMedium: PublishingMedium;
  }): Promise<ResponseOrError<PackagePublisherSettings | null>> {
    const [productPublishingSettingError, productPublishingSetting] =
      await forPublisherOrganization.maybeFetchProductPublishingSetting({
        product: this.modelData.product,
        publishingMedium: forPublishingMedium
      });

    if (productPublishingSettingError) {
      return wrapError(productPublishingSettingError);
    }

    if (!productPublishingSetting) {
      return wrapError(
        Error('Product publishing setting not found for publisher')
      );
    }

    const relatedPublisherSetting =
      this.getPackagePublisherSettingByProductPublishingSettingId(
        productPublishingSetting.id
      );

    return wrapSuccess(relatedPublisherSetting || null);
  }

  get publisherOrganizationsAssociatedWithPackage() {
    const publisherOrganizations = new Set<ERef<EOrganization>>();
    publisherOrganizations.add(this.modelData.authorizedOrganization);
    this.modelData.publisherOrganizations?.forEach(publisherOrganization => {
      publisherOrganizations.add(publisherOrganization);
    });
    return Array.from(publisherOrganizations);
  }

  public async getPackageRate({
    forPublisherOrganization,
    forPublishingMedium
  }: {
    forPublisherOrganization: OrganizationModel;
    forPublishingMedium: PublishingMedium;
  }) {
    const [packagePublisherSettingError, packagePublisherSetting] =
      await this.getPackagePublisherSetting({
        forPublisherOrganization,
        forPublishingMedium
      });

    if (packagePublisherSettingError) {
      return wrapError(packagePublisherSettingError);
    }

    if (packagePublisherSetting?.defaultRate) {
      return await safeGetOrThrow(packagePublisherSetting.defaultRate);
    }

    return wrapSuccess(null);
  }

  public isVisibleToUser(isUserPublisher: boolean) {
    if (
      !this.modelData.visibility ||
      this.modelData.visibility === FilingTypeVisibility.all_users.value
    ) {
      return true;
    }

    if (
      this.modelData.visibility === FilingTypeVisibility.publisher_only.value
    ) {
      return isUserPublisher;
    }

    return false;
  }
}
