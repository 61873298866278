import { safeGetOrThrow } from '../safeWrappers';
import { ColumnService } from '../services/directory';
import { EFirebaseContext } from '../types';
import { getErrorReporter } from '../utils/errors';
import { NewspaperErrorInfo, ConfigurationError } from './ColumnErrors';

/**
 * Tries to get the newspaper name and parent newspaper name for a given newspaper ID, but replies with defaults if there is an error.
 * If the parent newspaper is not found, it will be undefined.
 *
 * This is a safe async function!  It will always return the correct type, and will never throw an error.
 */
export const getNewspaperErrorInfoForNewspaperIdWithDefaults = async (
  ctx: EFirebaseContext,
  newspaperId: string
): Promise<NewspaperErrorInfo> => {
  let newspaperName = 'Unknown';
  let parentNewspaperName;
  const [newspaperError, newspaper] = await safeGetOrThrow(
    ctx.organizationsRef().doc(newspaperId)
  );
  if (newspaperError) {
    getErrorReporter().logAndCaptureError(
      ColumnService.DATABASE,
      newspaperError,
      `Error getting newspaper ${newspaperId}`
    );
    // Don't return as we need to return the default values
  } else {
    newspaperName = newspaper.data().name;
    const parentNewspaperRef = newspaper.data().parent;
    const parentResult = parentNewspaperRef
      ? await safeGetOrThrow(parentNewspaperRef)
      : undefined;
    if (parentNewspaperRef && parentResult) {
      const [parentError, parent] = parentResult;
      if (parentError) {
        getErrorReporter().logAndCaptureError(
          ColumnService.DATABASE,
          parentError,
          `Error getting parent newspaper ${parentNewspaperRef.id}`
        );
        // Don't return as we still at least get the newspaper name
      } else {
        parentNewspaperName = parent.data().name;
      }
    }
  }
  return {
    newspaperId,
    newspaperName,
    parentNewspaperName
  };
};

export const missingAccountIdError = (
  newspaperInfo: NewspaperErrorInfo
): ConfigurationError => {
  return new ConfigurationError({
    message: `Newspaper is missing an accountId`,
    toFix: `Setup the Stripe connect account for newspaper and ensure the Stripe Connect ID is set in admin under Stripe Settings.`,
    newspaperInfo
  });
};
