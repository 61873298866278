import {
  Filler,
  PublisherPaginationSettings
} from '../../types/publisherPaginationSettings';
import { ESnapshotExists, ETemplate } from '../../types';
import { wrapError, wrapSuccess } from '../../types/responses';
import {
  OptimizerHyperparams,
  AutoPaginationRequestLayout,
  AutoLayoutRequestElement,
  LayoutBlockFixedElement
} from '../../types/api';

const getTotalColumnInches = (orderBlocks: AutoLayoutRequestElement[]) => {
  return orderBlocks.reduce((acc, notice) => {
    const biggestVariant = notice.variants.reduce((biggest, variant) => {
      return Math.max(biggest, variant.height * variant.width);
    }, 0);
    return acc + biggestVariant;
  }, 0);
};

export const layoutIsValidForOrders = (
  layout: AutoPaginationRequestLayout,
  template: ESnapshotExists<ETemplate>,
  orderBlocks: AutoLayoutRequestElement[],
  hyperparams: OptimizerHyperparams
) => {
  const totalFixedHeightArea = layout.fixed_elements.reduce((acc, element) => {
    return acc + element.height;
  }, 0);
  const totalOrderArea = getTotalColumnInches(orderBlocks);
  const areaIsFeasible =
    totalOrderArea + hyperparams.max_whitespace + totalFixedHeightArea >=
    layout.width * layout.height;
  if (!areaIsFeasible) {
    return false;
  }
  const widthIsFeasible = orderBlocks.some(block =>
    block.variants.some(variant => variant.width <= layout.width)
  );
  if (!widthIsFeasible) {
    return false;
  }

  const templatePageHeight = template.data().styles?.pageHeight ?? 0;
  const isTooTall = layout.height > templatePageHeight;
  if (isTooTall) {
    return false;
  }

  const layoutColumns = template.data().styles?.columnCount ?? 0;
  const isTooWide = layout.width > layoutColumns;
  if (isTooWide) {
    return false;
  }

  return true;
};

const fillerIsValidForLayout = (
  filler: Filler,
  layout: { columns: number }
) => {
  if (filler.columns === 3 && layout.columns === 4) {
    return false;
  }
  return true;
};

export const getLayoutsForAutoPaginationRequest = async (
  publisherPaginationSettings: ESnapshotExists<PublisherPaginationSettings>,
  hyperparams: OptimizerHyperparams,
  template: ESnapshotExists<ETemplate>,
  orderBlocks: AutoLayoutRequestElement[]
) => {
  const maxFillersForPublisherLayouts = 1;

  const { fillers } = publisherPaginationSettings.data();

  const layouts: AutoPaginationRequestLayout[] = publisherPaginationSettings
    .data()
    .allowedLayouts.map(allowedLayout => {
      const fixedElements: LayoutBlockFixedElement[] = [];
      const relevantHeader = publisherPaginationSettings
        .data()
        .headers.find(
          header => header.id === allowedLayout.layoutHeader?.headerId
        );
      if (relevantHeader) {
        for (let i = 0; i < allowedLayout.columns; i++) {
          fixedElements.push({
            x: i,
            y: 0,
            width: 1,
            height: 18,
            id: `${relevantHeader.id}-${i}`
          });
        }
      }
      const orLayout = {
        id: allowedLayout.id,
        width: allowedLayout.columns,
        height: allowedLayout.heightPixels,
        fixed_elements: fixedElements,
        max_whitespace: hyperparams.max_whitespace,
        optional_elements: fillers
          .filter(filler => fillerIsValidForLayout(filler, allowedLayout))
          .flatMap(filler =>
            Array.from({ length: maxFillersForPublisherLayouts }, (_, i) => ({
              ...filler,
              width: filler.columns,
              id: `${filler.name}-${allowedLayout.id}-${filler.id}-${i}`
            }))
          )
      };
      return orLayout;
    });

  // dynamically compute the max number of full page column layouts to consider
  const fullPageLayoutColumnInches =
    Math.floor(template.data().styles?.pageHeight ?? 0) *
    (template.data().styles?.columnCount ?? 1);
  const totalColumnInches = getTotalColumnInches(orderBlocks);
  const fullPageLayoutsToInclude = Math.ceil(
    totalColumnInches / fullPageLayoutColumnInches
  );

  for (let i = 0; i < fullPageLayoutsToInclude; i++) {
    const fixedElements: LayoutBlockFixedElement[] = [];
    const relevantHeader = publisherPaginationSettings
      .data()
      .headers.find(header => !!header.id);
    if (relevantHeader) {
      for (let i = 0; i < (template.data().styles?.columnCount || 1); i++) {
        fixedElements.push({
          x: i,
          y: 0,
          width: 1,
          height: 18,
          id: `${relevantHeader.id}-${i}`
        });
      }
    }
    layouts.push({
      id: `auto-paginate-${i}`,
      width: template.data().styles?.columnCount ?? 1,
      height: Math.floor(template.data().styles?.pageHeight ?? 0),
      max_whitespace: hyperparams.max_whitespace,
      fixed_elements: fixedElements,
      optional_elements: fillers
        .filter(filler =>
          fillerIsValidForLayout(filler, {
            columns: template.data().styles?.columnCount ?? 1
          })
        )
        .flatMap(filler =>
          Array.from({ length: filler.maxOccurrences }, (_, j) => ({
            ...filler,
            width: filler.columns,
            id: `${filler.name}-big-${i}-${filler.id}-${j}`
          }))
        )
    });
  }
  for (let col = 1; col < (template?.data()?.styles?.columnCount || 1); col++) {
    const fixedElements: LayoutBlockFixedElement[] = [];
    const relevantHeader = publisherPaginationSettings
      .data()
      .headers.find(header => !!header.id);
    if (relevantHeader) {
      for (let i = 0; i < col; i++) {
        fixedElements.push({
          x: i,
          y: 0,
          width: 1,
          height: 18,
          id: `${relevantHeader.id}-${i}`
        });
      }
    }

    layouts.push({
      id: `auto-paginate-${col}`,
      width: col,
      height: Math.floor(template?.data()?.styles?.pageHeight ?? 0),
      max_whitespace: hyperparams.max_whitespace,
      fixed_elements: fixedElements,
      optional_elements: fillers
        .filter(filler => fillerIsValidForLayout(filler, { columns: col }))
        .flatMap(filler =>
          Array.from({ length: filler.maxOccurrences }, (_, j) => ({
            ...filler,
            width: filler.columns,
            id: `${filler.name}-small-${col}-${filler.id}-${j}`
          }))
        )
    });
  }

  const possibleLayouts = layouts.filter(layout => {
    return layoutIsValidForOrders(layout, template, orderBlocks, hyperparams);
  });

  if (!possibleLayouts.length) {
    return wrapError(
      new Error(
        'No layouts found that could work with the request. This probably means that you need a new layout size for small publication issues to be able to auto-paginate.'
      )
    );
  }

  return wrapSuccess(possibleLayouts);
};
