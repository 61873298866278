import React, { useEffect, useState, useRef, RefObject } from 'react';
import { useAppSelector } from 'redux/hooks';
import { appendToCurrentParams, updateHistoryNoRerender } from 'utils/urls';
import { getLocationParams } from 'lib/frontend/utils/browser';
import { NoticeStatusType, Product } from 'lib/enums';
import { isPublisher as isUserPublisher } from 'lib/utils/users';
import LoadingState from 'components/LoadingState';
import {
  ESnapshotExists,
  EUser,
  EUnsubscribe,
  EDisplayParams,
  exists
} from 'lib/types';
import { useNavigate } from 'react-router-dom';
import { getDynamicRoute, useAppParams } from 'lib/frontend/utils/router';
import { MAIN_ROUTES, NOTICE_ROUTES } from 'router/routes';
import FreeformCModal from 'components/modals/FreeFormCModal';
import PlacementActions, { EPlacement } from 'redux/placement';
import AuthActions, { selectAuthError, selectIsUserLoggedIn } from 'redux/auth';

import { getFirebaseContext } from 'utils/firebase';
import classNames from 'classnames';
import { canEditNoticeWithoutSupport } from 'utils/permissions';
import { CancelOrSubmitModal } from 'lib/components/CancelOrSubmitModal';
import { publisherReadyToUpload } from 'lib/publishers';

import { ColumnService } from 'lib/services/directory';
import PlacementLoginModal from './PlacementLoginModal';
import { usePlacementAnonymousLogin } from './hooks/usePlacementAnonymousLogin';
import NoticeContentStep from './NoticeContentStep';
import ConfirmPublisherStep from './ConfirmPublisher/ConfirmPublisherStep';
import ConfirmProofStep from './ConfirmProofStep';
import ConfirmFilerStep from './ConfirmFilerStep';
import {
  isAnonymousFlow,
  removeSubdomainFromHostIfExists,
  shouldShowAccountIdFeatures
} from './helpers';
import {
  CONFIRM_PUBLISHER,
  CONFIRM_PROOF,
  CONFIRM_AD,
  CONFIRM_FILER,
  SCHEDULE_NOTICE,
  getFirstStep,
  StepEntry,
  getFormattedPlacementSteps,
  getVisiblePlacementSteps
} from './helpers/calculatePlacementSteps';
import { PlaceNoticeState } from './types';
import PlacementNavbar from './PlacementNavbar';
import { useSyncFormat } from './hooks/useSyncFormat';
import PlacementSidebar from './PlacementSidebar';
import { ScheduleNoticeStep } from './ScheduleNoticeStep';
import { useFetchCustomer } from './hooks/useFetchCustomer';
import { selectPlacementSteps } from './placeScrollSelectors';
import { DisabledPublisherModal } from './ConfirmPublisher/DisabledPublisherModal';

type PlaceNoticeProps = {
  user: ESnapshotExists<EUser> | null;
  isAnonymous?: boolean;
  isPublisher: boolean;
  placementActions: typeof PlacementActions;
  authActions: typeof AuthActions;
  placement: EPlacement;
};

type SimultaneousActivity = {
  id?: string;
  header: string;
  body: string;
  onConfirm: () => void;
  buttonText: string;
};

export type PreviewContentType = {
  displayParams: EDisplayParams | null;
  price: string;
};

export default function PlaceNotice({
  isPublisher,
  user,
  isAnonymous,
  placementActions,
  authActions,
  placement
}: PlaceNoticeProps) {
  const navigate = useNavigate();
  const { id: noticeId } = useAppParams(NOTICE_ROUTES.PLACE);

  const { startedFromAnonymousFlow } = usePlacementAnonymousLogin();
  const isUserLoggedIn = useAppSelector(selectIsUserLoggedIn);
  const authError = useAppSelector(selectAuthError);
  const [current, setCurrent] = useState<StepEntry>();
  const [hydrated, setHydrated] = useState(false);
  const [noticeState, setNoticeState] = useState<PlaceNoticeState | null>(null);
  const [uploading, setUploading] = useState(false);
  const [simultaneousActivity, setSimultaneousActivity] =
    useState<SimultaneousActivity>();
  const [
    showSubmitFileWithoutFormattingModal,
    setShowSubmitFileWithoutFormattingModal
  ] = useState(false);
  const [newspaperUnsub, setNewspaperUnsub] = useState<EUnsubscribe>();

  const [userEmail, setUserEmail] = useState('');
  const [showEnterPasswordModal, setShowEnterPasswordModal] = useState(false);
  const [showDisabledPublisherModal, setShowDisabledPublisherModal] =
    useState(false);

  const syncFormat = useSyncFormat(noticeState?.newspaper);
  const shouldShowAccountIDInPlacement =
    shouldShowAccountIdFeatures(syncFormat);

  const placementStepsList = useAppSelector(state =>
    selectPlacementSteps(
      state,
      shouldShowAccountIDInPlacement,
      startedFromAnonymousFlow
    )
  );
  const visibleSteps = getVisiblePlacementSteps(placementStepsList);
  const placementStepsMap = getFormattedPlacementSteps(placementStepsList);
  const stepsLoaded = !!Object.keys(placementStepsMap).length;
  const loading =
    !placement.draft ||
    !noticeState ||
    !noticeState.notice ||
    (!isUserLoggedIn && !isAnonymousFlow());

  const [showPreview, setShowPreview] = useState(false);

  const { refs, formContainer } = usePlaceScrollRefs();

  const ctx = getFirebaseContext();

  function scrollToRef(ref: React.MutableRefObject<any>) {
    // Set timeout ensures that the ref is updated with the correct offset before scrolling
    setTimeout(() => {
      if (!formContainer.current || !ref.current) return;
      formContainer.current.scrollTo({
        top: ref.current.offsetTop - 16,
        behavior: 'smooth'
      });
    }, 0);
  }

  const publisherOrganizationDisabled =
    noticeState?.newspaper &&
    exists(noticeState.newspaper) &&
    !publisherReadyToUpload(noticeState.newspaper, isPublisher);

  useEffect(() => {
    if (publisherOrganizationDisabled) {
      setShowDisabledPublisherModal(true);
    }
  }, [publisherOrganizationDisabled]);

  const next = () => {
    if (current) {
      const nextStepId = placementStepsMap[current.id].next;
      if (nextStepId) {
        setCurrent(placementStepsMap[nextStepId]);
      }
    }
    placementActions.saveDraft();
  };

  const previous = () => {
    if (!current) {
      return;
    }

    const previousStepId = placementStepsMap[current.id].previous;
    if (!previousStepId) {
      return;
    }

    setCurrent(placementStepsMap[previousStepId]);
  };

  const onDisabledStepClick = (stepId: string) => {
    const stepOrder = Object.keys(placementStepsMap);
    const isStepBeforeCurrentStep =
      stepOrder.indexOf(current ? current.id : '') > stepOrder.indexOf(stepId);
    if (!isStepBeforeCurrentStep) return;
    setCurrent(placementStepsMap[stepId]);
  };

  const handleScrollUI = () => {
    if (!current || !current.id || !placement || !placement.original) return;

    updateHistoryNoRerender(
      `/place/${placement.original.id}?${appendToCurrentParams(
        'step',
        current.id
      ).toString()}`
    );

    const ref = refs[current.id].container;
    if (ref) {
      if (
        current.id === CONFIRM_AD &&
        refs[current.id] &&
        refs[current.id].step &&
        refs[current.id].step?.current &&
        refs[current.id].step?.current.animateIn
      ) {
        scrollToRef(ref);
        const { step } = refs[current.id];
        step?.current.animateIn();
      } else {
        scrollToRef(ref);
      }
    }
  };

  const urlStep = getLocationParams().get('step');

  useEffect(() => {
    if (!current || !refs) return;
    if (placement && placement.postWithoutFormatting) {
      scrollToRef(refs[current?.id].container);
    }
    if (placement?.currentStep?.id !== current?.id) {
      placementActions.setCurrentStep(current);
    }
  }, [current]);

  useEffect(() => {
    if (
      !stepsLoaded ||
      !hydrated ||
      (placement.newspaper && !noticeState?.newspaper)
    ) {
      return;
    }
    const startStep = getFirstStep({ placementStepsMap, urlStep });
    setCurrent(startStep);
  }, [stepsLoaded, noticeState?.newspaper?.id, hydrated]);

  useEffect(() => {
    !!current && handleScrollUI();
  }, [current, !!placement?.original]);

  useEffect(() => {
    if (current?.id === CONFIRM_AD) setShowPreview(true);
  }, [current?.id]);

  useEffect(() => {
    if (!isUserLoggedIn || authError) return;
    placementActions.hydrateNoticeData(noticeId);
    setHydrated(true);
  }, [isUserLoggedIn]);

  useEffect(() => {
    if (placement?.placementError) {
      setSimultaneousActivity({
        id: 'draft-submitted',
        header: 'Placement Error',
        body:
          placement?.placementError.message ||
          `An error occurred while placing the notice. Please try opening a new tab and contact help@column.us if the problem persists.`,
        onConfirm: async () => {
          navigate(MAIN_ROUTES.NOTICES);
        },
        buttonText: 'Back to Notices'
      });
    }
  }, [placement?.placementError]);

  useEffect(() => {
    if (!placement.original || !noticeState) return;

    const handleSimultaneousActivity = async () => {
      if (!placement.original || !noticeState) return;

      if (placement.draftSnap && !exists(placement.draftSnap)) {
        await new Promise(resolve => {
          setTimeout(resolve, 8000);
        });
        const originalNotice = await ctx
          .userNoticesRef()
          .doc(placement.original.id)
          .get();
        if (
          exists(originalNotice) &&
          originalNotice.data().noticeStatus ===
            NoticeStatusType.cancelled.value
        ) {
          // notice cancelled
          setSimultaneousActivity({
            id: 'notice-cancelled',
            header: 'Notice Cancelled',
            body: `Another user just cancelled this notice.
             You will no longer be able to make edits. 
             For additional help, please reach out to customer support.`,
            onConfirm: () =>
              navigate(
                getDynamicRoute(NOTICE_ROUTES.DETAIL, {
                  id: originalNotice.id
                })
              ),
            buttonText: 'Back To Notice Details'
          });
        }
        // invoice created
        else if (
          noticeState.newspaper &&
          noticeState.notice &&
          !noticeState.newspaper.data().allowedNotices &&
          exists(originalNotice) &&
          originalNotice.data().invoice
        ) {
          setSimultaneousActivity({
            id: 'invoice-created',
            header: 'Invoice Created',
            body: `The newspaper just submitted an invoice for this notice.
             You are no longer able to make edits, as edits may affect price. 
             Please reach out to customer support for help if needed.`,
            onConfirm: () => {
              placementActions.resetState();
              navigate(
                getDynamicRoute(NOTICE_ROUTES.DETAIL, {
                  id: originalNotice.id
                })
              );
            },
            buttonText: 'Back To Notice Details'
          });
        }
        // draft updated
      } else {
        setSimultaneousActivity({
          id: 'notice-updated',
          header: 'Notice Update',
          body: `This notice has been updated by another user. 
          In order to continue with your edits, you must refresh 
          this draft with the latest changes.`,
          onConfirm: async () => {
            const original = await placement.original?.get();
            if (!exists(original)) {
              return;
            }
            const { drafts } = original.data();
            const otherDrafts = drafts
              ? drafts.filter(draft => draft.id !== placement.draftSnap?.id)
              : [];
            await original.ref.update({
              drafts: otherDrafts
            });
            await placement.draftSnap?.ref.delete();
            window.location.reload();
          },
          buttonText: 'Refresh Draft'
        });
      }
    };

    if (
      placement.draftSnap &&
      (!(placement.draftSnap && exists(placement.draftSnap)) ||
        placement.draftSnap.data().inactive)
    ) {
      void handleSimultaneousActivity();
    }
  }, [placement.draftSnap, placement.original]);

  /**
   * TODO: Move data fetching to an async thunk action and consider merging with hydrateNoticeData
   * and storing snapshots in Redux state.
   */
  const fetchNoticeData = async (placement: EPlacement) => {
    if (!placement.draft) return;
    const draftSnap = await placement.draft.get();
    if (!exists(draftSnap)) return;

    const { newspaper, adTemplate, rate, filer } = placement;

    let rateSnap;
    let adTemplateSnap;

    const newspaperSnap = await newspaper?.get();

    if (
      user &&
      !isPublisher &&
      exists(newspaperSnap) &&
      draftSnap.data().publicationDates?.length > 0 &&
      placement.editing
    ) {
      const canEditNotice = canEditNoticeWithoutSupport(
        draftSnap,
        user,
        newspaperSnap
      );

      /**
       * NOTE: editRunDatesAfterInvoicePaid is a confusing field name.
       * It doesn't appear to actually control whether "run dates" can be edited
       * and it doesn't seem to be related to whethere the invoice is paid or not.
       * It appears to control whether an advertiser can edit a notice after an
       * invoice is created in general, so here we check if the notice has an invoice
       * and if editing after an invoice is created is enabled.
       */
      const noticeHasInvoiceAndCannotEdit =
        canEditNotice &&
        draftSnap.data().invoice &&
        !newspaperSnap.data().editRunDatesAfterInvoicePaid;

      // If the advertiser is not allowed to edit the notice, redirect them to place a new notice
      if (!canEditNotice || noticeHasInvoiceAndCannotEdit) {
        return navigate(MAIN_ROUTES.PLACE_NOTICE);
      }
    }

    if (newspaper && exists(newspaperSnap)) {
      /**
       * move it to the saga
       * cancel the subscription
       */
      if (
        draftSnap.data() &&
        draftSnap.data().noticeStatus &&
        !isPublisher &&
        !noticeState?.newspaper
      ) {
        const unsub = newspaper.onSnapshot(doc => {
          if (!simultaneousActivity && !doc.data()?.advertiserEditingEnabled) {
            setSimultaneousActivity({
              header: 'Editing Disabled',
              body: `The newspaper has disabled editing. 
                You are no longer able to make edits. 
                Please reach out to customer support for help if needed.`,
              buttonText: 'Back to Notice Details',
              onConfirm: () => {
                placementActions.resetState();
                navigate(
                  getDynamicRoute(NOTICE_ROUTES.DETAIL, {
                    id: placement.original?.id
                  })
                );
              }
            });
          }
        });
        setNewspaperUnsub(unsub);
      }
    }

    if (adTemplate) {
      adTemplateSnap = await adTemplate.get();
    } else if (exists(newspaperSnap)) {
      placementActions.setTemplate(newspaperSnap.data().adTemplate);
    }

    if (!filer && draftSnap) {
      // todo tech-debt ticket coredev-1437
      // to move the isAnonymous check somewhere less risky
      if (draftSnap.data().userId && isAnonymous === false) {
        try {
          const user = await ctx.usersRef().doc(draftSnap.data().userId).get();
          if (exists(user) && !isUserPublisher(user)) {
            placementActions.setFiler(user.ref);
          }
        } catch (err) {
          console.error(err);
        }
      }
    }

    if (rate) rateSnap = await rate.get();

    const filerSnap = await filer?.get();

    const state: PlaceNoticeState = {
      notice: draftSnap,
      newspaper: exists(newspaperSnap) ? newspaperSnap : undefined,
      rate: exists(rateSnap) ? rateSnap : undefined,
      adTemplate: exists(adTemplateSnap) ? adTemplateSnap : undefined,
      filer: exists(filerSnap) ? filerSnap : undefined
    };

    setNoticeState(state);
  };

  useEffect(() => {
    if (!placement.draft || !placement.original || !hydrated) return;
    void fetchNoticeData(placement);

    return () => {
      if (newspaperUnsub) {
        newspaperUnsub();
        setNewspaperUnsub(undefined);
      }
    };
  }, [
    placement.draft?.id, // draft snapshot is used in other components
    placement.newspaper?.id, // newspaper snapshot is used in other components
    placement.adTemplate?.id, // adTemplate snapshot is used in other components
    placement.rate?.id, // rate snapshot is used in other components
    placement.filer?.id, // filer is updated
    placement.proofStoragePath, // when proof is updated the noticeSnap should also be updated to reflect pricing
    placement.draftSnap?.data()?.noticeType,
    hydrated
  ]);

  /**
   * Show a Modal to block the placement if customer is on hold.
   * This will only be visible to advertiser at the time of new placement when the placment step is not confirm newspaper
   */
  const customer = useFetchCustomer();
  const isCustomerAccountOnHold =
    !isPublisher &&
    !placement.editing &&
    current?.id !== CONFIRM_PUBLISHER &&
    noticeState &&
    exists(noticeState.newspaper) &&
    customer &&
    customer.data().isAccountOnHold;

  if (loading) {
    return <LoadingState context={{ service: ColumnService.WEB_PLACEMENT }} />;
  }

  const renderConfirmPublisherStep = () => {
    return (
      <div ref={refs[CONFIRM_PUBLISHER].container}>
        <ConfirmPublisherStep
          next={() => {
            if (publisherOrganizationDisabled) {
              return setShowDisabledPublisherModal(true);
            }

            next();
          }}
          onDisabledStepClick={onDisabledStepClick}
          publisherOrganization={noticeState.newspaper}
        />
      </div>
    );
  };

  const handleNoticeWithoutAdProof = () => {
    placementActions.setPostWithoutFormatting(true);
    setShowSubmitFileWithoutFormattingModal(false);
  };

  const renderSubmitFileWithoutFormattingModal = () => (
    <FreeformCModal
      setOpen={setShowSubmitFileWithoutFormattingModal}
      header="Submit file without formatting?"
      id="submit-without-formatting-modal"
      body={`You can opt to submit your file directly to the newspaper instead of Column formatting your notice. If you proceed, you will not receive a proof until the newspaper designs your notice manually.`}
    >
      <section>
        <button
          type="button"
          className="bg-blue-200 border border-transparent duration-150 ease-in-out focus:border-blue-500 focus:outline-none focus:shadow-outline-blue font-medium hover:bg-blue-600 leading-6 mt-3 px-4 py-2 rounded-md shadow-sm sm:leading-5 sm:text-sm text-base text-blue-700 hover:text-white transition w-50"
          id="submit-without-formatting"
          onClick={handleNoticeWithoutAdProof}
        >
          Submit Without Formatting
        </button>
        <button
          type="button"
          className="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-blue-900 hover:text-blue-800 bg-transparent transition duration-150 ease-in-out"
          id="cancel-submit-without-formatting"
          onClick={() => setShowSubmitFileWithoutFormattingModal(false)}
        >
          Cancel
        </button>
      </section>
    </FreeformCModal>
  );

  const renderContentStep = () => {
    return (
      <div ref={refs[CONFIRM_AD].container}>
        <NoticeContentStep
          placementActions={placementActions}
          ref={refs[CONFIRM_AD].step}
          id={CONFIRM_AD}
          activeStepId={current?.id as string}
          notice={noticeState.notice}
          newspaper={noticeState.newspaper}
          setUploading={setUploading}
          next={() => {
            if (
              !placement.continueWithLargeFile &&
              !placement.postWithoutFormatting
            ) {
              placementActions.setFormattingError(null);
            }
            placementActions.saveDraft();
            if (current?.next) {
              setCurrent(placementStepsMap[current.next]);
            }
          }}
          previous={
            placementStepsMap[CONFIRM_AD].previous ? previous : undefined
          }
          onDisabledStepClick={onDisabledStepClick}
          showSubmitFileWithoutFormattingModal={
            showSubmitFileWithoutFormattingModal
          }
          renderSubmitFileWithoutFormattingModal={
            renderSubmitFileWithoutFormattingModal
          }
        />
      </div>
    );
  };

  const renderConfirmProof = () => (
    <div id={CONFIRM_PROOF} ref={refs[CONFIRM_PROOF].container}>
      <ConfirmProofStep
        id={CONFIRM_PROOF}
        newspaper={noticeState.newspaper}
        notice={noticeState.notice}
        isPublisher={isPublisher}
        user={user}
        onDisabledStepClick={onDisabledStepClick}
        editing={placement.editing}
        placementActions={placementActions}
      />
      <div className="flex w-full h-16 items-center mb-6 justify-end"></div>
    </div>
  );
  const renderFilerStep = () => (
    <div ref={refs[CONFIRM_FILER].container}>
      <ConfirmFilerStep
        publisherOrganization={noticeState.newspaper}
        filer={noticeState.filer}
        syncFormat={syncFormat}
        setShowEnterPasswordModal={setShowEnterPasswordModal}
        next={next}
        previous={
          placementStepsMap[CONFIRM_FILER].previous ? previous : undefined
        }
        onDisabledStepClick={onDisabledStepClick}
        placementActions={placementActions}
        authActions={authActions}
        setUserEmail={setUserEmail}
        startedFromAnonymousFlow={startedFromAnonymousFlow}
      />
    </div>
  );

  return (
    <div className={'fixed min-w-full bg-gray-80 h-full w-screen z-modal'}>
      <PlacementNavbar
        steps={visibleSteps}
        hidePrice={!!noticeState.rate?.data()?.hidePreview}
        exitModalMessage='Your notice will be saved under "Draft Notices" so you can easily pick up where you left off.'
      />
      <div className="relative w-full h-full flex flex-nowrap">
        <div
          className={classNames('overflow-y-scroll', {
            'w-full': !showPreview,
            'md:w-2/3': showPreview
          })}
          id="place-notice-form-container"
          ref={formContainer}
        >
          <div
            style={{ transition: 'width .5s' }}
            className={classNames('mx-auto px-3 py-12 space-y-12', {
              'md:w-3/5': !showPreview,
              'w-11/12': showPreview
            })}
          >
            {placementStepsMap[CONFIRM_PUBLISHER] &&
              renderConfirmPublisherStep()}
            {placementStepsMap[CONFIRM_FILER] && renderFilerStep()}
            {placementStepsMap[CONFIRM_AD] && renderContentStep()}
            {placementStepsMap[SCHEDULE_NOTICE] && (
              <div ref={refs[SCHEDULE_NOTICE].container}>
                <ScheduleNoticeStep
                  publisherOrganization={noticeState.newspaper}
                  notice={noticeState.notice}
                  filer={noticeState.filer}
                  next={next}
                  previous={previous}
                  onDisabledStepClick={onDisabledStepClick}
                />
              </div>
            )}
            {placementStepsMap[CONFIRM_PROOF] && renderConfirmProof()}
          </div>
          <PlacementSidebar
            showContent={showPreview}
            setShowContent={setShowPreview}
            rate={noticeState.rate}
            adTemplate={noticeState.adTemplate ?? null}
            newspaper={noticeState.newspaper}
            uploading={uploading}
          />
          {simultaneousActivity && (
            <FreeformCModal
              id={simultaneousActivity.id}
              header={simultaneousActivity.header}
              body={simultaneousActivity.body}
            >
              <section>
                <button
                  id={`confirm-${simultaneousActivity.id}`}
                  onClick={() => simultaneousActivity.onConfirm()}
                  className="bg-blue-200 w-auto border border-transparent duration-150 ease-in-out focus:border-blue-500 focus:outline-none focus:shadow-outline-blue font-medium hover:bg-blue-600 leading-6 mt-3 px-4 py-2 rounded-md shadow-sm sm:leading-5 sm:text-sm text-base text-blue-700 hover:text-white transition w-32"
                >
                  {simultaneousActivity.buttonText}
                </button>
              </section>
            </FreeformCModal>
          )}
          {isAnonymousFlow() && showEnterPasswordModal && (
            <PlacementLoginModal
              onClose={() => setShowEnterPasswordModal(false)}
              email={userEmail}
              productType={Product.Notice}
            />
          )}
          {isCustomerAccountOnHold && (
            <CancelOrSubmitModal
              onClose={() => {
                navigate(MAIN_ROUTES.NOTICES);
              }}
              header="Your account is on hold"
              primaryButtonText="Select different newspaper"
              tertiaryButtonText="Cancel"
              onSubmit={() => {
                // If there is a subdomain, we need to replace that with column host only
                const subdomain = removeSubdomainFromHostIfExists(
                  window.location.host
                );
                if (!subdomain || window.location.host.includes('web.app')) {
                  navigate(MAIN_ROUTES.PLACE_NOTICE);
                } else {
                  navigate(`${window.location.protocol}//${subdomain}/place/`);
                }
              }}
              noExitOutsideModal
            >
              <p className="py-5">{`${
                noticeState.newspaper?.data().name
              } has put your account on hold. Please contact the publisher for more information, or place your notice in a different publication.

`}</p>
            </CancelOrSubmitModal>
          )}
          {showDisabledPublisherModal && (
            <DisabledPublisherModal
              publisherOrganization={noticeState.newspaper}
              setOpen={setShowDisabledPublisherModal}
            />
          )}
        </div>
      </div>
    </div>
  );
}

function usePlaceScrollRefs() {
  const refs = {
    [CONFIRM_PUBLISHER]: {
      container: useRef(null)
    },
    [CONFIRM_FILER]: {
      container: useRef(null),
      step: useRef(null)
    },
    [CONFIRM_AD]: {
      container: useRef(null),
      step: useRef(null)
    },
    [CONFIRM_PROOF]: {
      container: useRef(null),
      step: useRef(null)
    },
    [SCHEDULE_NOTICE]: {
      container: useRef(null)
    }
  } as Record<
    string,
    {
      container: React.MutableRefObject<any>;
      step?: React.MutableRefObject<any>;
    }
  >;
  const formContainer = useRef() as RefObject<HTMLDivElement>;
  const navSpacer = useRef() as RefObject<HTMLDivElement>;

  return { refs, formContainer, navSpacer };
}
