import React from 'react';
import {
  CardGridLayout,
  GridInput,
  useEditableCard
} from 'lib/components/Card/Grid';
import { Form } from 'lib/components/Form';
import { ESnapshotExists } from 'lib/types';
import { EditableCardArray } from 'lib/components/Card/EditableCardArray';
import { TextField } from 'lib/components/TextField';
import { SwitchControlledCard } from 'lib/components/Card/SwitchControlledCard';
import {
  AllowedLayout,
  PublisherPaginationSettings
} from 'lib/types/publisherPaginationSettings';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { LEE_DEFAULT_PAGINATION_FILLERS } from 'lib/services/publisherPaginationSettingsService/defaultValues';

export default function AllowedLayoutSettings({
  publisherPaginationSettings
}: {
  publisherPaginationSettings: ESnapshotExists<PublisherPaginationSettings>;
}) {
  const { formSettings, onChange, onSubmitWrapper, editable, disabled } =
    useEditableCard(publisherPaginationSettings.data());

  const saveAllowedLayoutSettings = async () => {
    const relevantSettings = {
      allowedLayouts: formSettings.allowedLayouts
    };
    try {
      await publisherPaginationSettings.ref.update(relevantSettings);
      return { success: true };
    } catch (e) {
      return { success: false };
    }
  };

  function AllowedLayoutCard({
    value,
    onChange
  }: {
    value: AllowedLayout;
    onChange: (value: AllowedLayout) => void;
  }) {
    const { headers } = publisherPaginationSettings.data();
    console.log('headers', headers);
    return (
      <>
        <GridInput>
          <TextField
            labelText="Columns"
            value={value.columns?.toString()}
            onChange={e => onChange({ ...value, columns: parseInt(e, 10) })}
            type="number"
            required
            min={1}
            max={10}
            id="allowed-layout-columns"
          />
        </GridInput>
        <GridInput>
          <TextField
            labelText="Height in pixels"
            value={value.heightPixels?.toString()}
            onChange={e =>
              onChange({ ...value, heightPixels: parseInt(e, 10) })
            }
            id="allowed-layout-height"
            type="number"
            required
            min={1}
            max={100000}
            step={1}
          />
        </GridInput>
        <GridInput fullWidth>
          <SwitchControlledCard
            labelProps={{
              label: 'Place header at top of block',
              value: value.layoutHeader !== null,
              disabled: !headers?.length,
              onChange: e =>
                onChange({
                  ...value,
                  layoutHeader: e
                    ? {
                        headerId: headers[0].id,
                        width: 'column'
                      }
                    : null
                })
            }}
            header="Include Header"
          >
            <GridInput fullWidth>
              <ColumnSelect
                labelText="Header"
                allowUndefined
                value={value.layoutHeader?.headerId}
                onChange={headerId =>
                  onChange({
                    ...value,
                    layoutHeader: {
                      width: 'column',
                      ...value.layoutHeader,
                      headerId
                    }
                  })
                }
                id="allowed-layout-header-select"
                options={(headers || []).map(header => ({
                  label: header.name,
                  value: header.id
                }))}
              />
            </GridInput>
            <GridInput fullWidth>
              <ColumnSelect
                labelText="Header Width"
                value={value.layoutHeader?.width}
                allowUndefined
                onChange={width =>
                  onChange({
                    ...value,
                    layoutHeader: {
                      headerId: headers[0].id,
                      ...value.layoutHeader,
                      width
                    }
                  })
                }
                required
                id="header-width-select"
                options={[
                  {
                    label: 'Column Width',
                    value: 'column'
                  },
                  {
                    label: 'Full Layout Width',
                    value: 'layout'
                  }
                ]}
              />
            </GridInput>
          </SwitchControlledCard>
        </GridInput>
      </>
    );
  }

  return (
    <Form
      onSubmit={() => onSubmitWrapper(saveAllowedLayoutSettings)}
      id="pagination-settings-form"
    >
      <CardGridLayout
        header={{
          title: 'Layout Settings',
          description:
            'Determine the size and layout of the blocks that will be generated for your publication.'
        }}
        editable={editable}
      >
        <GridInput fullWidth>
          <EditableCardArray
            archivable={{
              shouldAllow: () => formSettings.allowedLayouts.length > 1,
              confirmation: {
                header: 'Are you sure you want to remove this layout?',
                body: 'This action cannot be undone.'
              },
              buttonText: 'Remove'
            }}
            columns={1}
            disabled={disabled}
            baseHeader="Allowed Layout"
            onChange={newLayouts =>
              onChange({ ...formSettings, allowedLayouts: newLayouts })
            }
            createable={{
              buttonText: 'Add option',
              initialValue: {
                id: 'random',
                columns: 1,
                heightPixels: 100,
                layoutHeader: null,
                fillers: LEE_DEFAULT_PAGINATION_FILLERS
              }
            }}
            Component={AllowedLayoutCard}
            values={formSettings.allowedLayouts}
          />
        </GridInput>
      </CardGridLayout>
    </Form>
  );
}
