import moment from 'moment';

import {
  ESnapshotExists,
  EUser,
  ESnapshot,
  InvoiceReminderSentEvent,
  InvoiceCancelledEvent,
  InvoiceRefundedEvent,
  NoticeEditedEvent,
  NoticeConfirmedEvent,
  InvoiceCreateEvent,
  AffidavitUploadedEvent,
  AffidavitReUploadedEvent,
  InvoicePaymentNoteEvent,
  InvoicePaymentProcessedManualEvent,
  Event,
  exists,
  ENotice,
  FirebaseTimestamp,
  NoticeSubmittedEvent
} from 'lib/types';
import * as EventTypes from 'lib/types/events';
import { OccupationType, SyncStatusType } from 'lib/enums';
import {
  awaitAllPromises,
  getFulfilled,
  isColumnUser,
  cdnIfy
} from 'lib/helpers';
import { getFirebaseContext } from 'utils/firebase';
import { logAndCaptureMessage } from 'utils';
import { getSyncStatusCategory } from 'lib/utils/events';
import { getModelFromRef, getModelFromSnapshot } from 'lib/model';
import { NoteModel } from 'lib/model/objects/noteModel';
import { getOrThrow } from 'lib/utils/refs';
import { UserModel } from 'lib/model/objects/userModel';
import { RunStatusType } from 'lib/types/runs';
import { convertDateStringFormat } from 'lib/utils/dates';
import { getBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';

const COLUMN_SOFTWARE = 'Column Software';

export type EventLink = {
  text: string;
  url: string;
};

type EventLogMessage = {
  header: string | null;
  content?: string;
  link?: EventLink;
  userEmail?: string;
};
export type EventWithTimestamp = Pick<Event, 'type' | 'hidden'> &
  EventLogMessage & {
    timestamp: FirebaseTimestamp;
    dateString: string;
  };

type ActivityLogSettings = {
  advertiserOrgName: string;
  advertiserSnap?: ESnapshotExists<EUser>;
  newspaperName: string;
  isPublisher: boolean;
  notice: ESnapshotExists<ENotice>;
  user: ESnapshotExists<EUser>;
  timezone: string;
};

const getEventInitiatorString = (
  user: ESnapshot<EUser> | undefined,
  organizationName: string
) => {
  if (!exists(user)) {
    return organizationName;
  }
  /**
   * If a member of Column Support has initiated an event by
   * shadowing the publisher or advertiser organization,
   * always show 'Column Support' in the event log
   * instead of the shadowed org
   */
  if (isColumnUser(user)) {
    return `${user.data().name} (${COLUMN_SOFTWARE})`;
  }

  return organizationName.length
    ? `${user.data().name} (${organizationName})`
    : `${user.data().name}`;
};

const getUserEmail = (user: ESnapshot<EUser> | undefined) => {
  if (!exists(user)) {
    return '';
  }
  return user.data().email;
};

export const renderNoticeSubmittedEvent = async (
  event: NoticeSubmittedEvent,
  {
    notice,
    newspaperName,
    advertiserSnap,
    advertiserOrgName
  }: ActivityLogSettings
): Promise<EventLogMessage> => {
  const {
    data: { submittedNoticeData }
  } = event;
  const { createdBy, filer } = notice.data();
  const useColumnCDN = getBooleanFlag(LaunchDarklyFlags.ENABLE_COLUMN_CDN);

  const submittedProofPath = submittedNoticeData?.proofStoragePath;
  const link = submittedProofPath
    ? { text: 'View proof.', url: cdnIfy(submittedProofPath, { useColumnCDN }) }
    : undefined;

  // If the created by field is the same as the filer,
  // then the filer submitted the notices on behalf of themself.
  if (createdBy && filer.id !== createdBy.id) {
    const publisher = await createdBy.get();

    return {
      header: getEventInitiatorString(publisher, newspaperName),
      content: `Uploaded this notice for ${advertiserSnap?.data()?.name}${
        advertiserOrgName.length ? ` (${advertiserOrgName})` : ''
      }.`,
      userEmail: getUserEmail(publisher),
      link
    };
  }

  return {
    header: getEventInitiatorString(advertiserSnap, advertiserOrgName),
    content: `Submitted this notice to ${newspaperName}.`,
    userEmail: getUserEmail(advertiserSnap),
    link
  };
};

const renderNoticeConfirmedEvent = async (
  event: NoticeConfirmedEvent,
  { newspaperName }: ActivityLogSettings
): Promise<EventLogMessage> => {
  const { confirmedBy } = event.data;
  if (confirmedBy) {
    const publisher = await confirmedBy.get();
    return {
      header: getEventInitiatorString(publisher, newspaperName),
      content: `Confirmed the notice.`,
      userEmail: getUserEmail(publisher)
    };
  }
  return {
    header: `Notice was confirmed`
  };
};

const renderNoticeCancelledEvent = async ({
  notice,
  newspaperName,
  advertiserOrgName
}: ActivityLogSettings): Promise<EventLogMessage> => {
  const refreshedNotice = (await getFirebaseContext()
    .userNoticesRef()
    .doc(notice.id)
    .get()) as ESnapshotExists<ENotice>;
  const { noticeCancellationReason, cancelledBy } = refreshedNotice.data();
  const user = await cancelledBy?.get();
  if (user && exists(user)) {
    const orgName =
      user?.data().occupation === OccupationType.publishing.value
        ? newspaperName
        : advertiserOrgName;
    return {
      header: getEventInitiatorString(user, orgName),
      content: `Cancelled this notice, with reason "${noticeCancellationReason}".`,
      userEmail: getUserEmail(user)
    };
  }
  return {
    header: 'Notice cancelled',
    content: `Reason: "${noticeCancellationReason}".`
  };
};
const renderNoticeCancellationRequestedEvent = async (
  event: EventTypes.NoticeCancellationRequestedEvent,
  { advertiserOrgName }: ActivityLogSettings
): Promise<EventLogMessage> => {
  const { requestedBy, cancellationReason } = event.data;
  const user = await requestedBy.get();

  if (exists(user)) {
    return {
      header: getEventInitiatorString(user, advertiserOrgName),
      content: `Requested to cancel this notice, with the reason "${cancellationReason}"`,
      userEmail: getUserEmail(user)
    };
  }
  return {
    header: 'Cancellation requested',
    content: `Reason: "${cancellationReason}"`
  };
};

const renderNoticeRefundedEvent = async ({
  notice,
  newspaperName
}: ActivityLogSettings): Promise<EventLogMessage> => {
  const invoice = await notice.data().invoice?.get();
  if (!exists(invoice)) {
    return {
      header: `Refund initiated.`
    };
  }
  const { refund_amount, paid_outside_stripe } = invoice.data();
  if (paid_outside_stripe || !refund_amount)
    return {
      header: newspaperName,
      content: `Initiated a refund.`
    };
  return {
    header: newspaperName,
    content: `Initiated a refund for $${(refund_amount / 100).toFixed(2)}.`
  };
};

const renderAffidavituploadedEvent = async (
  event: AffidavitUploadedEvent,
  { newspaperName }: ActivityLogSettings
): Promise<EventLogMessage> => {
  const { uploadedBy } = event.data;
  const publisher = await uploadedBy?.get();
  return {
    header: getEventInitiatorString(publisher, newspaperName),
    content: `Uploaded the affidavit.`,
    userEmail: getUserEmail(publisher)
  };
};

const renderAffidavitReuploadedEvent = async (
  event: AffidavitReUploadedEvent,
  { newspaperName }: ActivityLogSettings
): Promise<EventLogMessage> => {
  const { uploadedBy } = event.data;
  const publisher = await uploadedBy?.get();
  return {
    header: getEventInitiatorString(publisher, newspaperName),
    userEmail: getUserEmail(publisher),
    content: `Uploaded a new affidavit.`
  };
};

export const renderInvoiceCreatedEvent = async (
  event: InvoiceCreateEvent,
  { newspaperName }: ActivityLogSettings
): Promise<EventLogMessage> => {
  const { createdBy, invoice } = event.data;
  const invoiceSnapshot = await invoice?.get();
  const invoicePDFStoragePath = invoiceSnapshot?.data()?.invoice_pdf;
  const useColumnCDN = getBooleanFlag(LaunchDarklyFlags.ENABLE_COLUMN_CDN);
  const link = invoicePDFStoragePath
    ? {
        text: 'View PDF.',
        url: cdnIfy(invoicePDFStoragePath, { useColumnCDN })
      }
    : undefined;

  if (createdBy) {
    const publisher = await createdBy.get();
    return {
      header: getEventInitiatorString(publisher, newspaperName),
      content: `Created an invoice.`,
      userEmail: getUserEmail(publisher),
      link
    };
  }
  return {
    header: newspaperName,
    content: `Created an invoice.`,
    link
  };
};

const renderPaymentProcessedEvent = async ({
  advertiserSnap,
  advertiserOrgName
}: ActivityLogSettings): Promise<EventLogMessage> => {
  return {
    header: getEventInitiatorString(advertiserSnap, advertiserOrgName),
    content: `Paid the invoice.`,
    userEmail: getUserEmail(advertiserSnap)
  };
};

const renderCheckPaymentProcessedEvent = async ({
  advertiserSnap,
  advertiserOrgName
}: ActivityLogSettings): Promise<EventLogMessage> => {
  return {
    header: getEventInitiatorString(advertiserSnap, advertiserOrgName),
    userEmail: getUserEmail(advertiserSnap),
    content: `Paid the invoice by check.`
  };
};

const renderBulkPaymentProcessedEvent = async ({
  advertiserSnap,
  advertiserOrgName
}: ActivityLogSettings): Promise<EventLogMessage> => {
  return {
    header: getEventInitiatorString(advertiserSnap, advertiserOrgName),
    userEmail: getUserEmail(advertiserSnap),
    content: `Paid the invoice by bulk payment.`
  };
};

const renderCardPaymentProcessedEvent = async ({
  advertiserSnap,
  advertiserOrgName
}: ActivityLogSettings): Promise<EventLogMessage> => {
  return {
    header: getEventInitiatorString(advertiserSnap, advertiserOrgName),
    userEmail: getUserEmail(advertiserSnap),
    content: `Paid the invoice by card.`
  };
};

const renderACHPaymentProcessedEvent = async ({
  advertiserSnap,
  advertiserOrgName
}: ActivityLogSettings): Promise<EventLogMessage> => {
  return {
    header: getEventInitiatorString(advertiserSnap, advertiserOrgName),
    userEmail: getUserEmail(advertiserSnap),
    content: `Paid the invoice by ACH.`
  };
};

const renderPaymentInitiatedEvent = async ({
  advertiserSnap,
  advertiserOrgName
}: ActivityLogSettings): Promise<EventLogMessage> => {
  return {
    header: getEventInitiatorString(advertiserSnap, advertiserOrgName),
    userEmail: getUserEmail(advertiserSnap),
    content: `Initiated payment.`
  };
};

const renderPaymentProcessedManualEvent = async (
  event: InvoicePaymentProcessedManualEvent,
  { newspaperName }: ActivityLogSettings
): Promise<EventLogMessage> => {
  const { user, invoice } = event.data;
  const publisherSnap = await user?.get();
  const invoiceSnap = await invoice.get();

  if (invoiceSnap.data()?.paidByBulkInvoice) {
    return {
      header: COLUMN_SOFTWARE,
      content: `Invoice paid by bulk invoice.`
    };
  }

  return {
    header: getEventInitiatorString(publisherSnap, newspaperName),
    content: `Marked an invoice as paid out of platform.`,
    userEmail: getUserEmail(publisherSnap)
  };
};

const renderPaymentNoteEvent = async (
  event: InvoicePaymentNoteEvent,
  { newspaperName }: ActivityLogSettings
): Promise<EventLogMessage> => {
  const { user, note } = event.data;
  const publisherSnap = await user?.get();

  return {
    header: getEventInitiatorString(publisherSnap, newspaperName),
    userEmail: getUserEmail(publisherSnap),
    content: `Recorded a payment note: ${note}`
  };
};

const renderInvoicePaidOutsideEvent = async ({
  newspaperName
}: ActivityLogSettings): Promise<EventLogMessage> => {
  return {
    header: newspaperName,
    content: `Marked the invoice as to be paid outside Column.`
  };
};

const renderInvoiceCancelledEvent = async (
  event: InvoiceCancelledEvent,
  { newspaperName }: ActivityLogSettings
): Promise<EventLogMessage> => {
  const { cancelledBy } = event.data;
  const publisher = await cancelledBy?.get();
  return {
    header: getEventInitiatorString(publisher, newspaperName),
    content: `Cancelled an invoice.`,
    userEmail: getUserEmail(publisher)
  };
};

const renderInvoiceRefundedEvent = async (
  event: InvoiceRefundedEvent,
  { newspaperName }: ActivityLogSettings
): Promise<EventLogMessage> => {
  const { cancelledBy, refundedBy, refundAmount, refundReason } = event.data;
  let initiator = await refundedBy?.get();
  if (!exists(initiator)) {
    initiator = await cancelledBy?.get();
  }
  return {
    header: getEventInitiatorString(initiator, newspaperName),
    content: `Initiated a refund for $${(refundAmount / 100).toFixed(2)}.${
      refundReason ? ` Reason: ${refundReason}` : ''
    }`,
    userEmail: getUserEmail(initiator)
  };
};

const renderInvoiceReminderSentEvent = async (
  event: InvoiceReminderSentEvent,
  { newspaperName, advertiserOrgName }: ActivityLogSettings
): Promise<EventLogMessage> => {
  const { user, email, byMail } = event.data;
  const userSnap = await user.get();
  const isPublisher =
    userSnap?.data()?.occupation === OccupationType.publishing.value;
  const initiatedBy = getEventInitiatorString(
    userSnap,
    isPublisher ? newspaperName : advertiserOrgName
  );

  if (byMail && email)
    return {
      header: initiatedBy,
      userEmail: getUserEmail(userSnap),
      content: `Sent an invoice reminder to ${email} and by mail.`
    };

  if (byMail)
    return {
      header: initiatedBy,
      userEmail: getUserEmail(userSnap),
      content: `Sent an invoice reminder by mail${
        isPublisher && advertiserOrgName ? ` to ${advertiserOrgName}` : ''
      }.`
    };

  return {
    header: initiatedBy,
    userEmail: getUserEmail(userSnap),
    content: `Sent an invoice reminder to ${email}.`
  };
};

const renderInvoiceMailSent = async ({
  newspaperName
}: ActivityLogSettings): Promise<EventLogMessage> => {
  return {
    header: newspaperName,
    content: `Sent the invoice by mail.`
  };
};

export const renderNoticeEditedEvent = async (
  event: NoticeEditedEvent,
  { newspaperName, advertiserOrgName, advertiserSnap }: ActivityLogSettings
): Promise<EventLogMessage> => {
  const { editedBy } = event.data;
  const editor = await editedBy?.get();
  const isPublisher =
    editor?.data()?.occupation === OccupationType.publishing.value;

  const afterProofStoragePath = event.data.afterData?.proofStoragePath;
  const useColumnCDN = getBooleanFlag(LaunchDarklyFlags.ENABLE_COLUMN_CDN);
  const link = afterProofStoragePath
    ? {
        text: 'View proof.',
        url: cdnIfy(afterProofStoragePath, { useColumnCDN })
      }
    : undefined;
  if (editedBy && isPublisher) {
    return {
      header: getEventInitiatorString(editor, newspaperName),
      content: `Edited this notice.`,
      userEmail: getUserEmail(editor),
      link
    };
  }
  if (editor && !isPublisher) {
    return {
      header: getEventInitiatorString(advertiserSnap, advertiserOrgName),
      content: `Edited this notice.`,
      userEmail: getUserEmail(advertiserSnap),
      link
    };
  }
  return {
    header: getEventInitiatorString(advertiserSnap, advertiserOrgName),
    content: `Notice was edited.`,
    userEmail: getUserEmail(advertiserSnap),
    link
  };
};

// We only show payout/transfer events to publishers
const renderNoticeTransferredEvent = async ({
  isPublisher
}: ActivityLogSettings): Promise<EventLogMessage> => {
  return isPublisher ? { header: 'Payout initiated.' } : { header: null };
};

const renderTriggerForSyncEvent = async (
  event: EventTypes.SyncEvent<EventTypes.SyncTriggerEvent>
) => {
  switch (event.type) {
    case EventTypes.CUSTOM_ID_UPDATED_SYNC:
      return 'on Order Number Change';
    case EventTypes.INVOICE_CREATED_SYNC:
      return 'on Invoice Creation';
    case EventTypes.INVOICE_PAYMENT_INITIATED_SYNC:
      return 'on Payment Initiated';
    case EventTypes.INVOICE_PAID_OUTSIDE_SYNC:
    case EventTypes.INVOICE_PAYMENT_PROCESSED_SYNC:
    case EventTypes.INVOICE_PAYMENT_PROCESSED_MANUAL_SYNC:
      return 'on Payment';
    case EventTypes.INVOICE_REFUNDED_SYNC:
      return 'on Refund';
    case EventTypes.NOTICE_AT_DEADLINE_SYNC:
      return 'at Deadline';
    case EventTypes.NOTICE_CANCELLED_SYNC:
      return 'on Cancellation';
    case EventTypes.NOTICE_CONFIRMED_SYNC:
      return 'on Confirmation';
    case EventTypes.NOTICE_CREATED_SYNC:
      return 'on Submission';
    case EventTypes.NOTICE_EDITED_SYNC:
      return 'on Edit';
    case EventTypes.INVOICE_UPFRONT_PAYMENT_WAIVER_SYNC:
      return 'on Payment Terms Changed';
    case EventTypes.MANUAL_SYNC_REQUEST_SYNC:
    case EventTypes.MANUAL_BUILD_AD_REQUEST_SYNC:
    case EventTypes.MANUAL_CANCEL_BUILD_AD_REQUEST_SYNC:
      return 'on Request';
    default:
      return 'on Unknown Trigger';
  }
};

const getSyncResult = (statusValue: number) => {
  const syncStatusCategory = getSyncStatusCategory(statusValue);

  switch (syncStatusCategory.value) {
    case 1:
    case 5: {
      return 'Error';
    }
    case 2: {
      return 'Success';
    }
    case 4: {
      return 'Blocked';
    }
    default: {
      return '';
    }
  }
};
const renderSyncEvent = async (
  event: EventTypes.SyncEvent<EventTypes.SyncTriggerEvent>,
  { isPublisher }: ActivityLogSettings
): Promise<EventLogMessage> => {
  const syncStatus = SyncStatusType.by_value(event.data.syncStatus);

  if (!isPublisher || !syncStatus) {
    return { header: null };
  }

  const { syncMessage, syncWarnings } = event.data;

  if (!syncMessage) {
    return { header: null };
  }

  const syncTriggerLabel = await renderTriggerForSyncEvent(event);

  const syncResult = getSyncResult(event.data.syncStatus);
  const header = `Sync ${syncTriggerLabel}${
    syncResult ? ':' : ''
  } ${syncResult}`;

  if (syncStatus.value === SyncStatusType.success_with_warning.value) {
    return {
      header,
      content: `${syncMessage} ${syncWarnings
        .map((warning, idx) => `${idx + 1}) ${warning.inAppMessage}`)
        .join(' ')}`
    };
  }

  return {
    header,
    content: `${syncStatus.label}: ${syncMessage ?? ''}`
  };
};

const renderNoticeAtDeadlineEvent = async (
  event: EventTypes.NoticeAtDeadlineEvent,
  { isPublisher }: ActivityLogSettings
): Promise<EventLogMessage> => {
  const firstPublicationDate = moment(
    event.data.firstPublicationDate.toDate()
  ).format('D MMM YYYY');
  return isPublisher
    ? {
        header: 'Notice at Deadline',
        content: `For first publication date: ${firstPublicationDate}`
      }
    : { header: null };
};

const renderManualSyncRequestEvent = async (
  event: EventTypes.ManualSyncRequestEvent,
  { newspaperName, isPublisher }: ActivityLogSettings
): Promise<EventLogMessage> => {
  const requestedBy = await event.data.requestedBy.get();
  return isPublisher
    ? {
        header: getEventInitiatorString(requestedBy, newspaperName),
        content: 'Requested to sync the notice.',
        userEmail: getUserEmail(requestedBy)
      }
    : { header: null };
};

const renderNoticeNoteAddedEvent = async (
  event: EventTypes.NoticeNoteAdded
): Promise<EventLogMessage> => {
  const Note = await getModelFromRef(
    NoteModel,
    getFirebaseContext(),
    event.data.note
  );

  const noteCreator = await getOrThrow(Note.modelData.noteCreator);
  const noteCreatorOrganization = Note.modelData.noteCreatorOrganization
    ? await getOrThrow(Note.modelData.noteCreatorOrganization)
    : null;
  const link: EventLogMessage['link'] = Note.modelData.attachments?.length
    ? {
        text: 'View Attachment.',
        url: Note.modelData.attachments[0].downloadURL
      }
    : undefined;

  return {
    header: getEventInitiatorString(
      noteCreator,
      noteCreatorOrganization?.data().name || ''
    ),
    content: `Note: ${Note.modelData.content}`,
    userEmail: getUserEmail(noteCreator),
    link
  };
};

const renderRunStatusChangeEvent = async (
  event: EventTypes.RunStatusChange,
  { newspaperName }: ActivityLogSettings
): Promise<EventLogMessage> => {
  const { status } = event;
  if (
    ![
      RunStatusType.MANUALLY_VERIFIED,
      RunStatusType.OCR_VERIFICATION_SUCCEEDED
    ].includes(status)
  ) {
    return { header: null };
  }

  const run = await event.ref.get();

  if (!exists(run)) {
    return { header: null };
  }

  const runDate = convertDateStringFormat(run.data().publicationDate, {
    from: 'YYYY-MM-DD',
    to: 'D MMM YYYY'
  });

  const changedBy = await event.statusChangedBy?.get();
  const eedition = await event.data?.eedition?.get();

  const includeEEditionLink = exists(eedition);

  const header = getEventInitiatorString(changedBy, newspaperName);

  const content = `Verified publication for ${runDate}.`;

  const useColumnCDN = getBooleanFlag(LaunchDarklyFlags.ENABLE_COLUMN_CDN);
  const link = includeEEditionLink
    ? {
        text: 'View E-Edition.',
        url: cdnIfy(eedition.data().storagePath, {
          useImgix: true,
          useColumnCDN
        })
      }
    : undefined;

  return { header, content, link };
};

export const getEventDescription = async (
  event: Event,
  settings: ActivityLogSettings
): Promise<EventLogMessage> => {
  switch (event.type) {
    // Notice events
    case EventTypes.NOTICE_SUBMITTED_EVENT:
      return await renderNoticeSubmittedEvent(event, settings);
    case EventTypes.NOTICE_CONFIRMED:
      return await renderNoticeConfirmedEvent(
        event as EventTypes.NoticeConfirmedEvent,
        settings
      );
    case EventTypes.NOTICE_EDITED:
      return await renderNoticeEditedEvent(
        event as EventTypes.NoticeEditedEvent,
        settings
      );
    case EventTypes.AFFIDAVIT_UPLOADED:
      return await renderAffidavituploadedEvent(
        event as EventTypes.AffidavitUploadedEvent,
        settings
      );
    case EventTypes.AFFIDAVIT_RE_UPLOADED:
      return await renderAffidavitReuploadedEvent(
        event as EventTypes.AffidavitReUploadedEvent,
        settings
      );
    case EventTypes.NOTICE_CANCELLED:
      return await renderNoticeCancelledEvent(settings);
    case EventTypes.NOTICE_CANCELLATION_REQUESTED:
      return await renderNoticeCancellationRequestedEvent(
        event as EventTypes.NoticeCancellationRequestedEvent,
        settings
      );
    case EventTypes.NOTICE_CANCELLED_AND_REFUNDED:
      return await renderNoticeRefundedEvent(settings);
    case EventTypes.NOTICE_TRANSFERRED:
      return await renderNoticeTransferredEvent(settings);
    // Sync events
    case EventTypes.NOTICE_SYNC_EVENT:
      return settings.isPublisher
        ? { header: `Notice Synced.` }
        : { header: null };
    case EventTypes.NOTICE_SYNC_FAILURE_EVENT:
      return settings.isPublisher
        ? { header: `Notice Failed to Sync.` }
        : { header: null };

    // Invoice events
    case EventTypes.INVOICE_MAIL_SENT_EVENT:
      return await renderInvoiceMailSent(settings);
    case EventTypes.INVOICE_CREATED:
      return await renderInvoiceCreatedEvent(
        event as EventTypes.InvoiceCreateEvent,
        settings
      );
    case EventTypes.INVOICE_REMINDER_SENT:
      return await renderInvoiceReminderSentEvent(
        event as EventTypes.InvoiceReminderSentEvent,
        settings
      );
    case EventTypes.INVOICE_PAYMENT_PROCESSED:
      switch (
        (event as EventTypes.InvoicePaymentProcessEvent).data.paymentMethod
      ) {
        case 'ach':
          return await renderACHPaymentProcessedEvent(settings);
        case 'card':
          return await renderCardPaymentProcessedEvent(settings);
        case 'check':
          return await renderCheckPaymentProcessedEvent(settings);
        case 'bulk-payment':
          return await renderBulkPaymentProcessedEvent(settings);
        default:
          return await renderPaymentProcessedEvent(settings);
      }
    case EventTypes.INVOICE_PAYMENT_INITIATED:
      return await renderPaymentInitiatedEvent(settings);
    case EventTypes.INVOICE_PAYMENT_PROCESSED_MANUAL:
      return await renderPaymentProcessedManualEvent(
        event as InvoicePaymentProcessedManualEvent,
        settings
      );
    case EventTypes.INVOICE_PAYMENT_NOTE:
      return await renderPaymentNoteEvent(
        event as EventTypes.InvoicePaymentNoteEvent,
        settings
      );
    case EventTypes.INVOICE_PAID_OUTSIDE:
      return renderInvoicePaidOutsideEvent(settings);
    case EventTypes.INVOICE_CANCELLED:
      return await renderInvoiceCancelledEvent(
        event as EventTypes.InvoiceCancelledEvent,
        settings
      );
    case EventTypes.INVOICE_REFUNDED:
      return await renderInvoiceRefundedEvent(
        event as EventTypes.InvoiceRefundedEvent,
        settings
      );
    case EventTypes.NOTICE_NOTE_ADDED:
      return await renderNoticeNoteAddedEvent(event);
    case EventTypes.NOTICE_AT_DEADLINE_SYNC:
    case EventTypes.NOTICE_CANCELLED_SYNC:
    case EventTypes.NOTICE_CONFIRMED_SYNC:
    case EventTypes.NOTICE_CREATED_SYNC:
    case EventTypes.NOTICE_EDITED_SYNC:
    case EventTypes.INVOICE_CREATED_SYNC:
    case EventTypes.INVOICE_PAID_OUTSIDE_SYNC:
    case EventTypes.INVOICE_PAYMENT_INITIATED_SYNC:
    case EventTypes.INVOICE_PAYMENT_PROCESSED_MANUAL_SYNC:
    case EventTypes.INVOICE_PAYMENT_PROCESSED_SYNC:
    case EventTypes.INVOICE_REFUNDED_SYNC:
    case EventTypes.INVOICE_UPFRONT_PAYMENT_WAIVER_SYNC:
    case EventTypes.CUSTOM_ID_UPDATED_SYNC:
    case EventTypes.MANUAL_SYNC_REQUEST_SYNC:
    case EventTypes.MANUAL_BUILD_AD_REQUEST_SYNC:
    case EventTypes.MANUAL_CANCEL_BUILD_AD_REQUEST_SYNC:
      return await renderSyncEvent(event, settings);
    case EventTypes.NOTICE_AT_DEADLINE:
      return await renderNoticeAtDeadlineEvent(event, settings);
    case EventTypes.MANUAL_SYNC_REQUEST:
      return await renderManualSyncRequestEvent(event, settings);
    case EventTypes.RUN_STATUS_CHANGE:
      return await renderRunStatusChangeEvent(event, settings);
    default:
      return { header: null };
  }
};

export const getEventDataFromEventSnaps = async (
  noticeEventSnaps: ESnapshotExists<Event>[],
  notice: ESnapshotExists<ENotice>,
  user: ESnapshotExists<EUser>
): Promise<EventWithTimestamp[]> => {
  const User = getModelFromSnapshot(UserModel, getFirebaseContext(), user);
  const advertiserSnap = await getOrThrow(notice.data().filer);
  const publisherOrganization = await getOrThrow(notice.data().newspaper);
  const advertiserOrganization = notice.data().filedBy
    ? await getOrThrow(notice.data().filedBy)
    : null;

  const advertiserOrgName =
    advertiserOrganization?.data().name ||
    advertiserSnap.data().organizationName ||
    '';
  const newspaperName = publisherOrganization.data().name || '';
  const timezone = publisherOrganization.data().iana_timezone || '';

  const settings: ActivityLogSettings = {
    advertiserOrgName,
    advertiserSnap,
    newspaperName,
    timezone,
    notice,
    user,
    isPublisher: User.isPublisher
  };

  // Add a notice submitted event if it doesn't exist
  const eventSnaps = noticeEventSnaps.slice();
  const hasNoticeSubmitted = eventSnaps?.some(
    e => e.data().type === EventTypes.NOTICE_SUBMITTED_EVENT
  );
  if (!hasNoticeSubmitted) {
    const fakeEvent: NoticeSubmittedEvent = {
      createdAt: notice.data().createTime || notice.data().confirmedAt,
      type: EventTypes.NOTICE_SUBMITTED_EVENT,
      notice: notice.ref,
      data: {
        newspaper: notice.data().newspaper,
        filer: notice.data().filer,
        publicationDates: notice.data().publicationDates,
        submittedNoticeData: {}
      }
    };

    // TODO: We really should not be doing this! If we want to fake a snapshot
    // we could use a SnapshotModel with a bogus path/id but that's also a bad
    // idea.
    eventSnaps.push({
      data: () => fakeEvent
    } as any);
  }

  const unsortedEvents = await awaitAllPromises(
    eventSnaps.map<Promise<EventWithTimestamp>>(async eventSnap => {
      const event = eventSnap.data();
      const eventDescription = await getEventDescription(
        eventSnap.data(),
        settings
      );
      return {
        ...eventDescription,
        hidden: event.hidden,
        timestamp: event.createdAt,
        dateString: moment(event.createdAt.toMillis())
          .tz(settings.timezone || 'America/Chicago')
          .format('D MMM YYYY [at] LT z'),
        type: event.type
      };
    })
  );
  const noticeEvents = getFulfilled(unsortedEvents);

  const sortedEvents = noticeEvents.sort((event1, event2) => {
    if (event1.type === EventTypes.NOTICE_SUBMITTED_EVENT) return -1;
    if (event2.type === EventTypes.NOTICE_SUBMITTED_EVENT) return 1;
    if (event1.timestamp && event2.timestamp) {
      if (event1.timestamp.toMillis() > event2.timestamp.toMillis()) return 1;
      if (event1.timestamp.toMillis() < event2.timestamp.toMillis()) return -1;
    } else {
      logAndCaptureMessage('Event does not have createdAt property', {
        event1Link: event1.link?.url,
        event2Link: event2.link?.url
      });
    }
    return 0;
  });

  const filteredEvents = sortedEvents.filter(event => event.header !== null);

  return filteredEvents;
};
