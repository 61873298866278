import { EOrganization, ESnapshotExists } from 'lib/types';
import AuthActions, { selectShowAllOrgsNotices } from 'redux/auth';
import NoticeActions from 'redux/notices';
import { AppThunk } from 'redux/types';
import { NavigateFunction } from 'react-router-dom';
import { ACTIVE_NOTICES_TAB } from 'routes/notices/table/types';
import { MAIN_ROUTES } from 'router/routes';

export function reloadAppState(
  navigate: NavigateFunction,
  searchParams: URLSearchParams
): AppThunk {
  return dispatch => {
    const currentPath = window.location.pathname;
    if (
      currentPath.startsWith(MAIN_ROUTES.NOTICE) ||
      currentPath.startsWith(MAIN_ROUTES.SETTINGS) ||
      currentPath.startsWith(MAIN_ROUTES.AFFIDAVITS)
    ) {
      navigate(MAIN_ROUTES.NOTICES);
      dispatch(NoticeActions.setTab(ACTIVE_NOTICES_TAB));
      dispatch(NoticeActions.setCurrentPage(0));
    } else {
      // Reloads app state without losing auth state
      navigate(`${currentPath}?${searchParams.toString()}`);
    }
  };
}

export function processActiveOrganizationChange(
  newActiveOrg: ESnapshotExists<EOrganization>,
  navigate: NavigateFunction,
  searchParams: URLSearchParams
): AppThunk {
  return (dispatch, getState) => {
    const state = getState();
    if (selectShowAllOrgsNotices(state)) {
      dispatch(AuthActions.showAllOrgsNotices(false));
    }

    // We set the active organization to null before redirecting to the notices table
    // to avoid any listener on other pages that could reset the active organization
    dispatch(AuthActions.setActiveOrganization(null));
    dispatch(AuthActions.setActiveOrganization(newActiveOrg));
    dispatch(reloadAppState(navigate, searchParams));
  };
}
