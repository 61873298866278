import { AnySchema, Schema, ValidationResult } from 'joi';
import { ResponseOrError, wrapError, wrapSuccess } from '../types/responses';

export function validateJoiSchema<T>(
  schema: AnySchema,
  payload: unknown
): { success: true; payload: T } | { success: false; error: Error } {
  const { error, value }: ValidationResult = schema.validate(payload);

  if (error) {
    return { success: false, error };
  }

  return { success: true, payload: value as T };
}

export function safeValidateSchema<T>(
  schema: Schema,
  data: unknown,
  message?: string
): ResponseOrError<T> {
  const validation = validateJoiSchema<T>(schema, data);
  if (!validation.success) {
    return wrapError(
      Error(
        `${message || 'Invalid value'}, Error: ${
          validation.error
        }, Data: ${JSON.stringify(data)}`
      )
    );
  }

  return wrapSuccess(validation.payload);
}
