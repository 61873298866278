/**
 * The utilities in this file help ensure consistent data across our various
 * CSV export endpoints.
 */

import moment from 'moment';
import htmlToText from 'html-to-text';

import { dbToUICurrency } from '../pricing/ui';
import { firestoreTimestampOrDateToDate, getDisplayName } from '../helpers';
import {
  getDistributeFeeSettings,
  invoiceDataToDBPricingObject
} from '../pricing';
import {
  Customer,
  CustomerOrganization,
  EInvoice,
  ENotice,
  EOrganization,
  ERate,
  ESnapshot,
  ESnapshotExists,
  EUser,
  exists
} from '../types';
import { Ad } from '../types/ad';
import { getErrorReporter } from './errors';
import { ColumnService } from '../services/directory';

export const sanitizeValue = (val: any): string => {
  if (!val) {
    return '';
  }

  let newVal = typeof val === 'string' ? val : `${val}`;

  // Replace newlines with spaces
  newVal = newVal.replace(/\n/g, ' ');

  // Replace quotes with double quotes
  newVal = newVal.replace(/"/g, '""');

  // Remove commas
  newVal = newVal.replace(/,/g, '');

  // Trim excess whitespace
  newVal = newVal.trim();

  return newVal;
};

/**
 * Stringify CSV data.
 */
export const makeCsvContent = (headers: string[], body: string[][]): string => {
  const sanitizedHeaders = headers.map(sanitizeValue);
  const sanitizedBody = body.map(row => row.map(sanitizeValue));
  const csvRows = [sanitizedHeaders, ...sanitizedBody];
  return csvRows.map(e => e.join(',')).join('\n');
};

export const PAYOUT_CSV_HEADERS = [
  'Payout Date',
  'Notice or Order ID',
  'NewspaperOrder ID',
  'Product Category',
  'Paper Name',
  'Total Cost',
  'Total Invoiced Amount',
  'Publication Dates',
  'Invoice Number',
  'Filer',
  'Organization',
  'Account ID',
  'Transaction Type',
  'Custom ID',
  'Transaction Date',
  'Preview'
];

// Header: 'ID'
export const noticeID = (
  notice: ESnapshotExists<ENotice> | undefined
): string => {
  return notice?.id || 'N/A';
};

// Header: 'Paper Name'
export const paperName = (
  newspaper: ESnapshotExists<EOrganization> | undefined
): string => {
  if (!newspaper) return 'N/A';
  return sanitizeValue(newspaper.data().name);
};

// Header: 'Publication Dates'
export const publicationDates = (
  notice: ESnapshotExists<ENotice> | undefined
): string => {
  return (
    notice
      ?.data()
      .publicationDates.map(ts => {
        const publicationDate = ts.toDate();
        return publicationDate.toLocaleDateString('en-US', {
          month: '2-digit',
          day: '2-digit',
          year: 'numeric'
        });
      })
      .join(';') || 'N/A'
  );
};

// Header: 'Notice name'
export const noticeName = (notice: ESnapshotExists<ENotice>): string => {
  // eslint-disable-next-line no-useless-escape
  return notice.data().referenceId ? `\"${notice.data().referenceId}\"` : '';
};

// Header: 'Total Invoiced Amount'
export const totalInvoicedAmount = (
  newspaperSnap: ESnapshotExists<EOrganization> | undefined,
  invoiceSnap: ESnapshotExists<EInvoice> | undefined,
  rateSnap: ESnapshotExists<ERate> | undefined
): string => {
  if (!invoiceSnap || !newspaperSnap || !rateSnap) return 'N/A';

  const {
    inAppLineItems,
    convenienceFeePct,
    convenienceFeeFlat = 0,
    convenienceFeeCap,
    pricing: { affidavitFeeInCents },
    inAppTaxPct
  } = invoiceSnap.data();

  // TODO: Do we need to do all this just to get the total?
  const dbPricingObject = invoiceDataToDBPricingObject(
    inAppLineItems,
    convenienceFeePct,
    convenienceFeeFlat,
    convenienceFeeCap,
    affidavitFeeInCents,
    inAppTaxPct,
    getDistributeFeeSettings(newspaperSnap, rateSnap),
    rateSnap.data()
  );

  const uiTotal = dbToUICurrency(dbPricingObject.total);
  if (uiTotal) {
    return uiTotal.toFixed(2);
  }

  return 'N/A';
};

// Header: 'Invoice Number'
export const invoiceNumber = (
  invoiceSnap: ESnapshotExists<EInvoice> | undefined
): string => {
  return invoiceSnap?.data().invoice_number || 'N/A';
};

// Header: 'Filer'
export const filer = (user: ESnapshot<EUser> | undefined): string => {
  if (!user) {
    return 'N/A';
  }

  const userData = user.data();
  return userData
    ? sanitizeValue(getDisplayName(userData.firstName, userData.lastName))
    : 'N/A';
};

// Header: 'Organization'
export const organization = (
  org: ESnapshot<EOrganization> | undefined
): string => {
  return sanitizeValue(org?.data()?.name || 'N/A');
};

// Header: 'Account ID'
export const accountID = (
  customer: ESnapshotExists<Customer> | undefined,
  customerOrganization: ESnapshotExists<CustomerOrganization> | undefined
): string => {
  const customerAccountNumber = customer?.data().internalID;

  if (customerAccountNumber) {
    return customerAccountNumber;
  }

  const customerOrganizationAccountNumber =
    customerOrganization?.data().internalID;

  if (customerOrganizationAccountNumber) {
    return customerOrganizationAccountNumber;
  }

  return 'N/A';
};

// Header: 'Total Cost'
export const totalCost = (
  invoiceSnap: ESnapshotExists<EInvoice>,
  newspaperSnap: ESnapshotExists<EOrganization>,
  rate: ESnapshotExists<ERate> | undefined
): string => {
  const {
    inAppLineItems,
    convenienceFeePct,
    convenienceFeeFlat = 0,
    convenienceFeeCap,
    pricing: { affidavitFeeInCents },
    inAppTaxPct,
    appliedBalance
  } = invoiceSnap.data();

  const DBPricingObject = invoiceDataToDBPricingObject(
    inAppLineItems,
    convenienceFeePct,
    convenienceFeeFlat,
    convenienceFeeCap,
    affidavitFeeInCents,
    inAppTaxPct,
    getDistributeFeeSettings(newspaperSnap, rate),
    rate?.data(),
    appliedBalance
  );
  return `$${DBPricingObject.total / 100}`;
};

// Header: 'Invoice Paid Date'
export const invoicePaidDate = (
  invoiceSnap: ESnapshotExists<EInvoice>
): string => {
  const { finalized_at } = invoiceSnap.data();
  if (!finalized_at) return '';

  const invoicePaidDate = firestoreTimestampOrDateToDate(finalized_at);

  return moment(invoicePaidDate).format('MM/DD/YYYY');
};

// Header: Custom ID
export const customID = (
  notice: ESnapshotExists<ENotice> | undefined
): string => {
  if (!exists(notice)) return 'N/A';
  const noticeCustomId = notice.data().customId;
  return typeof noticeCustomId === 'string'
    ? sanitizeValue(noticeCustomId)
    : 'N/A';
};

// Header: Preview
export const preview = (
  notice: ESnapshotExists<ENotice> | undefined
): string => {
  if (!exists(notice)) return '';
  const { confirmedHtml, text } = notice.data();
  try {
    let previewText = '';
    if (confirmedHtml) {
      previewText = htmlToText.fromString(confirmedHtml, {
        singleNewLineParagraphs: true
      });
    }

    if (text && !previewText) {
      previewText = text;
    }

    // Get the first 100 characters, escape any quotes for CSV and trim excess whitespace
    previewText = sanitizeValue(previewText).slice(0, 100).trim();

    // Wrap preview text in quotes so it show up in one cell in the CSV, even if it has commas, etc.
    return `"${previewText}"`;
  } catch (e) {
    const err = e as any;
    getErrorReporter().logAndCaptureError(
      ColumnService.PAYMENTS,
      err,
      'Error generating preview for notice in generating payout CSV',
      {
        noticeId: notice.id
      }
    );
  }

  return '';
};

// Header: 'Transaction Date'
/**
 * When was the transaction created?
 * @param created when the transaction was created in seconds from the Unix epoch.
 * @returns {string} date of the transaction. Ex. 02/12/2022
 */
export const transactionDate = (
  created: number | string | undefined
): string => {
  if (!created) {
    return '';
  }
  // Stripe 'payout.created' values are numbers
  if (typeof created === 'number') {
    return moment(created * 1000).format('MM/DD/YYYY');
  }
  // Dwolla transfers use an ISO-8601 timestamp string for "created"
  if (typeof created === 'string') {
    return moment(created, moment.ISO_8601).format('MM/DD/YYYY');
  }
  return '';
};

export const csvToHtml = (rows: string[][], headers: string[]) => {
  let parsedHtml = '<table><tr>';

  headers.forEach(header => (parsedHtml += `<th>${header}</th>`));

  parsedHtml += '</tr>';

  rows.forEach(
    row =>
      (parsedHtml += `<tr>${row
        .map(c => `<td>${c.replace(/"/g, '')}</td>`)
        .join('')}</tr>`)
  );

  parsedHtml += '</table>';

  return parsedHtml;
};

export const getColumnValueForRow = (
  row: (string | number)[],
  header: string,
  headers: (string | number)[]
) => {
  return row[headers.indexOf(header)];
};

// Header: Preview
export const previewAdContent = (
  ad: ESnapshotExists<Ad> | undefined
): string => {
  if (!exists(ad)) return '';
  const { content } = ad.data();
  try {
    let previewText = '';
    if (content) {
      previewText = htmlToText.fromString(content, {
        singleNewLineParagraphs: true
      });
    }

    // Get the first 100 characters, escape any quotes for CSV and trim excess whitespace
    previewText = sanitizeValue(previewText).slice(0, 100).trim();

    // Wrap preview text in quotes so it show up in one cell in the CSV, even if it has commas, etc.
    return `"${previewText}"`;
  } catch (e) {
    const err = e as any;
    getErrorReporter().logAndCaptureError(
      ColumnService.PAYMENTS,
      err,
      'Error generating preview for ad in generating payout CSV',
      {
        adId: ad.id
      }
    );
    return 'Unable to generate preview';
  }
};
