import { RateType } from 'lib/enums';
import { AdRate, EOrganization, ERate, ESnapshotExists } from 'lib/types';
import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';
import LabeledSwitch from 'lib/components/LabeledSwitch';
import {
  isNoticeRate,
  isOrderRate,
  OrderRate,
  isRateWithWeeklyPricingPeriod
} from 'lib/types/rates';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { useBooleanFlag } from 'utils/flags';
import { OrganizationModel } from 'lib/model/objects/organizationModel';
import { getModelFromSnapshot } from 'lib/model';
import { getFirebaseContext } from 'utils/firebase';
import { useAppSelector } from 'redux/hooks';
import { selectUser } from 'redux/auth';
import { UserModel } from 'lib/model/objects/userModel';
import { DayRates } from './DayRates';
import { OffsetWithFlatFee } from './OffsetWithFlatFee';
import { BoldFees } from './BoldFees';
import { DistributeFees } from './DistributeFees';
import ColorFees from './ColorFees';
import JobcaseFeeConfig from './JobcaseFees';

const areOffsetsEnabled = (rate: AdRate) => {
  if (rate.rateType === RateType.flat.value) return false;
  if (rate.rateType === RateType.nebraska.value) return false;
  if (rate.rateType === RateType.per_run.value) return false;
  return true;
};

type AdvancedSettingsCardProps = {
  onUpdateRateData: (newRate: AdRate | ERate | OrderRate) => void;
  initialRateData: AdRate | ERate;
  updatedRateData: AdRate | ERate;
  activeOrganization: ESnapshotExists<EOrganization>;
};

export default function AdvancedSettingsCard({
  onUpdateRateData,
  initialRateData,
  updatedRateData,
  activeOrganization
}: AdvancedSettingsCardProps) {
  const enablePeriodBasedOrderPricing = useBooleanFlag(
    LaunchDarklyFlags.ENABLE_PERIOD_BASED_ORDER_PRICING
  );
  const enableJobcaseUpsell = useBooleanFlag(
    LaunchDarklyFlags.ENABLE_JOBCASE_UPSELL
  );

  const offsetsEnabled = areOffsetsEnabled(updatedRateData);
  const isEditingNoticeRate =
    isNoticeRate(initialRateData) && isNoticeRate(updatedRateData);
  const activeOrganizationModel = getModelFromSnapshot(
    OrganizationModel,
    getFirebaseContext(),
    activeOrganization
  );

  const user = useAppSelector(selectUser);
  const userModel = user
    ? getModelFromSnapshot(UserModel, getFirebaseContext(), user)
    : undefined;

  return (
    <CardGridLayout
      header={{
        title: 'Advanced Settings',
        description:
          'Take fine-grained control over how this rate is charged and appears to customers.'
      }}
    >
      {isEditingNoticeRate &&
        (!enablePeriodBasedOrderPricing ||
          !isRateWithWeeklyPricingPeriod(updatedRateData)) && (
          <DayRates
            values={updatedRateData.dayRates ?? null}
            initialValue={initialRateData.dayRates ?? null}
            onChange={dayRates =>
              onUpdateRateData({ ...updatedRateData, dayRates })
            }
          />
        )}

      {offsetsEnabled && (
        <OffsetWithFlatFee
          value={updatedRateData}
          initialValue={initialRateData}
          onChange={changes =>
            onUpdateRateData({ ...updatedRateData, ...changes })
          }
        />
      )}

      {isEditingNoticeRate && (
        <GridInput fullWidth>
          <LabeledSwitch
            label="Hide prices during placement?"
            description={
              userModel?.isColumnUser
                ? "If enabled, customers won't see expected prices while they create their notice. NOTE: Price previews are automatically hidden in the placement flow for Express papers."
                : "If enabled, customers won't see expected prices while they create their notice."
            }
            value={Boolean(updatedRateData.hidePreview)}
            onChange={val => {
              onUpdateRateData({
                ...updatedRateData,
                hidePreview: val
              });
            }}
            disabled={activeOrganizationModel?.isExpress}
          />
        </GridInput>
      )}

      <BoldFees
        value={updatedRateData}
        initialValue={initialRateData}
        onChange={changes =>
          onUpdateRateData({ ...updatedRateData, ...changes })
        }
      />

      {isOrderRate(updatedRateData) && (
        <>
          <ColorFees value={updatedRateData} onChange={onUpdateRateData} />
          {enableJobcaseUpsell && (
            <JobcaseFeeConfig
              value={updatedRateData}
              onChange={onUpdateRateData}
            />
          )}
        </>
      )}

      {isEditingNoticeRate && (
        <DistributeFees
          value={updatedRateData.distributeEnoticeFee}
          initialValue={initialRateData.distributeEnoticeFee}
          onChange={distributeEnoticeFee =>
            onUpdateRateData({ ...updatedRateData, distributeEnoticeFee })
          }
        />
      )}
    </CardGridLayout>
  );
}
