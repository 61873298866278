import { State } from 'lib/enums';
import React from 'react';
import { Alert } from 'lib/components/Alert';
import {
  CheckCircleIcon,
  ExclamationCircleIcon
} from '@heroicons/react/24/outline';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { TextField } from 'lib/components/TextField';
import { Customer, EOrganization, ERate, ERef, ESnapshot } from 'lib/types';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { getBooleanFlag } from 'utils/flags';
import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';
import OrganizationSearch from './OrganizationSearch';

export type NewCustomerAlertType =
  | 'none'
  | 'existingCustomer'
  | 'deletedCustomer'
  | 'existingUser'
  | 'existingAnonymousUser';

/**
 * A modified version of the Customer / CustomerOrg types
 * to track input data for this settings page
 */
export type CustomerObjDataFields = {
  // Customer fields
  linerRateSnap?: ESnapshot<ERate>;
  displayRateSnap?: ESnapshot<ERate>;
  email?: string;
  // Customer Organization fields
  name?: string | null;
  // Other fields
  organizationsToAdd?: ERef<EOrganization>[];
  customerOrgAddOptions?: ERef<EOrganization>[];
} & Partial<Customer>;

export type CreateOrEditModalDetailsProps = {
  value: CustomerObjDataFields;
  onChange: (value: CustomerObjDataFields) => void;
  activeOrganization: ESnapshot<EOrganization> | null;
  alert?: NewCustomerAlertType;
  isNewCustomer?: boolean;
  isCustomerOrganization?: boolean;
  showOrganizationName?: boolean;
};

export function CreateOrEditModalDetails({
  value,
  onChange,
  activeOrganization,
  alert,
  isNewCustomer,
  isCustomerOrganization,
  showOrganizationName
}: CreateOrEditModalDetailsProps) {
  const showAddOrgsFromCustomerList =
    !isCustomerOrganization &&
    getBooleanFlag(LaunchDarklyFlags.ENABLE_ADD_ORGS_FROM_CUSTOMER_LIST);
  const heading = `Provide ${
    isCustomerOrganization ? 'organization' : 'personal'
  } details.`;
  const description = `Save basic details about your ${
    isCustomerOrganization ? 'organization' : 'customer'
  }, including their name and contact information.`;

  return (
    <CardGridLayout header={{ title: heading, description }}>
      {isCustomerOrganization ? (
        <>
          <GridInput>
            <TextField
              id="organization-name"
              labelText="Organization name"
              value={value.name || ''}
              onChange={name =>
                onChange({
                  ...value,
                  name
                })
              }
            />
          </GridInput>
          <GridInput>
            <TextField
              id="organization-id"
              labelText="Organization ID"
              value={value.internalID || ''}
              onChange={internalID =>
                onChange({
                  ...value,
                  internalID
                })
              }
            />
          </GridInput>
        </>
      ) : (
        <>
          <GridInput fullWidth>
            <TextField
              id="customer-email"
              labelText="Email address"
              type="email"
              value={value.email || ''}
              onChange={email => {
                onChange({
                  ...value,
                  email
                });
              }}
              /**
               * Edit flow: email editing is blocked
               * Create flow: email editiing triggers user/customer query
               */
              disabled={!isNewCustomer}
              errorText={alert === 'existingCustomer' ? ' ' : ''}
              placeholder="Enter an email"
              required
            />
          </GridInput>
          {alert && (
            <EmailAlert
              alert={alert}
              email={value.email || ''}
              organizationName={activeOrganization?.data()?.name || ''}
            />
          )}
          <GridInput>
            <TextField
              id="customer-first-name"
              labelText="First name"
              value={value.firstName || ''}
              onChange={firstName => {
                onChange({
                  ...value,
                  firstName
                });
              }}
              maxLength={100}
              placeholder="Enter a first name"
              disabled={
                isNewCustomer && (!alert || alert === 'existingCustomer')
              }
              required
            />
          </GridInput>
          <GridInput>
            <TextField
              id="customer-last-name"
              labelText="Last name"
              value={value.lastName || ''}
              onChange={lastName =>
                onChange({
                  ...value,
                  lastName
                })
              }
              maxLength={100}
              placeholder="Enter a last name"
              disabled={
                isNewCustomer && (!alert || alert === 'existingCustomer')
              }
            />
          </GridInput>
          <GridInput fullWidth>
            <TextField
              id="customer-account-number"
              labelText="Account number"
              type="text"
              value={value.internalID || ''}
              onChange={internalID =>
                onChange({
                  ...value,
                  internalID
                })
              }
              placeholder="Enter an account number"
              disabled={
                isNewCustomer && (!alert || alert === 'existingCustomer')
              }
            />
          </GridInput>
        </>
      )}

      <GridInput>
        <TextField
          id="phone-number"
          labelText="Phone number"
          type="tel"
          value={value.phone || ''}
          onChange={phone =>
            onChange({
              ...value,
              phone
            })
          }
          placeholder="Enter a phone number"
          disabled={isNewCustomer && (!alert || alert === 'existingCustomer')}
        />
      </GridInput>
      <GridInput>
        <TextField
          id="address-line-1"
          labelText="Address line 1"
          value={value.address || ''}
          onChange={address =>
            onChange({
              ...value,
              address
            })
          }
          placeholder="Enter address line 1"
          disabled={isNewCustomer && (!alert || alert === 'existingCustomer')}
        />
      </GridInput>
      <GridInput>
        <TextField
          id="address-line-2"
          labelText="Address line 2"
          value={value.addressLine2 || ''}
          onChange={addressLine2 =>
            onChange({
              ...value,
              addressLine2
            })
          }
          placeholder="Enter address line 2"
          disabled={isNewCustomer && (!alert || alert === 'existingCustomer')}
        />
      </GridInput>
      <GridInput>
        <TextField
          id="city"
          labelText="City"
          value={value.city || ''}
          onChange={city =>
            onChange({
              ...value,
              city
            })
          }
          placeholder="Enter a city"
          disabled={isNewCustomer && (!alert || alert === 'existingCustomer')}
        />
      </GridInput>
      <GridInput>
        <ColumnSelect
          labelText="State"
          disabled={isNewCustomer && (!alert || alert === 'existingCustomer')}
          id="state"
          placeholder="Select a state"
          allowUndefined
          value={value.state?.toString() || ''}
          options={State.items().map(state => ({
            value: state.value.toString(),
            label: state.label
          }))}
          onChange={newValue => {
            onChange({
              ...value,
              state: parseInt(newValue, 10)
            });
          }}
        />
      </GridInput>
      <GridInput>
        <TextField
          id="zip-code"
          labelText="Zip Code"
          type="postal-code"
          value={value.zipCode || ''}
          onChange={zipCode =>
            onChange({
              ...value,
              zipCode
            })
          }
          placeholder="Enter a zip code"
          disabled={isNewCustomer && (!alert || alert === 'existingCustomer')}
        />
      </GridInput>
      {showOrganizationName && (
        <GridInput fullWidth>
          <TextField
            id="organization-name"
            labelText="Organization Name"
            value={value.organizationName || ''}
            onChange={organizationName =>
              onChange({
                ...value,
                organizationName
              })
            }
            placeholder="Enter an organization name"
          />
        </GridInput>
      )}
      {showAddOrgsFromCustomerList && value.customerOrgAddOptions && (
        <GridInput fullWidth>
          <OrganizationSearch
            activeOrganization={activeOrganization}
            updatedCustomerData={value}
            onChange={(newList: ERef<EOrganization>[]) => {
              onChange({
                ...value,
                organizationsToAdd: newList
              });
            }}
            disabled={
              !!isNewCustomer && (!alert || alert === 'existingCustomer')
            }
          />
        </GridInput>
      )}
    </CardGridLayout>
  );
}

function EmailAlert({
  alert,
  email,
  organizationName
}: {
  alert: NewCustomerAlertType;
  email: string;
  organizationName: string;
}) {
  const showExistingCustomerAlert = alert === 'existingCustomer';
  const showExistingUserAlert =
    alert === 'existingUser' || alert === 'existingAnonymousUser';
  const showDeletedCustomerAlert = alert === 'deletedCustomer';

  if (
    !showExistingCustomerAlert &&
    !showExistingUserAlert &&
    !showDeletedCustomerAlert
  ) {
    return null;
  }

  return (
    <GridInput fullWidth>
      {showExistingCustomerAlert && (
        <Alert
          id="existing-customer-error"
          status="error"
          icon={<ExclamationCircleIcon className="h-5 w-5" />}
          description={`Oops! It looks like ${email} is already a customer of ${organizationName}`}
        />
      )}
      {showExistingUserAlert && (
        <Alert
          id="existing-user-invite-success"
          status="success"
          icon={<CheckCircleIcon className="h-5 w-5" />}
          description={`Customer ${
            alert === 'existingAnonymousUser'
              ? "has a pending invite to join Column. They'll receive an email invitation to place notices with you."
              : 'already has a Column account.'
          }`}
        />
      )}
      {showDeletedCustomerAlert && (
        <Alert
          id="customer-archived-alert"
          status="warning"
          icon={<ExclamationCircleIcon className="h-5 w-5" />}
          description={`This customer was formerly deleted from your customers list.`}
        />
      )}
    </GridInput>
  );
}
