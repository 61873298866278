import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { logAndCaptureMessage } from 'utils';
import { useAppSelector } from 'redux/hooks';
import { OccupationType } from 'lib/enums';
import { selectUser } from 'redux/auth';
import { exists } from 'lib/types';
import api from 'api';
import { REGISTER_ROUTES } from 'router/routes';
import Occupations from './Occupations';

export default function OccupationsRoute() {
  const navigate = useNavigate();
  const user = useAppSelector(selectUser);
  const [occupationType, setOccupationType] = useState<number>();
  const [loading, setLoading] = useState(false);

  const nextButtonClicked = async () => {
    if (occupationType !== null) {
      await completeBasicSignup();
    }
  };

  const completeBasicSignup = async () => {
    if (!exists(user)) return;
    setLoading(true);

    const response = await api.post('users/assign-occupation', {
      occupation: occupationType
    });
    if (response.success) {
      // Artifical delay before navigating to avoid showing a glimpse of previous screen
      setTimeout(() => {
        if (occupationType === OccupationType.individual.value) {
          navigate(REGISTER_ROUTES.INDIVIDUAL);
        } else if (occupationType === OccupationType.publishing.value) {
          navigate(REGISTER_ROUTES.PUBLISHER);
        } else {
          navigate(REGISTER_ROUTES.ORGANIZATION);
        }
      }, 500);
    } else {
      setLoading(false);
      logAndCaptureMessage(response.error);
    }
  };

  return (
    <Occupations
      occupationType={occupationType}
      onOccupationTypeChange={setOccupationType}
      onOccupationSelect={nextButtonClicked}
      loading={loading}
    />
  );
}
