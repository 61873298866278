import { EOrganization, ESnapshotExists, EUser, exists } from 'lib/types';
import { isPublisherOrganization } from 'lib/utils/organizations';
import { isPublisher } from 'lib/utils/users';
import { getModelFromSnapshot } from 'lib/model';
import { getFirebaseContext } from 'utils/firebase';
import { OrganizationModel } from 'lib/model/objects/organizationModel';
import { Product } from 'lib/enums';
import { getShouldUseAdvancedPagination } from 'routes/pagination/paginationTableUtils';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { useAppSelector } from 'redux/hooks';
import {
  selectAvailableOrganizations,
  selectShowAllOrgsNotices
} from 'redux/auth';
import { PopoverItemType } from './HeaderNavBarPopover';

const getPaginationTabItemsInner = ({
  publicNoticesPaginationEnabled,
  obituariesPaginationEnabled,
  classifiedsPaginationEnabled
}: {
  publicNoticesPaginationEnabled: boolean;
  obituariesPaginationEnabled: boolean;
  classifiedsPaginationEnabled: boolean;
}): PopoverItemType[] => [
  {
    id: Product.Notice,
    path: `/pagination?product=${Product.Notice}`,
    label: 'Public Notices',
    enabled: publicNoticesPaginationEnabled
  },
  {
    id: Product.Obituary,
    path: `/pagination?product=${Product.Obituary}`,
    label: 'Obituaries',
    enabled: obituariesPaginationEnabled
  },
  {
    id: Product.Classified,
    path: `/pagination?product=${Product.Classified}`,
    label: 'Classifieds',
    enabled: classifiedsPaginationEnabled
  }
];

export const useGetPaginationTabItems = (
  activeOrganization: ESnapshotExists<EOrganization> | null,
  user: ESnapshotExists<EUser> | null
): PopoverItemType[] => {
  const noPaginationTabItems = getPaginationTabItemsInner({
    publicNoticesPaginationEnabled: false,
    obituariesPaginationEnabled: false,
    classifiedsPaginationEnabled: false
  });

  const showAllOrgsNotices = useAppSelector(selectShowAllOrgsNotices);
  const availablePublishers = useAppSelector(selectAvailableOrganizations);

  const { value: paginationTabItems } = useAsyncEffect<PopoverItemType[]>({
    fetchData: async () => {
      // Should only show the pagination tab if the current user is a publisher
      if (
        !exists(user) ||
        !exists(activeOrganization) ||
        !isPublisherOrganization(activeOrganization) ||
        !isPublisher(user)
      ) {
        return noPaginationTabItems;
      }

      const publicNoticesPaginationEnabled =
        await getShouldUseAdvancedPagination(
          activeOrganization,
          Product.Notice
        );

      const organizationsToCheck = showAllOrgsNotices
        ? availablePublishers
        : [activeOrganization];

      const organizationModels = organizationsToCheck.map(org =>
        getModelFromSnapshot(OrganizationModel, getFirebaseContext(), org)
      );

      const obituariesPaginationEnabled = organizationModels.some(org =>
        org.hasAdTypeActive(Product.Obituary)
      );
      const classifiedsPaginationEnabled = organizationModels.some(org =>
        org.hasAdTypeActive(Product.Classified)
      );

      // Should only show the pagination tab if the newspaper has customPagination enabled
      // and the current user is a publisher
      return getPaginationTabItemsInner({
        publicNoticesPaginationEnabled,
        obituariesPaginationEnabled,
        classifiedsPaginationEnabled
      });
    },
    dependencies: [activeOrganization?.id, user?.id, showAllOrgsNotices]
  });

  return paginationTabItems || noPaginationTabItems;
};
