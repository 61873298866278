import React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { useAppParams } from 'lib/frontend/utils/router';
import { ERROR_ROUTE } from 'router/routes';

const styles = () =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '80vh'
    }
  });

type ErrorPageProps = {
  classes: Record<string, string>;
};

function ErrorPage({ classes }: ErrorPageProps) {
  const { code } = useAppParams(ERROR_ROUTE);

  if (code === '204') {
    return (
      <div className={classes.root}>
        <div> This page has not yet been implemented!</div>
      </div>
    );
  }
  return <></>;
}

export default withStyles(styles)(ErrorPage);
