import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import { logAndCaptureException } from 'utils';
import { connect } from 'react-redux';
import { UNABLE_TO_FETCH_USER } from 'sagas/AuthSaga';
import { WarningIcon } from 'icons';
import { ColumnService } from 'lib/services/directory';
import { ENV, PROD, DEMO } from '../constants';

type BoundaryProps = {
  error: string;
  children: React.ReactNode;
};

type BoundaryState = {
  hasError: false;
};

const determineColumnService = (path: string) => {
  if (path.includes('obituaries') || path.includes('obituary')) {
    return ColumnService.ORDER_MANAGEMENT;
  }

  if (path.includes('place') && !path.includes('obituaries')) {
    return ColumnService.WEB_PLACEMENT;
  }

  if (path.includes('invoice')) {
    return ColumnService.PAYMENTS;
  }

  return ColumnService.UNKNOWN;
};

class SuspenseErrorBoundary extends React.Component<
  BoundaryProps,
  BoundaryState
> {
  constructor(props: BoundaryProps) {
    super(props);
    this.state = {
      hasError: false
    };
  }

  static getDerivedStateFromError(error: Error) {
    const service = determineColumnService(window.location.pathname || '');
    if (ENV === DEMO || ENV === PROD) {
      logAndCaptureException(service, error, 'Suspense Error Boundary Error', {
        env: ENV
      });
    }

    return {
      hasError: true
    };
  }

  // See EnoticeFirebase.ts for an explanation of why we set this property.
  showLongPollingSelector = () => {
    return [this.props.error, this.state.hasError].some(
      e => e === UNABLE_TO_FETCH_USER
    );
  };

  render() {
    if (this.props.error || this.state.hasError) {
      return (
        <>
          <Grid
            container
            style={{
              width: '100vw',
              height: '100vh',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Grid>
              <Typography variant="h5">
                Whoops! We've encountered an error
              </Typography>
              <Typography>
                Please refresh the page. If that doesn't help, email
                help@column.us and we will assist you!
              </Typography>
              {this.showLongPollingSelector() && (
                <div
                  className="mt-10 flex items-center bg-yellow-200 text-gray-900 text-sm font-normal px-4 py-3"
                  role="alert"
                >
                  <WarningIcon className="h-5 w-5 mr-3" />
                  <p>
                    We detected an issue with your network that may be
                    preventing you from accessing Column. <br /> Click{' '}
                    <a
                      href="#"
                      onClick={e => {
                        e.preventDefault();
                        window.localStorage.setItem(
                          'enableAutoDetectLongPolling',
                          'true'
                        );
                        window.location.reload();
                        return false;
                      }}
                      className="font-semibold underline transition duration-150 ease-in-out"
                    >
                      here
                    </a>{' '}
                    to enable an experimental setting which may fix the issue
                  </p>
                </div>
              )}
            </Grid>
          </Grid>
        </>
      );
    }

    return this.props.children;
  }
}

const mapStateToProps = (state: any) => ({
  error: state.errors.error
});

export default connect(mapStateToProps)(SuspenseErrorBoundary);
