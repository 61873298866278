import { z } from 'zod';
import { FileType } from '../types/mime';

export const ALLOWED_FINALIZED_DISPLAY_TYPES = [
  FileType.JPG,
  FileType.PDF
] as const;

export type AllowedFinalizedDisplayType =
  (typeof ALLOWED_FINALIZED_DISPLAY_TYPES)[number];

export const isAllowedFinalizedDisplayType = (
  fileType: FileType | undefined
): fileType is AllowedFinalizedDisplayType => {
  if (!fileType) {
    return false;
  }
  return ALLOWED_FINALIZED_DISPLAY_TYPES.includes(fileType as any);
};

export const LoginResponseSchema = z.object({
  success: z.boolean(),
  response: z.string(),
  user: z.record(z.string(), z.any()).optional()
});

export type LoginResponse = z.infer<typeof LoginResponseSchema>;

export const LogoutResponseSchema = z.object({
  success: z.boolean(),
  response: z.string()
});

export type LogoutResponse = z.infer<typeof LogoutResponseSchema>;

export const FileSchema = z.object({
  download_url: z.string().url(),
  file: z.string(),
  file_level: z.string(),
  folder: z.string(),
  size: z.string(),
  size_sort: z.number(),
  modified_time: z.string()
});

export type AxFile = z.infer<typeof FileSchema>;

export const AXOrderSchema = z
  .object({
    id: z.number(),
    media_type: z.string(),
    first_rundate: z.string().date(),
    production_status: z.union([
      z.enum([
        'In Progress',
        'Awaiting Materials',
        'Order Materials Submitted',
        'Order Updated',
        'In Production',
        'Awaiting Review',
        'Killed',
        'Delivered',
        'Delivery Processed'
      ]),
      z.string()
    ]),
    files: z.array(FileSchema).or(z.tuple([]))
  })
  .catchall(z.any());
export type AXOrder = z.infer<typeof AXOrderSchema>;
