import { ColumnService } from '../services/directory';
import { AdRate } from '../types';
import { ApplicationFeeSettings } from '../types/organization';
import { InternalServerError } from '../errors/ColumnErrors';
import { getErrorReporter } from '../utils/errors';

// (3.1, .25) => 3.25, (3.1, 0) => 3.1, (3.0, 0.25) => 3.0
export const roundUp = (x: number, r: number | undefined | null) =>
  r ? Math.ceil(x / r) * r : x;

// Based on the following rate sheet: https://michiganpress.org/wp-content/uploads/2021/03/Long-Folio-Chart.pdf
export const getFolios = (words: number) => {
  const folios = Math.ceil(words / 100);
  return folios;
};

export const getColumnInches = (
  actualHeight: number,
  columns: number,
  roundOff: number | null
) => {
  const possiblyRoundedHeight = roundUp(actualHeight, roundOff);
  return possiblyRoundedHeight * columns;
};

export const getColumnCentimeters = (
  height: number,
  columns: number,
  roundOff: number | null
) => {
  return getColumnInches(height, columns, roundOff) * 2.54;
};

/**
 * Safely check if a maybe-number is a number (and not NaN or Infinity).
 */
export const valueIsNumber = (val: any): val is number => {
  return typeof val === 'number' && !Number.isNaN(val) && Number.isFinite(val);
};

export const getOffset = (rate: AdRate) => rate.offset || 0;
export const getOffsetFlatRateInCents = (rate: AdRate) =>
  rate.offsetFlatRateInCents || 0;

export const getLinesPerInch = (rate: AdRate, rateIdentifier: string) => {
  const linesPerInch = rate.images?.linesPerInch;

  if (typeof linesPerInch !== 'number') {
    console.warn(
      `Attempting to price images in ${rateIdentifier} without images.linesPerInch set`
    );

    return 0;
  }

  return linesPerInch;
};

export const calculatePublisherAmountInCents = ({
  applicationFeeInCents,
  subtotalInCents,
  taxInCents
}: {
  applicationFeeInCents: number;
  subtotalInCents: number;
  taxInCents: number;
}): number => {
  // This can occur in the create invoice page when editing the amount of a line item, so don't squawk
  if (subtotalInCents < 0) {
    getErrorReporter().logInfo(
      `Subtotal in cents is negative: ${subtotalInCents}.  Responding with 0 cents in calculatePublisherAmountInCents.`
    );
    return 0;
  }

  const publisherAmountInCents =
    subtotalInCents + taxInCents - applicationFeeInCents;
  if (publisherAmountInCents < 0) {
    getErrorReporter().logAndCaptureCriticalError(
      ColumnService.PAYMENTS,
      new InternalServerError(
        `Publisher amount in cents is negative: ${publisherAmountInCents}`
      ),
      `Publisher amount in cents is negative in calculatePublisherAmountInCents`
    );
    return 0;
  }
  return publisherAmountInCents;
};

export const calculateApplicationFeeInCents = (
  applicationFeeSettings: ApplicationFeeSettings | undefined,
  subtotalInCents: number
): number => {
  if (!applicationFeeSettings) {
    return 0;
  }
  const {
    enabled,
    percentFee: maybePercentFee,
    flatFeeInCents: maybeFlatFeeInCents
  } = applicationFeeSettings;
  const percentFee = maybePercentFee || 0;
  const flatFeeInCents = maybeFlatFeeInCents || 0;
  if (flatFeeInCents % 1 !== 0) {
    getErrorReporter().logAndCaptureError(
      ColumnService.PAYMENTS,
      new InternalServerError(
        `Application fee flat fee in cents is not an integer: ${flatFeeInCents}`
      ),
      `Unexpected decimal in flatFeeInCents application fee property during fee calculation`
    );
    // Proceed with the calculation, but log an error
  }

  if (!enabled) {
    return 0;
  }
  const amountInCents = Math.round(
    subtotalInCents * (percentFee / 100) + flatFeeInCents
  );
  if (amountInCents > subtotalInCents) {
    getErrorReporter().logAndCaptureWarning(
      `Application fee exceeds subtotal: ${amountInCents} > ${subtotalInCents}.  Returning 0 cents.`
    );
    return 0;
  }

  if (amountInCents < 0) {
    const err = new InternalServerError("Application fee can't be negative");
    getErrorReporter().logAndCaptureCriticalError(
      ColumnService.PAYMENTS,
      err,
      `Application fee would be negative: ${amountInCents}  Returning 0. `
    );
    return 0;
  }

  return amountInCents;
};
