import {
  InternalServerError,
  wrapErrorAsColumnError
} from '../errors/ColumnErrors';
import { CollectionKey, SnapshotModel } from '.';
import { ColumnService } from '../services/directory';
import { ResponseOrColumnError, wrapSuccess } from '../types/responses';
import { getErrorReporter } from '../utils/errors';
import { TimestampedModel } from './types';
import { EPartialDocumentData } from '../types';

export async function safeUpdateModel<
  E extends TimestampedModel,
  C extends CollectionKey
>(
  model: SnapshotModel<E, C>,
  updateData: EPartialDocumentData<E>
): Promise<ResponseOrColumnError<void>> {
  try {
    await model.ref.update(updateData);
    return wrapSuccess(undefined);
  } catch (e) {
    getErrorReporter().logAndCaptureError(
      ColumnService.DATABASE,
      e,
      'Failed to update model',
      {
        id: model?.id ?? 'null',
        type: model?.type ?? 'null'
      }
    );
    return wrapErrorAsColumnError(e as Error, InternalServerError);
  }
}
