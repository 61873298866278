/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { EInvoiceRecipientEmail, EInvoiceRecipientMail } from 'lib/types';
import PlacementActions, {
  ConfirmInvoiceRecipientData,
  selectDefaultInvoiceRecipient
} from 'redux/placement';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { getBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { TextField } from 'lib/components/TextField';
import classNames from 'classnames';
import { safeStringify } from 'lib/utils/stringify';
import { getDisplayName } from 'lib/helpers';
import {
  RecipientInfoForm,
  EmailOrMailToggle,
  EmailOrMailForm,
  RecipientToggle
} from './InvoiceRecipientFormComponents';

export const useInvoiceRecipientFormState = () => {
  const dispatch = useAppDispatch();

  const enableNewPlacementFlow = getBooleanFlag(
    LaunchDarklyFlags.ENABLE_NEW_PLACEMENT_FLOW
  );

  const invoiceRecipient = useAppSelector(
    state => state.placement.invoiceRecipient
  );
  const defaultInvoiceRecipient = useAppSelector(selectDefaultInvoiceRecipient);

  const invoiceRecipientEmail =
    invoiceRecipient?.type === 'email' ? invoiceRecipient : undefined;
  const invoiceRecipientMail =
    invoiceRecipient?.type === 'mail' ? invoiceRecipient : undefined;

  const defaultAddress = invoiceRecipientMail?.address;

  const [sendToSomeoneElse, setSendToSomeoneElse] = useState(
    !!invoiceRecipient
  );
  const [sendToEmail, setSendToEmail] = useState(
    !(invoiceRecipient?.type === 'mail')
  );

  const [splitFirstName, splitLastName] = invoiceRecipient?.name
    ? invoiceRecipient.name.split(' ')
    : ['', ''];

  const [firstName, setFirstName] = useState(
    invoiceRecipient?.firstName || splitFirstName || ''
  );

  const [lastName, setLastName] = useState(
    invoiceRecipient?.lastName || splitLastName || ''
  );
  const [billTo, setBillTo] = useState('');
  const [organizationName, setOrganizationName] = useState(
    invoiceRecipient?.organizationName ?? ''
  );

  const [email, setEmail] = useState(
    invoiceRecipientEmail?.email || defaultInvoiceRecipient?.email || ''
  );

  const [addressLine1, setAddressLine1] = useState(
    defaultAddress?.address_line1 ?? ''
  );
  const [addressLine2, setAddressLine2] = useState(
    defaultAddress?.address_line2 ?? ''
  );
  const [city, setCity] = useState(defaultAddress?.address_city ?? '');
  const [state, setState] = useState(
    typeof defaultAddress?.address_state === 'string'
      ? defaultAddress?.address_state
      : ''
  );
  const [zip, setZip] = useState(
    invoiceRecipientMail?.address?.address_zip ?? ''
  );

  useEffect(() => {
    if (!defaultInvoiceRecipient) return;
    setEmail(
      invoiceRecipientEmail?.email || defaultInvoiceRecipient.email || ''
    );

    if (enableNewPlacementFlow) {
      const deprecatedInvoiceRecipientName =
        invoiceRecipient?.organizationName ||
        getDisplayName(invoiceRecipient?.firstName, invoiceRecipient?.lastName);
      setBillTo(
        deprecatedInvoiceRecipientName ||
          invoiceRecipient?.name ||
          defaultInvoiceRecipient.name ||
          ''
      );
    }
    // Don't default fill address information if invoiceRecipient is set to email
    if (!!invoiceRecipient && invoiceRecipient.type === 'email') return;

    // Pre-fill address fields either from the set invoice recipient
    // or from the default
    const entityToPopulateAddress = invoiceRecipientMail?.address.address_line1
      ? invoiceRecipientMail.address
      : defaultInvoiceRecipient.mailingAddress;

    const {
      address_line1,
      address_line2,
      address_city,
      address_state, // This is for some reason saved as Label, not Value
      address_zip
    } = entityToPopulateAddress;

    if (address_line1) {
      setAddressLine1(address_line1);
    }
    if (address_line2) {
      setAddressLine2(address_line2);
    }
    if (address_city) {
      setCity(address_city);
    }
    if (address_state) {
      setState(String(address_state) || '');
    }
    if (address_zip) {
      setZip(address_zip);
    }
  }, [safeStringify(invoiceRecipient), safeStringify(defaultInvoiceRecipient)]);

  const shouldSetInvoiceRecipientOnNotice =
    // Should always be set when sendToSomeoneElse is explicitly checked (old flow only)
    sendToSomeoneElse ||
    // If the email has been updated from the default
    (sendToEmail && defaultInvoiceRecipient?.email !== email) ||
    // If the billing name has been updated from the default
    (sendToEmail && defaultInvoiceRecipient?.name !== billTo) ||
    // Should always be set when sending a paper copy
    !sendToEmail;

  const sendToMailingAddress = !sendToEmail;

  const recipientInfoValid = enableNewPlacementFlow
    ? !!billTo
    : [firstName, lastName].every(e => !!e);

  // TextField has native validation for email/zip
  // but I'm not sure if it's possible to pull that validation into these checks
  const emailRe = /.+?@.+?\..+/;
  const recipientEmailValid = !sendToEmail || (!!email && emailRe.test(email));

  const zipRe = /^[0-9]{5}$/;
  const zipValid = zipRe.test(zip.trim());
  const recipientMailingValid =
    !sendToMailingAddress ||
    ([addressLine1, city, state, zip].every(e => !!e) && zipValid);

  const isStepComplete =
    (!shouldSetInvoiceRecipientOnNotice &&
      /**
       * The old placement flow doesn't always show the recipient info
       * fields - for new placement flow, this field is always shown + required
       * regardless of whether an invoice recipient is actually set
       */
      (enableNewPlacementFlow ? recipientInfoValid : true)) ||
    // All form fields have valid inputs
    (recipientInfoValid && recipientEmailValid && recipientMailingValid);

  const onExit = () => {
    if (!shouldSetInvoiceRecipientOnNotice) {
      const data: ConfirmInvoiceRecipientData = {
        invoiceRecipient: null
      };

      dispatch(PlacementActions.confirmInvoiceRecipient(data));
      return;
    }
    // We are deprecating all fields in favor of `name`
    const basicInfo = enableNewPlacementFlow
      ? { name: billTo }
      : {
          name: organizationName || getDisplayName(firstName, lastName)
        };

    if (sendToEmail) {
      const invoiceRecipient: EInvoiceRecipientEmail = {
        type: 'email',
        email,
        ...basicInfo
      };

      const data: ConfirmInvoiceRecipientData = {
        invoiceRecipient
      };

      dispatch(PlacementActions.confirmInvoiceRecipient(data));
    } else {
      const invoiceRecipient: EInvoiceRecipientMail = {
        type: 'mail',
        address: {
          address_line1: addressLine1,
          address_line2: addressLine2,
          address_city: city,
          address_state: state,
          address_zip: zip
        },
        ...basicInfo
      };

      const data: ConfirmInvoiceRecipientData = {
        invoiceRecipient
      };

      dispatch(PlacementActions.confirmInvoiceRecipient(data));
    }
  };
  return {
    isStepComplete,
    onExit,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    organizationName,
    setOrganizationName,
    email,
    setEmail,
    addressLine1,
    setAddressLine1,
    addressLine2,
    setAddressLine2,
    city,
    setCity,
    state,
    setState,
    zip,
    setZip,
    sendToEmail,
    setSendToEmail,
    sendToSomeoneElse,
    setSendToSomeoneElse,
    zipValid,
    recipientEmailValid,
    billTo,
    setBillTo
  };
};

type InvoiceRecipientFormProps = {
  isPublisher: boolean;
  firstName: string;
  setFirstName: (newValue: string) => void;
  lastName: string;
  setLastName: (newValue: string) => void;
  organizationName: string;
  setOrganizationName: (newValue: string) => void;
  email: string;
  setEmail: (newValue: string) => void;
  addressLine1: string;
  setAddressLine1: (newValue: string) => void;
  addressLine2: string;
  setAddressLine2: (newValue: string) => void;
  city: string;
  setCity: (newValue: string) => void;
  state: string;
  setState: (newValue: string) => void;
  zip: string;
  setZip: (newValue: string) => void;
  sendToEmail: boolean;
  setSendToEmail: (newValue: boolean) => void;
  sendToSomeoneElse: boolean;
  setSendToSomeoneElse: (newValue: boolean) => void;
  billTo: string;
  setBillTo: (newValue: string) => void;
};

export function InvoiceRecipientForm({
  isPublisher,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  organizationName,
  setOrganizationName,
  email,
  setEmail,
  addressLine1,
  setAddressLine1,
  addressLine2,
  setAddressLine2,
  city,
  setCity,
  state,
  setState,
  zip,
  setZip,
  sendToEmail,
  setSendToEmail,
  sendToSomeoneElse,
  setSendToSomeoneElse,
  billTo,
  setBillTo
}: InvoiceRecipientFormProps) {
  const enableNewPlacementFlow = getBooleanFlag(
    LaunchDarklyFlags.ENABLE_NEW_PLACEMENT_FLOW
  );

  // Only advertisers in the old flow should initially see the recipient toggle
  const showRecipientToggle = !enableNewPlacementFlow && !isPublisher;

  // Everyone else should immediately see the email/mail toggle
  const defaultShowEmailOrMailForm = enableNewPlacementFlow || isPublisher;

  return (
    <>
      <div
        className={classNames({
          'bg-gray-100 border rounded-md p-6': enableNewPlacementFlow
        })}
      >
        <form>
          {enableNewPlacementFlow && (
            <>
              <div
                id="invoice-recipient-subheader"
                className="text-sm text-grey-400 font-medium mb-6"
              >
                {isPublisher
                  ? 'Edit the billing details if someone other than the customer should pay for this notice.'
                  : 'Invoices are sent by e-mail to the customer and organization members who have billing notifications enabled. Complete the fields below if you wish to override this and send the invoice and invoice reminders only to a recipient specified below. Invoices are accessible by the customer and organization members in their Column account. You are still responsible for ensuring the invoices gets paid.'}
              </div>
              <div className="space-y-6">
                {true && (
                  <TextField
                    id="bill-to"
                    labelText="Bill to"
                    required
                    onChange={billTo => setBillTo(billTo)}
                    value={billTo}
                  />
                )}
                <div className="mt-3 pb-2 text-sm text-column-gray-500 font-medium">
                  How would you like to send this invoice?
                </div>
              </div>
            </>
          )}
          {showRecipientToggle && (
            <RecipientToggle
              sendToSomeoneElse={sendToSomeoneElse}
              setSendToSomeoneElse={setSendToSomeoneElse}
            />
          )}
          {((showRecipientToggle && sendToSomeoneElse) ||
            defaultShowEmailOrMailForm) && (
            <>
              {/** In the old publisher flow, this field is nested in the email/mail form */}
              {!enableNewPlacementFlow && !isPublisher && (
                <div className="mt-4 font-normal text-sm text-gray-700 uppercase">
                  Recipient Info
                  <RecipientInfoForm
                    firstName={firstName}
                    setFirstName={setFirstName}
                    lastName={lastName}
                    setLastName={setLastName}
                    organizationName={organizationName}
                    setOrganizationName={setOrganizationName}
                  />
                </div>
              )}
              <EmailOrMailToggle
                sendToEmail={sendToEmail}
                setSendToEmail={setSendToEmail}
              />
              <EmailOrMailForm
                email={email}
                setEmail={setEmail}
                sendToEmail={sendToEmail}
                zip={zip}
                setZip={setZip}
                addressLine1={addressLine1}
                setAddressLine1={setAddressLine1}
                addressLine2={addressLine2}
                setAddressLine2={setAddressLine2}
                city={city}
                setCity={setCity}
                state={state}
                setState={setState}
                firstName={firstName}
                setFirstName={setFirstName}
                lastName={lastName}
                setLastName={setLastName}
                organizationName={organizationName}
                setOrganizationName={setOrganizationName}
              />
            </>
          )}
        </form>
      </div>
    </>
  );
}
