import React, { useState } from 'react';
import { OrderModel } from 'lib/model/objects/orderModel';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { useAppSelector } from 'redux/hooks';
import { selectAvailableOrganizations, selectIsPublisher } from 'redux/auth';
import { wrapError, wrapSuccess } from 'lib/types/responses';
import LoadingState from 'components/LoadingState';
import { ColumnButton } from 'lib/components/ColumnButton';
import { capitalize, isError } from 'lodash';
import { OrganizationModel } from 'lib/model/objects/organizationModel';
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/24/solid';
import { cdnIfy } from 'lib/helpers';
import { NewspaperOrderModel } from 'lib/model/objects/newspaperOrderModel';
import { Modal } from 'lib/components/Modal';
import { Product } from 'lib/enums';
import classNames from 'classnames';
import { downloadFileContentsInBrowser } from 'lib/frontend/utils/browser';
import api from 'api';

type PreviewTabProps = {
  order: OrderModel;
};

type PublisherAndProofData = {
  publisher: OrganizationModel;
  pdfStoragePath: string | undefined;
  newspaperOrder: NewspaperOrderModel;
};

export default function PreviewTab({ order }: PreviewTabProps) {
  const availableOrganizations = useAppSelector(selectAvailableOrganizations);
  const [activeProofIndex, setActiveProofIndex] = useState(0);
  const [imageModalURL, setImageModalURL] = useState('');
  const isPublisher = useAppSelector(selectIsPublisher);
  const [downloadingRtf, setDownloadingRtf] = useState(false);

  const { value: ad } = useAsyncEffect({
    fetchData: async () => {
      const [adError, ad] =
        order.modelData.product === Product.Classified
          ? await order.getClassified()
          : await order.getObituary();
      if (adError) {
        return null;
      }
      return ad;
    },
    dependencies: [order?.id]
  });

  const {
    value: publishersAndProofs,
    isLoading,
    error: publishersAndProofsError
  } = useAsyncEffect({
    fetchData: async () => {
      const newspaperOrders = await order.getNewspaperOrders();
      const relevantNewspaperOrders = newspaperOrders.filter(
        newspaperOrder =>
          !isPublisher ||
          availableOrganizations.find(
            organization =>
              organization.id === newspaperOrder.modelData.newspaper.id
          )
      );
      const publishersAndProofs = await Promise.all(
        relevantNewspaperOrders.map(async newspaperOrder => {
          const [publisherError, publisher] =
            await newspaperOrder.getNewspaper();
          if (publisherError) {
            return wrapError(publisherError);
          }

          return wrapSuccess({
            publisher,
            newspaperOrder
          });
        })
      );

      const successfullyLoadedPublishersAndProofs = publishersAndProofs
        .filter(publisherAndProof => !isError(publisherAndProof))
        .map(
          publisherAndProof => publisherAndProof.response
        ) as PublisherAndProofData[];

      setActiveProofIndex(0);
      return successfullyLoadedPublishersAndProofs;
    },
    dependencies: [order?.id]
  });

  if (isLoading) {
    return <LoadingState />;
  }

  if (publishersAndProofsError) {
    return <div className="p-8">Error loading publishers and proofs</div>;
  }

  const activePublisherAndProof = publishersAndProofs?.[activeProofIndex];
  if (!activePublisherAndProof) {
    return <div className="p-8">No publishers and proofs found</div>;
  }

  const { publisher, newspaperOrder } = activePublisherAndProof;
  const { pdfStoragePath } = newspaperOrder.modelData;

  const downloadRTF = async () => {
    setDownloadingRtf(true);
    const { response: rtfBuffer, error: rtfBufferError } = await api.safePost(
      'orders/download-rtf',
      {
        orderId: order.id
      }
    );
    if (rtfBufferError || !rtfBuffer) {
      return;
    }
    downloadFileContentsInBrowser(`${order.id}.rtf`, rtfBuffer, 'text/rtf');
    setDownloadingRtf(false);
  };

  const proofImageURL = pdfStoragePath
    ? cdnIfy(pdfStoragePath, {
        useImgix: true,
        imgixTransformations: { fm: 'png', dpr: '2' }
      })
    : null;

  return (
    <>
      {imageModalURL && (
        <Modal
          id="preview-image-modal"
          title={`${publisher.modelData.name} ${capitalize(
            newspaperOrder.modelData.publishingMedium
          )}`}
          onClose={() => setImageModalURL('')}
        >
          <div className="flex justify-center items-center bg-column-gray-50 p-4">
            <img
              src={imageModalURL}
              alt="Preview"
              className="max-w-full max-h-full"
            />
          </div>
        </Modal>
      )}
      <div className="flex flex-col gap-4 px-8 py-4">
        <div className="flex items-center justify-between">
          <ColumnButton
            onClick={() => {
              setActiveProofIndex(activeProofIndex - 1);
            }}
            disabled={!publishersAndProofs || activeProofIndex === 0}
            buttonText={<ArrowLeftIcon className="w-4 h-4" />}
            type="button"
          />
          <div className="font-medium text-center max-w-64">
            {publisher.modelData.name}{' '}
            {capitalize(newspaperOrder.modelData.publishingMedium)}
          </div>
          <ColumnButton
            onClick={() => {
              setActiveProofIndex(activeProofIndex + 1);
            }}
            buttonText={<ArrowRightIcon className="w-4 h-4" />}
            disabled={
              !publishersAndProofs ||
              activeProofIndex === (publishersAndProofs?.length ?? 0) - 1
            }
            type="button"
          />
        </div>

        {proofImageURL && (
          <div
            className="flex justify-center items-center bg-column-gray-50 p-4"
            onClick={() => setImageModalURL(proofImageURL)}
          >
            <img
              className="w-full max-h-1/4 object-contain cursor-pointer"
              src={proofImageURL}
              alt="Preview"
            />
          </div>
        )}

        <div className="text-sm flex flex-col gap-4">
          <div className="flex flex-col gap-3">
            <div className="flex justify-between">
              <div className="text-column-gray-400">Words</div>
              <div>{newspaperOrder.modelData.displayParams?.words ?? ''}</div>
            </div>
            <div className="flex justify-between">
              <div className="text-column-gray-400">Lines</div>
              <div>{newspaperOrder.modelData.displayParams?.lines ?? ''}</div>
            </div>
          </div>
        </div>
        {pdfStoragePath && (
          <div className="flex justify-between gap-2">
            <ColumnButton
              buttonText="Download PDF"
              onClick={() => {
                window.open(cdnIfy(pdfStoragePath), '_blank');
              }}
              type="button"
              fullWidth
              secondary
            />
            <ColumnButton
              buttonText="Download RTF"
              onClick={downloadRTF}
              type="button"
              loading={downloadingRtf}
              fullWidth
              secondary
            />
          </div>
        )}
        <div
          className={classNames('grid gap-2', {
            'grid-cols-1': ad?.modelData.orderImages?.length === 1,
            'grid-cols-2': ad?.modelData.orderImages?.length !== 1
          })}
        >
          {ad?.modelData.orderImages?.map((orderImage, index) => (
            <ColumnButton
              key={index}
              buttonText={`Download Image ${index + 1}`}
              onClick={() => {
                window.open(orderImage.imageUrl);
              }}
              type="button"
              fullWidth
              secondary
            />
          ))}
        </div>
      </div>
    </>
  );
}
