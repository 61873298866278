import { UploadSettingsFTP } from '../types/integrations';

export const NOTICE_ELEMENT_PREFIX = 'NOTICE';
export const ORDER_ELEMENT_PREFIX = 'ORDER';

export type UploadDestination = {
  /** Human readable name */
  label: string;

  /**
   * A string to identify the paper for which the pagination
   * files will be uploaded. Some papers (e.g. Lee papers)
   * will use both a primary identifier (the site code) and
   * a secondary identifier (the publication code), which will be concatenated
   * with a hyphen: `${primaryIdentifier}-${secondaryIdentifier}`
   */
  primaryIdentifier: string;
  secondaryIdentifier?: string;

  /** Email addresses that receive notice pagination files are awaiting review */
  liaisonContacts: string[];

  /** Email addresses that receive notice pagination files are approved for publication */
  paginationContacts: string[];

  /** FTP settings */
  ftpConfig: UploadSettingsFTP;

  /**
   * @deprecated Grayscaling of ads will now be determined by
   * the `colorOptions` on the newspaper order
   *
   * Whether or not to grayscale pagination files
   * */
  shouldGrayscale: boolean;
};
