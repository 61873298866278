import React from 'react';
import { QuestionInputEvent, TextInputQuestion } from 'lib/types/madlib';
import { TextField } from 'lib/components/TextField';

type TextQuestionInputProps = {
  question: TextInputQuestion;
  value: string | undefined;
  onChange: (e: QuestionInputEvent) => void;
};

export function TextQuestionInput({
  question,
  value,
  onChange
}: TextQuestionInputProps) {
  return (
    <TextField
      id={`text-${question.varName}`}
      labelText={question.prompt}
      value={value}
      onChange={v => onChange({ varName: question.varName, value: v })}
      required={!question.optional}
      placeholder={question.placeholder}
      pattern={question.pattern}
    />
  );
}
