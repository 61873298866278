import {
  isNoticePricingParameters,
  PricingParameters,
  calculateSubtotalFromLineItems,
  isAdditionalFeeLineItem
} from '.';
import { ColumnService } from '../services/directory';
import { LineItemType, Product } from '../enums';
import { MailDelivery } from '../types';
import { LineItem } from '../types/invoices';
import {
  AdRate,
  AdditionalFee,
  ColorFees,
  FlatAdditionalFee,
  JobcaseFee,
  isAffidavitFee,
  isFlatAdditionalFee,
  isPercentAdditionalFee
} from '../types/rates';
import { InternalServerError } from '../errors/ColumnErrors';
import { getErrorReporter } from '../utils/errors';
import { COLUMN_REP_FEE } from '../constants';

/**
 * This function is used for applying the rounding difference in cents to the last non-fee and non-discount line item.
 * If a non-fee or a non-discount line item does not exist, this function will return 0 so we don't lose the rounding difference.
 */
export const getLastNonFeeAndNonDiscountLineItemIndex = (
  lineItems: LineItem[]
) => {
  let lastNonFeeLineItemIndex = 0;
  for (const [i, item] of lineItems.entries()) {
    if (
      !isAdditionalFeeLineItem(item) &&
      item.type !== LineItemType.discount.value
    )
      lastNonFeeLineItemIndex = i;
  }
  return lastNonFeeLineItemIndex;
};

export function getAdditionalFeesFromRate(rate: AdRate): AdditionalFee[] {
  if (rate.additionalFees) {
    const hasOffsetAdditionalFee =
      rate.additionalFees.filter(fee => fee.type === 'flat' && fee.isOffsetFee)
        .length > 0;
    if (rate.offsetFlatRateInCents && hasOffsetAdditionalFee) {
      getErrorReporter().logAndCaptureCriticalError(
        ColumnService.PAYMENTS,
        new InternalServerError(
          'Offset fee and offsetFlatRateInCents cannot be used together!'
        ),
        'Found bad rate in getAdditionalFeesFromRate!  Continuing...',
        {
          rateDescription: rate.description,
          orgId: rate.organization.id
        }
      );
    }
    return rate.additionalFees
      .filter(fee => {
        if (isFlatAdditionalFee(fee)) {
          return !!fee.amount;
        }
        return !!fee.feePercentage;
      })
      .sort((a, b) => {
        // sort flat fees before percent fees so that we can calculate the
        // percent fee line items with the flat fee line items in the subtotal
        const { type: aType } = a;
        const { type: bType } = b;

        if (aType === bType) {
          return 0;
        }

        if (aType === 'flat' && bType === 'percent') {
          return -1;
        }

        return 1;
      });
  }

  return [];
}

const getAmountForAdditionalFeeLineItem = (
  additionalFee: AdditionalFee,
  numberOfRuns: number,
  subtotal: number
) => {
  if (isFlatAdditionalFee(additionalFee)) {
    if (additionalFee.perRun) {
      return additionalFee.amount * numberOfRuns;
    }
    return additionalFee.amount;
  }

  return Math.round((additionalFee.feePercentage / 100) * subtotal);
};

export function getAffidavitAdditionalFeeLineItemsFromRate(
  additionalFees: AdditionalFee[],
  mail: MailDelivery[],
  lastPubDatePlusOneMinute: Date
): LineItem[] {
  if (!mail?.length) return [];

  const totalNumberOfAffidavits = mail.reduce((a, m) => a + (m.copies || 0), 0);

  const affidavitFeeLineItems = additionalFees
    .filter(
      (additionalFee): additionalFee is FlatAdditionalFee =>
        isFlatAdditionalFee(additionalFee) && !!additionalFee.perAffidavitFee
    )
    .map<LineItem>(additionalFee => ({
      date: lastPubDatePlusOneMinute,
      amount: additionalFee.amount * totalNumberOfAffidavits,
      unitPricing: {
        price: additionalFee.amount,
        quantity: totalNumberOfAffidavits
      },
      description: additionalFee.description,
      type: LineItemType.fee.value
    }));

  return affidavitFeeLineItems;
}

export const getColorAdditionalFeeLineItems = (
  colorFees: ColorFees | undefined,
  pricingParameters: PricingParameters,
  lastPubDatePlusOneMinute: Date
): LineItem[] => {
  if (isNoticePricingParameters(pricingParameters)) {
    throw Error('Cannot price order with notice pricing parameters');
  }
  const colorAdditionalFees: LineItem[] = [];
  const usesBorderColor =
    pricingParameters.colorOptions?.borderColor !== 'transparent';

  const usesBackgroundColor =
    pricingParameters.colorOptions?.backgroundColor !== 'transparent';

  if (
    colorFees?.flatFee &&
    !pricingParameters.colorOptions?.isGrayscale &&
    (pricingParameters.orderImages?.length ||
      pricingParameters.usesTextColor ||
      usesBackgroundColor ||
      usesBorderColor)
  ) {
    colorAdditionalFees.push({
      date: lastPubDatePlusOneMinute,
      amount: colorFees.flatFee,
      description: 'Color Printing Fee',
      type: LineItemType.fee.value
    });
  }

  if (colorFees?.textColorFee && pricingParameters.usesTextColor) {
    colorAdditionalFees.push({
      date: lastPubDatePlusOneMinute,
      amount: colorFees.textColorFee,
      description: 'Custom Text Color Fee',
      type: LineItemType.fee.value
    });
  }

  if (colorFees?.borderColorFee && usesBorderColor) {
    colorAdditionalFees.push({
      date: lastPubDatePlusOneMinute,
      amount: colorFees.borderColorFee,
      description: 'Custom Border Color Fee',
      type: LineItemType.fee.value
    });
  }

  if (colorFees?.backgroundColorFee && usesBackgroundColor) {
    colorAdditionalFees.push({
      date: lastPubDatePlusOneMinute,
      amount: colorFees.backgroundColorFee,
      description: 'Custom Background Color Fee',
      type: LineItemType.fee.value
    });
  }
  return colorAdditionalFees;
};

export const getJobcaseAdditionalFeeLineItem = (
  jobcaseFee: JobcaseFee,
  pricingParameters: PricingParameters,
  lastPubDatePlusOneMinute: Date
): LineItem | undefined => {
  if (isNoticePricingParameters(pricingParameters)) {
    throw Error('Cannot price order with notice pricing parameters');
  }
  if (!pricingParameters.jobcaseSettings?.enableJobcase) {
    return;
  }
  return {
    date: lastPubDatePlusOneMinute,
    amount: jobcaseFee.flatFee,
    description: 'Jobcase promotion fee',
    type: LineItemType.fee.value
  };
};

export const getLineItemFromAdditionalFee = (
  additionalFee: AdditionalFee,
  lastPubDatePlusOneMinute: Date,
  numberOfRuns: number,
  preFeeSubtotal: number
): LineItem => ({
  date: lastPubDatePlusOneMinute,
  amount: getAmountForAdditionalFeeLineItem(
    additionalFee,
    numberOfRuns,
    preFeeSubtotal
  ),
  description: additionalFee.description,
  type: LineItemType.fee.value
});

/**
 * This function handles calculating flat fees followed by percentage fees to generate additional fee line items
 */
export const getNonAffidavitAdditionalFeeLineItemsFromAdditionalFees = (
  product: Product,
  unfilteredAdditionalFees: AdditionalFee[],
  lastPubDatePlusOneMinute: Date,
  lineItems: LineItem[],
  pricingParameters: PricingParameters,
  enableFixedPriceAdditionalFees: boolean | undefined
): LineItem[] => {
  const subtotalNoAdditionalFees = calculateSubtotalFromLineItems(lineItems);
  const numberOfRuns = pricingParameters.publicationDates.length || 1;
  const hasFixedPrice =
    'fixedPrice' in pricingParameters && !!pricingParameters.fixedPrice;

  if (hasFixedPrice && !enableFixedPriceAdditionalFees) {
    getErrorReporter().logInfo(
      'Additional fee calculation is disabled for this notice'
    );
    return [];
  }

  const additionalFees = unfilteredAdditionalFees.filter(
    fee => fee.product === product || !fee.product
  );

  let additionalFeeLineItems: LineItem[] = [];
  const flatFees = additionalFees
    // Remove affidavit fees as those line items are generated in another function
    .filter(fee => !isAffidavitFee(fee))
    .filter(isFlatAdditionalFee);

  let percentFees = additionalFees.filter(isPercentAdditionalFee);
  const percentFeesWithoutRepFee = percentFees.filter(
    naf => naf.description !== COLUMN_REP_FEE
  );

  if (percentFees.length !== percentFeesWithoutRepFee.length) {
    getErrorReporter().logInfo(
      'Ignoring Column Rep fee in fee line item calculation'
    );
    percentFees = percentFeesWithoutRepFee;
  }

  const flatFeeLineItems = flatFees.map(fee =>
    getLineItemFromAdditionalFee(
      fee,
      lastPubDatePlusOneMinute,
      numberOfRuns,
      subtotalNoAdditionalFees
    )
  );
  additionalFeeLineItems = additionalFeeLineItems.concat(flatFeeLineItems);

  /**
   * Percent additional fees should be calculated based on the sum of all previous line items
   *
   * If we decide to change this behavior and make it a specific order of percent fees
   * over a non-deterministic calculation, we will need to accomadate the importance of
   * order in the UI
   */
  const subtotalWithAdditionalFees = calculateSubtotalFromLineItems(
    lineItems.concat(additionalFeeLineItems)
  );
  const percentFeeLineItems = percentFees.map(fee =>
    getLineItemFromAdditionalFee(
      fee,
      lastPubDatePlusOneMinute,
      numberOfRuns,
      subtotalWithAdditionalFees
    )
  );
  additionalFeeLineItems = additionalFeeLineItems.concat(percentFeeLineItems);
  return additionalFeeLineItems;
};

export const __private = {
  getAmountForAdditionalFeeLineItem,
  getAdditionalFeesFromRate
};
