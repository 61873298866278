import { RouteObject } from 'react-router-dom';
import RegistrationLayout from 'layouts/registrationLayout';
import RegistrationOccupationSelect from 'routes/register/occupations';
import PostOrgRegistration from 'routes/register/organization/PostOrgRegistration';
import RegisterIndividual from 'routes/register/individual';
import RegisterOrganization from 'routes/register/organization';
import RegisterPublisher from 'routes/register/publisher';
import AddAdvertiserOrganization from 'routes/addAdvertiserOrganization';
import EmailNeedsConfirm from 'routes/register/user/EmailNeedsConfirm';
import {
  MAIN_ROUTES,
  REGISTER_ORGANIZATION_ROUTES,
  REGISTER_ROUTES
} from './routes';
import { RequireAuth } from './redirect';
import { RequireUnregistered } from './redirect/RequireUnregistered';

const blockedRoutes: RouteObject = {
  element: <RequireUnregistered />,
  children: [
    {
      path: REGISTER_ROUTES.CONFIRM,
      element: <EmailNeedsConfirm />
    },
    {
      path: REGISTER_ROUTES.INDIVIDUAL,
      element: <RegisterIndividual />
    },
    {
      path: REGISTER_ROUTES.OCCUPATIONS,
      element: <RegistrationOccupationSelect />
    },
    {
      path: REGISTER_ROUTES.ORGANIZATION,
      element: <RegisterOrganization />
    },
    {
      path: REGISTER_ROUTES.PUBLISHER,
      element: <RegisterPublisher />
    }
  ]
};

export const onboardingRoutes: RouteObject = {
  element: <RequireAuth />,
  children: [
    {
      element: <RegistrationLayout />,
      children: [
        {
          path: MAIN_ROUTES.ADD_ORGANIZATION,
          element: <AddAdvertiserOrganization />
        },
        {
          path: REGISTER_ORGANIZATION_ROUTES.POST_REGISTRATION,
          element: <PostOrgRegistration />
        },
        blockedRoutes
      ]
    }
  ]
};
