import { Product } from 'lib/enums';
import { selectIsPublisher } from 'redux/auth';
import { useAppSelector } from 'redux/hooks';
import MadlibOverride from 'routes/placeScroll/NoticeContentStep/MadlibOverride';
import MadlibOverrideAlert from 'routes/placeScroll/NoticeContentStep/MadlibOverrideAlert';

type MadlibInfoProps = {
  isOverrideActive: boolean;
  product: Product;
  onDisableMadlib: () => void;
};

function MadlibInfo({
  isOverrideActive,
  product,
  onDisableMadlib
}: MadlibInfoProps) {
  const isPublisher = useAppSelector(selectIsPublisher);
  if (isOverrideActive) {
    return <MadlibOverrideAlert product={product} isPublisher={isPublisher} />;
  }

  if (isPublisher) {
    return <MadlibOverride product={product} onClick={onDisableMadlib} />;
  }

  return <></>;
}

export default MadlibInfo;
