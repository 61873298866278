import { useBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { Autocomplete } from 'lib/components/Autocomplete';
import { Product } from 'lib/enums';
import { EOrganization, ERef, ETemplate } from 'lib/types';
import { useState, useContext, useEffect } from 'react';
import { PublisherLocationFilter } from 'routes/placeScroll/ConfirmPublisher/PublisherLocationFilter';
import { getFirebaseContext } from 'utils/firebase';
import { NewspaperOrder, NewspaperOrderStatus } from 'lib/types/newspaperOrder';
import { PublishingMedium } from 'lib/enums/PublishingMedium';
import { PRODUCT_TO_NAME } from 'lib/enums/Product';
import { getModelFromId, getModelFromSnapshot } from 'lib/model';
import { OrganizationModel } from 'lib/model/objects/organizationModel';
import { LayoutModel } from 'lib/model/objects/layoutModel';
import { isPublisherOrder } from 'lib/types/order';
import { Alert } from 'lib/components/Alert';

import { OrderModel } from 'lib/model/objects/orderModel';
import { logAndCaptureException } from 'utils';
import { FilingTypeModel } from 'lib/model/objects/filingTypeModel';
import { ColumnService } from 'lib/services/directory';
import LoadingState from 'components/LoadingState';
import { Ad } from 'lib/types/ad';
import { ColumnSelectOption } from 'lib/components/ColumnSelect';
import { groupBy } from 'lodash';
import { ProductPublishingSettingsService } from 'lib/services/productPublishingSettingsService';
import { asyncFilter, asyncMap, isDefined } from 'lib/helpers';
import { ResponseOrError, wrapError, wrapSuccess } from 'lib/types/responses';
import { NotFoundError } from 'lib/errors/ColumnErrors';
import { safeAsync } from 'lib/safeWrappers';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { PackageService } from 'lib/services/packageService';
import { PackageModel } from 'lib/model/objects/packageModel';
import { DetailedProductPublishingSetting } from 'lib/types/publishingSetting';
import { doFilingTypesHaveConflictingMadlibs } from './validation';
import { NewspapersContext } from '../../contexts/NewspapersContext';
import { NewspaperOrdersFormData } from '../../PlacementFlowStepSelector';
import PublisherCard from './PublisherCard';
import {
  getColorSettingsVisibility,
  getShouldForceGrayscale
} from '../colorHelpers';
import { getDefaultLayout } from '../layoutHelpers';
import { getJobcaseEnablement } from '../jobcaseHelpers';
import { useAdForm } from '../../contexts/AdFormStatusProvider';

const ALL_MEDIUMS = Object.values(PublishingMedium);
const packagesService = new PackageService(getFirebaseContext());

const getValidProductPublishingSettings = async (
  newspaper: ERef<EOrganization>,
  product: Product,
  filingTypeLabel: string,
  isUserPublisher: boolean
): Promise<ResponseOrError<DetailedProductPublishingSetting[]>> => {
  const productPublishingSettingService = new ProductPublishingSettingsService(
    getFirebaseContext()
  );

  const mediums = await asyncFilter(
    ALL_MEDIUMS,
    async (
      medium
    ): Promise<ResponseOrError<DetailedProductPublishingSetting | null>> => {
      const result =
        await productPublishingSettingService.fetchOrCreateDetailedProductPublishingSetting(
          newspaper,
          product,
          medium,
          {
            shouldCreate: false
          }
        );

      // It's fine if the settings aren't there; we only care about other errors.
      if (result.error && !(result.error instanceof NotFoundError)) {
        return result;
      }

      const detailedProductPublishingSetting = result.response;

      const matchingFilingTypes =
        detailedProductPublishingSetting?.filingTypes.filter(
          filingType =>
            filingType.isVisibleToUser(isUserPublisher) &&
            filingType.modelData.label === filingTypeLabel
        );

      if (
        !detailedProductPublishingSetting ||
        !matchingFilingTypes ||
        !matchingFilingTypes.length ||
        detailedProductPublishingSetting.publishingSetting.modelData.deadlines.every(
          deadline => !deadline.publish
        )
      ) {
        return wrapSuccess(null);
      }

      return wrapSuccess({
        ...detailedProductPublishingSetting,
        filingTypes: matchingFilingTypes
      });
    }
  );

  return mediums;
};

async function generateInitialNewspaperOrder({
  newspaper,
  publishingMedium,
  inputData,
  filingType,
  adTemplate,
  packageModel
}: {
  newspaper: OrganizationModel;
  publishingMedium: PublishingMedium;
  inputData: Partial<Ad>;
  filingType?: FilingTypeModel;
  adTemplate?: ERef<ETemplate>;
  packageModel: PackageModel | null;
}): Promise<ResponseOrError<Partial<NewspaperOrder>>> {
  if (!filingType) {
    return wrapError(new Error('Missing filing type'));
  }
  const supportedLayouts = filingType.getSortedSupportedLayouts(inputData);
  const defaultLayout = getDefaultLayout(supportedLayouts);
  const layoutModel = new LayoutModel(defaultLayout);

  const [packageRateError, packageRate] = packageModel
    ? await packageModel.getPackageRate({
        forPublisherOrganization: newspaper,
        forPublishingMedium: publishingMedium
      })
    : wrapSuccess(null);

  if (packageRateError) {
    return wrapError(packageRateError);
  }

  const [filingTypeRateError, filingTypeRate] = await filingType.getRate({
    isDisplayLayout: layoutModel.isDisplayLayout
  });

  if (filingTypeRateError) {
    return wrapError(filingTypeRateError);
  }

  const rate = packageRate || filingTypeRate;

  if (!rate) {
    return wrapError(new Error('Missing rate'));
  }

  const rateData = rate.data();
  /**
   * NOTE: Since we're only setting these properties on the initialization of the newspaper order when selecting a publisher,
   * they won't refresh if the rate changes later due to other parameters (ex. layout change from liner to display).
   * Likely these settings should be derived from the general newspaper order rather than saved once, or these options
   * should not be configured on rates and rather on the filing type, publishing medium, or product settings.
   */
  const shouldForceGrayscale = getShouldForceGrayscale(rateData);
  const colorEnablement = getColorSettingsVisibility(rateData);
  const jobcaseEnablement = getJobcaseEnablement(rateData);

  return wrapSuccess({
    newspaper: newspaper.ref,
    adTemplate: filingType.modelData.template || adTemplate,
    publishingDates: [],
    status: NewspaperOrderStatus.DRAFT,
    publishingMedium,
    package: packageModel?.ref || null,
    jobcaseEnablement,
    colorEnablement,
    colorOptions: {
      isGrayscale: shouldForceGrayscale,
      backgroundColor: 'transparent',
      borderColor: 'transparent'
    },
    filingType: filingType.ref,
    layout: defaultLayout
  });
}

export type SelectPublicationProps = {
  newspaperOrdersFormData: NewspaperOrdersFormData;
  onNewspaperOrdersFormDataChange: React.Dispatch<
    React.SetStateAction<NewspaperOrdersFormData>
  >;
  product: Product;
  inputData: Partial<Ad>;
  orderModel: OrderModel;
  onUpdateAd: (update: Partial<Ad>) => void;
  publishersLoading: boolean;
  autoSelectedPaper: ColumnSelectOption<string> | null;
  showAddPublications: boolean;
  availablePublisherOptions: ColumnSelectOption<string>[];
  stateOptions: number[];
  stateFilter: number | undefined;
  setStateFilter: React.Dispatch<React.SetStateAction<number | undefined>>;
};

function SelectPublication({
  newspaperOrdersFormData,
  onNewspaperOrdersFormDataChange,
  product,
  inputData,
  orderModel,
  publishersLoading,
  autoSelectedPaper,
  showAddPublications,
  availablePublisherOptions,
  stateOptions,
  stateFilter,
  setStateFilter
}: SelectPublicationProps) {
  const enablePackages = useBooleanFlag(
    LaunchDarklyFlags.ENABLE_PACKAGES,
    false
  );
  const productTypeName = PRODUCT_TO_NAME[product].singular.toLowerCase();
  const { clearUserError, setFormStatus } = useAdForm();

  const newspaperOrdersWithMedium = newspaperOrdersFormData.filter(
    (
      no
    ): no is Partial<NewspaperOrder> &
      Pick<NewspaperOrder, 'publishingMedium'> => isDefined(no.publishingMedium)
  );

  const newspaperOrdersByNewspaperId = groupBy(
    newspaperOrdersWithMedium,
    no => no.newspaper?.id
  ) as Record<string, typeof newspaperOrdersWithMedium>;

  const [searchedNewspaperId, setSearchedNewspaperId] = useState<
    string | undefined
  >(undefined);

  const [addNewspaperAlert, setAddNewspaperAlert] = useState('');

  const { publishersAvailableForPlacement } = useContext(NewspapersContext);
  const searchedNewspaperLoaded = publishersAvailableForPlacement.find(
    o => o.id === searchedNewspaperId
  );

  const [
    validProductPublishingSettingsByNewspaperId,
    setProductPublishingSettingsByNewspaperId
  ] = useState<Record<string, DetailedProductPublishingSetting[]>>({});
  const [packagesByNewspaperId, setPackagesByNewspaperId] = useState<
    Record<string, PackageModel[]>
  >({});

  const { isLoading: isLoadingValidSettings } = useAsyncEffect({
    fetchData: async () => {
      const uniquePublishers = newspaperOrdersFormData.filter(
        (o, idx) =>
          newspaperOrdersFormData.findIndex(
            otherOrder => otherOrder.newspaper?.id === o.newspaper?.id
          ) === idx
      );
      const validSettingsForExistingPapersResult = await asyncMap(
        uniquePublishers,
        async o => {
          if (!o.newspaper) {
            return wrapError(Error('Newspaper not found'));
          }

          const [
            getPublishingSettingsError,
            validProductPublishingSettingsForPaper
          ] = await getValidProductPublishingSettings(
            o.newspaper,
            product,
            inputData.filingTypeName || '',
            isPublisherOrder(orderModel.modelData)
          );

          if (getPublishingSettingsError) {
            return wrapError(getPublishingSettingsError);
          }

          return wrapSuccess([
            o.newspaper.id,
            validProductPublishingSettingsForPaper
          ]);
        }
      );

      if (validSettingsForExistingPapersResult.error) {
        return validSettingsForExistingPapersResult;
      }

      const validMediumsForExistingPapers: Record<
        string,
        DetailedProductPublishingSetting[]
      > = Object.fromEntries(validSettingsForExistingPapersResult.response);

      setProductPublishingSettingsByNewspaperId(validMediumsForExistingPapers);

      if (!enablePackages) {
        return;
      }

      const publisherPackagesForExistingPapersResult = await asyncMap(
        uniquePublishers,
        async o => {
          if (!o.newspaper) {
            return wrapError(Error('Newspaper not found'));
          }

          const [loadPackagesError, packages] =
            await packagesService.getPublisherPackagesByFilingTypeLabel({
              publisherOrganization: o.newspaper,
              filingTypeLabel: inputData.filingTypeName || '',
              isUserPublisher: isPublisherOrder(orderModel.modelData)
            });

          if (loadPackagesError) {
            return wrapError(loadPackagesError);
          }

          return wrapSuccess([o.newspaper.id, packages]);
        }
      );

      if (publisherPackagesForExistingPapersResult.error) {
        return publisherPackagesForExistingPapersResult;
      }

      const publisherPackagesForExistingPapers: Record<string, PackageModel[]> =
        Object.fromEntries(publisherPackagesForExistingPapersResult.response);

      setPackagesByNewspaperId(publisherPackagesForExistingPapers);
    },
    dependencies: [],
    errorConfig: {
      service: ColumnService.ORDER_PLACEMENT,
      message: 'Failed to load publishing settings for existing publishers',
      tags: {
        product
      }
    }
  });

  const loading = publishersLoading || isLoadingValidSettings;

  async function generateNewspaperOrdersFromSettings({
    organizationModel,
    newProductPublishingSettingsByNewspaperId
  }: {
    organizationModel: OrganizationModel;
    newProductPublishingSettingsByNewspaperId: Record<
      string,
      DetailedProductPublishingSetting[]
    >;
  }) {
    if (!inputData.filingTypeName) {
      return setAddNewspaperAlert('Please select a category first.');
    }

    const [loadPackagesError, packages] = enablePackages
      ? await packagesService.getPublisherPackagesByFilingTypeLabel({
          publisherOrganization: organizationModel.ref,
          filingTypeLabel: inputData.filingTypeName,
          isUserPublisher: isPublisherOrder(orderModel.modelData)
        })
      : [null, null];

    if (loadPackagesError) {
      logAndCaptureException(
        ColumnService.ORDER_PLACEMENT,
        loadPackagesError,
        'Error getting publisher packages for filing type',
        {
          newspaperId: organizationModel.id,
          filingTypeLabel: inputData.filingTypeName
        }
      );
      return setAddNewspaperAlert(
        'Failed to get publisher packages for filing type.'
      );
    }

    if (packages) {
      setPackagesByNewspaperId({
        ...packagesByNewspaperId,
        [organizationModel.id]: packages
      });
    }

    // Auto selecting the first package
    // TODO: Add ability to set a default package
    const defaultPackage = packages ? packages[0] : null;
    const defaultPackagePublisherSettings = defaultPackage
      ? defaultPackage.modelData.publisherSettings
      : null;

    const packageProductPublisherSettingsIds =
      defaultPackagePublisherSettings?.map(p => p.productPublishingSetting.id);

    // Check if newspaper supports category (aka filing type) selected.
    // TODO: refactor this with obit category select unification. We should move this into a helper to set layout and filing type for a newspaper order.
    // NOTE: I'm not sure what the comment above means.
    const newspaperPublishingSettings =
      newProductPublishingSettingsByNewspaperId[organizationModel.id].filter(
        publishingSetting => {
          const hasSelectedFilingTypeEnabled =
            publishingSetting.filingTypes.some(
              filingType =>
                filingType.modelData.label === inputData.filingTypeName
            );

          const isIncludedinDefaultPackage =
            !defaultPackage ||
            packageProductPublisherSettingsIds?.includes(
              publishingSetting.productPublishingSetting.id
            );

          return hasSelectedFilingTypeEnabled && isIncludedinDefaultPackage;
        }
      );
    /*
     * Set add error alert if filing type is unavailable for selected newspaper.
     */
    if (!newspaperPublishingSettings.length) {
      return setAddNewspaperAlert(
        `${organizationModel.modelData.name} selected does not support ${inputData.filingTypeName}. Please select another publisher.`
      );
    }

    const allFilingTypes = Object.keys(
      newProductPublishingSettingsByNewspaperId
    ).flatMap(publisherId => {
      const filingTypes = newProductPublishingSettingsByNewspaperId[publisherId]
        .map(setting => setting.filingTypes)
        .flat();
      return filingTypes.filter(
        filingType => filingType.modelData.label === inputData.filingTypeName
      );
    });

    const filingTypesHaveConflictingMadlibs =
      doFilingTypesHaveConflictingMadlibs(allFilingTypes);

    if (filingTypesHaveConflictingMadlibs) {
      const alertMessage = `The selected publications have templates for ${inputData.filingTypeName} ads that do not support placing in multiple publications. Please select a different category or publisher.`;
      return setAddNewspaperAlert(alertMessage);
    }

    clearUserError();
    setAddNewspaperAlert('');

    return await asyncMap(newspaperPublishingSettings, publishingSetting => {
      const filingType = publishingSetting.filingTypes.find(
        filingType => filingType.modelData.label === inputData.filingTypeName
      );

      return generateInitialNewspaperOrder({
        newspaper: organizationModel,
        publishingMedium:
          publishingSetting.productPublishingSetting.modelData.publishingMedium,
        packageModel: defaultPackage,
        inputData,
        filingType,
        adTemplate: publishingSetting.publishingSetting.modelData.adTemplate
      });
    });
  }

  async function addNewspaper(newspaperId: string) {
    if (newspaperOrdersByNewspaperId[newspaperId]) {
      return;
    }

    const [getOrganizationError, organizationModel] = await safeAsync(() =>
      getModelFromId(
        OrganizationModel,
        getFirebaseContext(),
        getFirebaseContext().organizationsRef(),
        newspaperId
      )
    )();

    if (getOrganizationError) {
      logAndCaptureException(
        ColumnService.ORDER_PLACEMENT,
        getOrganizationError,
        'Unable to get the organization model',
        { newspaperId }
      );
      return setAddNewspaperAlert(
        'There was an error loading the publisher. Please try again.'
      );
    }

    // Load all publishing settings with all filing types for the product
    const [publishingSettingsError, validPublishingSettings] =
      await getValidProductPublishingSettings(
        organizationModel.ref,
        product,
        inputData.filingTypeName || '',
        isPublisherOrder(orderModel.modelData)
      );

    if (publishingSettingsError) {
      logAndCaptureException(
        ColumnService.ORDER_PLACEMENT,
        publishingSettingsError,
        'Error getting valid product publishing settings for publisher',
        { newspaperId: organizationModel.id }
      );
      return setAddNewspaperAlert(
        'Failed to get valid publishing mediums for newspaper.'
      );
    }

    const newProductPublishingSettingsByNewspaperId = {
      ...validProductPublishingSettingsByNewspaperId,
      [organizationModel.id]: validPublishingSettings
    };

    setProductPublishingSettingsByNewspaperId(
      newProductPublishingSettingsByNewspaperId
    );

    const newNewspaperOrders = await generateNewspaperOrdersFromSettings({
      organizationModel,
      newProductPublishingSettingsByNewspaperId
    });

    if (newNewspaperOrders?.error) {
      logAndCaptureException(
        ColumnService.ORDER_PLACEMENT,
        newNewspaperOrders.error,
        'Error generating newspaper orders from settings',
        { newspaperId: organizationModel.id }
      );
      return setAddNewspaperAlert('Failed to add newspaper');
    }

    if (!newNewspaperOrders) {
      return;
    }

    onNewspaperOrdersFormDataChange([
      ...newspaperOrdersFormData,
      ...newNewspaperOrders.response
    ]);
    setSearchedNewspaperId(newspaperId);
  }

  function removeNewspaper(newspaperId: string) {
    onNewspaperOrdersFormDataChange(
      newspaperOrdersFormData.filter(no => no.newspaper?.id !== newspaperId)
    );
  }

  useEffect(() => {
    if (newspaperOrdersFormData.length || !autoSelectedPaper) {
      return;
    }

    // Auto selected paper is either the publisher's active organization or the paper associated with the custom subdomain
    if (autoSelectedPaper) {
      void addNewspaper(autoSelectedPaper.value);
    }
  }, [autoSelectedPaper?.value]);

  const selectedNewspaperIds = Object.keys(newspaperOrdersByNewspaperId);

  return (
    <>
      {addNewspaperAlert && (
        <Alert
          id="add-newspaper-error"
          onDismiss={() => setAddNewspaperAlert('')}
          title={addNewspaperAlert}
          status="error"
          icon={<ExclamationCircleIcon className="h-5 w-5" />}
        />
      )}

      <div className="grid grid-cols-12 gap-6">
        {showAddPublications && (
          <>
            <div className="col-span-12 md:col-span-6 xl:col-span-8">
              <Autocomplete
                id="selectPublisher"
                labelText="Add publications"
                placeholder={`Choose one or more places to run your ${productTypeName}`}
                value={
                  searchedNewspaperLoaded && !loading ? searchedNewspaperId : ''
                }
                options={availablePublisherOptions}
                onChange={async newspaperId => {
                  setFormStatus('loading');
                  if (
                    newspaperOrdersFormData.find(
                      o => o.newspaper?.id === newspaperId
                    )
                  ) {
                    removeNewspaper(newspaperId);
                  } else if (newspaperId) {
                    await addNewspaper(newspaperId);
                  }
                  setFormStatus('idle');
                }}
                loading={loading}
                required={publishersAvailableForPlacement.length === 0}
                validationMessages={{
                  valueMissing: 'Please select a publisher'
                }}
                selectedOptionsValues={selectedNewspaperIds}
                showCheckBoxForSelectedItems
              />
            </div>
            <div className="col-span-12 md:col-span-6 xl:col-span-4 pt-8">
              <PublisherLocationFilter
                stateOptions={stateOptions}
                onStateChange={state => {
                  setStateFilter(state);
                }}
                activeFilters={{ stateFilter }}
              />
            </div>
          </>
        )}

        <div className="col-span-12 flex flex-col gap-8">
          {loading && (
            <LoadingState
              isFullScreen={false}
              context={{
                service: ColumnService.ORDER_PLACEMENT,
                location: 'Ad placement - Select publication',
                tags: {
                  product,
                  adPlacementFlow: 'true',
                  orderId: orderModel.id
                }
              }}
            />
          )}

          {!loading &&
            Object.entries(newspaperOrdersByNewspaperId).map(
              ([newspaperId, ordersForNewspaper]) => {
                /**
                 * We are traversing newspaperOrders instead of the newspapers context
                 * so the soft deleting of newspaperOrders can be reflected in the UI
                 */
                const newspaper = publishersAvailableForPlacement.find(
                  n => n.id === newspaperId
                );
                if (!newspaper) return null;
                const organizationModel = getModelFromSnapshot(
                  OrganizationModel,
                  getFirebaseContext(),
                  newspaper
                );
                const publishingSettings =
                  validProductPublishingSettingsByNewspaperId[newspaperId];
                const packagesForNewspaper =
                  packagesByNewspaperId[newspaperId] || [];
                const selectedPackageId = ordersForNewspaper[0]?.package?.id;

                return (
                  <div key={newspaper.id} className="col-span-12">
                    <PublisherCard
                      inputData={inputData}
                      onRemovePublisher={() => removeNewspaper(newspaperId)}
                      required={
                        newspaper.data().name === autoSelectedPaper?.label
                      }
                      newspaper={newspaper}
                      validProductPublishingSettings={(
                        validProductPublishingSettingsByNewspaperId[
                          newspaperId
                        ] || []
                      ).map(
                        setting => setting.productPublishingSetting.modelData
                      )}
                      packages={packagesForNewspaper}
                      selectedPackageId={selectedPackageId}
                      publishingMediums={ordersForNewspaper.map(
                        o => o.publishingMedium
                      )}
                      onPackageChange={async packageId => {
                        const selectedPackage = packagesByNewspaperId[
                          newspaperId
                        ].find(p => p.id === packageId);
                        if (!selectedPackage || !inputData.filingTypeName) {
                          return;
                        }
                        const packagePublisherSettings =
                          selectedPackage.modelData.publisherSettings;
                        const packageProductPublisherSettingsIds =
                          packagePublisherSettings.map(
                            p => p.productPublishingSetting.id
                          );
                        const relevantPublishingSettings =
                          publishingSettings.filter(publishingSetting =>
                            packageProductPublisherSettingsIds.includes(
                              publishingSetting.productPublishingSetting.id
                            )
                          );

                        if (relevantPublishingSettings.length === 0) {
                          return;
                        }

                        const newNewspaperOrdersForPublisherResult =
                          await asyncMap(
                            relevantPublishingSettings,
                            publishingSetting => {
                              const filingType =
                                publishingSetting.filingTypes.find(
                                  filingType =>
                                    filingType.modelData.label ===
                                    inputData.filingTypeName
                                );

                              return generateInitialNewspaperOrder({
                                newspaper: organizationModel,
                                publishingMedium:
                                  publishingSetting.productPublishingSetting
                                    .modelData.publishingMedium,
                                packageModel: selectedPackage,
                                inputData,
                                filingType,
                                adTemplate:
                                  publishingSetting.publishingSetting.modelData
                                    .adTemplate
                              });
                            }
                          );

                        if (newNewspaperOrdersForPublisherResult.error) {
                          logAndCaptureException(
                            ColumnService.ORDER_PLACEMENT,
                            newNewspaperOrdersForPublisherResult.error,
                            'Error generating newspaper orders when changing package selection',
                            {
                              newspaperId: organizationModel.id,
                              packageId
                            }
                          );
                          return setAddNewspaperAlert(
                            'There was an error changing the selection. Please try again.'
                          );
                        }

                        onNewspaperOrdersFormDataChange([
                          ...newspaperOrdersFormData.filter(
                            newspaperOrder =>
                              newspaperOrder.newspaper?.id !== newspaperId
                          ),
                          ...newNewspaperOrdersForPublisherResult.response
                        ]);
                      }}
                      onPublishingMediumsChange={async value => {
                        setAddNewspaperAlert('');
                        setFormStatus('loading');

                        const missingMedium = !ordersForNewspaper.some(
                          o => o.publishingMedium === value
                        );

                        if (missingMedium) {
                          const relevantPublishingSetting =
                            publishingSettings.find(
                              setting =>
                                setting.productPublishingSetting.modelData
                                  .publishingMedium === value
                            );
                          const newOrderResult =
                            await generateInitialNewspaperOrder({
                              newspaper: organizationModel,
                              publishingMedium: value,
                              inputData,
                              filingType:
                                relevantPublishingSetting?.filingTypes.find(
                                  filingType =>
                                    filingType.modelData.label ===
                                    inputData.filingTypeName
                                ),
                              adTemplate:
                                relevantPublishingSetting?.publishingSetting
                                  .modelData.adTemplate,
                              packageModel: null
                            });

                          if (newOrderResult.error) {
                            logAndCaptureException(
                              ColumnService.ORDER_PLACEMENT,
                              newOrderResult.error,
                              'Error generating newspaper orders when changing publishing medium selection',
                              {
                                newspaperId: organizationModel.id,
                                medium: value
                              }
                            );
                            return setAddNewspaperAlert(
                              'There was an error changing the selection. Please try again.'
                            );
                          }

                          onNewspaperOrdersFormDataChange([
                            ...newspaperOrdersFormData,
                            newOrderResult.response
                          ]);
                        } else {
                          if (
                            !ordersForNewspaper.some(
                              o => o.publishingMedium !== value
                            )
                          ) {
                            setAddNewspaperAlert(
                              'At least one publishing medium must be selected.'
                            );

                            return;
                          }

                          onNewspaperOrdersFormDataChange(
                            newspaperOrdersFormData.filter(
                              o =>
                                o.newspaper?.id !== newspaperId ||
                                o.publishingMedium !== value
                            )
                          );
                        }

                        setFormStatus('idle');
                      }}
                    />
                  </div>
                );
              }
            )}
        </div>
      </div>
    </>
  );
}

export default SelectPublication;
