import React from 'react';

import { COLUMN_LOGO } from 'lib/constants';
import LinkTo from 'components/LinkTo';
import { MAIN_ROUTES } from 'router/routes';

export default function SignInLayoutHeader() {
  return (
    <header className="h-20 flex items-center px-6 md:px-12 border-b h-20 z-100">
      <div className="w-28 md:w-32 flex-shrink-0">
        <LinkTo href={MAIN_ROUTES.LOGIN}>
          <img className="w-full" src={COLUMN_LOGO} />
        </LinkTo>
      </div>
    </header>
  );
}
