import { z } from 'zod';
import { ETemplateStyles } from '../../types';

export const templateStylesToPixelSizes = (styles: ETemplateStyles) => {
  const { columnCount, columnGutter, pageHeight, pageWidth, pointsPerInch } =
    styles;
  if (
    !columnCount ||
    !columnGutter ||
    !pageHeight ||
    !pageWidth ||
    !pointsPerInch
  ) {
    throw new Error('Missing required template styles');
  }
  const colWidth = (pageWidth - (columnCount - 1) * columnGutter) / columnCount;

  return {
    colWidth,
    gutterWidth: columnGutter,
    height: pageHeight,
    columnCount,
    pointsPerInch
  };
};

const BOUNDING_BLOCK_ID = 'block-boundary';
export const buildColumnLayoutElements = (styles: ETemplateStyles) => {
  const { colWidth, columnCount, gutterWidth, height } =
    templateStylesToPixelSizes(styles);
  const elements: ExcalidrawElement[] = [];
  for (let i = 0; i < columnCount; i++) {
    const x = i * (colWidth + gutterWidth);
    const y = 0;

    const element: ExcalidrawElement = {
      id: `${BOUNDING_BLOCK_ID}-${i}`,
      type: 'rectangle',
      width: colWidth,
      height,
      x,
      y,
      roughness: 0,
      locked: true,
      strokeColor: '#1e1e1e',
      backgroundColor: 'transparent',
      fillStyle: 'hachure',
      strokeWidth: 1,
      strokeStyle: 'solid',
      scale: [1, 1],
      fileId: null,
      angle: 0,
      groupIds: [],
      frameId: null,
      roundness: null,
      isDeleted: false,
      boundElements: null,
      updated: Date.now(),
      link: null
    };
    elements.push(element);
  }
  return elements;
};

const ExcalidrawElementSchema = z.object({
  id: z.string(),
  type: z.string(),
  x: z.number(),
  y: z.number(),
  width: z.number(),
  height: z.number(),
  fileId: z.string().nullable(),
  scale: z.array(z.number()),
  angle: z.number(),
  groupIds: z.array(z.string()),
  frameId: z.string().nullable(),
  roundness: z.number().nullable(),
  isDeleted: z.boolean(),
  boundElements: z.array(z.string()).nullable(),
  roughness: z.number(),
  locked: z.boolean(),
  strokeColor: z.string(),
  backgroundColor: z.string(),
  fillStyle: z.string(),
  strokeWidth: z.number(),
  strokeStyle: z.string(),
  updated: z.number(),
  link: z.string().nullable()
});
export type ExcalidrawElement = z.infer<typeof ExcalidrawElementSchema>;
