import { useBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { Autocomplete } from 'lib/components/Autocomplete';
import { Product } from 'lib/enums';
import { EOrganization, ERef } from 'lib/types';
import { useState, useContext, useEffect } from 'react';
import { PublisherLocationFilter } from 'routes/placeScroll/ConfirmPublisher/PublisherLocationFilter';
import { getFirebaseContext } from 'utils/firebase';
import { NewspaperOrder } from 'lib/types/newspaperOrder';
import { PRODUCT_TO_NAME } from 'lib/enums/Product';
import { getModelFromSnapshot } from 'lib/model';
import { OrganizationModel } from 'lib/model/objects/organizationModel';
import { isPublisherOrder } from 'lib/types/order';
import { Alert } from 'lib/components/Alert';
import { OrderModel } from 'lib/model/objects/orderModel';
import { logAndCaptureException } from 'utils';
import { ColumnService } from 'lib/services/directory';
import LoadingState from 'components/LoadingState';
import { Ad } from 'lib/types/ad';
import { ColumnSelectOption } from 'lib/components/ColumnSelect';
import { groupBy } from 'lodash';
import { asyncMap, isDefined } from 'lib/helpers';
import { ResponseOrError, wrapError, wrapSuccess } from 'lib/types/responses';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { PackageService } from 'lib/services/packageService';
import { PackageModel } from 'lib/model/objects/packageModel';
import { safeGetModelArrayFromRefs } from 'lib/model/getModel';
import { DetailedProductPublishingSetting } from 'lib/types/publishingSetting';
import { doFilingTypesHaveConflictingMadlibs } from './validation';
import { NewspapersContext } from '../../contexts/NewspapersContext';
import { NewspaperOrdersFormData } from '../../PlacementFlowStepSelector';
import PublisherCard from './PublisherCard';
import { useAdForm } from '../../contexts/AdFormStatusProvider';
import {
  generateInitialNewspaperOrder,
  generateNewspaperOrdersFromSettings,
  loadProductPublishingSettings,
  loadPublisher,
  loadPublisherPackages
} from './selectPublicationHelpers';

export type SelectPublicationProps = {
  newspaperOrdersFormData: NewspaperOrdersFormData;
  onNewspaperOrdersFormDataChange: React.Dispatch<
    React.SetStateAction<NewspaperOrdersFormData>
  >;
  product: Product;
  inputData: Partial<Ad>;
  orderModel: OrderModel;
  onUpdateAd: (update: Partial<Ad>) => void;
  publishersLoading: boolean;
  autoSelectedPaper: ColumnSelectOption<string> | null;
  showAddPublications: boolean;
  availablePublisherOptions: ColumnSelectOption<string>[];
  stateOptions: number[];
  stateFilter: number | undefined;
  setStateFilter: React.Dispatch<React.SetStateAction<number | undefined>>;
};

function SelectPublication({
  newspaperOrdersFormData,
  onNewspaperOrdersFormDataChange,
  product,
  inputData,
  orderModel,
  publishersLoading,
  autoSelectedPaper,
  showAddPublications,
  availablePublisherOptions,
  stateOptions,
  stateFilter,
  setStateFilter
}: SelectPublicationProps) {
  const enablePackages = useBooleanFlag(
    LaunchDarklyFlags.ENABLE_PACKAGES,
    false
  );
  const productTypeName = PRODUCT_TO_NAME[product].singular.toLowerCase();
  const { clearUserError, setFormStatus } = useAdForm();

  const newspaperOrdersWithMedium = newspaperOrdersFormData.filter(
    (
      no
    ): no is Partial<NewspaperOrder> &
      Pick<NewspaperOrder, 'publishingMedium'> => isDefined(no.publishingMedium)
  );

  const newspaperOrdersByNewspaperId = groupBy(
    newspaperOrdersWithMedium,
    no => no.newspaper?.id
  ) as Record<string, typeof newspaperOrdersWithMedium>;

  const [searchedNewspaperId, setSearchedNewspaperId] = useState<
    string | undefined
  >(undefined);

  const [addNewspaperAlert, setAddNewspaperAlert] = useState('');

  const { publishersAvailableForPlacement } = useContext(NewspapersContext);
  const searchedNewspaperLoaded = publishersAvailableForPlacement.find(
    o => o.id === searchedNewspaperId
  );

  const [
    validProductPublishingSettingsByNewspaperId,
    setProductPublishingSettingsByNewspaperId
  ] = useState<Record<string, DetailedProductPublishingSetting[]>>({});
  const [packagesByNewspaperId, setPackagesByNewspaperId] = useState<
    Record<string, PackageModel[]>
  >({});

  const { isLoading: isLoadingValidSettings } = useAsyncEffect({
    fetchData: async () => {
      const publisherOrganizations = new Set<ERef<EOrganization>>();
      newspaperOrdersFormData.forEach(newspaperOrder => {
        if (newspaperOrder.newspaper) {
          publisherOrganizations.add(newspaperOrder.newspaper);
        }
      });
      const uniquePublishers = Array.from(publisherOrganizations);
      const validSettingsForExistingPapersResult =
        await loadProductPublishingSettings({
          publisherOrganizations: uniquePublishers,
          product,
          filingTypeName: inputData.filingTypeName || '',
          isPublisher: isPublisherOrder(orderModel.modelData)
        });

      if (validSettingsForExistingPapersResult.error) {
        return validSettingsForExistingPapersResult;
      }

      setProductPublishingSettingsByNewspaperId(
        validSettingsForExistingPapersResult.response
      );

      if (!enablePackages) {
        return;
      }

      const publisherPackagesForExistingPapersResult = await asyncMap(
        uniquePublishers,
        async publisherOrganization => {
          const packagesService = new PackageService(getFirebaseContext());
          const [loadPackagesError, packages] =
            await packagesService.getPublisherPackagesByFilingTypeLabel({
              publisherOrganization,
              filingTypeLabel: inputData.filingTypeName || '',
              isUserPublisher: isPublisherOrder(orderModel.modelData)
            });

          if (loadPackagesError) {
            return wrapError(loadPackagesError);
          }

          return wrapSuccess([publisherOrganization.id, packages]);
        }
      );

      if (publisherPackagesForExistingPapersResult.error) {
        return publisherPackagesForExistingPapersResult;
      }

      const publisherPackagesForExistingPapers: Record<string, PackageModel[]> =
        Object.fromEntries(publisherPackagesForExistingPapersResult.response);

      setPackagesByNewspaperId(publisherPackagesForExistingPapers);
    },
    dependencies: [],
    errorConfig: {
      service: ColumnService.ORDER_PLACEMENT,
      message: 'Failed to load publishing settings for existing publishers',
      tags: {
        product
      }
    }
  });

  const loading = publishersLoading || isLoadingValidSettings;

  async function addNewspaper(newspaperId: string) {
    if (newspaperOrdersByNewspaperId[newspaperId]) {
      return;
    }
    if (!inputData.filingTypeName) {
      return setAddNewspaperAlert('Please select a category first.');
    }

    const [getOrganizationError, organizationModel] = await loadPublisher(
      newspaperId
    );

    if (getOrganizationError) {
      return setAddNewspaperAlert(
        'There was an error loading the publisher. Please try again.'
      );
    }

    const [loadPackagesError, packages] = enablePackages
      ? await loadPublisherPackages({
          organizationModel,
          filingTypeLabel: inputData.filingTypeName,
          isUserPublisher: isPublisherOrder(orderModel.modelData)
        })
      : [null, null];

    if (loadPackagesError) {
      return setAddNewspaperAlert(
        'Failed to get publisher packages for filing type.'
      );
    }

    if (packages) {
      setPackagesByNewspaperId({
        ...packagesByNewspaperId,
        [organizationModel.id]: packages
      });
    }

    // Auto selecting the first package
    // TODO: Add ability to set a default package
    const selectedPackage = packages?.[0] || null;

    const newNewspaperOrdersResult = await handleGenerateNewspaperOrders({
      selectedPackage,
      organizationModel
    });

    if (newNewspaperOrdersResult.error) {
      return;
    }

    onNewspaperOrdersFormDataChange([
      ...newspaperOrdersFormData,
      ...newNewspaperOrdersResult.response
    ]);

    setSearchedNewspaperId(newspaperId);
  }

  async function handleGenerateNewspaperOrders({
    selectedPackage,
    organizationModel
  }: {
    selectedPackage: PackageModel | null;
    organizationModel: OrganizationModel;
  }): Promise<ResponseOrError<Partial<NewspaperOrder>[]>> {
    const organizationsToLoad = selectedPackage
      ? selectedPackage.publisherOrganizationsAssociatedWithPackage
      : [organizationModel.ref];

    const [getPublishingSettingsError, validSettingsForPublishers] =
      await loadProductPublishingSettings({
        publisherOrganizations: organizationsToLoad,
        product,
        filingTypeName: inputData.filingTypeName || '',
        isPublisher: isPublisherOrder(orderModel.modelData)
      });

    if (getPublishingSettingsError) {
      setAddNewspaperAlert(
        'There was an error loading the publisher settings. Please try again.'
      );
      return wrapError(getPublishingSettingsError);
    }

    const newProductPublishingSettingsByNewspaperId = {
      ...validProductPublishingSettingsByNewspaperId,
      ...validSettingsForPublishers
    };

    setProductPublishingSettingsByNewspaperId(
      newProductPublishingSettingsByNewspaperId
    );

    const allFilingTypes = Object.keys(
      newProductPublishingSettingsByNewspaperId
    ).flatMap(publisherId => {
      const filingTypes = newProductPublishingSettingsByNewspaperId[publisherId]
        .map(setting => setting.filingTypes)
        .flat();
      return filingTypes.filter(
        filingType => filingType.modelData.label === inputData.filingTypeName
      );
    });

    const filingTypesHaveConflictingMadlibs =
      doFilingTypesHaveConflictingMadlibs(allFilingTypes);

    if (filingTypesHaveConflictingMadlibs) {
      const alertMessage = `The selected publications have templates for ${inputData.filingTypeName} orders that do not support placing in multiple publications. Please select a different category or publisher.`;
      setAddNewspaperAlert(alertMessage);
      return wrapError(new Error(alertMessage));
    }

    const [organizationModelsError, organizationModels] =
      await safeGetModelArrayFromRefs(
        OrganizationModel,
        getFirebaseContext(),
        organizationsToLoad
      );

    if (organizationModelsError) {
      logAndCaptureException(
        ColumnService.ORDER_PLACEMENT,
        organizationModelsError,
        'Error getting organization models for publisher',
        { newspaperId: organizationModel.id }
      );
      setAddNewspaperAlert(
        'There was an error loading the publisher. Please try again.'
      );
      return wrapError(organizationModelsError);
    }

    const newNewspaperOrdersResult = await generateNewspaperOrdersFromSettings({
      organizationModels,
      productPublishingSettingsByNewspaperId:
        newProductPublishingSettingsByNewspaperId,
      inputData,
      selectedPackage
    });

    if (newNewspaperOrdersResult?.error) {
      logAndCaptureException(
        ColumnService.ORDER_PLACEMENT,
        newNewspaperOrdersResult.error,
        'Error generating newspaper orders from settings',
        { newspaperId: organizationModel.id }
      );
      setAddNewspaperAlert('Failed to add newspaper');
      return newNewspaperOrdersResult;
    }

    if (!newNewspaperOrdersResult) {
      const alertMessage = `${organizationModel.modelData.name} selected does not support ${inputData.filingTypeName}. Please select another publisher.`;
      setAddNewspaperAlert(alertMessage);
      return wrapError(new Error(alertMessage));
    }

    clearUserError();
    setAddNewspaperAlert('');

    return newNewspaperOrdersResult;
  }

  function removeNewspaper({
    newspaperId,
    packageId
  }: {
    newspaperId: string;
    packageId?: string;
  }) {
    if (packageId) {
      return onNewspaperOrdersFormDataChange(
        newspaperOrdersFormData.filter(no => no.package?.id !== packageId)
      );
    }
    onNewspaperOrdersFormDataChange(
      newspaperOrdersFormData.filter(no => no.newspaper?.id !== newspaperId)
    );
  }

  async function onPackageChange({
    organizationModel,
    previousPackageId,
    newPackageId
  }: {
    organizationModel: OrganizationModel;
    previousPackageId?: string;
    newPackageId: string;
  }) {
    removeNewspaper({
      newspaperId: organizationModel.id,
      packageId: previousPackageId
    });
    const selectedPackage = packagesByNewspaperId[organizationModel.id].find(
      p => p.id === newPackageId
    );

    const newNewspaperOrdersResult = await handleGenerateNewspaperOrders({
      selectedPackage: selectedPackage || null,
      organizationModel
    });

    if (newNewspaperOrdersResult.error) {
      return setAddNewspaperAlert(
        'There was an error changing the selection. Please try again.'
      );
    }

    onNewspaperOrdersFormDataChange([
      ...newspaperOrdersFormData.filter(
        newspaperOrder =>
          newspaperOrder.newspaper?.id !== organizationModel.id &&
          (!previousPackageId ||
            newspaperOrder.package?.id !== previousPackageId)
      ),
      ...newNewspaperOrdersResult.response
    ]);
  }

  useEffect(() => {
    if (newspaperOrdersFormData.length || !autoSelectedPaper) {
      return;
    }

    // Auto selected paper is either the publisher's active organization or the paper associated with the custom subdomain
    if (autoSelectedPaper) {
      void addNewspaper(autoSelectedPaper.value);
    }
  }, [autoSelectedPaper?.value]);

  const selectedNewspaperIds = Object.keys(newspaperOrdersByNewspaperId);

  return (
    <>
      {addNewspaperAlert && (
        <Alert
          id="add-newspaper-error"
          onDismiss={() => setAddNewspaperAlert('')}
          title={addNewspaperAlert}
          status="error"
          icon={<ExclamationCircleIcon className="h-5 w-5" />}
        />
      )}

      <div className="grid grid-cols-12 gap-6">
        {showAddPublications && (
          <>
            <div className="col-span-12 md:col-span-6 xl:col-span-8">
              <Autocomplete
                id="selectPublisher"
                labelText="Add publications"
                placeholder={`Choose one or more places to run your ${productTypeName}`}
                value={
                  searchedNewspaperLoaded && !loading ? searchedNewspaperId : ''
                }
                options={availablePublisherOptions}
                onChange={async newspaperId => {
                  setFormStatus('loading');
                  const existingNewspaperOrdersForPublisher =
                    newspaperOrdersFormData.filter(
                      o => o.newspaper?.id === newspaperId
                    );
                  if (existingNewspaperOrdersForPublisher.length) {
                    const packageId = existingNewspaperOrdersForPublisher.find(
                      o => o.package?.id
                    )?.package?.id;
                    removeNewspaper({ newspaperId, packageId });
                  } else if (newspaperId) {
                    await addNewspaper(newspaperId);
                  }
                  setFormStatus('idle');
                }}
                loading={loading}
                required={publishersAvailableForPlacement.length === 0}
                validationMessages={{
                  valueMissing: 'Please select a publisher'
                }}
                selectedOptionsValues={selectedNewspaperIds}
                showCheckBoxForSelectedItems
              />
            </div>
            <div className="col-span-12 md:col-span-6 xl:col-span-4 pt-8">
              <PublisherLocationFilter
                stateOptions={stateOptions}
                onStateChange={state => {
                  setStateFilter(state);
                }}
                activeFilters={{ stateFilter }}
              />
            </div>
          </>
        )}

        <div className="col-span-12 flex flex-col gap-8">
          {loading && (
            <LoadingState
              isFullScreen={false}
              context={{
                service: ColumnService.ORDER_PLACEMENT,
                location: 'Ad placement - Select publication',
                tags: {
                  product,
                  adPlacementFlow: 'true',
                  orderId: orderModel.id
                }
              }}
            />
          )}

          {!loading &&
            Object.entries(newspaperOrdersByNewspaperId).map(
              ([newspaperId, ordersForNewspaper]) => {
                /**
                 * We are traversing newspaperOrders instead of the newspapers context
                 * so the soft deleting of newspaperOrders can be reflected in the UI
                 */
                const newspaper = publishersAvailableForPlacement.find(
                  n => n.id === newspaperId
                );
                if (!newspaper) return null;
                const organizationModel = getModelFromSnapshot(
                  OrganizationModel,
                  getFirebaseContext(),
                  newspaper
                );
                const publishingSettings =
                  validProductPublishingSettingsByNewspaperId[newspaperId];
                const packagesForNewspaper =
                  packagesByNewspaperId[newspaperId] || [];
                const selectedPackageId = ordersForNewspaper[0]?.package?.id;

                return (
                  <div key={newspaper.id} className="col-span-12">
                    <PublisherCard
                      inputData={inputData}
                      onRemovePublisher={() =>
                        removeNewspaper({
                          newspaperId,
                          packageId: selectedPackageId
                        })
                      }
                      required={
                        newspaper.data().name === autoSelectedPaper?.label
                      }
                      newspaper={newspaper}
                      validProductPublishingSettings={(
                        validProductPublishingSettingsByNewspaperId[
                          newspaperId
                        ] || []
                      ).map(
                        setting => setting.productPublishingSetting.modelData
                      )}
                      packages={packagesForNewspaper}
                      selectedPackageId={selectedPackageId}
                      publishingMediums={ordersForNewspaper.map(
                        o => o.publishingMedium
                      )}
                      onPackageChange={async newPackageId => {
                        setFormStatus('loading');
                        await onPackageChange({
                          organizationModel,
                          previousPackageId: selectedPackageId,
                          newPackageId
                        });
                        setFormStatus('idle');
                      }}
                      onPublishingMediumsChange={async value => {
                        setAddNewspaperAlert('');
                        setFormStatus('loading');

                        const missingMedium = !ordersForNewspaper.some(
                          o => o.publishingMedium === value
                        );

                        if (missingMedium) {
                          const relevantPublishingSetting =
                            publishingSettings.find(
                              setting =>
                                setting.productPublishingSetting.modelData
                                  .publishingMedium === value
                            );
                          const newOrderResult =
                            await generateInitialNewspaperOrder({
                              newspaper: organizationModel,
                              publishingMedium: value,
                              inputData,
                              filingType:
                                relevantPublishingSetting?.filingTypes.find(
                                  filingType =>
                                    filingType.modelData.label ===
                                    inputData.filingTypeName
                                ),
                              adTemplate:
                                relevantPublishingSetting?.publishingSetting
                                  .modelData.adTemplate,
                              packageModel: null
                            });

                          if (newOrderResult.error) {
                            logAndCaptureException(
                              ColumnService.ORDER_PLACEMENT,
                              newOrderResult.error,
                              'Error generating newspaper orders when changing publishing medium selection',
                              {
                                newspaperId: organizationModel.id,
                                medium: value
                              }
                            );
                            return setAddNewspaperAlert(
                              'There was an error changing the selection. Please try again.'
                            );
                          }

                          onNewspaperOrdersFormDataChange([
                            ...newspaperOrdersFormData,
                            newOrderResult.response
                          ]);
                        } else {
                          if (
                            !ordersForNewspaper.some(
                              o => o.publishingMedium !== value
                            )
                          ) {
                            setAddNewspaperAlert(
                              'At least one publishing medium must be selected.'
                            );

                            return;
                          }

                          onNewspaperOrdersFormDataChange(
                            newspaperOrdersFormData.filter(
                              o =>
                                o.newspaper?.id !== newspaperId ||
                                o.publishingMedium !== value
                            )
                          );
                        }

                        setFormStatus('idle');
                      }}
                    />
                  </div>
                );
              }
            )}
        </div>
      </div>
    </>
  );
}

export default SelectPublication;
