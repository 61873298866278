import { EOrganization, ESnapshotExists } from '../types';
import { getErrorReporter } from '../utils/errors';

/**
 * Newspapers can share a stripe subscription if either:
 *  1. All newspapers have the same stripe customer id set in their payColumnInvoices field
 *  2. Or there is only a single unique newspaper id in the newspaperOrders
 */
export const newspapersCanShareSubscription = (
  newspapers: ESnapshotExists<EOrganization>[]
): boolean => {
  const uniqueNewspaperIds = [...new Set(newspapers.map(doc => doc.id))];
  const logData = {
    newspapers: newspapers.map(newspaper => newspaper.id).join(', ')
  };
  if (uniqueNewspaperIds.length === 1) {
    getErrorReporter().logInfo(
      'One newspaper order - can share subscription',
      logData
    );
    return true;
  }
  const stripeCustomerIds = newspapers.map(
    newspaper => newspaper.data().payColumnInvoices?.stripeCustomerId
  );
  const uniqueStripeCustomerIds = [...new Set(stripeCustomerIds)];
  if (uniqueStripeCustomerIds.length !== 1 || !uniqueStripeCustomerIds[0]) {
    getErrorReporter().logWarn(
      'Newspapers do not have the same stripe customer id for subscription',
      logData
    );
    return false;
  }

  // We either need no stripe subscription id defined, or exactly one unique subscription id
  const stripeSubscriptionIds = newspapers
    .map(newspaper => newspaper.data().payColumnInvoices?.stripeSubscriptionId)
    .filter(Boolean);
  const uniqueStripeSubscriptionIds = [...new Set(stripeSubscriptionIds)];
  if (uniqueStripeSubscriptionIds.length > 1) {
    getErrorReporter().logWarn(
      'Newspapers have multiple stripe subscription ids',
      logData
    );
    return false;
  }

  getErrorReporter().logInfo('Newspapers can share subscription', logData);
  return true;
};
