import { EOrganization, ERate, ESnapshot, ESnapshotExists } from 'lib/types';
import { CardGridLayout } from 'lib/components/Card/Grid';
import { OrganizationType } from 'lib/enums';
import { FC } from 'react';
import { CustomerObjDataFields } from './CreateOrEditModalDetails';
import {
  BillingTermSettings,
  CustomerAffidavitSettings,
  CustomerDiscountSettings,
  CustomerRateSettings
} from './shared';

type AllowAllOrganizationTypes = { type: 'all' };
type OrganizationTypeWhiteList = {
  type: 'whitelist';
  organizationTypes: number[];
};
type OrganizationTypeBlackList = {
  type: 'blacklist';
  organizationTypes: number[];
};
type OrganizationTypeFilter =
  | AllowAllOrganizationTypes
  | OrganizationTypeWhiteList
  | OrganizationTypeBlackList;

type RenderIfRelevantToCustomerProps<T extends Record<string, unknown>> = {
  organizationTypeFilter: OrganizationTypeFilter;
  organization: EOrganization | null;
  Component: FC<T>;
  props: T;
};

function RenderIfRelevantToCustomer<T extends Record<string, unknown>>({
  organizationTypeFilter,
  organization,
  Component,
  props
}: RenderIfRelevantToCustomerProps<T>) {
  if (organizationTypeFilter.type === 'all') {
    return <Component {...props} />;
  }

  const { organizationTypes } = organizationTypeFilter;
  const { organizationType = 0 } = organization || {};
  const hasRelevantType = organizationTypes.includes(organizationType);

  if (organizationTypeFilter.type === 'whitelist') {
    if (!hasRelevantType) {
      return null;
    }
  }

  if (organizationTypeFilter.type === 'blacklist') {
    if (hasRelevantType) {
      return null;
    }
  }

  return <Component {...props} />;
}

export type EditCustomerOrganizationModalSettingsProps = {
  value: CustomerObjDataFields;
  onChange: (value: CustomerObjDataFields) => unknown;
  activeOrganization: ESnapshot<EOrganization> | null;
  clientOrganization: ESnapshot<EOrganization>;
  rates?: ESnapshotExists<ERate>[];
};

export function EditCustomerOrganizationModalSettings({
  value,
  onChange,
  activeOrganization,
  clientOrganization,
  rates
}: EditCustomerOrganizationModalSettingsProps) {
  const renderForAll: AllowAllOrganizationTypes = { type: 'all' };
  const renderIfNotFuneralHome: OrganizationTypeBlackList = {
    type: 'blacklist',
    organizationTypes: [OrganizationType.funeral_home.value]
  };

  return (
    <CardGridLayout
      header={{
        title: "Set up this organization's preferences.",
        description:
          'Configure how you want this organization to interact with your publication.'
      }}
    >
      <RenderIfRelevantToCustomer
        Component={CustomerRateSettings}
        props={{
          activeOrganization,
          onChange,
          value,
          rates
        }}
        organizationTypeFilter={renderIfNotFuneralHome}
        organization={clientOrganization.data() || null}
      />
      <RenderIfRelevantToCustomer
        Component={BillingTermSettings}
        props={{
          activeOrganization,
          onChange: (billingTerm: number) =>
            onChange({ ...value, billingTerm }),
          value: value.billingTerm
        }}
        organizationTypeFilter={renderForAll}
        organization={clientOrganization.data() || null}
      />
      <RenderIfRelevantToCustomer
        Component={CustomerAffidavitSettings}
        props={{
          activeOrganization,
          onChange,
          value
        }}
        organizationTypeFilter={renderIfNotFuneralHome}
        organization={clientOrganization.data() || null}
      />
      <RenderIfRelevantToCustomer
        Component={CustomerDiscountSettings}
        props={{
          onChange: discountConfig => onChange({ ...value, discountConfig }),
          value: value.discountConfig ?? {}
        }}
        organizationTypeFilter={renderIfNotFuneralHome}
        organization={clientOrganization.data() || null}
      />{' '}
    </CardGridLayout>
  );
}
