import {
  ArrowDownTrayIcon,
  ChevronDownIcon,
  DocumentDuplicateIcon,
  ReceiptPercentIcon,
  CurrencyDollarIcon
} from '@heroicons/react/24/outline';
import { ColumnButton } from 'lib/components/ColumnButton';
import { Popover } from 'lib/components/Popover';
import { ColumnService } from 'lib/services/directory';
import { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import ToastActions from 'redux/toast';
import downloadProof from 'routes/ads/place/helpers/downloadProof';
import { logAndCaptureException } from 'utils';
import { OrderModel } from 'lib/model/objects/orderModel';
import { InvoiceModel } from 'lib/model/objects/invoiceModel';
import { OrderInvoice } from 'lib/types/invoices';
import { getReceiptUrlFromInvoice } from 'lib/utils/receipts';
import { useNavigate } from 'react-router-dom';
import { duplicateOrder } from 'routes/ads/place/helpers/duplicateOrder';
import { selectUser } from 'redux/auth';
import { useSafeLoading } from 'lib/components/hooks/useSafeLoading';
import { logError } from 'utils/logger';
import { PRODUCT_TO_ROUTES } from 'router/routes';
import { getDynamicRoute } from 'lib/frontend/utils/router';
import MoreActionsItem from './MoreActionsItem';

type MoreActionsProps = {
  order: OrderModel;
  invoice: InvoiceModel<OrderInvoice> | undefined;
  invoiceIsLoading: boolean;
};

function MoreActions({ order, invoice, invoiceIsLoading }: MoreActionsProps) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const [downloadIsLoading, setDownloadIsLoading] = useState(false);

  const [orderIsDuplicating, handleDuplicateOrderClick] = useSafeLoading({
    callback: async (order: OrderModel) => {
      const { response: newOrderRef, error: newOrderRefError } =
        await duplicateOrder(order, user);
      if (newOrderRefError) {
        dispatch(
          ToastActions.toastError({
            headerText: 'Error',
            bodyText: 'Error duplicating order. Please try again.'
          })
        );
        logError(`Error duplicating order: ${newOrderRefError}`);
        return;
      }

      navigate(
        getDynamicRoute(PRODUCT_TO_ROUTES[order.modelData.product].PLACE, {
          id: newOrderRef.id
        })
      );
    },
    errorConfig: {
      service: ColumnService.ORDER_MANAGEMENT,
      message: 'Error duplicating order',
      tags: {
        orderId: order.id
      }
    }
  });

  const loading = downloadIsLoading || invoiceIsLoading || orderIsDuplicating;

  const receiptUrl = invoice ? getReceiptUrlFromInvoice(invoice) : undefined;

  const handleDownloadPDF = async () => {
    try {
      setDownloadIsLoading(true);

      await downloadProof(order.id, order.modelData.activeVersion);
    } catch (error) {
      logAndCaptureException(
        ColumnService.ORDER_MANAGEMENT,
        error,
        'Error downloading proofs',
        {
          orderId: order.id
        }
      );

      dispatch(
        ToastActions.toastError({
          headerText: 'Error',
          bodyText: 'Error downloading proofs. Please try again.'
        })
      );
    } finally {
      setDownloadIsLoading(false);
    }
  };

  return (
    <Popover
      id="more-options"
      activator={
        <ColumnButton
          type="button"
          loading={loading}
          buttonText="More actions"
          endIcon={<ChevronDownIcon className="w-4 h-4 text-column-gray-400" />}
        />
      }
      alignment="right"
    >
      <div className="p-2 space-y-2">
        <MoreActionsItem
          icon={<ArrowDownTrayIcon />}
          label="Download proof"
          onClick={handleDownloadPDF}
        />
        {invoice?.isUnpaid() && (
          <MoreActionsItem
            icon={<CurrencyDollarIcon />}
            label="Pay invoice"
            onClick={() => {
              window.open(
                `${window.location.origin}/invoices/${invoice.id}/pay`,
                '_blank'
              );
            }}
          />
        )}
        {receiptUrl && (
          <MoreActionsItem
            icon={<ReceiptPercentIcon />}
            label="View receipt"
            onClick={() => {
              window.open(receiptUrl, '_blank');
            }}
          />
        )}
        <MoreActionsItem
          icon={<DocumentDuplicateIcon />}
          label="Duplicate order"
          onClick={() => handleDuplicateOrderClick(order)}
        />
      </div>
    </Popover>
  );
}

export default MoreActions;
