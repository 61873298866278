import { Product } from 'lib/enums';
import { EOrganization, ERef, ETemplate } from 'lib/types';
import { getFirebaseContext } from 'utils/firebase';
import { NewspaperOrder, NewspaperOrderStatus } from 'lib/types/newspaperOrder';
import { PublishingMedium } from 'lib/enums/PublishingMedium';
import { getModelFromId } from 'lib/model';
import { OrganizationModel } from 'lib/model/objects/organizationModel';
import { LayoutModel } from 'lib/model/objects/layoutModel';

import { logAndCaptureException } from 'utils';
import { FilingTypeModel } from 'lib/model/objects/filingTypeModel';
import { ColumnService } from 'lib/services/directory';
import { Ad } from 'lib/types/ad';
import { ProductPublishingSettingsService } from 'lib/services/productPublishingSettingsService';
import { asyncFilter, asyncMap } from 'lib/helpers';
import { ResponseOrError, wrapError, wrapSuccess } from 'lib/types/responses';
import { NotFoundError } from 'lib/errors/ColumnErrors';
import { safeAsync } from 'lib/safeWrappers';
import { PackageService } from 'lib/services/packageService';
import { PackageModel } from 'lib/model/objects/packageModel';
import { DetailedProductPublishingSetting } from 'lib/types/publishingSetting';
import {
  getColorSettingsVisibility,
  getShouldForceGrayscale
} from '../colorHelpers';
import { getDefaultLayout } from '../layoutHelpers';
import { getJobcaseEnablement } from '../jobcaseHelpers';

const ALL_MEDIUMS = Object.values(PublishingMedium);

export async function loadPublisher(newspaperId: string) {
  const organizationResult = await safeAsync(() => {
    return getModelFromId(
      OrganizationModel,
      getFirebaseContext(),
      getFirebaseContext().organizationsRef(),
      newspaperId
    );
  })();

  if (organizationResult.error) {
    logAndCaptureException(
      ColumnService.ORDER_PLACEMENT,
      organizationResult.error,
      'Unable to get the organization model',
      { newspaperId }
    );
  }

  return organizationResult;
}

export async function loadProductPublishingSettings({
  publisherOrganizations,
  product,
  filingTypeName,
  isPublisher
}: {
  publisherOrganizations: ERef<EOrganization>[];
  product: Product;
  filingTypeName: string;
  isPublisher: boolean;
}) {
  const validSettingsForPublishersResult = await asyncMap(
    publisherOrganizations,
    async publisherOrganization => {
      const [
        getPublishingSettingsError,
        validProductPublishingSettingsForPaper
      ] = await getValidProductPublishingSettings(
        publisherOrganization,
        product,
        filingTypeName,
        isPublisher
      );

      if (getPublishingSettingsError) {
        return wrapError(getPublishingSettingsError);
      }

      return wrapSuccess([
        publisherOrganization.id,
        validProductPublishingSettingsForPaper
      ]);
    }
  );

  if (validSettingsForPublishersResult.error) {
    return validSettingsForPublishersResult;
  }

  const validPublishingSettings: Record<
    string,
    DetailedProductPublishingSetting[]
  > = Object.fromEntries(validSettingsForPublishersResult.response);

  return wrapSuccess(validPublishingSettings);
}

export async function loadPublisherPackages({
  organizationModel,
  filingTypeLabel,
  isUserPublisher
}: {
  organizationModel: OrganizationModel;
  filingTypeLabel: string;
  isUserPublisher: boolean;
}) {
  const packagesService = new PackageService(getFirebaseContext());
  const packageResult =
    await packagesService.getPublisherPackagesByFilingTypeLabel({
      publisherOrganization: organizationModel.ref,
      filingTypeLabel,
      isUserPublisher
    });

  if (packageResult.error) {
    logAndCaptureException(
      ColumnService.ORDER_PLACEMENT,
      packageResult.error,
      'Error getting publisher packages for filing type',
      {
        newspaperId: organizationModel.id,
        filingTypeLabel
      }
    );
  }

  return packageResult;
}

export async function generateNewspaperOrdersFromSettings({
  organizationModels,
  productPublishingSettingsByNewspaperId,
  inputData,
  selectedPackage
}: {
  organizationModels: OrganizationModel[];
  productPublishingSettingsByNewspaperId: Record<
    string,
    DetailedProductPublishingSetting[]
  >;
  inputData: Partial<Ad>;
  selectedPackage: PackageModel | null;
}): Promise<ResponseOrError<Partial<NewspaperOrder>[]>> {
  const defaultPackagePublisherSettings =
    selectedPackage?.modelData.publisherSettings;

  const packageProductPublisherSettingsIds =
    defaultPackagePublisherSettings?.map(p => p.productPublishingSetting.id);

  // Check if newspaper supports category (aka filing type) and package selected.
  // TODO: refactor this with obit category select unification. We should move this into a helper to set layout and filing type for a newspaper order.
  // NOTE: I'm not sure what the comment above means.
  const newspaperPublishingSettings: DetailedProductPublishingSetting[] = [];
  organizationModels.forEach(organizationModel => {
    const productPublishingSettings =
      productPublishingSettingsByNewspaperId[organizationModel.id];
    const filteredProductPublishingSettings = productPublishingSettings.filter(
      publishingSetting => {
        const hasSelectedFilingTypeEnabled = publishingSetting.filingTypes.some(
          filingType => filingType.modelData.label === inputData.filingTypeName
        );
        const isIncludedInSelectedPackage =
          !selectedPackage ||
          packageProductPublisherSettingsIds?.includes(
            publishingSetting.productPublishingSetting.id
          );
        return hasSelectedFilingTypeEnabled && isIncludedInSelectedPackage;
      }
    );
    if (filteredProductPublishingSettings.length) {
      newspaperPublishingSettings.push(...filteredProductPublishingSettings);
    }
  });

  if (!newspaperPublishingSettings.length) {
    return wrapSuccess([]);
  }

  return await asyncMap(newspaperPublishingSettings, publishingSetting => {
    const filingType = publishingSetting.filingTypes.find(
      filingType => filingType.modelData.label === inputData.filingTypeName
    );
    const organizationModel = organizationModels.find(
      organizationModel =>
        organizationModel.id ===
        publishingSetting.productPublishingSetting.getOrganizationRef()?.id
    );

    return generateInitialNewspaperOrder({
      newspaper: organizationModel || null,
      publishingMedium:
        publishingSetting.productPublishingSetting.modelData.publishingMedium,
      packageModel: selectedPackage,
      inputData,
      filingType,
      adTemplate: publishingSetting.publishingSetting.modelData.adTemplate
    });
  });
}

export async function generateInitialNewspaperOrder({
  newspaper,
  publishingMedium,
  inputData,
  filingType,
  adTemplate,
  packageModel
}: {
  newspaper: OrganizationModel | null;
  publishingMedium: PublishingMedium;
  inputData: Partial<Ad>;
  filingType?: FilingTypeModel;
  adTemplate?: ERef<ETemplate>;
  packageModel: PackageModel | null;
}): Promise<ResponseOrError<Partial<NewspaperOrder>>> {
  if (!filingType) {
    return wrapError(new Error('Missing filing type'));
  }
  if (!newspaper) {
    return wrapError(new Error('Missing newspaper'));
  }
  const supportedLayouts = filingType.getSortedSupportedLayouts(inputData);
  const defaultLayout = getDefaultLayout(supportedLayouts);
  const layoutModel = new LayoutModel(defaultLayout);

  const [packageRateError, packageRate] = packageModel
    ? await packageModel.getPackageRate({
        forPublisherOrganization: newspaper,
        forPublishingMedium: publishingMedium
      })
    : wrapSuccess(null);

  if (packageRateError) {
    return wrapError(packageRateError);
  }

  const [filingTypeRateError, filingTypeRate] = await filingType.getRate({
    isDisplayLayout: layoutModel.isDisplayLayout
  });

  if (filingTypeRateError) {
    return wrapError(filingTypeRateError);
  }

  const rate = packageRate || filingTypeRate;

  if (!rate) {
    return wrapError(new Error('Missing rate'));
  }

  const rateData = rate.data();
  /**
   * NOTE: Since we're only setting these properties on the initialization of the newspaper order when selecting a publisher,
   * they won't refresh if the rate changes later due to other parameters (ex. layout change from liner to display).
   * Likely these settings should be derived from the general newspaper order rather than saved once, or these options
   * should not be configured on rates and rather on the filing type, publishing medium, or product settings.
   */
  const shouldForceGrayscale = getShouldForceGrayscale(rateData);
  const colorEnablement = getColorSettingsVisibility(rateData);
  const jobcaseEnablement = getJobcaseEnablement(rateData);

  return wrapSuccess({
    newspaper: newspaper.ref,
    adTemplate: filingType.modelData.template || adTemplate,
    publishingDates: [],
    status: NewspaperOrderStatus.DRAFT,
    publishingMedium,
    package: packageModel?.ref || null,
    jobcaseEnablement,
    colorEnablement,
    colorOptions: {
      isGrayscale: shouldForceGrayscale,
      backgroundColor: 'transparent',
      borderColor: 'transparent'
    },
    filingType: filingType.ref,
    layout: defaultLayout
  });
}

async function getValidProductPublishingSettings(
  newspaper: ERef<EOrganization>,
  product: Product,
  filingTypeLabel: string,
  isUserPublisher: boolean
): Promise<ResponseOrError<DetailedProductPublishingSetting[]>> {
  const productPublishingSettingService = new ProductPublishingSettingsService(
    getFirebaseContext()
  );

  const mediums = await asyncFilter(
    ALL_MEDIUMS,
    async (
      medium
    ): Promise<ResponseOrError<DetailedProductPublishingSetting | null>> => {
      const result =
        await productPublishingSettingService.fetchOrCreateDetailedProductPublishingSetting(
          newspaper,
          product,
          medium,
          {
            shouldCreate: false
          }
        );

      // It's fine if the settings aren't there; we only care about other errors.
      if (result.error && !(result.error instanceof NotFoundError)) {
        return result;
      }

      const detailedProductPublishingSetting = result.response;

      const matchingFilingTypes =
        detailedProductPublishingSetting?.filingTypes.filter(
          filingType =>
            filingType.isVisibleToUser(isUserPublisher) &&
            filingType.modelData.label === filingTypeLabel
        );

      if (
        !detailedProductPublishingSetting ||
        !matchingFilingTypes ||
        !matchingFilingTypes.length ||
        detailedProductPublishingSetting.publishingSetting.modelData.deadlines.every(
          deadline => !deadline.publish
        )
      ) {
        return wrapSuccess(null);
      }

      return wrapSuccess({
        ...detailedProductPublishingSetting,
        filingTypes: matchingFilingTypes
      });
    }
  );

  if (mediums.error) {
    logAndCaptureException(
      ColumnService.ORDER_PLACEMENT,
      mediums.error,
      'Error getting valid product publishing settings for publisher',
      { newspaperId: newspaper.id }
    );
  }

  return mediums;
}
