import React, { useState, useEffect } from 'react';
import { EResponseTypes, ERequestTypes } from 'lib/types';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment-timezone';

import { getSubdomain } from 'utils/urls';
import { getCounties } from 'lib/utils/counties';
import { State } from 'lib/enums';
import {
  ExclamationTriangleIcon,
  InformationCircleIcon,
  TrashIcon,
  XMarkIcon,
  PencilIcon
} from '@heroicons/react/24/outline';
import { ColumnButton } from 'lib/components/ColumnButton';
import api from 'api';
import {
  SearchablePublicNoticeRecord,
  SORT_DESCENDING
} from 'lib/types/searchable';
import { getFirebaseContext } from 'utils/firebase';
import { TextAreaField } from 'lib/components/TextAreaField';
import { stripHtmlTags } from 'lib/helpers';
import { useAppSelector } from 'redux/hooks';
import { selectActiveOrganization } from 'redux/auth';
import SettingsHeader from '../../SettingsHeader';

/**
 * Note: when updating this also update SUBCATEGORY_ID_CACHE
 */
export const DEFAULT_SELECTABLE_TYPES = [
  'Abandoned Property',
  'Administrators Notice',
  'Administrators Sale',
  'Adoption Notices',
  'Adoption',
  'Alcohol Permit Hearing Notices',
  'Amended and Restated Articles',
  'Annual Statement',
  'Application for Water',
  'Application Letters of Administration',
  'Articles of Amendment to Articles of Organization',
  'Articles of Dissolution',
  'Articles of Incorporation',
  'Articles of Organization',
  'Assumed Names',
  'Bid Notices and Requests for Services',
  'Bid Proposals',
  'Bids & Proposals',
  'Bond Issue',
  'Business Name',
  'Change of Name',
  'Child Adoption',
  'Child In Need of Care',
  'Citation by Publication',
  'Claims Heirs Intestate',
  'Claims',
  'Court Filings',
  'Delinquent Taxpayers',
  'Denial of Will',
  'Divorce Petition',
  'Divorce',
  'Election Machine Testing',
  'Election',
  'Elections',
  'Environmental Report',
  'Estate (Probate) Filings',
  'Final Account Supervised',
  'Foreclosure Sale',
  'Foreclosure Sales – Commissioner (Judicial) Sales',
  'Foreclosure Sales – Miscellaneous',
  'Foreclosure Sales – Non-judicial Sales',
  "Foreclosure Sales – US Marshall's Sales",
  'General Election',
  'Government Publications - Election Notices',
  'Government Publications – Notices of Hearings',
  'Government Publications – Notices of Rule Making',
  'Government Publications – Ordinances',
  'Government Publications – Tax Notices',
  'Heirs Only',
  'Invitation to Comment',
  'Legal Notice',
  'Meeting Agenda',
  'Meeting Minutes',
  'Miscellaneous Notices',
  'Miscellaneous',
  'Mortgage Foreclosure',
  'Name Change',
  'Notice of Action',
  'Notice of Application',
  'Notice of Auction',
  'Notice of Availability',
  'Notice of Development',
  'Notice of Election',
  'Notice of Equalization',
  'Notice of Final Settlement',
  'Notice of Gaming Chip Redemption',
  'Notice of Hearing',
  'Notice of Non-Compliance',
  'Notice of Nondiscriminatory Policy',
  'Notice of Nuisance',
  'Notice of Proposed Budget',
  'Notice of Purchase of Property',
  'Notice of Regulatory Hearing',
  'Notice of Sale',
  'Notice of Seizure/Forfeiture',
  'Notice of Suit',
  "Notice of Trustee's Sale",
  'Notice of Vacancy',
  'Notice to Creditors',
  'Notice to Cut Weeds',
  'Official Notices',
  'Omitted Heirs',
  'Ordinance',
  'Parental Action',
  'Personal Property',
  'Personal',
  'Pit Closure',
  'Presumption of Death',
  'Primary Election',
  'Probate Notice',
  'Probate',
  'Proceedings',
  'Public Auction',
  'Public Health',
  'Public Notice',
  'Public Hearing',
  'Public Sales and Auctions, Notices of',
  'Request for Bid',
  'Request for Bids',
  'Request for Candidates',
  'Request for Comments',
  'Request for Proposal',
  'Request for Qualifications',
  'Resolution',
  'Rezoning',
  'Saltwater Injection',
  'School Elections',
  'Sheriff Sale',
  'Special Election',
  'Special Notices',
  'Special Permit for Construction',
  'Special Use Permit',
  'Statement of Non-Discrimination',
  'Summary Administration',
  'Summons',
  'Supplemental Proceedings',
  'Trustee Sale',
  'Trustee Sales',
  'Upload Notice',
  'Vehicle Auctions',
  'Verified Report Independent',
  'Voter Registration',
  'Warnings to Appear – Circuit Court Proceedings',
  'Warnings to Appear – Divorce Proceedings',
  'Warnings to Appear – Foreclosures',
  'Warnings to Appear – Miscellaneous',
  'Warnings to Appear – Municipal Court Proceedings',
  'Warnings to Appear – Quiet Title Actions',
  'Warnings to Appear – US District Court Proceedings',
  'Water Rights',
  'Well Abandonment',
  'Well Extension',
  'Will and Claims',
  'Will Only',
  'Zoning and Planning'
];

const WAPO_SELECTABLE_TYPES = [
  'Bids & Proposals',
  'Legal Notice',
  'Official Notices',
  'Special Notices',
  'Trustee Sale'
];
const SELECTABLE_STATES = ['DC', 'Maryland', 'Pennsylvania', 'Virginia'];
const SELECTABLE_COUNTYS = [
  'Anne Arundel Co.',
  'Calvert Co.',
  'Charles Co.',
  'Culpeper Co.',
  'Fairfax Co.',
  'Fauquier Co.',
  'Frederick Co.',
  'Howard Co.',
  'Loudon Co.',
  'Montgomery Co.',
  'Orange Co.',
  'Philadelphia Co.',
  "Prince George's Co.",
  'Prince William Co.',
  'Spotsylvania Co.',
  'Stafford Co.',
  'Washington'
];

const IS_WAPO = getSubdomain() === 'wapo-admin';

const selectableTypes = IS_WAPO
  ? WAPO_SELECTABLE_TYPES
  : DEFAULT_SELECTABLE_TYPES;

function NoticeListItem({
  filterDeletedNotice,
  notice
}: {
  updateNotices: Function;
  filterDeletedNotice: Function;
  notice: SearchablePublicNoticeRecord;
}) {
  const [showDelete, setShowDelete] = useState(false);
  const [showEdit, setShowEdit] = useState(false);

  const [publishedDate, setPublishedDate] = useState<string | undefined>('');
  const [noticeType, setNoticeType] = useState<string | undefined>('');
  const [county, setCounty] = useState<string | undefined>('');
  const [state, setState] = useState<string | null | undefined>('');
  const [text, setText] = useState<string | undefined>('');
  const [publicationMethod, setPublicationMethod] = useState('');
  const [shouldShowPublicationMethod, setShouldShowPublicationMethod] =
    useState(false);

  const deleteNotice = async () => {
    await getFirebaseContext().publicNoticesRef().doc(notice.id).delete();

    setShowDelete(false);
    filterDeletedNotice(notice);
  };

  const saveNotice = async () => {
    if (!publishedDate) {
      console.error('Unable to save notice due to missing publication date');
      return;
    }

    const updates: any = {
      publishedTimestamp: new Date(publishedDate).getTime(),
      publishedDate,
      noticeType,
      county,
      state,
      text
    };
    if (shouldShowPublicationMethod) {
      updates.publicationMethod = publicationMethod;
    }
    await getFirebaseContext()
      .publicNoticesRef()
      .doc(notice.id)
      .update(updates);
    setShowEdit(false);
  };

  useEffect(() => {
    if (!notice) return;
    const loadNotice = async () => {
      const firestoreNotice = await getFirebaseContext()
        .publicNoticesRef()
        .doc(notice.id)
        .get();

      setPublishedDate(firestoreNotice.data()?.publishedDate);
      setNoticeType(firestoreNotice.data()?.noticeType);
      setCounty(firestoreNotice.data()?.county || '');
      setState(firestoreNotice.data()?.state);
      setText(firestoreNotice.data()?.text);
      setShouldShowPublicationMethod(
        firestoreNotice.data()?.state === 'Florida'
      );
      const publicationMethod = firestoreNotice.data()?.publicationMethod;
      if (publicationMethod) {
        setPublicationMethod(publicationMethod);
      }
    };

    void loadNotice();
  }, [notice?.id]);
  const { newspapername, pdfurl } = notice;

  return (
    <>
      {showEdit && (
        <div className="fixed z-max inset-0 overflow-y-auto">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity">
              <div className="absolute inset-0 bg-column-gray-300 opacity-75"></div>
            </div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
            &#8203;
            <div
              className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
              <div
                className="hidden sm:block absolute top-0 right-0 pt-4 pr-4"
                onClick={() => setShowEdit(false)}
              >
                <button
                  type="button"
                  className="text-column-gray-400 hover:text-column-gray-400 focus:outline-none focus:text-column-gray-400 transition ease-in-out duration-150"
                  aria-label="Close"
                >
                  <XMarkIcon className="h-6 w-6" />
                </button>
              </div>
              <div className="px-4 py-5 bg-white sm:p-6">
                <div>
                  <h3 className="text-lg leading-6 font-medium text-column-gray-900">
                    Edit Public Notice in {newspapername}
                  </h3>
                </div>
                <div className="mt-4">
                  <div className="col-span-3 sm:col-span-2">
                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                    <label className="block text-sm font-medium leading-5 text-column-gray-400">
                      Publication Date
                    </label>
                    <div className="box-border rounded border border-column-gray-100">
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                          label=""
                          value={
                            publishedDate ? new Date(publishedDate) : new Date()
                          }
                          placeholder="MM/DD/YYYY"
                          className={`date-picker-publish`}
                          InputProps={{
                            disableUnderline: true,
                            startAdornment: (
                              <svg
                                width="60"
                                height="38"
                                className="border-r border-column-gray-100 mr-4"
                              >
                                <svg
                                  width="42"
                                  height="38"
                                  viewBox="0 0 42 38"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M0 2C0 0.89543 0.895431 0 2 0H42V38H2C0.89543 38 0 37.1046 0 36V2Z"
                                    fill="#F9FAFB"
                                  />
                                </svg>
                                <svg
                                  x="11.5"
                                  y="11"
                                  width="18"
                                  height="18"
                                  viewBox="0 0 18 18"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M5.66667 4.83333V1.5V4.83333ZM12.3333 4.83333V1.5V4.83333ZM4.83333 8.16667H13.1667H4.83333ZM3.16667 16.5H14.8333C15.2754 16.5 15.6993 16.3244 16.0118 16.0118C16.3244 15.6993 16.5 15.2754 16.5 14.8333V4.83333C16.5 4.39131 16.3244 3.96738 16.0118 3.65482C15.6993 3.34226 15.2754 3.16667 14.8333 3.16667H3.16667C2.72464 3.16667 2.30072 3.34226 1.98816 3.65482C1.67559 3.96738 1.5 4.39131 1.5 4.83333V14.8333C1.5 15.2754 1.67559 15.6993 1.98816 16.0118C2.30072 16.3244 2.72464 16.5 3.16667 16.5Z"
                                    stroke="#4A5568"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </svg>
                            )
                          }}
                          autoOk
                          onChange={newDate => {
                            if (newDate) {
                              setPublishedDate(
                                moment(newDate).format('MM/DD/YYYY')
                              );
                            }
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                  </div>
                </div>
                <div className="mt-4">
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label className="block text-sm leading-5 font-medium text-column-gray-400">
                    Notice Type
                  </label>
                  <select
                    className="mt-1 block form-select w-full py-2 px-3 border border-column-gray-100 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-column-primary-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    value={noticeType}
                    onChange={e => setNoticeType(e.target.value)}
                  >
                    {selectableTypes.sort().map((label, i) => (
                      <option key={i} value={label}>
                        {label}
                      </option>
                    ))}
                  </select>
                </div>
                {IS_WAPO && (
                  <div className="mt-4">
                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                    <label className="block text-sm leading-5 font-medium text-column-gray-400">
                      State
                    </label>
                    <select
                      className="mt-1 block form-select w-full py-2 px-3 border border-column-gray-100 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-column-primary-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      value={state!}
                      onChange={e => setState(e.target.value)}
                    >
                      {SELECTABLE_STATES.map((label, i) => (
                        <option key={i} value={label}>
                          {label}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
                {!IS_WAPO && (
                  <div className="mt-4">
                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                    <label className="block text-sm leading-5 font-medium text-column-gray-400">
                      County
                    </label>
                    <select
                      className="mt-1 block form-select w-full py-2 px-3 border border-column-gray-100 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-column-primary-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      value={county}
                      onChange={e => setCounty(e.target.value)}
                    >
                      {getCounties(state!).map((label, i) => (
                        <option key={i} value={label}>
                          {label}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
                {IS_WAPO && (
                  <div className="mt-4">
                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                    <label className="block text-sm leading-5 font-medium text-column-gray-400">
                      County
                    </label>
                    <select
                      className="mt-1 block form-select w-full py-2 px-3 border border-column-gray-100 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-column-primary-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      value={county}
                      onChange={e => setCounty(e.target.value)}
                    >
                      {SELECTABLE_COUNTYS.map((label, i) => (
                        <option key={i} value={label}>
                          {label}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
                {shouldShowPublicationMethod && (
                  <div className="mt-4">
                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                    <label className="block text-sm leading-5 font-medium text-column-gray-400">
                      Publication Method
                    </label>
                    <select
                      className="mt-1 block form-select w-full py-2 px-3 border border-column-gray-100 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-column-primary-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      value={publicationMethod}
                      onChange={e => setPublicationMethod(e.target.value)}
                    >
                      <option key="print-and-website" value="print and website">
                        Print and website
                      </option>
                      <option key="website-only" value="website only">
                        Website only
                      </option>
                    </select>
                  </div>
                )}
                <div className="mt-4">
                  <label
                    htmlFor="about"
                    className="block text-sm leading-5 font-medium text-column-gray-400"
                  >
                    {pdfurl ? 'PDF' : 'Text'}
                  </label>
                  {pdfurl && (
                    <div className="flex">
                      <div className="w-0 flex-1 flex items-center">
                        <svg
                          className="flex-shrink-0 h-5 w-5 text-column-gray-400"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z"
                            clipRule="evenodd"
                          />
                        </svg>
                        <span className="ml-2 flex-1 w-0 truncate">
                          Uploaded PDF
                        </span>
                      </div>
                      <div className="ml-4 flex-shrink-0">
                        <div
                          className="cursor-pointer font-medium text-column-primary-600 hover:text-column-primary-500 transition duration-150 ease-in-out"
                          onClick={() => window.open(pdfurl)}
                        >
                          Download
                        </div>
                      </div>
                    </div>
                  )}
                  {!pdfurl && (
                    <div className="rounded-md shadow-sm">
                      <TextAreaField
                        id="about"
                        rows={10}
                        labelText=""
                        placeholder="you@example.com"
                        value={text}
                        onChange={value => setText(value)}
                      />
                    </div>
                  )}
                </div>
                <div className="mt-4">
                  <div className="flex justify-end">
                    <span className="inline-flex rounded-md shadow-sm">
                      <button
                        type="button"
                        className="py-2 px-4 border border-column-gray-25 rounded-md text-sm leading-5 font-medium text-column-gray-400 hover:text-column-gray-400 focus:outline-none focus:border-column-primary-300 focus:shadow-outline-blue active:bg-column-gray-25 active:text-column-gray-500 transition duration-150 ease-in-out"
                        onClick={() => setShowEdit(false)}
                      >
                        Cancel
                      </button>
                    </span>
                    <span className="ml-3 inline-flex rounded-md shadow-sm">
                      <button
                        type="submit"
                        className="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-column-primary-600 hover:bg-column-primary-500 focus:outline-none focus:border-column-primary-700 focus:shadow-outline-blue active:bg-column-primary-700 transition duration-150 ease-in-out"
                        onClick={saveNotice}
                      >
                        Save
                      </button>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {showDelete && (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity">
              <div className="absolute inset-0 bg-column-gray-300 opacity-75"></div>
            </div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
            &#8203;
            <div
              className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
              <div
                className="hidden sm:block absolute top-0 right-0 pt-4 pr-4"
                onClick={() => setShowDelete(false)}
              >
                <button
                  type="button"
                  className="text-column-gray-400 hover:text-column-gray-400 focus:outline-none focus:text-column-gray-400 transition ease-in-out duration-150"
                  aria-label="Close"
                >
                  <XMarkIcon className="h-6 w-6" />
                </button>
              </div>
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                  <ExclamationTriangleIcon className="h-6 w-6 text-red-600" />
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <h3
                    className="text-lg leading-6 font-medium text-column-gray-900"
                    id="modal-headline"
                  >
                    Delete Public Notice
                  </h3>
                  <div className="mt-2">
                    <p className="text-sm leading-5 text-column-gray-400">
                      Are you sure you want to delete this notice? It will no
                      longer appear on the public notice website for your state.
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <span
                  className="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto"
                  onClick={deleteNotice}
                >
                  <button
                    type="button"
                    className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-red-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                  >
                    Delete
                  </button>
                </span>
                <span
                  className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto"
                  onClick={() => setShowDelete(false)}
                >
                  <button
                    type="button"
                    className="inline-flex justify-center w-full rounded-md border border-column-gray-100 px-4 py-2 bg-white text-base leading-6 font-medium text-column-gray-400 shadow-sm hover:text-column-gray-400 focus:outline-none focus:border-column-primary-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                  >
                    Cancel
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
      <li className="col-span-1 bg-white rounded-lg shadow">
        <div className="w-full flex items-center justify-between p-6 space-x-6">
          <div className="flex-1 truncate">
            <div className="flex items-center space-x-3">
              <h3 className="text-column-gray-900 text-sm leading-5 font-medium truncate flex-1">
                {newspapername} - {publishedDate}
              </h3>
              <span className="flex-shrink-0 inline-block px-2 py-0.5 text-teal-800 text-xs leading-4 font-medium bg-teal-100 rounded-full">
                {noticeType}
              </span>
            </div>
            <p className="mt-1 text-column-gray-400 text-sm leading-5 h-20 whitespace-pre-line">
              {pdfurl && 'PDF Upload:\n\n'}
              {stripHtmlTags(text!)}
            </p>
          </div>
        </div>
        <div className="border-t border-column-gray-50">
          <div className="-mt-px flex">
            <div className="w-0 flex-1 flex border-r border-column-gray-50">
              <div
                className="cursor-pointer hover:bg-column-gray-25 relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm leading-5 text-column-gray-400 font-medium border border-transparent rounded-bl-lg hover:text-column-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-column-primary-300 focus:z-10 transition ease-in-out duration-150"
                onClick={() => setShowDelete(true)}
              >
                <TrashIcon className="w-5 h-5 text-column-gray-400" />
                <span className="ml-3">Delete</span>
              </div>
            </div>
            <div className="-ml-px w-0 flex-1 flex">
              <div
                className="cursor-pointer hover:bg-column-gray-25 relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm leading-5 text-column-gray-400 font-medium border border-transparent rounded-br-lg hover:text-column-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-column-primary-300 focus:z-10 transition ease-in-out duration-150"
                onClick={() => setShowEdit(true)}
              >
                <PencilIcon className="w-5 h-5 text-column-gray-400" />
                <span className="ml-3">Edit</span>
              </div>
            </div>
          </div>
        </div>
      </li>
    </>
  );
}

const PAGE_SIZE = 10;

function FTPSettings() {
  const activeOrganization = useAppSelector(selectActiveOrganization);
  const [notices, setNotices] = useState<SearchablePublicNoticeRecord[]>([]);
  const [hasMore, setHasMore] = useState(false);
  const [page, setPage] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [totalHits, setTotalHits] = useState(0);

  const updateNotices = async () => {
    const res: EResponseTypes['search/public-notices'] = await api.post(
      'search/public-notices',
      {
        search: '',
        allFilters: [
          { newspapername: [activeOrganization?.data()?.name] },
          { state: [State.by_value(activeOrganization?.data()?.state)?.label] }
        ],
        sort: [{ publishedtimestamp: SORT_DESCENDING }],
        pageSize: PAGE_SIZE,
        current: page + 1
      } as ERequestTypes['search/public-notices']
    );
    if (res.success) {
      setNotices(res.results);
      setHasMore((page + 1) * PAGE_SIZE < res.page.total_results);
      setTotalHits(res.page.total_results);
    }
  };

  useEffect(() => {
    void updateNotices();
  }, [page]);

  return (
    <>
      <div>
        <SettingsHeader
          header="Archive Settings"
          description="View, edit and delete previously uploaded notices."
        >
          <ColumnButton
            tertiary
            id="archive-settings"
            size="lg"
            buttonText={'Help'}
            onClick={() => window.open('https://help.column.us/')}
            startIcon={
              <InformationCircleIcon className="w-5 h-5 text-column-gray-500" />
            }
            type="button"
          />
        </SettingsHeader>
      </div>
      <div className="p-3 bg-column-gray-25" style={{ minHeight: '60vh' }}>
        <div className="my-5 max-w-sm ml-auto">
          <div>
            <div className="mt-1 relative rounded-md shadow-sm flex">
              <input
                className="form-input block w-full sm:text-sm sm:leading-5"
                placeholder="My keywords..."
                value={searchValue}
                onChange={e => setSearchValue(e.target.value)}
                maxLength={500}
              />
              <button
                type="button"
                className="ml-5 inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-column-primary-600 hover:bg-column-primary-500 focus:outline-none focus:border-column-primary-700 focus:shadow-outline-blue active:bg-column-primary-700 transition ease-in-out duration-150"
                onClick={() => {
                  setPage(0);
                  void updateNotices();
                }}
              >
                Search
              </button>
            </div>
          </div>
        </div>
        <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2">
          {notices.map((notice, i) => (
            <NoticeListItem
              key={i}
              updateNotices={updateNotices}
              notice={notice}
              filterDeletedNotice={async (
                deletedNotice: SearchablePublicNoticeRecord
              ) => {
                const filtered = notices.filter(n => n.id !== deletedNotice.id);
                setNotices(filtered);
              }}
            />
          ))}
        </ul>
      </div>
      <nav className="px-4 py-3 sm:rounded-lg flex items-center justify-between border-t border-column-gray-50 sm:px-6">
        <div className="hidden sm:block">
          <p className="text-sm leading-5 text-column-gray-400">
            Showing results
            <span className="ml-1 mr-1 font-medium">
              {page * PAGE_SIZE + 1}
            </span>
            to
            <span className="ml-1 font-medium">
              {page * PAGE_SIZE + notices.length}
            </span>{' '}
            of
            <span className="ml-1 font-medium">{totalHits}</span>
          </p>
        </div>
        <div className="flex-1 flex justify-between sm:justify-end">
          <div
            className={`${
              page > 0 ? 'cursor-pointer' : 'cursor-not-allowed'
            } relative inline-flex items-center px-4 py-2 border border-column-gray-25 text-sm leading-5 font-medium rounded-md text-column-gray-400 bg-white hover:text-column-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-column-primary-300 active:bg-column-gray-25 active:text-column-gray-400 transition ease-in-out duration-150`}
            onClick={() => {
              if (page < 1) return;
              setPage(page - 1);
              void updateNotices();
            }}
          >
            Previous
          </div>
          <div
            className={`${
              hasMore ? 'cursor-pointer' : 'cursor-not-allowed'
            } ml-3 relative inline-flex items-center px-4 py-2 border border-column-gray-25 text-sm leading-5 font-medium rounded-md text-column-gray-400 bg-white hover:text-column-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-column-primary-300 active:bg-column-gray-25 active:text-column-gray-400 transition ease-in-out duration-150`}
            onClick={() => {
              if (!hasMore) return;
              setPage(page + 1);
              void updateNotices();
            }}
          >
            Next
          </div>
        </div>
      </nav>
    </>
  );
}

export default FTPSettings;
