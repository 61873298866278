import LoadingState from 'components/LoadingState';
import { Alert } from 'lib/components/Alert';
import { Product } from 'lib/enums';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { ColumnService } from 'lib/services/directory';
import { Order, isAdvertiserWithOrganizationOrder } from 'lib/types/order';
import { useState } from 'react';
import AuthActions, {
  selectAuthLoading,
  selectIsPublisher,
  selectUser
} from 'redux/auth';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { OrderModel } from 'lib/model/objects/orderModel';
import { getModelFromId } from 'lib/model';
import { getFirebaseContext } from 'utils/firebase';
import { CustomerTypeString } from '../filters/helpers';
import FormWrapper from './FormWrapper';
import PlacementFlowStepSelector from './PlacementFlowStepSelector';
import Placemat from './Placemat';
import ResumeOrder from './ResumeOrder';
import { useAdForm } from './contexts/AdFormStatusProvider';
import {
  createOrder,
  getLoadOrderEmail,
  getOrderToken,
  SELECT_CATEGORY_STEP_NUMBER
} from './helpers/orderOperations';
import ClassifiedPlacementFlowHelper from './helpers/placementFlowHelper/classifiedPlacementFlowHelper';
import ObituaryPlacementFlowHelper from './helpers/placementFlowHelper/obituaryPlacementFlowHelper';
import { PlacementFlowStep } from './placementFlowStep';
import PersonalDetail, { MINIMUM_ORDER } from './steps/PersonalDetail';

const stepsByFlow: Record<
  CustomerTypeString | Product.Classified,
  PlacementFlowStep[]
> = {
  [CustomerTypeString.FUNERAL_HOME]: [
    PlacementFlowStep.Verification,
    PlacementFlowStep.Category,
    PlacementFlowStep.Publication,
    PlacementFlowStep.Schedule,
    PlacementFlowStep.Content,
    PlacementFlowStep.Summary
  ],
  [CustomerTypeString.INDIVIDUAL]: [
    PlacementFlowStep.Details,
    PlacementFlowStep.Category,
    PlacementFlowStep.Publication,
    PlacementFlowStep.Schedule,
    PlacementFlowStep.Verification,
    PlacementFlowStep.Content,
    PlacementFlowStep.Summary
  ],
  [Product.Classified]: [
    PlacementFlowStep.Details,
    PlacementFlowStep.Category,
    PlacementFlowStep.Publication,
    PlacementFlowStep.Schedule,
    PlacementFlowStep.Content,
    PlacementFlowStep.Summary
  ]
};

type AdPlacementFlowProps = {
  claim: string;
  orderDraftId?: string;
  product: Product;
};

export default function AdPlacementFlow({
  claim,
  orderDraftId,
  product
}: AdPlacementFlowProps) {
  const context = getFirebaseContext();
  const dispatch = useAppDispatch();
  const { updateCurrentStep } = useAdForm();
  const user = useAppSelector(selectUser);
  const isPublisher = useAppSelector(selectIsPublisher);
  const isAuthLoading = useAppSelector(selectAuthLoading);

  const [orderFormData, setOrderFormData] = useState<Partial<Order>>({
    ...MINIMUM_ORDER
  });
  const [anonymousOrderCreationRequested, setAnonymousOrderCreationRequested] =
    useState(false);
  const isDraftFlow = !!orderDraftId;
  const draftId = orderDraftId || claim;
  const placementFlowHelper =
    product === Product.Classified
      ? new ClassifiedPlacementFlowHelper()
      : new ObituaryPlacementFlowHelper();

  const { value: createdOrderId, isError: failedToCreateOrder } =
    useAsyncEffect({
      fetchData: async () => {
        if (!draftId && user) {
          const { response: order, error: createAdError } =
            await placementFlowHelper.createAd(user);
          if (createAdError) {
            return;
          }
          return order.id;
        }
      },
      errorConfig: {
        service: ColumnService.ORDER_PLACEMENT,
        message: 'Failed to create order model',
        tags: {
          userId: user?.id || ''
        }
      },
      dependencies: [user?.id]
    });

  const orderId = createdOrderId || draftId;

  const { value, isError: failedToLoad } = useAsyncEffect({
    fetchData: async () => {
      if (!orderId) {
        return null;
      }

      const orderModel = await getModelFromId(
        OrderModel,
        context,
        context.ordersRef(),
        orderId
      );

      const newspaperOrderModels = await orderModel.getNewspaperOrders();
      const newspaperOrders = newspaperOrderModels.map(
        newspaperOrderModel => newspaperOrderModel.modelData
      );

      const adModelResult = await placementFlowHelper.getAdModel(orderModel);

      if (adModelResult.error) {
        throw adModelResult.error;
      }

      return { orderModel, newspaperOrders, adModel: adModelResult.response };
    },
    errorConfig: {
      service: ColumnService.ORDER_PLACEMENT,
      message: 'Failed to retrieve order model',
      tags: {
        orderId,
        claim
      }
    },
    dependencies: [orderId]
  });

  if (failedToCreateOrder || failedToLoad) {
    return <Alert title="Loading failure" id="loading-failure-error" />;
  }

  const { orderModel, adModel, newspaperOrders } = value ?? {};

  const chosenFlow =
    product === Product.Classified
      ? Product.Classified
      : orderModel && isAdvertiserWithOrganizationOrder(orderModel.modelData)
      ? CustomerTypeString.FUNERAL_HOME
      : CustomerTypeString.INDIVIDUAL;

  const steps = stepsByFlow[chosenFlow];

  const getPlacementFlowSteps = () => {
    if (isPublisher && !isDraftFlow && chosenFlow !== Product.Classified) {
      return [PlacementFlowStep.CustomerType, ...steps];
    }
    if (!isPublisher) {
      return steps.includes(PlacementFlowStep.Details) ? steps.slice(1) : steps;
    }
    return steps;
  };

  if (orderModel && adModel) {
    return (
      <PlacementFlowStepSelector
        orderModel={orderModel}
        adModel={adModel}
        draftNewspaperOrders={newspaperOrders}
        steps={getPlacementFlowSteps()}
        version={orderModel.modelData.activeVersion}
        product={product}
        editData={undefined}
      />
    );
  }

  const login = async ({
    accessCode,
    email,
    startingStepNumber
  }: {
    accessCode: string;
    email: string;
    startingStepNumber: number;
  }) => {
    const { token } = await getOrderToken(accessCode, email);
    dispatch(AuthActions.loginToken(token));
    updateCurrentStep(startingStepNumber);
  };

  const loadOrderEmail = getLoadOrderEmail();

  if (loadOrderEmail) {
    if (claim) {
      dispatch(AuthActions.logout());
    }

    return (
      <Placemat>
        <ResumeOrder email={loadOrderEmail} login={login} />
      </Placemat>
    );
  }

  const isAnonymousFlow = !user && !isAuthLoading;
  if (isAnonymousFlow && !anonymousOrderCreationRequested) {
    return (
      <FormWrapper
        onSubmit={async () => {
          // We never disable this because we'll be in a different block once this is done
          setAnonymousOrderCreationRequested(true);
          try {
            const { contactEmail, firstName, lastName, phone } = {
              ...MINIMUM_ORDER,
              ...orderFormData
            };

            const accessCode = await createOrder({
              contactEmail,
              firstName,
              lastName,
              phone,
              product
            });

            await login({
              accessCode,
              email: contactEmail,
              startingStepNumber: SELECT_CATEGORY_STEP_NUMBER
            });
          } finally {
          }
        }}
        submitText="Next"
        steps={steps}
        loading={anonymousOrderCreationRequested}
        newspaperOrdersFormData={[]}
        userError=""
        product={product}
      >
        <Placemat>
          <PersonalDetail
            product={product}
            inputData={orderFormData}
            setInputData={setOrderFormData}
          />
        </Placemat>
      </FormWrapper>
    );
  }

  return (
    <LoadingState
      context={{
        service: ColumnService.ORDER_PLACEMENT,
        location: 'Ad placement',
        tags: {
          isAnonymousFlow: isAnonymousFlow ? 'true' : 'false',
          order: orderModel?.id ?? 'undefined',
          adModel: adModel?.id ?? 'undefined',
          product,
          adPlacementFlow: 'true'
        }
      }}
    />
  );
}
