import LoadingState from 'components/LoadingState';
import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { selectAuthLoading, selectUserAuth } from 'redux/auth';
import { useAppSelector } from 'redux/hooks';
import { useRedirectToLanding } from './hooks';

export function RequireAnon() {
  const { pathname } = useLocation();

  const auth = useAppSelector(selectUserAuth);
  const authLoading = useAppSelector(selectAuthLoading);

  const redirectToLanding = useRedirectToLanding();

  useEffect(() => {
    if (authLoading) {
      return;
    }

    if (auth) {
      return redirectToLanding();
    }
  }, [auth, authLoading, pathname]);

  return authLoading ? <LoadingState /> : <Outlet />;
}
