import { getFirebaseContext } from 'utils/firebase';
import { RateType } from 'lib/enums';
import {
  ESnapshotExists,
  EQuerySnapshot,
  EOrganization,
  ERate,
  AdRate
} from 'lib/types';

/**
 * Determines whether or not a rate snapshot supports display ads.
 * Right now only column inch, flat, and iowa form rates can support display ads.
 * @param rate rate to test
 * @returns {boolean}
 */
export const rateTypeSupportsDisplay = (rateType: ERate['rateType']) => {
  if (rateType === RateType.column_inch.value) {
    return true;
  }

  if (rateType === RateType.flat.value) {
    return true;
  }

  if (rateType === RateType.iowa_form.value) {
    return true;
  }

  if (rateType === RateType.nypa_statutory_rate.value) {
    return false;
  }

  return false;
};

/**
 * Determines whether or not a rate is one of the default liner or display rates for an organization
 * @param organization organization to check
 * @param rate rate object
 * @returns {boolean}
 */
export const isDefaultRate = (
  organization: ESnapshotExists<EOrganization>,
  rateId: string
) => {
  return [
    organization.data().defaultLinerRate?.id,
    organization.data().defaultDisplayRate?.id
  ].includes(rateId);
};

export type RatesFilter = {
  rateType: null | number;
  runType: null | 'runBased' | 'nonRunBased';
};

export const DEFAULT_RATE_FILTER: RatesFilter = {
  rateType: null,
  runType: null
};

/**
 * Formats inputs in a pretty fashion that may be numbers or strings. If the input is a number,
 * it will be formatted to the specified precision without trailing zeros.
 * @param value input to format
 * @param precision precision to format numeric values to
 * @returns {any} formatted value
 */
export const prettyFormatNumber = (value: any, precision = 4) => {
  if (typeof value === 'number') {
    // set the max precision of 4 decimals
    const precise = parseFloat(`${value}`).toFixed(precision);

    // strip trailing zeroes
    let withoutTrailing = precise.replace(/0+$/, '');

    // ensure we have a minimum of two decimals
    const numberOfDecimals = withoutTrailing.split('.')[1]?.length || 0;
    for (let i = 0; i < Math.max(0, 2 - numberOfDecimals); i++) {
      withoutTrailing += '0';
    }
    return withoutTrailing;
  }
  return value;
};

/**
 * Combine the results of two queries into a single array, removing duplicates.
 */
const dedupeQueryCombination = <T>(...queries: EQuerySnapshot<T>[]) => {
  const seen = new Set();
  const deduped = [];
  for (const query of queries) {
    for (const doc of query.docs) {
      if (!seen.has(doc.id)) {
        deduped.push(doc);
        seen.add(doc.id);
      }
    }
  }
  return deduped;
};

/**
 * Determines all of the customers that are using a rate as either their liner or display rate.
 * @param organization organization we are searching for
 * @param rate rate object we are looking at
 * @returns {Promise<ESnapshotExists<ECustomer>[]>} set of customers using the rate
 */
export const getCustomersOnRate = async (
  organization: ESnapshotExists<EOrganization>,
  rate: ESnapshotExists<ERate>
) => {
  const customersUsingRateAsLinerQuery = await getFirebaseContext()
    .customersRef()
    .where('organization', '==', organization.ref)
    .where('linerRate', '==', rate.ref)
    .get();
  const customersUsingRateAsDisplayQuery = await getFirebaseContext()
    .customersRef()
    .where('organization', '==', organization.ref)
    .where('displayRate', '==', rate.ref)
    .get();

  return dedupeQueryCombination(
    customersUsingRateAsLinerQuery,
    customersUsingRateAsDisplayQuery
  );
};

/**
 * Determines all of the customer organizations that are using a rate as either their liner or display rate.
 * @param organization organization we are looking at
 * @param rate rate we are looking at
 * @returns {Promise<ESnapshotExists<ECustomerOrganization>[]>} set of customer organizations using the rate
 */
export const getCustomerOrgsUsingRate = async (
  organization: ESnapshotExists<EOrganization>,
  rate: ESnapshotExists<ERate>
) => {
  const customerOrgsUsingRateAsLinerQuery = await getFirebaseContext()
    .customerOrganizationsRef()
    .where('organization', '==', organization.ref)
    .where('linerRate', '==', rate.ref)
    .get();
  const customerOrgsUsingRateAsDisplayQuery = await getFirebaseContext()
    .customerOrganizationsRef()
    .where('organization', '==', organization.ref)
    .where('linerRate', '==', rate.ref)
    .get();

  return dedupeQueryCombination(
    customerOrgsUsingRateAsLinerQuery,
    customerOrgsUsingRateAsDisplayQuery
  );
};

/**
 * Determines whether or not we should show a row for a rate in the table
 * @param rate rate to test
 * @param searchString user input in a search field
 * @param filter filter state from our dialog
 * @returns {boolean} true if it should show up false otherwise
 */
export const shouldShowRateInTable = (
  rate: ESnapshotExists<AdRate>,
  searchString: string,
  filter: RatesFilter
) => {
  if (
    !rate.data().description.toLowerCase().includes(searchString.toLowerCase())
  ) {
    return false;
  }

  if (
    filter.rateType !== null &&
    RateType.by_value(rate.data().rateType)?.value !== filter.rateType
  ) {
    return false;
  }

  if (filter.runType === 'runBased' && !rate.data().runBased) {
    return false;
  }

  if (filter.runType === 'nonRunBased' && rate.data().runBased) {
    return false;
  }

  return true;
};

export const getNumActiveFromFilter = (filter: RatesFilter) => {
  let totalActive = 0;
  if (filter.rateType !== null) {
    totalActive++;
  }
  if (filter.runType !== null) {
    totalActive++;
  }
  return totalActive;
};

/**
 * Determines whether or not a rate is a legacy rate. Legacy rates are defined
 * as rates in which:
 * 1. The rate charges the same amount per run
 * 2. The amount that rate charges per run depends on the total number of runs
 * @param rate
 * @returns
 */
export const isLegacyRate = (rate: AdRate) => {
  const { runBased, rate_0, rate_1, rate_2 } = rate;
  if (runBased) return false;
  if (rate_0 === rate_1 && rate_1 === rate_2) return false;
  return true;
};

/**
 * Determines whether or not to show the third run rate particular rate in the UI
 * We show the third run rate if:
 * 1) The rate has additional rates beyond the third pricing run
 * 2) The rate is a run based rate and the second run rate is different from the first run rate
 * @param {AdRate} rate
 * @returns {boolean} true if the third run rate should be shown, false otherwise
 */
export const shouldShowThirdRunRate = (rate: AdRate): boolean => {
  if (rate.additionalRates) {
    return true;
  }
  return rate.rate_2 !== rate.rate_1;
};
