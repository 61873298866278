import React from 'react';
import { State } from 'lib/enums';
import { EOrganization, ESnapshotExists, exists } from 'lib/types';
import { Autocomplete } from 'lib/components/Autocomplete';
import { SearchableOrganizationRecord } from 'lib/types/searchable';

type SelectPublisherDropdownProps = {
  publisherOrganization: ESnapshotExists<EOrganization> | undefined;
  onPublisherOrganizationChange: (publisherOrganizationId: string) => void;
  publisherOptions: SearchableOrganizationRecord[];
  loading: boolean;
};

export function SelectPublisherDropdown({
  publisherOrganization,
  onPublisherOrganizationChange,
  publisherOptions,
  loading
}: SelectPublisherDropdownProps) {
  const formattedPublisherOptions = publisherOptions.map(formatOptions);

  const noteText = getPublisherLocationNoteText(publisherOrganization?.data());

  return (
    <Autocomplete
      id="selectPublisher"
      labelText="Publisher"
      noteText={!loading ? noteText : ''}
      placeholder="Select a publisher"
      value={
        exists(publisherOrganization) && !loading
          ? publisherOrganization.id
          : ''
      }
      options={formattedPublisherOptions}
      onChange={onPublisherOrganizationChange}
      loading={loading}
    />
  );
}

export function formatOptions(paper: SearchableOrganizationRecord) {
  // TODO(kevinm): Move this derived label into a model helper
  const state = State.by_value(paper.state);
  const isLouisiana = state?.key === 'louisiana';
  const countyLabel = isLouisiana ? 'Parish' : 'County';
  const countyDescription = paper.county
    ? paper.county.toLowerCase().includes(countyLabel.toLowerCase())
      ? paper.county
      : `${paper.county} ${countyLabel}`
    : null;

  return {
    value: paper.id,
    label: paper.name,
    description: [paper.city, countyDescription, state?.abbrev.toUpperCase()]
      .filter(Boolean)
      .join(', ')
  };
}

function getPublisherLocationNoteText(
  publisherOrganization: EOrganization | undefined
) {
  const { state: stateId } = publisherOrganization || {};

  const stateLabel = State.by_value(stateId)?.label;
  const noteText = stateLabel
    ? `This publisher is located in ${stateLabel}.`
    : undefined;

  return noteText;
}
