import React from 'react';
import moment from 'moment';
import { InvoiceStatus } from 'lib/enums';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import { SearchableExpressEmailConversationRecord } from 'lib/types/searchable';
import LinkTo from 'components/LinkTo';
import { usePlacementRequestData } from './usePlacementRequestData';

const MAX_FILENAME_LENGTH = 20;
const extractNameFromUrl = (url: string) => {
  const fileName = url.split('/').pop();
  const fileNameWithoutExtension = fileName?.split('.').slice(0, -1).join('.');
  if (!fileNameWithoutExtension) {
    return 'Unknown';
  }
  const extensionWithoutQueryParams = fileName?.split('.').pop()?.split('?')[0];

  if (fileNameWithoutExtension?.length > MAX_FILENAME_LENGTH) {
    return `${fileNameWithoutExtension.slice(
      0,
      MAX_FILENAME_LENGTH
    )}...${extensionWithoutQueryParams}`;
  }
  return `${fileNameWithoutExtension}.${extensionWithoutQueryParams}`;
};

export default function ConversationRequestRow({
  conversationRequest
}: {
  conversationRequest: SearchableExpressEmailConversationRecord;
}) {
  const { placementData } = usePlacementRequestData(conversationRequest);
  const notice = placementData?.notice;
  const invoice = placementData?.invoice;

  return (
    <>
      <td>
        <div className="flex flex-col gap-1">
          <div className="text-sm text-grey-500">
            {conversationRequest.custom_id}
          </div>
          <div className="text-sm text-grey-400">
            {moment(conversationRequest.conversation_created_at).format(
              'M/DD/YYYY hh:mm A'
            )}
            {conversationRequest.external_id ? (
              <>
                {' - '}
                {conversationRequest.external_id}
              </>
            ) : null}
          </div>
        </div>
      </td>
      <td>
        {conversationRequest.notice_id ? (
          <div
            className="ml-12 flex flex-col gap-1"
            onClick={e => e.stopPropagation()}
          >
            <LinkTo
              href={`/notice/${conversationRequest.notice_id}`}
              className="text-sm text-column-primary-500 underline flex gap-2 items-center"
              target="_blank"
            >
              <span>{conversationRequest.notice_id}</span>
              <span>
                <ArrowTopRightOnSquareIcon className="w-4 h-4" />
              </span>
            </LinkTo>
            <div className="text-sm text-column-grey-400">
              Publishing{' '}
              {notice
                ?.data()
                ?.publicationDates.map(date =>
                  moment(date.toDate()).format('M/DD/YYYY')
                )
                .join(', ')}
            </div>
          </div>
        ) : (
          <div className="ml-12">
            <div className="text-sm text-columngrey-500">Unplaced</div>
          </div>
        )}
      </td>
      <td>
        <div className="ml-12">
          {invoice?.data()?.status
            ? InvoiceStatus.by_value(invoice.data()?.status)?.label
            : 'Not invoiced yet'}
        </div>
      </td>
      <td>
        <div className="ml-12">
          {notice?.data()?.affidavitURL ? (
            <a
              href={notice?.data()?.affidavitURL}
              target="_blank"
              className="text-sm text-column-primary-500"
              rel="noreferrer"
            >
              {extractNameFromUrl(notice?.data()?.affidavitURL ?? '')}
            </a>
          ) : (
            <div className="text-sm text-column-grey-500">No affidavit</div>
          )}
        </div>
      </td>
    </>
  );
}
