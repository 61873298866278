import { Product } from 'lib/enums';
import { useFirestoreQueryListener } from 'lib/frontend/hooks/useFirestoreQueryListener';
import { IRateService } from 'lib/services/adRateService';
import { AdRate } from 'lib/types';
import { selectActiveOrganization } from 'redux/auth';
import { useAppSelector } from 'redux/hooks';

type UsePublisherOrgRatesConfig = {
  includeArchived?: boolean;
  product?: Product;
};

export function usePublisherOrgRates<T extends AdRate>(
  rateService: IRateService<T>,
  config?: UsePublisherOrgRatesConfig
) {
  const activeOrganization = useAppSelector(selectActiveOrganization);

  const { ownedRatesQuery, allowedRatesQuery } =
    rateService.getPublisherOrgRatesQueries(activeOrganization?.ref);

  const ownedRates = useFirestoreQueryListener(
    ownedRatesQuery,
    [activeOrganization?.id, config?.product],
    {
      message: 'Error listening to owned rates',
      tags: { organizationId: activeOrganization?.id ?? '' }
    }
  );
  const allowedRates = useFirestoreQueryListener(
    allowedRatesQuery,
    [activeOrganization?.id, config?.product],
    {
      message: 'Error listening to allowed rates',
      tags: { organizationId: activeOrganization?.id ?? '' }
    }
  );

  const loading = allowedRates === null || ownedRates === null;
  const orgRates = [...(ownedRates?.docs ?? []), ...(allowedRates?.docs ?? [])];

  if (!config?.includeArchived) {
    return {
      orgRates: orgRates.filter(rate => !rate.data().archived),
      loading
    };
  }

  return { orgRates, loading };
}
