// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { ReactElement } from 'react';
import {
  ArrowPathIcon,
  ExclamationTriangleIcon
} from '@heroicons/react/24/outline';
import { Badge } from 'lib/components/Badge';
import { AffinityXSyncStatus } from './helpers';

interface AffinityXSyncStatusPillProps {
  syncStatus: AffinityXSyncStatus | null;
}

interface SyncStatusConfig {
  status: 'success' | 'warning' | 'critical' | 'info';
  message: string;
  icon?: ReactElement;
}

function getSyncStatusConfig(
  syncStatus: AffinityXSyncStatus
): SyncStatusConfig | undefined {
  switch (syncStatus) {
    case AffinityXSyncStatus.SYNC_SUCCESSFUL:
      return { status: 'success', message: 'Sync complete' };
    case AffinityXSyncStatus.SYNC_CANCELLED_EDIT_REQUIRED:
      return { status: 'info', message: 'Sync cancelled' };
    case AffinityXSyncStatus.SYNC_FAILED_BEFORE_ORDER_CREATION:
    case AffinityXSyncStatus.SYNC_FAILED_AFTER_ORDER_CREATION:
      return { status: 'critical', message: 'Sync failed' };
    case AffinityXSyncStatus.SYNC_UNKNOWN:
      return {
        status: 'critical',
        message: 'Unknown status',
        icon: <ExclamationTriangleIcon className="h-3 w-3" />
      };
    default:
      if (syncStatus.startsWith('SYNC_IN_PROGRESS')) {
        return { status: 'warning', message: 'In progress' };
      }
  }
}

function AffinityXSyncStatusPill({ syncStatus }: AffinityXSyncStatusPillProps) {
  if (!syncStatus) {
    return null;
  }
  const config = getSyncStatusConfig(syncStatus);

  if (!config) {
    return null;
  }

  return (
    <Badge
      status={config.status}
      shape="rectangle"
      startIcon={config.icon || <ArrowPathIcon className="h-3 w-3" />}
    >
      {config.message}
    </Badge>
  );
}

export default AffinityXSyncStatusPill;
