import React from 'react';
import {
  CardGridLayout,
  GridInput,
  useEditableCard
} from 'lib/components/Card/Grid';
import { Form } from 'lib/components/Form';
import { ESnapshotExists } from 'lib/types';
import { EditableCardArray } from 'lib/components/Card/EditableCardArray';
import { TextField } from 'lib/components/TextField';
import {
  Filler,
  PublisherPaginationSettings
} from 'lib/types/publisherPaginationSettings';
import { guidGenerator } from 'utils';

function FillerCard({
  value,
  onChange
}: {
  value: Filler;
  onChange: (houseAd: Filler) => void;
}) {
  return (
    <>
      <GridInput>
        <TextField
          labelText="Name"
          value={value.name}
          onChange={e => onChange({ ...value, name: e })}
          required
          id="house-ad-name"
        />
      </GridInput>
      <GridInput>
        <TextField
          labelText="Columns"
          value={value.columns?.toString()}
          onChange={newColumns =>
            onChange({ ...value, columns: parseInt(newColumns, 10) })
          }
          type="number"
          required
          id="house-ad-columns"
          min={1}
          max={10}
        />
      </GridInput>
      <GridInput fullWidth>
        <TextField
          labelText="PDF URL"
          value={value.pdfUrl}
          onChange={newPdfUrl => onChange({ ...value, pdfUrl: newPdfUrl })}
          type="url"
          id="house-ad-pdf-url"
          required
        />
      </GridInput>
    </>
  );
}

export default function FillerSettings({
  publisherPaginationSettings
}: {
  publisherPaginationSettings: ESnapshotExists<PublisherPaginationSettings>;
}) {
  const { formSettings, onChange, onSubmitWrapper, editable, disabled } =
    useEditableCard(publisherPaginationSettings.data());

  const saveHouseAdsSettings = async () => {
    const relevantSettings = {
      fillers: formSettings.fillers
    };
    try {
      await publisherPaginationSettings.ref.update(relevantSettings);
      return { success: true };
    } catch (e) {
      return { success: false };
    }
  };

  return (
    <Form
      onSubmit={() => onSubmitWrapper(saveHouseAdsSettings)}
      id="pagination-settings-form"
    >
      <CardGridLayout
        header={{
          title: 'Fillers & House Ads',
          description:
            'Standard content that will be inserted into the block to fill blank space.'
        }}
        editable={editable}
      >
        <GridInput fullWidth>
          <EditableCardArray
            disabled={disabled}
            columns={1}
            baseHeader="Fillers & House Ads"
            onChange={newFillers =>
              onChange({ ...formSettings, fillers: newFillers })
            }
            archivable={{
              buttonText: 'Remove'
            }}
            createable={{
              buttonText: 'Add option',
              initialValue: {
                columns: 1,
                pdfUrl: '',
                name: '',
                height: 180,
                id: guidGenerator().slice(0, 8),
                maxOccurrences: 1
              }
            }}
            Component={FillerCard}
            values={formSettings.fillers}
          />
        </GridInput>
      </CardGridLayout>
    </Form>
  );
}
