import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ERROR_ROUTE, FILE_ROUTES, FORM_ROUTES } from 'router/routes';
import { getDynamicRoute } from 'lib/frontend/utils/router';
import NoticeTypeCard from './NoticeTypeCard';

type CreateUserNoticeCardSettingProps = {
  noticeType: any;
  noticeId: string;
  draftId: string;
};

const CreateUserNoticeCard = ({
  noticeType,
  noticeId,
  draftId
}: CreateUserNoticeCardSettingProps) => {
  const navigate = useNavigate();
  return (
    <NoticeTypeCard
      noticeType={noticeType}
      onClick={() => {
        if (noticeType.children().length) {
          navigate(
            getDynamicRoute(FILE_ROUTES.BY_NOTICE_WITH_SLUG, {
              id: noticeId,
              noticeSlug: noticeType.key
            })
          );
          return;
        }

        if (!noticeType.typeform) {
          navigate(getDynamicRoute(ERROR_ROUTE, { code: '204' }));
          return;
        }

        navigate(
          getDynamicRoute(FORM_ROUTES.NOTICE_TYPE, {
            noticeId: draftId,
            noticeType: noticeType.value
          })
        );
      }}
    />
  );
};

export default CreateUserNoticeCard;
