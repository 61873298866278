import React, { useState } from 'react';
import ToastActions from 'redux/toast';
import {
  PublicationIssueAttachmentStatus,
  PublicationIssueAttachmentType
} from 'lib/types/publicationIssueAttachment';
import { PublicationIssueAttachmentModel } from 'lib/model/objects/publicationIssueAttachmentModel';
import { InputAccessories } from 'lib/components/InputAccessories';
import { MetaFile } from 'types/metaFile';
import { PublicationIssueStatus } from 'lib/types/publicationIssue';
import { getNewspaperCodeFromDestination } from 'lib/pagination/helpers';
import { FileMetadata } from 'lib/types/metaFile';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { selectUser } from 'redux/auth';
import { exists } from 'lib/types';
import { getBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { PublicationIssueWithSection } from 'lib/types/publicationIssueSection';
import { Modal } from 'lib/components/Modal';
import { useSafeLoading } from 'lib/components/hooks/useSafeLoading';
import { ColumnService } from 'lib/services/directory';
import { ColumnButtonProps } from 'lib/components/ColumnButton';
import { Product } from 'lib/enums';
import { FullGenericPaginationSubmissionAudit } from 'lib/types/pagination';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { getFirebaseContext } from 'utils/firebase';
import { Alert } from 'lib/components/Alert';
import { getUploadDestinationByOrgId } from 'lib/publishers/uploads';
import { logAndCaptureCriticalError } from 'utils';
import { isColumnUser } from 'lib/helpers';
import { ColumnH1 } from 'lib/components/Typography';
import moment from 'moment';
import { capitalize } from 'lodash';
import { handlePublicationIssueAttachmentUploads } from './handleUpload';
import { AWAITING_APPROVAL_TAB, PaginationTabs } from '../paginationTableUtils';
import {
  AttachmentUploadField,
  UploadedAttachmentData
} from './AttachmentUploadField/AttachmentUploadField';
import { handlePaginationReview } from './handlePaginationReview';
import PaginationReviewDisplay from './PaginationReviewDisplay';

const MANIFEST_UPLOAD_FIELD_ID = 'upload-field-manifest';
const ATTACHMENTS_UPLOAD_FIELD_ID = 'upload-field-attachments';

export type PaginationUploadModalProps = {
  publicationIssueWithSection: PublicationIssueWithSection;
  product: Product;
  changesSubmittingForIssue: number;
  onHandleInProgressChanges: (changeInProgress: 1 | -1) => void;
  reloadPublicationIssues: () => void;
  setShowPaginationUploadModal: (
    show: false | PublicationIssueWithSection
  ) => void;
  setPaginationTableTab: (tab: PaginationTabs) => void;
};

export function PaginationUpload({
  publicationIssueWithSection,
  product,
  changesSubmittingForIssue,
  onHandleInProgressChanges,
  reloadPublicationIssues,
  setShowPaginationUploadModal,
  setPaginationTableTab
}: PaginationUploadModalProps) {
  const user = useAppSelector(selectUser);
  const dispatch = useAppDispatch();
  const { publicationIssue, section } = publicationIssueWithSection;

  /**
   * If we are using orders, there should be a section passed in whose status
   * determines the status shown in the UI. In the absence of a section, we use
   * the status on the issue.
   */
  const controllingDocument = section ?? publicationIssue;
  const { value: newspaperCode } = useAsyncEffect({
    fetchData: async () => {
      const [siteError, site] = await getUploadDestinationByOrgId(
        getFirebaseContext(),
        publicationIssue.modelData.publisher.id,
        product
      );
      if (siteError) {
        throw siteError;
      }
      if (!site) {
        const errorMessage =
          'User trying to upload blocks to paper that is not on advanced pagination';
        logAndCaptureCriticalError(
          ColumnService.PAGINATION,
          new Error(errorMessage),
          'Cannot get newspaper code for publication issue in pagination uploader',
          {
            newspaperId: publicationIssue.modelData.publisher.id,
            publicationIssueId: publicationIssue.id,
            product,
            userId: user?.id || 'unknown'
          }
        );
        throw new Error(errorMessage);
      }
      return getNewspaperCodeFromDestination(site);
    },
    dependencies: [
      publicationIssue.modelData.publisher.id,
      product,
      section?.id
    ]
  });

  const {
    value: filteredAttachments,
    invalidateData: refreshPaginationAttachments
  } = useAsyncEffect<PublicationIssueAttachmentModel[]>({
    fetchData: async () => {
      const [attachmentsError, attachments] = section
        ? await section.getAttachmentsForIssueSection()
        : await publicationIssue.getAttachmentsForIssue();
      if (attachmentsError) {
        throw attachmentsError;
      }
      return attachments;
    },
    dependencies: [controllingDocument?.id]
  });

  const [userMessage, setUserMessage] = useState<string | undefined>();
  const { publicationDate } = publicationIssue.modelData;

  const [paginationReview, setPaginationReview] =
    useState<FullGenericPaginationSubmissionAudit>();

  const readOnly = ![
    PublicationIssueStatus.READY_FOR_PAGINATION,
    PublicationIssueStatus.CHANGES_REQUESTED
  ].includes(controllingDocument.modelData.status);

  const getUploadedAttachmentFromPublicationIssueAttachment = (
    publicationIssueAttachment: PublicationIssueAttachmentModel
  ): UploadedAttachmentData => {
    const { downloadUrl, storagePath, status, name, metadata } =
      publicationIssueAttachment.modelData;
    const { id } = publicationIssueAttachment;
    return { id, url: downloadUrl, path: storagePath, status, name, metadata };
  };

  const manifest = filteredAttachments?.find(
    attachment =>
      attachment.modelData.type === PublicationIssueAttachmentType.MANIFEST
  );
  const attachments = filteredAttachments
    ?.filter(
      attachment =>
        attachment.modelData.type !== PublicationIssueAttachmentType.MANIFEST
    )
    .reduce<Record<string, PublicationIssueAttachmentModel>>(
      (acc, attachment) => {
        acc[attachment.id] = attachment;
        return acc;
      },
      {}
    );

  const handleDeleteAttachment = async (
    uploadFieldId: string,
    attachmentId: string
  ): Promise<void> => {
    onHandleInProgressChanges(1);
    try {
      if (uploadFieldId === MANIFEST_UPLOAD_FIELD_ID) {
        if (!manifest) {
          throw Error('No manifest found.');
        }
        await manifest?.updateStatus(PublicationIssueAttachmentStatus.DELETED);
      } else if (uploadFieldId === ATTACHMENTS_UPLOAD_FIELD_ID) {
        if (!attachments || !attachments[attachmentId]) {
          throw Error('Attachment not found.');
        }
        await attachments[attachmentId].updateStatus(
          PublicationIssueAttachmentStatus.DELETED
        );
      } else {
        throw Error('Upload field not found.');
      }
    } catch (error: any) {
      setUserMessage(`Error deleting attachment: ${error.message}`);
    } finally {
      onHandleInProgressChanges(-1);
      refreshPaginationAttachments();
    }
  };

  const handleUploadFiles = async (
    uploadFieldId: string,
    files: MetaFile[]
  ): Promise<void> => {
    onHandleInProgressChanges(1);
    const type =
      uploadFieldId === MANIFEST_UPLOAD_FIELD_ID
        ? PublicationIssueAttachmentType.MANIFEST
        : PublicationIssueAttachmentType.PAGINATION;
    try {
      if (!newspaperCode) {
        throw new Error('Newspaper code not found.');
      }
      await handlePublicationIssueAttachmentUploads(
        publicationIssue.id,
        section?.id,
        newspaperCode,
        publicationDate,
        files,
        type
      );
    } catch (error: any) {
      setUserMessage(`Error adding attachment: ${error.message}`);
    } finally {
      onHandleInProgressChanges(-1);
      refreshPaginationAttachments();
    }
  };

  const handleSubmit = async (): Promise<void> => {
    if (!manifest || !attachments || Object.entries(attachments).length === 0) {
      return setUserMessage(
        `Please upload a manifest and at least one attachment to submit`
      );
    }
    if (!exists(user)) {
      return;
    }

    await controllingDocument.updateStatus(
      user,
      PublicationIssueStatus.AWAITING_APPROVAL
    );
    reloadPublicationIssues();
    setPaginationTableTab(AWAITING_APPROVAL_TAB);
    setShowPaginationUploadModal(false);
    refreshPaginationAttachments();
  };

  const handleUpdateMetadata = async (
    uploadFieldId: string,
    attachmentId: string,
    key: keyof FileMetadata,
    value: string
  ): Promise<void> => {
    onHandleInProgressChanges(1);
    try {
      if (uploadFieldId === MANIFEST_UPLOAD_FIELD_ID) {
        if (!manifest) {
          throw Error('No manifest found.');
        }
        await manifest.updateMetadata(key, value);
      } else if (uploadFieldId === ATTACHMENTS_UPLOAD_FIELD_ID) {
        if (!attachments || !attachments[attachmentId]) {
          throw Error('Attachment not found.');
        }
        await attachments[attachmentId].updateMetadata(key, value);
      } else {
        throw Error('Upload field not found.');
      }
    } catch (error: any) {
      setUserMessage(`Error updating metadata: ${error.message}`);
    } finally {
      onHandleInProgressChanges(-1);
      refreshPaginationAttachments();
    }
  };

  // These are the same attachments above that have been converted to the UploadedAttachmentData format
  const convertedManifest = manifest
    ? [getUploadedAttachmentFromPublicationIssueAttachment(manifest)]
    : undefined;
  const convertedAttachments = attachments
    ? Object.values(attachments).map(attachment =>
        getUploadedAttachmentFromPublicationIssueAttachment(attachment)
      )
    : undefined;

  const [runningPaginationReview, runPaginationReviewOnClick] = useSafeLoading({
    callback: async () => {
      const [manifest] = convertedManifest || [];
      if (!manifest) {
        return;
      }
      const attachments = convertedAttachments || [];
      if (!attachments.length) {
        return;
      }
      if (runningPaginationReview) {
        return;
      }

      onHandleInProgressChanges(1);

      const [paginationReviewError, paginationReview] =
        await handlePaginationReview({
          product,
          manifest,
          attachments,
          publicationIssue,
          section
        });
      if (paginationReviewError) {
        dispatch(
          ToastActions.toastError({
            headerText: 'Pagination error',
            bodyText:
              'Please try again later. If the problem persists, reach out to the Column team.'
          })
        );
      } else {
        setPaginationReview(paginationReview);
      }
      onHandleInProgressChanges(-1);
    },
    errorConfig: {
      service: ColumnService.PAGINATION,
      message: 'Error running automated pagination review',
      tags: {
        sectionId: section?.id ?? 'null',
        publicationIssueId: publicationIssue.id
      }
    }
  });

  const loading = changesSubmittingForIssue > 0 || runningPaginationReview;

  const enableInAppValidation = getBooleanFlag(
    LaunchDarklyFlags.ENABLE_IN_APP_PAGINATION_REVIEW
  );

  const disablePrimarySubmit =
    readOnly || (!paginationReview && enableInAppValidation);

  const secondaryActions: ColumnButtonProps[] = [
    {
      buttonText: 'Cancel',
      type: 'button',
      onClick: () => setShowPaginationUploadModal(false)
    }
  ];
  if (enableInAppValidation) {
    secondaryActions.push({
      buttonText: 'Run Pagination Review',
      type: 'button',
      onClick: runPaginationReviewOnClick,
      secondary: true,
      loading
    });
  }

  const blockUploadDueToBadIssueSection =
    product !== Product.Notice && !!user && !!isColumnUser(user) && !section;
  const blockUploadDueToUnexpectedSection =
    product === Product.Notice && !!user && !!isColumnUser(user) && !!section;

  const blockDueToBadSection =
    blockUploadDueToBadIssueSection || blockUploadDueToUnexpectedSection;

  return (
    <Modal
      id="pagination-upload-modal"
      onClose={() => setShowPaginationUploadModal(false)}
      primaryAction={{
        buttonText: 'Submit',
        type: 'button',
        onClick: handleSubmit,
        disabled: disablePrimarySubmit,
        loading
      }}
      title={
        <div className="flex flex-col gap-4">
          <ColumnH1>
            Upload Pagination for {publicationDate} {newspaperCode}
          </ColumnH1>
          <div className="text-sm text-column-gray-300">
            {capitalize(product)} section{section ? ` (${section.id})` : ''}
            {controllingDocument.modelData.deadlineTimestamp
              ? ` ${moment(
                  controllingDocument.modelData.deadlineTimestamp?.toDate()
                ).format('MM/DD/YY h:mm a')}`
              : ''}
          </div>
        </div>
      }
      secondaryActions={secondaryActions}
    >
      {paginationReview && (
        <PaginationReviewDisplay paginationReview={paginationReview} />
      )}

      {readOnly && (
        <div className="my-2">
          <div className="text-column-red-500">
            This issue is not "Ready for Pagination". New uploads are not
            allowed.
          </div>
        </div>
      )}
      {userMessage && (
        <Alert
          id="pagination-upload-modal-user-alert-message"
          description={userMessage}
          status="error"
        />
      )}
      {blockUploadDueToBadIssueSection && (
        <Alert
          id="pagination-upload-modal-user-alert-message"
          description={`(Column Users) This is a ${product} issue but for some reason the "issue section" is not set. If you hit upload it will not be linked to the issue, see ONCALL-5134. Please refresh the page and try again.`}
          status="error"
        />
      )}

      {blockUploadDueToUnexpectedSection && (
        <Alert
          id="pagination-upload-modal-user-alert-message"
          description={`(Column Users) This is a ${product} issue but for some reason the "issue section" is set. You are accessing attachments for the wrong product, see ONCALL-5134. Please refresh the page and try again.`}
          status="error"
        />
      )}

      <div className="my-4">
        <InputAccessories id={`file-manifest`} labelText={`Upload Manifest`}>
          <AttachmentUploadField
            id={MANIFEST_UPLOAD_FIELD_ID}
            multiSelect={false}
            readOnly={readOnly || blockDueToBadSection}
            loading={loading}
            acceptFileTypes=".csv"
            uploadedAttachments={convertedManifest}
            onUpdateMetadata={handleUpdateMetadata}
            onUploadFiles={handleUploadFiles}
            onDeleteAttachment={handleDeleteAttachment}
          />
        </InputAccessories>
      </div>
      <div className="my-4">
        <InputAccessories
          id={`file-attachments`}
          labelText={`Upload Attachments`}
        >
          <AttachmentUploadField
            id={ATTACHMENTS_UPLOAD_FIELD_ID}
            multiSelect={false}
            readOnly={readOnly || blockDueToBadSection}
            loading={loading}
            acceptFileTypes=".pdf,.jpg"
            uploadedAttachments={convertedAttachments}
            onUpdateMetadata={handleUpdateMetadata}
            onUploadFiles={handleUploadFiles}
            onDeleteAttachment={handleDeleteAttachment}
          />
        </InputAccessories>
      </div>
    </Modal>
  );
}
