import { InvoiceStatus, NoticeStatusType } from 'lib/enums';
import { isInvoiceClosed } from 'lib/helpers';
import {
  EInvoice,
  ENotice,
  ESnapshot,
  ESnapshotExists,
  exists
} from 'lib/types';

const getInvoiceCannotChangeToPaidStatus = (
  invoice: ESnapshotExists<EInvoice>,
  notice: ESnapshotExists<ENotice>
): boolean => {
  const hasInvoicePdf = !!invoice?.data()?.invoice_pdf;
  const invoiceCannotChangeToPaidStatus =
    !hasInvoicePdf ||
    notice.data().noticeStatus === NoticeStatusType.cancelled.value ||
    invoice.data().invoiceOutsideColumn ||
    invoice.data().void ||
    isInvoiceClosed(invoice);
  return invoiceCannotChangeToPaidStatus;
};

export const getInvoiceCanBePaidInColumn = (
  invoice: ESnapshot<EInvoice> | undefined,
  notice: ESnapshotExists<ENotice>
): boolean => {
  if (exists(invoice)) {
    const invoiceCannotUpdatePaymentStatus = getInvoiceCannotChangeToPaidStatus(
      invoice,
      notice
    );
    return (
      invoice?.data().status !== InvoiceStatus.unpayable.value &&
      !invoiceCannotUpdatePaymentStatus
    );
  }
  return false;
};

export const getInvoiceCanBeMarkedAsPaid = (
  invoice: ESnapshot<EInvoice> | undefined,
  notice: ESnapshotExists<ENotice>
): boolean => {
  if (exists(invoice)) {
    const invoiceCannotUpdatePaymentStatus = getInvoiceCannotChangeToPaidStatus(
      invoice,
      notice
    );
    return !invoiceCannotUpdatePaymentStatus;
  }
  return false;
};
