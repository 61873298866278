import { Tab } from '@headlessui/react';
import classNames from 'classnames';
import React from 'react';

export type TabOption<T extends string = string> = {
  enabled: boolean;
  label: React.ReactNode;
  id: T;
};
type TabGroupProps<T extends string = string> = {
  onClickTab: (tab: TabOption<T>) => void;
  className?: string;
  activeTab: TabOption<T>;
  tabs: TabOption<T>[];
  id: string;
};

/**
 * Generalized tab component that is used to organize pages
 */
export default function TabGroup<T extends string = string>({
  onClickTab,
  className,
  activeTab,
  tabs,
  id
}: TabGroupProps<T>) {
  return (
    <Tab.Group
      onChange={(index: number) => {
        if (tabs[index].enabled) {
          onClickTab(tabs[index]);
        }
      }}
      selectedIndex={tabs.findIndex(tab => tab.id === activeTab.id)}
    >
      <Tab.List
        className={classNames(
          'w-full border-b border-column-gray-100',
          className
        )}
        id={id}
      >
        {tabs.map(tab => (
          <Tab
            className={classNames('ml-6 py-6 focus:outline-none', {
              'cursor-not-allowed': !tab.enabled
            })}
            key={tab.id}
          >
            <span
              className={classNames(
                'text-base font-medium py-6',
                {
                  'text-column-primary-600 border-b-2 border-column-primary-500':
                    activeTab.id === tab.id
                },
                {
                  'text-column-gray-500': activeTab.id !== tab.id && tab.enabled
                },
                {
                  'hover:text-column-primary-600': tab.enabled
                },
                {
                  'text-column-gray-300 cursor-not-allowed': !tab.enabled
                }
              )}
            >
              {tab.label}
            </span>
          </Tab>
        ))}
      </Tab.List>
    </Tab.Group>
  );
}
