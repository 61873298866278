import React from 'react';
import { getLocationParams } from 'lib/frontend/utils/browser';
import ModulistWelcomeBanner from './ModulistWelcome';

/**
 * Renders various in-app banners based on the URL params
 */
export default function Banners() {
  const urlParams = getLocationParams();
  if (urlParams.get('modulist')) {
    return <ModulistWelcomeBanner />;
  }
  return null;
}
