import React, { ReactElement } from 'react';
import { ColumnButton } from 'lib/components/ColumnButton';
import { DoublePaperAndShadow } from 'lib/components/gifs';

function ExitConfirmationModal({
  keepEditing,
  exitWithoutSaving,
  body,
  id,
  width,
  maxWidth,
  disableOverflow
}: {
  keepEditing: () => void;
  exitWithoutSaving: () => void;
  body?: ReactElement | string | Element;
  id?: string;
  width?: string;
  customStyle?: string;
  maxWidth?: string;
  disableOverflow?: boolean;
  disableMainButton?: boolean;
}) {
  // TODO: we need to change the image URL to something in the codebase instead of URL
  return (
    <div
      id={id}
      className={`fixed z-modal inset-0 ${
        !disableOverflow ? 'overflow-y-auto' : ''
      }`}
    >
      <div className="flex items-center md:items-end justify-center min-h-screen pt-4 px-4 md:pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity">
          <div className="absolute inset-0 bg-black opacity-50"></div>
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
        &#8203;
        <div
          className={`inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle ${
            maxWidth || 'sm:max-w-lg'
          } ${!!width && width} sm:w-full`}
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
          id={`${id}-inner`}
        >
          <div className="p-12 border-b border-gray-300">
            <div className={`sm:mt-0 text-center`}>
              <div className="flex justify-center">
                <img
                  src={DoublePaperAndShadow}
                  style={{
                    clipPath: 'circle()',
                    backgroundColor: '#FFDF66',
                    width: '104px',
                    height: '104px'
                  }}
                ></img>
              </div>
              <h3
                className="text-xl pt-12 leading-6 font-semibold text-gray-900"
                id="modal-headline"
              >
                Are you sure you want to exit?
              </h3>
              {body && (
                <div className="pt-6 pb-12">
                  <span className="text-sm font-medium leading-5 text-gray-700">
                    {body}
                  </span>
                </div>
              )}
              <div className="text-center">
                <span className="pr-4">
                  <ColumnButton
                    secondary
                    size="lg"
                    buttonText="Keep editing"
                    onClick={keepEditing}
                    type="button"
                  />
                </span>
                <ColumnButton
                  primary
                  size="lg"
                  buttonText="Yes, exit"
                  onClick={exitWithoutSaving}
                  type="button"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExitConfirmationModal;
