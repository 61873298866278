import React from 'react';
import { useNavigate } from 'react-router-dom';

import { includeSearchParams } from 'utils/urls';
import { ColumnButton } from 'lib/components/ColumnButton';
import { MAIN_ROUTES } from 'router/routes';

export default function RegisterUserFooter() {
  const navigate = useNavigate();
  return (
    <div className="mb-24">
      <div className="mt-6">
        <div className="relative flex justify-center text-base leading-5">
          <hr className="flex-1 text-column-gray-400 mt-3 px-10" />
          <p className="flex-auto text-center text-column-gray-500 text-base">
            Already have an account?
          </p>
          <hr className="flex-1 text-column-gray-400 mt-3 px-10  " />
        </div>
      </div>
      <div className="mt-6">
        <ColumnButton
          tertiary
          onClick={() => navigate(includeSearchParams(MAIN_ROUTES.LOGIN))}
          buttonText="Back to Sign In"
          id="back-to-signin"
          fullWidth
          size="lg"
          type="button"
        />
      </div>
    </div>
  );
}
