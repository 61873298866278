import React from 'react';

type StackedLayoutProps = {
  children: React.ReactNode;
};

export default function StackedLayout({ children }: StackedLayoutProps) {
  return (
    <div className="w-screen max-w-9xl mx-auto py-4 px-8 mb-10 flex flex-col gap-4">
      {children}
    </div>
  );
}
