import { OrderModel } from 'lib/model/objects/orderModel';
import { Ad } from 'lib/types/ad';
import { PricePreview } from 'routes/ads/place/components/PricePreview/PricePreview';
import { ConsolidatedOrderPricing } from 'lib/types/order';
import { NewspaperOrderInfo } from './NewspaperOrderInfo';
import ProofPreview from './ProofPreview';
import { NewspaperOrdersFormData } from '../../../PlacementFlowStepSelector';

type TopActionBarProps<T extends Ad> = {
  adData: Partial<T>;
  consolidatedOrderPricing: ConsolidatedOrderPricing | undefined;
  order: OrderModel;
  version: number;
  newspaperOrdersFormData: NewspaperOrdersFormData;
  priceLoading: boolean;
  priceIsStale: boolean;
};

export function TopActionBar<T extends Ad>({
  adData,
  consolidatedOrderPricing,
  order,
  version,
  newspaperOrdersFormData,
  priceLoading,
  priceIsStale
}: TopActionBarProps<T>) {
  return (
    <div className="flex justify-between container mx-auto border border-column-gray-100 rounded-md p-2">
      <NewspaperOrderInfo
        newspaperOrderFormData={newspaperOrdersFormData}
        filingTypeName={adData.filingTypeName || ''}
      />
      <div className="flex justify-between md:justify-end w-full gap-8">
        <PricePreview
          consolidatedOrderPricing={consolidatedOrderPricing}
          loading={priceLoading}
          priceIsStale={priceIsStale}
        />
        <ProofPreview
          newspaperOrdersFormData={newspaperOrdersFormData}
          reloadingPreview={priceLoading}
          order={order}
          version={version}
        />
      </div>
    </div>
  );
}
