/* eslint-disable no-await-in-loop */
import React from 'react';
import moment from 'moment';
import api from 'api';
import { EOrganization, ESnapshot } from 'lib/types';
import { Badge, BadgeProps } from 'lib/components/Badge';
import { ColumnButton } from 'lib/components/ColumnButton';
import { InvoiceStatus } from 'lib/enums';
import {
  ArrowDownTrayIcon,
  CurrencyDollarIcon,
  EnvelopeIcon
} from '@heroicons/react/24/outline';
import { isPublisherOrganization } from 'lib/utils/organizations';
import { dbToUICurrencyString } from 'lib/pricing/ui';
import { isUnpaidSearchableInvoice } from 'lib/utils/invoices';
import { INVOICE_STATUSES_FUNDS_PENDING } from 'lib/model/objects/invoiceModel';
import { SearchableInvoiceRecord } from '../../../lib/types/searchable';
import { getInvoiceStatus } from './invoiceTableSettingsUtils';
import { EResponseTypes } from '../../../lib/types';

export const downloadInvoice = async (
  e: React.MouseEvent<SVGSVGElement, MouseEvent>,
  invoiceId: string,
  isBulkInvoice: boolean
) => {
  e.stopPropagation();
  const { response }: EResponseTypes['documents/invoice/:invoiceId/download'] =
    await api.get(
      `documents/${
        isBulkInvoice ? 'bulk-invoice' : 'invoice'
      }/${invoiceId}/download`
    );

  if (response) {
    window.open(response);
  }
};

export const getInvoiceBadgeStatus = (
  invoiceStatus: number,
  due_date: number,
  voided: number,
  amount: number | undefined
) => {
  let status: BadgeProps['status'] = 'critical';
  let color = 'red';
  // in case of invoice has no line items or amount is 0
  if (!amount) {
    status = 'success';
    color = 'green';
  } else if (INVOICE_STATUSES_FUNDS_PENDING.includes(invoiceStatus)) {
    status = 'warning';
    color = 'yellow';
  } else if (voided) {
    status = undefined;
    color = 'gray';
  } else if (
    invoiceStatus === InvoiceStatus.unpaid.value &&
    moment.utc(due_date * 1000).isBefore(moment.utc())
  ) {
    color = 'red';
    status = 'critical';
  } else if (invoiceStatus === InvoiceStatus.unpaid.value) {
    color = 'yellow';
    status = 'warning';
  } else if (
    [InvoiceStatus.paid.value, InvoiceStatus.partially_refunded.value].includes(
      invoiceStatus
    )
  ) {
    color = 'green';
    status = 'success';
  }

  return { status, color };
};

export function InvoicesTableRow({
  invoice,
  organization,
  setSendReminderModalInvoice,
  setProcessPaymentModalInvoice,
  usesBulkInvoiceV2
}: {
  invoice: SearchableInvoiceRecord;
  organization: ESnapshot<EOrganization> | null;
  setSendReminderModalInvoice: Function;
  setProcessPaymentModalInvoice: Function;
  usesBulkInvoiceV2: boolean;
}) {
  const isInvoiceUnpaid = isUnpaidSearchableInvoice(invoice);
  const invoiceBadgeStatus = getInvoiceBadgeStatus(
    invoice.status,
    moment.utc(invoice.formattedduedate, 'MM/DD/YYYY').unix(),
    invoice.voided,
    invoice.nettotal
  ).status;

  const invoiceRowStatus = getInvoiceStatus(
    invoice.status,
    moment.utc(invoice.formattedduedate, 'MM/DD/YYYY').unix(),
    invoice.voided,
    invoice.nettotal
  );

  if (isPublisherOrganization(organization)) {
    return (
      <>
        <td>
          <div className="flex items-center">
            <div className="text-sm font-medium">
              <div className="leading-6 text-column-gray-400">
                {invoice.filername}
              </div>
              <div className="text-column-gray-400">
                {invoice.invoicenumber}
              </div>
            </div>
          </div>
        </td>
        {!usesBulkInvoiceV2 ? (
          <td>
            <a
              href={`/notice/${invoice.noticeid}`}
              target="_blank"
              rel="noreferrer"
              className="text-blue-700 underline cursor-pointer"
            >
              {invoice.noticename}
            </a>
          </td>
        ) : (
          <></>
        )}
        <td>{invoice.filerorganizationname || ''}</td>
        <td>{moment.utc(invoice.created).format('MM/DD/YYYY')}</td>
        <td>${dbToUICurrencyString(invoice.nettotal)}</td>
        <td>
          <Badge status={invoiceBadgeStatus}>{invoiceRowStatus}</Badge>
        </td>
        <td>{invoice.formattedduedate}</td>
        <td className="flex items-center pt-4 gap-2">
          <ColumnButton
            tertiary
            aria-label="Send Reminder"
            size="sm"
            disabled={!isInvoiceUnpaid}
            onClick={() => {
              setSendReminderModalInvoice(invoice);
            }}
            startIcon={
              <EnvelopeIcon className="w-5 py-1 text-column-grey-400" />
            }
            type="button"
          />
          <ColumnButton
            tertiary
            aria-label="Process Payment"
            size="sm"
            disabled={!isInvoiceUnpaid}
            onClick={() => {
              setProcessPaymentModalInvoice(invoice);
            }}
            startIcon={
              <CurrencyDollarIcon className="w-5 py-1 text-column-grey-400" />
            }
            type="button"
          />
          <ColumnButton
            tertiary
            aria-label="Download Invoice"
            size="sm"
            onClick={(e: any) =>
              downloadInvoice(e, invoice.id, !!invoice.isbulkinvoice)
            }
            startIcon={
              <ArrowDownTrayIcon className="w-5 py-1 text-column-grey-400" />
            }
            type="button"
          />
        </td>
      </>
    );
  }
  return (
    <>
      <td>{moment.utc(invoice.created).format('MM/DD/YYYY')}</td>
      <td>{invoice.invoicenumber}</td>
      {!usesBulkInvoiceV2 ? (
        <td>
          <a
            href={`/notice/${invoice.noticeid}`}
            target="_blank"
            rel="noreferrer"
            className="text-blue-700 underline cursor-pointer"
          >
            {invoice.noticename}
          </a>
        </td>
      ) : (
        <></>
      )}
      <td>{invoice.publishername}</td>
      <td>${dbToUICurrencyString(invoice.nettotal)}</td>
      <td>
        <Badge status={invoiceBadgeStatus}>{invoiceRowStatus}</Badge>
      </td>
      <td>{invoice.formattedduedate}</td>
      <td className="flex items-center pt-5 justify-around text-align">
        <ColumnButton
          buttonText={'Pay'}
          secondary
          size="sm"
          onClick={e => {
            e.stopPropagation();
            window.open(`${window.location.origin}/invoices/${invoice.id}/pay`);
          }}
          disabled={!isInvoiceUnpaid}
          type="button"
        />
        <div>
          <ColumnButton
            tertiary
            id="download-report"
            size="sm"
            onClick={(e: any) =>
              downloadInvoice(e, invoice.id, !!invoice.isbulkinvoice)
            }
            startIcon={
              <ArrowDownTrayIcon className="w-5 py-1 text-column-grey-400" />
            }
            type="button"
          />
        </div>
      </td>
    </>
  );
}
