/**
 * Checks if two strings are very similar, ignoring casing and non-word characters.
 */
export const fuzzyStringMatch = (a: string, b: string) => {
  const aSimplified = a
    .toLocaleLowerCase()
    .trim()
    .replace(/[^a-z0-9\s]/gi, '')
    .replace(/\s/g, '');

  const bSimplified = b
    .toLocaleLowerCase()
    .trim()
    .replace(/[^a-z0-9\s]/gi, '')
    .replace(/\s/g, '');

  return aSimplified === bSimplified;
};

/**
 * Checks if one string contains (or nearly contains another), ignoring casing and non-word characters.
 */
export const fuzzyStringContains = (a: string, b: string) => {
  const aSimplified = a
    .toLocaleLowerCase()
    .trim()
    .replace(/[^a-z0-9\s]/gi, '')
    .replace(/\s/g, '');

  const bSimplified = b
    .toLocaleLowerCase()
    .trim()
    .replace(/[^a-z0-9\s]/gi, '')
    .replace(/\s/g, '');

  return aSimplified.indexOf(bSimplified) >= 0;
};

export const capitalizeWord = (word: string) => {
  return word.charAt(0).toLocaleUpperCase() + word.substring(1);
};

export const capitalizeWords = (words: string) => {
  const individualWords = words.toLowerCase().split(' ');
  return individualWords.map(capitalizeWord).join(' ');
};

export const applySentenceCase = (sentence: string) => {
  return sentence.charAt(0).toUpperCase() + sentence.substring(1).toLowerCase();
};

export const formatPhoneNumber = (
  phone: string | undefined,
  { digitsOnly = false }: { digitsOnly?: boolean } = {}
) => {
  if (!phone) {
    return '';
  }

  const digits = phone.replace(/\D/g, '');

  if (digits.length < 10) {
    return '';
  }

  if (digitsOnly) {
    return digits;
  }

  return digits.slice(0, 10).replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
};

export const decodeHtml = (html: string) => {
  return html
    .replace(/&amp;/g, '&')
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/&quot;/g, '"')
    .replace(/&rsquo;/g, "'")
    .replace(/&lsquo;/g, "'")
    .replace(/&ldquo;/g, '"')
    .replace(/&rdquo;/g, '"')
    .replace(/&#39;/g, "'")
    .replace(/&#96;/g, '`')
    .replace(/&mdash;/g, '—')
    .replace(/&ndash;/g, '–')
    .replace(/&hellip;/g, '…')
    .replace(/&copy;/g, '©')
    .replace(/&reg;/g, '®')
    .replace(/&trade;/g, '™')
    .replace(/&euro;/g, '€')
    .replace(/&pound;/g, '£')
    .replace(/&yen;/g, '¥')
    .replace(/&dollar;/g, '$')
    .replace(/&cent;/g, '¢')
    .replace(/&pound;/g, '£')
    .replace(/&nbsp;/g, ' ');
};
