import api from 'api';
import { OrganizationStatus } from 'lib/enums';
import { OrganizationTypeValue } from 'lib/enums/OrganizationType';
import {
  EOrganization,
  ERequestTypes,
  EResponseTypes,
  ESnapshot,
  ESnapshotExists,
  EUser
} from 'lib/types';
import { getCreateCustomerFn } from 'utils/callableFunctions';
import { getFirebaseContext } from 'utils/firebase';
import { updatePendingInviteStatusForAnonUsers } from '../helpers';

export type RegisterOrganizationAddress = {
  name: string;
  addressLine1: string;
  addressLine2?: string;
  city: string;
  state: number | undefined;
  zipCode: string;
  phone: string;
  email: string;
};

export const EMPTY_ADDRESS: RegisterOrganizationAddress = {
  name: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: undefined,
  zipCode: '',
  phone: '',
  email: ''
};

type CreateOrganizationParams = {
  organizationType: OrganizationTypeValue;
  name: string;
  address: string | undefined;
  addressLine2: string | undefined;
  city: string | undefined;
  state: number | undefined;
  zipCode: string | undefined;
  phone: string | undefined;
  organizationEmail: string | undefined;
  createdBy: string | undefined;
  stripeId?: string;
  userName: string;
  email: string;
};

export const createOrganization = async ({
  organizationType,
  name,
  address,
  addressLine2,
  city,
  state,
  zipCode,
  phone,
  organizationEmail,
  createdBy,
  stripeId,
  userName,
  email
}: CreateOrganizationParams) => {
  const organizationData: Partial<EOrganization> = {
    organizationType,
    organizationStatus: OrganizationStatus.not_applicable.value,
    name,
    address,
    addressLine2: addressLine2 || '',
    city,
    state,
    zipCode,
    phone,
    email: organizationEmail,
    createdBy,
    showInSearch: true
  };

  // Create stripe customer id for organization
  if (stripeId) {
    organizationData.stripeId = stripeId;
  } else {
    const createCustomer = getCreateCustomerFn();
    const result = await createCustomer({
      name: userName,
      email,
      firestoreUID: createdBy ?? ''
    });
    organizationData.stripeId = result.data.stripeId;
  }

  // TODO: better typing for different types of organizations
  // Had to as any here because this org is missing
  // fields like fullPageTemplate which is not relevant for a govt
  const newlyCreatedOrganization = await getFirebaseContext()
    .organizationsRef()
    .add(organizationData as EOrganization);

  return newlyCreatedOrganization;
};

export async function registerOrganizationAsUser({
  user,
  activeOrganization,
  organizationType,
  address
}: {
  user: ESnapshotExists<EUser>;
  activeOrganization: ESnapshot<EOrganization> | null;
  organizationType: OrganizationTypeValue;
  address: RegisterOrganizationAddress;
}) {
  const newlyCreatedOrganization = await createOrganization({
    organizationType,
    name: address.name,
    address: address.addressLine1,
    addressLine2: address.addressLine2,
    city: address.city,
    state: address.state,
    zipCode: address.zipCode,
    phone: address.phone,
    organizationEmail: address.email,
    createdBy: user.id,
    stripeId: user.data().stripeId || activeOrganization?.data()?.stripeId,
    userName: user.data().name,
    email: user.data().email
  });

  const reqBody: ERequestTypes['users/link-filer-to-org'] = {
    // if there is an active organization, skip the notice migration
    skipNoticeMigration: Boolean(activeOrganization),
    // link to the new organization
    organizationId: newlyCreatedOrganization.id,
    uid: user.id || ''
  };

  const resp: EResponseTypes['users/link-filer-to-org'] = await api.post(
    'users/link-filer-to-org',
    reqBody
  );

  if (!resp.success) {
    throw new Error(
      `Unable to link filer ${uid} to organization ${newlyCreatedOrganization.id}`
    );
  }

  await updatePendingInviteStatusForAnonUsers(getFirebaseContext(), user);
  await user.ref.update({
    postRegistrationComplete: true,
    activeOrganization: newlyCreatedOrganization
  });
}
