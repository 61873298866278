import React, { useState } from 'react';
import { ArrowDownIcon } from '@heroicons/react/24/solid';
import api from 'api';
import { Alert } from 'lib/components/Alert';
import { ColumnButton } from 'lib/components/ColumnButton';
import { Form } from 'lib/components/Form';
import { Modal } from 'lib/components/Modal';
import { TextField } from 'lib/components/TextField';
import moment from 'moment';
import { selectActiveOrganization } from 'redux/auth';
import { useAppSelector } from 'redux/hooks';

export function UniversalDownloadForm() {
  const activeOrganization = useAppSelector(selectActiveOrganization);
  const [date, setDate] = useState(moment().format('YYYY-MM-DD'));
  const [loading, setLoading] = useState(false);
  const [showDownloadForm, setShowDownloadForm] = useState(false);

  const downloadForm = async () => {
    if (!activeOrganization?.id) {
      return;
    }
    setLoading(true);
    const { response, error } = await api.safePost(
      'pagination/universal-download',
      {
        publisherId: activeOrganization?.id,
        date
      }
    );
    setLoading(false);
    if (error) {
      return;
    }
    window.open(response?.downloadUrl, '_blank');
  };

  return (
    <div className="w-full mb-8">
      {showDownloadForm && (
        <Modal
          id="universal-download-form-modal"
          title="Universal Download"
          onClose={() => setShowDownloadForm(false)}
        >
          <Form
            id="universal-download-form"
            onSubmit={() => {
              void downloadForm();
            }}
          >
            <div className="flex flex-col gap-4">
              <TextField
                value={date}
                onChange={setDate}
                id="universal-download-form-date"
                placeholder="YYYY-MM-DD"
                required
                labelText="Download Date"
                errorText={
                  moment(date, 'YYYY-MM-DD').isValid() ? '' : 'Invalid date'
                }
              />
              <div className="flex justify-end flex-row">
                <ColumnButton
                  fullWidth
                  primary
                  buttonText="Download"
                  id="universal-download-form-download"
                  type="submit"
                  loading={loading}
                />
              </div>
            </div>
          </Form>
        </Modal>
      )}
      <Alert
        title="Universal Download"
        description={
          <div className="flex flex-row justify-between items-center">
            <span>
              Download all orders for a given date. Note this is only available
              for Column users
            </span>
            <ColumnButton
              buttonText="Download"
              id="universal-download-form-download"
              onClick={() => setShowDownloadForm(true)}
              type="button"
              startIcon={<ArrowDownIcon className="w-4 h-4" />}
              loading={loading}
            />
          </div>
        }
        id="universal-download-form-alert"
        status="success"
      />
    </div>
  );
}
