import { useNavigate } from 'react-router-dom';
import { exists } from 'lib/types';
import { Form } from 'lib/components/Form';
import { Modal } from 'lib/components/Modal';
import { useState } from 'react';
import AuthActions, {
  selectActiveOrganization,
  selectUser,
  selectUserAuth
} from 'redux/auth';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import ToastActions from 'redux/toast';
import { REGISTER_ORGANIZATION_ROUTES, REGISTER_ROUTES } from 'router/routes';
import { organizationAlreadyExists } from 'routes/register/helpers';
import { logAndCaptureException, logAndCaptureMessage } from 'utils';

import { ColumnService } from 'lib/services/directory';
import { OrganizationTypeValue } from 'lib/enums/OrganizationType';
import AdvertiserRegistrationFooter from '../AdvertiserRegistrationFooter';
import RegisterOrganizationAddressForm from './RegisterOrganizationAddressForm';
import RegisterOrganizationAlreadyExists from './RegisterOrganizationAlreadyExists';
import {
  EMPTY_ADDRESS,
  RegisterOrganizationAddress,
  registerOrganizationAsUser
} from './createOrganization';

type OrganizationRegistrationProps = {
  organizationType: OrganizationTypeValue;
};

export default function OrganizationRegistration({
  organizationType
}: OrganizationRegistrationProps) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const userAuth = useAppSelector(selectUserAuth);
  const activeOrganization = useAppSelector(selectActiveOrganization);

  const [address, setAddress] = useState<RegisterOrganizationAddress>({
    ...EMPTY_ADDRESS,
    email: user?.data().email || ''
  });
  const [loading, setLoading] = useState(false);
  const [showCancelWarning, setShowCancelWarning] = useState(false);
  const [alreadyExists, setAlreadyExists] = useState(false);

  const goBackLink = () => {
    navigate(REGISTER_ROUTES.OCCUPATIONS);
  };

  const handleSubmit = async () => {
    if (!exists(user)) {
      return logAndCaptureMessage('User does not exist');
    }

    setLoading(true);

    try {
      const orgAlreadyExists = await organizationAlreadyExists(
        organizationType,
        address.name
      );

      if (orgAlreadyExists) {
        setAlreadyExists(true);
        return setLoading(false);
      }

      await registerOrganizationAsUser({
        organizationType,
        address,
        user,
        activeOrganization
      });

      dispatch(AuthActions.register());
      navigate(REGISTER_ORGANIZATION_ROUTES.POST_REGISTRATION);
    } catch (e) {
      logAndCaptureException(
        ColumnService.AUTH_AND_USER_MANAGEMENT,
        e,
        'Failure when creating organization',
        {
          userId: userAuth?.uid
        }
      );
      dispatch(
        ToastActions.toastError({
          headerText: 'Error creating organization',
          bodyText:
            'There was an error creating your organization. Please try again.'
        })
      );
      return setLoading(false);
    }
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <div className="bg-white rounded-md p-8 shadow-2 border">
          <RegisterOrganizationAddressForm
            address={address}
            handleInputChanged={setAddress}
          />
        </div>
        <AdvertiserRegistrationFooter
          id="submit-organization"
          backButtonText="Go back"
          nextButtonText="Create organization"
          onBackButtonClick={() => setShowCancelWarning(true)}
          loading={loading}
        />
      </Form>
      {showCancelWarning && (
        <Modal
          title={'Are you sure?'}
          id={'exit-confirmation'}
          onClose={() => setShowCancelWarning(false)}
          primaryAction={{
            buttonText: 'Yes, continue',
            type: 'button',
            onClick: () => {
              setShowCancelWarning(false);
              goBackLink();
            }
          }}
          secondaryActions={[
            {
              buttonText: 'Cancel',
              type: 'button',
              onClick: () => {
                setShowCancelWarning(false);
              }
            }
          ]}
        >
          If you go back now, your progress will be lost. Please click "Yes,
          continue" to confirm.
        </Modal>
      )}
      {alreadyExists && (
        <RegisterOrganizationAlreadyExists
          name={address.name}
          onBackClicked={() => setAlreadyExists(false)}
        />
      )}
    </>
  );
}
