import React from 'react';
import { TableLayout } from 'lib/components/TableLayout';
import { SearchableNewspaperOrderRecord } from 'lib/types/searchable';
import { ObituariesOverviewRow } from './ObituariesOverviewRow';

type ObituariesOverviewProps = {
  obituaries: SearchableNewspaperOrderRecord[];
  loading: boolean;
};
export function ObituariesOverviewTable(props: ObituariesOverviewProps) {
  const columns = [
    'Title',
    'Customer Name',
    'Deceased Name',
    'Funeral Home',
    'View'
  ];

  const { obituaries, loading } = props;
  return (
    <div id="obituaries-table" className="overflow-scroll">
      <TableLayout
        filterable={{
          searchEnabled: false,
          shouldShowTableItem: () => true
        }}
        loading={loading}
        renderRow={obituary => <ObituariesOverviewRow obituary={obituary} />}
        header={{
          title: 'Obituaries',
          subtitle: 'All obituaries for this publication issue'
        }}
        columns={columns}
        data={obituaries}
        pagination={{ pageSize: 15 }}
      />
    </div>
  );
}
