import RouterTabs from 'lib/components/RouterTabs';
import { Navigate, useLocation, useSearchParams } from 'react-router-dom';
import {
  ORGANIZATION_SETTINGS_ROUTE,
  ORGANIZATION_SETTINGS_ROUTES
} from 'router/routes';
import { LEGACY_TAB_TO_ROUTE_MAP } from './legacy';
import { useValidOrganizationSettingsTabs } from './useValidOrganizationSettingsTab';

function OrganizationSettings() {
  const { pathname } = useLocation();
  const [params] = useSearchParams();

  const validTabs = useValidOrganizationSettingsTabs();

  const atRelativeRoot = pathname === ORGANIZATION_SETTINGS_ROUTE;
  const userCanViewTab = !!validTabs.find(t => t.path === pathname)?.enabled;

  const needsRedirect = atRelativeRoot || !userCanViewTab;

  if (needsRedirect) {
    const legacyTab = params.get('tab');
    const firstValidTab = validTabs.find(t => t.enabled);

    let redirectTo = legacyTab
      ? LEGACY_TAB_TO_ROUTE_MAP[legacyTab]
      : firstValidTab
      ? firstValidTab.path
      : ORGANIZATION_SETTINGS_ROUTES.MEMBERS;

    params.delete('tab');

    if (params.size > 0) {
      redirectTo = `${redirectTo}?${params.toString()}`;
    }

    return <Navigate to={redirectTo} replace />;
  }

  return (
    <RouterTabs
      tabs={validTabs}
      id="organization-settings-sidebar-tabs"
      hideDisabled
      sidebar
    />
  );
}

export default OrganizationSettings;
