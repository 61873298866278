import { useState } from 'react';

import { EOrganization, ESnapshotExists } from 'lib/types';
import { FilingType } from 'lib/types/filingType';

import { Product } from 'lib/enums';
import { ArchivableTableProps } from 'lib/components/TableLayout/types';
import NoticeTypeGlobalSettings from './noticeTypeGlobalSettings';
import FilingTypeUpdateForm from './filingTypeUpdateForm';
import FilingTypeTable from './filingTypeTable';
import FilingTypeDrawer from './filingTypeDrawer';

type FilingTypesSettingsProps<T extends FilingType> = {
  activeOrganization: ESnapshotExists<EOrganization>;
  filingTypes: T[];
  archivable?: ArchivableTableProps<T>;
  creatable?: { createButtonText: string; onCreate: () => T };
  onSave: (filingType: T) => Promise<void>;
  productLine: Product;
};

/**
 * Wrapper component for the Notice Types settings page.
 */
export default function FilingTypesSettings<T extends FilingType>({
  activeOrganization,
  filingTypes,
  archivable,
  creatable,
  onSave,
  productLine
}: FilingTypesSettingsProps<T>) {
  const [editedFilingType, setEditedFilingType] = useState<T>();
  const [drawerFilingType, setDrawerFilingType] = useState<FilingType>();
  const [showGlobalControls, setShowGlobalControls] = useState(false);

  if (showGlobalControls) {
    return (
      <NoticeTypeGlobalSettings
        activeOrganization={activeOrganization}
        onClose={() => setShowGlobalControls(false)}
      />
    );
  }

  if (editedFilingType) {
    const isNewFilingType = filingTypes.every(
      existingFilingType => existingFilingType.label !== editedFilingType.label
    );

    return (
      <FilingTypeUpdateForm<T>
        activeOrganization={activeOrganization}
        onClose={() => {
          setEditedFilingType(undefined);
        }}
        filingType={editedFilingType}
        onSave={onSave}
        productLine={productLine}
        isNewFilingType={isNewFilingType}
      />
    );
  }

  return (
    <>
      <FilingTypeTable<T>
        onEditFilingType={setEditedFilingType}
        onClickGlobalControls={() => setShowGlobalControls(true)}
        onViewFilingType={setDrawerFilingType}
        activeOrganization={activeOrganization}
        filingTypes={filingTypes}
        archivable={archivable}
        creatable={
          creatable
            ? {
                ...creatable,
                onCreate: () => {
                  const newFilingType = creatable.onCreate();

                  setEditedFilingType(newFilingType);
                }
              }
            : undefined
        }
        productLine={productLine}
      />
      {drawerFilingType && (
        <FilingTypeDrawer
          onEditFilingType={type => setEditedFilingType(type as T)}
          filingType={drawerFilingType}
          onClose={() => setDrawerFilingType(undefined)}
        />
      )}
    </>
  );
}
