import { useBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import FileUpload from 'lib/components/FileUpload';
import Firebase from 'EnoticeFirebase';
import ClipArtLibrary from 'routes/settings/publisher/generalOrderSettings/ClipArtSettings/ClipArtLibrary';
import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';
import { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { selectActiveOrganization, selectUser } from 'redux/auth';
import LoadingState from 'components/LoadingState';
import { ColumnService } from 'lib/services/directory';
import { Product } from 'lib/enums';
import { FileWithUploadRef } from 'lib/frontend/hooks/useFirebaseStorageUpload';
import ToastActions from 'redux/toast';
import { handleFileMetadataUpload } from './ClipArtLibrary/clipArtHelpers';

const getClipArtStoragePathForOrganization = (organizationId: string) =>
  `documentcloud/clipArt/${organizationId}`;

const storage = Firebase.storage();

type ClipArtSettingsProps = {
  product: Product;
};

function ClipArtSettings({ product }: ClipArtSettingsProps) {
  const dispatch = useAppDispatch();
  const useColumnCDN = useBooleanFlag(LaunchDarklyFlags.ENABLE_COLUMN_CDN);
  const activeOrganization = useAppSelector(selectActiveOrganization);
  const user = useAppSelector(selectUser);
  const [recentlyUploaded, setRecentlyUploaded] = useState(new Array<string>());

  if (!activeOrganization) {
    return (
      <LoadingState
        context={{
          location: window.location.pathname,
          tags: { card: 'ClipArtSettings' },
          service: ColumnService.SETTINGS_MANAGEMENT
        }}
      />
    );
  }

  async function onFileUpload(filesAndRefs: FileWithUploadRef[]) {
    if (!activeOrganization || !user) {
      return;
    }

    const [error] = await handleFileMetadataUpload({
      filesAndRefs,
      product,
      activeOrganization: activeOrganization.ref,
      user: user.ref,
      useColumnCDN
    });

    if (error) {
      return dispatch(
        ToastActions.toastError({
          headerText: 'Error uploading files',
          bodyText: 'One or more files failed to upload. Please try again.'
        })
      );
    }

    setRecentlyUploaded([
      ...recentlyUploaded,
      ...filesAndRefs.map(o => o.uploadRef.fullPath)
    ]);
  }

  return (
    <CardGridLayout
      header={{
        title: 'Media Library',
        description:
          'Upload and manage library of images available when creating orders'
      }}
    >
      <GridInput fullWidth>
        <div className="flex flex-col gap-2">
          <FileUpload
            id="clip-art-upload"
            uploadFolder={getClipArtStoragePathForOrganization(
              activeOrganization.id
            )}
            storage={storage}
            onFileUpload={onFileUpload}
          />
          <ClipArtLibrary
            organizationId={activeOrganization.id}
            folder={product}
            uploads={recentlyUploaded}
          />
        </div>
      </GridInput>
    </CardGridLayout>
  );
}

export default ClipArtSettings;
