import React from 'react';
import { EOrganization, ESnapshotExists, exists } from 'lib/types';
import { Autocomplete } from 'lib/components/Autocomplete';
import { ColumnService } from 'lib/services/directory';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { getErrorReporter } from 'lib/utils/errors';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { Alert } from 'lib/components/Alert';
import {
  fetchAdjudicationAreas,
  groupAdjudicationAreasByParent
} from 'routes/settings/publisher/publisherSettings/AdjudicationInfo/helper';
import { SearchableOrganizationRecord } from 'lib/types/searchable';
import { formatOptions } from '../SelectPublisherDropdown';

type SelectAdjudicatedPublisherDropdownProps = {
  publisherOrganization: ESnapshotExists<EOrganization> | undefined;
  onPublisherOrganizationChange: (publisherOrganizationId: string) => void;
  publisherOptions: SearchableOrganizationRecord[];
  loading: boolean;
};

export function SelectAdjudicatedPublisherDropdown({
  publisherOrganization,
  onPublisherOrganizationChange,
  publisherOptions,
  loading
}: SelectAdjudicatedPublisherDropdownProps) {
  const formattedPublisherOptions = publisherOptions.map(formatOptions);

  const {
    value: noteText,
    isLoading: loadingText,
    isError: error
  } = useAsyncEffect({
    fetchData: async () => {
      const result = await getPublisherLocationNoteText(publisherOrganization);
      return result;
    },
    dependencies: [publisherOrganization?.id],
    errorConfig: {
      message: 'Failed to load adjudication areas for SelectPublisherDropdown',
      service: ColumnService.WEB_PLACEMENT,
      tags: {
        activeOrganizationId: publisherOrganization?.id || ''
      }
    }
  });

  if (error) {
    <Alert
      id="publisher-dropdown-naming-error"
      status="error"
      icon={<InformationCircleIcon className="w-5 h-5" />}
      title="Error when retrieving adjudication areas name"
    />;
  }

  return (
    <Autocomplete
      id="selectPublisher"
      labelText="Publisher"
      noteText={!loading && !loadingText ? noteText : ''}
      placeholder="Select a publisher"
      value={
        exists(publisherOrganization) && !loading
          ? publisherOrganization.id
          : ''
      }
      options={formattedPublisherOptions}
      onChange={onPublisherOrganizationChange}
      loading={loading}
      noOptionsMessage="No results. Please start a new search or update your filters"
    />
  );
}

async function getPublisherLocationNoteText(
  publisherOrganization: ESnapshotExists<EOrganization> | undefined
) {
  if (!publisherOrganization) {
    getErrorReporter().logAndCaptureWarning(
      'No organization selected/found in notice placement'
    );
    return;
  }
  const adjudicationAreas = await fetchAdjudicationAreas(publisherOrganization);
  const grouped = await groupAdjudicationAreasByParent(adjudicationAreas);

  let areaLabel = '';

  Object.keys(grouped).forEach(key => {
    const countiesFromState = Object.keys(grouped[key].counties);
    if (countiesFromState.length > 0) {
      const countyNames = countiesFromState.map(
        countyKey => grouped[key].counties[countyKey].name
      );

      areaLabel =
        areaLabel.length === 0
          ? `${countyNames.join(', ')} ${grouped[key].name}`
          : `${areaLabel} and ${countyNames.join(', ')} ${grouped[key].name}`;
    }
  });

  const noteText = areaLabel
    ? `This newspaper publishes in ${areaLabel}.`
    : undefined;

  return noteText;
}
