import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import { ColumnButton } from 'lib/components/ColumnButton';
import { OrderModel } from 'lib/model/objects/orderModel';
import { PRODUCT_TO_ROUTE } from 'router/routes';

type OrderDetailBackButtonProps = {
  order: OrderModel;
};

export function OrderDetailBackButton({ order }: OrderDetailBackButtonProps) {
  const navigate = useNavigate();

  return (
    <ColumnButton
      type="button"
      link
      startIcon={
        <ArrowLeftIcon className="h-4 w-4 stroke-2 text-column-gray-400 hover:text-column-primary-600" />
      }
      onClick={() => navigate(PRODUCT_TO_ROUTE[order.modelData.product])}
      aria-label="Back"
    />
  );
}
