import React from 'react';
import { useNavigate } from 'react-router-dom';

import { REGISTER_ROUTES } from 'router/routes';
import { useAppSelector } from 'redux/hooks';
import { selectUser } from 'redux/auth';
import LoadingState from 'components/LoadingState';
import { exists } from 'lib/types';
import JoinOrCreateNewOrganization from './joinOrCreateNewOrganization';

export default function JoinOrCreateNewOrganizationRouter() {
  const navigate = useNavigate();
  const user = useAppSelector(selectUser);

  const onBackClick = () => {
    navigate(REGISTER_ROUTES.OCCUPATIONS);
  };

  if (!exists(user)) return <LoadingState />;

  return (
    <JoinOrCreateNewOrganization
      occupationType={user.data().occupation}
      onBackClick={onBackClick}
    />
  );
}
