import CurrencyTextField from 'lib/components/CurrencyTextField';
import { SwitchControlledCard } from 'lib/components/Card/SwitchControlledCard';
import { useState } from 'react';
import { AffidavitReconciliationSettings } from 'lib/types/organization';
import { useAppSelector } from 'redux/hooks';
import { selectActiveOrganization } from 'redux/auth';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { exists } from 'lib/types';
import { getInheritedProperty } from 'lib/utils/inheritance';
import { GlobalAffidavitControls } from './globalAffidavitControlHelpers';

type AffidavitFeeSplitSettingsCardProps = {
  onChangeGlobalAffidavitControls: (
    newControls: GlobalAffidavitControls
  ) => void;
  globalAffidavitControls: GlobalAffidavitControls;
  affidavitReconciliationSettings: AffidavitReconciliationSettings;
};

export default function AffidavitFeeSplitSettingsCard({
  onChangeGlobalAffidavitControls,
  globalAffidavitControls,
  affidavitReconciliationSettings
}: AffidavitFeeSplitSettingsCardProps) {
  const [feeSplitAmount, setFeeSplitAmount] = useState(
    affidavitReconciliationSettings?.affidavitFeeSplit?.feeSplit.amount ?? 0
  );
  const [feeSplitAmountErrorText, setFeeSplitAmountErrorText] = useState('');

  const activeOrganization = useAppSelector(selectActiveOrganization);

  const { value: payColumnInvoicesEnabled, isLoading: loadingStripeOrg } =
    useAsyncEffect({
      fetchData: async (): Promise<boolean | undefined> => {
        if (!exists(activeOrganization)) return undefined;
        const payColumnInvoices = await getInheritedProperty(
          activeOrganization.ref,
          'payColumnInvoices'
        );
        return payColumnInvoices?.enabled;
      },
      dependencies: [activeOrganization?.id]
    });

  const handleFeeSplitAmountChange = (newValue: number) => {
    if (
      newValue >
      (affidavitReconciliationSettings?.automatedAffidavitFeeInCents ?? 0)
    ) {
      setFeeSplitAmountErrorText(
        `Cannot exceed fee amount ($${(
          (affidavitReconciliationSettings?.automatedAffidavitFeeInCents ?? 0) /
          100
        ).toFixed(2)})`
      );
    } else {
      setFeeSplitAmount(newValue);
      setFeeSplitAmountErrorText('');
      onChangeGlobalAffidavitControls({
        ...globalAffidavitControls,
        affidavitReconciliationSettings: {
          ...affidavitReconciliationSettings,
          affidavitFeeSplit: {
            feeSplit: {
              type: 'flat',
              amount: newValue
            },
            source: 'newspaper'
          }
        }
      });
    }
  };

  const switchDescription = payColumnInvoicesEnabled
    ? 'This setting should only be changed with explicit confirmation from the Column Customer Success Leader.'
    : `This newspaper is not currently set up to support fee splits. If you need to set or edit fee splits, please enable "Pay Column monthly invoices?" in Column Admin on either this paper or its parent org -- confirm with the Column Customer Success Leader before doing so. To enable "Pay Column monthly invoices?", find this newspaper (or its parent org) in Column Admin, then go to General Settings > Stripe Settings and then (1) add a Stripe Connect ID for this publisher and (2) enable "Pay Column monthly invoices?".`;
  return (
    <SwitchControlledCard
      labelProps={{
        label: `Should a publisher fee split apply to Column's affidavit automation fee?`,
        description: switchDescription,
        value: !!affidavitReconciliationSettings.affidavitFeeSplit,
        onChange: newValue => {
          if (!newValue) {
            onChangeGlobalAffidavitControls({
              ...globalAffidavitControls,
              affidavitReconciliationSettings: {
                ...affidavitReconciliationSettings,
                affidavitFeeSplit: undefined
              }
            });
          } else {
            onChangeGlobalAffidavitControls({
              ...globalAffidavitControls,
              affidavitReconciliationSettings: {
                ...affidavitReconciliationSettings,
                affidavitFeeSplit: {
                  feeSplit: {
                    type: 'flat',
                    amount:
                      affidavitReconciliationSettings?.affidavitFeeSplit
                        ?.feeSplit.amount ?? 0
                  },
                  source: 'newspaper'
                }
              }
            });
          }
        }
      }}
      header="Fee Split Detail Settings"
      disabled={!payColumnInvoicesEnabled || loadingStripeOrg}
    >
      {!!affidavitReconciliationSettings.affidavitFeeSplit && (
        <CurrencyTextField
          id="flat-fee-split-amount"
          labelText="What flat amount should the publisher pay?"
          initialValue={feeSplitAmount}
          placeholder={`Enter a value in $`}
          max={affidavitReconciliationSettings?.automatedAffidavitFeeInCents}
          errorText={feeSplitAmountErrorText}
          onChange={handleFeeSplitAmountChange}
          required
        />
      )}
    </SwitchControlledCard>
  );
}
