import { NoticeTypeData } from 'lib/enums/NoticeType';
import { DataWithNoticeType } from 'lib/helpers';
import { PublishingSettingModel } from 'lib/model/objects/publishingSettingModel';
import { EOrganization, ESnapshotExists, EUser } from 'lib/types';
import {
  disableNonPublishingDays,
  getIsAfterPublishingDeadline,
  isAllowedPublicationDay,
  publishingDayEnumValuesFromDeadlines
} from 'lib/utils/deadlines';
import { isPublisher } from 'lib/utils/users';

export type shouldDisablePublishingDateParams = {
  day: Date;
  publishingSetting: PublishingSettingModel;
  newspaper: ESnapshotExists<EOrganization>;
  user: ESnapshotExists<EUser> | null;
};
/**
 * Checks if the given date should be disabled in the confirm schedule step
 */
export const shouldDisablePublishingDate = ({
  day,
  publishingSetting,
  newspaper,
  user
}: shouldDisablePublishingDateParams): boolean => {
  const { iana_timezone } = newspaper.data();
  const { deadlines, deadlineOverrides = {} } =
    publishingSetting.modelData ?? {};
  if (!deadlines || !day || !iana_timezone) {
    return true;
  }

  const isNonPublishingDay = disableNonPublishingDays(
    day,
    publishingDayEnumValuesFromDeadlines(deadlines),
    deadlineOverrides,
    iana_timezone
  );

  const adTypeData: DataWithNoticeType = {
    noticeType: NoticeTypeData.other.value
  };

  const isAfterDeadline = getIsAfterPublishingDeadline(
    day,
    deadlines,
    deadlineOverrides,
    iana_timezone,
    adTypeData,
    newspaper
  );
  const userIsPublisher = user && isPublisher(user);
  const shouldDisableDueToDeadline = isAfterDeadline && !userIsPublisher;

  return (
    shouldDisableDueToDeadline ||
    !isAllowedPublicationDay(day, null) ||
    isNonPublishingDay
  );
};
