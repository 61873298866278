import { ProductPublishingSettingModel } from 'lib/model/objects/productPublishingSettingModel';
import { selectUser } from 'redux/auth';
import { useAppSelector } from 'redux/hooks';
import { userIsSuper } from 'utils/permissions';
import ClipArtSettings from './ClipArtSettings';
import PublishingMediumsForm from './PublishingMediumsForm';

type GeneralOrderSettingsProps = {
  productPublishingSetting: ProductPublishingSettingModel;
};

export function GeneralOrderSettings({
  productPublishingSetting
}: GeneralOrderSettingsProps) {
  const user = useAppSelector(selectUser);

  return (
    <>
      <PublishingMediumsForm
        productPublishingSetting={productPublishingSetting}
      />
      {user && userIsSuper(user) && (
        <ClipArtSettings product={productPublishingSetting.modelData.product} />
      )}
    </>
  );
}
