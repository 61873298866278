import { AllowedLayout, Filler } from '../../types/publisherPaginationSettings';
import { SpacerData } from '../../enums/Spacer';

export const LEE_DEFAULT_PAGINATION_FILLERS: Filler[] = [
  {
    id: `${SpacerData.notice.thick.prefix}-3`,
    name: 'Three Col Legals Pill',
    pdfUrl:
      'https://enotice-production.imgix.net/custom-documents%2Fpermalink%2F9a86.d90a2-thick_header.jpg',
    columns: 3,
    height: 50,
    maxOccurrences: 1
  },
  {
    id: `${SpacerData.notice.thick.prefix}-2`,
    name: 'Two Col Legals Pill',
    pdfUrl:
      'https://enotice-production.imgix.net/custom-documents%2Fpermalink%2F9a86.d90a2-thick_header.jpg',
    columns: 2,
    height: 30,
    maxOccurrences: 2
  },
  {
    id: `${SpacerData.notice.thick.prefix}-1`,
    name: 'One Col Legals Pill',
    pdfUrl:
      'https://enotice-production.imgix.net/custom-documents%2Fpermalink%2F9a86.d90a2-thick_header.jpg',
    columns: 1,
    height: 20,
    maxOccurrences: 2
  },
  {
    id: `${SpacerData.notice.super_skinny.prefix}-1`,
    name: 'Three Col Legals Pill super skinny',
    pdfUrl:
      'https://enotice-production.imgix.net/custom-documents%2Fpermalink%2F1169.f2e48-3-col-spacer.jpg',
    columns: 3,
    height: 10,
    maxOccurrences: 2
  },
  {
    id: `${SpacerData.notice.super_skinny.prefix}-2`,
    name: 'Two Col Legals Pill super skinny',
    pdfUrl:
      'https://enotice-production.imgix.net/custom-documents%2Fpermalink%2F1169.f2e48-3-col-spacer.jpg',
    columns: 2,
    height: 10,
    maxOccurrences: 2
  }
];

export const LEE_DEFAULT_PAGINATION_LAYOUTS: AllowedLayout[] = [
  {
    columns: 1,
    heightPixels: 180,
    layoutHeader: {
      headerId: 'skinny-spacer',
      width: 'column'
    },
    id: '1',
    fillers: LEE_DEFAULT_PAGINATION_FILLERS
  },
  {
    columns: 1,
    heightPixels: 360,
    layoutHeader: {
      headerId: 'skinny-spacer',
      width: 'column'
    },
    id: '2',
    fillers: LEE_DEFAULT_PAGINATION_FILLERS
  },
  {
    columns: 1,
    heightPixels: 480,
    layoutHeader: {
      headerId: 'skinny-spacer',
      width: 'column'
    },
    id: '3',
    fillers: LEE_DEFAULT_PAGINATION_FILLERS
  },
  {
    columns: 1,
    heightPixels: 540,
    layoutHeader: {
      headerId: 'skinny-spacer',
      width: 'column'
    },
    id: '3',
    fillers: LEE_DEFAULT_PAGINATION_FILLERS
  },
  {
    columns: 1,
    heightPixels: 600,
    layoutHeader: {
      headerId: 'skinny-spacer',
      width: 'column'
    },
    id: '4',
    fillers: LEE_DEFAULT_PAGINATION_FILLERS
  },
  {
    columns: 1,
    heightPixels: 720,
    layoutHeader: {
      headerId: 'skinny-spacer',
      width: 'column'
    },
    id: '5',
    fillers: LEE_DEFAULT_PAGINATION_FILLERS
  },
  {
    columns: 1,
    heightPixels: 1080,
    layoutHeader: {
      headerId: 'skinny-spacer',
      width: 'column'
    },
    id: '6',
    fillers: LEE_DEFAULT_PAGINATION_FILLERS
  },
  {
    columns: 2,
    heightPixels: 720,
    layoutHeader: {
      headerId: 'skinny-spacer',
      width: 'column'
    },
    id: '7',
    fillers: LEE_DEFAULT_PAGINATION_FILLERS
  },
  {
    columns: 2,
    heightPixels: 900,
    layoutHeader: {
      headerId: 'skinny-spacer',
      width: 'column'
    },
    id: '7a',
    fillers: LEE_DEFAULT_PAGINATION_FILLERS
  },
  {
    columns: 2,
    heightPixels: 1080,
    layoutHeader: {
      headerId: 'skinny-spacer',
      width: 'column'
    },
    id: '8',
    fillers: LEE_DEFAULT_PAGINATION_FILLERS
  },
  {
    columns: 2,
    heightPixels: 1200,
    layoutHeader: {
      headerId: 'skinny-spacer',
      width: 'column'
    },
    id: '8b',
    fillers: LEE_DEFAULT_PAGINATION_FILLERS
  },
  {
    columns: 2,
    heightPixels: 1320,
    layoutHeader: {
      headerId: 'skinny-spacer',
      width: 'column'
    },
    id: '9b',
    fillers: LEE_DEFAULT_PAGINATION_FILLERS
  },
  {
    columns: 2,
    heightPixels: 1440,
    layoutHeader: {
      headerId: 'skinny-spacer',
      width: 'column'
    },
    id: '9c',
    fillers: LEE_DEFAULT_PAGINATION_FILLERS
  },
  {
    columns: 2,
    heightPixels: 1680,
    layoutHeader: {
      headerId: 'skinny-spacer',
      width: 'column'
    },
    id: '9d',
    fillers: LEE_DEFAULT_PAGINATION_FILLERS
  },
  {
    columns: 2,
    heightPixels: 1920,
    layoutHeader: {
      headerId: 'skinny-spacer',
      width: 'column'
    },
    id: '9e',
    fillers: LEE_DEFAULT_PAGINATION_FILLERS
  },
  {
    columns: 2,
    heightPixels: 2100,
    layoutHeader: {
      headerId: 'skinny-spacer',
      width: 'column'
    },
    id: '9f',
    fillers: LEE_DEFAULT_PAGINATION_FILLERS
  },
  {
    columns: 3,
    heightPixels: 480,
    layoutHeader: {
      headerId: 'skinny-spacer',
      width: 'column'
    },
    id: '9a',
    fillers: LEE_DEFAULT_PAGINATION_FILLERS
  },
  {
    columns: 3,
    heightPixels: 600,
    layoutHeader: {
      headerId: 'skinny-spacer',
      width: 'column'
    },
    id: '9b',
    fillers: LEE_DEFAULT_PAGINATION_FILLERS
  },
  {
    columns: 3,
    heightPixels: 720,
    layoutHeader: {
      headerId: 'skinny-spacer',
      width: 'column'
    },
    id: '9',
    fillers: LEE_DEFAULT_PAGINATION_FILLERS
  },
  {
    columns: 3,
    heightPixels: 900,
    layoutHeader: {
      headerId: 'skinny-spacer',
      width: 'column'
    },
    id: '9g',
    fillers: LEE_DEFAULT_PAGINATION_FILLERS
  },
  {
    columns: 3,
    heightPixels: 1080,
    layoutHeader: {
      headerId: 'skinny-spacer',
      width: 'column'
    },
    id: '9h',
    fillers: LEE_DEFAULT_PAGINATION_FILLERS
  },
  {
    columns: 3,
    heightPixels: 1080,
    layoutHeader: {
      headerId: 'skinny-spacer',
      width: 'column'
    },
    id: '10',
    fillers: LEE_DEFAULT_PAGINATION_FILLERS
  },
  {
    columns: 4,
    heightPixels: 144,
    layoutHeader: {
      headerId: 'skinny-spacer',
      width: 'column'
    },
    id: '11',
    fillers: LEE_DEFAULT_PAGINATION_FILLERS
  },
  {
    columns: 4,
    heightPixels: 300,
    layoutHeader: {
      headerId: 'skinny-spacer',
      width: 'column'
    },
    id: '11',
    fillers: LEE_DEFAULT_PAGINATION_FILLERS
  }
];

export const LEE_DEFAULT_PAGINATION_HEADERS = [
  {
    id: 'skinny-spacer',
    name: 'Legals Header',
    url: 'https://enotice-production.imgix.net/custom-documents%2Fpermalink%2Fd600.7a390-skinny_header.jpg',
    dimensions: { width: 928, height: 127 }
  }
];
