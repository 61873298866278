import { PublicationIssueSection } from '../../types/publicationIssueSection';
import { SnapshotModel, getModelFromRef, getModelFromSnapshot } from '..';
import { Collections } from '../../constants';
import {
  PublicationIssue,
  PublicationIssueStatus
} from '../../types/publicationIssue';
import { PublicationIssueModel } from './publicationIssueModel';
import { ESnapshotExists, EUser, ERef } from '../../types';
import { ResponseOrError, wrapError, wrapSuccess } from '../../types/responses';
import {
  PublicationIssueSectionStatusChangeEvent,
  PUBLICATION_ISSUE_SECTION_STATUS_CHANGE
} from '../../types/events';
import { getOrThrow } from '../../utils/refs';
import { PublicationIssueAttachmentModel } from './publicationIssueAttachmentModel';
import { safeAsync } from '../../safeWrappers';
import { getErrorReporter } from '../../utils/errors';
import { ColumnService } from '../../services/directory';
import { PublicationIssueAttachmentStatus } from '../../types/publicationIssueAttachment';

export class PublicationIssueSectionModel extends SnapshotModel<
  PublicationIssueSection,
  typeof Collections.publicationIssueSections
> {
  get type() {
    return Collections.publicationIssueSections;
  }

  public async updateStatus(
    changedBy: ESnapshotExists<EUser> | null,
    status: PublicationIssueStatus
  ): Promise<ResponseOrError<boolean>> {
    // Only fire the update if the new status differs from the current status
    const beforeStatus = this.modelData.status;
    if (beforeStatus === status) {
      return wrapError(new Error('Publication issue already in this status'));
    }

    // Update the status
    try {
      await this.update({
        status
      });
    } catch (err) {
      return wrapError(err as Error);
    }

    // Insert status change event
    try {
      const publicationIssue = await getOrThrow(
        this.ref.parent.parent as ERef<PublicationIssue>
      );
      await this.ctx.eventsRef<PublicationIssueSectionStatusChangeEvent>().add({
        type: PUBLICATION_ISSUE_SECTION_STATUS_CHANGE,
        publicationIssueSection: this.ref,
        publicationIssue: publicationIssue.ref,
        createdAt: this.ctx.timestamp(),
        newspaper: publicationIssue.data().publisher,
        data: {
          beforeStatus,
          afterStatus: status,
          changedBy: changedBy?.ref ?? null
        },
        handleSideEffects: true
      });

      return wrapSuccess(true);
    } catch (err) {
      return wrapError(err as Error);
    }
  }

  public getPublicationIssueRef(): ERef<PublicationIssue> {
    if (!this.ref.parent.parent) {
      throw new Error(
        'Publication issue section missing parent publication issue'
      );
    }
    // TODO(goodpaul) - Build stronger typing for traversing refs in models
    return this.ref.parent.parent as ERef<PublicationIssue>;
  }

  public async getPublicationIssue(): Promise<PublicationIssueModel> {
    const publicationIssueRef = this.getPublicationIssueRef();
    const publicationIssue = await getModelFromRef(
      PublicationIssueModel,
      this.ctx,
      publicationIssueRef
    );
    return publicationIssue;
  }

  protected async update(
    requestedParams: Partial<PublicationIssueSection>
  ): Promise<void> {
    const params = requestedParams;
    if (params.status) {
      params.statusChanges = [
        ...(this.modelData.statusChanges || []),
        {
          status: params.status,
          timestamp: this.ctx.timestamp()
        }
      ];
    }

    await super.update(params);
  }

  public async getAttachmentsForIssueSection(): Promise<
    ResponseOrError<PublicationIssueAttachmentModel[]>
  > {
    const [pubIssueError, publicationIssue] = await safeAsync(() =>
      this.getPublicationIssue()
    )();
    if (pubIssueError) {
      getErrorReporter().logAndCaptureError(
        ColumnService.PAGINATION,
        pubIssueError,
        'Failed to get publication issue for section while getting attachments',
        { sectionPath: this.ref.path }
      );
      return wrapError(pubIssueError);
    }
    const publicationIssueAttachmentQuery = await this.ctx
      .publicationIssueAttachmentsRef(publicationIssue.ref)
      .orderBy('createdAt', 'asc')
      .get();
    const filteredPublicationIssueAttachmentSnaps =
      publicationIssueAttachmentQuery.docs.filter(
        doc => doc.data().status !== PublicationIssueAttachmentStatus.DELETED
      );
    const sectionAttachments = filteredPublicationIssueAttachmentSnaps.filter(
      attachment => attachment.data().section?.id === this.id
    );
    const publicationIssueAttachments = sectionAttachments.map(snap =>
      getModelFromSnapshot(PublicationIssueAttachmentModel, this.ctx, snap)
    );
    return wrapSuccess(publicationIssueAttachments);
  }
}
