import { fork } from 'redux-saga/effects';
import AuthSaga from './AuthSaga';
import SetupSaga from './SetupSaga';
import PlacementSaga from './PlacementSaga';
import NoticeSaga from './NoticeSaga';

export default function* root() {
  yield fork(SetupSaga);
  yield fork(AuthSaga);
  yield fork(PlacementSaga);
  yield fork(NoticeSaga);
}
