import {
  EOrganization,
  ERef,
  ERequestTypes,
  ESnapshotExists,
  EStripeBankAccount,
  EStripePaymentMethodCard,
  EStripePaymentMethodUSBankAccount,
  EUser
} from 'lib/types';
import api from 'api';

export type UserPaymentMethods = {
  bankAccounts: EStripeBankAccount[];
  bankAccountsPaymentMethods: EStripePaymentMethodUSBankAccount[];
  cards: EStripePaymentMethodCard[];
};
/**
 * Helper to get a list of all bank accounts that a user can access.
 *
 * @param user the active user.
 * @param organization if specified this overrides the user's active organization
 * as the organization to use for locating bank accounts.
 */
export const getUserPaymentMethods = async (
  user: ESnapshotExists<EUser>,
  organization?: ERef<EOrganization>
): Promise<UserPaymentMethods> => {
  const org = organization ?? user.data().activeOrganization;
  const req: ERequestTypes['payments/list-customer-payment-methods'] = org
    ? { type: 'organization', organizationId: org.id }
    : { type: 'individual', userId: user.id };

  const { error, response: res } = await api.safePost(
    'payments/list-customer-payment-methods',
    req
  );

  if (error || !res) {
    throw new Error(error || 'Failed to get user payment methods');
  }

  /**
   * TODO: IT-4394
   * Make a gateway agnostic type for bank accounts and consolidate old vs. new Stripe bank accounts
   * */
  return {
    cards: res.cards,
    bankAccounts: res.bank_accounts_sources,
    bankAccountsPaymentMethods: res.bank_accounts_payment_methods
  };
};
