import { getColumnInches } from '..';
import { isNoticeRate } from '../../types/rates';
import { floatToP2Float } from '../ui';
import { PricingFunction } from './types';

const SINGLE_COLUMN_FIRST_RUN_THRESHOLD: {
  circulationCap: number;
  costPerColumnInch: number;
}[] = [
  {
    circulationCap: 2500,
    costPerColumnInch: 4.76
  },
  {
    circulationCap: 4999,
    costPerColumnInch: 5.32
  },
  {
    circulationCap: 7499,
    costPerColumnInch: 5.39
  },
  {
    circulationCap: 9999,
    costPerColumnInch: 5.53
  },
  {
    circulationCap: 14999,
    costPerColumnInch: 5.67
  },
  {
    circulationCap: 19999,
    costPerColumnInch: 6.3
  },
  {
    circulationCap: 24999,
    costPerColumnInch: 6.79
  },
  {
    circulationCap: 29999,
    costPerColumnInch: 7.28
  },
  {
    circulationCap: 34999,
    costPerColumnInch: 7.5
  },
  {
    circulationCap: 39999,
    costPerColumnInch: 8.06
  },
  {
    circulationCap: 44999,
    costPerColumnInch: 11.0
  },
  {
    circulationCap: 49999,
    costPerColumnInch: 12.0
  },
  {
    circulationCap: 54999,
    costPerColumnInch: 14.0
  },
  {
    circulationCap: 59999,
    costPerColumnInch: 17.5
  },
  {
    circulationCap: 64999,
    costPerColumnInch: 30.0
  },
  {
    circulationCap: 69999,
    costPerColumnInch: 47.6
  },
  {
    circulationCap: 74999,
    costPerColumnInch: 48.0
  },
  {
    circulationCap: 79999,
    costPerColumnInch: 330.0
  },
  {
    circulationCap: 84999,
    costPerColumnInch: 538.56
  }
];

const SINGLE_COLUMN_ADDITIONAL_RUN_THRESHOLDS = [
  {
    circulationCap: 2500,
    costPerColumnInch: 4.76
  },
  {
    circulationCap: 4999,
    costPerColumnInch: 4.06
  },
  {
    circulationCap: 7499,
    costPerColumnInch: 4.13
  },
  {
    circulationCap: 9999,
    costPerColumnInch: 4.27
  },
  {
    circulationCap: 14999,
    costPerColumnInch: 4.41
  },
  {
    circulationCap: 19999,
    costPerColumnInch: 5.04
  },
  {
    circulationCap: 24999,
    costPerColumnInch: 5.53
  },
  {
    circulationCap: 29999,
    costPerColumnInch: 6.02
  },
  {
    circulationCap: 34999,
    costPerColumnInch: 7.5
  },
  {
    circulationCap: 39999,
    costPerColumnInch: 8.06
  },
  {
    circulationCap: 44999,
    costPerColumnInch: 11.0
  },
  {
    circulationCap: 49999,
    costPerColumnInch: 12.0
  },
  {
    circulationCap: 54999,
    costPerColumnInch: 14.0
  },
  {
    circulationCap: 59999,
    costPerColumnInch: 17.5
  },
  {
    circulationCap: 64999,
    costPerColumnInch: 30.0
  },
  {
    circulationCap: 69999,
    costPerColumnInch: 47.6
  },
  {
    circulationCap: 74999,
    costPerColumnInch: 48.0
  },
  {
    circulationCap: 79999,
    costPerColumnInch: 330.0
  },
  {
    circulationCap: 84999,
    costPerColumnInch: 538.56
  }
];

const MULTI_COLUMN_FIRST_RUN_THRESHOLD: {
  circulationCap: number;
  costPerColumnInch: number;
}[] = [
  {
    circulationCap: 2500,
    costPerColumnInch: 4.76
  },
  {
    circulationCap: 4999,
    costPerColumnInch: 6.44
  },
  {
    circulationCap: 7499,
    costPerColumnInch: 6.51
  },
  {
    circulationCap: 9999,
    costPerColumnInch: 6.65
  },
  {
    circulationCap: 14999,
    costPerColumnInch: 6.79
  },
  {
    circulationCap: 19999,
    costPerColumnInch: 6.93
  },
  {
    circulationCap: 24999,
    costPerColumnInch: 7.42
  },
  {
    circulationCap: 29999,
    costPerColumnInch: 7.5
  },
  {
    circulationCap: 34999,
    costPerColumnInch: 7.91
  },
  {
    circulationCap: 39999,
    costPerColumnInch: 8.4
  },
  {
    circulationCap: 44999,
    costPerColumnInch: 9.43
  },
  {
    circulationCap: 49999,
    costPerColumnInch: 11.0
  },
  {
    circulationCap: 54999,
    costPerColumnInch: 12.0
  },
  {
    circulationCap: 59999,
    costPerColumnInch: 14.0
  },
  {
    circulationCap: 64999,
    costPerColumnInch: 17.5
  },
  {
    circulationCap: 69999,
    costPerColumnInch: 30.0
  },
  {
    circulationCap: 74999,
    costPerColumnInch: 47.6
  },
  {
    circulationCap: 79999,
    costPerColumnInch: 48.0
  },
  {
    circulationCap: 84999,
    costPerColumnInch: 330.0
  },
  {
    circulationCap: 89999,
    costPerColumnInch: 538.56
  }
];

const MULTI_COLUMN_ADDITIONAL_RUN_THRESHOLDS = [
  {
    circulationCap: 2500,
    costPerColumnInch: 4.76
  },
  {
    circulationCap: 4999,
    costPerColumnInch: 5.18
  },
  {
    circulationCap: 7499,
    costPerColumnInch: 5.25
  },
  {
    circulationCap: 9999,
    costPerColumnInch: 5.39
  },
  {
    circulationCap: 14999,
    costPerColumnInch: 5.53
  },
  {
    circulationCap: 19999,
    costPerColumnInch: 6.16
  },
  {
    circulationCap: 24999,
    costPerColumnInch: 6.65
  },
  {
    circulationCap: 29999,
    costPerColumnInch: 7.14
  },
  {
    circulationCap: 34999,
    costPerColumnInch: 7.5
  },
  {
    circulationCap: 39999,
    costPerColumnInch: 9.43
  },
  {
    circulationCap: 44999,
    costPerColumnInch: 11.0
  },
  {
    circulationCap: 49999,
    costPerColumnInch: 12.0
  },
  {
    circulationCap: 54999,
    costPerColumnInch: 14.0
  },
  {
    circulationCap: 59999,
    costPerColumnInch: 17.5
  },
  {
    circulationCap: 64999,
    costPerColumnInch: 30.0
  },
  {
    circulationCap: 69999,
    costPerColumnInch: 47.6
  },
  {
    circulationCap: 74999,
    costPerColumnInch: 48.0
  },
  {
    circulationCap: 79999,
    costPerColumnInch: 330.0
  },
  {
    circulationCap: 84999,
    costPerColumnInch: 538.56
  }
];

const getRelevantThresholds = ({
  runNumber,
  columns
}: {
  runNumber: number;
  columns: number;
}) => {
  if (columns === 1) {
    if (runNumber === 0) {
      return SINGLE_COLUMN_FIRST_RUN_THRESHOLD;
    }
    return SINGLE_COLUMN_ADDITIONAL_RUN_THRESHOLDS;
  }
  if (runNumber === 0) {
    return MULTI_COLUMN_FIRST_RUN_THRESHOLD;
  }
  return MULTI_COLUMN_ADDITIONAL_RUN_THRESHOLDS;
};

export const getRelevantNypaPricingForCirculation = ({
  circulation,
  runNumber,
  columns
}: {
  circulation: number;
  runNumber: number;
  columns: number;
}) => {
  const thresholds = getRelevantThresholds({ runNumber, columns });
  const sortedThresholds = thresholds.sort((a, b) =>
    a.circulationCap > b.circulationCap ? -1 : 1
  );

  const maximumThreshold = sortedThresholds[0];

  let relevantThreshold = sortedThresholds[0];

  if (circulation <= maximumThreshold.circulationCap) {
    for (const threshold of sortedThresholds) {
      if (threshold.circulationCap > relevantThreshold.circulationCap) {
        continue;
      }
      if (circulation <= threshold.circulationCap) {
        relevantThreshold = threshold;
      }
    }
  } else {
    const linesBeyondMaximumThreshold =
      circulation - maximumThreshold.circulationCap;
    const incrementsBeyondMaximumThreshold = Math.ceil(
      linesBeyondMaximumThreshold / 5_000
    );
    relevantThreshold = {
      circulationCap: 164999,
      costPerColumnInch:
        maximumThreshold.costPerColumnInch +
        incrementsBeyondMaximumThreshold * 1.5
    };
  }

  return relevantThreshold;
};

export const priceNypaStatutoryRate: PricingFunction = pricingOptions => {
  const { displayParameters, columns, runNumber, rateRecord } = pricingOptions;

  if (!isNoticeRate(rateRecord)) {
    throw new Error('NYPA statutory rate is only applicable to notice rates');
  }

  const { circulation } = rateRecord;
  const { height } = displayParameters;

  if (!columns || !circulation || !height) return 0;

  const relevantThreshold = getRelevantNypaPricingForCirculation({
    circulation,
    runNumber,
    columns
  });

  const thresholdCostInCents = relevantThreshold.costPerColumnInch * 100;

  const columnInches = getColumnInches(height, columns, rateRecord.roundOff);
  const baseCost = floatToP2Float(columnInches * thresholdCostInCents);

  return baseCost;
};
