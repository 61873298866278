import { dbToUICurrencyString } from 'lib/pricing/ui';
import { NewspaperOrderPublishingData } from 'lib/types/order';
import { useContext, useState } from 'react';
import { Alert } from 'lib/components/Alert';
import { capitalize } from 'lodash';
import { HorizontalDivider } from 'lib/components/Card/Grid';
import { NewspapersContext } from '../../contexts/NewspapersContext';

type PricePreviewNewspaperLineItemsProps = {
  newspaperOrderPublishingDataGroup: NewspaperOrderPublishingData[];
};

export function PricePreviewNewspaperOrders({
  newspaperOrderPublishingDataGroup
}: PricePreviewNewspaperLineItemsProps) {
  const { publishersAvailableForPlacement } = useContext(NewspapersContext);
  const [error, setError] = useState('');

  return (
    <div className="flex flex-col gap-8 p-6">
      {error && <Alert id="newspaper-load-error">{error}</Alert>}
      {newspaperOrderPublishingDataGroup.map(
        (newspaperOrderPublishingData, index) => {
          const newspaper = publishersAvailableForPlacement.find(
            n => n.id === newspaperOrderPublishingData.newspaperId
          );
          if (!newspaper) {
            setError('Newspaper not found. Please refresh the page.');
            return;
          }
          const subtotalInCents = newspaperOrderPublishingData.lineItems.reduce(
            (acc, lineItem) => acc + lineItem.amount,
            0
          );
          return (
            <div
              className="text-sm flex flex-col gap-4"
              key={`${newspaper.data().name}-${index}`}
            >
              <div className="w-32 flex gap-2 w-full items-center">
                <span className="font-semibold">
                  {capitalize(newspaperOrderPublishingData.publishingMedium)}{' '}
                  publication in {newspaper.data().name}
                </span>
              </div>
              <div className="flex flex-col gap-3">
                <div className="flex justify-between">
                  <div className="text-column-gray-400">Words</div>
                  <div>
                    {newspaperOrderPublishingData.displayParams?.words ?? ''}
                  </div>
                </div>
                <div className="flex justify-between">
                  <div className="text-column-gray-400">Lines</div>
                  <div>
                    {newspaperOrderPublishingData.displayParams?.lines ?? ''}
                  </div>
                </div>
                <div className="flex justify-between">
                  <div className="text-column-gray-400">Column inches</div>
                  <div>
                    {newspaperOrderPublishingData.columnInches
                      ? newspaperOrderPublishingData.columnInches.toFixed(2)
                      : ''}
                  </div>
                </div>
                <HorizontalDivider noPadding />
                {newspaperOrderPublishingData.lineItems.map(
                  (lineItem, index) => {
                    return (
                      <div className="flex justify-between" key={index}>
                        <div className="text-column-gray-400">
                          {lineItem.description}
                        </div>
                        <div>${dbToUICurrencyString(lineItem.amount)}</div>
                      </div>
                    );
                  }
                )}
                <HorizontalDivider noPadding />
                <div className="flex justify-between">
                  <div className="text-column-gray-400">Subtotal</div>
                  <div>${dbToUICurrencyString(subtotalInCents)}</div>
                </div>
                <div className="flex justify-between">
                  <div className="text-column-gray-400">Tax</div>
                  <div>
                    $
                    {dbToUICurrencyString(
                      newspaperOrderPublishingData.taxesInCents
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        }
      )}
    </div>
  );
}
