import Firebase from 'EnoticeFirebase';
import { MadlibQuestionConfig, Question } from 'lib/types/madlib';

type MadlibsConfig = {
  template: string;
  questions: Question[];
  questionConfig?: MadlibQuestionConfig;
};

const fetchString = async (downloadUrl: string) => {
  const res = await fetch(downloadUrl);
  return res.text();
};

/**
 * Load the handlebars template and questions JSON from Google Cloud Storage.
 * @param path the path to the config FOLDER containing the files.
 */
export const fetchMadlibsConfig = async (
  path: string
): Promise<MadlibsConfig> => {
  const folderRef = Firebase.storage().ref('config').child(path);
  const templateRef = folderRef.child('template.handlebars');
  const questionsRef = folderRef.child('questions.json');

  const [templateDownloadUrl, questionDownloadUrl] = await Promise.all([
    templateRef.getDownloadURL(),
    questionsRef.getDownloadURL()
  ]);

  const [template, questionStr] = await Promise.all([
    fetchString(templateDownloadUrl),
    fetchString(questionDownloadUrl)
  ]);

  const questionsFile = JSON.parse(questionStr);
  const { questions, questionConfig } = questionsFile;

  return {
    template,
    questions,
    questionConfig
  };
};
