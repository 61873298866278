import { TabOption } from 'lib/components/Tabs';
import { Product } from 'lib/enums';
import {
  PublicationIssueSearchRequest,
  PublicationIssueStatus
} from 'lib/types/publicationIssue';
import moment from 'moment-timezone/moment-timezone';
import { PublicationIssueWithSection } from 'lib/types/publicationIssueSection';
import { EOrganization, ESnapshot, exists } from 'lib/types';
import { PRODUCT_TO_NAME } from 'lib/enums/Product';
import { applySentenceCase } from 'lib/utils/strings';
import { getAdvancedPaginationOnForProduct } from 'lib/pagination/helpers';

export const ALL_PUBLICATION_DATES = 'All publication dates';
export const TODAY = 'Today';
export const TOMORROW = 'Tomorrow';
export const TODAY_AND_TOMORROW = 'Today and tomorrow';

export type PaginationIssueFilter = {
  deadline: // Future TODO: date range select
  (typeof PAGINATION_ISSUE_DATE_FILTERS)[number] | undefined;
  publicationDate: // Future TODO: date range select
  (typeof PAGINATION_ISSUE_DATE_FILTERS)[number] | undefined;
};

export const PAGINATION_ISSUE_DATE_FILTERS = [
  ALL_PUBLICATION_DATES,
  TODAY,
  TOMORROW,
  TODAY_AND_TOMORROW
];

export const DEFAULT_PAGINATION_ISSUE_FILTER: PaginationIssueFilter = {
  deadline: undefined,
  publicationDate: undefined
};

export const PublicationIssueStatusLabels = {
  [PublicationIssueStatus.DISABLED]: 'Disabled',
  [PublicationIssueStatus.PENDING]: 'Awaiting Review',
  [PublicationIssueStatus.CHANGES_REQUESTED]: 'Changes Requested',
  [PublicationIssueStatus.READY_FOR_PAGINATION]: 'Ready for Pagination',
  [PublicationIssueStatus.AWAITING_APPROVAL]: 'Awaiting Approval',
  [PublicationIssueStatus.APPROVED]: 'Approved',
  [PublicationIssueStatus.PRINT_READY]: 'Print Ready',
  [PublicationIssueStatus.ARCHIVED]: 'Archived'
};

const getStartAndEndDatesFromIssueTableFilterOption = (
  filter?: (typeof PAGINATION_ISSUE_DATE_FILTERS)[number]
) => {
  // TODO does timezone matter for this
  if (filter === TODAY) {
    return {
      from: moment().startOf('day').toDate(),
      to: moment().endOf('day').toDate()
    };
  }
  if (filter === TOMORROW) {
    return {
      from: moment().add(1, 'day').startOf('day').toDate(),
      to: moment().add(1, 'day').endOf('day').toDate()
    };
  }
  if (filter === TODAY_AND_TOMORROW) {
    return {
      from: moment().startOf('day').toDate(),
      to: moment().add(1, 'day').endOf('day').toDate()
    };
  }

  return { from: undefined, to: undefined };
};

export const getDatesForIssueQueryFromTableFilter = (
  filter: PaginationIssueFilter
): Pick<
  PublicationIssueSearchRequest,
  | 'deadlineTimestampFrom'
  | 'deadlineTimestampTo'
  | 'publicationDateFrom'
  | 'publicationDateTo'
> => {
  const { deadline, publicationDate } = filter;
  const deadlineStartAndEnd =
    getStartAndEndDatesFromIssueTableFilterOption(deadline);
  const publicationDateStartAndEnd =
    getStartAndEndDatesFromIssueTableFilterOption(publicationDate);

  return {
    deadlineTimestampFrom: deadlineStartAndEnd.from,
    deadlineTimestampTo: deadlineStartAndEnd.to,
    publicationDateFrom: publicationDateStartAndEnd.from
      ? moment(publicationDateStartAndEnd.from).format('YYYY-MM-DD')
      : undefined,
    publicationDateTo: publicationDateStartAndEnd.to
      ? moment(publicationDateStartAndEnd.to).format('YYYY-MM-DD')
      : undefined
  };
};

export const filterHasChanges = (
  {
    deadline: previousDeadline,
    publicationDate: previousPublicationDate
  }: PaginationIssueFilter,
  {
    deadline: newDeadline,
    publicationDate: newPublicationDate
  }: PaginationIssueFilter
): boolean => {
  return (
    previousDeadline !== newDeadline ||
    previousPublicationDate !== newPublicationDate
  );
};

export const numberOfChanges = (filter: PaginationIssueFilter) => {
  let numFilters = 0;

  if (filter.deadline !== DEFAULT_PAGINATION_ISSUE_FILTER.deadline) {
    ++numFilters;
  }

  if (
    filter.publicationDate !== DEFAULT_PAGINATION_ISSUE_FILTER.publicationDate
  ) {
    ++numFilters;
  }

  return numFilters;
};

export const PENDING_TAB: TabOption = {
  label: 'Upcoming',
  enabled: true,
  id: 'pending-tab'
};

export const READY_FOR_PAGINATION_TAB: TabOption = {
  label: 'Ready for Pagination',
  enabled: true,
  id: 'ready-for-pagination-tab'
};

export const AWAITING_APPROVAL_TAB: TabOption = {
  label: 'Awaiting Approval',
  enabled: true,
  id: 'awaiting-approval-tab'
};

export const APPROVED_TAB: TabOption = {
  label: 'Approved',
  enabled: true,
  id: 'approved-tab'
};

export const PRINT_READY_TAB: TabOption = {
  label: 'Print-Ready',
  enabled: true,
  id: 'print-ready-tab'
};

export const ARCHIVED_TAB: TabOption = {
  label: 'Archived',
  enabled: true,
  id: 'archived-tab'
};

export const DISABLED_TAB: TabOption = {
  label: 'Disabled',
  enabled: true,
  id: 'disabled-tab'
};

export const ADVANCED_PAGINATION_TABS: PaginationTabs[] = [
  PENDING_TAB,
  READY_FOR_PAGINATION_TAB,
  AWAITING_APPROVAL_TAB,
  APPROVED_TAB,
  PRINT_READY_TAB,
  ARCHIVED_TAB
];

export const SIMPLIFIED_PAGINATION_TABS: PaginationTabs[] = [
  PENDING_TAB,
  APPROVED_TAB,
  ARCHIVED_TAB
];

export type PaginationTabs =
  | typeof PENDING_TAB
  | typeof READY_FOR_PAGINATION_TAB
  | typeof AWAITING_APPROVAL_TAB
  | typeof APPROVED_TAB
  | typeof PRINT_READY_TAB
  | typeof ARCHIVED_TAB
  | typeof DISABLED_TAB;

export const PAGINATION_TABS_TO_STATUSES: Record<
  PaginationTabs['id'],
  PublicationIssueStatus[]
> = {
  [PENDING_TAB.id]: [PublicationIssueStatus.PENDING],
  [READY_FOR_PAGINATION_TAB.id]: [
    PublicationIssueStatus.READY_FOR_PAGINATION,
    PublicationIssueStatus.CHANGES_REQUESTED
  ],
  [AWAITING_APPROVAL_TAB.id]: [PublicationIssueStatus.AWAITING_APPROVAL],
  [APPROVED_TAB.id]: [PublicationIssueStatus.APPROVED],
  [PRINT_READY_TAB.id]: [PublicationIssueStatus.PRINT_READY],
  [ARCHIVED_TAB.id]: [PublicationIssueStatus.ARCHIVED],
  [DISABLED_TAB.id]: [PublicationIssueStatus.DISABLED]
};

export const getShouldUseAdvancedPagination = async (
  publisher: ESnapshot<EOrganization> | null,
  product: Product
) => {
  const orgHasAdvancedPaginationEnabledForProduct =
    exists(publisher) &&
    (await getAdvancedPaginationOnForProduct(product, publisher));
  return orgHasAdvancedPaginationEnabledForProduct;
};

export const getTableHeaderText = (
  tabId: PaginationTabs['id'],
  product: Product
): { title: TabOption['label']; subtitle: string } => {
  switch (tabId) {
    case PENDING_TAB.id:
      return {
        title: applySentenceCase(
          `${PENDING_TAB.label} ${PRODUCT_TO_NAME[product].plural}`
        ),
        subtitle:
          product === Product.Notice
            ? 'Track all publication issues awaiting review for invoice creation. Issues with zero notices will not be shown here as they are auto-approved.'
            : 'Track all publication issues awaiting review. Issues with orders will not be shown here as they are auto-approved.'
      };
    case READY_FOR_PAGINATION_TAB.id:
      return {
        title: applySentenceCase(
          `${PRODUCT_TO_NAME[product].plural} ${READY_FOR_PAGINATION_TAB.label}`
        ),
        subtitle: 'Track all files pending pagination.'
      };
    case AWAITING_APPROVAL_TAB.id:
      return {
        title: applySentenceCase(
          `${PRODUCT_TO_NAME[product].plural} ${AWAITING_APPROVAL_TAB.label}`
        ),
        subtitle:
          'Track all files awaiting approval with active publication dates.'
      };
    case APPROVED_TAB.id:
      return {
        title: applySentenceCase(
          `${APPROVED_TAB.label} ${PRODUCT_TO_NAME[product].plural}`
        ),
        subtitle: 'Track all approved files with active publication dates.'
      };
    case PRINT_READY_TAB.id:
      return {
        title: applySentenceCase(
          `${PRINT_READY_TAB.label} ${PRODUCT_TO_NAME[product].plural}`
        ),
        subtitle: 'Track all files ready to print.'
      };
    case ARCHIVED_TAB.id:
      return {
        title: applySentenceCase(
          `${ARCHIVED_TAB.label} ${PRODUCT_TO_NAME[product].plural}`
        ),
        subtitle: 'Track all approved files with publication dates in the past.'
      };
    default:
      return {
        title: applySentenceCase(
          `${DISABLED_TAB.label} ${PRODUCT_TO_NAME[product].plural}`
        ),
        subtitle: 'This tab is visible to Column users only.'
      };
  }
};

/**
 * Sort by deadlineTimestamp asc in the pagination table
 * Future TODO: may make this sort configurable through UI
 */
export const comparePublicationIssuesSort = (
  pubIssue1: PublicationIssueWithSection,
  pubIssue2: PublicationIssueWithSection,
  paginationTab: PaginationTabs
) => {
  const deadline1 =
    pubIssue1.section?.modelData.deadlineTimestamp ||
    pubIssue1.publicationIssue.modelData.deadlineTimestamp;
  const deadline2 =
    pubIssue2.section?.modelData.deadlineTimestamp ||
    pubIssue2.publicationIssue.modelData.deadlineTimestamp;

  if (!deadline1) {
    return 1;
  }
  if (!deadline2) {
    return -1;
  }
  // show the latest deadline in the archived tab, show the earliest in all others
  if (paginationTab === ARCHIVED_TAB) {
    return deadline2.toMillis() - deadline1.toMillis();
  }
  return deadline1.toMillis() - deadline2.toMillis();
};
