import React, { useEffect, useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import { withStyles, createStyles, Theme } from '@material-ui/core/styles';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import LoadingState from 'components/LoadingState';
import {
  ESnapshot,
  EUser,
  ENoticeDraft,
  ESnapshotExists,
  exists
} from 'lib/types';
import PlacementActions, { EPlacement } from 'redux/placement';
import { AuthState, selectContextOrganizationId } from 'redux/auth';

import { EOrganization } from 'lib/types/organization';
import { getFirebaseContext } from 'utils/firebase';
import { FILE_ROUTES, FORM_ROUTES } from 'router/routes';
import { getDynamicRoute, useAppParams } from 'lib/frontend/utils/router';
import { NoticeType } from '../../lib/enums';
import AddNoticeTypeScreen from './AddNoticeTypeScreen';

const drawerWidth = 35;

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex'
    },
    drawer: {
      width: theme.spacing(drawerWidth),
      flexShrink: 0
    },
    drawerPaper: {
      width: theme.spacing(drawerWidth)
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(2)
    },
    toolbar: theme.mixins.toolbar
  });
type AddNoticesProp = {
  user: ESnapshot<EUser> | null;
};
function AddNotices({ user }: AddNoticesProp) {
  const [newspaper, setNewspaper] = useState<EOrganization>();
  const [draftId, setDraftId] = useState('');

  const { id: noticeId, noticeSlug } = useAppParams(
    FILE_ROUTES.BY_NOTICE_WITH_SLUG
  );

  const fetchNewspaper = async () => {
    const noticeSnap = await getFirebaseContext()
      .userNoticesRef()
      .doc(noticeId)
      .get();

    if (!exists(noticeSnap)) {
      return;
    }

    const drafts = await Promise.all(
      (noticeSnap.data().drafts || []).map(draft => draft.get())
    );

    const draftSnap = user
      ? drafts.find(draft => draft?.data()?.owner?.id === user.id)
      : drafts[0];
    if (draftSnap) {
      setDraftId(draftSnap.id);
      const newspaperSnap = await draftSnap.data()?.newspaper.get();
      if (newspaperSnap) {
        setNewspaper(newspaperSnap.data());
      }
    }
  };

  useEffect(() => {
    void fetchNewspaper();
  }, []);

  // get *probate* from e.g. /subnotices/probate/
  if (!newspaper) return <LoadingState />;
  const matchingNotice = NoticeType.by_key(noticeSlug);
  let notices = matchingNotice
    ? matchingNotice.children()
    : NoticeType.rootItems();
  notices = notices.filter(notice => notice.states?.includes(newspaper.state));
  return (
    <AddNoticeTypeScreen
      noticesToDisplay={notices}
      noticeId={noticeId}
      draftId={draftId}
    />
  );
}

const mapStateToProps = (state: {
  router: any;
  auth: AuthState;
  placement: EPlacement;
}) => ({
  user: state.auth.user,
  draft: state.placement.draftSnap
});

const mapDispatchToProps = (dispatch: any) => ({
  placementActions: bindActionCreators(PlacementActions, dispatch)
});

function AddNoticeWrapper({
  placementActions,
  noticeType,
  draft,
  ...props
}: AddNoticesProp & {
  placementActions: any;
  noticeType?: string;
  draft?: ESnapshotExists<ENoticeDraft> | null;
}) {
  const navigate = useNavigate();
  const contextOrganizationId = useAppSelector(selectContextOrganizationId);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!noticeType) {
      setInitialized(true);
      return;
    }

    if (draft) return;
    if (!contextOrganizationId) return;
    if (initialized) return;

    if (noticeType) {
      placementActions.hydrateNoticeData(null, false);
    }
  }, [draft?.id, contextOrganizationId]);

  useEffect(() => {
    const processPopulatedDraft = async () => {
      if (!noticeType || !draft) return;
      const noticeTypeValue = NoticeType.by_key(noticeType)?.value;
      await draft.ref.update({
        noticeType: noticeTypeValue
      });
      setInitialized(true);
      navigate(
        getDynamicRoute(FORM_ROUTES.NOTICE_TYPE, {
          noticeId: draft.id,
          noticeType: noticeTypeValue
        })
      );
    };

    void processPopulatedDraft();
  }, [draft?.id]);

  if (!initialized) return <LoadingState />;
  return <AddNotices {...props} />;
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AddNoticeWrapper));
