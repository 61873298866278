import { ColumnPublisherAbbreviation } from './column/constants';
import { WickPaperAbbreviation } from './wick/constants';

export const AFFINITY_ORDER_NUMBER_INCREMENTORS = [
  '',
  'A',
  'B',
  'C',
  'D'
] as const;
export type AffinityXOrderNumberIncrementor =
  (typeof AFFINITY_ORDER_NUMBER_INCREMENTORS)[number];

type WICK_ORDER_NUMBER =
  `WCC${WickPaperAbbreviation}${number}${number}${number}${number}${number}${number}${AffinityXOrderNumberIncrementor}`;
type COLUMN_ORDER_NUMBER =
  `COL${ColumnPublisherAbbreviation}${string}${string}${string}${number}${number}${number}${number}${number}${number}${AffinityXOrderNumberIncrementor}`;

export type AffinityXOrderNumber = WICK_ORDER_NUMBER | COLUMN_ORDER_NUMBER;

/**
 * example AffinityX file name: DTT000107.pdf
 */
export type AffinityXResponseFileName = `${AffinityXOrderNumber}.${
  | 'pdf'
  | 'PDF'}`;
