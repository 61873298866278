import { Tab } from '@headlessui/react';
import classNames from 'classnames';
import React, { FC } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Badge } from '../Badge';

export type RouterTabOption = {
  /** Whether the tab is clickable. */
  enabled: boolean;
  /** The label to display in the tab. */
  label: React.ReactNode;
  /** The path to navigate to when the tab is clicked. */
  path: string;
  /** Optional hotkey to select the tab via keyboard. */
  hotKey?: string;
  /** Optional icon to display alongside the tab label. */
  Icon?: FC<{ className?: string }>;
};

function RouterTab({
  path,
  enabled,
  hotKey,
  Icon,
  label,
  sidebar
}: RouterTabOption & { sidebar?: boolean }) {
  const isActive = useLocation().pathname.startsWith(path);

  const navigate = useNavigate();
  const onSelect = () => enabled && navigate(path);

  useHotkeys(hotKey || '', onSelect);

  if (sidebar) {
    return (
      <button
        className={classNames(
          'flex rounded font-medium text-base leading-5 mt-1 py-3 w-56',
          {
            'cursor-pointer hover:bg-column-primary-50 hover:text-primary-500 text-column-gray-500':
              !isActive && enabled,
            'bg-column-primary-50 text-primary-500': isActive,
            'cursor-not-allowed text-column-gray-300': !enabled
          }
        )}
        disabled={!enabled}
        role="tab"
        onClick={onSelect}
      >
        {Icon && (
          <div className="flex justify-center w-1/6">
            <Icon className="w-5 h-5" />
          </div>
        )}
        <span>{label}</span>
        {hotKey && <Badge>{hotKey.toUpperCase()}</Badge>}
      </button>
    );
  }

  return (
    <Tab
      className={classNames('ml-6 focus:outline-none', {
        'cursor-not-allowed': !enabled
      })}
      onClick={onSelect}
    >
      <button
        className={classNames(
          'text-base font-medium py-6 flex items-center gap-2',
          {
            'text-column-primary-600 border-b-2 border-column-primary-500':
              isActive
          },
          {
            'text-column-gray-500': !isActive && enabled
          },
          {
            'text-column-gray-300': !enabled
          }
        )}
        role="tab"
      >
        {Icon && <Icon className="w-5" />}
        <span>{label}</span>
        {hotKey && <Badge>{hotKey.toUpperCase()}</Badge>}
      </button>
    </Tab>
  );
}

type RouterTabsProps = {
  /**
   * List of tab options to render. Each should be separately handled by routing
   * logic to properly render the tab content.
   */
  tabs: RouterTabOption[];
  /** Unique identifier for the tab group. */
  id: string;
  /**
   * Optional flag to render the tabs in a sidebar. (By default, tabs are
   * rendered in a horizontal tab group.)
   */
  sidebar?: boolean;
  /**
   * Optional flag to hide disabled tabs. (By default, disabled tabs are
   * visible, but not clickable.)
   */
  hideDisabled?: boolean;
};

/**
 * Generalized tab component that integrates with react-router to render tabs
 * based on the current route.
 */
export default function RouterTabs({
  tabs,
  id,
  sidebar,
  hideDisabled
}: RouterTabsProps) {
  const relevantTabs = hideDisabled ? tabs.filter(tab => tab.enabled) : tabs;

  if (sidebar) {
    return (
      <div className="w-screen flex justify-center gap-6 p-8">
        <div className="mt-3">
          {relevantTabs.map(tab => (
            <RouterTab key={tab.path} {...tab} sidebar />
          ))}
        </div>
        <div className="flex-1 max-w-6xl">
          <Outlet />
        </div>
      </div>
    );
  }

  return (
    <>
      <Tab.Group>
        <Tab.List
          className={classNames('w-full border-b border-column-gray-100')}
          id={id}
        >
          {relevantTabs.map(tab => (
            <RouterTab key={tab.path} {...tab} />
          ))}
        </Tab.List>
      </Tab.Group>
      <Outlet />
    </>
  );
}
