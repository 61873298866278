import { getBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { Product } from 'lib/enums';
import { removeUndefinedFields } from 'lib/helpers';
import { ObituaryModel } from 'lib/model/objects/obituaryModel';
import { OrderModel } from 'lib/model/objects/orderModel';
import { ObituaryService } from 'lib/services/obituaryService';
import { ERef, ESnapshotExists, EUser } from 'lib/types';
import { Obituary } from 'lib/types/obituary';
import { Order } from 'lib/types/order';
import { getFirebaseContext } from 'utils/firebase';
import { isObituaryModel } from 'lib/model/objects/adModel';
import { ResponseOrError, wrapError, wrapSuccess } from 'lib/types/responses';
import { getErrorReporter } from 'lib/utils/errors';
import { ColumnService } from 'lib/services/directory';
import { getModelFromRef } from 'lib/model';
import { OrganizationModel } from 'lib/model/objects/organizationModel';
import IPlacementFlowHelper from '.';
import { MINIMUM_ORDER } from '../../steps/PersonalDetail';

class ObituaryPlacementFlowHelper
  implements IPlacementFlowHelper<Obituary, 'obituaries'>
{
  private context = getFirebaseContext();

  private obituaryService = new ObituaryService(this.context);

  async createAd(
    user: ESnapshotExists<EUser>
  ): Promise<ResponseOrError<ERef<Order>, Error>> {
    let activeOrganizationModel: OrganizationModel | undefined;
    const orgRef = user.data().activeOrganization;
    const enableIndividualObituaryPlacement = getBooleanFlag(
      LaunchDarklyFlags.ENABLE_INDIVIDUAL_OBITUARY_PLACEMENT,
      false
    );

    if (!enableIndividualObituaryPlacement && !orgRef) {
      return wrapError(
        new Error(
          `User must have an active organization to create an obituary: ${user.id}`
        )
      );
    }

    if (orgRef) {
      activeOrganizationModel = await getModelFromRef(
        OrganizationModel,
        getFirebaseContext(),
        orgRef
      );
    }

    const isPublisherOrganization =
      !!activeOrganizationModel?.isPublisherOrganization;
    const orgSupportsObituaryPlacement =
      !!activeOrganizationModel?.hasAdTypeActive(Product.Obituary);

    const shouldSetAdvertiserInfo =
      !activeOrganizationModel ||
      (!isPublisherOrganization && orgSupportsObituaryPlacement);

    const extraInfo = shouldSetAdvertiserInfo
      ? {
          advertiserOrganization: activeOrganizationModel
            ? activeOrganizationModel.ref
            : null,
          advertiser: user.ref
        }
      : { ...MINIMUM_ORDER };

    const obituaryModel = await this.obituaryService.create(
      removeUndefinedFields({
        user: user.ref,
        authorizedOrganization: activeOrganizationModel
          ? activeOrganizationModel.ref
          : null,
        ...extraInfo
      })
    );

    return wrapSuccess(obituaryModel.modelData.order);
  }

  async getAdModel(
    orderModel: OrderModel
  ): Promise<ResponseOrError<ObituaryModel>> {
    const adResult = await orderModel.getAdByVersion();

    if (adResult.error) {
      return adResult;
    }

    const adModel = adResult.response;

    if (!isObituaryModel(adModel)) {
      const errorMessage = 'Not an obituary';

      const error = Error(errorMessage);

      getErrorReporter().logAndCaptureError(
        ColumnService.ORDER_PLACEMENT,
        error,
        errorMessage,
        { orderId: orderModel.id }
      );

      return wrapError(error);
    }

    return wrapSuccess(adModel);
  }
}

export default ObituaryPlacementFlowHelper;
