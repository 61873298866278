import React from 'react';
import SettingsHeader from 'routes/settings/SettingsHeader';
import { EOrganization, ESnapshotExists } from 'lib/types';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { getFirebaseContext } from 'utils/firebase';
import { ColumnService } from 'lib/services/directory';
import { PublisherPaginationSettingsService } from 'lib/services/publisherPaginationSettingsService';
import CorePaginationSettings from './CorePaginationSettings';
import AllowedLayoutSettings from './AllowedLayoutSettings';
import FillerSettings from './FillerSettings';
import HeaderSettings from './HeaderSettings';

export default function PaginationSettings({
  activeOrganization
}: {
  activeOrganization: ESnapshotExists<EOrganization>;
}) {
  const { value: publisherPaginationSettings } = useAsyncEffect({
    fetchData: async () => {
      const [settingsError, settings] =
        await new PublisherPaginationSettingsService(
          getFirebaseContext()
        ).getOrCreateDefault(activeOrganization);
      if (settingsError) return null;
      return settings;
    },
    dependencies: [activeOrganization?.id],
    errorConfig: {
      service: ColumnService.PAGINATION,
      message: 'Failed to load publisher pagination settings'
    }
  });

  return (
    <>
      <SettingsHeader
        header="Pagination"
        description="Control how Column exports notice blocks for your publication."
      />

      {publisherPaginationSettings && (
        <>
          <CorePaginationSettings
            publisherPaginationSettings={publisherPaginationSettings}
            activeOrganization={activeOrganization}
          />
          <HeaderSettings
            publisherPaginationSettings={publisherPaginationSettings}
          />
          <AllowedLayoutSettings
            publisherPaginationSettings={publisherPaginationSettings}
          />
          <FillerSettings
            publisherPaginationSettings={publisherPaginationSettings}
          />
        </>
      )}
    </>
  );
}
