import React from 'react';

export type SettingsHeaderProps = {
  description?: `${Capitalize<string>}.`;
  children?: React.ReactNode;
  header: string;
};

/**
 * Default header component for our settings pages
 */
export default function SettingsHeader({
  header,
  description,
  children
}: SettingsHeaderProps) {
  return (
    <div className="flex pl-7 pr-5 py-5 border-b border-gray-300">
      <div className="w-full font-medium">
        <p className="text-xl text-blue-800">{header}</p>
        <p className="text-sm leading-6 text-grey-400">{description}</p>
      </div>
      {children && (
        <div className="w-full flex justify-end items-end">{children}</div>
      )}
    </div>
  );
}
