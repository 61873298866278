import { RouteObject } from 'react-router-dom';
import {
  ORGANIZATION_SETTINGS_ROUTE,
  ORGANIZATION_SETTINGS_ROUTES
} from 'router/routes';
import CustomBrandSettings from 'routes/settings/CustomBrandSettings';
import OrganizationMemberSettings from 'routes/settings/OrganizationMemberSettings';
import OrganizationSettings from 'routes/settings/OrganizationSettings';
import { AdvertisingDeadlinesSettings } from 'routes/settings/publisher/deadlines';
import FTPSettings from 'routes/settings/publisher/upload';
import AffidavitSettings from 'routes/settings/publisher/affidavits';
import { BillingSettings } from 'routes/settings/publisher/billing';
import Rates from 'routes/settings/publisher/rates';
import NoticeTypeSettings from 'routes/settings/publisher/filingTypes/NoticeTypeSettings';
import DesignAndLayoutSettings from 'routes/settings/publisher/designAndLayoutSettings';
import Payouts from 'routes/settings/publisher/paymentSettings';
import CustomersSettings from 'routes/settings/publisher/customers/CustomersSettings';
import ProductSettings from 'routes/settings/publisher/ProductSettings';
import { Product } from 'lib/enums';
import UserSettingsBankAccounts from 'routes/settings/userSettings/BankAccountSettings';
import PublisherSettings from 'routes/settings/userSettings/publisherSettings';
import { GeneralInfo } from 'routes/settings/GeneralInfo';

export const organizationSettingsRoutes: RouteObject = {
  element: <OrganizationSettings />,
  children: [
    {
      path: ORGANIZATION_SETTINGS_ROUTE
    },
    {
      path: ORGANIZATION_SETTINGS_ROUTES.AFFIDAVITS,
      element: <AffidavitSettings />
    },
    {
      path: ORGANIZATION_SETTINGS_ROUTES.BANK_ACCOUNTS,
      element: <UserSettingsBankAccounts />
    },
    {
      path: ORGANIZATION_SETTINGS_ROUTES.BILLING,
      element: <BillingSettings />
    },
    {
      path: ORGANIZATION_SETTINGS_ROUTES.BRANDING,
      element: <CustomBrandSettings />
    },
    {
      path: ORGANIZATION_SETTINGS_ROUTES.CLASSIFIEDS,
      element: <ProductSettings productLine={Product.Classified} />
    },
    {
      path: ORGANIZATION_SETTINGS_ROUTES.CUSTOMERS,
      element: <CustomersSettings />
    },
    {
      path: ORGANIZATION_SETTINGS_ROUTES.DEADLINES,
      element: <AdvertisingDeadlinesSettings />
    },
    {
      path: ORGANIZATION_SETTINGS_ROUTES.DESIGN_AND_LAYOUT,
      element: <DesignAndLayoutSettings />
    },
    { path: ORGANIZATION_SETTINGS_ROUTES.FTP, element: <FTPSettings /> },
    {
      path: ORGANIZATION_SETTINGS_ROUTES.GENERAL,
      element: <GeneralInfo />
    },
    {
      path: ORGANIZATION_SETTINGS_ROUTES.MEMBERS,
      element: <OrganizationMemberSettings />
    },
    {
      path: ORGANIZATION_SETTINGS_ROUTES.NOTICE_TYPES,
      element: <NoticeTypeSettings />
    },
    {
      path: ORGANIZATION_SETTINGS_ROUTES.OBITUARIES,
      element: <ProductSettings productLine={Product.Obituary} />
    },
    {
      path: ORGANIZATION_SETTINGS_ROUTES.PAYOUTS,
      element: <Payouts />
    },
    {
      path: ORGANIZATION_SETTINGS_ROUTES.PUBLISHER_SETTINGS,
      element: <PublisherSettings />
    },
    {
      path: ORGANIZATION_SETTINGS_ROUTES.RATES,
      element: <Rates />
    }
  ]
};
