import React from 'react';
import {
  CardGridLayout,
  GridInput,
  useEditableCard
} from 'lib/components/Card/Grid';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { Form } from 'lib/components/Form';
import { getFirebaseContext } from 'utils/firebase';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { EOrganization, ESnapshotExists, exists } from 'lib/types';
import CheckboxInput from 'lib/components/Checkbox/CheckboxInput';
import { PublisherPaginationSettings } from 'lib/types/publisherPaginationSettings';
import { useAppDispatch } from 'redux/hooks';
import ToastActions from 'redux/toast';

export default function CorePaginationSettings({
  publisherPaginationSettings,
  activeOrganization
}: {
  publisherPaginationSettings: ESnapshotExists<PublisherPaginationSettings>;
  activeOrganization: ESnapshotExists<EOrganization>;
}) {
  const dispatch = useAppDispatch();

  const { formSettings, onChange, onSubmitWrapper, editable, disabled } =
    useEditableCard(publisherPaginationSettings.data());

  const { value: availableTemplates } = useAsyncEffect({
    fetchData: async () => {
      if (!exists(activeOrganization)) return [];
      const publisherTemplates = await getFirebaseContext()
        .adTemplatesRef()
        .where('organization', '==', activeOrganization.ref)
        .get();
      const sharedTemplates = await getFirebaseContext()
        .adTemplatesRef()
        .where(
          'publisherOrganizations',
          'array-contains',
          activeOrganization.ref
        )
        .get();
      const allTemplates = [
        ...publisherTemplates.docs,
        ...sharedTemplates.docs
      ];
      const activeTemplates = allTemplates.filter(
        template => !template.data().archived
      );
      return activeTemplates;
    },
    dependencies: [activeOrganization?.id]
  });

  const savePaginationSettings = async () => {
    const relevantSettings = {
      flowText: formSettings.flowText,
      enableAdSplitting: formSettings.enableAdSplitting,
      adTemplate: formSettings.adTemplate
    };
    try {
      await publisherPaginationSettings.ref.update(relevantSettings);
      return { success: true };
    } catch (e) {
      dispatch(
        ToastActions.toastError({
          headerText: 'Failed to save pagination settings',
          bodyText: 'Please try again later.'
        })
      );
      return { success: false };
    }
  };

  return (
    <Form
      onSubmit={() => onSubmitWrapper(savePaginationSettings)}
      id="pagination-settings-form"
    >
      <CardGridLayout
        editable={editable}
        header={{
          title: 'Core Settings',
          description:
            'Basic controls that determine what the PDFs generated for your publication will look like.'
        }}
      >
        <GridInput fullWidth>
          <ColumnSelect
            options={(availableTemplates || []).map(template => ({
              value: template.ref.id,
              label: template.data().name
            }))}
            value={formSettings.adTemplate?.id}
            onChange={templateId => {
              const selectedTemplate = availableTemplates?.find(
                template => template.ref.id === templateId
              );
              if (selectedTemplate) {
                onChange({ adTemplate: selectedTemplate.ref });
              }
            }}
            id="auto-flow-template"
            labelText="Select a template whose layout you want to use for auto-flowing text"
            required
            disabled={disabled}
          />
        </GridInput>
        <GridInput>
          <CheckboxInput
            labelDescription="If you select this option we will automatically flow text from one column to the next in bulk downloads."
            checked={formSettings.flowText}
            onChange={() => {
              onChange({ flowText: !formSettings.flowText });
            }}
            id="flow-text"
            labelText="Flow text"
            disabled={disabled}
          />
        </GridInput>
        <GridInput>
          <CheckboxInput
            labelDescription="If you select this option we will sometimes split ads into multiple columns in bulk downloads."
            checked={formSettings.enableAdSplitting}
            onChange={() => {
              onChange({ enableAdSplitting: !formSettings.enableAdSplitting });
            }}
            id="enable-ad-splitting"
            labelText="Enable ad splitting"
            disabled={disabled}
          />
        </GridInput>
      </CardGridLayout>
    </Form>
  );
}
