import FullScreenModal from 'components/FullScreenModal';
import { EditableCardArray } from 'lib/components/Card/EditableCardArray';
import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { TextField } from 'lib/components/TextField';
import { ProductPublishingSettingModel } from 'lib/model/objects/productPublishingSettingModel';
import {
  ESnapshotExists,
  AdRate,
  ERef,
  Package,
  PackagePublisherSettings
} from 'lib/types';
import { ProductPublishingSetting } from 'lib/types/publishingSetting';
import { enumToSelectInput, FilingTypeVisibility, Product } from 'lib/enums';
import { ClassifiedFilingTypeNames } from 'lib/types/classified';
import { ObituaryFilingTypeNames } from 'lib/types/obituary';
import { useCallback, useState } from 'react';
import { useAppDispatch } from 'redux/hooks';
import {
  PackagePublisherSettingsCard,
  PackagePublisherSettingsCardProps
} from './PackagePublisherSettingsCard';
import {
  PackageFormData,
  updatePackage,
  createPackage
} from './packagesTableActions';
import { PublisherOrgSharingCard } from '../../sharing/PublisherOrgSharingCard';

const filingTypeLabelsMap = {
  [Product.Classified]: ClassifiedFilingTypeNames,
  [Product.Obituary]: ObituaryFilingTypeNames,
  [Product.Notice]: {}
};

type EditPackageModalFormProps = {
  productPublishingSettings: ProductPublishingSettingModel[];
  orgRates: ESnapshotExists<AdRate>[];
  onClose: () => void;
  product: Product;
  currentPackage?: ESnapshotExists<Package>;
};

export function EditPackageModalForm({
  onClose,
  product,
  currentPackage,
  productPublishingSettings,
  orgRates
}: EditPackageModalFormProps) {
  const dispatch = useAppDispatch();
  const isEditMode = !!currentPackage;
  const filingTypeLabels = Object.values(filingTypeLabelsMap[product]);
  const [formData, setFormData] = useState<PackageFormData>(
    isEditMode
      ? { ...currentPackage?.data() }
      : {
          title: '',
          description: '',
          publisherOrganizations: [],
          filingTypeLabel: filingTypeLabels[0],
          product,
          publisherSettings: [
            {
              defaultRate: null,
              productPublishingSetting: productPublishingSettings[0].ref
            }
          ]
        }
  );

  const usedProductPublishingSettings = formData.publisherSettings.map(
    setting => setting.productPublishingSetting
  );
  const relevantPackagePublisherSettings: PackagePublisherSettings[] = [];
  const otherPackagePublisherSettings: PackagePublisherSettings[] = [];
  formData.publisherSettings.forEach(setting => {
    const isRelevantSetting = productPublishingSettings.some(
      publisherSetting =>
        publisherSetting.id === setting.productPublishingSetting.id
    );

    isRelevantSetting
      ? relevantPackagePublisherSettings.push(setting)
      : otherPackagePublisherSettings.push(setting);
  });

  const editablePackagePublisherSettingCardWrapper = useCallback(
    ({
      value,
      onChange,
      index
    }: Pick<
      PackagePublisherSettingsCardProps,
      'value' | 'onChange' | 'index'
    >) => (
      <PackagePublisherSettingsCard
        index={index}
        value={value}
        onChange={onChange}
        availableRates={orgRates}
        availableProductPublishingSettings={filterAvailableProductPublishingSettings(
          productPublishingSettings,
          usedProductPublishingSettings,
          value.productPublishingSetting
        )}
      />
    ),
    []
  );

  return (
    <FullScreenModal
      headerText={isEditMode ? 'Edit Package' : 'Create Package'}
      id="edit-package-modal"
      submittable={{
        buttonText: isEditMode ? 'Save' : 'Create Package',
        onSubmit: async () => {
          const [error] = isEditMode
            ? await dispatch(updatePackage(formData, currentPackage))
            : await dispatch(createPackage(formData));

          !error && onClose();
        },
        disabled: false
      }}
      onClose={onClose}
    >
      <CardGridLayout
        header={{
          title: 'Basic Properties',
          description: 'Configure basic properties for this package.'
        }}
      >
        <GridInput fullWidth>
          <TextField
            id="title"
            labelText="Title"
            required
            value={formData.title}
            onChange={title => setFormData({ ...formData, title })}
          />
        </GridInput>
        <GridInput fullWidth>
          <TextField
            id="description"
            labelText="Description"
            value={formData.description}
            onChange={description => setFormData({ ...formData, description })}
          />
        </GridInput>
        <GridInput fullWidth>
          <ColumnSelect
            id="filing-type-label"
            labelText="Category Name"
            options={filingTypeLabels.map(label => ({
              label,
              value: label
            }))}
            value={formData.filingTypeLabel}
            onChange={filingTypeLabel =>
              setFormData({ ...formData, filingTypeLabel })
            }
            required
          />
        </GridInput>
        <GridInput fullWidth>
          <ColumnSelect
            id="package--visibility"
            options={enumToSelectInput(FilingTypeVisibility)}
            labelText={`Who should be able to view this and select it for placement?`}
            onChange={visibility => {
              setFormData({ ...formData, visibility: Number(visibility) });
            }}
            value={
              formData.visibility?.toString() ??
              FilingTypeVisibility.by_key('all_users')?.value.toString()
            }
            noteText="Use this field to test new filing types before making them public"
          />
        </GridInput>
      </CardGridLayout>

      <CardGridLayout
        header={{
          title: 'Publisher Settings',
          description:
            'Configure publisher settings for this package. These settings will override the default settings for the selected category.'
        }}
      >
        <GridInput fullWidth>
          <EditableCardArray
            disabled={false}
            columns={1}
            baseHeader="Publisher Settings"
            onChange={publisherSettings =>
              setFormData({
                ...formData,
                publisherSettings: [
                  ...publisherSettings,
                  ...otherPackagePublisherSettings
                ]
              })
            }
            archivable={
              relevantPackagePublisherSettings.length > 1
                ? {
                    buttonText: 'Remove'
                  }
                : undefined
            }
            createable={
              relevantPackagePublisherSettings.length <
              productPublishingSettings.length
                ? {
                    buttonText: 'Add publisher settings',
                    initialValue: {
                      defaultRate: null,
                      productPublishingSetting:
                        filterAvailableProductPublishingSettings(
                          productPublishingSettings,
                          usedProductPublishingSettings
                        )[0].ref
                    }
                  }
                : undefined
            }
            Component={editablePackagePublisherSettingCardWrapper}
            values={relevantPackagePublisherSettings}
          />
        </GridInput>
      </CardGridLayout>
      <PublisherOrgSharingCard
        resourceNoun="package"
        value={formData.publisherOrganizations ?? []}
        owner={currentPackage?.data().authorizedOrganization}
        onChange={publisherOrganizations => {
          setFormData({
            ...formData,
            publisherOrganizations
          });
        }}
      />
    </FullScreenModal>
  );
}

function filterAvailableProductPublishingSettings(
  productPublishingSettings: ProductPublishingSettingModel[],
  usedProductPublishingSettings: ERef<ProductPublishingSetting>[],
  selectedProductPublishingSetting?: ERef<ProductPublishingSetting>
) {
  return productPublishingSettings.filter(
    setting =>
      !usedProductPublishingSettings.some(
        usedSetting => usedSetting.id === setting.id
      ) || selectedProductPublishingSetting?.id === setting.id
  );
}
