import LoadingState from 'components/LoadingState';
import { OrderModel } from 'lib/model/objects/orderModel';
import { ColumnService } from 'lib/services/directory';
import { CopyText } from 'lib/components/CopyText';
import { centsToDollarsString, stripHtmlTags } from 'lib/helpers';
import { NewspaperOrderModel } from 'lib/model/objects/newspaperOrderModel';
import AdDetailsCard from 'routes/notice/AdDetailsCard';
import { useAppSelector } from 'redux/hooks';
import { selectIsPublisher, selectUser } from 'redux/auth';
import { isClassifiedModel } from 'lib/model/objects/adModel';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import useSafeAsyncEffect from 'lib/frontend/hooks/useSafeAsyncEffect';
import { OrderDetailModel } from 'lib/model/objects/orderDetailModel';
import { AdDetailHeader } from './AdDetailHeader';
import { PublicationDetails } from './PublicationDetails';
import { useOrderEditableData } from '../place/hooks/useOrderEditableData';
import { OrderCustomerDetail } from './OrderCustomerDetail';
import { AdDetailsCardRow } from './AdDetailsCardRow';

type ClassifiedDetailProps = {
  order: OrderModel;
  orderDetail: OrderDetailModel;
  newspaperOrders: NewspaperOrderModel[];
  anonymousUserAuthInfo: { authEmail: string; accessCode: string } | undefined;
};

export function ClassifiedDetail({
  order,
  orderDetail,
  newspaperOrders,
  anonymousUserAuthInfo
}: ClassifiedDetailProps) {
  const userSnap = useAppSelector(selectUser);
  const isPublisher = useAppSelector(selectIsPublisher);

  const { value: classified, isLoading: loadingClassified } =
    useSafeAsyncEffect({
      fetchData: async () => order.getAdByVersion(),
      errorConfig: {
        service: ColumnService.ORDER_MANAGEMENT,
        message: 'Error fetching classified',
        tags: { orderId: order.id }
      },
      dependencies: [order.id, order.modelData.activeVersion]
    });

  const { value: orderDetailsDescription } = useAsyncEffect({
    fetchData: order.orderDetailsDescription,
    dependencies: [orderDetail.id],
    errorConfig: {
      service: ColumnService.ORDER_MANAGEMENT,
      message: 'Error fetching order details description',
      tags: { orderId: order.id }
    }
  });

  const editData = useOrderEditableData({
    userSnap,
    order,
    newspaperOrders: newspaperOrders.map(no => no.modelData)
  });

  if (loadingClassified || !classified || !isClassifiedModel(classified)) {
    return (
      <LoadingState
        context={{
          service: ColumnService.ORDER_MANAGEMENT,
          location: 'Classified Detail',
          tags: { orderId: order.id, adDetail: 'true' }
        }}
      />
    );
  }

  return (
    <div className="grid grid-cols-12 gap-3">
      <div className="col-span-12 flex justify-between lg:py-4">
        <AdDetailHeader
          title={stripHtmlTags(classified.modelData.title ?? '')}
          order={order}
          orderDetail={orderDetail}
          anonymousUserAuthInfo={anonymousUserAuthInfo}
          editData={editData}
        />
      </div>

      <div className="col-span-12 lg:col-span-8 space-y-3">
        <AdDetailsCard
          id="publication-details"
          header={{
            title: 'Publication Details'
          }}
        >
          {newspaperOrders.map(newspaperOrder => (
            <PublicationDetails
              key={newspaperOrder.id}
              newspaperOrder={newspaperOrder}
              ad={classified}
              editData={editData?.[newspaperOrder.id]}
            />
          ))}
        </AdDetailsCard>
        {isPublisher && (
          <AdDetailsCard
            id="customer-details"
            header={{ title: 'Customer Details' }}
          >
            <OrderCustomerDetail order={order} />
          </AdDetailsCard>
        )}
      </div>

      <div className="col-span-12 lg:col-span-4">
        <AdDetailsCard
          id="order-details"
          header={{
            title:
              !isPublisher || !orderDetailsDescription ? (
                'Order Details'
              ) : (
                <CopyText copyText={orderDetailsDescription}>
                  Order Details
                </CopyText>
              )
          }}
        >
          <AdDetailsCardRow
            label="Order ID"
            value={<CopyText copyText={order.id}>{order.id}</CopyText>}
          />
          <AdDetailsCardRow
            label="Price"
            value={
              orderDetail.modelData.pricing?.totalInCents !== undefined
                ? `$${centsToDollarsString(
                    orderDetail.modelData.pricing?.totalInCents
                  )}`
                : ''
            }
          />
        </AdDetailsCard>
      </div>
    </div>
  );
}
