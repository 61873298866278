import React, { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

type LinkToProps = {
  id?: string;
  href: string;
  className?: string;
  children: ReactNode;
  target?: string;
};

/**
 * Note: our react-router setup is a bit strange so we can't use the normal <Link> tag.
 * This custom component fills in for the most basic use cases.
 */
function LinkTo({ id, href, className, children, target }: LinkToProps) {
  const navigate = useNavigate();

  return (
    <a
      id={id}
      className={className}
      href={href}
      onClick={e => {
        // When ctrlKey is pressed the user is trying to open the link a new
        // tab, so we let the default happen.
        //
        // When ctrlKey is NOT pressed this should avoid the browser default
        // behavior and do a router action.
        if (!(e.ctrlKey || e.metaKey) && target !== '_blank') {
          e.preventDefault();
          navigate(href);
        }
      }}
      target={target}
    >
      {children}
    </a>
  );
}

export default LinkTo;
