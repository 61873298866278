import { z } from 'zod';
import { AXOrder } from '../asyncDesign/types';
import {
  PayColumnInvoices,
  OrgContextState,
  ExpressSettings
} from './organization';
import { AffinityXOrderNumber } from '../integrations/affinityx/types';
import {
  EPayoutData,
  EStripeBankAccount,
  EStripePaperCheck,
  EStripePaperCheckListParams,
  EStripePaymentMethodCard
} from './stripe';
import { CheckSearchRequest } from './paperCheck';
import {
  ProductSiteSettingUpdateRequest,
  ProductSiteSettingCreateResponse,
  ProductSiteSettingCreateRequest
} from './productSiteSetting';
import {
  BulkDownloadRequest,
  BulkDownloadResponse,
  ECache,
  EOrganization,
  ERate,
  ERef,
  EStripePaymentMethodUSBankAccount,
  EStripePayout,
  ESubscription,
  ETemplateStyles
} from '.';
import { LoadPayInvoiceResponse, PaymentMethods } from './invoices';
import { DisplaySiteSubdomain } from './displaySite';
import { PayoutGroup, PayoutParentRowData } from './payout';
import {
  ElasticCustomerSearchResults,
  ElasticDocumentType,
  ElasticPublicNoticeSearchResults,
  SearchableCustomerRecordFilter,
  SearchableNoticeRecord,
  SearchableNoticeRecordFacet,
  SearchableNoticeRecordFilter,
  SearchableOrganizationRecord,
  SearchableOrganizationRecordFilter,
  SearchableInvoiceRecordFilter,
  SearchablePublicNoticeRecordFacet,
  SearchablePublicNoticeRecordFilter,
  SORT_DIRECTIONS,
  ElasticOrderSearchResults,
  SearchableNewspaperOrderRecordFilter,
  ElasticOrderFacetsResults,
  SearchableNewspaperOrderRecordFacet,
  SearchableNewspaperOrderRecord,
  SearchableAdjudicationAreaRecordFilter,
  SearchableAdjudicationAreaRecord,
  SearchableInvoiceRecordWithCheck,
  SearchableInvoiceRecord,
  SearchablePublicationIssueSectionRecordFilter,
  SearchablePublicationIssueSectionRecord,
  SearchableExpressEmailConversationRecord
} from './searchable';
import { EUser } from './user';
// NOTE: Do not use the `ResponseOrError` type directly as a Response type.
// Instead, use the `ApiResponseOrError` type, which is designed to preserve
// the error message over the wire (use `sendResponseOrError` to easily
// convert a `ResponseOrError` to an `ApiResponseOrError`).
import { ApiResponseOrError, SearchedOrganizations } from './responses';
import {
  ColumnPaymentResponse,
  InvoiceTransactionsCreateAuthorizationRequest,
  InvoiceTransactionsCreateChargeRequest,
  InvoiceTransactionsCreateSessionRequest,
  InvoiceTransactionsCreateSessionResponse,
  InvoiceTransactionsFetchRefundTransactionsResponse,
  PaymentGatewayResult
} from './invoiceTransaction';
import {
  ImplementationStatus,
  OrganizationType,
  Product,
  TimezoneType
} from '../enums';
import { IntegrationCustomerInfo } from './integrations/request';
import { SyncTriggerEvent } from './events';
import { RecordPreservationSettings } from './mergeOrganizations/recordPreservationSettings';
import { IgnoreCustomSettings } from './mergeOrganizations/ignoreCustomSettings';
import { FileType } from './mime';
import { DocumentProperties } from './documents';
import { RegenDocType } from './documents/regeneration';
import { UploadMethod } from '../affidavits';
import { AutomatedAffidavitSignerValue } from '../enums/AutomatedAffidavitSigners';
import { AutomatedAffidavitNotaryValue } from '../enums/AutomatedAffidavitNotaries';
import { SigninProviderType } from '../enums/SigninProviderType';
import {
  AutomatedAffidavitNotarizationBatch,
  DigitalSigNotarizationNotice,
  FastWetSigNotarizationNotice,
  ManualVerificationRunData,
  WetSigNotarizationNotice
} from '../affidavits/types';
import { UpdateSettingRequestTypes } from './updateSettingsRequest';
import { AdminDocumentUpdateRequest } from './admin';
import { OrderCreateRequest, OrderCreateResponse } from './order';
import { AffidavitData } from './documents/affidavits';
import { StorageReference } from './firebase';
import { AffidavitTemplateV2 } from './affidavitTemplate';
import { ProofNotaryObject } from '../affidavits/proof';
import { VisionDataBotResponse } from './rpa';
import { OrganizationTypeValue } from '../enums/OrganizationType';
import { DisplayAdEmailNotification } from '../displayAds';
import { SerializedAssignNoticesItem } from './affidavits/wetSignature';
import { NotarizationStatus } from './notarization';
import { EEditionStatus, EEditionUploadMethod } from './eedition';
import { SerializedOrganizationModel } from '../model/objects/organizationModel';
import { SerializedPublicationIssueModel } from '../model/objects/publicationIssueModel';
import { SearchSubdomainsRequest, SubdomainQueryResponse } from './subdomain';
import { OrderDisplayNewspaperResponse } from './orderDisplay';
import { PublishingMedium } from '../enums/PublishingMedium';
import {
  OrderCouponApply,
  OrderCouponRemove,
  CouponSaveRequest
} from './coupon';
import {
  FullNoticePaginationSubmissionAudit,
  FullOrderPaginationSubmissionAudit
} from './pagination';
import { InvitedUserValues } from './invite';
import { NewspaperOrderStatus } from './newspaperOrder';
import { ExpressPlacementBilling } from './expressPlacementBilling';
import { AutomaticBulkDownloadRequest } from './requests';
import { Obituary } from './obituary';

export const HEADER_SESSION_ID_KEY = 'x-column-sessionid';
export const HEADER_COLUMN_APP = 'x-column-app';

type GenericResponse = {
  success?: boolean;
  error?: string;
};

type AdminInvoiceRegenerationResponse = GenericResponse & {
  message?: string;
};

export type AdminCreateNoticeTransferResponse = {
  /**
   * Same as the `transfer` prop on ENotice but instead of a transfer ref, we use a string for the ID
   */
  transfer: boolean | string | null | undefined;
  reasonForNoTransfer?: string;
};

type PasswordResetEmailResponse = GenericResponse & {
  noUserFound: boolean;
};

type AffidavitAutomationAssignNoticesToMailCentersResponse =
  | {
      success: true;
    }
  | {
      success: false;
      error: string;
    };

export type RelatedPaperOption = {
  label: string;
  value: string;
  state: number;
};

type GetRelatedPapersResponse = ApiResponseOrError<{
  subdomainPaper: RelatedPaperOption | null;
  publisherActivePaper: RelatedPaperOption | null;
  relatedPapers: RelatedPaperOption[];
}>;

type AdminOrganizationsSubdomainSettingsResponse = ApiResponseOrError<null>;

type AffidavitAutomationGetNoticesResponse = {
  success?: boolean;
  /**
   * @deprecated TODO: remove once we deprecate the affidavits Retool page
   */
  noticeIds: string[];
  notarizationNotices: DigitalSigNotarizationNotice[];
};

type AffidavitAutomationGetRunsToVerifyResponse = {
  runsToVerify: ManualVerificationRunData[];
};

type AffidavitAutomationGetFullWetSigNotarizationNoticesResponse =
  | {
      success: true;
      notarizationNotices: WetSigNotarizationNotice[];
    }
  | {
      success: false;
      error: string;
    };

type AffidavitAutomationGetNoticesForWetSigNotarizationResponse =
  | {
      success: true;
      notarizationNotices: FastWetSigNotarizationNotice[];
    }
  | {
      success: false;
      error: string;
    };

type AffidavitAutomationGetNoticesAssignedToMailCentersResponse =
  | {
      success: true;
      notarizationNotices: (
        | WetSigNotarizationNotice
        | FastWetSigNotarizationNotice
      )[];
    }
  | {
      success: false;
      error: string;
    };

type AffidavitAutomationAddToNotarizationResponse =
  | {
      success: true;
      partialErrors?: string[];
      notarizationId: string;
    }
  | {
      success: false;
      error: string;
    };

type AffidavitAutomationFinalizeResponse =
  | { success: true }
  | { success: false; error: any };

type AffidavitAutomationGetAllNotariesResponse = ApiResponseOrError<
  ProofNotaryObject[]
>;

type PaymentsDwollaPayoutDataResponse =
  | { success: true; payouts: PayoutGroup[] }
  | { success: false; error?: string };

type PaymentsListCustomerPaymentMethodsResponse = ApiResponseOrError<{
  cards: EStripePaymentMethodCard[];
  bank_accounts_sources: EStripeBankAccount[];
  bank_accounts_payment_methods: EStripePaymentMethodUSBankAccount[];
}>;

type PaymentsStripeCreateSetupIntentResponse =
  | { success: true; clientSecret: string }
  | { success: false; error?: string };

type PaymentsGetBankAccountPaymentMethodStatusResponse =
  | { success: true; status: string }
  | { success: false; error?: string };

type PaymentsBankAccountPaymentMethodsThatRequireMicrodepositsResponse =
  | { success: true; bankAccountPaymentMethodsRequiringMicrodeposits: string[] }
  | { success: false };

type PaymentsGetClientSecretForSetupIntentResponse =
  | { success: true; setupIntentClientSecret: string }
  | { success: false };

type PaymentsStripeCustomerDetailsResponse =
  | { success: true; name: string; email: string }
  | { success: false };

type SearchCustomersResponse =
  | ({
      success: true;
    } & ElasticCustomerSearchResults)
  | {
      success: false;
      error: Error;
    };

type NoticeAwaitingBulkInvoiceResponse =
  | {
      success: true;
      noticeAwaitingBulkInvoice: boolean;
    }
  | {
      success: false;
    };

type TemplatesForkResponse =
  | { success: true; downloadUrl: string }
  | { success: false };

type TemplatesStylesResponse =
  | { success: true; styles: ETemplateStyles }
  | { success: false; error?: string };

export type UsersInviteResponse = {
  invited: string[];
  alreadyHaveInvitesToCurrentOrg: string[];
  resend: boolean | undefined;
  emailsLinkedToNotices: string[];
  alreadyLinkedToIncompatibleOrganization: string[];
  alreadyInvitedToIncompatibleOrganization: string[];
  alreadyMembersOfInvitedOrg: string[];
  invitesUnsnoozed: number;
};

type UsersCreateAdvertiserResponse =
  | {
      success: true;
      organizationId: string;
    }
  | { success: false; error: string };

type RetoolCreateAdvertiserOrgResponse = UsersCreateAdvertiserResponse;

type RatesGenerateCodeResponse =
  | { success: true; code: number }
  | { success: false; error: string };

type PaywayQueueTransactionResponse = {
  paywayCode: string;
  paywayMessage: string;
  columnPaymentSuccess?: boolean;
  errorMessage?: string;
};

type IntegrationsCustomersFindResponse =
  | {
      success: true;
      customer: IntegrationCustomerInfo;
    }
  | {
      success: false;
      error: string;
    };

export type UserQueryResponse = Pick<EUser, 'name' | 'email' | 'occupation'> & {
  userId: string;
  organizationID: string;
  activeOrganizationID: string;
  allowedOrganizationIDs: string[];
};

type SearchAdvertiserOrganizationsResponse =
  | {
      success: true;
      organizationsList: SearchedOrganizations[];
    }
  | {
      success: false;
    };

type SearchOrdersResponse =
  | ({
      success: true;
    } & ElasticOrderSearchResults)
  | {
      success: false;
      error: Error;
    };

type SearchOrganizationsResponse = {
  results: SearchableOrganizationRecord[];
  page: number;
  error?: boolean;
};

type SearchInvoicesResponse =
  | { error: true }
  | {
      results: SearchableInvoiceRecord[];
      page: {
        current: number;
        total_pages: number;
        total_results: number;
        size: number;
      };
      error: false;
    };

type SearchPublicNoticesResponse =
  | (ElasticPublicNoticeSearchResults & { success: true })
  | {
      error: boolean;
      success: false;
    };

type SearchPublicNoticesFacetsResponse =
  | {
      facets: (string | number)[];
      success: true;
    }
  | {
      error: boolean;
      success: false;
    };

type SearchUserNoticesResponse = {
  results: SearchableNoticeRecord[];
  page: number;
  error?: boolean;
};
type UsersAuthProvidersByEmailResponse =
  | { success: true; numAuthProviders: number }
  | { success: false; error: string };

type UsersGetRequiredPasswordSecurityResponse =
  | { success: true; dwollaPasswordSecurityEnabled: boolean }
  | { success: false; error: string };

type IntegrationsVisionDataOcrResponseLocation = {
  type: 'text-location';
  location: {
    upperLeftCorner: {
      x: number;
      y: number;
    };
    height: number;
    width: number;
  };
};
type IntegrationsVisionDataOcrResponseExtraction = {
  type: 'text-extract';
  value: string;
};

type UserRegisterFailureResponse = {
  success: false;
  type:
    | 'invalid user input'
    | 'user already exists with non-temporary password'
    | 'new user has temporary password'
    | 'unknown';
  error: Error;
};
type UserRegisterSuccessResponse = {
  success: true;
  token: string;
  userId: string;
  wasUserAnonymous: boolean;
};
type UsersRegisterResponse =
  | UserRegisterFailureResponse
  | UserRegisterSuccessResponse;

export type IntegrationsVisionDataOcrResponse =
  | IntegrationsVisionDataOcrResponseExtraction
  | IntegrationsVisionDataOcrResponseLocation;

type IntegrationsTriggerEventResponse =
  | { success: true; eventId: string }
  | { success: false; error?: string };

type ExistsResponse = {
  exists: boolean;
};
type UsersGetUserByEmailResponse =
  | {
      success: true;
      userId: string;
      data: Pick<EUser, 'hasTemporaryPassword'>;
    }
  | { success: false; error?: string };

type UsersGetUsersFromCustomerOrgResponse =
  | { success: true; userIds: string[] }
  | { success: false; error?: string };

type AdminSignInResponse =
  | { success: true; email: string; firebaseToken: string }
  | { success: false };

type GoogleMicrosoftSignInResponse =
  | { success: true; firebaseToken: string }
  | { success: false };

type DocumentsPropertiesResponse =
  | { success: true; properties: DocumentProperties }
  | { success: false; error?: string };

type AdminSyncToElasticResponse =
  | { success: true }
  | { success: false; error: string };

type AdminPaginationManifestResponse =
  | { success: true; manifestUrl: string }
  | { success: false; error?: string };

type OrderFilerNameResponse = ApiResponseOrError<{
  isAnonymousOrder: boolean;
  filerFirstName: string;
  filerLastName: string;
}>;

type NoticePaginationReviewResponse =
  ApiResponseOrError<FullNoticePaginationSubmissionAudit>;
type OrderPaginationReviewResponse =
  ApiResponseOrError<FullOrderPaginationSubmissionAudit>;

type PaginationDownloadResponse =
  | { success: true; url: string | undefined }
  | { success: false; error: string };

type PaginationReadyOrdersResponse = ApiResponseOrError<
  SearchableNewspaperOrderRecord[]
>;

type GetPublicationIssueOrdersResponse =
  | { success: true; orders: SearchableNewspaperOrderRecord[] }
  | { success: false; error: string };

type AdminExternalUploadResponse = {
  dates: { value: string; success: boolean; totalNotices: number }[];
};

type AdminUserManagementSearchResponse = {
  users: UserQueryResponse[];
};

type AdminImpersonateUserResponse = {
  token: string;
};

type OrdersGrantAccessResponse =
  | { success: true; token: string; orderId: string }
  | { success: false; error: string };

type AdPriceResponse =
  | { success: true; price: number }
  | { success: false; error: string };

type PDFDownloadResponse = ApiResponseOrError<{ url: string }>;

type DocumentsAffidavitsPreviewResponse = ApiResponseOrError<StorageReference>;

/**
 * The proofDownload can either be the contents of a file or a URL.
 * If the value is a Buffer, it represents the actual file contents that need to be downloaded.
 * If the value is a string, it represents a URL from which the file can be downloaded.
 * This distinction is important because a string here does not indicate a plaintext file, but rather a URL.
 */
export type OrdersDownloadProofsData = {
  fileNameWithoutExtension: string;
  proofDownload: string | Buffer | null;
  fileType: FileType;
};

type GetOrCreateCustomerWithCustomerOrganizationResponse =
  | { success: true; customerId: string }
  | { success: false; error: string };

export type DisplayQAMessage = {
  id: string;
  label: string;
  description: string;
  level: 'warning' | 'error' | 'success';
};
export type DisplayQAResult = {
  success: boolean;
  messages: DisplayQAMessage[];
};

export type EEditionTrackerRowData = {
  publicationIssue: SerializedPublicationIssueModel;
  publisher: SerializedOrganizationModel;
  parent: SerializedOrganizationModel | null;
};
type AdminAffidavitsGetEEditionTrackerDataResponse = ApiResponseOrError<
  EEditionTrackerRowData[]
>;

type SearchAdjudicationAreaResponse = {
  results: SearchableAdjudicationAreaRecord[];
  page: {
    current: number;
    total_pages: number;
    total_results: number;
    size: number;
  };
};

export const AutoLayoutRequestElementSchema = z.object({
  id: z.string(),
  order_type: z.string(),
  variants: z.array(
    z.object({
      width: z.number(),
      height: z.number()
    })
  )
});
export type AutoLayoutRequestElement = z.infer<
  typeof AutoLayoutRequestElementSchema
>;

export const LayoutBlockFixedElementSchema = z.object({
  id: z.string(),
  height: z.number(),
  width: z.number(),
  x: z.number(),
  y: z.number()
});
export type LayoutBlockFixedElement = z.infer<
  typeof LayoutBlockFixedElementSchema
>;

export const LayoutBlockOptionalElementSchema = z.object({
  id: z.string(),
  width: z.number(),
  height: z.number(),
  columns: z.number()
});

export const AutoPaginationRequestLayoutSchema = z.object({
  width: z.number(),
  height: z.number(),
  id: z.string(),
  max_whitespace: z.number(),
  fixed_elements: z.array(LayoutBlockFixedElementSchema),
  optional_elements: z.array(LayoutBlockOptionalElementSchema)
});
export type AutoPaginationRequestLayout = z.infer<
  typeof AutoPaginationRequestLayoutSchema
>;

export const AutoPaginationRequestLayoutBlockSchema = z.object({
  elements: z.array(AutoLayoutRequestElementSchema),
  layout: AutoPaginationRequestLayoutSchema,
  whitespace: z.number(),
  fixed_elements: z.array(LayoutBlockFixedElementSchema)
});

const AutoLayoutResponseElementSchema = z.object({
  id: z.string(),
  width: z.number(),
  height: z.number(),
  x: z.number(),
  y: z.number()
});
export type AutoLayoutResponseElement = z.infer<
  typeof AutoLayoutResponseElementSchema
>;

export const AutoPaginationResponseBlockSchema = z.object({
  elements: z.array(AutoLayoutResponseElementSchema),
  layout: AutoPaginationRequestLayoutSchema,
  whitespace: z.number()
});

export const OptimizerResponseSchema = z.array(
  AutoPaginationResponseBlockSchema
);
export type AutoPaginationResponseBlock = z.infer<
  typeof AutoPaginationResponseBlockSchema
>;
export type OptimizerResponse = z.infer<typeof OptimizerResponseSchema>;
export type OptimizerHyperparams = {
  max_time_seconds: number;
  max_whitespace: number;
  enable_split_points: boolean;
  enable_page_splitting: boolean;
  min_element_margin: number;
};

export const ExperimentDraftCompletionResponseSchema = z.object({
  content: z.string()
});

export const InboxAutomationTemplateResponseSchema = z.object({
  client: z.string(),
  state: z.string(),
  templateContent: z.string()
});
export type InboxAutomationTemplateResponse = z.infer<
  typeof InboxAutomationTemplateResponseSchema
>;

/**
 * Response types for our API endpoints.
 */
export type EResponseTypes = {
  'admin/organizations/bulk-refresh/notices': ApiResponseOrError<void>;
  'admin/organizations/layout-settings/update': ApiResponseOrError<void>;
  'admin/display-site-uploads/add-display-site-upload-id': ApiResponseOrError<void>;
  'admin/display-site-uploads/delete-display-site-upload-id': ApiResponseOrError<void>;
  'admin/display-site-uploads/update-display-site-upload-id': ApiResponseOrError<void>;
  'admin/display-site-uploads/generate-feed-key': ApiResponseOrError<string>;

  'admin/organizations/display-sites/update': ApiResponseOrError<void>;
  'admin/affidavits/organizations/:organizationId/update-edition-portal-url': ApiResponseOrError<void>;
  'admin/notices/:noticeId/force-archive': ApiResponseOrError<void>;
  'admin/organizations/refresh-affidavit-settings': ApiResponseOrError<void>;
  'search/publication-issue-sections': ApiResponseOrError<{
    results: SearchablePublicationIssueSectionRecord[];
    page: {
      current: number;
      total_pages: number;
      total_results: number;
      size: number;
    };
  }>;
  'docs/oauth': ApiResponseOrError<{ redirectUrl: string }>;
  'conversation-requests/search': ApiResponseOrError<
    SearchableExpressEmailConversationRecord[]
  >;
  'conversation-requests/refresh': ApiResponseOrError<void>;
  'archive/parse-twp': ApiResponseOrError<{
    notices: number;
    file: string;
    content: string;
  }>;
  'admin/organizations/duplicate-publisher': ApiResponseOrError<{
    newPublisherId: string;
  }>;
  'admin/customer-organizations/:customerOrganizationId/update': ApiResponseOrError<void>;
  'admin/rates/:rateId/update': ApiResponseOrError<void>;
  'openapi/': any;
  'admin/affidavits/scrape-edition-run-date': ApiResponseOrError<{
    success: boolean;
    editionPDFUrl: string;
  }>;
  'admin/organizations/parent-org-settings': ApiResponseOrError<{
    success: boolean;
  }>;
  'admin/organizations/express-settings': ApiResponseOrError<{
    success: boolean;
  }>;
  'admin/organizations/advertiser-express-settings': ApiResponseOrError<{
    success: boolean;
  }>;
  'admin/affidavits/notices/refresh-ars-from-publisher': ApiResponseOrError<{
    success: boolean;
  }>;
  'users/update-role': ApiResponseOrError<{ success: boolean }>;
  'admin/orders/update-newspaper-order': ApiResponseOrError<{
    success: boolean;
  }>;
  'orders/download-rtf': ApiResponseOrError<string>;
  'admin/orders/refresh-all-proofs': ApiResponseOrError<{ success: boolean }>;
  'admin/orders/refresh-proof': ApiResponseOrError<{ success: boolean }>;
  'admin/orders/update-ad-content': ApiResponseOrError<{ success: boolean }>;
  'admin/ad-building/orders/group-ads-by-type': ApiResponseOrError<
    Record<string, { filingTypeName: string; publicationGroup: string }>
  >;
  'admin/ad-building/orders/list-display-ads': ApiResponseOrError<{
    allFiles: {
      name: string;
      parentFolderId: string;
    }[];
    allFolders: {
      name: string;
      parentFolderId: string;
    }[];
  }>;
  'admin/ad-building/orders/download-block': ApiResponseOrError<{
    url: string;
  }>;
  'pagination/universal-download': ApiResponseOrError<{
    downloadUrl: string;
  }>;
  'pagination/download-block': ApiResponseOrError<string>;
  'pagination/auto-paginate': ApiResponseOrError<
    z.infer<typeof OptimizerResponseSchema>
  >;
  'payments/download-dwolla-payouts':
    | { url: string }
    | string
    | { success: false };
  'payments/generate-dwolla-payout':
    | {
        success: boolean;
        csvRows: string[][];
        csvHeaders: string[];
      }
    | { success: false; error: string };
  'payments/dwolla-funding-source': ApiResponseOrError<void>;
  'payments/:id/refund': { success: boolean; error?: string };
  'payments/cancel-invoice': ApiResponseOrError<boolean>;
  'payments/download-payouts': ApiResponseOrError<{ url: string }>;
  'payments/stripe-login-link': ApiResponseOrError<string>;
  'payments/stripe-bt-payouts-data': ApiResponseOrError<{
    balanceTransactions: EStripePayout[];
  }>;
  'payments/stripe-payout-data': ApiResponseOrError<{
    payouts: EStripePayout[];
  }>;
  'admin/organizations/funeral-homes/convert-to-funeral-home': ApiResponseOrError<{
    success: boolean;
  }>;
  'payments/create-receipt-pdf': ApiResponseOrError<{ receiptUrl: string }>;
  'admin/orders/transfer-ownership': ApiResponseOrError<{ success: boolean }>;
  'admin/display-qa': ApiResponseOrError<DisplayQAResult>;
  'admin/ad-building/download-block': ApiResponseOrError<{ url: string }>;
  'admin/affidavits/notices/:noticeId/get-full-wet-sig-notarization-notices': AffidavitAutomationGetFullWetSigNotarizationNoticesResponse;
  'admin/affidavits/notices/:noticeId/force-assign-affidavit-to-notice': GenericResponse;
  'admin/affidavits/notices/:noticeId/update-affidavit-reconciliation-settings': GenericResponse;
  'admin/affidavits/get-runs-needing-verification': AffidavitAutomationGetRunsToVerifyResponse;
  'admin/affidavits/set-run-unverifiable': GenericResponse;
  'admin/affidavits/wet-signature/create-notarizations': GenericResponse;
  'admin/affidavits/wet-signature/change-notarization-statuses': GenericResponse;
  'admin/affidavits/get-eedition-tracker-data': AdminAffidavitsGetEEditionTrackerDataResponse;
  'admin/checks/:stripeCheckId/refresh': ApiResponseOrError<void>;
  'admin/coupons/:couponId/delete': ApiResponseOrError<void>;
  'admin/coupons/:couponId/update': ApiResponseOrError<void>;
  'admin/coupons/create': ApiResponseOrError<string>;
  'admin/external-upload': AdminExternalUploadResponse;
  'admin/impersonate-user': AdminImpersonateUserResponse;
  'admin/inbox-automation/templates': ApiResponseOrError<
    InboxAutomationTemplateResponse[]
  >;
  'admin/inbox-automation/templates/create': ApiResponseOrError<void>;
  'admin/inbox-automation/templates/delete': ApiResponseOrError<void>;
  'admin/inbox-automation/templates/preview': ApiResponseOrError<string>;
  'admin/invoices/detach-invoice-from-notice': GenericResponse;
  'admin/invoices/void-detached-invoice': GenericResponse;
  'admin/invoices/regenerate-invoices': AdminInvoiceRegenerationResponse;
  'admin/invoices/refund-bulk-paid-invoice': ApiResponseOrError<void>;
  'admin/notices/:noticeId/upload-file': GenericResponse;
  'admin/notices/:noticeId/replace-indd': GenericResponse;
  'admin/notices/:noticeId/upload-camera-ready-ad': GenericResponse;
  'admin/notices/:noticeId/create-notice-transfer': ApiResponseOrError<AdminCreateNoticeTransferResponse>;
  'admin/orders/cancel': GenericResponse;
  'admin/orders/void-invoice': ApiResponseOrError<null>;
  'admin/orders/cancel-invoice': ApiResponseOrError<null>;
  'admin/organizations/caches/create': GenericResponse;
  'admin/organizations/caches/modify-description': GenericResponse;
  'admin/organizations/caches/modify-required': GenericResponse;
  'admin/organizations/caches/delete-entry': GenericResponse;
  'admin/organizations/caches/update-entry': GenericResponse;
  'admin/organizations/export-settings': GenericResponse;
  'admin/organizations/invoice-settings': GenericResponse;
  'admin/organizations/plan-settings': GenericResponse;
  'admin/organizations/product-implementation-settings': GenericResponse;
  'admin/organizations/regenerate-affidavits': GenericResponse;
  'admin/organizations/subdomain-settings': AdminOrganizationsSubdomainSettingsResponse;
  'admin/organizations/stripe-settings': GenericResponse;
  'admin/organizations/upload-settings': GenericResponse;
  'admin/organizations/integration-upload-settings': GenericResponse;
  'admin/organizations/build-export-settings': GenericResponse;
  'admin/organizations/deadline-settings': ApiResponseOrError<void>;
  'admin/payments/unreconciled-checks': ApiResponseOrError<EStripePaperCheck[]>;
  'admin/payments/reconciled-checks': ApiResponseOrError<
    SearchableInvoiceRecordWithCheck[]
  >;
  'pagination/pagination-review': NoticePaginationReviewResponse;
  'pagination/order-pagination-review': OrderPaginationReviewResponse;
  'pagination/pagination-download': PaginationDownloadResponse;
  'pagination/get-pagination-ready-orders': PaginationReadyOrdersResponse;
  'admin/upload-customer-list': GenericResponse;
  'admin/verify-customer-list': GenericResponse;
  'admin/sign-in': AdminSignInResponse;
  'admin/pagination-manifest': AdminPaginationManifestResponse;
  'admin/sync-to-elastic': AdminSyncToElasticResponse;
  'admin/regenerate-documents': ApiResponseOrError<void>;
  'admin/roles': GenericResponse;
  'admin/reps': GenericResponse;
  'notices/async-design/upload-finalized-design': GenericResponse;
  'admin/shadow': GenericResponse;
  'admin/unshadow': GenericResponse;
  'admin/unshadow-an-org': GenericResponse;
  'admin/unshadow-as-columnist': GenericResponse;
  'admin/user-management/delete': ApiResponseOrError<void>;
  'admin/user-management/organization-settings': GenericResponse;
  'admin/user-management/search': AdminUserManagementSearchResponse;
  'admin/user-management/update-stripe-id': GenericResponse;
  'admin/user-management/update-user-information': GenericResponse;
  'admin/user-management/update-notification-settings': ApiResponseOrError<void>;
  'affidavit-automation/events/create-eedition-uploaded-event': GenericResponse;
  'affidavit-automation/notarization/add-to-notarization-batch': AffidavitAutomationAddToNotarizationResponse;
  'affidavit-automation/notarization/:notarizationID/finalize': AffidavitAutomationFinalizeResponse;
  'affidavit-automation/get-all-notaries-from-proof': AffidavitAutomationGetAllNotariesResponse;
  'affidavit-automation/notarization/get-notices-ready-for-notarization': AffidavitAutomationGetNoticesResponse;
  'affidavit-automation/notarization/get-notices-ready-for-wet-sig-notarization': AffidavitAutomationGetNoticesForWetSigNotarizationResponse;
  'affidavit-automation/notarization/get-notices-assigned-to-mail-centers': AffidavitAutomationGetNoticesAssignedToMailCentersResponse;
  'affidavit-automation/notarization/assign-wet-sig-notices-to-mail-pages': AffidavitAutomationAssignNoticesToMailCentersResponse;
  'affidavit-automation/notarization/process-wet-sig-notarized-affidavit': GenericResponse;
  'affidavit-automation/notarization/override-notarization-preconditions': GenericResponse;
  'affidavit-automation/notarization/:notarizationID/process-complete': ApiResponseOrError<void>;
  'affidavit-automation/send-weekly-digest': GenericResponse;
  'affidavit-automation/manually-verify-runs': GenericResponse;
  'display-ads/unapproved-display-ad-email-alert': GenericResponse;
  'display-ads/ax/:instance/orders/search': DisplayAdsAXOrdersSearchResponse;
  'documents/affidavits/preview': DocumentsAffidavitsPreviewResponse;
  'documents/properties': DocumentsPropertiesResponse;
  'documents/invoice/:invoiceId/download': ApiResponseOrError<string>;
  'documents/invoices/download': ApiResponseOrError<string>;
  'documents/unpaid-invoices-summary/download': ApiResponseOrError<
    string | null
  >;
  'documents/pdf/download': PDFDownloadResponse;
  'documents/generate-proof': ApiResponseOrError<void>;
  'documents/zip': BulkDownloadResponse;
  'ftp/parse-filemage-path': GenericResponse;
  'integrations/customers/:newspaperId/find': IntegrationsCustomersFindResponse;
  'integrations/async-design/notice/:noticeId/cancel-build-ad-request': GenericResponse;
  'integrations/async-design/notice/:noticeId/create-build-ad-request': GenericResponse;
  'integrations/visionData/ocr': ApiResponseOrError<IntegrationsVisionDataOcrResponse>;
  'integrations/trigger-event': IntegrationsTriggerEventResponse;
  'invoice-transactions/create-authorization': PaymentGatewayResult;
  'invoice-transactions/create-charge': ColumnPaymentResponse;
  'invoice-transactions/create-session': InvoiceTransactionsCreateSessionResponse;
  'invoice-transactions/:invoiceId/refund-transactions': InvoiceTransactionsFetchRefundTransactionsResponse;
  'invoices/:invoiceId/pay-invoice-data': ApiResponseOrError<LoadPayInvoiceResponse>;
  'notices/awaiting-bulk-invoice': NoticeAwaitingBulkInvoiceResponse;
  'notices/:id/cancel': ApiResponseOrError<boolean>;
  'order-display/newspapers/:newspaperId/:product': ApiResponseOrError<OrderDisplayNewspaperResponse>;
  'orders/apply-coupon': ApiResponseOrError<void>;
  'orders/remove-coupon': ApiResponseOrError<void>;
  'orders/cancel': GenericResponse;
  'orders/create': OrderCreateResponse;
  'orders/create-invoice': ApiResponseOrError<string>;
  'orders/download-proofs': ApiResponseOrError<OrdersDownloadProofsData>;
  'orders/finalize-invoice': ApiResponseOrError<void>;
  'orders/grant-access': OrdersGrantAccessResponse;
  'orders/update-price': ApiResponseOrError<void>;
  'orders/:orderId/filer-name': OrderFilerNameResponse;
  'orders/:orderId/submit-free': ApiResponseOrError<void>;
  'orders/:orderId/submit-unpaid': ApiResponseOrError<void>;
  'orders/:orderId/confirm-edits': ApiResponseOrError<void>;
  'orders/:orderId/delete': ApiResponseOrError<void>;
  'payments/create-bank-account': ApiResponseOrError<boolean>;
  'payments/delete-source': ApiResponseOrError<{ deleted: boolean }>;
  'payments/create-verified-account': ApiResponseOrError<{
    success: boolean;
    error?: string;
  }>;
  'payments/delete-payment-method': ApiResponseOrError<boolean>;
  'payments/download-payout': ApiResponseOrError<{ csvRows: string[][] }>;
  'payments/stripe-cancel-setup-intent': ApiResponseOrError<boolean>;
  'payments/dwolla-payout-data': PaymentsDwollaPayoutDataResponse;
  'payments/list-customer-payment-methods': PaymentsListCustomerPaymentMethodsResponse;
  'payments/pay-stripe-invoice': ApiResponseOrError<void>;
  'payments/update-connected-account': ApiResponseOrError<{
    accountId: string;
  }>;
  'payments/mark-invoice-paid': ApiResponseOrError<void>;
  'payments/verify-bank-account': ApiResponseOrError<void>;
  'payments/stripe-create-setup-intent': PaymentsStripeCreateSetupIntentResponse;
  'payments/get-bank-account-payment-method-status': PaymentsGetBankAccountPaymentMethodStatusResponse;
  'payments/attach-payment-method-to-customer': GenericResponse;
  'payments/bank-account-payment-methods-that-require-microdeposits': PaymentsBankAccountPaymentMethodsThatRequireMicrodepositsResponse;
  'payments/client-secret-for-setup-intent': PaymentsGetClientSecretForSetupIntentResponse;
  'payments/stripe-customer-details': PaymentsStripeCustomerDetailsResponse;
  'publication-issue/orders': GetPublicationIssueOrdersResponse;
  'retool/organizations/:newspaperId/link-newspaper-parent': GenericResponse;
  'search/advertiser-organizations': SearchAdvertiserOrganizationsResponse;
  'search/customers': SearchCustomersResponse;
  'search/invoices': SearchInvoicesResponse;
  'search/orders': SearchOrdersResponse;
  'search/orders/facets': ApiResponseOrError<ElasticOrderFacetsResults>;
  'search/organizations': SearchOrganizationsResponse;
  'search/public-notices': SearchPublicNoticesResponse;
  'search/public-notices/facets': SearchPublicNoticesFacetsResponse;
  'search/usernotices': SearchUserNoticesResponse;
  'site-settings': ApiResponseOrError<ProductSiteSettingCreateResponse>;
  'site-settings/:siteSettingId': ApiResponseOrError<null>;
  'subdomains/:subdomain': ApiResponseOrError<SubdomainQueryResponse>;
  'templates/:adTemplateId/archive': GenericResponse;
  'templates/fork': TemplatesForkResponse;
  'payway/queue-transaction': PaywayQueueTransactionResponse;
  'templates/styles': TemplatesStylesResponse;
  'users/invite': ApiResponseOrError<UsersInviteResponse>;
  'rates/generate-code': RatesGenerateCodeResponse;
  'retool/organizations/create-advertiser-org': RetoolCreateAdvertiserOrgResponse;
  'retool/users': UserQueryResponse[];
  'users/create-advertiser': UsersCreateAdvertiserResponse;
  'users/delete-active-member': ApiResponseOrError<void>;
  'users/send-password-reset-email': PasswordResetEmailResponse;
  'users/get-auth-providers-by-email': UsersAuthProvidersByEmailResponse;
  'users/get-user-by-email': UsersGetUserByEmailResponse;
  'users/get-users-from-customer-org': UsersGetUsersFromCustomerOrgResponse;
  'users/get-required-password-security': UsersGetRequiredPasswordSecurityResponse;
  'users/link-filer-to-org': GenericResponse;
  'users/register': UsersRegisterResponse;
  'users/change-email': GenericResponse;
  'users/send-change-account-email-link': GenericResponse;
  'auth/signin-exchange-token-for-google-microsoft-provider': GoogleMicrosoftSignInResponse;
  'organization-management/merge': GenericResponse;
  'organizations/:subdomain/context': OrgContextState;
  'organizations/related-papers': GetRelatedPapersResponse;
  'organizations/:organizationType/exists': ExistsResponse;
  'organizations/:organizationType/create': ApiResponseOrError<string>;
  'organization-management/delete': GenericResponse;
  'retool/duplicate-newspaper': GenericResponse;
  'customers/send-update-billing-status-request': GenericResponse;
  'customers/verify-billing-request-to-send-notification': GenericResponse;
  'customers/bulk-invoicing-eligibility': GenericResponse;
  'customers/get-or-create-with-customer-organization': GetOrCreateCustomerWithCustomerOrganizationResponse;
  'customers/get-or-create-customer': GetOrCreateCustomerWithCustomerOrganizationResponse;
  'ad-pricing/price': AdPriceResponse;
  'image/upscale': ApiResponseOrError<{ url: string }>;
  'ledger/bulk-pay-invoices': ApiResponseOrError<string>;
  'ledger/receipt': ApiResponseOrError<string>;
  'search/adjudication-areas': ApiResponseOrError<SearchAdjudicationAreaResponse>;
  'experiments/chat-complete': ApiResponseOrError<
    z.infer<typeof ExperimentDraftCompletionResponseSchema>
  >;
  'bulk-export': void;
};

/** Notice-level ARS made API-friendly */
type AdminNoticesUpdateAffidavitSettingsRequest = Partial<{
  affidavitsManagedByColumn: boolean;
  notarizationRequired: boolean;
  managedAffidavitTemplateId: string;
  managedAffidavitTemplateStoragePath: string;
  notarizationVendor: 'notarize' | 'manual';
  requiresInStateNotary: boolean;
  assignedMailCenter: number;
  overrideNotarizationPreconditions: boolean;
}>;

type AffidavitAutomationNotarizationAddToNotarizationRequest = {
  noticeIDs: string[];
  assignments?: {
    signer: AutomatedAffidavitSignerValue;
    notary: AutomatedAffidavitNotaryValue;
  };
};

type AffidavitAutomationFinalizeRequest = Record<string, never>;

type AffidavitAutomationOverrideNotarizationPreconditionsRequest = {
  noticeIds: string[];
};

type AffidavitAutomationBulkNotarizationRequest = {
  includeUnverifiedAffidavits: boolean;
  affidavitSigningFirstName: string;
  affidavitSigningLastName: string;
  affidavitSigningPhone: string;
  affidavitSigningEmail: string;
};

type AffidavitAutomationSendWeeklyDigestRequest = {
  newspaperId: string;
};

type AffidavitAutomationCreateEEditionUploadedEventRequest = {
  newspaperId: string;

  storagePath: string;

  uploadMethod: UploadMethod;

  /**
   * format: MM/DD/YYYY
   */
  runDateString: string;
};

type AffidavitAutomationAssignWetSigNoticesToMailPagesRequest = {
  assignedBatches: AutomatedAffidavitNotarizationBatch[];
};

type AffidavitAutomationForceAssignAffidavitToNoticeRequest = {
  affidavitStoragePath: string;
};

type AffidavitAutomationGetNoticesForWetSigNotarizationRequest = {
  includeAlreadyAssignedNotices: boolean;
};

type AffidavitAutomationGetNoticesWithMailCenterAssignmentsRequest = {
  mailCenter: number | 'all mail center assignments';
  includeAffidavitUrlAndAddresses?: boolean;
  activeOrPast?: 'active' | 'past';
};

type AffidavitAutomationProcessWetSigNotarizedAffidavitRequest = {
  noticeId: string;

  /**
   * File uploads from Retool convert the data to a string. We may
   * wish to update this request type if we move mail center pages to
   * Column Admin if we wish to pass file data via download URL or
   * storage path instead of stringified raw data
   */
  fileData: string;
  fileNameWithExtension: string;
};

type AffidavitAutomationNotarizationProcessCompleteRequest = {
  notarizationID: string;
};

type AffidavitAutomationGetRunsToVerifyRequest = {
  parentIds: string[];
};

type AffidavitAutomationSetUnverifiableRequest = {
  noticeId: string;
  /**
   * YYYY-MM-DD formatted date string
   */
  runDate: string;
  reason?: string;
};

type AffidavitAutomationManuallyVerifyRunsRequest = {
  runIds: string[];
};

type PaymentsCancelInvoiceRequest = {
  noticeId: string;
  voidReason?: string;
};

type PaymentsCreateBankAccountRequest = {
  stripeId: string;
  token: string;
};

type PaymentsCreateVerifiedAccountRequest = {
  accountNumber: string;
  accountId: string;
  publicToken: string;
  institutionName: string;
  stripeId: string;
};

type PaymentsDeleteSourceRequest = {
  stripeId: string;

  /**
   * The Stripe source ID (not the source document ID in our database)
   */
  sourceId: string;
};

type PaymentsDeletePaymentMethodRequest = {
  /**
   * The ID of the payment method that is being detached
   */
  bankAccount: string;
};

type PaymentsDownloadPayoutRequest = {
  payout: EStripePayout | EPayoutData;
  accountId: string;
};

type PaymentsStripeCancelSetupIntentsRequest = {
  /**
   * The ID of the payment method that is being detached
   */
  bankAccount: string;
};

type PaymentsListCustomerPaymentMethodsRequest =
  | {
      type: 'individual';
      userId: string;
    }
  | {
      type: 'organization';
      organizationId: string;
    };

type PaymentsPayInvoiceRequest = {
  amount: number;
  customerEmail: string;
  invoiceId: string;

  /**
   * The id of a Stripe source representing a US bank account.
   */
  stripeBankAccountSourceId?: string;

  userId?: string;
  /**
   * This is the Stripe ID for a payment method reference (src_kf73jdf…, etc.)
   */
  paymentMethodId?: string;

  /**
   * This is the human-readable payment method for in-app processed payments
   */
  paymentMethod: PaymentMethods;

  /**
   * If the user opts in, attach the card information to their Stripe customer.
   */
  saveCreditCard?: boolean;

  /**
   * Used to control whether we detach the payment method in old invoice code
   * Can be removed once pay invoice refactor is fully live
   */
  isSavedPaymentMethod?: boolean;

  /**
   * Used to identify when the individual invoice is being paid as a result of the bulk invoice being paid
   */
  paidByBulkInvoice?: boolean;

  /**
   * Used to kick off additional logging for a specific orders dashboard
   */
  isOrder?: boolean;

  /**
   * Used to kick off additional logging for a specific orders by product dashboard
   */
  product: Product;

  /**
   * Used to identify which version of the order the invoice applies to. Does not apply to notice invoices
   * as the order version structure only exists in the new schema. Loom here for more information on
   * order versions: https://www.loom.com/share/e577fdde7ab14f85b950b6d5f6b8d1ca
   */
  orderVersion?: number;
};

type PaymentsMarkInvoicePaidRequest = {
  invoiceId: string;
  payBySupport?: boolean;
  manualPaymentDetails?: {
    note: string;
    userId: string;
  };
  customer_email: string;
  paymentMethod?: PaymentMethods;
  /**
   * Used to identify which version of the order the invoice applies to. Does not apply to notice invoices
   * as the order version structure only exists in the new schema. Loom here for more information on
   * order versions: https://www.loom.com/share/e577fdde7ab14f85b950b6d5f6b8d1ca
   */
  orderVersion?: number;
};

type PaymentsStripeCreateSetupIntentRequest = {
  /**
   * The ID of the customer creating a setup intent
   */
  customer: string;
};

type PaymentsGetBankAccountPaymentMethodStatusRequest = {
  /**
   * The ID of the payment method whose status we are retrieving
   */
  payment_method: string;
};

type PaymentsAttachPaymentMethodToCustomerRequest = {
  /**
   * The ID of the payment method being attached to customer
   */
  payment_method: string;

  /**
   * The ID of the customer who we are attaching the payment method to
   */
  customer: string;
};

type PaymentsBankAccountPaymentMethodsThatRequireMicrodepositsRequest = {
  /**
   * The ID of the customer with bank accounts requiring microdeposits
   */
  customer: string;
};

type PaymentsGetClientSecretForSetupIntentRequest = {
  /**
   * The ID of the payment method whose setup intent's client secret we need to obtain
   */
  payment_method: string;
};

type PaymentsStripeCustomerDetailsRequest = {
  /**
   * The ID of the customer whose Stripe info we are trying to retrieve
   */
  customer: string;
};

type UsersInviteRequest = {
  inviteData: InvitedUserValues[];
  organizationId: string;
  isExistingUserInvite: boolean;
};

type UsersCreateAdvertiserRequest = {
  parent: string;
  organizationType: OrganizationTypeValue;
  orgName: string;
  address: string;
  city: string;
  state: number;
  zipCode: string;
  phone: string;
  subOrg?: ERef<EOrganization>;
  website?: string;
  createdBy?: string;
  addressLine2?: string;
};

type UsersDeleteInvitedMemberRequest = {
  inviteId: string;
};

type UsersDeleteActiveMemberRequest = {
  userId: string;
  organizationId: string;
};

type PaymentsVerifyBankAccountRequest = {
  /**
   * The user (or user organization's) Stripe customer ID
   */
  userStripeId: string;

  /**
   * The Stripe source ID (ex: ba_abcd123)
   */
  sourceId: string;

  /**
   * Microdeposit amounts.
   */
  amounts: number[];
};

type SearchUserNoticesRequest = {
  search: string;
  sort?: Record<string, SORT_DIRECTIONS>[];
  current?: number;
  size?: number;
  isPublisher: boolean;
  showAllOrgsNotices?: boolean;

  /**
   * User's current active organization. This is taken from the client
   * rather than the server because it may have changed recently.
   */
  activeOrganizationId: string | undefined;

  /**
   * When the user selects "My Notices" in the dropdown.
   */
  showUserOnlyNotices?: boolean;

  isArchivedTab?: boolean;
  isDraftTab?: boolean;

  /**
   * When true, only the explicit SearchableNoticeRecordFilter fields below
   * are considered.
   */
  onlyExplicitFilters?: boolean;

  /**
   * Filters which are AND-ed together.
   */
  filters?: SearchableNoticeRecordFilter[];

  /**
   * Filters which are OR-ed together;
   */
  anyFilters?: SearchableNoticeRecordFilter[];

  /**
   * Filters which must not match
   */
  noneFilters?: SearchableNoticeRecordFilter[];
};

type SearchUserNoticesFacetRequest = {
  search: string;
  facets: SearchableNoticeRecordFacet;
  filters?: SearchableNoticeRecordFilter[];
  showAllOrgsNotices?: boolean;
  hasinvoice?: boolean;
};

type SearchOrganizationsRequest = {
  search: string;
  filters: SearchableOrganizationRecordFilter[];
  pageSize?: number;
  fetchAllOrganizations?: boolean;
};

type SearchOrdersRequest = {
  search: string;
  filters: SearchableNewspaperOrderRecordFilter[];
  sort: Record<string, SORT_DIRECTIONS>[];
  pageSize?: number;
  pageNumber?: number;
};

type SearchOrdersFacetsRequest = {
  search: string;
  filters: SearchableNewspaperOrderRecordFilter[];
  facets: SearchableNewspaperOrderRecordFacet;
};

type SearchInvoicesRequest = {
  search: string;
  allFilters: SearchableInvoiceRecordFilter[];
  sort: Record<string, SORT_DIRECTIONS>[];
  pageSize?: number;
};

type SearchPublicNoticesRequest = {
  search: string;
  allFilters: SearchablePublicNoticeRecordFilter[];
  sort: Record<string, SORT_DIRECTIONS>[];
  noneFilters?: SearchablePublicNoticeRecordFilter[];
  pageSize?: number;
  current?: number;
  isDemo?: boolean;
};

type SearchPublicNoticesFacetsRequest = {
  search: string;
  facets: SearchablePublicNoticeRecordFacet;
  allFilters: SearchablePublicNoticeRecordFilter[];
  noneFilters: SearchablePublicNoticeRecordFilter[];
  isDemo?: boolean;
};

type SearchCustomersRequest = {
  search: string;
  filters: SearchableCustomerRecordFilter[];
  pageSize?: number;
};

type SearchAdjudicationAreaRequest = {
  search: string;
  filters: SearchableAdjudicationAreaRecordFilter[];
  pageSize?: number;
};

interface CreatePublicNoticePaidSubscriptionRequest
  extends Pick<
    ESubscription,
    'email' | 'frequency' | 'elasticFilters' | 'isPaidSubscription' | 'query'
  > {
  paymentId: string;
  subInterval: string;
  subIntervalCount: number;
  product: string;
  productPrice: number;
  organizationId?: string | null;
}

interface CreatePublicNoticeFreeSubscriptionRequest
  extends Pick<
    ESubscription,
    | 'email'
    | 'frequency'
    | 'elasticFilters'
    | 'query'
    | 'isPaidSubscription'
    | 'phone'
  > {
  organizationId?: string | null;
}

interface UpdateSubscriptionQueryRequest
  extends Pick<ESubscription, 'elasticFilters' | 'query'> {
  subId: string;
  subscriptionType: 'flatRate' | 'free' | 'optionalRate';
  frequency: string;
}

type TemplatesForkRequest = {
  downloadUrl: string;
  styles: Omit<ETemplateStyles, 'id'>;
};

type TemplatesStylesRequest = {
  templateId: string;
  forceRefresh?: boolean;
};

type AffidavitAutomationStakInitiationRequest = {
  newspaperId: string;
  eEditionURL: string;
  date: string; // format MM/DD/YYYY
};

type PaywayQueueTransactionRequest = {
  paywayRequestToken: string;
  creditCardNumber: string;
  expirationDate: string;
  firstName: string;
  lastName: string;
  fullAddress: string;
  city: string;
  stateAbbrev: string;
  zip: string;
  email: string;
  phone: string;
  noticeId: string;
  paymentName: string;
};

type InvoicePassthroughReportRequest = {
  startDate?: string;
  endDate?: string;
  passedThrough?: 'true' | 'false';
};

type UpdatePassthroughInvoiceRequest = {
  params: {
    invoiceId: string;
  };
  body: {
    passedThrough?: boolean;
  };
};

type PDFDownloadRequest = {
  noticeId: string;
  rasterize: boolean;
};

type EditUploadIdRequest = {
  uploadID: string;
  newspaperName: string;
  countiesBefore: [string, ...string[]];
  subdomain: DisplaySiteSubdomain;
  newUploadID: string;
  newNewspaperName: string;
  countiesAfter: [string, ...string[]];
  organizationId: string | null;
};

type AddUploadIdRequest = {
  uploadID: string;
  newspaperName: string;
  counties: [string, ...string[]];
  subdomain: DisplaySiteSubdomain;
  organizationId: string | null;
};

type SetPreferCustomerOnParentRequest = {
  parentID: string;
  newSetting: boolean | undefined;
};

type IntegrationsCustomersFindRequest = {
  email: string;
  firstName?: string;
  lastName?: string;
  phone?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: number | '' | null;
  zip?: string;
  organizationName?: string;
};

type IntegrationsCancelBuildAdRequest = {
  buildAdRequestEventId: string;
};

type IntegrationsCreateBuildAdRequest = {
  requestingUserId: string;
  orderNumber: AffinityXOrderNumber | null;
  numberOfColumns: number;
  approxHeightInInches: number;
  pageCount: number;
};

type PaymentsGenerateDwollaPayoutRequest = {
  payout: PayoutParentRowData;
};

type UsersSendPasswordResetEmailRequest = {
  email: string;
};

type UsersMigrateNoticesBetweenAccountsRequest = {
  oldUserId: string;
  newUserId: string;
  newOrganizationId?: string;
  migrateDraftNotices?: boolean;
};

type UsersRegisterRequest = {
  firstName: string;
  lastName: string;
  password: string;
  email: string;
  inviteId?: string;
  organizationId?: string;
  organizationName?: string;
  role?: number;
  hasTemporaryPassword?: boolean;
};

type UsersSendChangeAccountEmailLinkRequest = {
  email: string;
};

type UsersChangeEmailRequest = {
  token: string;
};

type SearchAdvertiserOrganizationsRequest = {
  organizationType: number;
};

type RetoolManualSyncRequest = {
  noticeId: string;
  userId: string;
};

type RetoolLinkNewspaperParentRequest = {
  parentId: string;
};

type RetoolNewAdvertiserOrgRequest = {
  name: string;
  organizationType:
    | typeof OrganizationType.government.value
    | typeof OrganizationType.law_firm.value
    | typeof OrganizationType.other_organization.value;
  address: string;
  addressLine2?: string;
  city: string;
  state: number;
  zipCode: string;
  phone: string;
  website?: string;
  email: string;
  firstName: string;
  lastName: string;
};

type InvoicesCreateBulkInvoicesLoopRequest = {
  advertisers: boolean;
  advertiserOrganizations: boolean;
};

type InvoicesCreateBulkInvoicesRequest = {
  advertiserOrgId: string | undefined;
  advertiserId: string | undefined;
  startAt: string | undefined;
  endAt: string | undefined;
};

type GenerateProofRequest = {
  draftId: string;
};

export const IntegrationsVisionDataOcrRequestSchema = z.object({
  screenshotUrl: z.string().nonempty(),
  target: z.union([
    z.object({
      type: z.literal('text'),
      search: z.string().nonempty()
    }),
    z.object({
      type: z.literal('ad-number')
    }),
    z.object({
      type: z.literal('locked-ad')
    })
  ])
});

type IntegrationsTriggerEventRequest = {
  eventType: SyncTriggerEvent['type'];
  noticeId: string;
  invoiceId?: string;
  ignoreTriggers?: boolean;
};

type OrganizationManagementMergeRequest = {
  correctOrganizationId: string;
  duplicateOrganizationId: string;
  recordPreservationSettings?: RecordPreservationSettings;
  ignoreCustomSettings?: IgnoreCustomSettings;
};

// TODO: It is not safe to pass ERate via the API
export type DuplicateNewspaperInput = Partial<
  Omit<EOrganization, 'defaultLinerRate' | 'defaultDisplayRate'> & {
    defaultLinerRate: ERate;
    defaultDisplayRate: ERate;
  }
>;

type RetoolDuplicateNewspaperRequest = {
  organizationId: string;
  newOrganizationName?: string;
  newOrganizationData?: DuplicateNewspaperInput;
};

type UsersGetUserByEmailRequest = {
  email: string;
};

type UsersGetUsersFromCustomerOrgRequest = {
  customerOrganizationId: string;
};

type UsersLinkFilerToOrgRequest = {
  organizationId: string;
  uid: string;
  occupation?: number;
  skipNoticeMigration?: boolean;
};

type OrganizationManagementDeleteRequest = {
  organizationId: string;
  recordPreservationSettings?: RecordPreservationSettings;
};

type GetRelatedPapersRequest = {
  subdomain: string;
  product: Product;
};

type AdminUserManagementDeleteRequest = {
  userId: string;
  skipDeleteAuth: boolean;
  skipDeleteStripe: boolean;
};

type FTPParseFilemagePathRequest = {
  path: string;
};

type InvoicesDownloadRequest = {
  invoiceIds: string[];
};

type UnpaidInvoicesSummaryDownloadRequest = {
  advertiserOrganizationId: string;
};

type AdminSignInRequest = {
  token: string;
};

type GoogleMicrosoftSignInRequest = {
  token: string;
  userProfile: Record<string, string>;
  uid: string;
  type: SigninProviderType;
};

type AdminShadowRequest = {
  userId?: string;
  userEmail?: string;
  orgId?: string;
  shadowAsColumnist?: boolean;
};

type AdminUnshadowOrgRequest = {
  orgId: string;
};

type DocumentsPropertiesRequest = {
  url?: string;
  filePath?: string;
  fileType: FileType;
  fileFormat: string;
};

type RetoolAsyncDesignUploadFinalizedDesignRequest = {
  noticeId: string;
  url: string;
  fileType: FileType.PDF | FileType.JPG;
  columns?: number;
  modularSizeId?: string;
};

type AdminRegenerateDocumentsRequest = {
  noticeId: string;
  docType: RegenDocType;
};

type AdminSyncToElasticRequest = {
  documentType: ElasticDocumentType;
  documentId: string;
};

type AdminUploadCustomerListRequest = {
  publisherID: string;
  organizationTypeValue: OrganizationTypeValue;
  csvContents: string;
};

type PaginationReviewNoticeRequest = {
  publicationIssueId: string;
  manifestStoragePath: string;
  attachmentStoragePaths: string[];
};

export type PaginationReviewOrderRequest = {
  publicationIssueId: string;
  sectionId: string;
  manifestStoragePath: string;
  attachmentStoragePaths: string[];
};

type PaginationDownloadRequest = {
  publicationIssueId: string;
  product: Product;
  publishingMedium: PublishingMedium;
};

type PaginationGetOrdersRequest = {
  publicationIssueId: string;
  product: Product;
  publishingMedium: PublishingMedium;
};

type AdminPaginationManifestRequest = {
  manifestStoragePath: string;
  attachmentStoragePaths: string[];
};

type AdminRepsRequest = {
  userEmail: string;
  isColumnRep: boolean;
};

type AdminRoleRequest = {
  /**
   * Optional parameter to limit role change to a specific organization. If excluded, applies
   * `role` to all organizations.
   */
  orgId?: string;
  /**
   * The role to apply to the user. Applies only to the specified organization if `orgId` is
   * included in the request.
   */
  role: number;
  updateRoleWithChildOrg?: boolean;
};

type DraftsDeleteRequest = {
  noticeId?: string;
  isdraft: boolean;
  draftownerid?: string;
  ispublisherdraft: boolean;
};

type AdminExternalUploadRequest = {
  newspaperId: string;
  startDate: string;
  endDate: string;
};

type CustomersSendUpdateBillingStatusRequest = {
  newspaperId: string;
  billingStatusUpdateRequestReason: string;
  requestType?: UpdateSettingRequestTypes;
};

type CustomersBulkInvoicingEligibilityRequest = {
  organizationId: string;
};

type AdminUserManagementSearchRequest = {
  searchTerm: string;
};

type AdminUserManagementStripeSettingsRequest = {
  userId: string;
  newStripeId: string;
  oldStripeId: string;
  updatedBy: string;
};

type AdminUserManagementUserInformationRequest = {
  userId: string;
} & Pick<
  EUser,
  | 'address'
  | 'addressLine2'
  | 'city'
  | 'state'
  | 'zipCode'
  | 'firstName'
  | 'lastName'
  | 'phone'
>;

type AdminUserManagementUserNotificationsRequest = {
  userId: string;
} & Pick<EUser, 'receiveOrganizationNotifications' | 'notifications'>;

type CustomersVerifyBillingRequestToSendNotification = {
  customerOrganizationId: string;
  requestType: string;
};

type AdminInvoiceDetachRequest = {
  noticeId: string;
};

type AdminInvoiceVoidDetachedRequest = {
  detachedInvoiceId: string;
};

type AdminOrganizationsStripeSettingsRequest = {
  payColumnInvoices?: PayColumnInvoices;
  updatedBy: string;
  oldStripeId: string;
  newStripeId: string;
  orgId: string;
  applicationFeeSettings: EOrganization['applicationFeeSettings'];
  transferTrigger: EOrganization['transferTrigger'];
};

type AdminOrganizationsSubdomainSettingsRequest = {
  orgId: string;
  subdomain: string;
};

type AdminInvoiceRegenerationRequest = {
  userId?: string;
  orgId?: string;
  stripeId: string;
};

export type OrganizationMembership = {
  organizationId: string;
  role: number;
};

type AdminUserManagementOrganizationSettingsRequest = {
  userId: string;
  orgs: OrganizationMembership[];
};

type AdminOrganizationsRegenerateAffidavitsRequest = {
  organizationId: string;
};

type PaymentsUpdateConnectedAccountRequest = {
  code: string;
  organizationId: string;
};

type AdminImpersonateUserRequest = {
  userEmail: string;
};

type AdminOrganizationsIntegrationExportSettings = AdminDocumentUpdateRequest<
  EOrganization,
  'xmlExport'
>;

type AdminOrganizationsIntegrationUploadSettings = AdminDocumentUpdateRequest<
  EOrganization,
  'integrationUploadSettings'
>;

type AdminOrganizationsBuildExportSettings = AdminDocumentUpdateRequest<
  EOrganization,
  'buildExport'
>;

type AdminOrganizationsInvoiceSettings = AdminDocumentUpdateRequest<
  EOrganization,
  'editRunDatesAfterInvoicePaid'
>;

type AdminOrganizationsUploadSettings = AdminDocumentUpdateRequest<
  EOrganization,
  'productUploadSettings'
>;

type AdminOrganizationsPlanSettingsRequest = AdminDocumentUpdateRequest<
  EOrganization,
  'planSettings'
>;

type AdminOrganizationsProductSettingRequest = {
  documentId: string;
  classifiedStatus: ImplementationStatus;
  obituaryStatus: ImplementationStatus;
  noticeStatus: number;
};

type AdminPaymentsUnreconciledChecksRequest = EStripePaperCheckListParams;

export type AdminPaymentsReconciledChecksRequest = {
  /**
   * Search invoice elastic records
   * If this is defined, invoices will be searched first, then checks will be associated with the invoices.
   */
  invoiceSearch?: string;

  /**
   * Search checks by specific properties.
   * If this is defined, checks will be searched first, then invoices will be associated with the checks.
   */
  checkSearch?: CheckSearchRequest;
};

type OrdersGrantAccessRequest = {
  authEmail: string;
  accessCode: string;
  editVersion?: number;
  forFuneralHomeVerification: boolean;
};

type OrdersConfirmEditsRequest = {
  version: number;
};

type OrdersGetFontResourcesRequest = {
  styles: Required<ETemplateStyles>;
};

type OrdersDownloadProofsRequest = {
  orderId: string;
  version: number;
};

type AdminOrganizationIntegrationCreateCache = {
  newspaperId: string;
  cacheId: string;
  data: ECache;
};

type AdminOrganizationIntegrationModifyCacheDescription = {
  newspaperId: string;
  cacheId: string;
  description: string;
};

type AdminOrganizationIntegrationModifyRequiredDescription = {
  newspaperId: string;
  cacheId: string;
  required: boolean;
};

type AdminOrganizationIntegrationCacheEntry = {
  newspaperId: string;
  cacheId: string;
  newKey: string;
  newValue: string;
  /** Optional property to distinguish create from update. */
  currentKey?: string;
  description?: string;
};

type AdminOrganizationIntegrationDeleteCacheEntry = {
  newspaperId: string;
  cacheId: string;
  deleteKey: string;
};

type RefundInvoiceRequest = {
  newspaperId: string;
  refundReason: string;
  refundAmount: string;
  isInvoiceCancellation: boolean;
};

type OrdersCreateInvoiceRequest = {
  orderId: string;
  version: number;
};

type AdPriceRequest = {
  adId: string;
  adType: Product;
};

type DocumentsAffidavitsPreviewRequest = {
  affidavitData: AffidavitData;
  templateHandlebars: string;
  logoSource: AffidavitTemplateV2['logoSource'];
  newspaperId: string;

  logoHeightInInches?: number;
  blockWidthInInches?: number;
  fontSize?: number;
};

type AdminNoticesUploadFileRequest = {
  filePath: string;
  downloadUrl: string;
};

type AdminReplaceInddRequest = {
  storagePath: string;
};

type AdminNoticesUploadCameraReadyAdRequest = {
  filePath: string;
  columns: number;
};

type AdminOrderCancelRequest = {
  orderId: string;
  cancellationReason: string;
};

type OrderCancelRequest = {
  orderId: string;
  cancellationReason: string;
  anonymousUserAuthInfo: { authEmail: string; accessCode: string } | null;
};

type GetPublicationIssueOrdersRequest = {
  publicationIssueId: string;
  product: Product;
  publishingMedium?: PublishingMedium;
};

type DisplayAdsUnapprovedDisplayAdEmailAlertRequest = {
  emailData: DisplayAdEmailNotification[];
};

type DisplayAdsAXOrdersSearchRequest = {
  fromDate: string;
  toDate: string;
};
type DisplayAdsAXOrdersSearchResponse = AXOrder[];

type AdminAffidavitsWetSignatureCreateNotarizationsRequest = {
  batches: {
    state: number;
    items: Pick<SerializedAssignNoticesItem, 'mailing' | 'notice'>[];
  }[];
};

type AdminAffidavitsWetSignatureChangeNotarizationStatusesRequest = {
  notarizationIds: string[];
  status: NotarizationStatus;
};

type GetOrCreateCustomerWithCustomerOrganizationRequest = {
  userId: string;
  organizationId: string;
  publisherId: string;
};

type GetOrCreateCustomerRequest = {
  userId: string;
  publisherId: string;
};

type AdminDisplayQARequest = {
  affinityXFile: string;
  referenceFile: string;
  originalFile: string;
};

export type DownloadablePaginationElement = {
  fileId?: string | null;
  id: string;
  width: number;
  height: number;
  x: number;
  y: number;
  columnWidth: number;
};
type AdminDownloadBlockRequest = {
  newspaperId: string;
  elements: DownloadablePaginationElement[];
  fileName: string;
};

type CancelNoticeRequest = {
  cancellationReason: string;
  isRefund: boolean;
};

export const LedgerBulkPayInvoicesRequestSchema = z.object({
  invoiceIds: z.array(z.string()).min(2),
  userId: z.string().min(20),
  organizationId: z.string().min(20).optional(),
  stripePaymentMethodId: z.string().startsWith('pm_')
});

export const AdminAffidavitsGetEEditionTrackerDataRequestSchema = z.object({
  parentIds: z.array(z.string()).nullable(),
  publicationDateFrom: z.string().regex(/^\d{4}-\d{2}-\d{2}$/),
  publicationDateTo: z.string().regex(/^\d{4}-\d{2}-\d{2}$/),
  expectedUploadMethod: z.nativeEnum(EEditionUploadMethod).nullable(),
  eeditionStatus: z
    .union([z.nativeEnum(EEditionStatus), z.literal('missing')])
    .nullable(),
  verificationStatus: z.boolean().nullable()
});
type AdminAffidavitsGetEEditionTrackerDataRequest = z.infer<
  typeof AdminAffidavitsGetEEditionTrackerDataRequestSchema
>;

export const LedgerReceiptRequestSchema = z.object({
  ledgerItemId: z.string().min(20)
});

export const OrganizationsCreateRequestSchema = z.object({
  name: z.string().nonempty(),
  circulationSize: z.number().int().positive(),
  county: z.string().nonempty(),
  address: z.string().nonempty(),
  city: z.string().nonempty(),
  state: z.number().int().positive(),
  zipCode: z.string().nonempty(),
  phone: z.string().nonempty(),
  iana_timezone: z.enum([
    TimezoneType['America/Chicago'].logical,
    ...TimezoneType.items().map(item => item.logical)
  ])
});

export const ExperimentDraftCompletionRequestSchema = z.object({
  systemPrompt: z.string(),
  userInput: z.string()
});

const getDeadlineSchema = (dayEnum: number) =>
  z.object({
    dayEnum: z.number().int().min(dayEnum).max(dayEnum),
    publish: z.boolean(),
    deadline: z.object({
      dayEnum: z.number().int().min(1).max(7),
      time: z.string().regex(/^\d{2}:\d{2}$/)
    }),
    weeks: z.number().int().optional(),
    displayOffset: z.number().int().optional()
  });
export const AdminOrganizationsDeadlineSettingsRequestSchema = z.object({
  organizationId: z.string().min(20).max(20),
  deadlines: z.tuple([
    getDeadlineSchema(1),
    getDeadlineSchema(2),
    getDeadlineSchema(3),
    getDeadlineSchema(4),
    getDeadlineSchema(5),
    getDeadlineSchema(6),
    getDeadlineSchema(7)
  ])
});

export const AdminOrganizationsFuneralHomesConvertToFuneralHomeRequestSchema =
  z.object({
    orgId: z.string().min(10).max(40)
  });
type AdminOrganizationsFuneralHomesConvertToFuneralHomeRequest = z.infer<
  typeof AdminOrganizationsFuneralHomesConvertToFuneralHomeRequestSchema
>;

export const AdminOrdersTransferOwnershipRequestSchema = z.object({
  newUserId: z.string().min(10).max(40),
  orderId: z.string().min(10).max(40),
  newOrganizationId: z.string().min(10).max(40).nullable()
});
type AdminOrdersTransferOwnershipRequest = z.infer<
  typeof AdminOrdersTransferOwnershipRequestSchema
>;

export type AutoPaginateOrder = {
  variants: {
    width: number;
    height: number;
  }[];
  order_type: string;
  id: string;
};

export const ListConversationRequestSchema = z.object({
  query: z.object({
    page: z.number().min(0).optional().default(0),
    limit: z.number().min(1).max(1000).optional().default(30),
    query: z.string().optional()
  }),
  tab: z.enum([
    'missing-placement',
    'missing-invoice',
    'missing-up-front-payment',
    'missing-billed-payment',
    'running',
    'missing-affidavit',
    'complete',
    'cancelled',
    'all'
  ])
});

export const AdminInboxAutomationTemplatesCreateRequestSchema = z.object({
  client: z.string(),
  state: z.string(),
  templateContent: z.string()
});
type AdminInboxAutomationTemplatesCreateRequest = z.infer<
  typeof AdminInboxAutomationTemplatesCreateRequestSchema
>;

export const AdminInboxAutomationTemplatesDeleteRequestSchema = z.object({
  client: z.string(),
  state: z.string()
});
type AdminInboxAutomationTemplatesDeleteRequest = z.infer<
  typeof AdminInboxAutomationTemplatesDeleteRequestSchema
>;

export const AdminInboxAutomationTemplatesPreviewRequestSchema = z.object({
  online: z.boolean(),
  multiTenant: z.boolean(),
  templateContent: z.string()
});
type AdminInboxAutomationTemplatesPreviewRequest = z.infer<
  typeof AdminInboxAutomationTemplatesPreviewRequestSchema
>;

type AdminRefundBulkPaidInvoiceRequest = {
  paymentIntentId: string;
  invoiceId: string;
};

/**
 * Request types for our API endpoints.
 */
export type ERequestTypes = {
  'admin/organizations/layout-settings/update': {
    organizationId: string;
    noticeHeightThresholds: EOrganization['noticeHeightThresholds'] | null;
  };
  'admin/organizations/bulk-refresh/notices': {
    organizationId: string;
  };
  'admin/display-site-uploads/generate-feed-key': {
    organizationId: string;
  };
  'admin/display-site-uploads/add-display-site-upload-id': {
    displaySiteCacheId: string;
    displaySiteUploadId: string;
    uploadIdData: {
      newspaperName: string;
      uploadID: string;
      newspaperId: string;
      counties: [string, ...string[]];
    };
  };
  'admin/display-site-uploads/delete-display-site-upload-id': {
    displaySiteCacheId: string;
    displaySiteUploadId: string;
  };
  'admin/display-site-uploads/update-display-site-upload-id': {
    displaySiteCacheId: string;
    displaySiteUploadId: string;
    displaySiteUploadIdUpdates: Partial<{
      newspaperName: string;
      uploadID: string;
      newspaperId: string;
      counties: [string, ...string[]];
    }>;
  };

  'admin/organizations/display-sites/update': {
    organizationId: string;
    displaySiteSettings: Partial<EOrganization['searchContext']>;
  };
  'admin/affidavits/organizations/:organizationId/update-edition-portal-url': {
    editionPortalURL: string;
  };
  'admin/notices/:noticeId/force-archive': {
    archiveReason: string;
  };
  'search/publication-issue-sections': {
    filters: SearchablePublicationIssueSectionRecordFilter[];
    pageSize: number;
    search: string;
    sort: Record<string, SORT_DIRECTIONS>[];
  };
  'docs/oauth': {};
  'conversation-requests/search': z.infer<typeof ListConversationRequestSchema>;
  'conversation-requests/refresh': { conversationId: string };
  'archive/parse-twp': { daysAgo: number };
  'admin/organizations/duplicate-publisher': {
    organizationId: string;
    newPublisherName: string;
    newPublisherCounty: string;
    newPublisherState: number;
  };
  'admin/customer-organizations/:customerOrganizationId/update': {
    name: string;
  };
  'admin/rates/:rateId/update': Partial<ERate>;

  'admin/organizations/refresh-affidavit-settings': {
    organizationId: string;
  };

  'admin/affidavits/scrape-edition-run-date': {
    runDate: string;
    publisherId: string;
    noticeId: string;
  };
  'admin/organizations/parent-org-settings': {
    organizationId: string;
    parentOrgId: string | null;
  };
  'admin/organizations/advertiser-express-settings': {
    organizationId: string;
    expressPlacementBilling: ExpressPlacementBilling | null;
  };
  'admin/organizations/express-settings': {
    organizationId: string;
    nonColumnPlacementSite: string;
    supportEmail: string;
    expressSettings: ExpressSettings;
    confirmedByEmail: string;
  };
  'admin/affidavits/notices/refresh-ars-from-publisher': {
    noticeId: string;
  };
  'users/update-role': {
    userId: string;
    organizationId: string;
    role: number;
  };
  'admin/orders/update-newspaper-order': {
    orderId: string;
    newspaperOrderId: string;
    status: NewspaperOrderStatus;
    layoutId: string;
    templateId: string | undefined;
  };
  'orders/download-rtf': { orderId: string };
  'admin/orders/update-ad-content': {
    orderId: string;
    adContent: string;
    adTitle: string | undefined;
    deceasedName: string | undefined;
    deceasedBirthDate: string | undefined;
    deceasedDeathDate: string | undefined;
    deathVerification: Obituary['deathVerification'] | undefined;
  };
  'admin/orders/refresh-all-proofs': { publisherId: string };
  'admin/orders/refresh-proof': { orderId: string };
  'admin/ad-building/orders/list-display-ads': {};
  'admin/ad-building/orders/group-ads-by-type': {
    newspaperOrderData: {
      newspaperId: string;
      newspaperOrderId: string;
      newspaperOrderText: string;
      filingTypeName: string;
      orderId: string;
    }[];
    filingTypeMapping: Record<string, string>;
  };
  'admin/ad-building/orders/download-block': {
    fileName: string;
    elements: any[];
    newspaperId: string;
    customElementUrls: { id: string; url: string }[];
  };
  'pagination/universal-download': {
    publisherId: string;
    date: string;
  };
  'payments/stripe-login-link': { accountId: string };
  'payments/stripe-payout-data': {};
  'payments/download-dwolla-payouts': {
    period: 'currMonth' | 'lastMonth' | 'currYear' | 'lastYear' | 'all';
    customerId: string;
  };
  'payments/dwolla-payout-data': {
    dwollaCustomer: string;
  };
  'payments/dwolla-funding-source': {
    accountId: string;
    publicToken: string;
    accountNickname: string;
  };
  'payments/download-payouts': {
    period: 'currMonth' | 'lastMonth' | 'currYear' | 'lastYear' | 'all';
  };
  'payments/stripe-bt-payouts-data': {
    payouts: EStripePayout[];
    accountId: string;
  };
  'payments/create-receipt-pdf': { invoiceId: string };
  'admin/organizations/funeral-homes/convert-to-funeral-home': AdminOrganizationsFuneralHomesConvertToFuneralHomeRequest;
  'admin/orders/transfer-ownership': AdminOrdersTransferOwnershipRequest;
  'admin/display-qa': AdminDisplayQARequest;
  'admin/ad-building/download-block': AdminDownloadBlockRequest;
  'admin/affidavits/notices/:noticeId/force-assign-affidavit-to-notice': AffidavitAutomationForceAssignAffidavitToNoticeRequest;
  'admin/affidavits/notices/:noticeId/update-affidavit-reconciliation-settings': AdminNoticesUpdateAffidavitSettingsRequest;
  'admin/affidavits/get-runs-needing-verification': AffidavitAutomationGetRunsToVerifyRequest;
  'admin/affidavits/set-run-unverifiable': AffidavitAutomationSetUnverifiableRequest;
  'admin/affidavits/wet-signature/create-notarizations': AdminAffidavitsWetSignatureCreateNotarizationsRequest;
  'admin/affidavits/wet-signature/change-notarization-statuses': AdminAffidavitsWetSignatureChangeNotarizationStatusesRequest;
  'admin/affidavits/get-eedition-tracker-data': AdminAffidavitsGetEEditionTrackerDataRequest;
  'admin/checks/:stripeCheckId/refresh': Record<string, never>;
  'admin/coupons/:couponId/delete': Record<string, never>;
  'admin/coupons/:couponId/update': CouponSaveRequest;
  'admin/coupons/create': CouponSaveRequest;
  'admin/external-upload': AdminExternalUploadRequest;
  'admin/impersonate-user': AdminImpersonateUserRequest;
  'admin/inbox-automation/templates/create': AdminInboxAutomationTemplatesCreateRequest;
  'admin/inbox-automation/templates/delete': AdminInboxAutomationTemplatesDeleteRequest;
  'admin/inbox-automation/templates/preview': AdminInboxAutomationTemplatesPreviewRequest;
  'admin/invoices/detach-invoice-from-notice': AdminInvoiceDetachRequest;
  'admin/invoices/void-detached-invoice': AdminInvoiceVoidDetachedRequest;
  'admin/invoices/regenerate-invoices': AdminInvoiceRegenerationRequest;
  'admin/invoices/refund-bulk-paid-invoice': AdminRefundBulkPaidInvoiceRequest;
  'admin/notices/:noticeId/upload-file': AdminNoticesUploadFileRequest;
  'admin/notices/:noticeId/replace-indd': AdminReplaceInddRequest;
  'admin/notices/:noticeId/upload-camera-ready-ad': AdminNoticesUploadCameraReadyAdRequest;
  'admin/notices/:noticeId/create-notice-transfer': Record<string, never>;
  'admin/orders/cancel': AdminOrderCancelRequest;
  'admin/orders/void-invoice': { invoiceId: string };
  'admin/orders/cancel-invoice': { invoiceId: string };
  'admin/organizations/caches/create': AdminOrganizationIntegrationCreateCache;
  'admin/organizations/caches/modify-description': AdminOrganizationIntegrationModifyCacheDescription;
  'admin/organizations/caches/modify-required': AdminOrganizationIntegrationModifyRequiredDescription;
  'admin/organizations/caches/delete-entry': AdminOrganizationIntegrationDeleteCacheEntry;
  'admin/organizations/caches/update-entry': AdminOrganizationIntegrationCacheEntry;
  'admin/organizations/export-settings': AdminOrganizationsIntegrationExportSettings;
  'admin/organizations/invoice-settings': AdminOrganizationsInvoiceSettings;
  'admin/organizations/plan-settings': AdminOrganizationsPlanSettingsRequest;
  'admin/organizations/product-implementation-settings': AdminOrganizationsProductSettingRequest;
  'admin/organizations/regenerate-affidavits': AdminOrganizationsRegenerateAffidavitsRequest;
  'admin/organizations/subdomain-settings': AdminOrganizationsSubdomainSettingsRequest;
  'admin/organizations/stripe-settings': AdminOrganizationsStripeSettingsRequest;
  'admin/organizations/upload-settings': AdminOrganizationsUploadSettings;
  'admin/organizations/integration-upload-settings': AdminOrganizationsIntegrationUploadSettings;
  'admin/organizations/build-export-settings': AdminOrganizationsBuildExportSettings;
  'admin/organizations/deadline-settings': z.infer<
    typeof AdminOrganizationsDeadlineSettingsRequestSchema
  >;
  'admin/payments/unreconciled-checks': AdminPaymentsUnreconciledChecksRequest;
  'admin/payments/reconciled-checks': AdminPaymentsReconciledChecksRequest;
  'pagination/pagination-review': PaginationReviewNoticeRequest;
  'pagination/download-block': AdminDownloadBlockRequest;
  'pagination/auto-paginate': {
    layouts: {
      id: string;
      width: number;
      height: number;
      max_whitespace?: number;
      min_whitespace?: number;
    }[];
    orders: AutoPaginateOrder[];
    hyperparams: OptimizerHyperparams;
  };
  'pagination/order-pagination-review': PaginationReviewOrderRequest;
  'pagination/pagination-download': PaginationDownloadRequest;
  'pagination/get-pagination-ready-orders': PaginationGetOrdersRequest;
  'admin/upload-customer-list': AdminUploadCustomerListRequest;
  'admin/verify-customer-list': AdminUploadCustomerListRequest;
  'admin/pagination-manifest': AdminPaginationManifestRequest;
  'admin/reps': AdminRepsRequest;
  'admin/roles': AdminRoleRequest;
  'admin/sign-in': AdminSignInRequest;
  'admin/shadow': AdminShadowRequest;
  'admin/unshadow-an-org': AdminUnshadowOrgRequest;
  'admin/unshadow-as-columnist': AdminUnshadowOrgRequest;
  'admin/sync-to-elastic': AdminSyncToElasticRequest;
  'admin/regenerate-documents': AdminRegenerateDocumentsRequest;
  'admin/user-management/delete': AdminUserManagementDeleteRequest;
  'admin/user-management/organization-settings': AdminUserManagementOrganizationSettingsRequest;
  'admin/user-management/search': AdminUserManagementSearchRequest;
  'admin/user-management/update-stripe-id': AdminUserManagementStripeSettingsRequest;
  'admin/user-management/update-user-information': AdminUserManagementUserInformationRequest;
  'admin/user-management/update-notification-settings': AdminUserManagementUserNotificationsRequest;
  'affidavit-automation/events/create-eedition-uploaded-event': AffidavitAutomationCreateEEditionUploadedEventRequest;
  'affidavit-automation/notarization/add-to-notarization-batch': AffidavitAutomationNotarizationAddToNotarizationRequest;
  'affidavit-automation/notarization/:notarizationID/finalize': AffidavitAutomationFinalizeRequest;
  'affidavit-automation/notarization/override-notarization-preconditions': AffidavitAutomationOverrideNotarizationPreconditionsRequest;
  'affidavit-automation/notarization/get-notices-ready-for-wet-sig-notarization': AffidavitAutomationGetNoticesForWetSigNotarizationRequest;
  'affidavit-automation/notarization/assign-wet-sig-notices-to-mail-pages': AffidavitAutomationAssignWetSigNoticesToMailPagesRequest;
  'affidavit-automation/notarization/get-notices-assigned-to-mail-centers': AffidavitAutomationGetNoticesWithMailCenterAssignmentsRequest;
  'affidavit-automation/notarization/process-wet-sig-notarized-affidavit': AffidavitAutomationProcessWetSigNotarizedAffidavitRequest;
  'affidavit-automation/notarization/:notarizationID/process-complete': AffidavitAutomationNotarizationProcessCompleteRequest;
  'affidavit-automation/bulk-notarize': AffidavitAutomationBulkNotarizationRequest;
  'affidavit-automation/send-weekly-digest': AffidavitAutomationSendWeeklyDigestRequest;
  'affidavit-automation/manually-verify-runs': AffidavitAutomationManuallyVerifyRunsRequest;
  'affidavit-automation/get-all-notaries-from-proof': Record<string, unknown>;
  'bulk-export': AutomaticBulkDownloadRequest;
  'notices/async-design/upload-finalized-design': RetoolAsyncDesignUploadFinalizedDesignRequest;
  'ftp/parse-filemage-path': FTPParseFilemagePathRequest;
  'display-ads/unapproved-display-ad-email-alert': DisplayAdsUnapprovedDisplayAdEmailAlertRequest;
  'display-ads/ax/:instance/orders/search': DisplayAdsAXOrdersSearchRequest;
  'documents/affidavits/preview': DocumentsAffidavitsPreviewRequest;
  'documents/generate-proof': GenerateProofRequest;
  'documents/pdf/download': PDFDownloadRequest;
  'documents/properties': DocumentsPropertiesRequest;
  'documents/zip': BulkDownloadRequest;
  'documents/invoices/download': InvoicesDownloadRequest;
  'documents/unpaid-invoices-summary/download': UnpaidInvoicesSummaryDownloadRequest;
  'drafts/:id/delete': DraftsDeleteRequest;
  'integrations/customers/:newspaperId/find': IntegrationsCustomersFindRequest;
  'integrations/async-design/notice/:noticeId/cancel-build-ad-request': IntegrationsCancelBuildAdRequest;
  'integrations/async-design/notice/:noticeId/create-build-ad-request': IntegrationsCreateBuildAdRequest;
  'integrations/trigger-event': IntegrationsTriggerEventRequest;
  'integrations/visionData/ocr': z.infer<
    typeof IntegrationsVisionDataOcrRequestSchema
  >;
  'integrations/visionData/response/:noticeId/:triggerId': VisionDataBotResponse;
  'invoice-transactions/create-authorization': InvoiceTransactionsCreateAuthorizationRequest;
  'invoice-transactions/create-charge': InvoiceTransactionsCreateChargeRequest;
  'invoice-transactions/create-session': InvoiceTransactionsCreateSessionRequest;
  'notices/:id/cancel': CancelNoticeRequest;
  'orders/apply-coupon': OrderCouponApply;
  'orders/remove-coupon': OrderCouponRemove;
  'orders/cancel': OrderCancelRequest;
  'orders/create': OrderCreateRequest;
  'orders/create-invoice': OrdersCreateInvoiceRequest;
  'orders/finalize-invoice': { invoiceId: string };
  'orders/download-proofs': OrdersDownloadProofsRequest;
  'orders/grant-access': OrdersGrantAccessRequest;
  'orders/update-price': { orderId: string; version: number };
  'orders/get-font-resources': OrdersGetFontResourcesRequest;
  'orders/:orderId/submit-free': { version: number };
  'orders/:orderId/submit-unpaid': { version: number };
  'orders/:orderId/confirm-edits': OrdersConfirmEditsRequest;
  'orders/:orderId/delete': Record<string, never>;
  'payments/:id/refund': RefundInvoiceRequest;
  'payments/cancel-invoice': PaymentsCancelInvoiceRequest;
  'payments/create-bank-account': PaymentsCreateBankAccountRequest;
  'payments/create-verified-account': PaymentsCreateVerifiedAccountRequest;
  'payments/delete-source': PaymentsDeleteSourceRequest;
  'payments/delete-payment-method': PaymentsDeletePaymentMethodRequest;
  'payments/download-payout': PaymentsDownloadPayoutRequest;
  'payments/stripe-cancel-setup-intent': PaymentsStripeCancelSetupIntentsRequest;
  'payments/list-customer-payment-methods': PaymentsListCustomerPaymentMethodsRequest;
  'payments/pay-stripe-invoice': PaymentsPayInvoiceRequest;
  'payments/update-connected-account': PaymentsUpdateConnectedAccountRequest;
  'payments/mark-invoice-paid': PaymentsMarkInvoicePaidRequest;
  'payments/verify-bank-account': PaymentsVerifyBankAccountRequest;
  'payments/stripe-create-setup-intent': PaymentsStripeCreateSetupIntentRequest;
  'payments/get-bank-account-payment-method-status': PaymentsGetBankAccountPaymentMethodStatusRequest;
  'payments/attach-payment-method-to-customer': PaymentsAttachPaymentMethodToCustomerRequest;
  'payments/bank-account-payment-methods-that-require-microdeposits': PaymentsBankAccountPaymentMethodsThatRequireMicrodepositsRequest;
  'payments/client-secret-for-setup-intent': PaymentsGetClientSecretForSetupIntentRequest;
  'payments/stripe-customer-details': PaymentsStripeCustomerDetailsRequest;
  'payments/generate-dwolla-payout': PaymentsGenerateDwollaPayoutRequest;
  'payway/queue-transaction': PaywayQueueTransactionRequest;
  'publication-issue/orders': GetPublicationIssueOrdersRequest;
  'retool/reports/passthrough': InvoicePassthroughReportRequest;
  'retool/:invoiceId/passthrough': UpdatePassthroughInvoiceRequest;
  'retool/organizations/create-advertiser-org': RetoolNewAdvertiserOrgRequest;
  'retool/organizations/:newspaperId/link-newspaper-parent': RetoolLinkNewspaperParentRequest;
  'retool/duplicate-newspaper': RetoolDuplicateNewspaperRequest;
  'search/invoices': SearchInvoicesRequest;
  'search/usernotices': SearchUserNoticesRequest;
  'search/usernotices/facets': SearchUserNoticesFacetRequest;
  'search/orders': SearchOrdersRequest;
  'search/orders/facets': SearchOrdersFacetsRequest;
  'search/organizations': SearchOrganizationsRequest;
  'search/public-notices': SearchPublicNoticesRequest;
  'search/public-notices/facets': SearchPublicNoticesFacetsRequest;
  'search/subdomains': SearchSubdomainsRequest;
  'search/customers': SearchCustomersRequest;
  'subscription/notices-subscription': CreatePublicNoticeFreeSubscriptionRequest;
  'subscription/paid-notices-subscription': CreatePublicNoticePaidSubscriptionRequest;
  'subscription/update-search-query': UpdateSubscriptionQueryRequest;
  'templates/:adTemplateId/archive': Record<string, unknown>;
  'templates/fork': TemplatesForkRequest;
  'templates/styles': TemplatesStylesRequest;
  'affidavit-automation/initiate-stak-verification': AffidavitAutomationStakInitiationRequest;
  'retool/edit-upload-id': EditUploadIdRequest;
  'retool/add-upload-id': AddUploadIdRequest;
  'retool/organizations/set-prefer-customer-on-parent': SetPreferCustomerOnParentRequest;
  'site-settings': ProductSiteSettingCreateRequest;
  'site-settings/:siteSettingId': ProductSiteSettingUpdateRequest;
  'users/invite': UsersInviteRequest;
  'users/create-advertiser': UsersCreateAdvertiserRequest;
  'users/delete-invited-member': UsersDeleteInvitedMemberRequest;
  'users/delete-active-member': UsersDeleteActiveMemberRequest;
  'users/send-password-reset-email': UsersSendPasswordResetEmailRequest;
  'users/migrate-notices-between-users': UsersMigrateNoticesBetweenAccountsRequest;
  'users/get-user-by-email': UsersGetUserByEmailRequest;
  'users/get-users-from-customer-org': UsersGetUsersFromCustomerOrgRequest;
  'users/link-filer-to-org': UsersLinkFilerToOrgRequest;
  'users/register': UsersRegisterRequest;
  'users/change-email': UsersChangeEmailRequest;
  'users/send-change-account-email-link': UsersSendChangeAccountEmailLinkRequest;
  'auth/signin-exchange-token-for-google-microsoft-provider': GoogleMicrosoftSignInRequest;
  'search/advertiser-organizations': SearchAdvertiserOrganizationsRequest;
  'retool/notices/manual-sync': RetoolManualSyncRequest;
  'invoices/create-bulk-invoices': InvoicesCreateBulkInvoicesRequest;
  'invoices/create-bulk-invoices-loop': InvoicesCreateBulkInvoicesLoopRequest;
  'organization-management/merge': OrganizationManagementMergeRequest;
  'organization-management/delete': OrganizationManagementDeleteRequest;
  'organizations/related-papers': GetRelatedPapersRequest;
  'organizations/:organizationType/create': z.infer<
    typeof OrganizationsCreateRequestSchema
  >;
  'customers/send-update-billing-status-request': CustomersSendUpdateBillingStatusRequest;
  'customers/verify-billing-request-to-send-notification': CustomersVerifyBillingRequestToSendNotification;
  'customers/bulk-invoicing-eligibility': CustomersBulkInvoicingEligibilityRequest;
  'customers/get-or-create-with-customer-organization': GetOrCreateCustomerWithCustomerOrganizationRequest;
  'customers/get-or-create-customer': GetOrCreateCustomerRequest;
  'ad-pricing/price': AdPriceRequest;
  'image/upscale': { storagePath: string };
  'ledger/bulk-pay-invoices': z.infer<
    typeof LedgerBulkPayInvoicesRequestSchema
  >;
  'ledger/receipt': z.infer<typeof LedgerReceiptRequestSchema>;
  'search/adjudication-areas': SearchAdjudicationAreaRequest;
  'experiments/chat-complete': z.infer<
    typeof ExperimentDraftCompletionRequestSchema
  >;
};

type RequestKeys = keyof ERequestTypes;
type ResponseKeys = keyof EResponseTypes;
export type ApiEndpoint = RequestKeys & ResponseKeys;
export type ApiGetEndpoint = ResponseKeys;
