import React, { useState } from 'react';
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  SparklesIcon
} from '@heroicons/react/24/outline';
import { GridInput } from 'lib/components/Card/Grid';
import { ColumnButton } from 'lib/components/ColumnButton';
import { Form } from 'lib/components/Form';
import { Modal } from 'lib/components/Modal';
import ClickableIconWrapper from 'lib/components/TableLayout/ClickableIconWrapper';
import { PublicationIssueWithSection } from 'lib/types/publicationIssueSection';
import ToastActions from 'redux/toast';
import { useAppDispatch } from 'redux/hooks';
import { TextField } from 'lib/components/TextField';
import { getFirebaseContext } from 'utils/firebase';
import { safeGetOrThrow } from 'lib/safeWrappers';
import api from 'api';
import {
  AutoPaginationResponseBlock,
  OptimizerResponseSchema,
  AutoLayoutResponseElement
} from 'lib/types/api';
import { z } from 'zod';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { ENotice, ERequestTypes, ESnapshotExists, ETemplate } from 'lib/types';
import { floatToP2Float } from 'lib/pricing/ui';
import {
  DEFAULT_OPTIMIZER_HYPERPARAMS,
  PublisherPaginationSettingsService
} from 'lib/services/publisherPaginationSettingsService';
import { PublisherPaginationSettings } from 'lib/types/publisherPaginationSettings';
import { wrapError, wrapSuccess } from 'lib/types/responses';
import { getIndesignServerClient } from 'utils/indesign';
import { templateStylesToPixelSizes } from 'lib/services/publisherPaginationSettingsService/excalidrawUtils';

const getElementImageUrlFromElement = (
  element: AutoLayoutResponseElement,
  relevantNotices: ESnapshotExists<ENotice>[],
  publisherPaginationSettings: ESnapshotExists<PublisherPaginationSettings>
) => {
  const notice = relevantNotices?.find(notice =>
    element.id.includes(notice.id)
  );
  if (notice) {
    return notice.data().jpgURL;
  }
  const header = publisherPaginationSettings
    .data()
    .headers.find(header => element.id.includes(header.id));
  if (header) {
    return header.url;
  }
  return null;
};

function Block({
  publicationIssueWithSection,
  publisherPaginationSettings,
  relevantNotices,
  adTemplate,
  pagination,
  block
}: {
  publicationIssueWithSection: PublicationIssueWithSection;
  publisherPaginationSettings: ESnapshotExists<PublisherPaginationSettings>;
  relevantNotices: ESnapshotExists<ENotice>[];
  adTemplate: ESnapshotExists<ETemplate>;
  block: AutoPaginationResponseBlock;
  pagination: {
    nextDisabled: boolean;
    previousDisabled: boolean;
    onNextBlock: () => void;
    onPreviousBlock: () => void;
  };
}) {
  const dispatch = useAppDispatch();
  const [downloading, setDownloading] = useState(false);

  const downloadBlock = async (block: AutoPaginationResponseBlock) => {
    setDownloading(true);
    try {
      const [pdfError, pdfUrl] = await new PublisherPaginationSettingsService(
        getFirebaseContext()
      ).generateBlockUrl(
        publicationIssueWithSection.publicationIssue,
        block,
        async (request: ERequestTypes['pagination/download-block']) => {
          const { response: downloadUrl, error: downloadError } =
            await api.safePost('pagination/download-block', request);
          return downloadError || !downloadUrl
            ? wrapError(new Error(downloadError || 'Unknown error'))
            : wrapSuccess(downloadUrl);
        }
      );
      if (pdfError) {
        dispatch(
          ToastActions.toastError({
            headerText: 'Error downloading block',
            bodyText: `Failed to download block: ${pdfError}`
          })
        );
      } else if (pdfUrl) {
        window.open(pdfUrl, '_blank');
      }
    } catch (err) {
      dispatch(
        ToastActions.toastError({
          headerText: 'Error downloading block',
          bodyText: `Failed to download block: ${err}`
        })
      );
    } finally {
      setDownloading(false);
    }
  };
  const { value: templatePixelStyles } = useAsyncEffect({
    fetchData: async () => {
      const templateStyles = adTemplate.data().styles;
      if (!templateStyles) return null;
      return templateStylesToPixelSizes(templateStyles);
    },
    dependencies: [adTemplate.id]
  });

  if (!templatePixelStyles) return null;

  const { colWidth, gutterWidth } = templatePixelStyles;

  return (
    <div className="flex flex-col gap-4">
      <div className="flex justify-between w-full">
        <div>
          <ColumnButton
            buttonText={<ArrowLeftIcon className="w-6 h-6" />}
            onClick={pagination.onPreviousBlock}
            type="button"
            size="sm"
            fullWidth
            disabled={pagination.previousDisabled}
          />
        </div>
        <div className="text-center px-4">
          <div>
            {block.layout.width} col x{' '}
            {floatToP2Float(block.layout.height / 72)} inches
          </div>
          <div>
            {floatToP2Float(block.whitespace / 72)} col/inch whitespace (
            {block.whitespace} px)
          </div>
        </div>
        <div>
          <ColumnButton
            buttonText={<ArrowRightIcon className="w-6 h-6" />}
            onClick={pagination.onNextBlock}
            type="button"
            size="sm"
            fullWidth
            disabled={pagination.nextDisabled}
          />
        </div>
      </div>
      <div>
        <ColumnButton
          size="sm"
          buttonText="Download"
          onClick={() => downloadBlock(block)}
          id="download"
          type="button"
          fullWidth
          loading={downloading}
        />
      </div>
      <div
        className="relative border border-black mb-5 mx-auto"
        style={{
          width:
            block.layout.width * templatePixelStyles.colWidth +
            (block.layout.width - 1) * templatePixelStyles.gutterWidth,
          height: block.layout.height
        }}
      >
        {block.elements.map(element => {
          const imageUrl = getElementImageUrlFromElement(
            element,
            relevantNotices,
            publisherPaginationSettings
          );
          const adWidth =
            element.width * colWidth + (element.width - 1) * gutterWidth;
          const x = element.x * colWidth + element.x * gutterWidth;

          return (
            <div
              key={element.id}
              className="absolute"
              style={{
                left: x,
                top: element.y,
                width: adWidth,
                height: element.height,
                backgroundImage: imageUrl ? `url(${imageUrl})` : 'none',
                backgroundColor: '#f5f5f5',
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat'
              }}
            />
          );
        })}
      </div>
    </div>
  );
}

type AutoPaginateButtonProps = {
  publicationIssueWithSection: PublicationIssueWithSection;
};

export default function AutoPaginateButton({
  publicationIssueWithSection
}: AutoPaginateButtonProps) {
  const [running, setRunning] = useState(false);
  const [showAutoPaginateModal, setShowAutoPaginateModal] = useState(false);
  const [autoPaginationSettings, setAutoPaginationSettings] = useState(
    DEFAULT_OPTIMIZER_HYPERPARAMS
  );
  const [blocks, setBlocks] =
    useState<z.infer<typeof OptimizerResponseSchema>>();

  const [activeBlock, setActiveBlock] = useState(0);

  const dispatch = useAppDispatch();

  const { value: publisherPaginationData } = useAsyncEffect({
    fetchData: async () => {
      const [publisherError, publisher] = await safeGetOrThrow(
        publicationIssueWithSection.publicationIssue.modelData.publisher
      );
      if (publisherError) return null;
      const [settingsError, settings] =
        await new PublisherPaginationSettingsService(
          getFirebaseContext()
        ).getOrCreateDefault(publisher);
      if (settingsError) return null;
      const [templateError, template] = await safeGetOrThrow(
        settings.data().adTemplate
      );
      if (templateError) return null;
      return { settings, template };
    },
    dependencies: [
      publicationIssueWithSection.publicationIssue.modelData.publisher.id
    ]
  });

  const { value: relevantNotices } = useAsyncEffect({
    fetchData: async () => {
      const [noticesError, notices] =
        await publicationIssueWithSection.publicationIssue.slowGetNoticesForPublicationIssue();
      if (noticesError) return [];
      return notices;
    },
    dependencies: [publicationIssueWithSection.publicationIssue.id]
  });

  const handleAutoPaginate = async () => {
    setRunning(true);
    setBlocks(undefined);

    try {
      const [autoPaginationError, blocks] =
        await new PublisherPaginationSettingsService(
          getFirebaseContext()
        ).runAutoLayout(
          publicationIssueWithSection.publicationIssue,
          autoPaginationSettings,
          async (request: ERequestTypes['pagination/auto-paginate']) => {
            const { response: blocks, error: autoPaginationError } =
              await api.safePost('pagination/auto-paginate', request);
            return autoPaginationError || !blocks
              ? wrapError(
                  new Error(autoPaginationError) || new Error('Unknown error')
                )
              : wrapSuccess(blocks);
          },
          getIndesignServerClient(),
          DOMParser
        );
      if (autoPaginationError || !blocks) {
        dispatch(
          ToastActions.toastError({
            headerText: 'Error auto-paginating',
            bodyText: autoPaginationError?.message || 'Unknown error'
          })
        );
        return;
      }
      setActiveBlock(0);
      setBlocks(blocks);
    } catch (error) {
      dispatch(
        ToastActions.toastError({
          headerText: 'Error auto-paginating',
          bodyText: error instanceof Error ? error.message : 'Unknown error'
        })
      );
      return;
    } finally {
      setRunning(false);
    }
  };

  return (
    <>
      <ClickableIconWrapper
        id={`auto-paginate-${publicationIssueWithSection.publicationIssue.id}`}
        onClick={() => {
          setShowAutoPaginateModal(true);
        }}
        icon={<SparklesIcon className="w-6 h-6" />}
      />
      {showAutoPaginateModal && publisherPaginationData && (
        <Modal
          onClose={() => setShowAutoPaginateModal(false)}
          title="Auto Paginate"
          id={`auto-paginate-modal-${publicationIssueWithSection.publicationIssue.id}`}
        >
          <div onClick={e => e.stopPropagation()}>
            <Form
              id={`auto-paginate-form-${publicationIssueWithSection.publicationIssue.id}`}
              onSubmit={handleAutoPaginate}
            >
              <div className="grid grid-cols-2 gap-4">
                <GridInput>
                  <TextField
                    id={`auto-paginate-max-time-${publicationIssueWithSection.publicationIssue.id}`}
                    labelText="Max Time (seconds)"
                    value={autoPaginationSettings.max_time_seconds.toString()}
                    onChange={newMaxTime =>
                      setAutoPaginationSettings({
                        ...autoPaginationSettings,
                        max_time_seconds: Number(newMaxTime)
                      })
                    }
                    type="number"
                    min={30}
                    max={180}
                  />
                </GridInput>
                <GridInput>
                  <TextField
                    id={`auto-paginate-max-whitespace-${publicationIssueWithSection.publicationIssue.id}`}
                    labelText="Max Whitespace (pixels)"
                    value={
                      autoPaginationSettings.max_whitespace?.toString() || '500'
                    }
                    onChange={newMaxWhitespace =>
                      setAutoPaginationSettings({
                        ...autoPaginationSettings,
                        max_whitespace: Number(newMaxWhitespace)
                      })
                    }
                    type="number"
                    min={0}
                    max={100000}
                  />
                </GridInput>
                <GridInput fullWidth>
                  <ColumnButton
                    fullWidth
                    id={`auto-paginate-button-${publicationIssueWithSection.publicationIssue.id}`}
                    loading={running}
                    type="submit"
                    buttonText="Auto Paginate"
                    primary
                  />
                </GridInput>
              </div>
              <div className="mt-4">
                {/* TODO: Add a display of the blocks and download buttons */}
                {/* https://columnpbc.atlassian.net/browse/APP-4020 */}
                {blocks && blocks.length > 0 && (
                  <div style={{ maxHeight: 600, overflowY: 'scroll' }}>
                    <Block
                      block={blocks[activeBlock]}
                      relevantNotices={relevantNotices || []}
                      publicationIssueWithSection={publicationIssueWithSection}
                      publisherPaginationSettings={
                        publisherPaginationData.settings
                      }
                      adTemplate={publisherPaginationData.template}
                      pagination={{
                        nextDisabled: activeBlock === blocks.length - 1,
                        previousDisabled: activeBlock === 0,
                        onNextBlock: () => setActiveBlock(activeBlock + 1),
                        onPreviousBlock: () => setActiveBlock(activeBlock - 1)
                      }}
                    />
                  </div>
                )}
              </div>
            </Form>
          </div>
        </Modal>
      )}
    </>
  );
}
