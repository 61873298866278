import classNames from 'classnames';
import React from 'react';
import { Outlet } from 'react-router-dom';

import { selectActiveOrganization, selectUser } from 'redux/auth';
import { useAppSelector } from 'redux/hooks';
import ExistingUserRegistrationHeader from './ExistingUserRegistrationHeader';
import NewUserRegistrationHeader from './NewUserRegistrationHeader';
import BackgroundImg from './backgroundImg.svg';
import { hasUserCompletedRegistration } from './registrationLayoutHelpers';

export default function RegistrationLayoutFrame() {
  const activeOrganization = useAppSelector(selectActiveOrganization);
  const user = useAppSelector(selectUser);
  const userHasCompletedRegistration = hasUserCompletedRegistration(
    user,
    activeOrganization
  );

  return (
    <div
      className="bg-base-2 overflow-y-scroll h-screen w-screen"
      style={{ backgroundImage: `url(${BackgroundImg})` }}
    >
      {userHasCompletedRegistration ? (
        <ExistingUserRegistrationHeader />
      ) : (
        <NewUserRegistrationHeader />
      )}
      <div
        className={classNames('mx-auto max-w-6xl', {
          'mt-32': !userHasCompletedRegistration,
          'mt-24': userHasCompletedRegistration
        })}
      >
        <Outlet />
      </div>
    </div>
  );
}
