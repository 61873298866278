import { ColumnService } from 'lib/services/directory';
import { MediaService } from 'lib/services/mediaService';
import { getFirebaseContext } from 'utils/firebase';
import { EOrganization, ERef, EUser, Media } from 'lib/types';
import { FileWithUploadRef } from 'lib/frontend/hooks/useFirebaseStorageUpload';
import { Product } from 'lib/enums';
import { cdnIfy } from 'lib/helpers';
import { ResponseOrError, wrapError, wrapSuccess } from 'lib/types/responses';
import { logAndCaptureException } from 'utils';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { MediaModel } from 'lib/model/objects/mediaModel';

const mediaService = new MediaService(getFirebaseContext());

type UseMediaLibraryParams = {
  organizationId: string;
  folder: Media['folder'];
  uploads?: string[];
  skip?: boolean;
};

export const useMediaLibrary = ({
  organizationId,
  folder,
  uploads,
  skip
}: UseMediaLibraryParams) =>
  useAsyncEffect<MediaModel[]>({
    fetchData: () => {
      if (skip) {
        return Promise.resolve([] as MediaModel[]);
      }
      return mediaService.getOrganizationMedia({
        organizationId,
        folder
      });
    },
    dependencies: [uploads?.length], // TODO: Set up better data refresh logic or change to listener
    errorConfig: {
      service: ColumnService.SETTINGS_MANAGEMENT,
      message: 'Failed to load media',
      tags: {
        organizationId
      }
    }
  });

export async function handleFileMetadataUpload({
  filesAndRefs,
  product,
  activeOrganization,
  user,
  useColumnCDN
}: {
  filesAndRefs: FileWithUploadRef[];
  product: Product;
  activeOrganization: ERef<EOrganization>;
  user: ERef<EUser>;
  useColumnCDN: boolean;
}): Promise<ResponseOrError<undefined>> {
  const mediaService = new MediaService(getFirebaseContext());

  const promises = filesAndRefs.map(async ({ file, uploadRef }) => {
    const cdnUrl = cdnIfy(uploadRef.fullPath, { useColumnCDN });

    const fileMetadata: Media = {
      type: 'ClipArt',
      folder: product,
      fileName: file.name,
      filePath: uploadRef.fullPath,
      cdnUrl,
      authorizedOrganization: activeOrganization,
      createdBy: user,
      title: file.name,
      tags: [],
      isArchived: false
    };

    return await mediaService.create(fileMetadata);
  });

  const results = await Promise.all(promises);

  const errors = results
    .filter(result => !!result.error)
    .map(result => result.error);

  if (errors.length) {
    errors.forEach(error => {
      logAndCaptureException(
        ColumnService.SETTINGS_MANAGEMENT,
        error,
        'Failed to upload media metadata document',
        {
          organizationId: activeOrganization.id,
          product
        }
      );
    });

    return wrapError(new Error('Failed to upload media metadata documents'));
  }

  return wrapSuccess(undefined);
}
