import {
  ChevronDoubleLeftIcon,
  ChevronDownIcon,
  ReceiptPercentIcon,
  TrashIcon
} from '@heroicons/react/24/solid';
import CButton from 'components/CButton';
import { getBooleanFlag } from 'utils/flags';
import { useHasPermission } from 'utils/useHasPermission';
import { LoadingSpinner } from 'lib/components/LoadingSpinner';
import { NoticeStatusType, InvoiceStatus } from 'lib/enums';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { Permissions } from 'lib/permissions/roles';
import {
  EInvoice,
  ENotice,
  EOrganization,
  ESnapshotExists,
  exists
} from 'lib/types';
import { Popover, PopoverContext } from 'lib/components/Popover';
import { useContext } from 'react';
import { Tooltip } from '@material-ui/core';
import { canCancelInvoiceWithoutSupport } from 'utils/permissions';
import { hasPaymentOrPartialRefund } from 'lib/utils/invoices';
import { getNoticePubDatesNotOnInvoice } from 'lib/pricing';
import { STRIPE } from 'lib/constants';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { getOrganizationGateway } from 'lib/billing/gateways';

export const getCancelOrRefundInvoiceSettings = (
  notice: ESnapshotExists<ENotice>,
  invoice: ESnapshotExists<EInvoice> | undefined,
  newspaper: ESnapshotExists<EOrganization> | undefined,
  options: {
    userCanRefund: boolean;
    userCanVoid: boolean;
  }
) => {
  // The InvoiceActionPopoverFlyout component consuming this function is only visible to Publishers
  const { userCanRefund, userCanVoid } = options;

  type ButtonText = 'Delete Invoice' | 'Refund Invoice' | 'Void/Refund Invoice';
  type ModalToDisplay = 'cancel-invoice' | 'refund-invoice';

  let buttonText: ButtonText = 'Refund Invoice';
  let buttonDisabled = false;
  let modalToDisplay: ModalToDisplay | null = null;
  let toolTipBody = '';

  if (!exists(invoice)) {
    return {
      buttonText,
      buttonDisabled: true,
      modalToDisplay,
      toolTipBody
    };
  }

  const getNoticeHasPubDatesNotOnInvoice = () => {
    const additionalPubDates = getNoticePubDatesNotOnInvoice(
      invoice,
      notice.data().publicationDates
    );
    if (Array.isArray(additionalPubDates) && additionalPubDates.length > 0) {
      return true;
    }

    return false;
  };

  const noticeHasPubDatesNotOnInvoice = getNoticeHasPubDatesNotOnInvoice();

  const canRefundOrCancelInvoice = canCancelInvoiceWithoutSupport(
    invoice,
    notice
  );
  const paymentOrPartialRefundHasOccurred = hasPaymentOrPartialRefund(invoice);
  const newspaperName = newspaper?.data()?.name;
  const hasManualPayment = !!invoice.data().manualPaymentDetails;
  const hasTransfer = !!notice.data().transfer;
  const invoiceIOC = !!invoice.data().invoiceOutsideColumn;
  const paymentMethodIsCheck = invoice.data().paymentMethod === 'check';
  const paymentMethodIsACH = invoice.data().paymentMethod === 'ach';
  const noticeHasBulkInvoice =
    invoice.data().isWithinBulkInvoice && notice?.data().bulkInvoice;
  const isZeroCentsInvoice = invoice.data().pricing?.totalInCents === 0;

  if (!canRefundOrCancelInvoice) {
    if (noticeHasBulkInvoice) {
      buttonText = 'Refund Invoice';
      toolTipBody = `It looks like this notice is within a bulk invoice. To refund and cancel this invoice, you will need to reach out to customer support. Email help@column.us.`;
      buttonDisabled = true;
    } else {
      buttonText = 'Refund Invoice';
      toolTipBody = `It looks like the customer has already paid this invoice. To refund and cancel this invoice, you will need to reach out to customer support. Email help@column.us.`;
      buttonDisabled = true;
    }
  } else if (!(userCanVoid || userCanRefund)) {
    buttonText = 'Refund Invoice';
    toolTipBody = `You do not have permission to void or refund invoices. Please contact an Admin of ${
      newspaperName ?? 'the publisher'
    } to refund or void this invoice.`;
    buttonDisabled = true;
  } else if (!paymentOrPartialRefundHasOccurred) {
    // The invoice is not paid; and the user has permission to void or refund
    buttonText = 'Delete Invoice';
    toolTipBody = 'Cancel this unpaid invoice';
    buttonDisabled = false;
    modalToDisplay = 'cancel-invoice';
  } else if (isZeroCentsInvoice) {
    // The invoice is paid, but the total amount is $0.00
    buttonText = 'Delete Invoice';
    toolTipBody = 'Cancel this $0.00 invoice';
    buttonDisabled = false;
    modalToDisplay = 'cancel-invoice';
  } else if (
    invoice.data()?.status === InvoiceStatus.refunded.value &&
    !(invoice.data().void || !!invoice.data().voided_at)
  ) {
    // The invoice is fully refunded but has not been voided
    buttonText = 'Delete Invoice';
    toolTipBody = 'Cancel this fully refunded invoice';
    buttonDisabled = false;
    modalToDisplay = 'cancel-invoice';
  } else {
    // The invoice can be canceled or refunded, payment has occurred, and the user likely has permission to do so
    buttonDisabled = false;
    if (hasManualPayment && !hasTransfer) {
      buttonText = 'Void/Refund Invoice';
      toolTipBody = `The advertiser paid for this notice outside of Column. If you void this invoice, ${newspaperName} will be responsible for issuing a refund to the advertiser outside of Column. Please make sure to create a new invoice.`;
      modalToDisplay = 'cancel-invoice';
      buttonDisabled = false;
    } else if (!invoiceIOC && !hasTransfer) {
      buttonText = 'Refund Invoice';
      toolTipBody = `The advertiser has already paid for this notice. If you need to void the invoice, the advertiser will receive a full refund through Column. Please make sure to create a new invoice.`;
      modalToDisplay = noticeHasPubDatesNotOnInvoice
        ? 'cancel-invoice'
        : 'refund-invoice';
      if (paymentMethodIsACH || paymentMethodIsCheck) {
        buttonText = 'Void/Refund Invoice';
        modalToDisplay = 'cancel-invoice';
        toolTipBody = `This notice was paid by ${
          paymentMethodIsACH ? 'ACH' : 'check'
        }. If you void this invoice, Column will issue a manual refund. ${newspaperName} will be responsible for creating a new invoice and ensuring the customer submits payment again.`;
      }
    } else if (invoiceIOC) {
      buttonText = 'Refund Invoice';
      toolTipBody =
        'You marked this invoice as to be paid outside Column. If you void the current invoice, please be sure to create a new invoice.';
      modalToDisplay = 'cancel-invoice';
    } else {
      buttonText = 'Delete Invoice';
      modalToDisplay = 'cancel-invoice';
      toolTipBody =
        'The client will automatically be notified by email. Please be sure to create a new invoice.';
    }
  }

  return { buttonText, toolTipBody, buttonDisabled, modalToDisplay };
};

function PaidReceiptActionPopoverActivator() {
  const { open, setOpen } = useContext(PopoverContext);
  return (
    <div
      id="invoice-actions"
      className={`block hover:bg-column-gray-100 transition ease-in-out duration-150 mx-2 md:rounded-full w-auto cursor-pointer flex justify-center`}
      onClick={e => {
        e.stopPropagation();
        setOpen(!open);
      }}
    >
      <ChevronDownIcon className="h-6 w-6" />
    </div>
  );
}

type PaidReceiptActionPopoverFlyoutProps = {
  cancelOrRefundInvoiceToolTip: string;
  isCancelOrRefundInvoiceDisabled: boolean;
  cancelOrRefundModalToDisplay: string | null;
  cancelOrRefundButtonText: string;
  setShowRefundInvoiceModal: (shouldShow: boolean) => void;
  setShowCancelInvoiceModal: (shouldShow: boolean) => void;
};

function PaidReceiptActionPopoverFlyout({
  cancelOrRefundInvoiceToolTip,
  isCancelOrRefundInvoiceDisabled,
  cancelOrRefundModalToDisplay,
  cancelOrRefundButtonText,
  setShowRefundInvoiceModal,
  setShowCancelInvoiceModal
}: PaidReceiptActionPopoverFlyoutProps) {
  const { setOpen } = useContext(PopoverContext);

  return (
    <div
      id="flyout"
      className="relative grid gap-6 bg-white xl:gap-8 p-6 w-64"
      onClick={e => e.stopPropagation()}
    >
      <Tooltip title={cancelOrRefundInvoiceToolTip}>
        <div
          id="cancel-invoice"
          className={`${
            isCancelOrRefundInvoiceDisabled &&
            `opacity-50 cursor-not-allowed pointer-events-none`
          } cursor-pointer items-center inline-flex -m-3 p-3 flex rounded-md hover:bg-column-gray-25 transition ease-in-out duration-150`}
          onClick={() => {
            if (!isCancelOrRefundInvoiceDisabled) {
              if (cancelOrRefundModalToDisplay === 'cancel-invoice') {
                setShowCancelInvoiceModal(true);
              } else if (cancelOrRefundModalToDisplay === 'refund-invoice') {
                setShowRefundInvoiceModal(true);
              }
              setOpen(false);
            }
          }}
        >
          {cancelOrRefundModalToDisplay === 'refund-invoice' ? (
            <ChevronDoubleLeftIcon className="h-6 w-6 mr-2 inline-block" />
          ) : (
            <TrashIcon className="h-6 w-6 mr-2 inline-block" />
          )}
          <div className="text-base mt-0 font-medium text-column-gray-400 hover:text-column-gray-500 leading-none">
            {cancelOrRefundButtonText}
          </div>
        </div>
      </Tooltip>
    </div>
  );
}

type PaidReceiptButtonProps = {
  notice: ESnapshotExists<ENotice>;
  invoice: ESnapshotExists<EInvoice>;
  newspaper: ESnapshotExists<EOrganization>;
  receiptUrl: string | undefined;
  shouldDisableInvoiceAccessPerIOCConfig: boolean | undefined;
  buttonStyles: string;
  isInvoicedOutsideColumn: boolean | undefined;
  isPublisher: boolean;
  setModalToShow: any;
};

export function PaidReceiptButton({
  notice,
  invoice,
  newspaper,
  receiptUrl,
  shouldDisableInvoiceAccessPerIOCConfig,
  buttonStyles,
  isInvoicedOutsideColumn,
  isPublisher,
  setModalToShow
}: PaidReceiptButtonProps) {
  /* below block is used for partial refunds v2 */
  // APP-2220 Enabling partial refunds v2
  const enablePartialRefundsV2flag = getBooleanFlag(
    LaunchDarklyFlags.ENABLE_PARTIAL_REFUNDS_V_2,
    false
  );

  const { value: paymentGateway } = useAsyncEffect({
    fetchData: () => getOrganizationGateway(newspaper?.ref),
    dependencies: [newspaper?.id]
  });

  const enablePartialRefundsV2 =
    enablePartialRefundsV2flag && paymentGateway === STRIPE;

  const userCanVoid = useHasPermission(Permissions.INVOICES_VOID);
  const userCanRefund = useHasPermission(Permissions.INVOICES_REFUND);

  const cancelOrRefundInvoiceSettings = getCancelOrRefundInvoiceSettings(
    notice,
    invoice,
    newspaper,
    {
      userCanRefund,
      userCanVoid
    }
  );

  const {
    buttonDisabled: isCancelOrRefundInvoiceDisabled,
    buttonText: cancelOrRefundButtonText,
    modalToDisplay: cancelOrRefundModalToDisplay,
    toolTipBody: cancelOrRefundInvoiceToolTip
  } = cancelOrRefundInvoiceSettings;

  const showPopoverFlyout =
    isPublisher &&
    invoice?.data() &&
    notice?.data()?.noticeStatus !== NoticeStatusType.cancelled.value;
  /* end of block used for partial refunds v2 */

  return (
    <CButton
      id="invoice-pdf"
      startIcon={
        receiptUrl ? (
          <ReceiptPercentIcon className="h-6 w-6" />
        ) : (
          <LoadingSpinner />
        )
      }
      startClasses="col-span-2"
      className={`${
        (!invoice ||
          (!isPublisher && shouldDisableInvoiceAccessPerIOCConfig)) &&
        `opacity-50 cursor-not-allowed pointer-events-none`
      } ${buttonStyles}`}
      onClick={() => {
        receiptUrl && window.open(receiptUrl);
      }}
      middleClasses="col-span-8 text-left ml-2"
      endClasses={`${
        !isPublisher &&
        isInvoicedOutsideColumn &&
        'cursor-not-allowed pointer-events-none'
      } flex justify-center col-span-2`}
      endIcon={
        showPopoverFlyout ? (
          enablePartialRefundsV2 ? (
            <Popover
              activator={<PaidReceiptActionPopoverActivator />}
              alignment="right"
              id="invice-actions-popover-id"
            >
              <PaidReceiptActionPopoverFlyout
                cancelOrRefundInvoiceToolTip={cancelOrRefundInvoiceToolTip}
                isCancelOrRefundInvoiceDisabled={
                  isCancelOrRefundInvoiceDisabled
                }
                cancelOrRefundModalToDisplay={cancelOrRefundModalToDisplay}
                cancelOrRefundButtonText={cancelOrRefundButtonText}
                setShowCancelInvoiceModal={(shouldShow: boolean) =>
                  setModalToShow(shouldShow ? 'cancel-invoice' : null)
                }
                setShowRefundInvoiceModal={(shouldShow: boolean) =>
                  setModalToShow(shouldShow ? 'refund-invoice' : null)
                }
              />
            </Popover>
          ) : (
            <a
              id="cancel-invoice"
              className={
                'block hover:bg-column-gray-100 md:mx-2 xl:mx-7 rounded-full'
              }
              onClick={e => {
                e.stopPropagation();
                setModalToShow('cancel-invoice');
              }}
            >
              <TrashIcon className="m-auto h-6 w-6" />
            </a>
          )
        ) : undefined
      }
      disabled={!isPublisher && shouldDisableInvoiceAccessPerIOCConfig}
    >
      {invoice?.data()?.status === InvoiceStatus.refunded.value
        ? 'View Refund'
        : notice?.data()?.noticeStatus === NoticeStatusType.cancelled.value
        ? 'View Voided'
        : isInvoicedOutsideColumn
        ? 'View Statement'
        : 'View Receipt'}
    </CButton>
  );
}
