import React from 'react';
import { AnyAction } from '@reduxjs/toolkit';
import { State, State as States } from 'lib/enums';
import { MailingAddress } from 'lib/types/organization';
import { TrashIcon } from '@heroicons/react/24/outline';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { TextField } from 'lib/components/TextField';
import { ColumnButton } from 'lib/components/ColumnButton';
import {
  actions as affidavitRecipientActions,
  MAX_MAIL_COPIES
} from './affidavitRecipient.slice';

const {
  deleteMailingRecipient,
  updateMailingRecipientName,
  updateMailingRecipientCopies,
  updateMailingRecipientAddress
} = affidavitRecipientActions;

type AffidavitMailingAddressFormProps = {
  mailingAddress: MailingAddress;
  dispatch: React.Dispatch<AnyAction>;
  index: number;
  error: string;
  showErrors: boolean;
};

export function AffidavitMailingAddressForm({
  mailingAddress,
  index,
  dispatch,
  error,
  showErrors
}: AffidavitMailingAddressFormProps) {
  const selectedState = mailingAddress.address?.address_state
    ? {
        id: `${mailingAddress.address.address_state}`,
        label: States.by_value(mailingAddress.address.address_state)?.label
      }
    : {};

  function onMailAddressFieldChange(
    change: Partial<MailingAddress['address']>
  ) {
    dispatch(updateMailingRecipientAddress({ update: change, index }));
  }

  function onDelete() {
    dispatch(deleteMailingRecipient(index));
  }

  return (
    <div className="my-6 bg-gray-100 border rounded-md p-6">
      <form>
        <fieldset className="grid grid-cols-12 gap-4">
          <div className="col-span-12 flex justify-between">
            <legend className="text-sm text-grey-400 font-medium uppercase">
              Recipient details
            </legend>
            {index > 0 && (
              <ColumnButton
                destructive
                link
                aria-label="Delete address"
                startIcon={<TrashIcon className="w-5 h-5" />}
                onClick={onDelete}
                type="button"
              />
            )}
          </div>
          <div className="col-span-12 md:col-span-7">
            <TextField
              id={`name${index}`}
              labelText="Full name"
              required
              placeholder="Name"
              value={mailingAddress.name}
              onChange={newValue => {
                dispatch(
                  updateMailingRecipientName({
                    newValue,
                    index
                  })
                );
              }}
            />
          </div>

          <div className="col-span-12 md:col-span-5">
            <TextField
              type="number"
              id={`copies${index}`}
              labelText="How many copies should be sent?"
              placeholder="Copies"
              required
              value={String(mailingAddress.copies)}
              min={1}
              max={MAX_MAIL_COPIES}
              onChange={newValue => {
                dispatch(
                  updateMailingRecipientCopies({
                    newValue: Number(newValue),
                    index
                  })
                );
              }}
            />
          </div>
          <div className="col-span-12">
            <TextField
              id={`addressLine1${index}`}
              labelText="Address 1"
              placeholder="Address Line 1"
              required
              value={mailingAddress.address?.address_line1}
              onChange={newValue => {
                onMailAddressFieldChange({
                  address_line1: newValue
                });
              }}
            />
          </div>
          <div className="col-span-12">
            <TextField
              id={`addressLine2${index}`}
              labelText="Address 2"
              placeholder="Address Line 2"
              value={mailingAddress.address?.address_line2 || ''}
              onChange={newValue => {
                onMailAddressFieldChange({
                  address_line2: newValue
                });
              }}
            />
          </div>
          <div className="col-span-12">
            <TextField
              id={`city${index}`}
              labelText="City"
              required
              placeholder="City"
              value={mailingAddress.address?.address_city}
              onChange={newValue => {
                onMailAddressFieldChange({
                  address_city: newValue
                });
              }}
            />
          </div>

          <div className="col-span-12 md:col-span-6">
            <TextField
              id={`zipcode${index}`}
              type="postal-code"
              labelText="Zip code"
              required
              placeholder="Zip Code"
              value={mailingAddress.address?.address_zip}
              onChange={newValue => {
                onMailAddressFieldChange({
                  address_zip: newValue
                });
              }}
            />
          </div>
          <div className="col-span-12 md:col-span-6">
            <ColumnSelect
              id={`state${index}`}
              labelText="State"
              placeholder="State"
              required
              onChange={stateId => {
                onMailAddressFieldChange({
                  address_state: Number(stateId)
                });
              }}
              allowUndefined
              options={State.items().map((state: Record<string, any>) => ({
                value: String(state.value),
                label: state.label
              }))}
              value={selectedState.id}
            />
          </div>
          {showErrors && error && (
            <div className="col-span-12 text-red-600 text-sm font-normal pt-2">
              {error}
            </div>
          )}
        </fieldset>
      </form>
    </div>
  );
}
