import {
  ArrowDownTrayIcon,
  ExclamationTriangleIcon
} from '@heroicons/react/24/outline';
import LoadingState from 'components/LoadingState';
import { Alert } from 'lib/components/Alert';
import { Badge } from 'lib/components/Badge';
import { ColumnButton } from 'lib/components/ColumnButton';
import { ColumnService } from 'lib/services/directory';
import { Product } from 'lib/enums';
import { Dispatch, SetStateAction, useContext, useMemo, useState } from 'react';
import { logAndCaptureException } from 'utils';
import { Ad, isObituary } from 'lib/types/ad';
import { OrderModel } from 'lib/model/objects/orderModel';
import useSafeAsyncEffect from 'lib/frontend/hooks/useSafeAsyncEffect';
import { SuccessModal } from 'lib/components/SuccessModal';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useNavigate } from 'react-router-dom';
import { PRODUCT_TO_ROUTE } from 'router/routes';
import {
  CheckCircleIcon,
  QuestionMarkCircleIcon
} from '@heroicons/react/24/solid';
import AuthActions, {
  selectAvailableOrganizations,
  selectUser
} from 'redux/auth';
import { exists } from 'lib/types';
import { ConsolidatedOrderPricing } from 'lib/types/order';
import { useLoading } from 'lib/components/hooks/useLoading';
import { getBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import CheckboxInput from 'lib/components/Checkbox/CheckboxInput';
import { isPublisher } from 'lib/utils/users';
import { TextAreaField } from 'lib/components/TextAreaField';
import { Tooltip } from 'lib/components/Tooltip';
import { newspapersCanShareSubscription } from 'lib/subscriptions';
import {
  NewspaperOrdersFormData,
  SuccessModalType
} from '../../PlacementFlowStepSelector';
import MultiStepHeader from '../../components/MultiStepHeader';
import downloadProof from '../../helpers/downloadProof';
import { getSoonestAdDeadline } from '../../helpers/soonestAdDeadline';
import NewspaperOrderSummary from './NewspaperOrderSummary';
import { NewspapersContext } from '../../contexts/NewspapersContext';
import OrderSummaryRow from './OrderSummaryRow';
import Pricing from './Pricing';
import Coupons from './Coupons';
import { useCoupons } from '../../hooks/useCoupons';

type OrderSummaryProps = {
  newspaperOrdersFormData: NewspaperOrdersFormData;
  invoiceLoading: boolean;
  submitLoading: boolean;
  adData: Partial<Ad>;
  orderModel: OrderModel;
  filingTypeLabel: string;
  product: Product;
  version: number;
  consolidatedOrderPricing: ConsolidatedOrderPricing | undefined;
  requireNewInvoice: boolean;
  showSuccessModal: SuccessModalType | null;
  originalTotalPriceInCents: number | null;
  getRequireNewInvoiceLoading: boolean;
  onCouponUpdate: () => Promise<ConsolidatedOrderPricing | undefined>;
  invoiceMarkedAsPaid: boolean;
  setInvoiceMarkedAsPaid: Dispatch<SetStateAction<boolean>>;
  onPaymentNotesUpdate: Dispatch<SetStateAction<string>>;
};

function OrderSummary({
  newspaperOrdersFormData,
  invoiceLoading,
  submitLoading,
  adData,
  orderModel,
  filingTypeLabel,
  product,
  version,
  requireNewInvoice,
  showSuccessModal,
  getRequireNewInvoiceLoading,
  consolidatedOrderPricing,
  originalTotalPriceInCents,
  onCouponUpdate,
  invoiceMarkedAsPaid,
  setInvoiceMarkedAsPaid,
  onPaymentNotesUpdate
}: OrderSummaryProps) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const availableOrganizations = useAppSelector(selectAvailableOrganizations);

  const [error, setError] = useState('');
  const [notes, setNotes] = useState('');
  const enableCoupons = getBooleanFlag(LaunchDarklyFlags.ENABLE_COUPONS, true);

  const handleCouponUpdate = async () => {
    try {
      await onCouponUpdate();
    } catch (error) {
      logAndCaptureException(
        ColumnService.ORDER_PLACEMENT,
        error,
        'Error updating order price after coupon update',
        {
          orderId: orderModel.id
        }
      );
      setError('Error updating order price. Please try again.');
    }
  };

  const {
    coupons,
    addCoupon,
    removeCoupon,
    loading: couponLoading,
    error: couponError
  } = useCoupons({
    orderModel,
    version,
    onCouponUpdate: handleCouponUpdate,
    shouldDelayFetchCoupons: invoiceLoading
  });
  if (!consolidatedOrderPricing) {
    setError('Order pricing is not available. Please refresh the page.');
  }
  const { publishersAvailableForPlacement } = useContext(NewspapersContext);

  const filteredOrganizationData = availableOrganizations.filter(org =>
    newspaperOrdersFormData.some(
      newspaperOrder => newspaperOrder.newspaper?.id === org.id
    )
  );
  const canShareSubscription = useMemo(
    () => newspapersCanShareSubscription(filteredOrganizationData),
    [filteredOrganizationData.map(org => org.id).join(',')]
  );

  const totalInCents = consolidatedOrderPricing?.totalInCents;
  const { value: oldTotalInCents, isError: isPricingError } = useAsyncEffect({
    fetchData: async () => {
      // We're in the edit flow, so we need to get the pricing for the old version
      if (orderModel.modelData.activeVersion !== version) {
        const { response: pricing, error: pricingError } =
          await orderModel.getOrderPricing(orderModel.modelData.activeVersion);
        if (pricingError) {
          logAndCaptureException(
            ColumnService.ORDER_PLACEMENT,
            pricingError,
            'Error getting old pricing',
            { orderId: orderModel.id }
          );
          return null;
        }
        return pricing.totalInCents;
      }

      // Tracks price from the first time we loaded the order summary page
      if (originalTotalPriceInCents) {
        return originalTotalPriceInCents;
      }
      return null;
    },
    dependencies: [
      orderModel.modelData.activeVersion,
      consolidatedOrderPricing?.totalDiscountInCents
    ]
  });

  const newspaperOrderSummaries = newspaperOrdersFormData.map(
    newspaperOrder => {
      const newspaper = publishersAvailableForPlacement.find(
        snap => snap.id === newspaperOrder.newspaper?.id
      );
      if (!newspaper) {
        const err = new Error(
          `Newspaper ${newspaperOrder.newspaper?.id} not found`
        );
        logAndCaptureException(
          ColumnService.ORDER_PLACEMENT,
          err,
          'Newspaper not found in OrderSummary',
          {
            newspaperId: newspaperOrder.newspaper?.id,
            service: ColumnService.ORDER_PLACEMENT
          }
        );
        setError('Newspaper not found. Please refresh the page.');
        return null;
      }
      if (!newspaperOrder.publishingMedium) {
        const err = new Error(
          `Publishing medium for newspaper order of newspaper ${newspaperOrder.newspaper?.id} not found`
        );
        logAndCaptureException(
          ColumnService.ORDER_PLACEMENT,
          err,
          'Publishing medium not found in OrderSummary',
          {
            newspaperId: newspaperOrder.newspaper?.id,
            service: ColumnService.ORDER_PLACEMENT
          }
        );
        setError('Publishing medium not found. Please refresh the page.');
        return null;
      }
      if (!newspaperOrder.publishingDates) {
        const err = new Error(
          `Publishing dates for newspaper order of newspaper ${newspaperOrder.newspaper?.id} [${newspaperOrder.publishingMedium}] not found`
        );
        logAndCaptureException(
          ColumnService.ORDER_PLACEMENT,
          err,
          'Publishing dates not found in OrderSummary',
          {
            newspaperId: newspaperOrder.newspaper?.id,
            publishingMedium: newspaperOrder.publishingMedium,
            service: ColumnService.ORDER_PLACEMENT
          }
        );
        setError('Publishing dates not found. Please refresh the page.');
        return null;
      }

      return {
        newspaper,
        publishingMedium: newspaperOrder.publishingMedium,
        publishingDates: newspaperOrder.publishingDates
      };
    }
  );

  const [downloadingPDF, handleDownloadPDFWithLoading] = useLoading();
  const handleDownloadPDF = async () => {
    try {
      await downloadProof(orderModel.id, version);
    } catch (error) {
      logAndCaptureException(
        ColumnService.ORDER_PLACEMENT,
        error,
        'Error downloading proofs',
        {
          orderId: orderModel.id
        }
      );
      setError('Error downloading proofs. Please try again.');
    }
  };

  const isEditing = orderModel.modelData.activeVersion !== version;

  const { value: soonestAdDeadline } = useSafeAsyncEffect({
    fetchData: () => getSoonestAdDeadline(product, newspaperOrdersFormData),
    dependencies: []
  });

  if (!soonestAdDeadline) {
    return (
      <LoadingState
        context={{
          service: ColumnService.ORDER_PLACEMENT,
          location: 'Ad placement - Order Summary',
          tags: {
            product,
            adPlacementFlow: 'true',
            orderId: orderModel.id
          }
        }}
      />
    );
  }

  if (isPricingError) {
    return (
      <Alert id="pricing-comparison-error" title="Could not retrieve pricing" />
    );
  }

  const showComparison =
    oldTotalInCents !== null &&
    oldTotalInCents !== totalInCents &&
    !invoiceLoading;

  const enableMarkInvoicePaidFlag = getBooleanFlag(
    LaunchDarklyFlags.ENABLE_MARK_INVOICE_PAID_FOR_OBITS_AND_CLASSIFIEDS,
    false
  );

  const orderIncludesPublisherOutsideOfCurrentOrganization =
    !newspaperOrdersFormData.every(newspaperOrder =>
      availableOrganizations.some(
        org => org.id === newspaperOrder.newspaper?.id
      )
    );

  const enableMarkInvoicePaid =
    enableMarkInvoicePaidFlag &&
    canShareSubscription &&
    !orderIncludesPublisherOutsideOfCurrentOrganization;

  const publisherPaysColumnInvoices = user
    ? isPublisher(user) &&
      !!publishersAvailableForPlacement[0].data().payColumnInvoices?.enabled
    : false;

  const showToggle = enableMarkInvoicePaidFlag && publisherPaysColumnInvoices;

  return (
    <>
      {error && <Alert id="order-summary-error">{error}</Alert>}
      {isEditing && !getRequireNewInvoiceLoading && requireNewInvoice && (
        <Alert
          id="order-summary-warning"
          description="Column will issue a refund for the full amount of the original payment and you will be charged the new amount."
          status="warning"
          icon={<ExclamationTriangleIcon className="h-5 w-5" />}
        />
      )}
      <MultiStepHeader
        title="Order Summary"
        description="Please review your order and confirm submission details."
      >
        <div className="hidden md:block">
          <ColumnButton
            type="button"
            onClick={() => handleDownloadPDFWithLoading(handleDownloadPDF)}
            buttonText={`Download Proof${
              (newspaperOrdersFormData.length ?? 0) > 1 ? 's' : ''
            }`}
            startIcon={<ArrowDownTrayIcon className="h-5 w-5" />}
            size="lg"
            loading={downloadingPDF || invoiceLoading}
            tertiary
          />
        </div>
      </MultiStepHeader>
      <div className="grid grid-cols-1 gap-4">
        {isObituary(adData) && adData.deathVerification?.funeralHomeName && (
          <OrderSummaryRow
            label="Funeral Home"
            data={adData.deathVerification.funeralHomeName}
          />
        )}
        <hr />
        {newspaperOrderSummaries.map(summaryInfo =>
          summaryInfo ? (
            <>
              <NewspaperOrderSummary
                key={`${summaryInfo.newspaper.id}-${summaryInfo.publishingMedium}`}
                newspaper={summaryInfo.newspaper}
                publishingMedium={summaryInfo.publishingMedium}
                publishingDates={summaryInfo.publishingDates}
                filingTypeLabel={filingTypeLabel}
              />
              <hr />
            </>
          ) : null
        )}
        <OrderSummaryRow
          label="Ad Deadline"
          data={
            <Badge status="warning" size="md">
              {soonestAdDeadline.format('MMM D, YYYY h:mm A z')}
            </Badge>
          }
        />
        <hr />
        <Pricing
          showComparison={showComparison}
          oldTotalInCents={oldTotalInCents}
          totalInCents={totalInCents}
          invoiceLoading={invoiceLoading || getRequireNewInvoiceLoading}
        />
      </div>
      <div>
        {enableCoupons && (
          <div className="flex justify-end">
            <Coupons
              orderModel={orderModel}
              invoiceLoading={invoiceLoading}
              submitLoading={submitLoading}
              onAddCoupon={addCoupon}
              onRemoveCoupon={removeCoupon}
              coupons={coupons}
              couponLoading={couponLoading}
              couponError={couponError}
              version={version}
            />
          </div>
        )}
        {showToggle && (
          <div className="flex flex-col items-end gap-6">
            <div>
              {orderIncludesPublisherOutsideOfCurrentOrganization && (
                <Tooltip helpText="Mark as paid can only be used for publishers within the current organization. Please go back and remove additional papers.">
                  <QuestionMarkCircleIcon className="h-5 w-5" />
                </Tooltip>
              )}
              {!canShareSubscription && (
                <Tooltip helpText="Newspapers selected do not share the same payment account information. Please go back and remove additional papers.">
                  <QuestionMarkCircleIcon className="h-5 w-5" />
                </Tooltip>
              )}
              <CheckboxInput
                id="mark-invoice-paid"
                checked={invoiceMarkedAsPaid}
                onChange={() => {
                  setInvoiceMarkedAsPaid(!invoiceMarkedAsPaid);
                  setNotes('');
                  onPaymentNotesUpdate('');
                }}
                labelText="Click here to mark the invoice as paid"
                inputPosition="right"
                disabled={!enableMarkInvoicePaid}
              />
              {invoiceMarkedAsPaid && (
                <TextAreaField
                  id="notes"
                  value={notes}
                  onChange={value => {
                    setNotes(value);
                    onPaymentNotesUpdate(value);
                  }}
                  labelText="How was this payment collected?"
                  placeholder="Received check in person"
                  required
                />
              )}
            </div>
          </div>
        )}
      </div>
      {showSuccessModal && (
        <SuccessModal
          header={showSuccessModal}
          body=" "
          gif={<CheckCircleIcon className="w-16 text-column-green-400" />}
          onSubmit={() => {
            if (exists(user)) {
              navigate(PRODUCT_TO_ROUTE[product]);
            } else {
              dispatch(AuthActions.logout());
              navigate('/');
            }
          }}
          setOpen={() => {}}
          noExitOutsideModal
        />
      )}
    </>
  );
}

export default OrderSummary;
