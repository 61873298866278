import { Product } from 'lib/enums';
import Header from 'components/Header';
import { selectOrderIdClaims } from 'redux/auth';
import { useAppSelector } from 'redux/hooks';
import { OBITUARY_ROUTES } from 'router/routes';
import { useAppParams } from 'lib/frontend/utils/router';
import { AdFormStatusProvider } from './contexts/AdFormStatusProvider';
import AdPlacementFlow from './AdPlacementFlow';

export default function PlaceAdContainer() {
  const { id: orderDraftId } = useAppParams(OBITUARY_ROUTES.PLACE);

  const orderIdClaims = useAppSelector(selectOrderIdClaims);
  const [claim] = orderIdClaims;

  const segments = location.pathname.split('/');
  const product =
    segments[1] === 'obituaries' ? Product.Obituary : Product.Classified;

  return (
    <AdFormStatusProvider>
      <Header productLine={product} />
      <AdPlacementFlow
        claim={claim}
        orderDraftId={orderDraftId}
        product={product}
      />
    </AdFormStatusProvider>
  );
}
