import {
  InternalServerError,
  wrapErrorAsColumnError
} from '../errors/ColumnErrors';
import { safeGetModelArrayFromQuery } from '../model/getModel';
import { PackageModel } from '../model/objects/packageModel';
import { EFirebaseContext, EOrganization, ERef, Package } from '../types';
import { ResponseOrError, wrapSuccess } from '../types/responses';
import { BaseService } from './baseService';

export class PackageService extends BaseService<Package> {
  constructor(ctx: EFirebaseContext) {
    super(ctx, ctx.packagesRef());
  }

  public async getPublisherPackagesByFilingTypeLabel({
    publisherOrganization,
    filingTypeLabel,
    isUserPublisher
  }: {
    publisherOrganization: ERef<EOrganization>;
    filingTypeLabel: string;
    isUserPublisher: boolean;
  }): Promise<ResponseOrError<PackageModel[]>> {
    const ownedPackagesQuery = this.ctx
      .packagesRef()
      .where('authorizedOrganization', '==', publisherOrganization)
      .where('filingTypeLabel', '==', filingTypeLabel);
    const sharedPackagesQuery = this.ctx
      .packagesRef()
      .where('publisherOrganizations', 'array-contains', publisherOrganization)
      .where('filingTypeLabel', '==', filingTypeLabel);

    const [ownedPackagesError, ownedPackages] =
      await safeGetModelArrayFromQuery(
        PackageModel,
        this.ctx,
        ownedPackagesQuery
      );

    if (ownedPackagesError) {
      return wrapErrorAsColumnError(ownedPackagesError, InternalServerError);
    }

    const [sharedPackagesError, sharedPackages] =
      await safeGetModelArrayFromQuery(
        PackageModel,
        this.ctx,
        sharedPackagesQuery
      );

    if (sharedPackagesError) {
      return wrapErrorAsColumnError(sharedPackagesError, InternalServerError);
    }

    const packages = [...ownedPackages, ...sharedPackages];

    if (packages.length === 0) {
      return wrapSuccess([]);
    }

    const visiblePackages = packages.filter(packageModel =>
      packageModel.isVisibleToUser(isUserPublisher)
    );

    return wrapSuccess(visiblePackages);
  }
}
