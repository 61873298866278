import api from 'api';
import { isWickOrderNumber } from 'lib/integrations/affinityx/helpers';
import { ColumnService } from 'lib/services/directory';
import {
  ERef,
  ERequestTypes,
  EResponseTypes,
  ETemplate,
  ETemplateStyles
} from 'lib/types';
import { wrapError, wrapSuccess } from 'lib/types/responses';
import { isUndefined } from 'lodash';
import { logAndCaptureException } from 'utils';
import { ResponseOrError } from '../../../lib/types/responses';

export const getMaxAdHeightForTemplate = async (
  adTemplate: ERef<ETemplate>
): Promise<number | null> => {
  const reqBody: ERequestTypes['templates/styles'] = {
    templateId: adTemplate.id,
    forceRefresh: true
  };

  let styles: ETemplateStyles;
  try {
    const resp: EResponseTypes['templates/styles'] = await api.post(
      'templates/styles',
      reqBody
    );
    if (!resp.success) {
      throw new Error('Unable to get template styles');
    }
    styles = resp.styles;
  } catch (err) {
    logAndCaptureException(
      ColumnService.AFFINITY_X,
      err,
      'Unable to get template styles',
      {
        adTemplateId: adTemplate.id
      }
    );
    return null;
  }

  const {
    pageHeight: pageHeightInPx,
    borderWidth: borderWidthInPx,
    pointsPerInch
  } = styles;
  if (!pointsPerInch || !pageHeightInPx || isUndefined(borderWidthInPx)) {
    return null;
  }

  const maxPageHeightInPx = pageHeightInPx - 2 * borderWidthInPx;
  const maxPageHeightInInches = maxPageHeightInPx / pointsPerInch;
  // we round to 3 decimal places because the AX Panel UI uses a step of 0.001 on the height input
  return Math.round(maxPageHeightInInches * 1000) / 1000;
};

export enum AffinityXSyncStatus {
  /**
   * Indicates that no sync has yet been attempted with the current order number, no sync is in progress,
   * and the last sync attempt did not fail
   */
  READY_TO_SYNC = 'READY_TO_SYNC',

  /**
   * Indicates that a sync for this notice is already in progress
   */
  SYNC_IN_PROGRESS = 'SYNC_IN_PROGRESS',

  /**
   * Indicates that the sync is in progress, but AX is waiting for materials to be uploaded
   */
  SYNC_IN_PROGRESS_AWAITING_MATERIALS = 'SYNC_IN_PROGRESS_AWAITING_MATERIALS',

  /**
   * Indicates that the sync is in progress, and that AX is working on the order in production
   */
  SYNC_IN_PROGRESS_IN_PRODUCTION = 'SYNC_IN_PROGRESS_IN_PRODUCTION',

  /**
   * Indicates that the sync is in progress, and that AX has finished the order and is awaiting review
   */
  SYNC_IN_PROGRESS_AWAITING_REVIEW = 'SYNC_IN_PROGRESS_AWAITING_REVIEW',

  /**
   * Indicates that the last sync was successful, but a new order number is required to sync again
   */
  SYNC_SUCCESSFUL = 'SYNC_SUCCESSFUL',

  /**
   * Indicates that the last sync was cancelled, but a new order number is required to sync again
   */
  SYNC_CANCELLED_EDIT_REQUIRED = 'SYNC_CANCELLED_EDIT_REQUIRED',

  /**
   * Indicates that the last attempt to sync failed at some point after the order was created in AffinityX, so a new order number is required to retry
   */
  SYNC_FAILED_AFTER_ORDER_CREATION = 'SYNC_FAILED_AFTER_ORDER_CREATION',

  /**
   * Indicates that the last attempt to sync failed in Column before the order was created in AffinityX, so it is ready for a retry with the current order number
   */
  SYNC_FAILED_BEFORE_ORDER_CREATION = 'SYNC_FAILED_BEFORE_ORDER_CREATION',

  /**
   * Uknown. Should not happen under normal circumstances. But can happen as we roll out.
   */
  SYNC_UNKNOWN = 'SYNC_UNKNOWN'
}

export const getAXOrderStatus = async (
  orderNumber: string
): Promise<ResponseOrError<AffinityXSyncStatus, Error>> => {
  const instance = isWickOrderNumber(orderNumber) ? 'wick' : 'col';

  const apiStatusToPill = (status: string) => {
    if (status === 'Awaiting Materials')
      return AffinityXSyncStatus.SYNC_IN_PROGRESS_AWAITING_MATERIALS;
    if (status === 'In Production')
      return AffinityXSyncStatus.SYNC_IN_PROGRESS_IN_PRODUCTION;
    if (status === 'In Progress' || status === 'Order Materials Submitted')
      return AffinityXSyncStatus.SYNC_IN_PROGRESS;
    if (status === 'Awaiting Review')
      return AffinityXSyncStatus.SYNC_IN_PROGRESS_AWAITING_REVIEW;
    if (status === 'Killed')
      return AffinityXSyncStatus.SYNC_CANCELLED_EDIT_REQUIRED;
    if (status === 'Delivery Processed' || status === 'Delivered')
      return AffinityXSyncStatus.SYNC_SUCCESSFUL;
    return AffinityXSyncStatus.SYNC_UNKNOWN;
  };

  try {
    const apiOrderDetails = await api.get(
      `display-ads/ax/${instance}/orderNumbers/${orderNumber}`
    );
    const status = apiStatusToPill(apiOrderDetails.production_status);
    if (!status) return wrapError(new Error('Unknown status'));
    return wrapSuccess(status);
  } catch (e) {
    if (e instanceof Error && (e as Error).message.includes('404')) {
      return wrapSuccess(AffinityXSyncStatus.READY_TO_SYNC);
    }
    return wrapError(new Error('Failed to get status from AX Api'));
  }
};

export const getButtonTextFromSyncStatus = (
  syncStatus: AffinityXSyncStatus | null
): string => {
  switch (syncStatus) {
    case AffinityXSyncStatus.READY_TO_SYNC:
    case AffinityXSyncStatus.SYNC_FAILED_BEFORE_ORDER_CREATION:
      return 'Start sync';
    case AffinityXSyncStatus.SYNC_IN_PROGRESS:
    case AffinityXSyncStatus.SYNC_IN_PROGRESS_AWAITING_MATERIALS:
    case AffinityXSyncStatus.SYNC_IN_PROGRESS_IN_PRODUCTION:
    case AffinityXSyncStatus.SYNC_IN_PROGRESS_AWAITING_REVIEW:
    case AffinityXSyncStatus.SYNC_FAILED_AFTER_ORDER_CREATION:
      return 'Update order';
    case AffinityXSyncStatus.SYNC_SUCCESSFUL:
      return 'Edit notice';
    case AffinityXSyncStatus.SYNC_CANCELLED_EDIT_REQUIRED:
      return 'Sync new order';
    default:
      return 'Start sync';
  }
};
