import AppLayoutFrame from 'layouts/appLayout';
import { RouteObject } from 'react-router-dom';
import PlaceAdContainer from 'routes/ads/place';
import EditOrderContainer from 'routes/ads/place/EditOrderContainer';
import FuneralHomeVerification from 'routes/verify/FuneralHomeVerification';
import Logout from 'routes/logout';
import Reports from 'routes/reports';
import Affidavits from 'routes/automatedAffidavits';
import PlaceScroll from 'routes/placeScroll';
import StripeConnect from 'routes/settings/publisher/paymentSettings/StripeConnect';
import UserSettings from 'routes/settings/userSettings';
import NewspaperTypeForm from 'routes/typeform/newspaper';
import AddNoticeWrapper from 'routes/addNotice';
import Ads from 'routes/ads';
import NoticeDetail from 'routes/notice';
import AdDetail from 'routes/ads/details/AdDetail';
import DuplicateOrder from 'routes/ads/details/DuplicateOrder';
import CreateInvoice from 'routes/notice/billing/CreateInvoice';
import Payments from 'routes/payments';
import ErrorPage from 'routes/errors/ErrorPage';
import Madlib from 'routes/madlib';
import Impersonate from 'routes/impersonate';
import SubscriptionManagement from 'routes/subscriptions';
import LoadingState from 'components/LoadingState';
import Pagination from 'routes/pagination';
import UpdateEmail from 'routes/updateEmail';
import Placements from 'routes/placements';
import InternalDocs from 'routes/docs/internal';
import {
  CLASSIFIED_ROUTES,
  FILE_ROUTES,
  FORM_ROUTES,
  NOTICE_ROUTES,
  MAIN_ROUTES,
  ERROR_ROUTE,
  OBITUARY_ROUTES,
  PUBLISH_NOTICE_ROUTES,
  DOCS_ROUTES
} from '../routes';
import { RequireAuth } from '../redirect';
import { getRedirect } from '../redirect/RedirectWithParams';
import { organizationSettingsRoutes } from './organizationSettingsRoutes';

const publicRoutes: RouteObject = {
  children: [
    { path: CLASSIFIED_ROUTES.DETAIL, element: <AdDetail /> },
    { path: CLASSIFIED_ROUTES.EDIT, element: <EditOrderContainer /> },
    { path: CLASSIFIED_ROUTES.PLACE, element: <PlaceAdContainer /> },
    { path: FILE_ROUTES.BY_NOTICE_WITH_SLUG, element: <AddNoticeWrapper /> },
    { path: FILE_ROUTES.BY_NOTICE, element: <AddNoticeWrapper /> },
    { path: FILE_ROUTES.BY_TYPE_WITH_SLUG, element: <AddNoticeWrapper /> },
    { path: FORM_ROUTES.NEWSPAPER_WITH_NOTICE, element: <NewspaperTypeForm /> },
    { path: MAIN_ROUTES.PLACE_NOTICE, element: <PlaceScroll /> },
    { path: MAIN_ROUTES.SEARCH },
    { path: MAIN_ROUTES.UPDATE_EMAIL, element: <UpdateEmail /> },
    { path: MAIN_ROUTES.VERIFY, element: <FuneralHomeVerification /> },
    { path: OBITUARY_ROUTES.DETAIL, element: <AdDetail /> },
    { path: OBITUARY_ROUTES.EDIT, element: <EditOrderContainer /> },
    { path: OBITUARY_ROUTES.PLACE, element: <PlaceAdContainer /> }
  ]
};

const authenticatedRedirects: RouteObject[] = [
  getRedirect(PUBLISH_NOTICE_ROUTES.DETAIL, NOTICE_ROUTES.DETAIL),
  getRedirect(
    PUBLISH_NOTICE_ROUTES.INVOICE_CREATE,
    NOTICE_ROUTES.INVOICE_CREATE
  )
];

const authenticatedRoutes: RouteObject = {
  element: <RequireAuth />,
  children: [
    { path: '/', element: <LoadingState /> },
    { path: ERROR_ROUTE, element: <ErrorPage /> },
    { path: MAIN_ROUTES.AFFIDAVITS, element: <Affidavits /> },
    { path: MAIN_ROUTES.CLASSIFIEDS, element: <Ads /> },
    { path: MAIN_ROUTES.LOGOUT, element: <Logout /> },
    { path: MAIN_ROUTES.NOTICES, element: <Ads /> },
    { path: MAIN_ROUTES.OBITUARIES, element: <Ads /> },
    { path: MAIN_ROUTES.PAGINATION, element: <Pagination /> },
    { path: MAIN_ROUTES.PAYMENTS, element: <Payments /> },
    { path: MAIN_ROUTES.REPORTS, element: <Reports /> },
    { path: MAIN_ROUTES.SETTINGS, element: <UserSettings /> },
    { path: MAIN_ROUTES.STRIPE_CONNECT, element: <StripeConnect /> },
    { path: MAIN_ROUTES.SUBSCRIPTIONS, element: <SubscriptionManagement /> },
    { path: MAIN_ROUTES.PLACEMENTS, element: <Placements /> },
    { path: NOTICE_ROUTES.DETAIL, element: <NoticeDetail /> },
    { path: NOTICE_ROUTES.EDIT, element: <PlaceScroll /> },
    { path: NOTICE_ROUTES.INVOICE_CREATE, element: <CreateInvoice /> },
    { path: CLASSIFIED_ROUTES.DUPLICATE, element: <DuplicateOrder /> },
    organizationSettingsRoutes,
    ...authenticatedRedirects
  ]
};

const internalRoutes: RouteObject = {
  element: <RequireAuth internalOnly />,
  children: [
    { path: MAIN_ROUTES.IMPERSONATE, element: <Impersonate /> },
    { path: MAIN_ROUTES.MADLIB, element: <Madlib /> },
    { path: DOCS_ROUTES.GITBOOK, element: <InternalDocs /> }
  ]
};

export const appRoutes: RouteObject = {
  element: <AppLayoutFrame />,
  children: [publicRoutes, authenticatedRoutes, internalRoutes]
};
