import { PlusCircleIcon } from 'icons';
import { publisherReadyToUpload } from 'lib/publishers';
import { exists } from 'lib/types';
import { canPublisherUserSeeNewspaperSelect } from 'sagas/helpers';
import { ColumnButton } from 'lib/components/ColumnButton';
import { useAppSelector } from 'redux/hooks';
import {
  selectActiveOrganization,
  selectIsPublisher,
  selectShowAllOrgsNotices,
  selectUser
} from 'redux/auth';
import { createSelector } from 'reselect';
import { useNavigate } from 'react-router-dom';
import { MAIN_ROUTES } from 'router/routes';

type PlaceNoticeButtonProps = {
  id: string;
};

export default function PlaceNoticeButton({ id }: PlaceNoticeButtonProps) {
  const navigate = useNavigate();

  const noticePlacementDisabled = useAppSelector(
    selectIsNoticePlacementButtonDisabled
  );

  return (
    <ColumnButton
      id={id}
      trackingClass="place-notice-button"
      startIcon={<PlusCircleIcon />}
      buttonText={'Place a notice'}
      primary
      disabled={noticePlacementDisabled}
      type="button"
      onClick={() => {
        navigate(MAIN_ROUTES.PLACE_NOTICE);
      }}
    />
  );
}

export const selectIsNoticePlacementButtonDisabled = createSelector(
  selectUser,
  selectActiveOrganization,
  selectShowAllOrgsNotices,
  selectIsPublisher,
  (user, activeOrganization, showAllOrgsNotices, isPublisher) => {
    const publisherCanSeeNewspaperSelectinPlacement =
      !exists(user) || canPublisherUserSeeNewspaperSelect(user, true);

    const placementDisabled =
      /**
       * If the user is a publisher and they are currently viewing
       * a paper that is not ready for upload
       */
      (isPublisher &&
        !showAllOrgsNotices &&
        exists(activeOrganization) &&
        !publisherReadyToUpload(activeOrganization, true)) ||
      /**
       * If the user is a publisher, viewing the All Organizations page,
       * and they don't have newspaper selection enabled in placement
       */
      (showAllOrgsNotices && !publisherCanSeeNewspaperSelectinPlacement);

    return !!placementDisabled;
  }
);
