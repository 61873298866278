/* eslint-disable guard-for-in */
import React, { useState, useEffect } from 'react';
import { logAndCaptureCriticalError, logAndCaptureException } from 'utils';
import ReactPaginate from 'react-paginate';
import {
  ESnapshotExists,
  EOrganization,
  ESnapshot,
  EUser,
  FirebaseUser,
  EPayoutData,
  exists
} from 'lib/types';
import LoadingState from 'components/LoadingState';
import {
  getCurrencySymbol,
  unixTimeStampToNewspaperTimezoneDate
} from 'lib/helpers';
import api from 'api';
import { TransactionType, Product } from 'lib/enums';
import TailwindModal from 'components/TailwindModal';
import { makeCsvContent, PAYOUT_CSV_HEADERS } from 'lib/utils/csv';
import moment from 'moment';
import {
  PayoutParentRowData,
  PayoutGroup,
  PayoutChildRowData,
  StripeMetadataPerProductPayoutFields
} from 'lib/types/payout';
import { ColumnButton } from 'lib/components/ColumnButton';
import {
  ArrowDownTrayIcon,
  ArrowTopRightOnSquareIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  CreditCardIcon
} from '@heroicons/react/24/outline';
import ToastActions from 'redux/toast';
import { downloadFileContentsInBrowser } from 'lib/frontend/utils/browser';
import SettingsPage from 'routes/settings/SettingsPage';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { ColumnService } from 'lib/services/directory';
import { getModelFromSnapshot } from 'lib/model';
import { OrganizationModel } from 'lib/model/objects/organizationModel';
import { getFirebaseContext } from 'utils/firebase';
import { isValidProduct } from 'lib/enums/Product';
import { getErrorReporter } from 'lib/utils/errors';
import { selectActiveOrganization, selectUser } from 'redux/auth';
import RadioButtonGroup from 'lib/components/Checkbox/RadioButtonGroup';
import { Alert } from 'lib/components/Alert';
import {
  getNewspaperErrorInfoForNewspaperIdWithDefaults,
  missingAccountIdError
} from 'lib/errors/templateErrors';
import {
  ENOTICE_ACCOUNT_ID,
  DUMMY_ACCOUNT_ID,
  STRIPE_VARS
} from '../../../../constants';
import SettingsHeader from '../../SettingsHeader';
import Plaid from '../../plaid';
import AccountVerificationOptionsModal from '../../AccountVerificationOptionsModal';
import DwollaPasswordResetModal from './DwollaPasswordResetModal';
import DwollaCustomerModal from './DwollaCustomerModal';
import DwollaSettings from './DwollaSettings';
import PayoutGroupRows from './PayoutGroupRows';
import PayoutsFilterDialog from './PayoutsFilterDialog';
import {
  ALL_PRODUCT_TYPES_STRING,
  PayoutsFilterValue
} from './payoutFilterTypes';

const PAGE_SIZE = 10;

function PayoutTableHeader({
  header,
  width
}: {
  header?: string;
  width?: string;
}) {
  return (
    <th
      className={`py-3 ${
        width && `w-${width}`
      } bg-column-gray-25 text-left text-xs leading-4 font-medium text-column-gray-400 uppercase tracking-wider`}
    >
      {header}
    </th>
  );
}

function StripePayouts() {
  const activeOrganization = useAppSelector(selectActiveOrganization);
  const [payoutsLoading, setPayoutsLoading] = useState(false);
  const [payoutGroups, setPayoutGroups] = useState<PayoutGroup[]>([]);
  const [stripeLoginLink, setLoginLink] = useState<string | null>();
  const [stripeInvoicesLink, setStripeInvoicesLink] = useState('');
  const [payouts, setPayouts] = useState<any[]>([]);
  const [openDownloadModal, setOpenDownloadModal] = useState(false);
  const [pageVisited, setPageVisited] = useState<number[]>([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [showFilterMenu, setShowFilterMenu] = useState(false);
  const [productsActive, setProductsActive] = useState({
    [Product.Notice]: true,
    [Product.Classified]: false,
    [Product.Obituary]: false
  });
  const [userError, setUserError] = useState('');
  const [missingAccountId, setMissingAccountId] = useState(false);
  const [payoutsFilterValue, setPayoutsFilterValue] =
    useState<PayoutsFilterValue>({
      adType: ALL_PRODUCT_TYPES_STRING
    });

  const startIndex = pageNumber * PAGE_SIZE;
  const endIndex = payoutGroups?.length
    ? Math.min(startIndex + PAGE_SIZE, payoutGroups?.length) - 1
    : 0;

  useEffect(() => {
    const loadLink = async () => {
      if (!exists(activeOrganization)) {
        return;
      }
      if (!activeOrganization.data().accountId) {
        setUserError(
          'Payouts cannot be downloaded at this time. We have been notified of this issue and are working to resolve it.'
        );
        const paperInfo = await getNewspaperErrorInfoForNewspaperIdWithDefaults(
          getFirebaseContext(),
          activeOrganization.id
        );
        const err = missingAccountIdError(paperInfo);
        logAndCaptureCriticalError(
          ColumnService.CSMS,
          err,
          'Organization does not have a Stripe Connect ID set in the stripe payouts page',
          {
            organizationId: activeOrganization.id,
            accountId: activeOrganization.data()?.accountId ?? ''
          }
        );
        setMissingAccountId(true);
        return;
      }
      try {
        const { response: url, error: loginLinkError } = await api.safePost(
          'payments/stripe-login-link',
          {
            accountId: activeOrganization.data().accountId
          }
        );
        if (loginLinkError) {
          throw loginLinkError;
        }
        setLoginLink(url);
      } catch (err) {
        logAndCaptureException(
          ColumnService.PAYMENTS,
          err,
          'Unable to create login link',
          {
            accountId: activeOrganization.data().accountId
          }
        );
      }
    };
    setLoginLink(null);
    void loadLink();
  }, [activeOrganization && activeOrganization.id]);

  useEffect(() => {
    const loadStripeInvoicesLink = async (customerId: string) => {
      try {
        const { url } = await api.post('subscription/stripe-billing-session', {
          customerId,
          returnUrl: window.location.href,
          configuration: 'organization_invoices'
        });

        setStripeInvoicesLink(url);
      } catch (e) {}
    };

    setStripeInvoicesLink('');

    // Note: this will not be visible if the setting is inherited from the
    // parent org.
    const enabled = activeOrganization?.data()?.payColumnInvoices?.enabled;
    const stripeCustomerId =
      activeOrganization?.data()?.payColumnInvoices?.stripeCustomerId;

    if (enabled && stripeCustomerId) {
      void loadStripeInvoicesLink(stripeCustomerId);
    }
  }, [activeOrganization?.id]);

  const downloadCSV = async (payoutId: string) => {
    if (!exists(activeOrganization) || missingAccountId) {
      return;
    }

    const relevantPayout = payouts.find(payout => payout.id === payoutId);
    const { response: csvResponse, error } = await api.safePost(
      'payments/download-payout',
      {
        payout: relevantPayout,
        accountId: activeOrganization.data().accountId
      }
    );

    if (error || !csvResponse) {
      logAndCaptureException(
        ColumnService.PAYMENTS,
        error,
        'An error occurred when downloading payout',
        {
          accountId: activeOrganization.data().accountId,
          relevantPayout
        }
      );
      return;
    }

    const { csvRows } = csvResponse;
    await Promise.all(csvRows).then((results: any) => {
      const csvContent = makeCsvContent(PAYOUT_CSV_HEADERS, results);
      const arrivalDate = unixTimeStampToNewspaperTimezoneDate(
        relevantPayout.arrival_date,
        activeOrganization.data()?.iana_timezone
      );

      downloadFileContentsInBrowser(
        `Payout_${moment(arrivalDate).format(
          `YYYY-MM-DD_${relevantPayout.id.slice(-5)}`
        )}.csv`,
        csvContent,
        'text/csv'
      );
    });
  };

  const loadRowsForPayoutTransfer = async (
    payout: EPayoutData
  ): Promise<PayoutGroup | undefined> => {
    const currencySymbol = getCurrencySymbol(payout.currency);
    const parentRow: PayoutParentRowData = {
      arrival_date: unixTimeStampToNewspaperTimezoneDate(
        payout.arrival_date,
        activeOrganization?.data()?.iana_timezone
      ),
      created: unixTimeStampToNewspaperTimezoneDate(
        payout.created,
        activeOrganization?.data()?.iana_timezone
      ),
      amount: `${currencySymbol}${(payout.amount / 100).toFixed(2)}`,
      id: payout.id,
      type: 'PAYOUT',
      status: payout.status
    };

    const getAdTypeFromMetadata = (
      metadata: Record<string, string> | undefined
    ): Product => {
      const adType = metadata?.adType;
      if (!adType || !isValidProduct(adType)) {
        return Product.Notice;
      }
      return adType;
    };

    const childRow: PayoutChildRowData[] = [];
    if (payout?.payments?.length) {
      for (const payment of payout.payments) {
        if (!payment?.source?.source_transfer) {
          continue;
        }

        const type =
          payment.source.source_transfer.metadata.type === 'revenueShare'
            ? TransactionType.dividend.label
            : TransactionType.payment.label;

        const adType = getAdTypeFromMetadata(
          payment.source.source_transfer.metadata
        );

        childRow.push({
          id: payment.id,
          type,
          adType,
          amount: `${currencySymbol}${(
            (payment.source.amount || 0) / 100
          ).toFixed(2)}`,
          parentId: payout.id,
          noticeId:
            type !== TransactionType.dividend.label
              ? payment.source.source_transfer.metadata.userNoticeId || ''
              : '',
          orderId: payment.source.source_transfer.metadata.userNoticeId ?? '',
          newspaperOrderId:
            payment.source.source_transfer.metadata.newspaperOrderId ?? '',
          receipt: payment.source.receipt_url
        });
      }
    }
    if (payout?.charges?.length) {
      for (const charge of payout.charges) {
        if (!charge.source) {
          continue;
        }

        const adType = getAdTypeFromMetadata(
          charge.source.source_transfer?.metadata
        );

        childRow.push({
          id: charge.id,
          type: TransactionType.processing_fee_deduction.label,
          adType,
          amount: `-${currencySymbol}${(
            (charge.source.amount || 0) / 100
          ).toFixed(2)}`,
          parentId: payout.id,
          noticeId: charge.source.metadata.userNoticeId ?? '',
          receipt: charge.source.receipt_url
        });
      }
    }
    if (payout?.refunds?.length) {
      for (const refund of payout.refunds) {
        if (!refund.source?.transfer_reversal) {
          continue;
        }

        const adType = getAdTypeFromMetadata(
          refund.source.source_transfer?.metadata
        );

        const transferReversalType =
          refund.source.transfer_reversal?.metadata.type;
        const sourceType = refund.source.metadata.type;
        const type =
          TransactionType.by_key(transferReversalType)?.label ||
          TransactionType.by_key(sourceType)?.label ||
          '';
        childRow.push({
          id: refund.id,
          type,
          adType,
          amount: `-${currencySymbol}${(
            (refund.source.amount || 0) / 100
          ).toFixed(2)}`,
          parentId: payout.id,
          noticeId:
            refund.source.transfer_reversal?.metadata?.userNoticeId || '',
          receipt: refund.source.receipt_url
        });
      }
    }
    if (payout?.feeRefunds?.length) {
      for (const feeRefund of payout.feeRefunds) {
        if (!feeRefund.source) {
          continue;
        }

        const adTypeFromMetadata =
          feeRefund.source.source_transfer?.metadata.adType;
        const adType = isValidProduct(adTypeFromMetadata)
          ? adTypeFromMetadata
          : Product.Notice;

        const type = TransactionType.feeRefund.label;
        childRow.push({
          id: feeRefund.id,
          type,
          adType,
          amount: `${currencySymbol}${(
            (feeRefund.source.amount || 0) / 100
          ).toFixed(2)}`,
          parentId: payout.id,
          noticeId: feeRefund.source.metadata?.userNoticeId || '',
          transactionTypeValue: TransactionType.feeRefund.key
        });
      }
    }
    /** This block handles to display manual payouts, this can be of two types:
     * 1. A payout reversed from Stripe dashboard
     * 2. A payout manually paid from Stripe dashboard
     */
    if (!payout.automatic) {
      if (payout.original_payout) {
        childRow.push({
          id: payout.id,
          adType: null,
          type: TransactionType.paymentReversed.label,
          amount: `${currencySymbol}${(payout.amount / 100).toFixed(2)}`,
          parentId: payout.id,
          noticeId: '' // reversed payouts do not have noticeId in Stripe metadata
        });
      } else {
        childRow.push({
          id: payout.id,
          type: TransactionType.manualPayment.label,
          adType: null,
          amount: `${currencySymbol}${(payout.amount / 100).toFixed(2)}`,
          parentId: payout.id,
          noticeId: '' // manual payouts do not have noticeId in Stripe metadata
        });
      }
    }
    return {
      parent: parentRow,
      children: childRow
    };
  };

  const productPayoutMetadataPertainsToAdType = (
    productPayoutMetadata: StripeMetadataPerProductPayoutFields | undefined
  ) => {
    if (!productPayoutMetadata) {
      return false;
    }
    const { charges, refunds, transfers, other } = productPayoutMetadata;
    return charges > 0 || refunds > 0 || transfers > 0 || other > 0;
  };

  const loadBalanceTransactions = async (payouts: EPayoutData[]) => {
    if (!exists(activeOrganization)) {
      return [];
    }

    const filteredPayouts = payouts.filter(payout => {
      const payoutMetadata = payout.metadata;
      const filteredAdType = payoutsFilterValue.adType;

      if (!payoutMetadata) {
        // if there is no payout metadata, show payout if no filter is applied or the default filter is applied
        return !filteredAdType || filteredAdType === ALL_PRODUCT_TYPES_STRING;
      }
      if (filteredAdType && filteredAdType === ALL_PRODUCT_TYPES_STRING) {
        return true;
      }
      if (filteredAdType && !isValidProduct(filteredAdType)) {
        // Exclude payout if adType is invalid (should not happen)
        getErrorReporter().logAndCaptureError(
          ColumnService.PAYMENTS,
          new Error('Invalid adType filter value in payouts table filter'),
          'Could not load balance transactions for payouts',
          {
            accountId: activeOrganization.data().accountId,
            adType: filteredAdType
          }
        );
        return false;
      }

      return productPayoutMetadataPertainsToAdType(
        payoutMetadata[filteredAdType]
      );
    });

    const start = pageNumber * PAGE_SIZE;
    const end =
      start + 10 <= filteredPayouts.length
        ? start + 10
        : filteredPayouts.length;
    const payoutsList = filteredPayouts.slice(start, end);
    const balanceTransactionsResponse = await api.safePost(
      'payments/stripe-bt-payouts-data',
      {
        accountId: activeOrganization.data().accountId,
        payouts: payoutsList
      }
    );
    const {
      response: balanceTransactionsData,
      error: balanceTransactionsError
    } = balanceTransactionsResponse;
    if (
      !balanceTransactionsData?.balanceTransactions?.length ||
      balanceTransactionsError
    ) {
      return filteredPayouts;
    }
    const { balanceTransactions } = balanceTransactionsData;
    const newPayouts = filteredPayouts;
    for (let i = start; i < end; i++) {
      newPayouts[i] = {
        ...filteredPayouts[i],
        ...balanceTransactions[i - start]
      };
    }
    return newPayouts;
  };

  // load balance transactions of paginated payouts
  const loadPayoutGroupsPage = async (payouts: EPayoutData[]) => {
    const newPayouts: EPayoutData[] = await loadBalanceTransactions(payouts);
    const groups: PayoutGroup[] = [];
    await Promise.all(
      newPayouts.map(async (payout: EPayoutData) => {
        const group = await loadRowsForPayoutTransfer(payout);
        if (group) {
          groups.push(group);
        }
      })
    );
    setPayoutGroups(groups);
    if (!pageVisited.includes(pageNumber)) {
      pageVisited.push(pageNumber);
    }
    setPageVisited(pageVisited);
  };

  const loadPayoutGroups = () => {
    setPayoutsLoading(true);
    loadPayoutGroupsPage(payouts).finally(() => setPayoutsLoading(false));
  };

  useEffect(() => {
    if (pageNumber !== 0 && !pageVisited.includes(pageNumber)) {
      loadPayoutGroups();
    }
  }, [pageNumber]);

  useEffect(() => {
    loadPayoutGroups();
  }, [payoutsFilterValue.adType]);

  /**
   * Loads the initial metadata about payouts
   */
  const loadPayouts = async () => {
    setPayoutsLoading(true);
    const payoutsResponse = await api.safePost('payments/stripe-payout-data');
    const payouts = payoutsResponse.response?.payouts;
    if (!payoutsResponse.error && payouts) {
      await loadPayoutGroupsPage(payouts);
      setPayouts(payouts);
    }
    setPayoutsLoading(false);
  };

  const getAndSetActiveProducts = () => {
    if (!exists(activeOrganization)) {
      return;
    }

    const newspaperModel = getModelFromSnapshot(
      OrganizationModel,
      getFirebaseContext(),
      activeOrganization
    );
    const hasObitsActive = newspaperModel.hasAdTypeActive(Product.Obituary);
    const hasClassifiedsActive = newspaperModel.hasAdTypeActive(
      Product.Classified
    );
    setProductsActive({
      ...productsActive,
      [Product.Obituary]: hasObitsActive,
      [Product.Classified]: hasClassifiedsActive
    });
  };

  useEffect(() => {
    setPayoutGroups([]);
    getAndSetActiveProducts();
    void loadPayouts();
  }, [activeOrganization?.id]);

  if (payoutsLoading)
    return (
      <LoadingState
        message="Loading payment data..."
        timeout={180}
        context={{
          location: 'PaymentSettings',
          tags: { accountId: activeOrganization?.data()?.accountId ?? '' },
          service: ColumnService.PAYMENTS
        }}
      />
    );

  return (
    <SettingsPage>
      {userError && (
        <Alert status="error" id="payouts-error" title={userError} />
      )}
      <SettingsHeader
        header="Payouts"
        description="Expand to see a breakdown of each payout to your bank."
      >
        <div className="space-x-2 flex items-center">
          {stripeInvoicesLink && (
            <ColumnButton
              tertiary
              id="stripe-invoices"
              size="lg"
              buttonText={'Invoices'}
              onClick={() => window.open(stripeInvoicesLink)}
              startIcon={<CreditCardIcon className="w-5 mr-1" />}
              type="button"
            />
          )}

          {stripeLoginLink && (
            <ColumnButton
              tertiary
              id="stripe-update-account"
              size="lg"
              buttonText={'Update Account'}
              onClick={() => window.open(stripeLoginLink)}
              startIcon={<ArrowTopRightOnSquareIcon className="w-5" />}
              type="button"
            />
          )}
          {(productsActive[Product.Classified] ||
            productsActive[Product.Obituary]) && (
            /* Do not show button to open PayoutsFilterDialog if newspaper
            does not have classifieds or obits active  */
            <button
              type="button"
              onClick={() => setShowFilterMenu(true)}
              className={
                'border-column-gray-200 bg-white border focus:outline-none hover:border-column-primary-400 p-2 px-4 rounded-md text-column-gray-400 font-semibold items-center inline-flex -ml-2'
              }
              id="filter-payouts-menu"
              aria-expanded="true"
              aria-haspopup="true"
            >
              <span className="">Filter</span>
              <ChevronDownIcon className="ml-2 w-5 h-5" />
            </button>
          )}

          {showFilterMenu && (
            <div className="relative inline-block text-left text-column-gray-400">
              <PayoutsFilterDialog
                className="absolute top-2 -ml-2"
                value={payoutsFilterValue}
                onChange={v => {
                  setPayoutsFilterValue(v);
                  setShowFilterMenu(false);
                }}
                productsActive={productsActive}
              />
            </div>
          )}

          <ColumnButton
            tertiary
            id="download-report"
            size="lg"
            buttonText={'Download Report'}
            onClick={() => setOpenDownloadModal(true)}
            startIcon={
              <ArrowDownTrayIcon className="w-5 text-column-primary-900" />
            }
            type="button"
          />
        </div>
      </SettingsHeader>
      <table className="min-w-full divide-y divide-gray-200">
        <thead>
          <tr>
            <PayoutTableHeader />
            <PayoutTableHeader header="amount" />
            <PayoutTableHeader width="1/5" />
            <PayoutTableHeader header="created" />
            <PayoutTableHeader header="arrives/arrived" />
            <PayoutTableHeader header="actions" />
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 rounded-b-lg">
          {payoutGroups.slice(startIndex, endIndex + 1).map(group => (
            <PayoutGroupRows
              key={group.parent.id}
              group={group}
              payoutSource={'stripe'}
              handleDownloadCsvClicked={id => downloadCSV(id)}
            />
          ))}
        </tbody>
      </table>
      <footer className="pr-5 py-0.5 bg-column-gray-25 border border-column-gray-100">
        <nav className="px-4 flex items-center justify-between sm:px-0">
          <div className="pl-6 sm:block flex-1 flex">
            <p className="text-sm text-column-gray-400 font-medium mx-1">
              {payoutGroups.length > 1
                ? `Showing ${startIndex + 1} to ${endIndex + 1} of ${
                    payoutGroups.length
                  } payouts`
                : 'Showing all payouts'}
            </p>
          </div>
          <div className="-mt-px w-0 flex-1 flex justify-end">
            <ReactPaginate
              previousLabel={<ChevronLeftIcon className="w-5" />}
              previousClassName="focus:outline-none border-b-2 border-transparent py-4 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700"
              nextLabel={<ChevronRightIcon className="w-5" />}
              nextClassName="focus:outline-none border-b-2 border-transparent py-4 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700"
              breakLabel={'...'}
              initialPage={pageNumber}
              forcePage={pageNumber}
              breakClassName="focus:outline-none border-b-2 border-transparent py-4 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700"
              pageClassName="focus:outline-none border-b-2 border-transparent py-4 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300"
              activeLinkClassName="focus:outline-none outline-none text-blue-500 border-blue-500"
              pageLinkClassName="px-4 text-sm"
              pageCount={Math.ceil(payoutGroups.length / PAGE_SIZE)}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={pageTo => {
                setPageNumber(pageTo.selected);
              }}
              containerClassName={'pagination flex'}
              activeClassName={'text-blue-500 border-blue-500 outline-none'}
            />
          </div>
        </nav>
      </footer>
      {openDownloadModal && (
        <PayoutsDownloadModal close={() => setOpenDownloadModal(false)} />
      )}
    </SettingsPage>
  );
}

type CheckSettingsProps = {
  activeOrganization: ESnapshotExists<EOrganization>;
  user: ESnapshot<EUser>;
  fromRegistration?: boolean;
  continueRegistration?: () => void;
  updateToast?: () => void;
  userAuth?: FirebaseUser;
};

type PlaidData = {
  accountNumber: string;
  accountId: string;
  publicToken: string;
  institutionName: string;
  accountNickname: string;
};

export function CheckSettings({
  activeOrganization,
  user,
  fromRegistration,
  continueRegistration,
  updateToast,
  userAuth
}: CheckSettingsProps) {
  const dispatch = useAppDispatch();
  const [showDwollaCustomerModal, setShowDwollaCustomerModal] = useState(false);
  const [showAuthorizationModal, setShowAuthorizationModal] = useState(false);
  const [showAlreadyExistsModal, setShowAlreadyExistsModal] = useState('');
  const [showPasswordResetModal, setShowPasswordResetModal] = useState(false);
  const [openPlaid, setOpenPlaid] = useState(fromRegistration);
  const [loading, setLoading] = useState(false);
  // plaid details
  const [plaidData, setPlaidData] = useState<PlaidData>();
  const [addBankVerifyOptions, setAddBankVerifyOptions] = useState(false);

  // this function check if dwolla customer exists against newspaper
  const checkIsDwollaCustomerExists = async () => {
    const activeOrg = await activeOrganization.data();
    if (activeOrg?.dwolla?.dwollaCustomer) {
      if (user?.data()?.dwollaPasswordSecurityEnabled)
        setAddBankVerifyOptions(true);
      else setShowPasswordResetModal(true);
    } else {
      // create new customer
      setShowDwollaCustomerModal(true);
    }
  };
  const updateValues = () => {
    setShowDwollaCustomerModal(false);
    setShowPasswordResetModal(true);
  };
  const addBankToDwolla = async () => {
    try {
      const dwollaResponse = await api.safePost(
        'payments/dwolla-funding-source',
        plaidData
      );
      if (dwollaResponse.error) {
        return { error: dwollaResponse.error };
      }
      if (fromRegistration && continueRegistration) {
        continueRegistration();
      }
      return {};
    } catch (err) {
      logAndCaptureException(
        ColumnService.PAYMENTS,
        err,
        'Failed to add bank to Dwolla',
        {
          accountId: activeOrganization.data().accountId
        }
      );
    }
  };

  return (
    <div>
      {!fromRegistration && (
        <div>
          <div className="flex items-center justify-center">
            <button
              className={`font-medium text-sm text-white rounded uppercase px-5 py-2 mt-2 bg-blue-600`}
              type="button"
              id="direct-deposit"
              onClick={() => {
                if (activeOrganization.data()?.dwollaPaymentEnabled) {
                  void checkIsDwollaCustomerExists();
                } else window.location.replace(STRIPE_VARS.oauthUrl);
              }}
            >
              Get paid via direct deposit
            </button>
          </div>
        </div>
      )}
      {openPlaid && (
        <Plaid
          user={user}
          setOpen={setOpenPlaid}
          setShowAuthorizationModal={setShowAuthorizationModal}
          setPlaidData={setPlaidData}
        />
      )}
      {showDwollaCustomerModal && (
        <DwollaCustomerModal
          showCustomerModal={() => setShowDwollaCustomerModal(false)}
          updateOnSuccess={() => updateValues()}
          organization={activeOrganization}
        />
      )}
      {showPasswordResetModal && (
        <DwollaPasswordResetModal
          userAuth={userAuth}
          passwordUpdateSuccess={() => {
            setShowPasswordResetModal(false);
            setAddBankVerifyOptions(true);
            dispatch(
              ToastActions.toastSuccess({
                headerText: 'Success',
                bodyText: 'Your password has been updated.'
              })
            );
          }}
          user={user as ESnapshotExists<EUser>}
          setDwollaPasswordModal={() => setShowPasswordResetModal(false)}
        />
      )}
      {showAuthorizationModal && (
        <TailwindModal
          header="ACH authorization agreement"
          close={() => setShowAuthorizationModal(false)}
          noExitOutsideModal
          widthPct={30}
        >
          <div className="mb-1 mt-2">
            <div className="bg-gray-300 bg-opacity-50">
              <div className="p-4 text-xs mb-1">
                I hereby authorize and request Column, PBC to debit funds from
                my account at the Financial Institution indicated, and credit
                the funds. I authorize Column, PBC to take any and all action
                required to correct any errors.
                <br />
                <br /> By clicking the button below, I certify that the
                information I have given on this ACH Debit Authorization
                Agreement for Direct Payments is complete, true, and submitted
                for the purpose selected above. It will remain in effect until I
                notify Column, PBC of its cancellation by contacting
                help@column.us.
              </div>
            </div>
            <div className="flex items-center mb-4 mt-3">
              <input
                id="agreeToTerms"
                type="checkbox"
                className="form-checkbox h-4 w-4 text-gray-600 transition duration-150 ease-in-out"
                checked
              />
              <div className="ml-2 block text-sm leading-4 text-gray-900">
                I agree to the authorization terms above.
              </div>
            </div>
            <button
              className={`rounded-md font-semibold bg-blue-500 bg-opacity-25 text-blue-600 text-sm flex items-center px-5 py-2`}
              id="confirm-authorization"
              type="button"
              disabled={loading}
              onClick={async () => {
                setLoading(true);
                let result;
                if (plaidData) {
                  result = await addBankToDwolla();
                }
                setLoading(false);
                setShowAuthorizationModal(false);

                if (result?.error) {
                  // TODO handle errors for plaid flow
                  if (plaidData) setShowAlreadyExistsModal(result.error);
                } else {
                  plaidData && updateToast && updateToast();
                }
              }}
            >
              <span className="flex">
                {loading && (
                  <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-5 w-5 mr-2" />
                )}
                Confirm
              </span>
            </button>
          </div>
        </TailwindModal>
      )}
      {showAlreadyExistsModal && (
        <TailwindModal
          header="Bank Account Already Exists"
          body={showAlreadyExistsModal}
          close={() => setShowAlreadyExistsModal('')}
          buttonText="Back to Settings"
          onButtonClick={() => setShowAlreadyExistsModal('')}
          noExitOutsideModal
        />
      )}
      {addBankVerifyOptions && (
        <AccountVerificationOptionsModal
          customerId={activeOrganization?.data()?.dwolla?.dwollaCustomer}
          closeModal={() => setAddBankVerifyOptions(false)}
          secureLoginHandleClick={() => {
            setAddBankVerifyOptions(false);
            setOpenPlaid(true);
          }}
          verifyAccountHandleClick={() => {
            setAddBankVerifyOptions(false);
          }}
          user={user?.data()}
        />
      )}
    </div>
  );
}

function PayoutsDownloadModal({ close }: { close: Function }) {
  const [loadingPayouts, setLoadingPayouts] = useState(false);
  const [period, setPeriod] = useState<
    'lastMonth' | 'currMonth' | 'currYear' | 'lastYear' | 'all'
  >('lastMonth');
  const [error, setError] = useState('');

  const downloadPayouts = async () => {
    setLoadingPayouts(true);
    setError('');
    try {
      const { response, error } = await api.safePost(
        `payments/download-payouts`,
        {
          period
        }
      );
      if (error) {
        setError(error);
        logAndCaptureException(
          ColumnService.PAYMENTS,
          error,
          'Failed to download payouts',
          {
            period
          }
        );
      } else if (response?.url) window.open(response.url);
      else setError('There are no relevant payouts in the given period');
    } catch (err) {
      setError('There was an error downloading your payouts.');
      logAndCaptureException(
        ColumnService.PAYMENTS,
        err,
        'Failed to download payouts',
        {
          period
        }
      );
    }

    setLoadingPayouts(false);
  };

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
        &#8203;
        <div
          className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div>
            <div className="mt-1 text-center">
              <h3
                className="text-lg leading-6 font-medium text-gray-900"
                id="modal-headline"
              >
                Download Payouts
              </h3>
              <div className="my-5">
                <div className="col-span-6 sm:col-span-3">
                  <div className="mb-2 text-left block text-sm font-medium leading-5 text-gray-700">
                    Download payouts from
                  </div>
                  <select
                    value={period}
                    onChange={e =>
                      setPeriod(
                        e.target.value as
                          | 'lastMonth'
                          | 'currMonth'
                          | 'currYear'
                          | 'lastYear'
                          | 'all'
                      )
                    }
                    id="country"
                    className="mt-1 block form-select w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                  >
                    <option value="currMonth">Current month</option>
                    <option value="lastMonth">Previous month</option>
                    <option value="currYear">Current year</option>
                    <option value="lastYear">Previous year</option>
                    <option value="all">Download all</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          {error && (
            <div className="my-5">
              <div className="text-red-400 text-sm">{error}</div>
            </div>
          )}

          <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense mb-2">
            <span className="flex w-full rounded-md shadow-sm sm:col-start-2">
              <button
                type="button"
                className={`text-white shadow inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-blue-600 text-base leading-6 font-medium shadow-sm hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5`}
                onClick={() => downloadPayouts()}
                disabled={loadingPayouts}
              >
                <span className="flex">
                  {loadingPayouts && (
                    <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-5 w-5 mr-2" />
                  )}
                  Download
                </span>
              </button>
            </span>
            <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:col-start-1">
              <button
                type="button"
                className="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                onClick={() => close()}
              >
                Cancel
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

type PaymentSettingsProps = {
  fromRegistration?: boolean;
  userAuth?: FirebaseUser;
};

// add a check about dwolla to render dwolla payouts
export default function PaymentSettings(props: PaymentSettingsProps) {
  const dispatch = useAppDispatch();
  const activeOrganization = useAppSelector(selectActiveOrganization);
  const user = useAppSelector(selectUser);
  const [selectedOption, setSelectedOption] = useState<'dwolla' | 'stripe'>(
    'stripe'
  );

  if (!exists(activeOrganization) || !exists(user)) {
    return <LoadingState />;
  }

  const handleOptionChange = (value: 'dwolla' | 'stripe') => {
    setSelectedOption(value);
  };

  const organizationData = activeOrganization.data();
  const hasDwollaCustomer = !!organizationData.dwolla?.dwollaCustomer;
  const hasDwollaFundingSource = !!organizationData.dwolla?.dwollaFundingSource;

  return (
    <div id="payment-settings-wrapper">
      {(organizationData.accountId === ENOTICE_ACCOUNT_ID ||
        organizationData.accountId === DUMMY_ACCOUNT_ID) &&
      !hasDwollaFundingSource ? (
        <CheckSettings
          activeOrganization={activeOrganization}
          updateToast={() => {
            dispatch(
              ToastActions.toastSuccess({
                headerText: 'Successfully verified!',
                bodyText:
                  'You can now get paid for your notices using this direct payment method.'
              })
            );
          }}
          userAuth={props.userAuth}
          user={user}
        />
      ) : hasDwollaCustomer && !hasDwollaFundingSource ? (
        <>
          <div className="mt-4 w-full inline-block bg-white border border-column-gray-100 shadow-column-3 mb-4 rounded-lg p-4">
            <div className="flex">
              <RadioButtonGroup
                id="payment-settings-options"
                labelText="Choose your payout view:"
                value={selectedOption}
                onChange={handleOptionChange}
                options={[
                  { labelText: 'Dwolla Payouts', value: 'dwolla' },
                  { labelText: 'Stripe Payouts', value: 'stripe' }
                ]}
              />
            </div>
          </div>
          {selectedOption === 'dwolla' ? (
            <DwollaSettings
              activeOrganization={activeOrganization}
              user={user}
            />
          ) : (
            <StripePayouts />
          )}
        </>
      ) : hasDwollaFundingSource ? (
        <DwollaSettings activeOrganization={activeOrganization} user={user} />
      ) : (
        <StripePayouts />
      )}
    </div>
  );
}
