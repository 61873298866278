import * as Sentry from '@sentry/browser';
import { removeUndefinedFields } from 'lib/helpers';
import moment from 'moment';
import { logError, logInfo, logWarn } from 'utils/logger';
import { ColumnService } from 'lib/services/directory';
import { ColumnError } from 'lib/errors/ColumnErrors';
import { FirebaseTimestamp } from '../lib/types';

export const removeDuplicatesFromArray = (array: any[]) => {
  return [...new Set(array)];
};

export const guidGenerator = () => {
  const S4 = function () {
    return ((1 + Math.random()) * 0x10000 || 0).toString(16).substring(1);
  };
  return `${S4() + S4()}-${S4()}-${S4()}-${S4()}-${S4()}${S4()}${S4()}`;
};

export const truncateString = (s: string, maxLength = 25) =>
  s.length > maxLength ? `${s.slice(0, maxLength)}...` : s;

// vaidate if the object is of Error type or not
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error
const isError = (err: any) => {
  return Object.prototype.toString.call(err) === '[object Error]';
};

const isInUnitTests = () => {
  // See:
  // https://jestjs.io/docs/environment-variables
  return (
    process &&
    process.env &&
    process.env.NODE_ENV === 'test' &&
    !!process.env.JEST_WORKER_ID
  );
};

const logAndCaptureErrorHelper = (
  err: any,
  msg: string | undefined,
  tags: Record<string, string | undefined> = {},
  isCriticalError = false
) => {
  if (isInUnitTests()) {
    return;
  }

  const formattedTags = { ...tags };

  if (ColumnError.isColumnError(err) && err.alertServiceOverride) {
    formattedTags.service = err.alertServiceOverride;
  }
  if (isCriticalError) {
    formattedTags.severity = 'critical';
  }

  const formattedMsg = (msg || '').replaceAll('\n', ' ');
  removeUndefinedFields(formattedTags);

  if (formattedMsg) {
    logError(formattedMsg, formattedTags, err);
  }

  for (const k of Object.keys(formattedTags)) {
    Sentry.setTag(k, formattedTags[k] ?? '');
  }

  if (isError(err)) {
    const errorWithMsg = formattedMsg
      ? new Error(formattedMsg, { cause: err })
      : err;
    Sentry.captureException(errorWithMsg);
  } else {
    const errorWithMsg = formattedMsg
      ? new Error(`${formattedMsg} ${JSON.stringify(err)}`)
      : new Error(JSON.stringify(err));
    Sentry.captureException(errorWithMsg);
  }
};

export const logAndCaptureException = (
  service: ColumnService,
  err: any,
  msg: string,
  tags: Record<string, string | undefined> = {}
) => {
  return logAndCaptureErrorHelper(err, msg, { ...tags, service });
};

/**
 * This function is used in SetupSaga on the front-end to enable lib to use logInfo
 */
export const logLibInfo = (
  msg: string,
  tags: Record<string, string | undefined> = {}
) => {
  if (isInUnitTests()) {
    return;
  }

  logInfo(msg, tags);
};

/**
 * This function is used in SetupSaga on the front-end to enable lib to use logWarn
 */
export const logLibWarn = (
  msg: string,
  tags: Record<string, string | undefined> = {}
) => {
  if (isInUnitTests()) {
    return;
  }

  logWarn(msg, tags);
};

export const logAndCaptureMessage = (
  msg: string,
  tags: Record<string, string | undefined> = {}
) => {
  if (isInUnitTests()) {
    return;
  }

  logWarn(msg, tags);

  for (const k of Object.keys(tags)) {
    Sentry.setTag(k, tags[k] ?? '');
  }
  Sentry.captureMessage(msg);
};

export const logAndCaptureCriticalError = (
  service: ColumnService,
  err: any,
  msg?: string | undefined,
  tags: Record<string, string> = {}
) => {
  logAndCaptureErrorHelper(
    err,
    msg,
    {
      ...tags,
      service
    },
    true
  );
};

export const calculateTimeDifferenceFromTimestamp = (
  time: FirebaseTimestamp | undefined
) => {
  if (!time) return;
  const createdDate = moment(time.toDate());
  return moment(createdDate).fromNow();
};
