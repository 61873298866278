import React from 'react';
import classNames from 'classnames';
import { InputAccessories } from '../InputAccessories';
import CheckboxInput, { CheckboxInputProps } from './CheckboxInput';
import WithTooltip from './WithTooltip';

type RadioButtonGroupOption<T extends string> = Pick<
  CheckboxInputProps,
  'disabled' | 'labelText' | 'labelDescription'
> & {
  tooltipText?: string;
  value: T;
};

type RadioButtonGroupProps<T extends string> = {
  id: string;
  labelText: string;
  options: RadioButtonGroupOption<T>[];
  value: T;
  onChange: (change: T) => void;
  disabled?: boolean;
  required?: boolean;
  stacked?: boolean;
};

export default function RadioButtonGroup<T extends string = string>({
  id,
  labelText,
  options,
  value,
  onChange,
  disabled,
  required,
  stacked = false
}: RadioButtonGroupProps<T>) {
  return (
    <InputAccessories
      id={id}
      labelText={labelText}
      disabled={disabled}
      required={required}
    >
      {/* TODO: Update to a fieldset and legend rather than a label via InputAccessories */}
      <div
        className={classNames('grid gap-4', {
          'grid-cols-1': stacked,
          'md:grid-cols-2': !stacked
        })}
      >
        {options.map(
          ({ tooltipText, value: checkboxValue, ...checkBoxProps }, idx) => (
            <WithTooltip key={`${id}-${idx}`} tooltipText={tooltipText}>
              <CheckboxInput
                id={`${id}-${idx}`}
                {...checkBoxProps}
                checked={value === checkboxValue}
                onChange={checked => {
                  if (checked) {
                    onChange(checkboxValue);
                  }
                }}
                type="radio"
              />
            </WithTooltip>
          )
        )}
      </div>
    </InputAccessories>
  );
}
