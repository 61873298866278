import { useBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { useEffect, useState, useReducer } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { selectIsPublisher } from 'redux/auth';
import {
  EOrganization,
  ESnapshotExists,
  Customer,
  MailDelivery,
  exists,
  EUser,
  ENoticeDraft
} from 'lib/types';
import { State as States, CourtHouse as CourtHousesList } from 'lib/enums';
import { findMatchingNoticeType } from 'lib/publishers';
import {
  ConfirmAffidavitRecipientsData,
  confirmAffidavitRecipients,
  selectCurrentStepId,
  selectIsEditing,
  selectNoticeType,
  selectPreviousNoticeType
} from 'redux/placement';
import { MailingAddress } from 'lib/types/organization';
import { columnObjectsAreEqual } from 'lib/utils/stringify';
import { getNoticeTypeFromNoticeData } from 'lib/helpers';
import { CONFIRM_PROOF } from '../helpers/calculatePlacementSteps';
import { verifyAddressDeliverability } from '../helpers/verifyAddressDeliverability';
import {
  reducer as affidavitRecipientReducer,
  actions as affidavitRecipientActions,
  initialState,
  DEFAULT_ERROR,
  DEFAULT_MAILING,
  DEFAULT_COURTHOUSE,
  selectIsFormComplete
} from './affidavitRecipient.slice';

const {
  setSendAffidavitByMail,
  setCourthouseRecipients,
  setSendAffidavitToCourthouse,
  setMailingRecipients,
  setSendAffidavitByEmail,
  setRecipientValidationErrors,
  validateCourthouseRecipients,
  setShowErrors,
  validateMailingRecipients
} = affidavitRecipientActions;

export function useAffidavitRecipientsState({
  stepId,
  publisherOrganization,
  filer,
  draft
}: {
  stepId: string;
  publisherOrganization: ESnapshotExists<EOrganization> | undefined;
  filer: ESnapshotExists<EUser> | undefined;
  draft: ESnapshotExists<ENoticeDraft>;
}) {
  const reduxDispatch = useAppDispatch();
  const disableAddressVerification = useBooleanFlag(
    LaunchDarklyFlags.DISABLE_ADDRESS_VERIFICATION,
    false
  );
  const [state, dispatch] = useReducer(affidavitRecipientReducer, initialState);
  const {
    sendAffidavitByEmail,
    sendAffidavitByMail,
    mailingRecipients,
    recipientValidationErrors,
    sendAffidavitToCourthouse,
    courthouseRecipients,
    courthouseValidationErrors
  } = state;
  const isPublisher = useAppSelector(selectIsPublisher);
  const editing = useAppSelector(selectIsEditing);
  const activeStepId = useAppSelector(selectCurrentStepId);
  const noticeType = useAppSelector(selectNoticeType);
  const previousNoticeType = useAppSelector(selectPreviousNoticeType);
  const [customer, setCustomer] = useState<ESnapshotExists<Customer> | null>(
    null
  );
  const [sendEmailCheckEdited, setSendEmailCheckEdited] = useState(false);

  const defaultCourthouse =
    exists(publisherOrganization) && publisherOrganization.data().courthouseId
      ? CourtHousesList.find(
          c => c.id === publisherOrganization.data().courthouseId
        )
      : undefined;

  const placementNoticeType = useAppSelector(
    state => state.placement.noticeType
  );
  const placementMail = useAppSelector(state => state.placement.mail);
  const placementCustomer = useAppSelector(state => state.placement.customer);
  const placementRequireEmailAffidavit = useAppSelector(
    state => state.placement.requireEmailAffidavit
  );
  const publisherOrganizationState = publisherOrganization?.data().state;

  // TODO: These settings combinations are a little hard to follow — is there a way we could make them more clear?
  const mailAffidavitsOutsideColumn =
    !!publisherOrganization?.data().mailAffidavitsOutsideColumn;

  const publicationRequiresPhysicalAffidavit =
    !!publisherOrganization?.data().physicalAffidavit;
  const publicationRequiresWetSignature =
    publisherOrganization?.data().affidavitReconciliationSettings
      ?.notarizationVendor === 'manual';
  const customerRequiresWetSignature =
    customer?.data().affidavitReconciliationSettings?.notarizationVendor ===
    'manual';
  const customNoticeType = getNoticeTypeFromNoticeData(
    {
      noticeType,
      previousNoticeType
    },
    publisherOrganization,
    {
      skipDisplayType: true
    }
  );

  // TODO: Differentiate between false & undefined in settings cascade
  const customNoticeTypeRequiresWetSignature =
    customNoticeType?.affidavitReconciliationSettings?.notarizationVendor ===
    'manual';
  const noticeWillRequireWetSignature =
    customerRequiresWetSignature ||
    publicationRequiresWetSignature ||
    customNoticeTypeRequiresWetSignature;
  const customerMustReceiveMailedAffidavit =
    publicationRequiresPhysicalAffidavit || noticeWillRequireWetSignature;

  const hideEmailAffidavitOption =
    customerMustReceiveMailedAffidavit ||
    !!publisherOrganization?.data().hideDigitalAffidavits;

  const doesNotRequireEmailAffidavit = !(
    isPublisher ||
    !hideEmailAffidavitOption ||
    !!customer?.data().allowAffidavitEmail
  );

  // TODO: Move validation handling to TextField validation system
  useEffect(() => {
    dispatch(validateMailingRecipients());
  }, [mailingRecipients]);

  // TODO: Move validation handling to TextField validation system
  useEffect(() => {
    dispatch(validateCourthouseRecipients());
  }, [courthouseRecipients]);

  // TODO: Can this recipients calculation be moved to a Redux action?
  const loadAffidavitsRecipients = () => {
    let mailAddresses: MailDelivery[] = [];

    if (activeStepId === CONFIRM_PROOF || editing) {
      if (placementMail) {
        mailAddresses = placementMail;
      }
    } else {
      const savedAddresses = loadFromSaved({ filer, editing });
      const placementAddresses = loadPlacementMail({ placementMail });
      const newspaperAddresses = loadNewspaperMail({
        publisherOrganization,
        placementNoticeType
      });

      const customerAddress = isPublisher
        ? loadCustomerMail({ customer })
        : loadFilerMail({ filer });

      if (customerAddress && sendAffidavitByMail) {
        mailAddresses = [customerAddress];
      } else if (newspaperAddresses.length) {
        mailAddresses = newspaperAddresses;
      } else if (placementAddresses.length) {
        mailAddresses = placementAddresses;
      } else {
        mailAddresses = savedAddresses;
      }
    }

    if (customerMustReceiveMailedAffidavit) {
      dispatch(setSendAffidavitByMail(true));
    }

    const courthouseAddresses = mailAddresses.filter(item => item.isCourthouse);
    if (courthouseAddresses.length) {
      dispatch(setCourthouseRecipients(courthouseAddresses));
      dispatch(setSendAffidavitToCourthouse(true));
    }

    mailAddresses = mailAddresses.filter(item => !item.isCourthouse);
    const provider = noticeWillRequireWetSignature ? 'manual' : 'lob';
    if (mailAddresses.length) {
      dispatch(
        setMailingRecipients(
          mailAddresses.map(mailDelivery => ({ ...mailDelivery, provider }))
        )
      );
      dispatch(setSendAffidavitByMail(true));
    } else {
      dispatch(setMailingRecipients([{ ...DEFAULT_MAILING, provider }]));
    }
  };

  useEffect(() => {
    if (sendAffidavitByMail) {
      loadAffidavitsRecipients();
    }
  }, [sendAffidavitByMail]);

  // TODO: Can/should this state be part of PlaceNoticeState
  useEffect(() => {
    // We rely on the customer from placement to avoid creating a duplicate customer
    // when the publisherOrganization and filer are set
    if (!placementCustomer?.id) {
      setCustomer(null);
      return;
    }

    void (async () => {
      const customerSnap = await placementCustomer?.get();
      if (exists(customerSnap)) {
        setCustomer(customerSnap);
      } else {
        setCustomer(null);
      }
    })();
  }, [placementCustomer?.id]);

  // TODO: Can this be moved to a reducer?
  useEffect(() => {
    if (editing && !sendEmailCheckEdited) {
      dispatch(setSendAffidavitByEmail(placementRequireEmailAffidavit));
      setSendEmailCheckEdited(true);
    }
  }, [placementRequireEmailAffidavit]);

  // TODO: Can this be moved to a reducer?
  useEffect(() => {
    if (sendAffidavitToCourthouse && defaultCourthouse) {
      dispatch(
        setCourthouseRecipients(
          courthouseRecipients.map(c =>
            c.name === ''
              ? {
                  ...c,
                  courtHouse: defaultCourthouse.id,
                  name: defaultCourthouse.name,
                  address: {
                    address_line1: defaultCourthouse.address,
                    address_line2: '',
                    address_city: defaultCourthouse.city,
                    address_state: publisherOrganizationState || '',
                    state: States.kansas.value,
                    address_zip: defaultCourthouse.address.slice(
                      defaultCourthouse.address.length - 5
                    )
                  },
                  isCourthouse: true,
                  copies: 1
                }
              : c
          )
        )
      );
    }
  }, [sendAffidavitToCourthouse]);

  // TODO: Can this be moved to a reducer?
  useEffect(() => {
    if (!sendAffidavitByMail) {
      dispatch(setRecipientValidationErrors(DEFAULT_ERROR));
    }
  }, [activeStepId]);

  // Unfortunately, it's important that this useEffect happens after
  // the one above it (setting courthouse defaults). So don't move this!
  useEffect(() => {
    dispatch(setSendAffidavitByMail(false));
    loadAffidavitsRecipients();
  }, [filer?.id, publisherOrganization?.id, placementNoticeType]);

  // TODO: Can this be moved to an action?
  const validateAffidavitForm = async () => {
    const data: ConfirmAffidavitRecipientsData = {
      mailAffidavitsOutsideColumn,
      requireEmailAffidavit: doesNotRequireEmailAffidavit
        ? false
        : sendAffidavitByEmail
    };

    if (sendAffidavitByMail && recipientValidationErrors.some(e => !!e)) {
      dispatch(setShowErrors(true));
      return { success: false, data };
    }

    if (
      sendAffidavitToCourthouse &&
      courthouseValidationErrors.some(e => !!e)
    ) {
      dispatch(setShowErrors(true));
      return { success: false, data };
    }

    const isMail = !columnObjectsAreEqual(mailingRecipients, [DEFAULT_MAILING]);
    const isCourtHouse = !columnObjectsAreEqual(courthouseRecipients, [
      DEFAULT_COURTHOUSE
    ]);

    const newMail: MailDelivery[] = [];
    if (isMail) {
      newMail.push(...mailingRecipients);
    }
    if (isCourtHouse) {
      newMail.push(...courthouseRecipients);
    }

    data.mail = newMail.length > 0 ? newMail : null;

    if (sendAffidavitByMail) {
      const addressVerifications = await Promise.all(
        mailingRecipients.map(mailingAddress => {
          if (disableAddressVerification) {
            return true;
          }
          return verifyAddressDeliverability({ mailingAddress, draft });
        })
      );
      const hasUndeliverableAddress = addressVerifications.some(
        deliverable => !deliverable
      );

      if (hasUndeliverableAddress) {
        const newError = [...DEFAULT_ERROR];
        addressVerifications.forEach((deliverable, index) => {
          if (!deliverable) {
            newError[index] =
              'The address you’ve entered is invalid. Please input a new address to ensure the affidavit will be delivered.';
          }
        });

        dispatch(setRecipientValidationErrors([...newError]));
        dispatch(setShowErrors(true));
        return { success: false, data };
      }
    }

    return await reduxDispatch(confirmAffidavitRecipients(data));
  };

  const complete = selectIsFormComplete(state, activeStepId, stepId);

  return {
    state,
    dispatch,
    validateAffidavitForm,
    complete,
    doesNotRequireEmailAffidavit,
    hideEmailAffidavitOption,
    customerMustReceiveMailedAffidavit
  };
}

const loadFromSaved = ({
  filer,
  editing
}: {
  filer: ESnapshotExists<EUser> | undefined;
  editing: boolean;
}): MailingAddress[] => {
  if (!exists(filer)) return [];
  if (!editing) return [];

  const { savedInfo } = filer.data();
  const savedMailAddresses = savedInfo?.mails;
  if (savedMailAddresses?.length) {
    return savedMailAddresses.map(savedMailAddress => ({
      description: '',
      ...savedMailAddress
    }));
  }
  return [];
};

const loadPlacementMail = ({
  placementMail
}: {
  placementMail: MailDelivery[] | null;
}): MailingAddress[] => {
  if (!placementMail?.length) return [];
  const nonDefaultAddresses = placementMail.filter(
    item => !item.isNoticeTypeDefault
  );
  if (nonDefaultAddresses.length) {
    return nonDefaultAddresses;
  }
  return [];
};

const loadFilerMail = ({
  filer
}: {
  filer: ESnapshotExists<EUser> | undefined;
}): MailingAddress | null => {
  if (!filer) return null;

  const { firstName, lastName, address, addressLine2, zipCode, city, state } =
    filer.data();

  const name = firstName && lastName ? `${firstName} ${lastName}` : '';

  return {
    name,
    address: {
      address_line1: address || '',
      address_line2: addressLine2 || '',
      address_zip: zipCode || '',
      address_city: city || '',
      address_state: state || ''
    },
    description: '',
    isCourthouse: false,
    copies: 1
  };
};

const loadCustomerMail = ({
  customer
}: {
  customer: ESnapshotExists<Customer> | null;
}): MailingAddress | null => {
  if (!customer) return null;

  const { firstName, lastName, address, addressLine2, zipCode, city, state } =
    customer.data();

  const name = firstName && lastName ? `${firstName} ${lastName}` : '';

  return {
    name,
    address: {
      address_line1: address || '',
      address_line2: addressLine2 || '',
      address_zip: zipCode || '',
      address_city: city || '',
      address_state: state || ''
    },
    description: '',
    isCourthouse: false,
    copies: 1
  };
};

const loadNewspaperMail = ({
  publisherOrganization,
  placementNoticeType
}: {
  publisherOrganization: ESnapshotExists<EOrganization> | undefined;
  placementNoticeType: number;
}) => {
  if (exists(publisherOrganization)) {
    const noticeType = findMatchingNoticeType(
      publisherOrganization,
      placementNoticeType
    );

    let newspaperAddresses: MailingAddress[];
    if (noticeType?.defaultMailingAddresses) {
      newspaperAddresses = noticeType.defaultMailingAddresses.map(mail => ({
        ...mail,
        address: {
          ...mail.address,
          address_state:
            typeof mail.address.address_state === 'string'
              ? States.by_label(mail.address.address_state)!.value
              : mail.address.address_state
        },
        isNoticeTypeDefault: true
      }));
    } else {
      newspaperAddresses = [];
    }

    return newspaperAddresses;
  }
  return [];
};
