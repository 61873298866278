import { z } from 'zod';
import { Product } from '../enums';
import { PublishingMedium } from '../enums/PublishingMedium';

export enum PublishTrigger {
  // Publish once first publishing date has passed
  FIRST_PUBLISHING_DATE = 'first-publishing-date',

  // Publish once the product is confirmed (and verified if applicable e.g. obituaries)
  CONFIRMATION = 'confirmation'
}

const AdditionalLinkSchema = z.object({
  name: z.string(),
  description: z.string(),
  link: z.string().url(),
  backgroundImageStoragePath: z.string()
});
export type AdditionalLink = z.infer<typeof AdditionalLinkSchema>;

export type ExpirationSetting =
  | {
      method: 'last-publication-date';
      days?: number;
    }
  | {
      method: 'relative';
      days: number;
    };

export type ProductSiteSetting = {
  product: Product;

  publishingMediums?: PublishingMedium[];

  expirationSetting?: ExpirationSetting;

  // When to show an ad on the display site
  publishTrigger: PublishTrigger;

  // Subdomain of the site.  Must be globally unique.
  subdomainName: string;

  // Disables the display site from loading
  disabled: boolean;

  // Enables a custom GA token for publisher tracking and analytics
  gaToken?: string;

  // Additional links to display on the display site
  additionalLinks?: AdditionalLink[];
};

export type ProductSiteSettingUpdateRequest = {
  newspaperId: string;
  productSiteSetting: Partial<ProductSiteSetting>;
};

export type ProductSiteSettingCreateRequest = {
  newspaperId: string;
  productSiteSetting: ProductSiteSetting;
};

export type ProductSiteSettingCreateResponse = {
  productSiteSettingId: string;
};
