import React, { useState } from 'react';
import { getFirebaseContext } from 'utils/firebase';
import { useAppDispatch } from 'redux/hooks';
import {
  CustomerOrganization,
  EOrganization,
  ERate,
  ESnapshot,
  ESnapshotExists,
  exists
} from 'lib/types';
import { columnObjectsAreEqual } from 'lib/utils/stringify';
import { removeUndefinedFields } from 'lib/helpers';
import ExitConfirmationModal from 'components/modals/ExitWithoutSavingModal';
import { logInfo } from 'utils/logger';
import { CustomerOrganizationTableData } from 'routes/settings/publisher/customers/customerOrganizationTable/utils';
import ToastActions from 'redux/toast';
import { apiPost } from 'api/typed';
import { UpdateSettingRequestTypes } from 'lib/types/updateSettingsRequest';
import { ColumnManagedAffidavitOverridesCard } from 'routes/settings/publisher/affidavits/overrideControls';
import { getModelFromSnapshot } from 'lib/model';
import { CustomerOrganizationModel } from 'lib/model/objects/customerOrganizationModel';
import HorizontalDivider from '../HorizontalDivider';
import CreateOrEditModal from './CreateOrEditModal';
import {
  CreateOrEditModalDetails,
  CustomerObjDataFields
} from './CreateOrEditModalDetails';
import { EditCustomerOrganizationModalSettings } from './EditCustomerOrganizationModalSettings';

type EditCustomerOrganizationModalProps = {
  customerOrganizationData: CustomerOrganizationTableData;
  activeOrganization: ESnapshot<EOrganization> | null;
  onClose: () => void;
  setCustomerOrganization?: (
    customerOrganization: Partial<CustomerOrganization>
  ) => void;
  rates: ESnapshotExists<ERate>[];
};

export default function EditCustomerOrganizationModal({
  customerOrganizationData,
  activeOrganization,
  onClose,
  setCustomerOrganization,
  rates
}: EditCustomerOrganizationModalProps) {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);

  /**
   * Intiailize fields from customerOrg.
   */
  const customerOrgSnap = customerOrganizationData.customerOrganization;
  const customerOrg = customerOrgSnap.data();
  const linerRateSnap =
    rates?.find(rate => rate.id === customerOrg?.defaultLinerRate?.id) ||
    rates?.find(
      rate => rate.id === activeOrganization?.data()?.defaultLinerRate?.id
    );

  const displayRateSnap =
    rates?.find(rate => rate.id === customerOrg?.defaultDisplayRate?.id) ||
    rates?.find(
      rate => rate.id === activeOrganization?.data()?.defaultDisplayRate?.id
    );
  const initialCustomerOrgData: CustomerObjDataFields = {
    ...customerOrg,
    linerRateSnap,
    displayRateSnap
  };
  const [updatedCustomerData, setUpdatedCustomerData] =
    useState<CustomerObjDataFields>(initialCustomerOrgData);
  const [showExitWithoutSavingModal, setShowExitWithoutSavingModal] =
    useState<boolean>();

  const disableSave = columnObjectsAreEqual(
    updatedCustomerData,
    initialCustomerOrgData
  );

  const onSave = async () => {
    setLoading(true);

    const linerRateIsPublisherDefault =
      updatedCustomerData.linerRateSnap?.id ===
      activeOrganization?.data()?.defaultLinerRate?.id;
    const displayRateIsPublisherDefault =
      updatedCustomerData.displayRateSnap?.id ===
      activeOrganization?.data()?.defaultDisplayRate?.id;

    const updates: Partial<CustomerOrganization> = {
      name: updatedCustomerData.name?.trim(),
      internalID: updatedCustomerData.internalID,
      phone: updatedCustomerData.phone,
      address: updatedCustomerData.address,
      addressLine2: updatedCustomerData.addressLine2,
      city: updatedCustomerData.city,
      state:
        typeof updatedCustomerData.state === 'number'
          ? updatedCustomerData.state
          : undefined,
      zipCode: updatedCustomerData.zipCode,
      defaultLinerRate: linerRateIsPublisherDefault
        ? (getFirebaseContext().fieldValue().delete() as any)
        : updatedCustomerData.linerRateSnap?.ref,
      defaultDisplayRate: displayRateIsPublisherDefault
        ? (getFirebaseContext().fieldValue().delete() as any)
        : updatedCustomerData.displayRateSnap?.ref,
      bulkPaymentEnabled_v2: updatedCustomerData.bulkPaymentEnabled_v2,
      billingTerm: updatedCustomerData.billingTerm,
      enableAffidavitsBeforePayment:
        updatedCustomerData.enableAffidavitsBeforePayment,
      discountConfig:
        updatedCustomerData.discountConfig?.subtotal?.amount === 0
          ? {}
          : updatedCustomerData.discountConfig
    };
    removeUndefinedFields(updates);

    const { affidavitReconciliationSettings } = updatedCustomerData;

    if (!exists(customerOrgSnap)) {
      logInfo('Attempted to access non-existing customer organization');
      return;
    }
    const customerOrganizationModel = getModelFromSnapshot(
      CustomerOrganizationModel,
      getFirebaseContext(),
      customerOrgSnap
    );

    if (affidavitReconciliationSettings) {
      await customerOrganizationModel.updateAutomatedAffidavitConfiguration(
        affidavitReconciliationSettings
      );
    }

    await customerOrganizationData.customerOrganization.ref.update(updates);
    if (
      updatedCustomerData.bulkPaymentEnabled_v2 !==
      initialCustomerOrgData.bulkPaymentEnabled_v2
    ) {
      const requestType = updatedCustomerData.bulkPaymentEnabled_v2
        ? UpdateSettingRequestTypes.ENABLE_BULK_INVOICE
        : UpdateSettingRequestTypes.DISABLE_BULK_INVOICE;
      await apiPost('customers/verify-billing-request-to-send-notification', {
        customerOrganizationId:
          customerOrganizationData.customerOrganization.id,
        requestType
      });
    }
    const toastText = `Your changes to ${updatedCustomerData.name?.trim()}'s details have been updated.`;
    dispatch(
      ToastActions.toastSuccess({
        headerText: 'Changes successfully saved',
        bodyText: toastText
      })
    );
    if (setCustomerOrganization) {
      setCustomerOrganization(updates);
    }
    setLoading(false);
    onClose();
  };

  const formId = 'edit-customer-org-modal-form';

  return (
    <CreateOrEditModal
      headerText="Edit"
      buttonText="Save"
      onClose={onClose}
      isCustomerOrganization
      loading={loading}
      onSubmit={onSave}
      disableSave={disableSave}
      id={formId}
    >
      <div className="px-3 py-6 bg-gray-80 overflow-scroll">
        <CreateOrEditModalDetails
          value={updatedCustomerData}
          onChange={data => {
            setUpdatedCustomerData(data);
          }}
          activeOrganization={activeOrganization}
          isCustomerOrganization
        />
        <div className="py-6 max-w-4xl m-auto">
          <HorizontalDivider />
        </div>
        <EditCustomerOrganizationModalSettings
          value={updatedCustomerData}
          onChange={setUpdatedCustomerData}
          activeOrganization={activeOrganization}
          clientOrganization={customerOrganizationData.clientOrganization}
          rates={rates}
        />
        <div className="py-6 max-w-4xl m-auto">
          <HorizontalDivider />
        </div>
        <ColumnManagedAffidavitOverridesCard
          label="customer"
          value={updatedCustomerData.affidavitReconciliationSettings}
          onChange={affidavitReconciliationSettings =>
            setUpdatedCustomerData({
              ...updatedCustomerData,
              affidavitReconciliationSettings
            })
          }
        />
      </div>
      {showExitWithoutSavingModal && (
        <ExitConfirmationModal
          keepEditing={() => setShowExitWithoutSavingModal(false)}
          exitWithoutSaving={() => {
            setShowExitWithoutSavingModal(false);
            onClose();
          }}
          body={
            'Your progress will be lost if you exit now. Click keep editing to go back to save your changes instead.'
          }
          disableOverflow
        />
      )}
    </CreateOrEditModal>
  );
}
