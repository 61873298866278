import { Product } from 'lib/enums';

export const MAIN_ROUTES = {
  ADD_ORGANIZATION: '/add-organization',
  AFFIDAVITS: '/affidavits',
  CLASSIFIED: '/classified',
  CLASSIFIEDS: '/classifieds',
  PLACEMENTS: '/placements',
  ERROR: '/error',
  FILE: '/file',
  FORGOT_PASSWORD: '/forgot-password',
  FORM: '/form',
  IMPERSONATE: '/impersonate',
  INVITES: '/invites',
  INVOICES: '/invoices',
  LOGIN: '/login',
  LOGOUT: '/logout',
  MADLIB: '/madlib',
  NOTICE: '/notice',
  NOTICES: '/notices',
  OBITUARIES: '/obituaries',
  OBITUARY: '/obituary',
  PAGINATION: '/pagination',
  PAYMENTS: '/payments',
  PLACE_NOTICE: '/place',
  PUBLIC_NOTICE: '/public-notice',
  PUBLISH: '/publish',
  REGISTER: '/register',
  REPORTS: '/reports',
  RESET_PASSWORD: '/reset-password',
  SEARCH: '/search',
  SETTINGS: '/settings',
  STRIPE_CONNECT: '/stripe-connect',
  SUBSCRIPTIONS: '/subscriptions',
  UPDATE_EMAIL: '/update-email',
  VERIFY: '/verify'
} as const;

export const CLASSIFIED_ROUTES = {
  DETAIL: `${MAIN_ROUTES.CLASSIFIED}/:id`,
  DUPLICATE: `${MAIN_ROUTES.CLASSIFIED}/:id/duplicate`,
  EDIT: `${MAIN_ROUTES.CLASSIFIEDS}/edit/:orderId`,
  PLACE: `${MAIN_ROUTES.CLASSIFIEDS}/place/:id?`
} as const;

export const ERROR_ROUTE = `${MAIN_ROUTES.ERROR}/:code` as const;

const FILE_BY_NOTICE_ROUTE = `${MAIN_ROUTES.FILE}/:id` as const;
const FILE_BY_TYPE_ROUTE = `${MAIN_ROUTES.FILE}/by-type` as const;
export const FILE_ROUTES = {
  BY_NOTICE: FILE_BY_NOTICE_ROUTE,
  BY_NOTICE_WITH_SLUG: `${FILE_BY_NOTICE_ROUTE}/:noticeSlug`,
  BY_TYPE: FILE_BY_TYPE_ROUTE,
  BY_TYPE_WITH_SLUG: `${FILE_BY_TYPE_ROUTE}/:type`
} as const;

export const FORM_ROUTES = {
  NOTICE_TYPE: `${MAIN_ROUTES.FORM}/:noticeId/:noticeType`,
  NEWSPAPER: `${MAIN_ROUTES.FORM}/newspaper`,
  NEWSPAPER_WITH_NOTICE: `${MAIN_ROUTES.FORM}/newspaper/:noticeId`
} as const;

export const INVITE_ROUTE = `${MAIN_ROUTES.INVITES}/:id` as const;

export const INVOICE_ROUTE = `${MAIN_ROUTES.INVOICES}/:id` as const;
export const INVOICE_ROUTES = {
  PAY: `${INVOICE_ROUTE}/pay`
} as const;

const NOTICE_ROUTE = `${MAIN_ROUTES.NOTICE}/:id` as const;
export const NOTICE_ROUTES = {
  DETAIL: NOTICE_ROUTE,
  EDIT: `${MAIN_ROUTES.PLACE_NOTICE}/:id`,
  INVOICE_CREATE: `${NOTICE_ROUTE}/invoice/create`,
  PLACE: `${MAIN_ROUTES.PLACE_NOTICE}/:id?`
} as const;

export const OBITUARY_ROUTES = {
  DETAIL: `${MAIN_ROUTES.OBITUARY}/:id`,
  DUPLICATE: `${MAIN_ROUTES.OBITUARY}/:id/duplicate`,
  EDIT: `${MAIN_ROUTES.OBITUARIES}/edit/:orderId`,
  PLACE: `${MAIN_ROUTES.OBITUARIES}/place/:id?`
} as const;

export const ORGANIZATION_SETTINGS_ROUTE =
  `${MAIN_ROUTES.SETTINGS}/organization` as const;
export const ORGANIZATION_SETTINGS_ROUTES = {
  AFFIDAVITS: `${ORGANIZATION_SETTINGS_ROUTE}/affidavits`,
  BANK_ACCOUNTS: `${ORGANIZATION_SETTINGS_ROUTE}/bank-accounts`,
  BILLING: `${ORGANIZATION_SETTINGS_ROUTE}/billing`,
  BRANDING: `${ORGANIZATION_SETTINGS_ROUTE}/branding`,
  CLASSIFIEDS: `${ORGANIZATION_SETTINGS_ROUTE}/classifieds`,
  CUSTOMERS: `${ORGANIZATION_SETTINGS_ROUTE}/customers`,
  DEADLINES: `${ORGANIZATION_SETTINGS_ROUTE}/deadlines`,
  DESIGN_AND_LAYOUT: `${ORGANIZATION_SETTINGS_ROUTE}/design-and-layout`,
  FTP: `${ORGANIZATION_SETTINGS_ROUTE}/ftp`,
  GENERAL: `${ORGANIZATION_SETTINGS_ROUTE}/general`,
  MEMBERS: `${ORGANIZATION_SETTINGS_ROUTE}/members`,
  NOTICE_TYPES: `${ORGANIZATION_SETTINGS_ROUTE}/notice-types`,
  OBITUARIES: `${ORGANIZATION_SETTINGS_ROUTE}/obituaries`,
  PAYOUTS: `${ORGANIZATION_SETTINGS_ROUTE}/payouts`,
  PUBLISHER_SETTINGS: `${ORGANIZATION_SETTINGS_ROUTE}/publisher-settings`,
  RATES: `${ORGANIZATION_SETTINGS_ROUTE}/rates`
} as const;

export const PUBLIC_NOTICE_ROUTE = `${MAIN_ROUTES.PUBLIC_NOTICE}/:id` as const;

const PUBLISH_NOTICE_ROUTE = `${MAIN_ROUTES.PUBLISH}/:id` as const;
export const PUBLISH_NOTICE_ROUTES = {
  DETAIL: PUBLISH_NOTICE_ROUTE,
  INVOICE_CREATE: `${PUBLISH_NOTICE_ROUTE}/invoice/create`
} as const;

export const REGISTER_ROUTES = {
  CONFIRM: `${MAIN_ROUTES.REGISTER}/confirm`,
  INDIVIDUAL: `${MAIN_ROUTES.REGISTER}/individual`,
  OCCUPATIONS: `${MAIN_ROUTES.REGISTER}/occupations`,
  ORGANIZATION: `${MAIN_ROUTES.REGISTER}/organization`,
  PUBLISHER: `${MAIN_ROUTES.REGISTER}/publisher`
} as const;

export const REGISTER_ORGANIZATION_ROUTES = {
  POST_REGISTRATION: `${REGISTER_ROUTES.ORGANIZATION}/post-registration`
} as const;

export const PRODUCT_TO_ROUTE = {
  [Product.Classified]: MAIN_ROUTES.CLASSIFIEDS,
  [Product.Obituary]: MAIN_ROUTES.OBITUARIES,
  [Product.Notice]: MAIN_ROUTES.NOTICES
} as const;

export const PRODUCT_TO_ROUTES = {
  [Product.Classified]: CLASSIFIED_ROUTES,
  [Product.Obituary]: OBITUARY_ROUTES,
  [Product.Notice]: NOTICE_ROUTES
} as const;

export const DOCS_ROUTES = {
  GITBOOK: '/docs/internal'
} as const;
