import { safeGetModelArrayFromQuery } from '../model/getModel';
import { InvoiceModel } from '../model/objects/invoiceModel';
import { EFirebaseContext, ERef } from '../types';
import { OrderInvoice } from '../types/invoices';
import { Order } from '../types/order';
import { ResponseOrError, wrapError, wrapSuccess } from '../types/responses';
import { getErrorReporter } from '../utils/errors';
import { ColumnService } from './directory';

export class InvoiceService {
  constructor(private context: EFirebaseContext) {}

  public async getByOrderAndVersion(
    orderRef: ERef<Order>,
    version: number
  ): Promise<ResponseOrError<InvoiceModel<OrderInvoice> | null>> {
    const invoiceQuery = this.context
      .invoicesRef<OrderInvoice>()
      .where('order', '==', orderRef)
      .where('orderVersion', '==', version);

    const { response: invoicesForOrder, error: invoiceError } =
      await safeGetModelArrayFromQuery(
        InvoiceModel,
        this.context,
        invoiceQuery
      );
    if (invoiceError) {
      getErrorReporter().logAndCaptureError(
        ColumnService.ORDER_MANAGEMENT,
        invoiceError,
        'Failed to get invoice from order',
        {
          orderId: orderRef.id,
          version: `${version}`
        }
      );
      return wrapError(invoiceError);
    }

    /**
     * Our linter does not like passing in a narrowed type (InvoiceModel<OrderInvoice>)
     * into `safeGetModelArrayFromQuery` above, this cast is necessary to get an
     * array that is properly typed as InvoiceModel<OrderInvoice>[]
     */
    const activeInvoices = (invoicesForOrder as InvoiceModel<OrderInvoice>[])
      /**
       * If an invoice is voided, it can no longer be considered a source of truth about the order
       * or active for any order purposes; therefore, when calling the .getInvoice() method on an order model,
       * we should not return a voided invoice.
       */
      .filter(invoice => !invoice.isVoided());
    if (activeInvoices.length > 1) {
      const errorMessage =
        'Expected no more than 1 invoice for order and version';
      const invoiceIds = activeInvoices.map(invoice => invoice.id).join(', ');
      const error = Error(errorMessage);

      getErrorReporter().logAndCaptureError(
        ColumnService.PAYMENTS,
        error,
        errorMessage,
        {
          orderId: orderRef.id,
          version: `${version}`,
          invoiceIds
        }
      );

      return wrapError(error);
    }

    return wrapSuccess(activeInvoices[0] ?? null);
  }
}
