// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Dispatch, SetStateAction, useMemo } from 'react';
import { getWordsFromNumber } from 'lib/helpers';
import { EOrganization, ESnapshotExists } from 'lib/types';
import { getColumnRangeConfigForPublisher } from 'lib/notice/columns';
import { capitalize } from 'lodash';
import { ColumnSelect, ColumnSelectOption } from 'lib/components/ColumnSelect';
import { TextField } from 'lib/components/TextField';

type AffinityXSyncInputsProps = {
  approxHeight: number | null;
  maxHeight: number | null;
  setApproxHeight: Dispatch<SetStateAction<number | null>>;
  setHeightIsValid: Dispatch<SetStateAction<boolean>>;
  numColumns: number | null;
  setNumColumns: Dispatch<SetStateAction<number | null>>;
  pageCount: number | null;
  setPageCount: Dispatch<SetStateAction<number | null>>;
  newspaperSnap: ESnapshotExists<EOrganization>;
  disableInputs: boolean;
};

function AffinityXSyncInputs({
  approxHeight,
  maxHeight,
  setApproxHeight,
  setHeightIsValid,
  numColumns,
  setNumColumns,
  pageCount,
  setPageCount,
  newspaperSnap,
  disableInputs
}: AffinityXSyncInputsProps) {
  // TODO: maybe combine with logic used in placement flow?
  const getColumnOptionLabel = (numOfColumns: number) => {
    if (numOfColumns === 1) return 'One column';
    return `${capitalize(getWordsFromNumber(numOfColumns))} columns`;
  };
  const getColumnWidthOptions = (): ColumnSelectOption<string>[] => {
    const { minColumns, maxColumns } = getColumnRangeConfigForPublisher(
      newspaperSnap,
      /* isDisplay = */ true
    );
    const options: ColumnSelectOption<string>[] = [];
    for (let i = minColumns; i <= maxColumns; i++) {
      options.push({
        value: `${i}`,
        label: getColumnOptionLabel(i)
      });
    }

    return options;
  };
  const columnWidthOptions = useMemo(getColumnWidthOptions, [newspaperSnap.id]);

  return (
    <>
      <div className="grid grid-cols-1 gap-3 mb-4 w-full">
        <ColumnSelect
          id="affinity-x-column-select"
          labelText="Number of Columns"
          options={columnWidthOptions}
          value={String(numColumns || '')}
          allowUndefined
          onChange={newNumColumns => setNumColumns(parseInt(newNumColumns, 10))}
          disabled={disableInputs}
        />
        <TextField
          id="affinity-x-height-input"
          labelText="Height (inches)"
          type="number"
          step="0.001"
          min="0.000"
          max={String(maxHeight || '')}
          value={String(approxHeight || '')}
          onChange={newHeight => setApproxHeight(parseFloat(newHeight))}
          setValidityTracker={setHeightIsValid}
          disabled={disableInputs}
        />
        <TextField
          id="affinity-x-page-select"
          labelText="Page Count"
          type="number"
          step="1"
          min="1"
          max="50"
          value={String(pageCount || '')}
          onChange={newPageCount => setPageCount(parseInt(newPageCount, 10))}
          disabled={disableInputs}
        />
      </div>
    </>
  );
}

export default AffinityXSyncInputs;
