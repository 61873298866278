import { InviteStatus, RoleType } from '../enums';
import { getOpenInvitesAssociatedWithEmail } from '../helpers';
import {
  ESnapshotExists,
  EOrganization,
  EUser,
  EFirebaseContext,
  EInvite,
  ESnapshot,
  exists,
  ERef
} from '../types';

export const OPEN_INVITE_STATUSES = [
  InviteStatus.pending.value,
  InviteStatus.snoozed.value
];
export const CLOSED_INVITE_STATUSES = [
  InviteStatus.declined.value,
  InviteStatus.accepted.value
];

export const getAllowedOrganizationSnaps = async (
  user: ESnapshotExists<EUser>
) => {
  const { allowedOrganizations } = user.data();
  if (!allowedOrganizations || allowedOrganizations.length === 0) {
    return [];
  }

  return await Promise.all(
    allowedOrganizations.map(async org => {
      // Just in case there's broken refs out there, we're not using getOrThrow for now
      return (await org.get()) as ESnapshotExists<EOrganization>;
    })
  );
};

export const getRolesFromAllowedOrgs = (
  allowedOrganizations: ERef<EOrganization>[],
  role: number
): Record<string, number> => {
  return allowedOrganizations.reduce(
    (acc, org) => ({ ...acc, [org.id]: role || RoleType.admin.value }),
    {}
  );
};

/**
 * Filter for only open organization invites
 */
export const getOpenOrgInvitesForUser = async (
  ctx: EFirebaseContext,
  user: ESnapshotExists<EUser>
) => {
  return (
    await getOpenInvitesAssociatedWithEmail(ctx, user.data().email)
  ).filter(invite => !!invite.data().organizationId);
};
/**
 * Filter open organization invites for only pending invites
 */
export const getPendingOrgInvitesForUser = async (
  ctx: EFirebaseContext,
  user: ESnapshotExists<EUser>
) => {
  return (await getOpenOrgInvitesForUser(ctx, user)).filter(
    invite => invite.data().status === InviteStatus.pending.value
  );
};

/**
 * Open invite statuses: pending, snoozed
 * Closed invite statuses: declined, accepted
 */
export const inviteIsOpen = (invite: ESnapshot<EInvite> | undefined | null) => {
  return exists(invite) && OPEN_INVITE_STATUSES.includes(invite.data().status);
};

export const getAdminUsersOfOrganization = async (
  organizationSnap: ESnapshotExists<EOrganization>,
  ctx: EFirebaseContext
) => {
  const membersSnap = await ctx
    .usersRef()
    .where('allowedOrganizations', 'array-contains', organizationSnap.ref)
    .get();

  if (!membersSnap.docs.length) return;
  const adminMembers = membersSnap.docs.filter(
    member =>
      exists(member) &&
      member.data()?.roles![organizationSnap.id] === RoleType.admin.value
  );
  return adminMembers;
};
