import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import AuthActions, { selectIsUserLoggedOut } from 'redux/auth';
import { isAnonymousFlow } from '../helpers';

export function usePlacementAnonymousLogin() {
  const dispatch = useAppDispatch();
  const [startedFromAnonymousFlow, setStartedFromAnonymousFlow] =
    useState(false);
  const isLoggedOut = useAppSelector(selectIsUserLoggedOut);
  const isAnonymousLogin = isAnonymousFlow();
  useEffect(() => {
    // If no user is logged in, create an anonymous login
    if (isLoggedOut) {
      dispatch(AuthActions.anonymousLogin());
    }

    // Track if the user started out as an anonymous login so we can adjust the UI conditionally
    if (!isLoggedOut && isAnonymousLogin) {
      setStartedFromAnonymousFlow(true);
    }
  }, [isLoggedOut, isAnonymousLogin]);

  return { startedFromAnonymousFlow };
}
