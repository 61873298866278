import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { ORGANIZATION_SETTINGS_ROUTE } from 'router/routes';
import { selectActiveOrganization } from 'redux/auth';
import { logAndCaptureException } from 'utils';
import { getLocationParams } from 'lib/frontend/utils/browser';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import LoadingState from 'components/LoadingState';
import ToastActions from 'redux/toast';
import { exists } from 'lib/types';
import { ColumnService } from 'lib/services/directory';
import { getDynamicRoute } from 'lib/frontend/utils/router';
import api from 'api';

export default function StripeConnect() {
  const activeOrganization = useAppSelector(selectActiveOrganization);
  const oauthCode = getLocationParams().get('code');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const setOAuthCode = async () => {
      if (!exists(activeOrganization) || !oauthCode) return;

      try {
        const result = await api.safePost('payments/update-connected-account', {
          code: oauthCode,
          organizationId: activeOrganization.id
        });
        if (result.response) {
          dispatch(
            ToastActions.toastSuccess({
              headerText: 'Stripe account linked successfully',
              bodyText:
                'All payouts to this publication will be sent to the linked account.'
            })
          );
        } else {
          dispatch(
            ToastActions.toastError({
              headerText: 'Failed to link Stripe account',
              bodyText: `${result.error}. Please try again in a few minutes or contact support.`
            })
          );
          throw new Error(result.error);
        }
        navigate(
          getDynamicRoute(
            ORGANIZATION_SETTINGS_ROUTE,
            {},
            { tab: 'payment-settings' }
          )
        );
      } catch (err) {
        logAndCaptureException(
          ColumnService.PAYMENTS,
          err,
          'Failed to link Stripe account',
          {
            activeOrganization: activeOrganization?.id
          }
        );
      }
    };
    void setOAuthCode();
  }, [oauthCode, activeOrganization?.id]);
  return <LoadingState />;
}
