import React, { useState } from 'react';

import { exists } from 'lib/types';
import { State } from 'lib/enums';
import LoadingState from 'components/LoadingState';
import TabGroup, { TabOption } from 'lib/components/Tabs';
import SettingsPage from 'routes/settings/SettingsPage';
import {
  selectActiveOrganization,
  selectAvailableOrganizations
} from 'redux/auth';
import { useAppSelector } from 'redux/hooks';
import Archive from './Archive';
import Upload from './Upload';
import { ExternalUpload } from './ExternalUpload';

const UPLOAD_TAB: TabOption = {
  label: 'Upload',
  id: 'upload',
  enabled: true
};

const ARCHIVE_TAB: TabOption = {
  label: 'Archive',
  id: 'archive',
  enabled: true
};

function FTPSettings() {
  const activeOrganization = useAppSelector(selectActiveOrganization);
  const availableOrganizations = useAppSelector(selectAvailableOrganizations);
  const [activeTab, setActiveTab] = useState<TabOption>(UPLOAD_TAB);
  if (!exists(activeOrganization)) {
    return <LoadingState />;
  }

  const uploadFormat = State.by_value(
    activeOrganization.data().state
  )?.showUploads;

  return (
    <SettingsPage>
      <TabGroup
        onClickTab={setActiveTab}
        activeTab={activeTab}
        tabs={[UPLOAD_TAB, ARCHIVE_TAB]}
        id="statewide-site-uploads-tabs"
      ></TabGroup>
      {activeTab.id === UPLOAD_TAB.id &&
        (!uploadFormat || uploadFormat === 'INTERNAL' ? (
          <Upload
            activeOrganization={activeOrganization}
            availableOrganizations={availableOrganizations}
          />
        ) : (
          <ExternalUpload uploadFormat={uploadFormat} />
        ))}
      {activeTab.id === ARCHIVE_TAB.id && <Archive />}
    </SettingsPage>
  );
}

export default FTPSettings;
