import { isDefined } from 'lib/helpers';
import { ColorSettingVisibility } from 'lib/types/newspaperOrder';
import { OrderRate } from 'lib/types/rates';

export const getColorSettingsVisibility = function (
  rate: OrderRate
): ColorSettingVisibility {
  return {
    showColorToggle: isDefined(rate.colorFees?.flatFee),
    showBackgroundColor: isDefined(rate.colorFees?.backgroundColorFee),
    showBorderColor: isDefined(rate.colorFees?.borderColorFee),
    showTextColor: isDefined(rate.colorFees?.textColorFee)
  };
};

export const getShouldForceGrayscale = (rate: OrderRate): boolean => {
  // If colorFees isn't defined, they just haven't set up color fees on their rate.
  // If colorFees is defined but there's no defined flat fee, they've set up the rate, and chosen not to allow color.
  return isDefined(rate.colorFees) && !isDefined(rate.colorFees.flatFee);
};
