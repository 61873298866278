import { ORGANIZATION_SETTINGS_ROUTES } from 'router/routes';

enum LegacySettingsTabs {
  ADVERTISING_DEADLINES = 'advertising-deadlines',
  AFFIDAVIT_SETTINGS = 'affidavit-settings',
  BANK_ACCOUNTS = 'bank-accounts',
  BILLING = 'billing',
  CLASSIFIEDS = 'classifieds',
  CUSTOM_BRAND = 'custom-brand',
  CUSTOMERS = 'customers',
  DESIGN_AND_LAYOUT = 'design-and-layout',
  FTP = 'ftp',
  GENERAL_INFO = 'general-info',
  GENERAL_SETTING = 'general-setting',
  MEMBERS = 'members',
  MEMBER_SETTINGS = 'member-settings',
  NOTICE_TYPES = 'notice-types',
  OBITUARIES = 'obituaries',
  PAYMENT_SETTINGS = 'payment-settings',
  PRICING_SETTINGS = 'pricing-settings',
  PUBLISHER_SETTINGS = 'publisher-settings'
}
export const LEGACY_TAB_TO_ROUTE_MAP: Record<
  string,
  (typeof ORGANIZATION_SETTINGS_ROUTES)[keyof typeof ORGANIZATION_SETTINGS_ROUTES]
> = {
  [LegacySettingsTabs.ADVERTISING_DEADLINES]:
    ORGANIZATION_SETTINGS_ROUTES.DEADLINES,
  [LegacySettingsTabs.AFFIDAVIT_SETTINGS]:
    ORGANIZATION_SETTINGS_ROUTES.AFFIDAVITS,
  [LegacySettingsTabs.BANK_ACCOUNTS]:
    ORGANIZATION_SETTINGS_ROUTES.BANK_ACCOUNTS,
  [LegacySettingsTabs.BILLING]: ORGANIZATION_SETTINGS_ROUTES.BILLING,
  [LegacySettingsTabs.CLASSIFIEDS]: ORGANIZATION_SETTINGS_ROUTES.CLASSIFIEDS,
  [LegacySettingsTabs.CUSTOM_BRAND]: ORGANIZATION_SETTINGS_ROUTES.BRANDING,
  [LegacySettingsTabs.CUSTOMERS]: ORGANIZATION_SETTINGS_ROUTES.CUSTOMERS,
  [LegacySettingsTabs.DESIGN_AND_LAYOUT]:
    ORGANIZATION_SETTINGS_ROUTES.DESIGN_AND_LAYOUT,
  [LegacySettingsTabs.FTP]: ORGANIZATION_SETTINGS_ROUTES.FTP,
  [LegacySettingsTabs.GENERAL_INFO]: ORGANIZATION_SETTINGS_ROUTES.GENERAL,
  [LegacySettingsTabs.GENERAL_SETTING]: ORGANIZATION_SETTINGS_ROUTES.GENERAL,
  [LegacySettingsTabs.MEMBERS]: ORGANIZATION_SETTINGS_ROUTES.MEMBERS,
  [LegacySettingsTabs.MEMBER_SETTINGS]: ORGANIZATION_SETTINGS_ROUTES.MEMBERS,
  [LegacySettingsTabs.NOTICE_TYPES]: ORGANIZATION_SETTINGS_ROUTES.NOTICE_TYPES,
  [LegacySettingsTabs.OBITUARIES]: ORGANIZATION_SETTINGS_ROUTES.OBITUARIES,
  [LegacySettingsTabs.PAYMENT_SETTINGS]: ORGANIZATION_SETTINGS_ROUTES.PAYOUTS,
  [LegacySettingsTabs.PRICING_SETTINGS]: ORGANIZATION_SETTINGS_ROUTES.RATES,
  [LegacySettingsTabs.PUBLISHER_SETTINGS]:
    ORGANIZATION_SETTINGS_ROUTES.PUBLISHER_SETTINGS
};
