import { exists } from 'lib/types';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import {
  selectActiveOrganization,
  selectDefaultOrdersRoute,
  selectUserAuth,
  selectUser
} from 'redux/auth';
import { useAppSelector } from 'redux/hooks';
import { MAIN_ROUTES, REGISTER_ROUTES } from 'router/routes';
import { OrganizationType } from 'lib/enums';
import { getUserNeedsToBeRedirectedToRegistration } from 'lib/utils/users';
import { getFirebaseContext } from 'utils/firebase';

const REDIRECT_KEY = 'redirect';

export const useRedirectToLogin = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();

  const redirectToLogin = (): void => {
    if (pathname !== MAIN_ROUTES.LOGOUT && pathname !== '/') {
      searchParams.set(REDIRECT_KEY, pathname);
      navigate(`${MAIN_ROUTES.LOGIN}?${searchParams.toString()}`);
    } else {
      navigate(MAIN_ROUTES.LOGIN);
    }
  };

  return redirectToLogin;
};

export const useRedirectToLanding = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const auth = useAppSelector(selectUserAuth);
  const user = useAppSelector(selectUser);
  const activeOrganization = useAppSelector(selectActiveOrganization);
  const defaultOrdersPage = useAppSelector(selectDefaultOrdersRoute);

  const redirectToLanding = (): void => {
    if (!auth || !exists(user)) {
      return;
    }

    const redirectPath = searchParams.get(REDIRECT_KEY);
    if (redirectPath) {
      return navigate(redirectPath);
    }

    if (!exists(activeOrganization)) {
      return navigate(MAIN_ROUTES.NOTICES);
    }

    const { organizationType } = activeOrganization.data();

    if (
      organizationType === OrganizationType.holding_company.value &&
      activeOrganization.data().reportUrl
    ) {
      return navigate(MAIN_ROUTES.REPORTS);
    }

    if (organizationType === OrganizationType.funeral_home.value) {
      return navigate(MAIN_ROUTES.OBITUARIES);
    }

    return navigate(defaultOrdersPage);
  };

  return redirectToLanding;
};

export const useMaybeRedirectToRegistration = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const auth = useAppSelector(selectUserAuth);
  const user = useAppSelector(selectUser);

  const maybeRedirectToRegistration = async (): Promise<boolean> => {
    if (!auth || !exists(user)) {
      return false;
    }

    const needsRegistration = await getUserNeedsToBeRedirectedToRegistration({
      ctx: getFirebaseContext(),
      pathname,
      user
    });

    if (needsRegistration) {
      navigate(REGISTER_ROUTES.OCCUPATIONS);
    }

    return needsRegistration;
  };

  return maybeRedirectToRegistration;
};
