import { MediaModel } from 'lib/model/objects/mediaModel';
import MediaGrid from 'routes/settings/publisher/generalOrderSettings/ClipArtSettings/ClipArtLibrary/MediaGrid';
import { getReactImageStylesFromCrop, OrderImage } from 'lib/orders/images';
import { ArrowsPointingOutIcon, TrashIcon } from '@heroicons/react/24/outline';
import { FileWithUploadRef } from 'lib/frontend/hooks/useFirebaseStorageUpload';
import React, { useState } from 'react';
import { LoadingSpinner } from 'lib/components/LoadingSpinner';
import { MimeTypes } from 'lib/types/mime';
import { cdnIfy } from 'lib/helpers';
import { Layout } from 'lib/types/layout';
import FileUpload from 'lib/components/FileUpload';
import { Modal } from 'lib/components/Modal';
import Firebase from 'EnoticeFirebase';
import { getBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import ImageEditorModal from './ImageEditorModal';
import PinturaImageEditorModal from './PinturaImageEditorModal';

const storage = Firebase.storage();

type ImageOrUploaderProps = {
  onUpdateOrderImage: (orderImage: OrderImage) => void;
  onDeleteOrderImage: () => void;
  orderImage: OrderImage | null;
  mediaList: MediaModel[] | null;
  layout: Layout;
  disabled?: boolean;
};

export default function ImageEditorOrUploader({
  onUpdateOrderImage,
  onDeleteOrderImage,
  orderImage,
  mediaList,
  layout,
  disabled
}: ImageOrUploaderProps) {
  const [showImageEditModal, setShowImageEditModal] = useState(false);
  const [showMediaLibraryModal, setShowMediaLibraryModal] = useState(false);

  const uploadFolder = `documentcloud/ads/${new Date().getTime()}`;

  // Indicates the image is loading-the uploader has its own state to indicate that something is being uploaded.
  const [loading, setLoading] = useState(false);

  const handleFileUpload = async (file: FileWithUploadRef) => {
    setLoading(true);

    const useColumnCDN = getBooleanFlag(LaunchDarklyFlags.ENABLE_COLUMN_CDN);
    const imageUrl = cdnIfy(file.uploadRef.fullPath, {
      cloudinaryTransformations: 'q_auto:best',
      /**
       * Browsers generally do not support rendering TIFF files, but we allow uploading them, so we need to convert the file to JPG.
       * Cloudinary does not support TIFF files and returns `Image file format tiff not allowed` when trying to convert a tiff to jpg
       * Therefore, we use the Imgix transformation to convert the file to jpg instead.
       * Column CDN should support TIFF to JPG in the future.
       */
      imgixTransformations: { fm: 'jpg' },
      useColumnCDN
    });

    onUpdateOrderImage({
      sourcePath: file.uploadRef.fullPath,
      imageUrl
    });
    setLoading(false);
    setShowImageEditModal(true);
  };

  const buttonText = mediaList?.length ? (
    <span className="text-sm">
      Upload image <span className="text-grey-400">or</span>{' '}
      <span
        role="button"
        tabIndex={0}
        onClick={e => {
          e.stopPropagation();
          setShowMediaLibraryModal(true);
        }}
      >
        Choose from gallery
      </span>
    </span>
  ) : (
    ''
  );

  const ImageModalComponent = getBooleanFlag(
    LaunchDarklyFlags.ENABLE_ADVANCED_IMAGE_EDITING
  )
    ? PinturaImageEditorModal
    : ImageEditorModal;

  return (
    <div className="relative flex items-center justify-center w-full h-full">
      {showImageEditModal && orderImage && (
        <ImageModalComponent
          onUpdateOrderImage={onUpdateOrderImage}
          orderImage={orderImage}
          onClose={() => setShowImageEditModal(false)}
          layout={layout}
          disabled={disabled}
          uploadFolder={uploadFolder}
        />
      )}

      {showMediaLibraryModal && mediaList && (
        <Modal
          id="media-library"
          onClose={() => setShowMediaLibraryModal(false)}
          title="Choose from Gallery"
          size="3xl"
        >
          <MediaGrid
            mediaList={mediaList}
            onClick={media => {
              onUpdateOrderImage({
                sourcePath: media.modelData.filePath,
                imageUrl: media.modelData.cdnUrl
              });
              setShowMediaLibraryModal(false);
              setShowImageEditModal(true);
            }}
          />
        </Modal>
      )}

      {loading && <LoadingSpinner />}

      {!loading && !orderImage?.imageUrl && (
        <FileUpload
          id="uploader"
          buttonText={buttonText}
          multiple={false}
          onFileUpload={file => {
            void handleFileUpload(file[0]);
          }}
          uploadFolder={uploadFolder}
          storage={storage}
          acceptFileTypes={[
            MimeTypes.IMG_PNG,
            MimeTypes.IMG_JPG,
            MimeTypes.IMG_TIFF
          ]}
          disabled={disabled}
          required
        />
      )}

      {!loading && orderImage?.imageUrl && (
        <>
          <img
            src={orderImage.imageUrl}
            style={
              {
                ...getReactImageStylesFromCrop(orderImage.crop)
              } as React.CSSProperties
            }
          />
          <div className="absolute top-0 right-0 p-1 m-1 flex gap-1">
            {/* Load the image cropping modal */}
            <div
              className="p-1 rounded-md bg-column-primary-500"
              role="button"
              tabIndex={0}
              onClick={() => setShowImageEditModal(true)}
            >
              <ArrowsPointingOutIcon className="h-4 w-4 text-white" />
            </div>
            {/* Re-upload the image file */}
            <div
              className="p-1 rounded-md bg-column-primary-500"
              role="button"
              tabIndex={0}
              onClick={() => {
                if (disabled) return;
                onDeleteOrderImage();
              }}
            >
              <TrashIcon className="h-4 w-4 text-white" />
            </div>
          </div>
        </>
      )}
    </div>
  );
}
