import React, { useState } from 'react';

import { EOrganization, ESnapshotExists } from 'lib/types';
import { columnObjectsAreEqual } from 'lib/utils/stringify';
import { removeUndefinedFields } from 'lib/helpers';
import FullScreenModal from 'components/FullScreenModal';
import { TextField } from 'lib/components/TextField';
import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';
import { getFirebaseContext } from 'utils/firebase';
import {
  PUBLISHER_GLOBAL_RATE_SETTINGS_UPDATED,
  PublisherGlobalRateSettingsUpdated
} from 'lib/types/events';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { selectUser } from 'redux/auth';
import CheckboxGroup, {
  CheckboxGroupOption
} from 'lib/components/Checkbox/CheckboxGroup';
import { Product } from 'lib/enums';
import { logAndCaptureException } from 'utils';
import { ColumnService } from 'lib/services/directory';
import ToastActions from 'redux/toast';
import { selectOrganizationProductConfig } from 'layouts/appLayout/selectOrganizationProductConfig';
import { useBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';

type GlobalRateSettingsType = Pick<
  EOrganization,
  'taxPct' | 'productTaxSettings'
>;

const getGlobalRateSettingsFromActiveOrganization = (
  activeOrganization: ESnapshotExists<EOrganization>
): GlobalRateSettingsType => {
  const { taxPct, productTaxSettings } = activeOrganization.data();
  return {
    taxPct,
    productTaxSettings
  };
};

type GlobalRateSettingsProps = {
  activeOrganization: ESnapshotExists<EOrganization>;
  onClose: () => void;
};

export default function GlobalRateSettings({
  activeOrganization,
  onClose
}: GlobalRateSettingsProps) {
  const dispatch = useAppDispatch();
  const enableProductTaxSettings = useBooleanFlag(
    LaunchDarklyFlags.ENABLE_PRODUCT_TAX_SETTINGS,
    false
  );
  const user = useAppSelector(selectUser);
  const [globalRateSettings, setGlobalRateSettings] = useState(
    getGlobalRateSettingsFromActiveOrganization(activeOrganization)
  );
  const edited = !columnObjectsAreEqual(
    globalRateSettings,
    getGlobalRateSettingsFromActiveOrganization(activeOrganization)
  );
  const { taxPct = 0, productTaxSettings } = globalRateSettings;
  const organizationProductConfig = useAppSelector(
    selectOrganizationProductConfig
  );
  const productOptions: CheckboxGroupOption<Product>[] =
    organizationProductConfig
      .filter(item => item.enabled)
      .map(item => ({
        id: item.id,
        labelText: item.label,
        checked: Boolean(
          item.id === Product.Notice ||
            (productTaxSettings && productTaxSettings[item.id])
        ),
        disabled: item.id === Product.Notice
      }));

  return (
    <FullScreenModal
      id="edit-global-rate-settings-form"
      headerText="Edit Settings"
      onClose={onClose}
      submittable={{
        buttonText: 'Save',
        disabled: !edited,
        onSubmit: async () => {
          try {
            if (!user) {
              throw new Error(
                'Cannot save global rate settings without a user'
              );
            }

            await getFirebaseContext()
              .eventsRef<PublisherGlobalRateSettingsUpdated>()
              .add(
                removeUndefinedFields({
                  type: PUBLISHER_GLOBAL_RATE_SETTINGS_UPDATED,
                  publisher: activeOrganization.ref,
                  createdAt: getFirebaseContext().timestamp(),
                  data: {
                    before:
                      getGlobalRateSettingsFromActiveOrganization(
                        activeOrganization
                      ),
                    after: globalRateSettings,
                    changedBy: user.ref
                  }
                })
              );

            await activeOrganization.ref.update(
              removeUndefinedFields({
                taxPct,
                productTaxSettings
              })
            );

            onClose();
          } catch (e) {
            logAndCaptureException(
              ColumnService.SETTINGS_MANAGEMENT,
              e,
              'Error saving global rate settings',
              {
                organizationId: activeOrganization.id
              }
            );
            dispatch(
              ToastActions.toastError({
                headerText: 'Error saving global rate settings',
                bodyText:
                  'An error occurred while saving the global rate settings. Please try again.'
              })
            );
          }
        }
      }}
    >
      <CardGridLayout
        header={{
          title: 'Global Rate Settings',
          description: `Configure settings that apply by default to all rates for ${
            activeOrganization.data().name
          }.`
        }}
      >
        <GridInput fullWidth>
          <TextField
            type="number"
            value={taxPct ? taxPct.toString() : ''}
            onChange={newValue =>
              setGlobalRateSettings({
                ...globalRateSettings,
                taxPct: parseFloat(newValue)
              })
            }
            placeholder="0.000"
            step={0.001}
            max={25}
            min={0}
            labelText="What tax will Column apply by default to rates?"
            id="tax-amount"
          />
        </GridInput>
        {enableProductTaxSettings && (
          <GridInput fullWidth>
            <CheckboxGroup
              id="product-tax-settings"
              labelText="Apply tax to these products:"
              value={productOptions}
              onChange={newValues => {
                const updatedProductTaxSettings = {} as Record<
                  Product,
                  boolean
                >;

                Object.values(Product).forEach(product => {
                  updatedProductTaxSettings[product] = false;
                });

                newValues.forEach(({ id, checked }) => {
                  updatedProductTaxSettings[id] = checked;
                });

                setGlobalRateSettings({
                  ...globalRateSettings,
                  productTaxSettings: updatedProductTaxSettings
                });
              }}
              stacked
            />
          </GridInput>
        )}
      </CardGridLayout>
    </FullScreenModal>
  );
}
