import React from 'react';
import { PlusCircleIcon } from '@heroicons/react/24/outline';

import { ColumnButton } from 'lib/components/ColumnButton';
import { Tooltip } from 'lib/components/Tooltip';
import { useNavigate } from 'react-router-dom';
import { MAIN_ROUTES } from 'router/routes';

export default function AddCustomerOrganizationNavLink() {
  const navigate = useNavigate();
  return (
    <div data-testid="create-organization" className="px-7">
      <Tooltip
        helpText="Create or join an organization to share notices, invoices, and affidavits with your team."
        position="bottom"
        customPosition="w-80 text-center"
      >
        <ColumnButton
          id="create-organization"
          buttonText="New organization"
          startIcon={
            <PlusCircleIcon className="w-5 h-5 stroke-2 text-column-gray-400" />
          }
          tertiary
          onClick={() => navigate(MAIN_ROUTES.ADD_ORGANIZATION)}
          type="button"
        />
      </Tooltip>
    </div>
  );
}
