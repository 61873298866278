import { GridInput } from 'lib/components/Card/Grid';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { ProductPublishingSettingModel } from 'lib/model/objects/productPublishingSettingModel';
import { PackagePublisherSettings, ESnapshotExists, AdRate } from 'lib/types';

import EditPublicationDateSettingsForm from '../../filingTypes/filingTypeUpdateForm/EditPublicationDateSettingsForm';

export type PackagePublisherSettingsCardProps = {
  index: number;
  value: PackagePublisherSettings;
  onChange: (packagePublisherSettings: PackagePublisherSettings) => void;
  availableRates: ESnapshotExists<AdRate>[];
  availableProductPublishingSettings: ProductPublishingSettingModel[];
};

export function PackagePublisherSettingsCard({
  index,
  value,
  onChange,
  availableRates,
  availableProductPublishingSettings
}: PackagePublisherSettingsCardProps) {
  return (
    <>
      <GridInput fullWidth>
        <ColumnSelect
          id={`${index}-product-publishing-setting`}
          labelText="Publishing medium"
          options={availableProductPublishingSettings.map(setting => ({
            label:
              setting.modelData.customLabel ||
              setting.modelData.publishingMedium,
            value: setting.id
          }))}
          value={value.productPublishingSetting?.id ?? ''}
          onChange={productPublishingSettingId => {
            const selectedProductPublishingSetting =
              availableProductPublishingSettings.find(
                setting => setting.id === productPublishingSettingId
              );
            if (!selectedProductPublishingSetting) {
              return;
            }
            onChange({
              ...value,
              productPublishingSetting: selectedProductPublishingSetting.ref
            });
          }}
          required
        />
      </GridInput>
      <GridInput fullWidth>
        <ColumnSelect
          id={`${index}-default-rate`}
          labelText="Default rate"
          noteText="The rate to use when this package is selected. If not set, pricing will default to the rate settings on the filing type."
          options={availableRates.map(rate => ({
            label: rate.data().description,
            value: rate.id
          }))}
          value={value.defaultRate?.id ?? ''}
          onChange={rateId =>
            onChange({
              ...value,
              defaultRate:
                availableRates.find(rate => rate.id === rateId)?.ref ?? null
            })
          }
          allowUndefined
        />
      </GridInput>
      <EditPublicationDateSettingsForm
        id={`${index}-publication-date-settings`}
        publicationDateSettings={value.publicationDateSettings}
        onChange={updatedPublicationDateSettings => {
          onChange({
            ...value,
            publicationDateSettings: updatedPublicationDateSettings
          });
        }}
      />
    </>
  );
}
