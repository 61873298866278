import React from 'react';

export default function JoinOrCreateNewOrganizationHeader() {
  return (
    <div className="mt-8 text-center">
      <p className="text-column-gray-500 font-semibold leading-8 text-2xl">
        Join or create an organization
      </p>
      <p className="mt-4 text-column-gray-400 font-medium text-sm leading-6">
        Is your team currently on Column? Find and request to join them or
        create a new organization.
      </p>
    </div>
  );
}
