import React from 'react';
import { useNavigate } from 'react-router-dom';

import { selectActiveOrganization, selectUser } from 'redux/auth';
import AdvertisersInvitesForm from 'routes/invites/AdvertisersInvitesForm';
import { useAppSelector } from 'redux/hooks';
import { getRedirect } from 'utils/urls';
import LoadingState from 'components/LoadingState';
import { exists } from 'lib/types';
import { getDynamicRoute } from 'lib/frontend/utils/router';

export default function PostOrgRegistration() {
  const navigate = useNavigate();
  const userSnap = useAppSelector(selectUser);
  const activeOrganization = useAppSelector(selectActiveOrganization);

  const onInviteCompleted = async () => {
    await userSnap?.ref.update({
      postRegistrationComplete: true
    });
    await activeOrganization?.ref.update({
      postRegistrationComplete: true
    });
    navigate(
      getDynamicRoute(
        getRedirect(),
        {},
        activeOrganization ? { activeOrg: activeOrganization?.id } : undefined
      )
    );
  };

  if (!exists(activeOrganization)) return <LoadingState />;

  return (
    <AdvertisersInvitesForm
      onInviteCompleted={onInviteCompleted}
      organization={activeOrganization}
    />
  );
}
