import { LayoutModel } from 'lib/model/objects/layoutModel';
import { Layout } from 'lib/types/layout';

/** returns the first layout with image if available, otherwise the first. */
export const getDefaultLayout = (sortedSupportedLayouts: Layout[]) => {
  return sortedSupportedLayouts[
    Math.max(
      sortedSupportedLayouts.findIndex(
        layout => new LayoutModel(layout).imageCount > 0
      ),
      0
    )
  ];
};
