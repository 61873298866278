import LoadingState from 'components/LoadingState';
import { selectUser } from 'redux/auth';
import { useAppSelector } from 'redux/hooks';
import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useRedirectToLanding } from './hooks';

export function RequireUnregistered() {
  const { pathname } = useLocation();

  const user = useAppSelector(selectUser);

  const redirectToLanding = useRedirectToLanding();

  useEffect(() => {
    if (user && user.data().postRegistrationComplete) {
      return redirectToLanding();
    }
  }, [user?.id, pathname]);

  return user ? <Outlet /> : <LoadingState />;
}
