import { PublishingMedium } from 'lib/enums/PublishingMedium';
import { EOrganization, ESnapshotExists } from 'lib/types';
import { capitalize } from 'lodash';
import { State } from 'lib/enums';
import { ColumnButton } from 'lib/components/ColumnButton';
import CheckboxGroup from 'lib/components/Checkbox/CheckboxGroup';
import { ProductPublishingSetting } from 'lib/types/publishingSetting';
import { getBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { Ad, CategoryChoiceOption } from 'lib/types/ad';
import { ClassifiedFilingTypeNames } from 'lib/types/classified';
import { ObituaryFilingTypeNames } from 'lib/types/obituary';
import { PackageModel } from 'lib/model/objects/packageModel';
import RadioButtonGroup from 'lib/components/Checkbox/RadioButtonGroup';

type PublisherCardProps = {
  newspaper: ESnapshotExists<EOrganization>;
  publishingMediums: PublishingMedium[];
  validProductPublishingSettings: ProductPublishingSetting[];
  packages?: PackageModel[];
  selectedPackageId?: string;
  onPublishingMediumsChange: (value: PublishingMedium) => void;
  onPackageChange: (value: string) => void;
  onRemovePublisher: () => void;
  required?: boolean;
  inputData: Partial<Ad>;
};

function PublisherCard({
  newspaper,
  validProductPublishingSettings,
  packages,
  selectedPackageId,
  publishingMediums,
  onPublishingMediumsChange,
  onPackageChange,
  onRemovePublisher,
  required,
  inputData
}: PublisherCardProps) {
  const publishingMediumDescriptions = {
    [PublishingMedium.Print]:
      'Delivered to doorsteps and mailboxes across the country',
    [PublishingMedium.Online]: `Published online${
      newspaper.data().website ? ` at ${newspaper.data().website}` : ''
    }`,
    [PublishingMedium.EEdition]: `Available online in ${
      newspaper.data().name
    }'s digital e-edition`
  };
  const publishingMediumPrepositions = {
    [PublishingMedium.Print]: 'in print',
    [PublishingMedium.Online]: 'on their website',
    [PublishingMedium.EEdition]: 'in a digital e-edition'
  };
  const filingTypeCategoryDescriptions: Record<CategoryChoiceOption, string> = {
    [ClassifiedFilingTypeNames.Services]: 'services advertisements',
    [ClassifiedFilingTypeNames.AnnouncementsEvents]: 'event announcements',
    [ClassifiedFilingTypeNames.HousingProperty]: 'real estate listings',
    [ClassifiedFilingTypeNames.Jobs]: 'job listings',
    [ClassifiedFilingTypeNames.ForSale]: 'for sale listings',
    [ClassifiedFilingTypeNames.CommunityEngagementLetters]:
      'community engagement letters',
    [ClassifiedFilingTypeNames.Milestones]: 'milestone announcements',
    [ClassifiedFilingTypeNames.Pets]: 'pet listings',
    [ObituaryFilingTypeNames.DeathNotice]: 'death notices',
    [ObituaryFilingTypeNames.Obituary]: 'obituaries',
    [ObituaryFilingTypeNames.CelebrationOfLife]: 'celebration of life notices',
    [ObituaryFilingTypeNames.ServiceNotice]: 'service notices'
  };
  const prepositionsForAllAvailablePublishingMethods =
    validProductPublishingSettings.map(publishingSetting => {
      if (publishingSetting.customLabel) {
        return `via ${publishingSetting.customLabel.toLowerCase()}`;
      }
      return publishingMediumPrepositions[publishingSetting.publishingMedium];
    });
  const publishingMethodsPrepositionalDescription =
    prepositionsForAllAvailablePublishingMethods.length > 1
      ? `${prepositionsForAllAvailablePublishingMethods
          .slice(0, -1)
          .join(', ')} and ${prepositionsForAllAvailablePublishingMethods.slice(
          -1
        )}`
      : prepositionsForAllAvailablePublishingMethods[0];

  const hasPackages = packages && packages.length > 0;

  const isIncludedInPackageSelection = selectedPackageId && !hasPackages;

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center justify-between">
        <h3 className="text-sm font-bold text-column-gray-500">
          {newspaper.data().name}
        </h3>
        {!required && (
          <ColumnButton
            onClick={onRemovePublisher}
            buttonText="Remove"
            destructive
            size="sm"
            type="button"
          />
        )}
      </div>

      {isIncludedInPackageSelection && <p>Included in package selection</p>}

      {hasPackages && !isIncludedInPackageSelection && (
        <RadioButtonGroup
          id="package-selection"
          labelText="Select a package"
          options={packages.map(packageModel => ({
            id: packageModel.id,
            labelText: packageModel.modelData.title,
            labelDescription: packageModel.modelData.description,
            value: packageModel.id
          }))}
          value={selectedPackageId || ''}
          onChange={onPackageChange}
          stacked
        />
      )}

      {!hasPackages && !isIncludedInPackageSelection && (
        <CheckboxGroup
          id="publishing-medium-selection"
          value={validProductPublishingSettings.map(publishingSetting => ({
            id: publishingSetting.publishingMedium,
            labelText:
              publishingSetting.customLabel ||
              capitalize(publishingSetting.publishingMedium),
            labelDescription:
              publishingSetting.customDescription ||
              publishingMediumDescriptions[publishingSetting.publishingMedium],
            checked: publishingMediums.includes(
              publishingSetting.publishingMedium
            )
          }))}
          onChange={(_newValues, { id: medium }) =>
            onPublishingMediumsChange(medium)
          }
          labelText={
            validProductPublishingSettings.length === 0 ? (
              <>
                {newspaper.data().name} does not support publishing{' '}
                {filingTypeCategoryDescriptions[
                  inputData.filingTypeName as CategoryChoiceOption
                ] || inputData.filingTypeName}
                . Please select a different publication.
              </>
            ) : (
              <>
                Based in {newspaper.data().city},{' '}
                {State.by_value(newspaper.data().state)?.label},{' '}
                {newspaper.data().website ? (
                  <a
                    className="underline text-column-primary-500"
                    href={newspaper.data().website}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {newspaper.data().name}
                  </a>
                ) : (
                  newspaper.data().name
                )}{' '}
                publishes {publishingMethodsPrepositionalDescription}.
              </>
            )
          }
          required={getBooleanFlag(
            LaunchDarklyFlags.ENABLE_FAST_PUBLISHER_SELECTION
          )}
        />
      )}
    </div>
  );
}

export default PublisherCard;
