import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Paper,
  Typography,
  Avatar,
  Box,
  Input,
  InputLabel,
  FormHelperText,
  FormControl,
  CircularProgress
} from '@material-ui/core';
import AdornedButton from 'components/AdornedButton';
import LoadingState from 'components/LoadingState';
import { connect } from 'react-redux';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import MailOutline from '@material-ui/icons/MailOutline';
import api from 'api';
import { ESnapshot, EUser, exists } from 'lib/types';
import { EReduxState } from 'redux/types';
import { BROWSER, BROWSERS } from '../../../constants';

const styles = (theme: Theme) =>
  createStyles({
    main: {
      width: 'auto',
      height: '100vh',
      display: 'flex',
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      [theme.breakpoints.up(400 + theme.spacing(6))]: {
        width: 400,
        marginLeft: 'auto',
        marginRight: 'auto'
      }
    },
    paper: {
      marginTop: theme.spacing(16),
      marginBottom: theme.spacing(16),
      display: BROWSER === BROWSERS.ie ? 'block' : 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
        3
      )}px`,
      width: 500,
      height: 'max-content'
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1)
    },
    ieAligner: {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      flexDirection: 'column'
    },
    error: {
      color: theme.palette.error.main
    },
    resend: {
      '&:hover': {
        textDecoration: 'underline',
        textDecorationColor: theme.palette.primary.main,
        cursor: 'pointer'
      }
    }
  });

const mapStateToProps = (state: EReduxState) => ({
  user: state.auth.user
});

type EmailNeedsConfirmProps = {
  user: ESnapshot<EUser> | null;
  classes: Record<string, string>;
};

function EmailNeedsConfirm({ user, classes }: EmailNeedsConfirmProps) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState('');
  const [error, setError] = useState('');
  const [resendLoading, setResendLoading] = useState(false);
  const [resendEmailMessage, setResendEmailMessage] = useState('');

  const verify = async () => {
    if (!exists(user)) return;

    setLoading(true);
    try {
      await api.post('users/confirm-email', { code, uid: user.id });
    } catch (err) {
      const errStr = (err as Error).toString()
        ? (err as Error).toString().replace('Error: ', '')
        : 'Could not verify code';
      setError(errStr);
    }
    setLoading(false);
  };

  const resend = async () => {
    setResendLoading(true);
    try {
      await api.post('users/reset-code');
      setResendEmailMessage('Email with new code sent');
    } catch (err) {
      setResendEmailMessage(
        'We had trouble sending a new code. Please login again, and retry. If the problem persists, contanct help@column.us'
      );
    }
    setResendLoading(false);
  };

  if (!exists(user)) {
    return <LoadingState />;
  }

  if (!user.data().emailNeedsConfirm) {
    navigate('/');
    return <LoadingState />;
  }

  return (
    <main className={classes.main}>
      <Paper className={classes.paper}>
        <div className={BROWSER === BROWSERS.ie ? classes.ieAligner : ''}>
          <Avatar className={classes.avatar}>
            <MailOutline />
          </Avatar>
        </div>
        <Typography component="h1" variant="h5">
          Verify your Email Address
        </Typography>
        <Box mt={2}>
          <Typography variant="body2">
            Enter the verification code we sent to: <b>{user.data().email}</b>{' '}
            <br></br>If you don't see it, check your spam folder.
          </Typography>
        </Box>
        <Box mt={2} style={{ width: '100%', height: 50 }}>
          <FormControl style={{ width: '100%' }}>
            <InputLabel htmlFor="code">Verification Code</InputLabel>
            <Input
              onChange={e => {
                setError('');
                setCode(e.target.value);
              }}
              name="code"
              fullWidth
            />
            <FormHelperText className={classes.error} error={error !== ''}>
              {error}
            </FormHelperText>
          </FormControl>
        </Box>
        <Box mt={3} style={{ width: '100%' }}>
          <AdornedButton
            disabled={!code || code.length === 0 || loading}
            id="submit"
            fullWidth
            variant="contained"
            color="primary"
            loading={loading}
            onClick={verify}
            className={classes.submit}
          >
            Verify
          </AdornedButton>
        </Box>
        <Box mt={2}>
          {resendLoading ? (
            <CircularProgress size={15} />
          ) : resendEmailMessage ? (
            <Typography variant="body2">{resendEmailMessage}</Typography>
          ) : (
            <div
              className={classes.resend}
              onClick={() => {
                if (resendLoading) return;
                void resend();
              }}
            >
              <Typography color="primary" variant="body2">
                Resend
              </Typography>
            </div>
          )}
        </Box>
      </Paper>
    </main>
  );
}

export default connect(mapStateToProps)(
  withStyles(styles, { withTheme: true })(EmailNeedsConfirm)
);
