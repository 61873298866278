import { useState } from 'react';
import { Modal } from 'lib/components/Modal';
import { TextField } from 'lib/components/TextField';
import firebase from 'firebase';
import { logAndCaptureException } from 'utils';
import { useNavigate } from 'react-router-dom';
import { capitalizeWord } from 'lib/utils/strings';
import { logInfo } from 'utils/logger';
import { ColumnService } from 'lib/services/directory';
import { FirebaseAuthError } from './types';

type PromptForPasswordModalProps = {
  onClose: () => void;
  userAuthData: FirebaseAuthError;
};

export default function PromptForPasswordModal({
  onClose,
  userAuthData
}: PromptForPasswordModalProps) {
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [linkingWithCredentials, setLinkingWithCredentials] = useState(false);
  const providerId = userAuthData.credential.providerId.split('.')[0];

  const setPasswordToLinkCredentials = async () => {
    setLinkingWithCredentials(true);
    const { email, credential } = userAuthData;
    try {
      await firebase.auth().signInWithEmailAndPassword(email, password);
      if (credential && firebase.auth().currentUser) {
        logInfo(
          `User linking ${capitalizeWord(providerId)} account with Password`,
          { email }
        );
        await firebase.auth().currentUser?.linkWithCredential(credential);
        navigate('/');
      }
    } catch (error) {
      logAndCaptureException(
        ColumnService.AUTH_AND_USER_MANAGEMENT,
        error,
        'Failed to link with email provider',
        {
          email
        }
      );
    }
    setLinkingWithCredentials(false);
  };

  return (
    <Modal
      id="password-prompt-modal"
      title={'Enter password'}
      subtitle={`The email you are trying to register is already linked with password. To link an existing account with ${capitalizeWord(
        providerId
      )}, please enter the password of your existing account. Otherwise, click cancel.`}
      primaryAction={{
        type: 'button',
        loading: linkingWithCredentials,
        buttonText: 'Submit',
        onClick: setPasswordToLinkCredentials
      }}
      allowCloseOutsideModal={false}
      showCloseButton={false}
      secondaryActions={[
        {
          type: 'button',
          buttonText: 'Cancel',
          onClick: onClose
        }
      ]}
      onClose={onClose}
    >
      <div className="pb-6">
        <TextField
          id="password"
          labelText="Enter password"
          type="password"
          placeholder="******"
          value={password}
          onChange={value => setPassword(value)}
        />
      </div>
    </Modal>
  );
}
