import { RouteObject } from 'react-router-dom';
import { INVOICE_ROUTES } from 'router/routes';
import PayInvoice from 'routes/notice/billing/payInvoice/PayInvoice';

const publicRoutes: RouteObject = {
  children: [{ path: INVOICE_ROUTES.PAY, element: <PayInvoice /> }]
};

export const standaloneRoutes: RouteObject = {
  children: [publicRoutes]
};
