import React, { useState } from 'react';
import { useAppDispatch } from 'redux/hooks';
import { useNavigate } from 'react-router-dom';

import TemporaryPasswordResetContaner from 'layouts/signInLayout/TemporaryPasswordResetContainer';
import { ESnapshot, EInvite } from 'lib/types';
import { registerUser } from 'utils/users';
import ToastActions from 'redux/toast';
import AuthActions from 'redux/auth';

import firebase from 'firebase/app';
import { logAndCaptureException } from 'utils';
import { ColumnService } from 'lib/services/directory';
import { ColumnH1 } from 'lib/components/Typography';
import { getDefaultRegistrationDataFromInvite } from './registerUserHelpers';
import RegisterUserFooter from './RegisterUserFooter';
import RegisterUserForm from './RegisterUserForm';
import AccountLinkingConfirmationModal from './AccountLinkingConfirmationModal';

type RegisterProps = {
  invite?: ESnapshot<EInvite>;
};

export default function Register({ invite }: RegisterProps) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [registeringUser, setRegisteringUser] = useState(false);
  const [registrationData, setRegistrationData] = useState(
    getDefaultRegistrationDataFromInvite(invite)
  );
  const [showTemporaryPasswordAlert, setShowTemporaryPasswordAlert] =
    useState(false);

  const [existingSignedinMethods, setExistingSignedinMethods] = useState<
    string[]
  >([]);

  const handleRegistrationSuccess = (token: string) => {
    dispatch(AuthActions.loginToken(token));
    // Go to / and let our routingSaga take you to the correct place based on the invites associated with your account
    navigate('/');
  };

  const handleRegistrationFailure = (error: Error, type: string) => {
    setRegisteringUser(false);
    switch (type) {
      case 'invalid user input':
        dispatch(
          ToastActions.toastError({
            headerText: 'Error',
            bodyText: error.message
          })
        );
        break;
      case 'user already exists with non-temporary password':
        dispatch(
          ToastActions.toastError({
            headerText: 'Error',
            bodyText: 'A user with this email already exists'
          })
        );
        break;
      case 'new user has temporary password':
        setShowTemporaryPasswordAlert(true);
        break;
      case 'unknown':
      default:
        dispatch(
          ToastActions.toastError({
            headerText: 'Error',
            bodyText:
              'There was a problem processing your request, please check your connection, refresh and try again'
          })
        );
    }
  };

  const handleAccountLinkingWithOtherProviders = async () => {
    try {
      const credential = firebase.auth.EmailAuthProvider.credential(
        registrationData.email,
        registrationData.password
      );
      if (existingSignedinMethods[0] === 'google.com') {
        setExistingSignedinMethods([]);
        await firebase
          .auth()
          .signInWithPopup(new firebase.auth.GoogleAuthProvider());
        if (credential && firebase.auth().currentUser) {
          await firebase.auth().currentUser?.linkWithCredential(credential);
        }
      } else if (existingSignedinMethods[0] === 'microsoft.com') {
        setExistingSignedinMethods([]);
        await firebase
          .auth()
          .signInWithPopup(new firebase.auth.OAuthProvider('microsoft.com'));
        if (credential && firebase.auth().currentUser) {
          await firebase.auth().currentUser?.linkWithCredential(credential);
          navigate('/');
        }
      }
    } catch (error) {
      logAndCaptureException(
        ColumnService.AUTH_AND_USER_MANAGEMENT,
        error,
        'Failed to link email/password account with other providers'
      );
    }
  };

  const handleRegistration = async () => {
    setRegisteringUser(true);
    const { firstName, lastName, email, password } = registrationData;
    // Verify if other providers (Google/Microsoft) signed up with same email
    const existingProviders = await firebase
      .auth()
      .fetchSignInMethodsForEmail(email);
    if (existingProviders.length && !existingProviders.includes('password')) {
      setExistingSignedinMethods(existingProviders);
      return;
    }

    const organizationId = invite?.data()?.organizationId;

    const userRegistrationResponse = await registerUser({
      organizationId,
      firstName,
      lastName,
      password,
      email
    });

    if (userRegistrationResponse.success) {
      handleRegistrationSuccess(userRegistrationResponse.token);
    } else {
      handleRegistrationFailure(
        userRegistrationResponse.error,
        userRegistrationResponse.type
      );
    }
  };

  return (
    <div className="w-full">
      <div>
        <ColumnH1 className="text-center">Create your account</ColumnH1>
        <p className="text-center mt-4 text-sm font-medium leading-5 text-column-gray-400">
          Publish information that matters.
        </p>
      </div>
      <RegisterUserForm
        onChangeRegistrationData={setRegistrationData}
        registrationData={registrationData}
        submitting={registeringUser}
        onSubmit={handleRegistration}
        invite={invite?.data()}
      />
      {showTemporaryPasswordAlert && (
        <TemporaryPasswordResetContaner email={registrationData.email} />
      )}
      {!!existingSignedinMethods.length && (
        <AccountLinkingConfirmationModal
          onClose={() => {
            setExistingSignedinMethods([]);
            setRegisteringUser(false);
          }}
          onClickConfirmLinking={handleAccountLinkingWithOtherProviders}
        />
      )}
      <RegisterUserFooter />
    </div>
  );
}
