import { TableLayout } from 'lib/components/TableLayout';
import { useFirestoreQueryListener } from 'lib/frontend/hooks/useFirestoreQueryListener';
import { EOrganization, ESnapshotExists, Package } from 'lib/types';
import { Product } from 'lib/enums';
import { PRODUCT_TO_NAME } from 'lib/enums/Product';
import { useState } from 'react';
import { getFirebaseContext } from 'utils/firebase';
import { EditPackageModal } from './EditPackageModal';

type PackagesTableProps = {
  activeOrganization: ESnapshotExists<EOrganization>;
  product: Product;
};
export default function PackagesTable({
  activeOrganization,
  product
}: PackagesTableProps) {
  const [currentPackage, setCurrentPackage] =
    useState<ESnapshotExists<Package>>();
  const [showEditPackageModal, setShowEditPackageModal] = useState(false);

  const packagesQuery = getFirebaseContext()
    .packagesRef()
    .where('authorizedOrganization', '==', activeOrganization.ref);
  const sharedPackagesQuery = getFirebaseContext()
    .packagesRef()
    .where('publisherOrganizations', 'array-contains', activeOrganization.ref);

  const ownedPackages = useFirestoreQueryListener(
    packagesQuery,
    [activeOrganization.id],
    {
      message: 'Failed to load packages',
      tags: {
        location: 'PackagesTable',
        organizationId: activeOrganization.id ?? ''
      }
    }
  );
  const sharedPackages = useFirestoreQueryListener(
    sharedPackagesQuery,
    [activeOrganization.id],
    {
      message: 'Failed to load shared packages',
      tags: {
        location: 'PackagesTable',
        organizationId: activeOrganization.id ?? ''
      }
    }
  );

  const orgPackages = [
    ...(ownedPackages?.docs ?? []),
    ...(sharedPackages?.docs ?? [])
  ];

  const productPackages = orgPackages.filter(
    packageSnap => packageSnap.data().product === product
  );

  function openCreatePackageModal() {
    setCurrentPackage(undefined);
    setShowEditPackageModal(true);
  }

  function openEditPackageModal(packageSnap: ESnapshotExists<Package>) {
    setCurrentPackage(packageSnap);
    setShowEditPackageModal(true);
  }

  function closeEditPackageModal() {
    setCurrentPackage(undefined);
    setShowEditPackageModal(false);
  }

  if (showEditPackageModal) {
    return (
      <EditPackageModal
        onClose={closeEditPackageModal}
        product={product}
        currentPackage={currentPackage}
      />
    );
  }

  const productLabel = PRODUCT_TO_NAME[product].singular;

  return (
    <TableLayout<ESnapshotExists<Package>>
      header={{
        title: `${productLabel} Packages`,
        subtitle: `Configure the packages available to your customers.`
      }}
      filterable={{ searchEnabled: false, shouldShowTableItem: () => true }}
      creatable={{
        onCreate: openCreatePackageModal,
        createButtonText: 'Add Package'
      }}
      editable={{
        editTooltip: 'Edit this package.',
        onEdit: openEditPackageModal
      }}
      columns={['Package Name', 'Category Name']}
      renderRow={packageSnap => (
        <>
          <td>{packageSnap.data().title}</td>
          <td>{packageSnap.data().filingTypeLabel}</td>
        </>
      )}
      data={productPackages || []}
      loading={!productPackages}
      id="packages-settings"
    />
  );
}
