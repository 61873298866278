import React, { useState } from 'react';
import Drawer from 'lib/components/Drawer';
import { useFirestoreSnapshot } from 'lib/frontend/hooks/useFirestoreSnapshot';
import TabGroup, { TabOption } from 'lib/components/Tabs';
import { PublicationIssueAttachmentModel } from 'lib/model/objects/publicationIssueAttachmentModel';
import { Product } from 'lib/enums';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { PublicationIssueWithSection } from 'lib/types/publicationIssueSection';
import { ColumnService } from 'lib/services/directory';
import { NoticesTab } from './NoticesTab';
import PublicationIssueDrawerHeader from './PublicationIssueDrawerHeader';
import BlocksTab from './BlocksTab';
import { OrdersTab } from './OrdersTab';
import PublicationIssueDrawerNotes from './PublicationIssueDrawerNotes';

const NOTES_TAB = {
  label: 'Notes',
  enabled: true,
  id: 'notes'
};
const NOTICES_TAB = {
  label: 'Notices',
  enabled: true,
  id: 'notices'
};
const OBITUARIES_TAB = {
  label: 'Obituaries',
  enabled: true,
  id: 'obituaries'
};
const CLASSIFIEDS_TAB = {
  label: 'Classifieds',
  enabled: true,
  id: 'classifieds'
};
const BLOCKS_TAB = {
  label: 'Blocks',
  enabled: true,
  id: 'blocks'
};

const getTabFromProduct = (product: Product) => {
  switch (product) {
    case Product.Obituary:
      return OBITUARIES_TAB;
    case Product.Classified:
      return CLASSIFIEDS_TAB;
    default:
      return NOTICES_TAB;
  }
};

const getTabs = (
  issueAttachments: PublicationIssueAttachmentModel[],
  product: Product,
  usingAdvancedPagination: boolean
) => {
  const productTab = getTabFromProduct(product);
  return [
    {
      ...BLOCKS_TAB,
      enabled: issueAttachments.length > 0
    },
    {
      ...productTab
    },
    ...(usingAdvancedPagination ? [NOTES_TAB] : [])
  ];
};

type PublicationIssueDrawerProps = {
  closeDrawer: () => void;
  publicationIssueWithSection: PublicationIssueWithSection;
  product: Product;
  usingAdvancedPagination: boolean;
};

export default function PublicationIssueDrawer({
  publicationIssueWithSection,
  closeDrawer,
  product,
  usingAdvancedPagination
}: PublicationIssueDrawerProps) {
  const { publicationIssue, section } = publicationIssueWithSection;
  const controllingDocument = section ?? publicationIssue;
  const [activeTab, setActiveTab] = useState<TabOption>(
    getTabFromProduct(product)
  );

  const { value: potentiallyRelevantAttachments } = useAsyncEffect<
    PublicationIssueAttachmentModel[]
  >({
    fetchData: () =>
      section
        ? section.getAttachmentsForIssueSection()
        : publicationIssue.getAttachmentsForIssue(),
    dependencies: [controllingDocument?.id],
    errorConfig: {
      service: ColumnService.PAGINATION,
      message:
        'Error fetching publication issue attachments in pagination drawer',
      tags: {
        publicationIssueId: publicationIssue.id,
        sectionId: section?.id ?? 'undefined'
      }
    }
  });
  const issueAttachments = potentiallyRelevantAttachments?.filter(
    attachment => attachment.modelData.section?.id === section?.id
  );

  const publisher = useFirestoreSnapshot(publicationIssue.modelData.publisher);
  if (!publisher || !issueAttachments) {
    return <></>;
  }

  const tabs = getTabs(issueAttachments, product, usingAdvancedPagination);

  return (
    <Drawer
      onClose={closeDrawer}
      className="flex flex-col"
      header={
        <PublicationIssueDrawerHeader
          publicationIssue={publicationIssue}
          publisher={publisher}
        />
      }
      open
    >
      <TabGroup
        tabs={tabs}
        activeTab={activeTab}
        onClickTab={setActiveTab}
        id="publication-issue-drawer-tabs"
      />
      {activeTab.id === BLOCKS_TAB.id && (
        <BlocksTab issueAttachments={issueAttachments} />
      )}
      {activeTab.id === NOTICES_TAB.id && (
        <NoticesTab publicationIssue={publicationIssue} />
      )}
      {(activeTab.id === OBITUARIES_TAB.id ||
        activeTab.id === CLASSIFIEDS_TAB.id) && (
        <OrdersTab
          publicationIssue={publicationIssue}
          product={product}
          section={section}
        />
      )}
      {activeTab.id === NOTES_TAB.id && (
        <PublicationIssueDrawerNotes
          publicationIssueWithSection={publicationIssueWithSection}
        />
      )}
    </Drawer>
  );
}
