import { useBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { Product } from 'lib/enums';
import { PRODUCT_TO_NAME } from 'lib/enums/Product';
import { useFetchAvailablePublishers } from 'hooks/useFetchAvailablePublishers';
import { useFetchSubdomainAssociatedPapers } from 'hooks/useFetchSubdomainAssociatedPapers';
import { getContextKey } from 'sagas/AuthSaga';
import { useAppSelector } from 'redux/hooks';
import { selectIsPublisher } from 'redux/auth';
import { Ad } from 'lib/types/ad';
import { OrderModel } from 'lib/model/objects/orderModel';
import SlowSelectPublication from './SlowSelectPublication';
import FastSelectPublication from './FastSelectPublication';
import MultiPublisherPackageSelectPublication from './SelectPublication';
import MultiStepHeader from '../../components/MultiStepHeader';
import { NewspaperOrdersFormData } from '../../PlacementFlowStepSelector';

type SelectPublicationProps = {
  newspaperOrdersFormData: NewspaperOrdersFormData;
  onNewspaperOrdersFormDataChange: React.Dispatch<
    React.SetStateAction<NewspaperOrdersFormData>
  >;
  product: Product;
  inputData: Partial<Ad>;
  orderModel: OrderModel;
  onUpdateAd: (update: Partial<Ad>) => void;
};

export default function SelectPublication(props: SelectPublicationProps) {
  const isPublisher = useAppSelector(selectIsPublisher);
  const enableFastPublisherSelection = useBooleanFlag(
    LaunchDarklyFlags.ENABLE_FAST_PUBLISHER_SELECTION
  );
  const enableMultiPublisherPackages = useBooleanFlag(
    LaunchDarklyFlags.ENABLE_MULTI_PUBLISHER_PACKAGES
  );

  const {
    loading: availablePublishersLoading,
    stateOptions,
    stateFilter,
    setStateFilter,
    publisherOptions
  } = useFetchAvailablePublishers({
    restrictedSingleState: undefined,
    isUserPublisher: false,
    restrictedPublisherIds: [],
    product: props.product
  });

  const formattedPublisherOptions = publisherOptions.map(o => ({
    label: o.name,
    value: o.id
  }));

  const {
    loading: relatedPublishersLoading,
    relatedPublisherOptions,
    autoSelectedPaper
  } = useFetchSubdomainAssociatedPapers(props.product, stateFilter);

  const { showAddPublications, useRelatedPublishers } =
    getShouldShowPublications({
      isPublisher,
      relatedPublisherOptions
    });

  const availablePublisherOptions = useRelatedPublishers
    ? relatedPublisherOptions
    : formattedPublisherOptions;

  const loading = relatedPublishersLoading || availablePublishersLoading;

  if (enableFastPublisherSelection) {
    return (
      <FastSelectPublication
        {...props}
        publishersLoading={loading}
        autoSelectedPaper={autoSelectedPaper}
        showAddPublications={showAddPublications}
        availablePublisherOptions={availablePublisherOptions}
        stateOptions={stateOptions}
        stateFilter={stateFilter}
        setStateFilter={setStateFilter}
      />
    );
  }

  const { title, description } = getTitleAndDescriptionForAddPublisherSetup({
    showAddPublications,
    product: props.product
  });

  return (
    <>
      {!loading && <MultiStepHeader title={title} description={description} />}
      {!enableMultiPublisherPackages && (
        <SlowSelectPublication
          {...props}
          publishersLoading={loading}
          autoSelectedPaper={autoSelectedPaper}
          showAddPublications={showAddPublications}
          availablePublisherOptions={availablePublisherOptions}
          stateOptions={stateOptions}
          stateFilter={stateFilter}
          setStateFilter={setStateFilter}
        />
      )}

      {enableMultiPublisherPackages && (
        <MultiPublisherPackageSelectPublication
          {...props}
          publishersLoading={loading}
          autoSelectedPaper={autoSelectedPaper}
          showAddPublications={showAddPublications}
          availablePublisherOptions={availablePublisherOptions}
          stateOptions={stateOptions}
          stateFilter={stateFilter}
          setStateFilter={setStateFilter}
        />
      )}
    </>
  );
}

function getTitleAndDescriptionForAddPublisherSetup({
  showAddPublications,
  product
}: {
  showAddPublications: boolean;
  product: Product;
}) {
  const productTypeName = PRODUCT_TO_NAME[product].singular.toLowerCase();

  if (showAddPublications) {
    return {
      title: `Where would you like to publish your ${productTypeName}?`,
      description: "Choose the newspapers that you'd like to publish with."
    };
  }
  return {
    title: `How would you like to place your order?`,
    description: 'Control how this order is sent to pagination.'
  };
}

function getShouldShowPublications({
  relatedPublisherOptions,
  isPublisher
}: {
  relatedPublisherOptions: { label: string; value: string }[];
  isPublisher: boolean;
}) {
  const subdomain = getContextKey();
  // for publishers
  if (isPublisher) {
    // hide if there are no options besides the current
    return {
      showAddPublications: relatedPublisherOptions.length > 1,
      useRelatedPublishers: true
    };
  }

  // for advertisers

  // hide if you are on a subdomain and there are no other options
  return {
    showAddPublications: !subdomain || relatedPublisherOptions.length > 1,
    useRelatedPublishers: false
  };
}
