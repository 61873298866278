import React, { useEffect } from 'react';
import api from 'api';
import { LoadingSpinner } from 'lib/components/LoadingSpinner';
import { useDispatch } from 'react-redux';
import ToastActions from 'redux/toast';

export default function InternalDocs() {
  const dispatch = useDispatch();

  useEffect(() => {
    void (async () => {
      try {
        const { error: tokenError, response: tokenData } = await api.safeGet(
          'docs/oauth'
        );
        if (tokenError) {
          dispatch(
            ToastActions.toastError({
              headerText: 'Error authenticating to documentation',
              bodyText: `Column's documentation is currently unavailable. Please try again later.`
            })
          );
          return;
        }
        window.location.href = tokenData?.redirectUrl ?? '';
      } catch (error) {
        dispatch(
          ToastActions.toastError({
            headerText: 'Error authenticating to documentation',
            bodyText: `Column's documentation is currently unavailable. Please try again later.`
          })
        );
      }
    })();
  }, []);

  return <LoadingSpinner />;
}
