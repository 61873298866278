import { ResponseOrError, wrapSuccess } from '../types/responses';
import { EFirebaseContext, Media } from '../types';
import { BaseService } from './baseService';
import { MediaModel } from '../model/objects/mediaModel';
import { safeGetModelArrayFromQuery } from '../model/getModel';
import {
  InternalServerError,
  wrapErrorAsColumnError
} from '../errors/ColumnErrors';

type MediaSearchRequest = {
  organizationId: string;
  folder: Media['folder'];
};
export class MediaService extends BaseService<Media> {
  constructor(ctx: EFirebaseContext) {
    super(ctx, ctx.mediaRef());
  }

  public async getOrganizationMedia(
    params: MediaSearchRequest
  ): Promise<ResponseOrError<MediaModel[]>> {
    const { organizationId, folder } = params;
    const organizationRef = this.ctx.organizationsRef().doc(organizationId);
    const query = this.ctx
      .mediaRef()
      .where('authorizedOrganization', '==', organizationRef)
      .where('folder', '==', folder);

    const [error, media] = await safeGetModelArrayFromQuery(
      MediaModel,
      this.ctx,
      query
    );

    if (error) {
      return wrapErrorAsColumnError(error, InternalServerError);
    }
    const filteredMedia = media.filter(m => !m.modelData.isArchived);
    return wrapSuccess(filteredMedia);
  }
}
