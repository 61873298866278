import { ColumnButton } from 'lib/components/ColumnButton';
import React from 'react';
import { removeFromCurrentUrlParams } from 'utils/urls';

// TODO: Deprecate this and figure out a way to do this directly in customer.io
export default function ModulistWelcomeBanner() {
  return (
    <div className="fixed inset-x-0 top-0 flex flex-col justify-between gap-x-8 gap-y-4 bg-white p-6 ring-1 ring-column-gray-500/10 md:flex-row md:items-center lg:px-8 z-max border-b">
      <p className="max-w-8xl text-sm/6 text-gray-900">
        Modulist customers can now use{' '}
        <a
          href="https://www.column.us"
          target="_blank"
          rel="noopener noreferrer"
          className="font-semibold text-column-primary-600"
        >
          Column
        </a>{' '}
        for processing obituaries, legal notices, and classifieds. To request
        historical data from your Modulist account, please{' '}
        <a
          href="https://forms.gle/CcWoqPY2P4h8C1Hh7"
          target="_blank"
          rel="noopener noreferrer"
          className="font-semibold text-column-primary-500"
        >
          complete this form
        </a>
        . If you have any questions about{' '}
        <a
          href="https://www.prnewswire.com/news-releases/column-announces-acquisition-of-modulist-expanding-platform-of-local-transactions-302032353.html"
          target="_blank"
          rel="noopener noreferrer"
          className="font-semibold text-column-primary-500"
        >
          this transition
        </a>
        , please don't hesitate to reach out to{' '}
        <a
          href="mailto:help@column.us"
          className="font-semibold text-column-primary-500"
        >
          help@column.us
        </a>
        .
      </p>
      <div className="flex flex-none items-center gap-x-5">
        <ColumnButton
          type="button"
          onClick={() => {
            const params = removeFromCurrentUrlParams('modulist');
            window.location.href = `/register${
              params.toString() ? `?${params.toString()}` : ''
            }`;
          }}
          buttonText="Create your account"
          secondary
        />
      </div>
    </div>
  );
}
