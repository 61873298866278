import React, { useState } from 'react';

import { EOrganization, ESnapshotExists } from 'lib/types';
import { columnObjectsAreEqual } from 'lib/utils/stringify';
import { AdditionalFee } from 'lib/types/rates';

import { TableLayout } from 'lib/components/TableLayout';
import { Product } from 'lib/enums';
import AdditionalFeeUpdateForm from './additionalFeeUpdateForm';
import CrossPaperFeeRow from './CrossPaperFeeRow';

type AdditionalFeesProps = {
  activeOrganization: ESnapshotExists<EOrganization>;
};

/**
 * Table layout for cross-paper fees
 */
export default function AdditionalFees({
  activeOrganization
}: AdditionalFeesProps) {
  const [editedAdditionalFee, setEditedAdditionalFee] =
    useState<AdditionalFee>();
  const [editedAdditionalFeeIndex, setEditedAdditionalFeeIndex] = useState(-1);
  const additionalFees = activeOrganization.data().additionalFees || [];

  return (
    <>
      {editedAdditionalFee && (
        <AdditionalFeeUpdateForm
          editedAdditionalFeeIndex={editedAdditionalFeeIndex}
          editedAdditionalFee={editedAdditionalFee}
          activeOrganization={activeOrganization}
          closeForm={() => setEditedAdditionalFee(undefined)}
        />
      )}
      <TableLayout<AdditionalFee>
        header={{
          subtitle: `Configure additional fees that show up across all public notice rates for ${
            activeOrganization.data().name
          }.`,
          title: 'Cross-Publisher Fees'
        }}
        creatable={{
          onCreate: () => {
            setEditedAdditionalFeeIndex(-1);
            const newFee: AdditionalFee = {
              type: 'flat',
              amount: 100,
              description: 'New Additional Fee',
              product: Product.Notice
            };
            setEditedAdditionalFee(newFee);
          },
          createButtonText: 'Add Fee'
        }}
        archivable={{
          renderWarning: feeToArchive => ({
            header: `Remove Fee: ${feeToArchive.description}`,
            body: 'Once removed, fees can no longer be recovered in the app. However, the Column team will still be able to recover your settings.'
          }),
          isArchiveDisabled: false,
          onArchive: async feeToArchive => {
            const newFees = activeOrganization
              .data()
              .additionalFees?.filter(
                fee => !columnObjectsAreEqual(fee, feeToArchive)
              );
            await activeOrganization.ref.update({
              additionalFees: newFees || []
            });
          }
        }}
        columns={['Fee Description', 'Amount/Percentage']}
        editable={{
          onEdit: additionalFee => {
            setEditedAdditionalFeeIndex(additionalFees.indexOf(additionalFee));
            setEditedAdditionalFee(additionalFee);
          }
        }}
        renderRow={additionalFee => (
          <CrossPaperFeeRow additionalFee={additionalFee} />
        )}
        data={additionalFees}
        filterable={{
          shouldShowTableItem: (additionalFee, filter) =>
            additionalFee.description
              .toLowerCase()
              .includes(filter.toLowerCase())
        }}
        id="additional-fees"
      />
    </>
  );
}
