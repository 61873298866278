/* eslint-disable no-await-in-loop */
import moment from 'moment';
import { EUser } from '../../types/user';
import { ResponseOrError, wrapError, wrapSuccess } from '../../types/responses';
import {
  PUBLICATION_ISSUE_STATUS_CHANGE,
  PublicationIssueStatusChangeEvent
} from '../../types/events';
import { SnapshotModel, getModelFromSnapshot, getModelFromRef } from '..';
import { Collections } from '../../constants';
import {
  FirebaseTimestamp,
  ESnapshotExists,
  ENotice,
  EFirebaseContext,
  ERef
} from '../../types';
import {
  PublicationIssue,
  PublicationIssueStatus
} from '../../types/publicationIssue';
import { PublicationIssueAttachmentModel } from './publicationIssueAttachmentModel';
import { PublicationIssueSectionModel } from './publicationIssueSectionModel';
import { PublicationIssueAttachmentStatus } from '../../types/publicationIssueAttachment';
import { getDateStringForDateInTimezone } from '../../utils/dates';
import { getOrThrow } from '../../utils/refs';
import { RunService } from '../../services/runService';
import { NoticeStatusType, Product } from '../../enums';
import { RunModel } from './runModel';
import { EEditionModel } from './EEditionModel';
import { OrganizationModel } from './organizationModel';
import { getNewSectionStatus } from '../../publicationIssues/publicationIssueSections';
import { safeAsync } from '../../safeWrappers';
import { getProductDeadlineTimeForPaper } from '../../utils/deadlines';
import { getErrorReporter } from '../../utils/errors';
import { PublishingMedium } from '../../enums/PublishingMedium';
import { ColumnError } from '../../errors/ColumnErrors';
import { SerializedModel } from '../types';
import { verifiableRunStatuses, verifiedRunStatuses } from '../../types/runs';
import {
  affidavitsAreManagedByColumnForNotice,
  bypassAutomaticVerificationForNotice,
  isAffidavitDisabled
} from '../../affidavits';
import { asyncFilter, asyncMap } from '../../helpers';
import { PublicationIssueSectionService } from '../../services/publicationIssueSectionService';

export type SerializedPublicationIssueModel = SerializedModel<
  typeof Collections.publicationIssues
>;

export class PublicationIssueModel extends SnapshotModel<
  PublicationIssue,
  typeof Collections.publicationIssues
> {
  private runService = new RunService(this.ctx);

  get type() {
    return Collections.publicationIssues;
  }

  public isAtLeastNDaysOld(days: number) {
    return moment(this.modelData.publicationDate, 'YYYY-MM-DD').isBefore(
      moment().subtract(days, 'days')
    );
  }

  get isPast() {
    return this.isAtLeastNDaysOld(0);
  }

  public async getAttachmentsForIssue(): Promise<
    ResponseOrError<PublicationIssueAttachmentModel[]>
  > {
    const publicationIssueAttachmentQuery = await this.ctx
      .publicationIssueAttachmentsRef(this.ref)
      .orderBy('createdAt', 'asc')
      .get();

    const filteredPublicationIssueAttachmentSnaps =
      publicationIssueAttachmentQuery.docs.filter(
        doc => doc.data().status !== PublicationIssueAttachmentStatus.DELETED
      );
    const nonSectionAttachments =
      filteredPublicationIssueAttachmentSnaps.filter(
        attachment => !attachment.data().section
      );
    const publicationIssueAttachments = nonSectionAttachments.map(snap =>
      getModelFromSnapshot(PublicationIssueAttachmentModel, this.ctx, snap)
    );
    return wrapSuccess(publicationIssueAttachments);
  }

  /**
   * Currently this method will return all non-disabled, non-cancelled runs associated with the publication issue.
   * This includes runs for notices that are archived (affidavit uploaded), so the runs returned here may not match
   * the notices returned from slowGetNoticesForPublicationIssue.
   *
   * We may update this in the future but for now devs should be aware of this distinction!
   */
  public async getRuns(
    options: {
      includeDisabled: boolean;
      includeCancelled: boolean;
    } = {
      includeDisabled: false,
      includeCancelled: false
    }
  ): Promise<ResponseOrError<RunModel[]>> {
    const runsQuery = this.ctx
      .runsRef()
      .where('publicationIssue', '==', this.ref);

    return this.runService.getRunModelsFromQuery(runsQuery, options);
  }

  public async getRunsToVerify(): Promise<ResponseOrError<RunModel[]>> {
    const { response: possiblyVerifiableRuns, error: runsError } =
      await this.runService.getRunsByPublicationIssueAndStatuses(this.ref, [
        ...verifiableRunStatuses
      ]);

    if (runsError) {
      return wrapError(runsError);
    }

    return asyncFilter(possiblyVerifiableRuns, async run => {
      const { response: notice, error: noticeError } = await run.getNotice();
      if (noticeError) {
        return wrapError(noticeError);
      }

      if (!notice.isPending) {
        return wrapSuccess(null);
      }

      const {
        response: affidavitsManagedByColumn,
        error: affidavitsManagedByColumnError
      } = await safeAsync(() =>
        affidavitsAreManagedByColumnForNotice(this.ctx, notice)
      )();

      if (affidavitsManagedByColumnError) {
        return wrapError(affidavitsManagedByColumnError);
      }

      if (!affidavitsManagedByColumn) {
        return wrapSuccess(null);
      }

      const bypassAutomaticVerification =
        bypassAutomaticVerificationForNotice(notice);

      if (bypassAutomaticVerification) {
        return wrapSuccess(null);
      }

      const { response: publisher, error: publisherError } = await safeAsync(
        () => notice.getPublisher()
      )();

      if (publisherError) {
        return wrapError(publisherError);
      }

      if (isAffidavitDisabled(notice.modelData, publisher)) {
        return wrapSuccess(null);
      }

      return wrapSuccess(run);
    });
  }

  public async getVerifiedRuns(): Promise<ResponseOrError<RunModel[]>> {
    return this.runService.getRunsByPublicationIssueAndStatuses(this.ref, [
      ...verifiedRunStatuses
    ]);
  }

  public async getEEditions(): Promise<ResponseOrError<EEditionModel[]>> {
    try {
      const eeditionsQuery = await this.ctx
        .eeditionsRef()
        .where('publicationIssue', '==', this.ref)
        .get();
      const eeditions = eeditionsQuery.docs.map(snap =>
        getModelFromSnapshot(EEditionModel, this.ctx, snap)
      );
      return wrapSuccess(eeditions);
    } catch (err) {
      return wrapError(err as Error);
    }
  }

  get publicationIssueAttachments() {
    return this.ctx.publicationIssueAttachmentsRef(this.ref);
  }

  /**
   * Updates the status and sends an event logging who made the change and what it was changed to
   */
  public async updateStatus(
    changedBy: ESnapshotExists<EUser>,
    status: PublicationIssueStatus
  ): Promise<ResponseOrError<boolean>> {
    const beforeStatus = this.modelData.status;
    if (beforeStatus === status) {
      return wrapError(new Error('Publication issue already in this status'));
    }
    try {
      await this.update({
        status
      });
    } catch (err) {
      return wrapError(err as Error);
    }
    const [attachmentsError, attachments] = await this.getAttachmentsForIssue();
    if (attachmentsError) {
      return wrapError(attachmentsError);
    }

    try {
      await this.ctx.eventsRef<PublicationIssueStatusChangeEvent>().add({
        type: PUBLICATION_ISSUE_STATUS_CHANGE,
        publicationIssue: this.ref,
        createdAt: this.ctx.timestamp(),
        newspaper: this.modelData.publisher,
        data: {
          beforeStatus,
          afterStatus: status,
          changedBy: changedBy.ref,
          attachmentData: attachments.map(attachment => attachment.modelData)
        },
        handleSideEffects: true
      });

      return wrapSuccess(true);
    } catch (err) {
      return wrapError(err as Error);
    }
  }

  public async updateDeadline(params: {
    deadlineTimestamp: FirebaseTimestamp;
  }): Promise<void> {
    const { deadlineTimestamp } = params;
    if (
      this.modelData.deadlineTimestamp?.toMillis() !==
      deadlineTimestamp.toMillis()
    ) {
      await this.update({
        deadlineTimestamp
      });
    }
  }

  protected async update(
    requestedParams: Partial<PublicationIssue>
  ): Promise<void> {
    // TODO(goodpaul): Abstract this to be used by publicationIssue and publicationIssueAttachment
    const params = requestedParams;
    if (params.status) {
      params.statusChanges = [
        ...(this.modelData.statusChanges || []),
        {
          status: params.status,
          // TODO(goodpaul): Move these to a subcollection so we can use the proper fieldValue timestamp
          timestamp: this.ctx.timestamp()
        }
      ];
    }
    await super.update(params);
  }

  /**
   * Loads all of the notices associated with a publication issue
   * TODO: replace this with an indexed query once publicationIssues are on our usernotice model
   */
  public async slowGetNoticesForPublicationIssue(): Promise<
    ResponseOrError<ESnapshotExists<ENotice>[]>
  > {
    const { publisher } = this.modelData;
    const noticeQuery = await this.ctx
      .userNoticesRef()
      .where('newspaper', '==', publisher)
      .where('isArchived', '==', false)
      .where('noticeStatus', '!=', NoticeStatusType.cancelled.value)
      .get();
    const { publicationDate } = this.modelData;
    const publisherSnapshot = await getOrThrow(publisher);
    const publisherTimezone = publisherSnapshot.data().iana_timezone;

    if (!publisherTimezone) {
      return wrapError(new Error('Publisher does not have a timezone'));
    }
    const noticesPublishingOnDate = noticeQuery.docs.filter(doc => {
      const { publicationDates, noticeStatus } = doc.data();
      // Notice status is only set after notice confirmation - don't show
      // notices that are still in draft stage
      if (!noticeStatus) return false;
      if (!publicationDates) return false;
      return publicationDates.some(noticeDate => {
        const noticeDateString = getDateStringForDateInTimezone({
          date: noticeDate.toDate(),
          timezone: publisherTimezone
        });
        return noticeDateString === publicationDate;
      });
    });
    return wrapSuccess(noticesPublishingOnDate);
  }

  /**
   * Updates the deadline of the given section if the publication schedule has changed
   * @param publicationIssueSection - The publication issue section
   * @param deadlineMoment - The deadline according to the current publication schedule
   */
  protected async updatePublicationSectionDeadline(
    publicationIssueSection: PublicationIssueSectionModel,
    deadlineMoment: moment.Moment
  ): Promise<void> {
    const publicationDateMoment = moment(
      this.modelData.publicationDate,
      'YYYY-MM-DD'
    );
    const now = moment();

    // Do not update the deadline for past publication issue sections
    if (publicationDateMoment.isBefore(now)) {
      return;
    }

    // Compare the existing deadline with the newspaper deadline
    const deadlineTimestamp = deadlineMoment.valueOf();
    const deadlineFirebaseTimestamp = this.ctx.timestampFromDate(
      deadlineMoment.toDate()
    );
    const existingDeadlineTimestamp =
      publicationIssueSection.modelData.deadlineTimestamp?.toMillis();

    if (deadlineTimestamp !== existingDeadlineTimestamp) {
      // Update the deadline if required
      await publicationIssueSection.ref.update({
        deadlineTimestamp: deadlineFirebaseTimestamp
      });
    }
  }

  public async getSections(options?: {
    includeDisabled: boolean;
  }): Promise<ResponseOrError<PublicationIssueSectionModel[]>> {
    return new PublicationIssueSectionService(
      this.ctx
    ).getSectionsForPublicationIssue(this.ref, options);
  }

  /**
   * This function returns the publication issue section of the current issue for the given section type (Obit, Classified,..) if
   * the newspaper is configured for that product. If the section does not exist, it will create a new section.
   * IMPORTANT: This method actively updates deadlines and status on the issue so it is not read only. If you ever change this behavior, you need to update functions/src/publicationIssues/publicationIssueCrons.ts
   */
  public async maybeGetOrCreateSection(
    sectionType: Product,
    publishingMedium: PublishingMedium
  ): Promise<
    ResponseOrError<PublicationIssueSectionModel | null, ColumnError>
  > {
    const publicationIssueSectionQuery = await this.ctx
      .publicationIssueSectionsRef(this.ref)
      .where('type', '==', sectionType)
      .where('publishingMedium', '==', publishingMedium)
      .get();
    const newspaperSnap = await getOrThrow(this.modelData.publisher);
    const newspaper = getModelFromSnapshot(
      OrganizationModel,
      this.ctx,
      newspaperSnap
    );
    const publisherTimezone = newspaper.modelData.iana_timezone;
    const { response: deadlineResult, error: deadlineResultError } =
      await getProductDeadlineTimeForPaper(
        newspaper,
        sectionType,
        publishingMedium,
        this.modelData.publicationDate
      );
    if (deadlineResultError) {
      return wrapError(deadlineResultError);
    }
    if (deadlineResult === null) {
      getErrorReporter().logInfo('Newspaper not configured for product', {
        newspaperId: newspaper.id,
        product: sectionType
      });
      return wrapSuccess(null);
    }
    const { deadlineMoment, deadlineSettings } = deadlineResult;
    const isNewspaperPublishing = !!deadlineSettings?.publish;

    // Return the section if exists
    if (!publicationIssueSectionQuery.empty) {
      const publicationIssueSection = getModelFromSnapshot(
        PublicationIssueSectionModel,
        this.ctx,
        publicationIssueSectionQuery.docs[0]
      );

      // TODO: Move state management out of the maybeGetOrCreateSection method.  What should drive this?
      if (
        !isNewspaperPublishing &&
        publicationIssueSection.modelData.status ===
          PublicationIssueStatus.PENDING
      ) {
        await publicationIssueSection.updateStatus(
          null,
          PublicationIssueStatus.DISABLED
        );
      } else if (
        isNewspaperPublishing &&
        publicationIssueSection.modelData.status ===
          PublicationIssueStatus.DISABLED
      ) {
        await publicationIssueSection.updateStatus(
          null,
          PublicationIssueStatus.PENDING
        );
      }

      // Update the section current deadline if the deadline has changed
      await this.updatePublicationSectionDeadline(
        publicationIssueSection,
        deadlineMoment
      );

      return wrapSuccess(publicationIssueSection);
    }

    // Create a new section
    // Determine the deadline of the publication date
    // Obituaries use a different deadline settings
    const deadlineTimestamp = this.ctx.timestampFromDate(
      deadlineMoment.toDate()
    );

    // Insert the new section
    const newSectionStatus = getNewSectionStatus(
      isNewspaperPublishing,
      this.modelData.publicationDate,
      publisherTimezone
    );

    const newSectionRef = await this.ctx
      .publicationIssueSectionsRef(this.ref)
      .add({
        status: newSectionStatus,
        deadlineTimestamp,
        type: sectionType,
        publishingMedium
      });

    const sectionModel = await getModelFromRef(
      PublicationIssueSectionModel,
      this.ctx,
      newSectionRef
    );
    return wrapSuccess(sectionModel);
  }

  /**
   * This function returns the publication issue sections (one for each given publishing medium) of the issue for the given section type (Obit, Classified,..).
   * If the section does not exist, it will create a new section.
   * IMPORTANT: This method actively updates deadlines and status on the issue so it is not read only. If you ever change this behavior, you need to update functions/src/publicationIssues/publicationIssueCrons.ts
   * @param sectionType - Product line
   * @param publishingMediums - Available publishing mediums on the newspaper
   */
  public async maybeGetOrCreateSectionsForPublishingMediums(
    sectionType: Product,
    publishingMediums: PublishingMedium[]
  ): Promise<ResponseOrError<(PublicationIssueSectionModel | null)[], Error>> {
    const { response: sections, error } = await asyncMap(
      publishingMediums,
      async publishingMedium =>
        this.maybeGetOrCreateSection(sectionType, publishingMedium)
    );

    if (error) {
      return wrapError(error);
    }
    return wrapSuccess(sections);
  }

  static async fromRef(ctx: EFirebaseContext, ref: ERef<PublicationIssue>) {
    return safeAsync(() => getModelFromRef(PublicationIssueModel, ctx, ref))();
  }
}
