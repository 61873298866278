import { getFirebaseContext } from 'utils/firebase';
import { selectActiveOrganization } from 'redux/auth';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { ColumnService } from 'lib/services/directory';
import { useAppSelector } from 'redux/hooks';
import { Product } from 'lib/enums';
import { Alert } from 'lib/components/Alert';
import { AdRateService } from 'lib/services/adRateService';
import { ProductPublishingSettingsService } from 'lib/services/productPublishingSettingsService';
import { ESnapshotExists, Package } from 'lib/types';
import LoadingState from 'components/LoadingState';
import { usePublisherOrgRates } from '../hooks/usePublisherOrgRates';
import { EditPackageModalForm } from './EditPackageModalForm';

const productPublishingSettingService = new ProductPublishingSettingsService(
  getFirebaseContext()
);

type EditPackageModalProps = {
  onClose: () => void;
  product: Product;
  currentPackage?: ESnapshotExists<Package>;
};

export function EditPackageModal({
  onClose,
  product,
  currentPackage
}: EditPackageModalProps) {
  const activeOrganization = useAppSelector(selectActiveOrganization);

  const rateService = new AdRateService(
    getFirebaseContext().adRatesRef(),
    product
  );

  const { orgRates, loading: orgRatesLoading } = usePublisherOrgRates(
    rateService,
    {
      includeArchived: true,
      product
    }
  );

  const {
    value: productPublishingSettings,
    isLoading: publishingSettingsLoading
  } = useAsyncEffect({
    fetchData: async () => {
      if (!activeOrganization) return [];
      const result =
        await productPublishingSettingService.fetchProductPublishingSettingArray(
          activeOrganization.ref,
          product
        );

      if (result.error) {
        throw result.error;
      }

      return result.response;
    },
    initialData: [],
    dependencies: [activeOrganization?.id, product],
    errorConfig: {
      service: ColumnService.SETTINGS_MANAGEMENT,
      message: 'Failed to load product publishing settings',
      tags: {
        location: 'EditPackageModal',
        organizationId: activeOrganization?.id ?? ''
      }
    }
  });

  const loading = orgRatesLoading || publishingSettingsLoading;

  if (loading) {
    return (
      <LoadingState
        context={{
          service: ColumnService.SETTINGS_MANAGEMENT,
          location: 'EditPackageModal',
          tags: { organizationId: `${activeOrganization?.id}` }
        }}
      />
    );
  }

  if (!productPublishingSettings) {
    return (
      <Alert
        id="Missing settings"
        title="Missing settings"
        description="Please configure publishing mediums before configuring packages"
      />
    );
  }

  return (
    <EditPackageModalForm
      onClose={onClose}
      product={product}
      currentPackage={currentPackage}
      productPublishingSettings={productPublishingSettings}
      orgRates={orgRates}
    />
  );
}
