import {
  CardGridLayout,
  GridInput,
  useEditableCard
} from 'lib/components/Card/Grid';
import { Form } from 'lib/components/Form';
import CheckboxGroup from 'lib/components/Checkbox/CheckboxGroup';
import { EOrganization, ESnapshotExists } from 'lib/types';

const getDefaultColumnDisplaySiteSettings = (
  activeOrganization: ESnapshotExists<EOrganization>
) => {
  return {
    convertPublicNoticeOnConfirmation:
      !!activeOrganization.data()?.convertPublicNoticeOnConfirmation
  };
};

type ExternalUploadProps = {
  activeOrganization: ESnapshotExists<EOrganization>;
};

export default function ColumnDisplaySiteForm({
  activeOrganization
}: ExternalUploadProps) {
  const { formSettings, onChange, onSubmitWrapper, editable, disabled } =
    useEditableCard(getDefaultColumnDisplaySiteSettings(activeOrganization));

  return (
    <Form
      onSubmit={async () =>
        onSubmitWrapper(async () => {
          if (!activeOrganization) {
            return { success: false, message: 'No active organization' };
          }

          await activeOrganization.ref.update(formSettings);

          return { success: true };
        })
      }
    >
      <CardGridLayout
        header={{
          title: 'Column Display Site'
        }}
        editable={editable}
      >
        <GridInput fullWidth>
          <CheckboxGroup
            id="display-site-upload-timing"
            labelText="When should notices appear on your Column Display Site?"
            value={[
              {
                labelText: 'The day of publication',
                labelDescription:
                  'Notices will appear on your display site after the first day of publication',
                checked: formSettings.convertPublicNoticeOnConfirmation,
                id: 'upload-on-confirmation'
              },
              {
                labelText: 'On notice confirmation',
                labelDescription:
                  'Notices will appear immediately on your display site after being confirmed',
                checked: !formSettings.convertPublicNoticeOnConfirmation,
                id: 'upload-normally'
              }
            ]}
            onChange={(_items, { id }) =>
              onChange({
                convertPublicNoticeOnConfirmation:
                  id === 'upload-on-confirmation'
              })
            }
            disabled={disabled}
          />
        </GridInput>
      </CardGridLayout>
    </Form>
  );
}
