import moment from 'moment';
import { safeStringify } from '../utils/stringify';
import {
  EFirebaseContext,
  EOrganization,
  EQuery,
  ERef,
  ESnapshotExists,
  EDeadline,
  exists
} from '../types';
import { getModelFromRef } from '../model';
import { PublicationIssueModel } from '../model/objects/publicationIssueModel';
import {
  CreatePublicationIssueParams,
  PublicationIssue,
  PublicationIssueSearchRequest,
  PublicationIssueStatus,
  PublicationIssueSectionSearchRequest
} from '../types/publicationIssue';
import { getQueryResultsWhereKeyInArray } from '../utils/firebase';
import { getErrorReporter } from '../utils/errors';
import {
  getDeadlineRecordsForPublicationDate,
  getLatestDeadlineTimestampForDeadlines
} from '../utils/deadlines';
import { getDateStringForDateInTimezone } from '../utils/dates';
import { ResponseOrError, wrapError, wrapSuccess } from '../types/responses';
import { PublicationIssueWithSection } from '../types/publicationIssueSection';
import { getRejected } from '../helpers';
import { productTypeUsesPublicationIssueSections } from '../publicationIssues/publicationIssueSections';
import { ColumnService } from './directory';
import { PublicationIssueSectionModel } from '../model/objects/publicationIssueSectionModel';

export const getPublicationIssueForPublisher = async (
  ctx: EFirebaseContext,
  orgRef: ERef<EOrganization>,
  publicationDate: string
): Promise<PublicationIssueModel | null> => {
  const publicationIssues = await ctx
    .publicationIssuesRef()
    .where('publisher', '==', orgRef)
    .where('publicationDate', '==', publicationDate)
    .get();
  if (publicationIssues.docs.length > 1) {
    getErrorReporter().logAndCaptureWarning(
      '[getPublicationIssueForPublisher] - Multiple publication issues found for publisher and publication date',
      {
        publicationDate,
        orgId: orgRef.id
      }
    );
  }
  if (publicationIssues.docs.length === 0) {
    return null;
  }
  return new PublicationIssueModel(ctx, {
    snap: publicationIssues.docs[0]
  });
};

export const getPublicationIssueForDeadline = async (
  ctx: EFirebaseContext,
  deadline: ESnapshotExists<EDeadline>
): Promise<PublicationIssueModel | null> => {
  const newspaperRef = deadline.ref.parent.parent as ERef<EOrganization> | null;
  const newspaperSnap = await newspaperRef?.get();
  if (!exists(newspaperSnap)) {
    getErrorReporter().logAndCaptureWarning(
      'Deadline has no parent newspaper; cannot get publication issue',
      {
        deadlinePath: deadline.ref.path
      }
    );
    return null;
  }

  const publicationDate = getDateStringForDateInTimezone({
    date: deadline.data().publicationDate.toDate(),
    timezone: newspaperSnap.data().iana_timezone
  });
  return getPublicationIssueForPublisher(
    ctx,
    newspaperSnap.ref,
    publicationDate
  );
};

export const createPublicationIssue = async (
  ctx: EFirebaseContext,
  params: CreatePublicationIssueParams
): Promise<PublicationIssueModel> => {
  const { publicationDate, publisherId } = params;
  getErrorReporter().logInfo('Creating publication issue', {
    publicationDate,
    publisherId
  });
  let newStatus = PublicationIssueStatus.PENDING;
  const publisherRef = ctx.organizationsRef().doc(publisherId);
  const deadlines = await getDeadlineRecordsForPublicationDate(
    ctx,
    publicationDate,
    publisherRef
  );
  const latestDeadlineTimestamp =
    getLatestDeadlineTimestampForDeadlines(deadlines);
  const publicationIssueIsInPast = moment
    .utc(publicationDate, 'YYYY-MM-DD')
    .isBefore(moment.utc().startOf('day'));
  if (!latestDeadlineTimestamp) {
    newStatus = PublicationIssueStatus.DISABLED;
  } else if (publicationIssueIsInPast) {
    newStatus = PublicationIssueStatus.ARCHIVED;
  }
  const publicationIssueRef = await ctx.publicationIssuesRef().add({
    publisher: publisherRef,
    status: newStatus,
    publicationDate,
    deadlineTimestamp: latestDeadlineTimestamp,
    createdAt: ctx.fieldValue().serverTimestamp(),
    modifiedAt: ctx.fieldValue().serverTimestamp(),
    statusChanges: []
  });

  const publicationIssue = await getModelFromRef(
    PublicationIssueModel,
    ctx,
    publicationIssueRef
  );

  return publicationIssue;
};

export const getOrCreatePublicationIssueForPublisher = async (
  ctx: EFirebaseContext,
  orgRef: ERef<EOrganization>,
  publicationDate: string
): Promise<PublicationIssueModel> => {
  const existingPublicationIssue = await getPublicationIssueForPublisher(
    ctx,
    orgRef,
    publicationDate
  );
  if (existingPublicationIssue) {
    return existingPublicationIssue;
  }

  getErrorReporter().logInfo(
    'Existing publication issue not found; returning a newly created one',
    {
      publicationId: orgRef.id,
      publicationDate
    }
  );

  return await createPublicationIssue(ctx, {
    publicationDate,
    publisherId: orgRef.id
  });
};

/**
 * Entry point to query the publication issue collection.
 */
export const queryPublicationIssues = async (
  ctx: EFirebaseContext,
  query: PublicationIssueSearchRequest
): Promise<ResponseOrError<PublicationIssueModel[]>> => {
  const {
    publisherIds,
    deadlineTimestampFrom,
    deadlineTimestampTo,
    publicationDateFrom,
    publicationDateTo,
    statuses,
    sectionType
  } = query;

  const querySetToDeadlineTimestampRange =
    deadlineTimestampFrom || deadlineTimestampTo;
  const querySetToPublicationDateRange =
    publicationDateFrom || publicationDateTo;
  if (querySetToDeadlineTimestampRange && querySetToPublicationDateRange) {
    return wrapError(
      new Error(
        'Unable to query by both deadline and publication timestamp.  Please provide one or the other.'
      )
    );
  }

  const queryIsForMoreThanTenPublishers =
    !publisherIds.length || publisherIds.length > 10;
  const queryHasNoTimeRange =
    !querySetToDeadlineTimestampRange && !querySetToPublicationDateRange;
  const queryHasNoStatusLimitation = !statuses || !statuses.length;
  const queryHasNeitherStatusNorTimeRestrictions =
    queryHasNoTimeRange && queryHasNoStatusLimitation;
  if (
    queryHasNeitherStatusNorTimeRestrictions &&
    queryIsForMoreThanTenPublishers
  ) {
    return wrapError(
      new Error(
        'Unable to query publication issues for more than 10 publishers without a specified time range or status limitation. Please restrict the query accordingly.'
      )
    );
  }

  let publicationIssuesQuery: EQuery<PublicationIssue> =
    ctx.publicationIssuesRef();

  if (deadlineTimestampFrom) {
    publicationIssuesQuery = publicationIssuesQuery.where(
      'deadlineTimestamp',
      '>=',
      ctx.timestampFromDate(deadlineTimestampFrom)
    );
  }
  if (deadlineTimestampTo) {
    publicationIssuesQuery = publicationIssuesQuery.where(
      'deadlineTimestamp',
      '<=',
      ctx.timestampFromDate(deadlineTimestampTo)
    );
  }
  if (publicationDateFrom) {
    publicationIssuesQuery = publicationIssuesQuery.where(
      'publicationDate',
      '>=',
      publicationDateFrom
    );
  }
  if (publicationDateTo) {
    publicationIssuesQuery = publicationIssuesQuery.where(
      'publicationDate',
      '<=',
      publicationDateTo
    );
  }

  // In case of querying sections, the status filter will be applied on sections not publication issues
  if (statuses && !productTypeUsesPublicationIssueSections(sectionType)) {
    publicationIssuesQuery = publicationIssuesQuery.where(
      'status',
      'in',
      statuses
    );
  }

  let publicationIssues: PublicationIssueModel[] = [];
  try {
    let publicationIssueResults;

    if (publisherIds.length) {
      const publisherRefs = publisherIds.map(id => {
        return ctx.organizationsRef().doc(id);
      });
      publicationIssueResults = await getQueryResultsWhereKeyInArray(
        publicationIssuesQuery,
        'publisher',
        publisherRefs
      );
    } else {
      publicationIssueResults = (await publicationIssuesQuery.get()).docs;
    }
    publicationIssues = publicationIssueResults.map(doc => {
      return new PublicationIssueModel(ctx, { snap: doc });
    });
  } catch (error) {
    getErrorReporter().logAndCaptureError(
      ColumnService.PAGINATION,
      error,
      'Error querying publication issues',
      {
        query: safeStringify(query) || ''
      }
    );
    return wrapError(error as Error);
  }

  return wrapSuccess(publicationIssues);
};

// This function will associate one or more sections with the given publication issue. It also applies the section status filter if any
// An object inside the returned array is considered a row in the pagination table
const constructPublicationIssueWithSectionsArray = (
  sections: (PublicationIssueSectionModel | null)[],
  publicationIssue: PublicationIssueModel,
  statuses: PublicationIssueStatus[] | undefined
): PublicationIssueWithSection[] => {
  const publicationIssueStatusMatches = statuses?.includes(
    publicationIssue.modelData.status
  );

  if (sections.every(section => !section)) {
    // All sections are not defined
    return publicationIssueStatusMatches
      ? [
          {
            publicationIssue,
            section: null
          }
        ]
      : [];
  }

  const publicationIssueWithSections = [];
  for (const section of sections) {
    if (section !== null && statuses?.length) {
      if (statuses.includes(section.modelData.status)) {
        publicationIssueWithSections.push({
          publicationIssue,
          section
        });
      }
    } else if (publicationIssueStatusMatches) {
      publicationIssueWithSections.push({
        publicationIssue,
        section
      });
    }
  }

  return publicationIssueWithSections;
};

// This function returns sections associated with the given publication issues that match the criteria in the given query
export const queryPublicationIssuesAndSections = async (
  publicationIssues: PublicationIssueModel[],
  query: PublicationIssueSectionSearchRequest
): Promise<ResponseOrError<PublicationIssueWithSection[]>> => {
  const { statuses, sectionType } = query;

  const publicationIssuesWithSections: PublicationIssueWithSection[] = [];
  if (sectionType && productTypeUsesPublicationIssueSections(sectionType)) {
    const result = await Promise.allSettled(
      publicationIssues.map(async publicationIssue => {
        // Get the section associated with the publication issue
        const { response: sections, error: sectionsError } =
          await publicationIssue.maybeGetOrCreateSectionsForPublishingMediums(
            sectionType,
            query.publishingMediums
          );

        if (sectionsError) {
          throw sectionsError;
        }

        const publicationIssueSections =
          constructPublicationIssueWithSectionsArray(
            sections,
            publicationIssue,
            statuses
          );
        publicationIssuesWithSections.push(...publicationIssueSections);
      })
    );

    const errors = getRejected(result);
    if (errors.length) {
      return wrapError(errors[0]);
    }
  } else {
    for (const publicationIssue of publicationIssues) {
      publicationIssuesWithSections.push({ publicationIssue, section: null });
    }
  }
  return wrapSuccess(publicationIssuesWithSections);
};

type ObjectWithPublicationIssue = {
  publicationIssue: PublicationIssueModel;
};

export const sortPublicationIssuesByDate = <
  T extends ObjectWithPublicationIssue
>(
  publicationIssues: T[],
  sortOrder: 'asc' | 'desc'
): T[] => {
  return publicationIssues.sort((a, b) => {
    const orderModifier = sortOrder === 'asc' ? 1 : -1;
    const comparison =
      a.publicationIssue.modelData.publicationDate.localeCompare(
        b.publicationIssue.modelData.publicationDate
      );
    return comparison * orderModifier;
  });
};
