import { Outlet } from 'react-router-dom';

import { exists } from 'lib/types';
import { selectActiveOrganization, selectUser } from 'redux/auth';
import { useAppSelector } from 'redux/hooks';
import AppLayoutHeader from './AppLayoutHeader';

export default function AppLayoutFrame() {
  const user = useAppSelector(selectUser);
  const activeOrganization = useAppSelector(selectActiveOrganization);

  return (
    <div className="flex flex-col h-screen">
      <AppLayoutHeader
        activeOrganization={
          exists(activeOrganization) ? activeOrganization : null
        }
        user={user}
      />
      <main className="bg-column-gray-25 flex-1 w-screen overflow-auto">
        <Outlet />
      </main>
    </div>
  );
}
